// import React, { useRef, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Icon from '../../../UI/Icon';
import { Button } from 'react-bootstrap';
import RiskyBusinessItem from './RiskyBusinessItem';

const RiskyBusinesses = () => {
  //   const fileRef = useRef(null);

  //   const [uploadedRiskyBusinesses, setUploadedRiskyBusinesses] = useState(null);

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'risky_businesses_list',
  });

  const handleAddRiskyBusinessesItem = () => {
    append({ value: 'New item' });
  };
  const handleDeleteRiskyBusinessesItem = (index) => {
    remove(index);
  };

  /*
  const handleTriggerFileUpload = () => {
    if (fileRef?.current) {
      fileRef.current.click();
    }
  };

  const handleUploadRiskyBusinessesFile = (e) => {
    // setUploadedRiskyBusinesses(e.target.files);
    if (!e.target?.files?.length) return;

    const file = e.target.files[0];

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        // Parse result
        const parsed = reader.result.split(',');
        setUploadedRiskyBusinesses(parsed);
      },
      false
    );

    if (file) {
      reader.readAsText(file);
    }
  };
  */

  return (
    <>
      <div className="row">
        <div className="col d-flex align-items-center">
          <h4>Risky businesses</h4>
        </div>
        <div>
          <Button
            onClick={handleAddRiskyBusinessesItem}
            variant="outline"
            className="shadow-none"
          >
            <Icon iconName="bi-plus-circle" size="2" />
          </Button>
          {/* <Button
            onClick={handleTriggerFileUpload}
            variant="outline"
            className="shadow-none"
          >
            <Icon iconName="bi-file-earmark-plus" size="2" />
          </Button>
          <input
            type="file"
            ref={fileRef}
            className="d-none"
            onChange={handleUploadRiskyBusinessesFile}
            accept="application/json, text/plain"
          /> */}
        </div>
      </div>
      <ListGroup>
        {fields.length ? (
          fields.map((item, idx) => (
            <RiskyBusinessItem
              key={item.id}
              idx={idx}
              onDelete={handleDeleteRiskyBusinessesItem}
            />
          ))
        ) : (
          <ListGroup.Item>
            No risky businesses items added yet...
          </ListGroup.Item>
        )}
      </ListGroup>
    </>
  );
};

export default RiskyBusinesses;
