import { useMemo, useState } from 'react';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import DataTable from 'react-data-table-component';
import AnySelector from '../UI/AnySelector';
import InlineSearch from '../UI/InlineSearch';
import useProductListColumns from './hooks/useProductListColumns';
import useLoader from '../UI/helpers/useLoader';
import Wait from './../UI/Wait';

const colsToSearch = ['name', 'vendorcode'];
const typedCol = 'id_type';

const ProductList = ({
    products,
    loading,
    onDeleteClick = () => {},
    onBuyClick = () => {},
    onRowClicked = () => {},
    totalRows,
    rowsCountPerPage,
    handleRowsPerPage = () => {},
    perPageChoices = [25],
    handlePageChange = () => {},
    onSetDefault = () => {},
    isAdmin = false,
}) => {
    const [keyword, setKeyword] = useState('');
    const [typeFilter, setTypeFilter] = useState('');
    const columns = useProductListColumns({
        products,
        onDeleteClick,
        onBuyClick,
        onSetDefault,
        isAdmin,
    });
    const displayedProducts = useMemo(() => {
        const searchFiltered = searchFilterIfConsist(
            products.productList,
            colsToSearch,
            keyword
        );
        const typifiedProducts = typifyIfEquals(
            searchFiltered,
            typedCol,
            typeFilter
        );
        return typifiedProducts;
    }, [keyword, products.productList, typeFilter]);
    const [progress, done] = useLoader([displayedProducts]);
    const types = useMemo(
        () =>
            Object.entries(products.productTypes).reduce(
                (carry, [key, value]) => [
                    ...carry,
                    { name: value, value: key },
                ],
                []
            ),
        [products.productTypes]
    );

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <InlineSearch setKeyword={setKeyword} />
                </div>
                <div>
                    <AnySelector
                        label="filter by type"
                        items={types}
                        nameSelector="name"
                        valueSelector="value"
                        resetItem={{ name: 'Show all', value: '' }}
                        setItem={setTypeFilter}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <DataTable
                        dense
                        striped
                        noHeader
                        highlightOnHover
                        progressPending={loading}
                        progressComponent={
                            <div className="p-5">
                                <Wait />
                            </div>
                        }
                        data={displayedProducts}
                        columns={columns}
                        onRowClicked={onRowClicked}
                        pagination={true}
                        paginationPerPage={rowsCountPerPage}
                        paginationRowsPerPageOptions={perPageChoices}
                        onChangeRowsPerPage={handleRowsPerPage}
                        paginationServer
                        onChangePage={handlePageChange}
                        paginationTotalRows={totalRows}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductList;
