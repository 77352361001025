import Honkio from '../../Honkio';

const changePassword = async function (options = {}, errorHandler = {}) {
  const { password, user_id } = options;

  return Honkio().mainShop.userFetch(
    'adminuserchangepassword',
    {
      user_id,
      password,
      version: 3,
      action: 'update',
    },
    errorHandler
  );
};

export default changePassword;
