import { useEffect, useState, useContext } from 'react';
import UserInfoContext from '../../../../contexts/UserInfoContext';
import InlineSearch from '../../../UI/InlineSearch';
import useSearchString from '../../../../utils/useSearchString';
import fixEmail from '../../../../utils/fixEmail';
import { getOrders } from '../../../../api/utils/order/getOrders';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import LoginsList from './LoginsList';
import { Col, Row, Container } from 'react-bootstrap';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const LOGIN_TRANSACTION_ORDER_TYPE = 'LOGIN_TRANSACTION_ORDER_TYPE';

const TransactionLoginList = () => {
    const [keyword, setKeyword] = useState('');
    const [loginOrderList, setLoginOrderList] = useState([]);
    const [totalRows, setTotalRows] = useState();

    const [loading, setLoading] = useState(false);

    const { merchant } = useSearchString();

    const {
        app: { id: appId },
        merchant: { id: contextMerchantId },
    } = useContext(UserInfoContext);

    const { email } = useSearchString(fixEmail);

    let merchantId = merchant
        ? merchant
        : contextMerchantId
        ? contextMerchantId
        : null;

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);

    useEffect(() => {
        fetchLoginOrders(1);
    }, [email, keyword, merchantId, perPage]);

    const fetchLoginOrders = async (page = 1) => {
        const resultHandler = (data) => {
            if (data && data?.orders) {
                setLoginOrderList(data.orders);
                setTotalRows(data.total);
            }
            setLoading(false);
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error');
            setLoading(false);
            setLoginOrderList([]);
            setTotalRows(0);
        };

        try {
            setLoading(true);
            const res = await getOrders(
                {
                    query_merchant: merchantId,
                    query_application: appId,
                    page: page - 1,
                    query_count: perPage,
                    query_type: LOGIN_TRANSACTION_ORDER_TYPE,
                    query_search: keyword,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            setLoginOrderList([]);
            setTotalRows(0);
            console.log(
                'Unexpected error fetching login transaction orders: ',
                error
            );
        }
    };

    const handlePageChange = (page) => {
        fetchLoginOrders(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    return (
        <>
            <NotificationContainer />

            <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <InlineSearch
                            keyword={keyword}
                            setKeyword={setKeyword}
                        />
                    </Col>
                </Row>
            </Container>

            <Row>
                <Col sm={12}>
                    <LoginsList
                        data={loginOrderList}
                        loading={loading}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        rowsCountPerPage={perPage}
                        handleRowsPerPage={handleRowsPerPage}
                        merchant={merchantId}
                        emptyText={'No login transactions yet...'}
                    />
                </Col>
            </Row>
        </>
    );
};

export default TransactionLoginList;
