import React, { useContext, useEffect, useState } from 'react';
import WidgetComponent from './WidgetComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';
import 'moment/locale/fi';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import UserInfoContext from '../../../contexts/UserInfoContext';
import getRegistrationOrders from '../../../api/utils/transactions/registration';
import Wait from '../../UI/Wait';

const NumRegistrationsWidgetComponent = ({
  position,
  height,
  width,
  name,
  onRename,
  onRemoveClick,
  start_date,
  end_date,
}) => {
  const [regOrders, setRegOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const userInfo = useContext(UserInfoContext);

  useEffect(() => {
    refreshData();
  }, [start_date, end_date]);

  const refreshData = async () => {
    setLoading(true);

    const resultHandler = (data) => {
      if (data && data?.status === 'accept') {
        setRegOrders(data?.orders);
        setTotal(data?.total);
        setLoading(false);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setRegOrders([]);
      setTotal(0);
      setLoading(false);
    };
    try {
      const res = await getRegistrationOrders(
        {
          merchantId:
            userInfo?.app?.name && userInfo.app.name === 'server'
              ? null
              : userInfo?.merchant?.id,
          appid:
            userInfo?.app?.name && userInfo.app.name === 'server'
              ? null
              : userInfo?.application?.id,
          query_start_date: moment(start_date).format('YYYY-MM-DD'),
          query_end_date: moment(end_date).format('YYYY-MM-DD'),
          query_count: 10,
        },
        errorHandler
      );
      resultHandler(res);
    } catch (error) {
      errorHandler(error);
    }
  };

  const onResize = (a, b, c) => {
    // TODO
  };

  let drawChart = (height, width) => {
    return (
      <div className="d-flex justify-content-center w-100 h-100">
        <span className="justify-content-center align-self-center h5">
          {total}
        </span>
      </div>
    );
  };

  return (
    <WidgetComponent
      className="position-relative"
      position={position}
      height={height}
      width={width}
      icon="fas fa-user-friends fa-2x"
      name={name}
      onRename={onRename}
      type="Number of users"
      summary1={
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          total
        </div>
      }
      onRemoveClick={onRemoveClick}
      chart={
        loading
          ? () => (
              <CircularProgress
                size={35}
                style={{
                  position: 'absolute',
                  top: '65%',
                  left: '50%',
                }}
              />
            )
          : drawChart
      }
    />
  );
};

export default NumRegistrationsWidgetComponent;
