import { useEffect, useState, useContext } from 'react';
import RegOrdersList from './RegOrdersList';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import { getOrders } from '../../../api/utils/order/getOrders';
import useSearchString from '../../../utils/useSearchString';
import UserInfoContext from '../../../contexts/UserInfoContext';

const DocumentsApproval = () => {
    const [orderList = [], setOrderList] = useState([]);
    const [selected, setSelected] = useState({});
    const userInfo = useContext(UserInfoContext);
    const { merchant = null, application = null } = useSearchString();
    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;
    const applicationId = application
        ? application
        : userInfo.app
        ? userInfo.app.id
        : null;
    //todo if merchant we have to ger merchant name someware to put it in title
    const pageTitle = merchant
        ? 'Merchant registration documents review'
        : userInfo.merchant?.name
        ? `Merchant registration documents review(${userInfo.merchant.name})`
        : 'Merchant registration documents review';

    const [loading, setLoading] = useState(false);
    let isMounted = true;
    const [perPage, setPerPage] = useState(25);
    const [totalRows, setTotalRows] = useState();

    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (merchantId) {
            fetchOrders(1);
        }
    }, [merchantId]);

    const fetchOrders = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                if (isMounted) {
                    setOrderList(data.orders);
                    setTotalRows(data.orders_count);
                    setLoading(false);
                }
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setOrderList([]);
            setTotalRows(0);
            setLoading(false);
        };

        if (isMounted) {
            getOrders(
                {
                    query_application: applicationId,
                    page: page - 1,
                    query_count: perPage,
                    query_child_merchants: true,
                    query_type: 'merchant_registration',
                    query_statuses: [
                        'documents_verified',
                        'documents_verification_start',
                        'documents_manual_review',
                    ],
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.log);
        }
    };

    const handlePageChange = (page) => {
        fetchOrders(page);
    };

    return (
        <div className="w-100 h-100">
            <NotificationContainer />
            <div className="container-fluid">
                <div className="cus-flex">
                    <h2>{pageTitle}</h2>{' '}
                </div>

                {merchantId ? (
                    <div className="row">
                        <div className="col">
                            <RegOrdersList
                                data={orderList}
                                selectItem={setSelected}
                                id={selected.id}
                                loading={loading}
                                totalRows={totalRows}
                                handlePageChange={handlePageChange}
                                rowsCountPerPage={perPage}
                                merchant={merchantId}
                                emptyText={'The order list is empty'}
                            />
                        </div>
                    </div>
                ) : (
                    'Please, select merchant first '
                )}
            </div>
        </div>
    );
};

export default DocumentsApproval;
