import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import Wait from '../UI/Wait';
import UserInfoContext from '../../contexts/UserInfoContext';
import getUserRegSettingsList from '../../api/utils/merchant/user/registration/getUserRegSettingsList';
import UserRegSettingsTable from './UserRegSettingsTable';
import getMerchantData from '../../api/utils/merchant/getMerchantData';
import selectUserRegSettings from '../../api/utils/merchant/user/registration/selectUserRegSettings';
import { deleteUserRegSettings } from '../../api/utils/merchant/user/registration/setUserRegSettings';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const UserRegSettingsSelector = () => {
    const [userRegSettings, setUserRegSettings] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingMerchant, setLoadingMerchant] = useState(false);
    const [loadingSettingsChange, setLoadingSettingsChange] = useState(false);

    const {
        app: { id: appId, name: appName },
        merchant: { id: merchantId, name: merchantName },
    } = useContext(UserInfoContext);

    const [merchant, setMerchant] = useState();

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState();

    const pageTitle = `User registration settings ${
        merchantName ? `(${merchantName})` : ''
    }`;

    useEffect(() => {
        if (merchantId) {
            fetchUserRegSettingsList(1);
            fetchMerchant(merchantId);
        } else {
            setMerchant(undefined);
            setUserRegSettings([]);
        }
    }, [merchantId, appId, perPage]);

    const fetchMerchant = async (merchantId) => {
        const resultHandler = (data) => {
            setLoadingMerchant(false);
            if (data && data?.status === 'accept' && data?.merchant) {
                setMerchant(data?.merchant);
            } else {
                setMerchant(null);
            }
        };

        const errorHandler = (error) => {
            setLoadingMerchant(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setMerchant(null);
        };

        try {
            setLoadingMerchant(true);
            const res = await getMerchantData({ id: merchantId }, errorHandler);
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    const fetchUserRegSettingsList = async (page = 1) => {
        setLoading(true);

        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setUserRegSettings(data.user_reg_settings_list);
                setTotalRows(data.total);
            } else {
                setUserRegSettings([]);
                setTotalRows(0);
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
            setUserRegSettings([]);
            setTotalRows(0);
        };

        try {
            const result = await getUserRegSettingsList(
                {
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                    query_merchant:
                        appName && appName === 'server' ? null : merchantId,
                    query_application:
                        appName && appName === 'server' ? null : appId,
                },
                errorHandler
            );
            resultHandler(result);
        } catch (error) {
            errorHandler(error);
        }
    };

    let onRowClick = (row) => {
        handleSelectSettings(row._id);
    };

    const onDeleteClick = async (id) => {
        console.log('onDeleteClick: id == ', id);
        if (id === merchant.user_reg_settings) {
            NotificationManager.error(
                'You cannot remove current user settings'
            );
            return;
        }

        const resultHandler = (data) => {
            setLoading(false);
            if (data && data?.status === 'accept') {
                fetchUserRegSettingsList(1);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setLoading(true);
            const res = await deleteUserRegSettings({ id }, errorHandler);
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
            console.error(error);
        }
    };

    const handlePageChange = (page) => {
        fetchUserRegSettingsList(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    const handleSelectSettings = async (id) => {
        const resultHandler = (data) => {
            setLoadingSettingsChange(false);
            if (data && data?.status === 'accept' && data?.merchant) {
                setMerchant(data?.merchant);
            }
        };

        const errorHandler = (error) => {
            setLoadingSettingsChange(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setLoadingSettingsChange(true);
            const res = await selectUserRegSettings(
                { merchant_id: merchantId, user_reg_settings_id: id },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <div className="row">
                <div className="col-12 col-md-8">
                    <h2>{pageTitle}</h2>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-end align-items-center">
                    <Button
                        variant="outline-primary"
                        as={Link}
                        to={`/merchant/user/registration-settings/create`}
                        className="btn btn-outline-primary mt-1 mb-3"
                    >
                        <i className="bi-plus-square ml-1 mr-2 " /> Create new
                        settings
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <UserRegSettingsTable
                        current={merchant?.user_reg_settings || ''}
                        onSelect={handleSelectSettings}
                        data={userRegSettings}
                        loading={
                            loading || loadingSettingsChange || loadingMerchant
                        }
                        onRowClicked={onRowClick}
                        onDeleteClick={onDeleteClick}
                        rowsCountPerPage={perPage}
                        handleRowsPerPage={handleRowsPerPage}
                        perPageChoices={PER_PAGE_CHOICES}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserRegSettingsSelector;
