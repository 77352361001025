import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SmartForm from '../Form/SmartForm';
import Fields from './Fields';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import IdLists from './IdLists';

const CustomerTypeEditForm = ({ defaultValues, onSubmit = () => {} }) => {
    return (
        <SmartForm
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="p-3"
        >
            <div className="form-row align-items-end">
                <div className="col-10">
                    <InputFieldWithValidation
                        className="mb-3"
                        name="name"
                        label="Customer type name"
                        type="text"
                        required={true}
                    />
                    <InputFieldWithValidation
                        className="mb-3"
                        name="description"
                        label="Customer type description"
                        type="text"
                        required={true}
                    />
                </div>
            </div>
            <Fields />
            <IdLists />
            <br />
            <button className="btn btn-primary">Submit</button>
        </SmartForm>
    );
};

export default CustomerTypeEditForm;
