import { useContext, useEffect, useState } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import getAssetType from './../../api/utils/asset/type/getAssetType';
import { NotificationManager } from 'react-notifications';
import UserInfoContext from '../../contexts/UserInfoContext';
import VoucherEditForm from './VoucherEditForm';

const AddVouchersModal = ({
  title = 'Select vouchers',
  show = false,
  onHide = () => {},
  onConfirm = () => {},
}) => {
  const userInfo = useContext(UserInfoContext);
  const merchantId = userInfo.merchant ? userInfo.merchant.id : null;

  const [voucherType, setVoucherType] = useState(null);

  const [progress, done] = useLoader([voucherType]);

  useEffect(() => {
    if (show) {
      fetchAssetType();
    }
  }, [show]);

  const fetchAssetType = async () => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept' && data.asset_type) {
        setVoucherType(data.asset_type);
      } else {
        setVoucherType(null);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setVoucherType(null);
    };

    getAssetType(
      { query_merchant: merchantId, query_name: 'voucher' },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const onClickHide = () => {
    onHide();
  };

  const onSubmit = (data) => {
    onConfirm(data, null, voucherType);
  };

  return (
    <Modal show={show} onHide={onClickHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {done ? (
          <div>
            <VoucherEditForm
              onSubmit={onSubmit}
              defaultValues={{}}
              assetType={voucherType}
            />
          </div>
        ) : (
          <LoadingBar progress={progress} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddVouchersModal;
