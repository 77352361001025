import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSearchString from '../../utils/useSearchString';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import ProductEditForm from './ProductEditForm';
import getProductTypeList from '../../api/utils/product/type/getProductTypeList';
import { merchantCreateProduct } from '../../api/utils/product/setMerchantProduct';
import BackButton from '../UI/BackButton';

const ProductCreate = () => {
    const history = useHistory();
    const userInfo = useContext(UserInfoContext);
    const { merchant = null } = useSearchString();
    const { application = null } = useSearchString();
    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;
    const appid = application
        ? application
        : userInfo.app
        ? userInfo.app.id
        : null;
    const [product, setProduct] = useState({
        merchant: merchantId ? merchantId : null,
        settings: {
            str_name: 'In new test product',
        },
    });
    const [types, setTypes] = useState([]);

    useEffect(() => {
        getProductTypeList({
            query_merchant: merchantId,
            query_skip: 0,
            query_count: 10000,
        })
            .then(({ types }) => types)
            .then((data) => {
                return data;
            })
            .then(setTypes)
            .then(
                setProduct({
                    ...product,
                    settings: {
                        id_type: types[0]?._id,
                    },
                })
            )
            .catch(console.error);
    }, [merchantId]);

    const onCreate = async (product) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Saved');
                history.goBack();
            } else {
                NotificationManager.error('Error');
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            const res = await merchantCreateProduct(
                { ...product, application: appid, merchant: merchantId },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log(error);
        }
    };

    const pageTitle = `Product ${product._id ? 'edit' : 'new'}`;

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton to="/products" text="Back to list" /> {pageTitle}
            </h2>
            <ProductEditForm
                onSubmit={onCreate}
                merchant={merchantId}
                defaultValues={{ ...product, types: types }}
            />
        </div>
    );
};

export default ProductCreate;
