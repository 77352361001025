import Honkio from '../../Honkio';

export const getMerchantsPreviewsList = (
    options = {},
    errorHandler = () => {}
) => {
    const { query_application, query_search, query_skip, query_count } =
        options;
    return Honkio().mainShop.userFetch('publicmerchantlist', {
        query_application,
        query_search,
        query_skip,
        query_count,
        version: 3,
        action: 'read',
    });
};

// Returns merchants with full info
const getMerchantList = (options = {}, errorHandler = () => {}) => {
    const {
        query_skip = 0,
        query_count = 25,
        query_non_shareholders,
        query_application,
        query_applications,
        query_parent,
        query_search,
        query_info,
        query_deleted,
    } = options;
    return Honkio().mainShop.userFetch(
        'merchantlist',
        {
            query_application,
            query_applications,
            query_parent,
            query_info: true,
            query_skip,
            query_count,
            query_non_shareholders,
            query_search,
            query_info,
            query_deleted,
            version: 3,
            action: 'read',
        },
        errorHandler,
        true
    );
};

export default getMerchantList;
