import clsx from 'clsx';
import { forwardRef } from 'react';

const AnySelector = forwardRef(
    (
        {
            label = 'selector',
            name = 'selector',
            items = [],
            setItem = () => {},
            valueSelector = '',
            nameSelector = '',
            defaultValue,
            resetItem,
            value,
            className,
            disabled = false,
            additional = '',
            showLabel = true,
        },
        ref
    ) => {
        return (
            <>
                {label && showLabel && <label htmlFor={name}>{label}</label>}
                <select
                    ref={ref}
                    className={clsx('custom-select ', className)} //w-auto
                    id={name}
                    name={name}
                    onChange={({ target: { value } }) => setItem(value)}
                    defaultValue={defaultValue}
                    value={value}
                    disabled={disabled}
                >
                    <option className="d-none">{label}</option>
                    {resetItem && (
                        <option key="reset" value={resetItem.value}>
                            {resetItem.name}
                        </option>
                    )}
                    {items?.map((item, index) => (
                        <option
                            key={index}
                            value={item?.[valueSelector] ?? item}
                        >
                            {item?.[nameSelector] ?? item}
                        </option>
                    ))}
                </select>
                <small className="text-muted">{additional}</small>
            </>
        );
    }
);

export default AnySelector;
