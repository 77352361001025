import Honkio from '../../../Honkio';

const setDefaultRecord = (options = {}, errorHandler = () => {}) => {
    const { id, model_name, is_default, merchant } = options;
    return Honkio().mainShop.userFetch(
        'adminappdefaultset',
        {
            id,
            model_name,
            is_default,
            merchant,
            version: 3,
            action: 'update',
        },
        errorHandler
    );
};
export default setDefaultRecord;
