import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';
import languages from '../../../__fixtures__/languages';
import UserInfoContext from '../../../contexts/UserInfoContext';
import getProductTemplate from '../../../api/utils/templateModels/templateProducts/getProductTemplate';
import getProductTypeList from '../../../api/utils/product/type/getProductTypeList';
import cn from 'classnames';
import AmountInput from '../../Products/components/AmountInput';

const LANGUAGES = [{ value: '', name: 'Default' }, ...languages];

const INITIAL_PRODUCT_TEMPLATE = {
    accessibility: 'private',
    settings: {
        str_name: '',
        bool_visible: true,
        str_name__en: '',
        str_name__fi: '',
        str_name__da: '',
        str_name__no: '',
        str_name__sv: '',
        str_ean: '',
        str_vendorcode: '',
        str_unit_type: '',
        id_type: '',
        str_notes: '',
        str_description: '',
        str_description__en: '',
        str_description__fi: '',
        str_description__da: '',
        str_description__no: '',
        str_description__sv: '',
        amount: 5,
        int_bonus_amount: 0.25,
        int_bonus_value: 5,
        str_bonus_type: 'percentage',
        int_vat: 10,
        amount_vat: 0.45,
    },
    application: '',
    merchant: '',
    active: true,
};

const ACCESSABILITY_OPTIONS = [
    {
        label: 'Private',
        value: 'private',
    },
    {
        label: 'Public',
        value: 'public',
    },
];

const ProductTemplateForm = ({
    id = null,
    onSubmit = async () => {},
    submitLoading = false,
}) => {
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [productTemplate, setProductTemplate] = useState(
        INITIAL_PRODUCT_TEMPLATE
    );
    const [productTypes, setProductTypes] = useState([]);

    const [selectedLanguage, setSelectedLanguage] = useState('');

    const [loading, setLoading] = useState(false);

    const postfix = useMemo(
        () => (selectedLanguage ? `__${selectedLanguage}` : ''),
        [selectedLanguage]
    );
    const postfixes = useMemo(
        () => ['', ...languages.map(({ value }) => `__${value}`)],
        []
    );

    useEffect(() => {
        if (merchantId) fetchProductTypes();
    }, [merchantId]);

    useEffect(() => {
        if (id) fetchCurrentProductTemplate(id);
    }, [id]);

    const fetchCurrentProductTemplate = async (id) => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data?.status === 'accept') {
                const productData = data.product_template;
                setProductTemplate(productData);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setProductTemplate({});
        };

        try {
            setLoading(true);
            const res = await getProductTemplate(
                { id, merchant: merchantId, application: appId },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
            setProductTemplate({});
        }
    };

    const fetchProductTypes = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setProductTypes(data.types);
            }
        };

        const errorHandler = (error) => {
            console.log('error getting product types: ', error);
            setProductTypes([]);
        };

        try {
            const res = await getProductTypeList(
                {
                    query_merchant: merchantId,
                    query_skip: 0,
                    query_count: 10000,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        if (!merchantId || !appId) {
            NotificationManager.error(
                'Please, select app and merchant',
                'Error'
            );
            return;
        }

        const submitSettings = _.cloneDeep(productTemplate);
        submitSettings.merchant = merchantId;
        submitSettings.application = appId;

        await onSubmit(submitSettings);
    };

    const handleChangeProductTemplate = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        setProductTemplate((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    const handleChangeSetting = (e) => {
        setProductTemplate((prev) => {
            return {
                ...prev,
                settings: {
                    ...prev.settings,
                    [e.target.name]: e.target.value,
                },
            };
        });
    };

    const handleUpdatedSettings = (newSettings = {}) => {
        setProductTemplate((prev) => ({
            ...prev,
            settings: {
                ...prev.settings,
                ...newSettings,
            },
        }));
    };

    const handleSelectLanguage = (e) => {
        setSelectedLanguage(e.target.value);
    };

    return (
        <Form onSubmit={handleSubmitForm}>
            {/* TODO: Copy from product form */}
            <Row>
                <Form.Group
                    as={Col}
                    xs={12}
                    md={2}
                    className="mb-3"
                    controlId="data.bool_visible"
                >
                    <Form.Label>Visible</Form.Label>
                    <Form.Check
                        type="switch"
                        id="bool_visible"
                        name="bool_visible"
                        label="Is visible"
                        checked={!!productTemplate.settings.bool_visible}
                        onChange={(e) =>
                            handleChangeSetting({
                                target: {
                                    name: 'bool_visible',
                                    value: e.target.checked,
                                },
                            })
                        }
                    />
                    <Form.Text className="text-muted">
                        Change visibility on API
                    </Form.Text>
                </Form.Group>

                <Form.Group
                    as={Col}
                    xs={12}
                    md={6}
                    className="mb-3"
                    controlId="data.language"
                >
                    <Form.Label>Language</Form.Label>
                    <Form.Control
                        as={'select'}
                        aria-label="Language"
                        name="language"
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                        className="custom-select"
                    >
                        {LANGUAGES.map((item, index) => (
                            <option
                                key={`${index}-${item?.value}-${item?.name}-lang-option`}
                                value={item?.value}
                            >
                                {item?.name}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Select language for subject and message
                    </Form.Text>
                </Form.Group>

                <Form.Group
                    as={Col}
                    xs={12}
                    md={4}
                    className="mb-3"
                    controlId="data.accessibility"
                >
                    <Form.Label>Accessibility</Form.Label>
                    <Form.Control
                        as={'select'}
                        aria-label="Accessibility"
                        name="accessibility"
                        value={productTemplate.accessibility}
                        onChange={handleChangeProductTemplate}
                        className="custom-select"
                    >
                        <option value="">Default</option>
                        {ACCESSABILITY_OPTIONS.map((item, index) => (
                            <option
                                key={`${index}-${item?.value}-${item?.label}-access-option`}
                                value={item?.value}
                            >
                                {item?.label}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Change accessibility
                    </Form.Text>
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} xs={12} sm={8} controlId="data.name">
                    <Form.Label>Name</Form.Label>
                    {postfixes.map((current, index) => (
                        <Form.Control
                            key={`product-name-input-${index}-${current}`}
                            className={cn({
                                'd-none': postfix !== current,
                            })}
                            type="text"
                            name={`str_name${current}`}
                            placeholder="The product"
                            value={
                                productTemplate.settings[`str_name${current}`]
                            }
                            onChange={handleChangeSetting}
                        />
                    ))}
                    <Form.Text className="text-muted">
                        Product template name{' '}
                        {selectedLanguage ? `(${selectedLanguage})` : null}
                    </Form.Text>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} controlId="data.ean">
                    <Form.Label>EAN</Form.Label>
                    <Form.Control
                        type="text"
                        name="str_ean"
                        placeholder="The product"
                        value={productTemplate.settings.str_ean}
                        onChange={handleChangeSetting}
                    />
                    <Form.Text className="text-muted">EAN barcode</Form.Text>
                </Form.Group>
            </Row>

            <Row>
                <Col xs={12} sm={6}>
                    <Form.Group controlId="data.vendor_code">
                        <Form.Label>Vendor code</Form.Label>
                        <Form.Control
                            type="text"
                            name="str_vendorcode"
                            placeholder="Vendor code"
                            value={productTemplate.settings.str_vendorcode}
                            onChange={handleChangeSetting}
                        />
                    </Form.Group>

                    <Form.Group controlId="data.unit_type">
                        <Form.Label>Unit type</Form.Label>
                        <Form.Control
                            type="text"
                            name="str_unit_type"
                            placeholder="Unit type"
                            value={productTemplate.settings.str_unit_type}
                            onChange={handleChangeSetting}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="data.product_type">
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                            as={'select'}
                            aria-label="Product type"
                            name="id_type"
                            value={productTemplate.settings?.id_type}
                            onChange={handleChangeSetting}
                            className="custom-select"
                            required={true}
                        >
                            <option value="">
                                Select product template type
                            </option>
                            {productTypes.map((item, index) => (
                                <option
                                    key={`${index}-${item?._id}-${item?.name}-type-option`}
                                    value={item?._id}
                                >
                                    {item?.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>

                <Form.Group as={Col} xs={12} sm={6} controlId="data.notes">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                        type="text"
                        as="textarea"
                        rows={7}
                        name="str_notes"
                        placeholder="Notes"
                        value={productTemplate.settings.str_notes}
                        onChange={handleChangeSetting}
                    />
                    <Form.Text className="text-muted">Optional notes</Form.Text>
                </Form.Group>
            </Row>

            <AmountInput
                settings={productTemplate.settings}
                setSettings={handleUpdatedSettings}
            />

            <Row>
                <Form.Group as={Col} xs={12} controlId="data.description">
                    <Form.Label>Description</Form.Label>

                    {postfixes.map((current, index) => (
                        <Form.Control
                            key={`descriptions-inputs-${index}`}
                            className={cn({
                                'd-none': postfix !== current,
                            })}
                            type="text"
                            as="textarea"
                            rows={4}
                            name={`str_description${current}`}
                            placeholder="Description"
                            value={
                                productTemplate.settings[
                                    `str_description${current}`
                                ]
                            }
                            onChange={handleChangeSetting}
                        />
                    ))}
                    <Form.Text className="text-muted">
                        Description{' '}
                        {selectedLanguage ? `(${selectedLanguage})` : null}
                    </Form.Text>
                </Form.Group>
            </Row>

            <Button
                type="submit"
                variant="primary"
                disabled={loading || submitLoading}
            >
                Submit{' '}
                {loading || submitLoading ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        product="status"
                        aria-hidden="true"
                    />
                ) : null}
            </Button>
        </Form>
    );
};

export default ProductTemplateForm;
