const statusesToNodeList = (data) => {
    return Object.entries(data).map(
        ([
            key,
            {
                name,
                position,
            },
        ]) => ({
            id: key,
            data: {
                label: name               
            },
            position: {
                x: position?.left || 0,
                y: position?.top || 0,
            },
        })
    );
};

export default statusesToNodeList;
