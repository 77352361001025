import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import PricingPlanItem from './PricingPlanItem';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Icon from '../../../UI/Icon';
import { Button } from 'react-bootstrap';

const PricingPlansList = ({ label = 'Pricing' }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pricing_plans',
  });

  const handleAddPricingPlan = () => {
    console.log('handleAddPricingPlan function called...');
    append({
      name: 'new_pricing_plan',
      amount: 5,
    });
  };

  const handleRemovePricingPlan = (index) => {
    remove(index);
  };

  return (
    <>
      <div className="row">
        <div className="col d-flex align-items-center">
          <h4>{label}</h4>
        </div>
        <div>
          <Button
            onClick={handleAddPricingPlan}
            variant="outline"
            className="shadow-none"
          >
            <Icon iconName="bi-plus-circle" size="2" />
          </Button>
        </div>
      </div>
      <ListGroup>
        {fields.length ? (
          fields.map((item, idx) => (
            <PricingPlanItem
              key={item.id}
              item={item}
              idx={idx}
              onDelete={handleRemovePricingPlan}
            />
          ))
        ) : (
          <ListGroup.Item>No plans added yet...</ListGroup.Item>
        )}
      </ListGroup>
    </>
  );
};

export default PricingPlansList;
