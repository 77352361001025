import { useEffect, useMemo, useState, useContext } from 'react';
import {
  Table,
  Card,
  Container,
  Row,
  Col,
  ListGroup,
  Badge,
  Button,
  ButtonGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import UserInfoContext from '../../contexts/UserInfoContext';
import useSearchString from '../../utils/useSearchString';
import getAmlOrderInfo from '../../api/utils/payments/getAmlOrderInfo';
import { errorHandler, resultHandler } from '../../utils/reqHandlers';
import LoadingBar from '../UI/LoadingBar';
import useLoader from '../UI/helpers/useLoader';
import getGreyList from '../../api/utils/greylist/getGreyList';
import ReactTooltip from 'react-tooltip';
import approvePaymentAml from '../../api/utils/payments/approvePaymentAml';
import cn from 'classnames';

const ManualVerify = (options) => {
  const [loading, setLoading] = useState(false);
  const [paymentOrder, setPaymentOrder] = useState();
  const [greyItems, setGreyItems] = useState([]);
  const { order_id, merchant } = useSearchString();
  const userInfo = useContext(UserInfoContext);
  const [progress, done] = useLoader([paymentOrder]);

  let merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;

  useEffect(() => {
    if (order_id) fetchAmlOrderInfo(order_id);
  }, [order_id]);

  useEffect(() => {
    if (paymentOrder) fetchGreylistItems();
  }, [paymentOrder]);

  const fetchAmlOrderInfo = () => {
    setLoading(true);
    getAmlOrderInfo(
      {
        order_id,
      },
      (error) => {
        errorHandler(error);
        setLoading(false);
      }
    ).then((data) => {
      return resultHandler(data, (data) => {
        console.log('data ==', data);
        setPaymentOrder(data?.info);
        setLoading(false);
      });
    });
  };

  const fetchGreylistItems = () => {
    setLoading(true);
    getGreyList(
      {
        merchantId: null,
        appid: null,
        greylistType: 'ids',
        id_list: paymentOrder?.greylist_result?.grey_ids || [],
      },
      (error) => {
        errorHandler(error);
        setLoading(false);
      }
    ).then((res) => {
      return resultHandler(res, (res) => {
        console.log('grey list items res ==', res);
        setGreyItems(res.greylist);
        setLoading(false);
      });
    });
  };

  const handleVerifyManually = (approved) => {
    setLoading(true);

    approvePaymentAml({ order_id, approved }, (error) => {
      if (error) NotificationManager.error('Error');
      setLoading(false);
    }).then((res) => {
      console.log('handleVerifyManually: res ==', res);
      setLoading(false);
      if (res?.status && res.status === 'accept') {
        NotificationManager.success(
          `${approved ? 'Approved' : 'Rejected'} successfully!`
        );
      } else {
        NotificationManager.error(
          `${approved ? 'Approval' : 'Rejection'} failed!`
        );
      }
      fetchAmlOrderInfo();
    });
  };

  const renderGreyItems = () => {
    return greyItems.map((item, i) => (
      <Col key={`grey-item-${item?.id}-${i}`}>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>
              Score
              <Badge pill className="bg-info text-white ml-2" as="div">
                {item?.score}
              </Badge>
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {item._id}
            </Card.Subtitle>
          </Card.Body>
          <ListGroup className="list-group-flush">
            {item?.email ? (
              <>
                <ListGroup.Item>Type: Email</ListGroup.Item>
                <ListGroup.Item
                  className="text-truncate"
                  data-tip
                  data-for="grey-item-email"
                >
                  {item?.email}
                  <ReactTooltip id="grey-item-email" place="top" effect="solid">
                    {item?.email}
                  </ReactTooltip>
                </ListGroup.Item>
                <ListGroup.Item className="text-break">
                  Reason: {item?.email_reason}
                </ListGroup.Item>
              </>
            ) : null}
            {item?.firstname || item?.lastname ? (
              <>
                <ListGroup.Item>Type: Name</ListGroup.Item>
                <ListGroup.Item
                  className="text-truncate"
                  data-tip
                  data-for="grey-item-name"
                >
                  {item?.firstname} {item?.lastname}
                  <ReactTooltip id="grey-item-name" place="top" effect="solid">
                    {item?.firstname} {item?.lastname}
                  </ReactTooltip>
                </ListGroup.Item>
                <ListGroup.Item className="text-break">
                  Reason: {item?.name_reason}
                </ListGroup.Item>
              </>
            ) : null}
            {item?.network ? (
              <>
                <ListGroup.Item>Type: Network</ListGroup.Item>
                <ListGroup.Item
                  className="text-truncate"
                  data-tip
                  data-for="grey-item-network"
                >
                  {item?.network}
                  <ReactTooltip
                    id="grey-item-network"
                    place="top"
                    effect="solid"
                  >
                    {item?.network}
                  </ReactTooltip>
                </ListGroup.Item>
              </>
            ) : null}
            {item?.address ? (
              <>
                <ListGroup.Item>Type: Address</ListGroup.Item>
                <ListGroup.Item className="text-break">
                  {item?.address}
                </ListGroup.Item>
              </>
            ) : null}
          </ListGroup>
        </Card>
      </Col>
    ));
  };

  return (
    <div>
      <NotificationContainer />
      <Container fluid>
        <div className="row justify-content-between">
          <div className="col-9 d-flex justify-content-start align-items-center">
            <h4>Order #{order_id} verification</h4> <br />
          </div>
          <div className="col-3">
            <ButtonGroup
              aria-label="AML verification controls"
              className="float-right"
            >
              <Button
                onClick={() => handleVerifyManually(true)}
                disabled={loading}
              >
                Approve
              </Button>
              <Button
                onClick={() => handleVerifyManually(false)}
                disabled={loading}
              >
                Reject
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Container>

      {done ? (
        <div className="container">
          <h4>Order</h4>
          <Table striped hover size="sm">
            <tbody>
              <tr>
                <td>Status</td>
                <td>{paymentOrder?.order?.status}</td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>
                  {paymentOrder?.order?.currency === 'EUR' ? '€' : ''}
                  {paymentOrder?.order?.amount}
                </td>
              </tr>
              {paymentOrder?.order?.description ? (
                <tr>
                  <td>Description</td>
                  <td>{paymentOrder?.order?.description}</td>
                </tr>
              ) : null}
            </tbody>
          </Table>

          <h4>User</h4>
          <Table striped hover size="sm">
            <tbody>
              <tr>
                <td>Email</td>
                <td>
                  <Link to={`/user/edit?id=${paymentOrder?.user?._id}`}>
                    {paymentOrder?.user?.email}
                  </Link>
                </td>
              </tr>
              <tr>
                <td>Name</td>
                <td>
                  {paymentOrder?.user?.settings?.str_firstname}{' '}
                  {paymentOrder?.user?.settings?.str_lastname}
                </td>
              </tr>
              <tr>
                <td>Phone</td>
                <td className="text-break">
                  {paymentOrder?.user?.settings?.str_telephone}
                </td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{paymentOrder?.user?.settings?.str_address1} </td>
              </tr>
              {paymentOrder?.user?.settings?.str_address2 ? (
                <tr>
                  <td>Address 2</td>
                  <td>{paymentOrder?.user?.settings?.str_address2}</td>
                </tr>
              ) : null}
              <tr>
                <td>SSN</td>
                <td>{paymentOrder?.user?.settings?.str_ssn}</td>
              </tr>
              <tr>
                <td>IBAN</td>
                <td>{paymentOrder?.user?.IBAN_Number}</td>
              </tr>
              <tr>
                <td>Bank</td>
                <td>{paymentOrder?.user?.bank_name}</td>
              </tr>
              <tr>
                <td>Born</td>
                <td>{paymentOrder?.user?.settings?.birth_date}</td>
              </tr>
              <tr>
                <td>Home location</td>
                <td>
                  {paymentOrder?.user?.settings?.home_location &&
                  paymentOrder?.user?.settings?.home_location?.length
                    ? '' +
                      paymentOrder?.user?.settings?.home_location?.[0] +
                      ', ' +
                      paymentOrder?.user?.settings?.home_location?.[1]
                    : null}
                </td>
              </tr>
              <tr>
                <td>Home address</td>
                <td>{paymentOrder?.user?.settings?.home_address}</td>
              </tr>
            </tbody>
          </Table>
          <hr />

          <h4>Device</h4>
          <Table striped hover size="sm">
            <thead>
              <tr>
                <th>Field name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>User agent</td>
                <td>{paymentOrder?.device.name}</td>
              </tr>
              <tr>
                <td>Ip address</td>
                <td>{paymentOrder?.device?.creation_ip}</td>
              </tr>
              <tr>
                <td>Created</td>
                <td>{paymentOrder?.device?.creation_date}</td>
              </tr>
            </tbody>
          </Table>
          <hr />

          <h4>Grey list result</h4>
          <Table striped hover size="sm">
            <tbody>
              <tr
                className={cn({
                  'bg-danger text-white':
                    paymentOrder?.greylist_result.status === 'reject',
                })}
              >
                <td>Status:</td>
                <td>{paymentOrder?.greylist_result.status}</td>
              </tr>
              <tr>
                <td>Score:</td>
                <td>{paymentOrder?.greylist_result.score}</td>
              </tr>
              {paymentOrder?.greylist_result?.reason ? (
                <tr>
                  <td>Reason:</td>
                  <td>{paymentOrder?.greylist_result.reason}</td>
                </tr>
              ) : null}
            </tbody>
          </Table>
          <Container fluid>
            <Row style={{ gap: '2rem' }}>{renderGreyItems()}</Row>{' '}
          </Container>
          <hr />

          <h4>Velocity result</h4>
          <Table striped hover size="sm">
            <tbody>
              <tr
                className={cn({
                  'bg-danger text-white':
                    paymentOrder?.velocity_result.status === 'reject',
                })}
              >
                <td>Status:</td>
                <td>{paymentOrder?.velocity_result.status}</td>
              </tr>
              <tr>
                <td>Total amount:</td>
                <td>
                  €
                  {paymentOrder?.velocity_result?.orders_sum
                    ? parseFloat(
                        String(
                          paymentOrder?.velocity_result?.orders_sum || '0.00'
                        )
                      ).toFixed(2)
                    : '0.00'}
                </td>
              </tr>
              {paymentOrder?.velocity_result?.reason ? (
                <tr>
                  <td>Reason:</td>
                  <td>{paymentOrder?.velocity_result.reason}</td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </div>
      ) : (
        <LoadingBar progress={progress} />
      )}
    </div>
  );
};

export default ManualVerify;
