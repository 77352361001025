import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';

const RootInfo = ({ data }) => {
  const { entityType, values } = data;

  return <h4>This is the registered merchant's company</h4>;
};

export default RootInfo;
