import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Wait from '../UI/Wait';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';
import { NotificationManager } from 'react-notifications';

function convertTZ(date, tzString) {
    const tzdate = new Date(date + ' GMT');
    return tzdate.toLocaleString('fi-FI', { timeZone: tzString });
}

const CustomersList = ({
    data,
    loading,
    emptyText,
    rowsCountPerPage,
    totalRows,
    handlePageChange,
    merchant,
    handleRowsPerPage,
    perPageChoices = [25],
    handleSort = () => {},
    onDeleteClick = () => {},
}) => {
    const history = useHistory();

    const columns = useMemo(
        () => [
            {
                name: 'Name',
                init_name: 'name',
                grow: 2,
                sortable: true,
                selector: (row) => row.name,
                cell: ({ name }) => name,
            },
            {
                name: 'User',
                init_name: 'user_id',
                sortable: true,
                selector: (row) => row.user_id,
                grow: 2,
                cell: ({ user_id }) =>
                    user_id ? (
                        <Link to={`/user/edit?id=${user_id}`}>{user_id}</Link>
                    ) : null,
            },
            {
                name: 'Type',
                init_name: 'type',
                sortable: true,
                selector: (row) => row.type,
                grow: 1,
                cell: ({ type, type_reference }) =>
                    type && type_reference ? (
                        <Link to={`/customer/type/edit?id=${type_reference}`}>
                            {type}
                        </Link>
                    ) : (
                        type
                    ),
            },
            {
                name: 'Created',
                init_name: 'created_at',
                grow: 1,
                sortable: true,
                selector: (row) => row.created_at,
                cell: ({ created_at }) =>
                    convertTZ(created_at, 'Europe/Helsinki'),
            },
            {
                name: 'Actions',
                grow: 0,
                right: true,
                cell: ({ _id }) => (
                    <div className="d-flex">
                        <Link to={`/customer/edit?id=${_id}`}>
                            <Icon iconName="bi-pen" />
                        </Link>
                        <div
                            onClick={() => {
                                onDeleteClick(_id);
                            }}
                            data-tip
                            data-for="deleteTip"
                        >
                            <Icon
                                iconName="btn bi-trash"
                                color="var(--danger)"
                            />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </div>
                    </div>
                ),
            },
        ],
        [loading]
    );

    let onRowClick = (row) => {
        history.push(`/customer/edit?id=${row._id}`);
    };

    return (
        <DataTable
            dense
            striped
            highlightOnHover
            pointerOnHover
            data={data}
            columns={columns}
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            paginationServer
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            progressPending={loading}
            noDataComponent={emptyText}
            sortServer
            onSort={handleSort}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
            onRowClicked={onRowClick}
            onChangeRowsPerPage={handleRowsPerPage}
        />
    );
};

export default CustomersList;
