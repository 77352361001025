import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import OrderModal from './OrderModal';
import { Link } from 'react-router-dom';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import Wait from '../UI/Wait';
import Icon from '../UI/Icon';

const AmlOrdersList = ({
  data,
  loading,
  emptyText,
  rowsCountPerPage,
  handleRowsPerPage,
  totalRows,
  handlePageChange,
  merchant,
  handleStartRefund,
  handleIssue,
}) => {
  const [showOrderPreview, setShowOrderPreview] = useState(false);
  const handleShowOrderPreview = () => setShowOrderPreview(true);
  const handleCloseOrderPreview = () => setShowOrderPreview(false);
  const [currenOrder, setCurrentOrder] = useState();

  const columns = useMemo(
    () => [
      { name: 'Created', grow: 2, cell: ({ creation_date }) => creation_date },
      {
        name: 'Email',
        grow: 2,
        cell: ({ user_info }) => user_info?.email || 'info@trumo.io',
      },
      {
        name: 'Phone',
        grow: 2,
        cell: ({ user_info }) => user_info?.phone || '-',
      },
      { name: 'Bank', grow: 1, cell: ({ customer_bank }) => customer_bank },
      { name: 'Amount, €', grow: 1, cell: ({ amount }) => amount },
      {
        name: 'Actions',
        grow: 1,
        cell: (row) => (
          <div>
            {row.order_type === 'PAYMENT_ORDER_TYPE' &&
            row.status !== 'refunded' ? (
              <>
                <Link
                  data-tip
                  data-for="manual_verify"
                  to={`/greylist_manualverify?order_id=${row._id}`}
                >
                  <Icon iconName="bi-eyeglasses" />
                  <ReactTooltip id="manual_verify" place="top" effect="solid">
                    Verify greylist score manually
                  </ReactTooltip>
                </Link>

                <span
                  data-tip
                  data-for="refund"
                  onClick={() => handleStartRefund(row)}
                >
                  <Icon iconName="bi-wallet2" />
                  <ReactTooltip id="refund" place="top" effect="solid">
                    Refund
                  </ReactTooltip>
                </span>

                {row.status !== 'issued' ? (
                  <span
                    data-tip
                    data-for="issue"
                    onClick={() => handleIssue(row)}
                  >
                    <Icon iconName="bi-ticket-perforated" />
                    <ReactTooltip id="issue" place="top" effect="solid">
                      Issue
                    </ReactTooltip>
                  </span>
                ) : null}
              </>
            ) : null}
          </div>
        ),
      },
    ],
    [loading]
  );

  let onRowClick = (row) => {
    setCurrentOrder(row);
    handleShowOrderPreview();
  };

  return (
    <div>
      <DataTable
        dense
        striped
        highlightOnHover
        data={data}
        columns={columns}
        pagination={true}
        paginationPerPage={rowsCountPerPage}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        onChangeRowsPerPage={(val) => handleRowsPerPage(val)}
        paginationServer
        onChangePage={handlePageChange}
        paginationTotalRows={totalRows}
        progressPending={loading}
        noDataComponent={emptyText}
        progressComponent={
          <div className="p-5">
            <Wait />
          </div>
        }
        onRowClicked={onRowClick}
      />
      {currenOrder && (
        <OrderModal
          orderId={currenOrder._id}
          show={showOrderPreview}
          onHide={handleCloseOrderPreview}
        ></OrderModal>
      )}
    </div>
  );
};

export default AmlOrdersList;
