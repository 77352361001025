import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import Wait from '../UI/Wait';
import OrderModal from './OrderModal';
import { Link } from 'react-router-dom';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';

const OrderList = ({
    data,
    loading,
    emptyText,
    rowsCountPerPage,
    totalRows,
    handlePageChange,
    merchant,
    handleRowsPerPage,
    perPageChoices = [25],
}) => {
    const [showOrderPreview, setShowOrderPreview] = useState(false);
    const handleShowOrderPreview = () => setShowOrderPreview(true);
    const handleCloseOrderPreview = () => setShowOrderPreview(false);
    const [currenOrder, setCurrentOrder] = useState();

    const columns = useMemo(
        () => [
            {
                name: 'Created',
                grow: 2,
                cell: ({ creation_date }) => creation_date,
            },
            { name: 'Title', grow: 2, cell: ({ title }) => title },
            {
                name: 'Owner',
                grow: 2,
                cell: ({ user_owner }) => user_owner || '',
            },
            { name: 'Status', grow: 1, cell: ({ status }) => status },
            { name: 'Model', grow: 1, cell: ({ model }) => model },
            {
                name: 'Actions',
                grow: 0,
                right: true,
                cell: ({ _id }) => (
                    <div>
                        <Link
                            to={`/orders?parent=${_id}`}
                            data-tip
                            data-for="orderChildren"
                        >
                            <Icon iconName="bi-diagram-3" />
                            <ReactTooltip
                                id="orderChildren"
                                place="top"
                                effect="solid"
                            >
                                Order children orders
                            </ReactTooltip>
                        </Link>
                        <Link
                            to={`/order/status/history?order_id=${_id}`}
                            data-tip
                            data-for="statusesHistory"
                        >
                            <Icon iconName="bi-clock-history" />
                            <ReactTooltip
                                id="statusesHistory"
                                place="top"
                                effect="solid"
                            >
                                Statuses
                            </ReactTooltip>
                        </Link>
                    </div>
                ),
            },
        ],
        [loading]
    );

    let onRowClick = (row) => {
        setCurrentOrder(row);
        handleShowOrderPreview();
    };

    return (
        <div>
            <DataTable
                dense
                striped
                highlightOnHover
                data={data}
                columns={columns}
                pagination={true}
                paginationPerPage={rowsCountPerPage}
                paginationRowsPerPageOptions={perPageChoices}
                paginationServer
                onChangePage={handlePageChange}
                paginationTotalRows={totalRows}
                progressPending={loading}
                noDataComponent={emptyText}
                progressComponent={
                    <div className="p-5">
                        <Wait />
                    </div>
                }
                onRowClicked={onRowClick}
                onChangeRowsPerPage={handleRowsPerPage}
            />
            {currenOrder && (
                <OrderModal
                    orderId={currenOrder._id}
                    show={showOrderPreview}
                    onHide={handleCloseOrderPreview}
                ></OrderModal>
            )}
        </div>
    );
};

export default OrderList;
