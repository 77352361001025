import { useContext, useState, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import BellContext from '../../contexts/BellContext';
import Icon from '../UI/Icon';
import cn from 'classnames';
import './bell.css';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

const NotificationMenu = ({ className }) => {
  const bellInfo = useContext(BellContext);
  const history = useHistory();

  const bellRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const getNewCount = () => {
    let cnt = 0;
    if (!_.isEmpty(bellInfo?.data || {})) {
      cnt += bellInfo.data?.count_acc_approval;
      cnt += bellInfo.data?.count_docs_approval;
    }
    if (bellInfo?.errors?.length) {
      cnt += bellInfo.errors.length;
    }
    return cnt;
  };

  const renderBellData = () => {
    let result = [];
    if (!_.isEmpty(bellInfo?.data || {})) {
      result.push(
        <Dropdown.Item
          key="bell-accounts-approval"
          as={'div'}
          onClick={() => history.push('/registrations/merchant/approval')}
          className={cn('crsr', 'd-flex justify-content-between')}
        >
          <div className="mr-3">Accounts approval: </div>
          <div>{bellInfo.data.count_acc_approval}</div>
        </Dropdown.Item>
      );
      result.push(
        <Dropdown.Item
          key="bell-documents-review"
          as={'div'}
          onClick={() =>
            history.push('/registrations/merchant/documents_review')
          }
          className={cn('crsr', 'd-flex justify-content-between')}
        >
          <div className="mr-3">Docs review:</div>
          <div>{bellInfo.data.count_docs_approval}</div>
        </Dropdown.Item>
      );
    }
    if (bellInfo?.errors?.length) {
      bellInfo.errors.forEach((err, i) => {
        result.push(
          <Dropdown.Item key={`bell-error-${i}`}>{err}</Dropdown.Item>
        );
      });
    }
    return result;
  };

  const handleBellFocus = () => {
    setIsFocused(true);
    setIsRead(true);
  };

  return (
    <div className={className}>
      <Dropdown>
        <Dropdown.Toggle
          variant="otline-light"
          id="dropdown-bell"
          className={cn(
            'bg-transparent border-0 outline-0 shadow-none',
            'bell'
          )}
          ref={bellRef}
          onFocus={handleBellFocus}
          onBlur={() => setIsFocused(false)}
        >
          {isFocused ? (
            <Icon iconName="bi-bell-fill" />
          ) : (
            <Icon iconName="bi-bell" />
          )}
          {!isRead && getNewCount() ? (
            <div className="bell-notif-cnt">{getNewCount()}</div>
          ) : null}
        </Dropdown.Toggle>

        <Dropdown.Menu>{renderBellData()}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default NotificationMenu;
