import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import Wait from '../../../UI/Wait';
import { Link } from 'react-router-dom';
import Icon from '../../../UI/Icon';
import RegisterOrderModal from './RegisterOrderModal';

const RegistrationsList = ({
    data,
    loading,
    emptyText,
    rowsCountPerPage,
    handleRowsPerPage,
    totalRows,
    handlePageChange,
}) => {
    const [showOrderPreview, setShowOrderPreview] = useState(false);
    const handleShowOrderPreview = () => setShowOrderPreview(true);
    const handleCloseOrderPreview = () => setShowOrderPreview(false);
    const [currenOrder, setCurrentOrder] = useState();

    const columns = useMemo(
        () => [
            {
                name: 'Created',
                grow: 2,
                cell: ({ creation_date }) => creation_date,
            },
            {
                name: 'Email',
                grow: 2,
                cell: ({ user_info }) =>
                    user_info?.email || <span>&mdash;</span>,
            },
            {
                name: 'IP',
                grow: 1,
                cell: ({ user_info }) =>
                    user_info?.ip_address || <span>&mdash;</span>,
            },
            {
                name: 'Phone',
                grow: 1,
                cell: ({ user_info }) =>
                    user_info?.phone || <span>&mdash;</span>,
            },
            {
                name: 'Name',
                grow: 1,
                cell: ({ user_info }) =>
                    user_info?.firstname && user_info?.lastname ? (
                        `${user_info.firstname} ${user_info.lastname}`
                    ) : (
                        <span>&mdash;</span>
                    ),
            },
            {
                name: 'Actions',
                grow: 1,
                right: true,
                cell: (row) => (
                    <div>
                        <Link
                            to={`/orders/view?order=${row?._id}`}
                            data-tip
                            data-for="viewOrderTip"
                        >
                            <Icon iconName="bi-info-circle" />
                            <ReactTooltip
                                id="viewOrdertip"
                                place="top"
                                effect="solid"
                            >
                                View Order
                            </ReactTooltip>
                        </Link>
                    </div>
                ),
            },
        ],
        [loading]
    );

    let onRowClick = (row) => {
        setCurrentOrder(row);
        handleShowOrderPreview();
    };

    return (
        <div>
            <DataTable
                dense
                striped
                highlightOnHover
                data={data}
                columns={columns}
                pointerOnHover
                pagination={true}
                paginationPerPage={rowsCountPerPage}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={(val) => handleRowsPerPage(val)}
                paginationServer
                onChangePage={handlePageChange}
                paginationTotalRows={totalRows}
                progressPending={loading}
                noDataComponent={emptyText}
                progressComponent={
                    <div className="p-5">
                        <Wait />
                    </div>
                }
                onRowClicked={onRowClick}
            />
            {currenOrder && (
                <RegisterOrderModal
                    orderId={currenOrder._id}
                    show={showOrderPreview}
                    onHide={handleCloseOrderPreview}
                />
            )}
        </div>
    );
};

export default RegistrationsList;
