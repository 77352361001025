import React, { useState, useEffect, useContext } from 'react';
import getAdminRolesList from '../../api/utils/role/getAdminRolesList';
import getServerAppList from '../../api/utils/app/getServerAppList';
import Select, { components } from 'react-select';
import { NotificationManager } from 'react-notifications';
import useLoader from '../UI/helpers/useLoader';

const groupStyles = {
    border: `2px dotted red !important`,
    borderRadius: '5px',
    background: '#f2fcff',
};

const CustomOption = (props) => {
    return (
        <>
            <components.Option {...props}>{props.children}</components.Option>
        </>
    );
};

const GroupHeading = (props) => {
    return (
        <div style={groupStyles}>
            <components.GroupHeading
                {...props}
                onClick={() => console.log('heading clicked')}
            />
        </div>
    );
};

/**
 *
 * @param {*} `selectedRoles` array of strings with `role._id`s
 * @returns
 */
const RoleSelect = ({
    selectedRoles,
    setSelectedRoles,
    isPlain = true,
    helperText,
}) => {
    const [roleOptions, setRoleOptions] = useState([]);

    const [allRoles, setAllRoles] = useState([]);
    const [allApps, setAllApps] = useState([]);

    const [selectedRolesList, setSelectedRolesList] = useState([]);

    const [progress, done] = useLoader([allRoles, allApps]);

    useEffect(() => {
        fetchRoles();
        fetchAllApps();
    }, []);

    const fetchRoles = async () => {
        const resultHandler = (res) => {
            if (res?.status === 'accept') {
                setAllRoles(res.roles);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        getAdminRolesList({ query_skip: 0, query_count: 10000 }, errorHandler)
            .then(resultHandler)
            .catch(errorHandler);
    };

    const fetchAllApps = async () => {
        const resultHandler = (res) => {
            if (res?.status === 'accept') {
                setAllApps(res.applications);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        getServerAppList({}, errorHandler)
            .then(resultHandler)
            .catch(errorHandler);
    };

    const generateRoleOptions = (apps, roles) => {
        let resultOptions = [];
        apps.forEach((app) => {
            const appId = app._id;
            const appName = app.name;
            const appGroupOpt = {
                appId: appId,
                label: appName,
                options: [],
            };
            roles.forEach((role) => {
                if (role?.application === appId) {
                    const newOpt = {
                        application: appId,
                        appName: appName,
                        value: role._id,
                        label: getOptName(role, appName),
                        searchStr: `${role?.name} ${role._id}`,
                        merchant: role.merchant,
                    };

                    appGroupOpt.options.push(newOpt);
                }
            });
            resultOptions.push(appGroupOpt);
        });

        const remainingGroups = roles
            .filter((item) => !item?.application)
            .map((item) => ({
                application: null,
                appName: 'No app',
                value: item._id,
                label: item.name,
                searchStr: `${item?.name} ${item._id}`,
                merchant: item.merchant,
            }));
        resultOptions.push({
            appId: null,
            label: 'Not attached to app',
            options: [...remainingGroups],
        });

        return resultOptions;
    };

    const generateSelectedRoleOptions = (apps, roles) => {
        let resultOptions = [];
        roles.forEach((role) => {
            const currentApp = apps.find(
                (item) => item._id === role?.application
            );

            const newOpt = {
                application: currentApp._id,
                appName: currentApp.name,
                value: role._id,
                label: getOptName(role, currentApp.name),
                searchStr: `${role?.name} ${role._id}`,
                merchant: role.merchant,
            };

            resultOptions.push(newOpt);
        });
        return resultOptions;
    };

    const loadSelectedRolesList = (selected = []) => {
        const currentRoles = allRoles.filter((item) =>
            selected.includes(item._id)
        );

        const newSelectedRoles = generateSelectedRoleOptions(
            allApps,
            currentRoles
        );

        if (newSelectedRoles?.length) {
            setSelectedRolesList((prev) => newSelectedRoles);
        } else {
            setSelectedRolesList([]);
        }
    };

    useEffect(() => {
        if (allApps?.length && allRoles?.length) {
            const opts = generateRoleOptions(allApps, allRoles);

            setRoleOptions(opts);
        }
    }, [allRoles, allApps]);

    useEffect(() => {
        if (!selectedRoles?.length) setSelectedRolesList([]);
        if (
            selectedRoles?.length &&
            allApps?.length &&
            allRoles?.length &&
            isPlain
        ) {
            loadSelectedRolesList(selectedRoles);
        } else {
            if (!isPlain) setSelectedRolesList(selectedRoles);
        }
    }, [selectedRoles, allRoles, allApps]);

    const getOptName = (roleOpt, appName) => {
        const name =
            roleOpt?.display_name ||
            roleOpt?.display_name__en ||
            roleOpt?.name ||
            roleOpt?._id ||
            '';

        const desc = roleOpt?.description || roleOpt?.description__en || '';

        return (
            <span
                title={`${appName} - ${desc ? desc : 'no description'}`}
            >{`${name}`}</span>
        );
    };

    const handleSetSelectedRoles = (val) => {
        if (isPlain) {
            setSelectedRoles(val.map((item) => item.value));
        } else {
            setSelectedRoles(val);
        }
    };
    return (
        <div className="form-group mt-3">
            <label htmlFor="roles-select">
                Roles {!done ? ' (loading...)' : null}
            </label>
            <Select
                isDisabled={!done}
                id="roles-select"
                value={selectedRolesList}
                onChange={handleSetSelectedRoles}
                isMulti
                options={roleOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={'— Select roles —'}
                isLoading={!done}
                // components={GroupHeading}
                components={{
                    GroupHeading: GroupHeading,
                    Option: CustomOption,
                }}
                menuPortalTarget={document.body}
                styles={{
                    groupHeading: (base) => ({
                        ...base,
                        flex: '1 1',
                        color: 'var(--white)',
                        backgroundColor: 'var(--primary)',
                        margin: 0,
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        padding: '0.5rem',
                        zIndex: 1000,
                    }),
                }}
                isSearchable
                getOptionValue={(option) => `${option['searchStr']}`}
            />
            {helperText ? (
                <small id="emailHelp" className="form-text text-muted">
                    {helperText}
                </small>
            ) : null}
        </div>
    );
};

export default RoleSelect;
