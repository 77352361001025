import Honkio from '../../../Honkio';

const getMerchantInvoices = (options = {}, errorHandler = {}) => {
  const { merchant, query_skip = 0, query_count } = options;
  return Honkio().mainShop.userFetch(
    'merchantpaymentinvoicelist',
    {
      query_merchant: merchant,
      query_info: true,
      query_sort: [{ due_date: -1 }],
      query_skip,
      query_count,
      query_type: 'subscription',
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getMerchantInvoices;
