import React, { useState } from 'react';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import JobForm from './JobForm';
import {
    createSchedulerJob,
    updateSchedulerJob,
} from '../../../api/utils/scheduler/setJob';
import useSearchString from '../../../utils/useSearchString';

const EditJob = () => {
    const { job_id } = useSearchString();

    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const handleSubmitForm = async (data) => {
        let dt = _.cloneDeep(data);

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description);
        };

        console.log('handleSubmitForm: data ==', data);

        setLoading(true);
        const res = await updateSchedulerJob(dt, errorHandler);

        setLoading(false);
        if (res && res?.status === 'accept') {
            NotificationManager.success('Job updated');
        }
    };

    return (
        <JobForm
            job_id={job_id}
            onSubmit={handleSubmitForm}
            submitLoading={loading}
        />
    );
};

export default EditJob;
