import Honkio from '../../../Honkio';

const getMerchantRegAppSettings = (options = {}, errorHandler = {}) => {
  const { applicationId } = options;
  return Honkio().mainShop.userFetch(
    'adminsettingsmerchantregisterappget',
    {
      application_id: applicationId,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getMerchantRegAppSettings;
