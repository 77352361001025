import React, { useState } from 'react';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import _ from 'lodash';
import createAccessRule from '../../api/utils/accessRules/createAccessRule';
import { useHistory } from 'react-router-dom';
import AccessRuleDocModal from './components/AccessRuleDocModal';
import AccessRuleForm from './components/AccessRuleForm';

const CreateAccessRule = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [ruleId, setRuleId] = useState(null);

  const [showDocModal, setShowDocModal] = useState(false);

  const handleSubmitForm = async (data) => {
    let dt = _.cloneDeep(data);

    const errorHandler = (error) => {
      setLoading(false);
      NotificationManager.error(error.description);
    };

    setLoading(true);
    const res = await createAccessRule(dt, errorHandler);

    setLoading(false);
    if (res && res?.status === 'accept') {
      NotificationManager.success('Access rule created');
    }

    if (res?.access_rule?._id) {
      setRuleId(res.access_rule._id);
      NotificationManager.info('Redirecting...');
      setTimeout(() => {
        history.push(`/accessrules/edit?rule_id=${res.access_rule?._id}`);
      }, 1500);
    }
  };

  return (
    <div>
      <NotificationContainer />

      <AccessRuleForm onSubmit={handleSubmitForm} submitLoading={loading} />

      <AccessRuleDocModal
        show={showDocModal}
        onHide={() => setShowDocModal(false)}
      />
    </div>
  );
};

export default CreateAccessRule;
