import React, { useState, useEffect, useContext } from 'react';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import _ from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BackButton from '../../UI/BackButton';
import useSearchString from '../../../utils/useSearchString';
import OrderModelEditForm from '../../OrderModel/OrderModelEditForm';
import { updateOrderModelTemplate } from '../../../api/utils/templateModels/templateOrderModels/setOrderModelTemplate';
import getOrderModelTemplate from '../../../api/utils/templateModels/templateOrderModels/getOrderModelTemplate';
import cn from 'classnames';
import { Form } from 'react-bootstrap';
import UserInfoContext from '../../../contexts/UserInfoContext';

const INITIAL_ORDER_MODEL_TEMPLATE = {
    name: 'test',
    statuses: [],
};

const OrderModelTemplateEdit = () => {
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const { id } = useSearchString();

    const [orderModelTemplate, setOrderModelTemplate] = useState(
        INITIAL_ORDER_MODEL_TEMPLATE
    );
    const [templateIsActive, setTemplateIsActive] = useState(false);

    const [submitLoading, setSubmitLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (id) fetchOrderModelTemplate(id);
    }, [id]);

    const fetchOrderModelTemplate = async (template_id) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setOrderModelTemplate(data.ordermodel_template);
                setTemplateIsActive(!!data.ordermodel_template?.active);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setOrderModelTemplate(INITIAL_ORDER_MODEL_TEMPLATE);
            setTemplateIsActive(false);
        };

        try {
            const res = await getOrderModelTemplate(
                { id: template_id, application: appId, merchant: merchantId },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    const handleSubmitForm = async (submitData) => {
        const resultHandler = (data) => {
            setSubmitLoading(false);
            if (data && data.status === 'accept') {
                setOrderModelTemplate(data.ordermodel_template);
                NotificationManager.success(
                    'Updated successfully',
                    'Success',
                    3000
                );
            }
        };

        const errorHandler = (error) => {
            setSubmitLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setSubmitLoading(true);
            const finalData = _.cloneDeep(submitData);
            finalData.id = id;
            finalData.application = appId;
            finalData.merchant = merchantId;
            finalData.active = templateIsActive;
            console.log('submitData == ', submitData);
            const res = await updateOrderModelTemplate(finalData, errorHandler);
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton
                    to="/template-models/ordermodels"
                    text="Back to list"
                />
                Edit order model template
            </h2>

            <Form.Group className="mb-3" controlId="data.active">
                <Form.Label>Active</Form.Label>
                <Form.Check // prettier-ignore
                    type="switch"
                    name="active"
                    id="active"
                    checked={templateIsActive}
                    onChange={(e) => setTemplateIsActive(e.target.checked)}
                />
            </Form.Group>
            <div className={cn('d-flex')} style={{ height: '700px' }}>
                <OrderModelEditForm
                    onSave={handleSubmitForm}
                    orderModel={orderModelTemplate}
                />
            </div>
        </div>
    );
};

export default OrderModelTemplateEdit;
