import { useMemo } from 'react';
import InputFieldArray from '../Form/elements/FieldArray/InputFieldArray';
import SelectFieldArray from '../Form/elements/FieldArray/SelectFieldArray';

const Field = ({ field = {}, index, actions = {}, control }) => {
  const types = useMemo(() => ['str', 'float', 'int', 'bool', 'list'], []);

  return (
    <div className="form-row align-items-end">
      <InputFieldArray
        label={index === 0 ? 'Name' : null}
        className="col-3"
        name={`fields.${index}.name`}
        defaultValue={field.name}
      />
      <SelectFieldArray
        label={index === 0 ? 'Type' : null}
        className="col-2"
        name={`fields.${index}.type`}
        defaultValue={field.type}
        options={types}
      />
      <InputFieldArray
        label={index === 0 ? 'Label' : null}
        className="col-5"
        name={`fields.${index}.label`}
        defaultValue={field.description}
      />
      <div className="ml-auto mr-3 col-1">
        <button
          className="btn btn-light"
          onClick={() => {
            actions.remove(index);
          }}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default Field;
