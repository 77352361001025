import React, { Component } from 'react';
import 'react-notifications/dist/react-notifications.css';
import 'react-notifications/dist/react-notifications.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataTable from 'react-data-table-component';

const Circular = () => (
  <div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>
);

const PAGE_ITEMS_COUNT = 20;

class PageableDatatablesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  render() {
    return (
      <DataTable
        data={this.props.items}
        progressComponent={<Circular />}
        pagination
        progressPending={this.props.progressPending}
        paginationServer={true}
        paginationPerPage={
          this.props.itemsPerPage ? this.props.itemsPerPage : PAGE_ITEMS_COUNT
        }
        paginationTotalRows={this.props.total}
        onChangePage={(page) => {
          page--;
          this.setState({ page: page });
          this.props.loadItems(page);
        }}
        noDataComponent={<span>List is empty</span>}
        paginationComponentOptions={{
          rowsPerPageText: 25,
          rangeSeparatorText: ', ',
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: 'All',
        }}
        paginationRowsPerPageOptions={[
          this.props.itemsPerPage ? this.props.itemsPerPage : PAGE_ITEMS_COUNT,
        ]}
        {...this.props}
      />
    );
  }
}

export default PageableDatatablesComponent;
