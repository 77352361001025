import { useMemo } from 'react';
import InputFieldArray from '../Form/elements/FieldArray/InputFieldArray';
import SelectFieldArray from '../Form/elements/FieldArray/SelectFieldArray';

const ListOfIds = ({ field = {}, index, actions = {}, control }) => {
  const types = useMemo(() => ['list'], []);
  const id_models = useMemo(() => ['user', 'shop', 'merchant'], []);
  const required = useMemo(() => ['yes', 'no'], []);

  return (
    <div className="form-row align-items-end mb-2">
      <InputFieldArray
        label={index === 0 ? 'Name' : null}
        className="col-3"
        name={`id_lists.${index}.name`}
        defaultValue={field.name}
      />
      <SelectFieldArray
        label={index === 0 ? 'Type' : null}
        className="col-2"
        name={`id_lists.${index}.type`}
        defaultValue={field.type}
        options={types}
      />
      <SelectFieldArray
        label={index === 0 ? 'Model' : null}
        className="col-2"
        name={`id_lists.${index}.id_model`}
        defaultValue={field.id_model}
        options={id_models}
      />
      <InputFieldArray
        label={index === 0 ? 'Description' : null}
        className="col-2"
        name={`id_lists.${index}.description`}
        defaultValue={field.description}
      />
      <SelectFieldArray
        label={index === 0 ? 'Required' : null}
        className="col-0"
        name={`id_lists.${index}.required`}
        defaultValue={field.required}
        options={required}
      />
      <div className="ml-auto mr-2 col-1">
        <button
          className="btn btn-light"
          onClick={() => {
            actions.remove(index);
          }}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default ListOfIds;
