import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Form, Image } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import Icon from '../../UI/Icon';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';

const colClass = 'col p-2';

const DocsInfoForm = ({
  regOrder,
  onSubmit,
  loading,
  verified,
  setVerified,
}) => {
  const [docStatuses, setDocStatuses] = useState(null);
  const [disableVerification, setDisableVerification] = useState(false);

  useEffect(() => {
    if (regOrder?.custom_fields?.documents_verification_status?.length) {
      let newDocStatuses =
        regOrder?.custom_fields?.documents_verification_status;
      newDocStatuses = newDocStatuses.map((item) => {
        const updatedItem = { ...item };
        updatedItem.url = regOrder.consumer?.customfields?.[item?.id || ''];
        return updatedItem;
      });
      setDocStatuses(newDocStatuses);
      setVerified(regOrder?.custom_fields?.documents_verified || false);
      if (regOrder?.custom_fields?.documents_verified) {
        setDisableVerification(true);
      }
    }
  }, [regOrder]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(docStatuses);
  };

  const handleChangeDocument = (e, i, is_checkbox = false) => {
    let newDocStatuses = docStatuses.map((item) => ({ ...item }));
    if (is_checkbox) {
      newDocStatuses[i][e.target.name] = !newDocStatuses[i][e.target.name];
    } else {
      newDocStatuses[i][e.target.name] = e.target.value;
    }
    setDocStatuses(newDocStatuses);
  };

  const renderDocuments = () => {
    if (!docStatuses?.length) return 'Not available';

    return docStatuses.map((item, i) => {
      if (item.id === 'passport_image') {
        return (
          <Form.Group
            key={`document-${item?.id}-${i}`}
            className="mb-5"
            controlId="formBasicDoc"
          >
            <Image
              src={regOrder?.consumer?.settings?.str_photo_data}
              fluid
              rounded
              thumbnail
              className={cn('w-100')}
            />
            <Form.Label>{item?.name}</Form.Label>
            <Form.Check
              type="checkbox"
              id={`checkbox-${item?.id}-${i}`}
              label="Verified"
              className="mb-2"
              name="is_verified"
              checked={item?.is_verified}
              onChange={(e) => handleChangeDocument(e, i, true)}
            />
            <Form.Control
              as="textarea"
              name="comment"
              placeholder="Leave a comment here"
              style={{ height: '100px' }}
              value={item?.comment || ''}
              onChange={(e) => handleChangeDocument(e, i)}
            />
            <Form.Text className="text-muted">
              Enter your comment to merchant's passport
            </Form.Text>
          </Form.Group>
        );
      }

      if (item.id === 'business_id') {
        return (
          <Form.Group
            key={`document-${item?.id}-${i}`}
            className="mb-5"
            controlId="formBasicDoc"
          >
            <Form.Label>
              {item?.name}: {regOrder?.consumer?.customfields?.business_id}
            </Form.Label>
            <Form.Check
              type="checkbox"
              id={`checkbox-${item?.id}-${i}`}
              label="Verified"
              className="mb-2"
              name="is_verified"
              checked={item?.is_verified}
              onChange={(e) => handleChangeDocument(e, i, true)}
            />
            <Form.Control
              as="textarea"
              name="comment"
              placeholder="Leave a comment here"
              style={{ height: '100px' }}
              value={item?.comment || ''}
              onChange={(e) => handleChangeDocument(e, i)}
            />
            <Form.Text className="text-muted">
              Enter your comment to merchant's passport
            </Form.Text>
          </Form.Group>
        );
      }

      return (
        <Form.Group
          key={`document-${item?.id}-${i}`}
          className="mb-5"
          controlId="formBasicDoc"
        >
          <Image
            src={item?.url}
            fluid
            rounded
            thumbnail
            className={cn('w-100')}
          />
          <Form.Label>{item?.name}</Form.Label>
          <Form.Check
            type="checkbox"
            id={`checkbox-${item?.id}-${i}`}
            label="Verified"
            className="mb-2"
            name="is_verified"
            checked={item?.is_verified}
            onChange={(e) => handleChangeDocument(e, i, true)}
          />
          <Form.Control
            as="textarea"
            name="comment"
            placeholder="Leave a comment here"
            style={{ height: '100px' }}
            value={item?.comment || ''}
            onChange={(e) => handleChangeDocument(e, i)}
          />
          <Form.Text className="text-muted">
            Enter your comment to that document here
          </Form.Text>
        </Form.Group>
      );
    });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {renderDocuments()}

        <Form.Check
          type="checkbox"
          id={`checkbox-docs-verified`}
          disabled={disableVerification}
          label={
            <span data-tip data-for="infoTip" className="font-weight-bold">
              <ReactTooltip id="infoTip" place="bottom" effect="solid">
                {disableVerification ? (
                  <span>Already verified!</span>
                ) : (
                  <>
                    Verify all of the merchant's documents.
                    <br /> This action will complete documents manual
                    verification process!
                  </>
                )}
              </ReactTooltip>
              Mark documents as verified (This cannot be undone!)
            </span>
          }
          className="mb-2"
          role="button"
          name="verified"
          size="lg"
          checked={verified}
          onChange={(e) => setVerified(!verified)}
        />

        <div className="form-row row-cols-1">
          <div className={cn(colClass)}>
            <button
              className="btn btn-primary btn-block btn-lg"
              disabled={loading}
            >
              Save changes
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default DocsInfoForm;
