import moment from 'moment';
import { useEffect, useMemo, useState, useContext } from 'react';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import DataTable from 'react-data-table-component';
import Wait from '../UI/Wait';
import getSalesReport from './../../api/utils/merchant/reports/getSalesReport';
import getAdminShopList from './../../api/utils/shop/getAdminShopList';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SmartForm from './../Form/SmartForm';
import UserInfoContext from './../../contexts/UserInfoContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { getOrders } from '../../api/utils/order/getOrders';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import getMerchantList from '../../api/utils/merchant/getMerchantList';

let SalesReport = () => {
    const userInfo = useContext(UserInfoContext);
    const merchantId = userInfo.merchant ? userInfo.merchant.id : undefined;
    let applicationId = userInfo.app ? userInfo.app.id : undefined;
    const pageTitle = userInfo.merchant?.name
        ? `Sales report (${userInfo.merchant.name})`
        : 'Sales report';

    const [sales, setSales] = useState();
    const [productList = [], setProductList] = useState([]);
    const [defaultValues, setDefaultValues] = useState({
        startDate: moment()
            .startOf('year')
            .format('YYYY-MM-DD')
            .toLocaleString(),
        endDate: moment().format('YYYY-MM-DD').toLocaleString(),
    });
    const [loading, setLoading] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [count, setCount] = useState(0);
    const [count24, setCount24] = useState(0);
    const [count14, setCount14] = useState(0);
    const [count10, setCount10] = useState(0);
    const [amount, setAmount] = useState(0);
    const [posList, setPosList] = useState([]);
    const [merchantList, setMerchantList] = useState([]);
    const [dropdown, setDropdown] = useState([]);

    let isMounted = true;
    useEffect(() => {
        // fetchMerchantList(null);
        fetchMerchantList(null);
        PointOfSalesList();
        fetchOrders();

        return () => {
            isMounted = false;
        };
    }, []);

    const fetchMerchantList = (merchantID) => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept') {
                    setMerchantList(
                        data.merchants?.reduce(
                            (merchant, item) => [
                                ...merchant,
                                {
                                    name: item.merchant.str_name,
                                    value: item.id,
                                    id: item.id,
                                },
                            ],
                            []
                        )
                    );
                    data?.merchants?.map((item) => {
                        if (item.id) {
                            fetchMerchantList2(item.id);
                        }
                    });
                } else {
                    NotificationManager.error('Error');
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        const options = {
            query_application: applicationId,
            query_parent: merchantID,
        };
        getMerchantList(options, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const fetchMerchantList2 = (merchantID) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                const subMercahnt = data.merchants?.reduce(
                    (merchant, item) => [
                        ...merchant,
                        {
                            name: item?.settings?.str_name,
                            value: item?._id,
                            id: item?._id,
                        },
                    ],
                    []
                );
                setMerchantList((merchantList) => [
                    ...merchantList,
                    ...subMercahnt,
                ]);
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        const options = {
            query_application: applicationId,
            query_parent: merchantID,
        };
        getMerchantList(options, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const PointOfSalesList = () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept') {
                    setPosList(
                        data.shops?.reduce(
                            (settings, item) => [
                                ...settings,
                                {
                                    name: item.settings.str_name,
                                    value: item._id,
                                },
                            ],
                            []
                        )
                    );
                } else {
                    NotificationManager.error('Error');
                }
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
        };

        getAdminShopList(
            {
                query_count: 1000,
                appid: applicationId,
                merchant: merchantId,
            },
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };
    useEffect(() => {
        if (defaultValues.startDate && defaultValues.endDate && merchantId) {
            fetchReport();
            fetchOrders();
        }
    }, [defaultValues]);

    const fetchOrders = () => {
        const resultHandler = (data) => {
            setCount(0);
            setCount10(0);
            setCount14(0);
            setCount24(0);
            if (data && data.status === 'accept') {
                if (isMounted) {
                    setOrderList(data.orders);
                    data?.orders?.forEach((data) => {
                        if (data.products.length > 0) {
                            data.products.map((data3) => {
                                if (data3.vat_class === 0) {
                                    setCount((prevCount) => prevCount + 1);
                                }
                                if (data3.vat_class === 24) {
                                    setCount24((prevCount1) => prevCount1 + 1);
                                }
                                if (data3.vat_class === 14) {
                                    setCount14((prevCount2) => prevCount2 + 1);
                                }
                                if (data3.vat_class === 10) {
                                    setCount10((prevCount3) => prevCount3 + 1);
                                }
                            });
                        }
                    });
                }
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setOrderList([]);
            setLoading(false);
        };
        if (isMounted) {
            getOrders(
                {
                    query_merchant: merchantId,
                    query_application: applicationId,
                    query_child_merchants: true,
                    query_start_date: defaultValues.startDate,
                    query_end_date: defaultValues.endDate,
                    page: 0,
                    query_count: 10000,
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.log);
        }
    };
    const fetchReport = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept' && data.salesreport) {
                    setSales(data?.salesreport);
                    setProductList(data.products);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setSales();
            setLoading(false);
        };

        getSalesReport(
            {
                start_date: defaultValues.startDate,
                end_date: defaultValues.endDate,
                merchant: merchantId,
                point_of_sales: dropdown?.pointsOfSales,
                customer: dropdown?.customer,
            },
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };

    function currencyFormat(num, currency) {
        const result = num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        switch (currency) {
            case 'USD':
                return '$' + result;
            case 'EUR':
                return '\u20AC' + result;
            default:
                return result;
        }
    }

    const columns = useMemo(
        () => [
            {
                name: 'Product',
                grow: 2,
                cell: ({ name }) => name,
            },
            {
                name: 'Count',
                grow: 2,
                cell: ({ count }) => count,
            },
            {
                name: 'Amount',
                grow: 2,
                cell: ({ sum_amount }) => currencyFormat(sum_amount, 'EUR'),
            },
            {
                name: 'VAT amount',
                grow: 2,
                cell: ({ sum_vat_amount }) =>
                    currencyFormat(sum_vat_amount, 'EUR'),
            },
        ],
        [merchantId, defaultValues]
    );

    const onSubmit = (data) => {
        setDefaultValues({
            startDate: data.startDate,
            endDate: data.endDate,
        });
    };

    const onChangeDropdown = (name, value) => {
        setDropdown({
            ...dropdown,
            [name]: value,
        });
    };

    const formatMoneyVal = (value) => {
        let convertValue = 0;
        if (typeof value === 'string') convertValue = Number(value) || 0;
        if (typeof value === 'number') convertValue = value;

        return currencyFormat(
            Number(convertValue.toFixed(2)) || 0,
            sales?.currency || 'EUR'
        );
    };

    return (
        <div>
            <NotificationContainer />
            <h2>{pageTitle}</h2>
            {merchantId ? (
                <div>
                    <SmartForm
                        onSubmit={onSubmit}
                        defaultValues={defaultValues}
                    >
                        <Stack
                            direction="row"
                            justifyContent="start"
                            alignItems="flex-end"
                            spacing={1}
                            marginBottom="6px"
                        >
                            <InputFieldWithValidation
                                className="col-md-4"
                                name="startDate"
                                label="Start date"
                                required={true}
                                type="date"
                            />
                            <InputFieldWithValidation
                                className="col-md-4"
                                name="endDate"
                                label="End date"
                                required={true}
                                type="date"
                            />
                            <Button
                                variant="contained"
                                sx={{
                                    minWidth: '180px',
                                    height: '40px',
                                }}
                                type="submit"
                            >
                                Get report
                            </Button>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="start"
                            alignItems="flex-end"
                            spacing={1}
                            marginBottom="6px"
                        >
                            <SelectFieldWithValidation
                                className="col-md-4"
                                name="pointsOfSales"
                                label="Point of sales"
                                options={posList}
                                onChange={(name, value) => {
                                    onChangeDropdown(name, value);
                                }}
                            />
                            <SelectFieldWithValidation
                                className="col-md-4"
                                name="customer"
                                label="Customer"
                                options={merchantList}
                                onChange={(name, value) => {
                                    onChangeDropdown(name, value);
                                }}
                            />
                        </Stack>
                    </SmartForm>
                    {!loading ? (
                        sales ? (
                            <TableContainer sx={{ marginTop: 5 }}>
                                <Table
                                    sx={{ minWidth: 250 }}
                                    aria-label="simple table"
                                >
                                    <TableBody>
                                        <TableRow
                                            key="Orders count"
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                Orders count
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            ></TableCell>

                                            <TableCell align="right">
                                                {orderList?.length}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key="total-sales-without-vat"
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                Total sales without VAT
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            ></TableCell>
                                            <TableCell align="right">
                                                {formatMoneyVal(
                                                    sales?.total_sales_without_vat
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key="vat-24-sales"
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                VAT 24%
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {count24}
                                            </TableCell>
                                            <TableCell align="right">
                                                {formatMoneyVal(
                                                    sales?.vat_sums['24']
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key="vat-14-sales"
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                VAT 14%
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {count14}
                                            </TableCell>
                                            <TableCell align="right">
                                                {formatMoneyVal(
                                                    sales?.vat_sums['14']
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key="vat-10-sales"
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                VAT 10%
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {count10}
                                            </TableCell>
                                            <TableCell align="right">
                                                {formatMoneyVal(
                                                    sales?.vat_sums['10']
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key="vat-0-sales"
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                VAT 0%
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {count}
                                            </TableCell>
                                            <TableCell align="right">
                                                {formatMoneyVal(
                                                    sales?.vat_sums['0']
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key="total-vat-sales"
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                Total VAT
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            ></TableCell>
                                            <TableCell align="right">
                                                {formatMoneyVal(sales?.vat_sum)}
                                                {/* {currencyFormat(sales.vat_sum, sales.currency)} */}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key="total-sales"
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                Total Sales
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            ></TableCell>
                                            <TableCell align="right">
                                                {currencyFormat(
                                                    sales.order_amount_sum,
                                                    sales.currency
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            'no data'
                        )
                    ) : (
                        'loading'
                    )}
                    <DataTable
                        dense
                        striped
                        highlightOnHover
                        data={productList}
                        columns={columns}
                        progressPending={loading}
                        noDataComponent="Product list is empty"
                        progressComponent={
                            <div className="p-5">
                                <Wait />
                            </div>
                        }
                    />
                </div>
            ) : (
                'Select merchant first'
            )}
        </div>
    );
};

export default SalesReport;
