import { useEffect, useState } from 'react';
import useSearchString from '../../utils/useSearchString';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SmartForm from '../Form/SmartForm';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import customMessage from '../../api/utils/customMessage';
import Fields from './Fields';

// API emulator component in fact

const MysteriousComponent = () => {
  const history = useHistory();
  const [product, setProduct] = useState({ info: null, types: [] });
  const [message, setMessage] = useState({ command: 'violence', options: [] });
  const [result, setResult] = useState('Nope');
  const { product: id } = useSearchString();

  const [showConfirmRemoveProductType, setShowConfirmRemoveProductType] =
    useState(false);
  const handleShowConfirmRemoveProductType = () =>
    setShowConfirmRemoveProductType(true);
  const handleCloseConfirmRemoveProductType = () =>
    setShowConfirmRemoveProductType(false);
  const showConfirmDialog = () => {
    handleShowConfirmRemoveProductType();
  };

  // useEffect(() => {
  //     getProduct({ id })
  //         .then((data) => {
  //             console.log(data);
  //             return data;
  //         })
  //         .then(({ product, types }) => ({ info: product, types }))
  //         .then(setProduct)
  //         .catch(console.error);
  // }, [id]);

  const onSubmit = (message) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Sent');
      } else {
        NotificationManager.error('Error');
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    console.log('MESSAGE');
    console.log(message);
    let options = {};
    message.options.map((e) => (options[e.key] = e.value));
    customMessage(message.command, options, errorHandler).then((data) =>
      setResult(JSON.stringify(data, null, 2))
    );
    // Honkio()
    // .mainShop.userFetch(message.command, {
    //     ...message.options,
    // }, errorHandler)
    // merchantUpdateProduct({ ...prepareToPublish(id, product) }, errorHandler)
    //     .then((data) => {
    //         return resultHandler(data)
    //     })
  };

  return (
    <div>
      <NotificationContainer />
      <h2>API Emulator</h2>

      <SmartForm onValid={() => {}} onSubmit={onSubmit} defaultValues={message}>
        <fieldset className="form-row mb-3">
          <div className="col-4 p-3">
            <InputFieldWithValidation name="command" label="Command" />
          </div>
        </fieldset>
        Options
        <Fields />
        <br />
        <button type="submit" className="btn btn-primary">
          Save changes
        </button>
      </SmartForm>
      <br />
      <h2>Result</h2>
      <label className="multiline">{result}</label>
    </div>
  );
};

export default MysteriousComponent;
