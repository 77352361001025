import {
  ButtonActionForm,
  InputFieldWithValidation,
} from '../../Form/elements/formElements';
import SmartForm from '../../Form/SmartForm';
import { useEffect } from 'react';
import { Button, Form, Col } from 'react-bootstrap';

const EmailGreyListForm = (props) => {
  const { greyList, onSave, btnLabel } = props;

  let isMounted = true;

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  if (!greyList) return null;
  const { email, email_reason, score } = greyList;
  const defaultValues = { email, reason: email_reason, score };

  return (
    <div className="container-fluid">
      <SmartForm onSubmit={onSave} defaultValues={defaultValues}>
        <div className="row mb-3">
          <InputFieldWithValidation
            className="col-2"
            name="score"
            label="Score"
            type="number"
            required="true"
          />
          <InputFieldWithValidation
            className="col-4"
            name="email"
            label="Email"
            type="email"
            required="true"
          />
          <InputFieldWithValidation
            className="col-4"
            name="reason"
            label="Reason"
            type="text"
            required="true"
          />
          <div className="d-flex align-items-end col-2">
            <ButtonActionForm
              label={btnLabel ? btnLabel : 'Add'}
              name="add"
              value={true}
              className="btn btn-primary"
              type="submit"
            />
          </div>
        </div>
      </SmartForm>
    </div>
  );
};

export default EmailGreyListForm;
