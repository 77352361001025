import Honkio from '../../Honkio';

const setCustomer = (options = {}, errorHandler = () => {}) => {
    const {
        id,
        name,
        description,
        type,
        merchant,
        application,
        parent,
        visible,
        coordinates,
        pos,
        properties,
        user_id,
        external_reference,
        action,
    } = options;
    const params = {
        id,
        name,
        description,
        type,
        merchant,
        application,
        parent,
        visible,
        coordinates,
        pos,
        properties,
        user_id,
        external_reference,
        action,
        version: 3,
    };
    return Honkio().mainShop.userFetch(
        'admincustomerset',
        params,
        errorHandler
    );
};

export const createCustomer = (options, errorHandler) => {
    const opts = {
        ...options,
        action: 'create',
    };
    return setCustomer(opts, errorHandler);
};

export const updateCustomer = (options, errorHandler) => {
    const opts = {
        ...options,
        action: 'update',
    };
    return setCustomer(opts, errorHandler);
};

export const deleteCustomer = (options, errorHandler) => {
    const opts = {
        ...options,
        action: 'delete',
    };
    return setCustomer(opts, errorHandler);
};
