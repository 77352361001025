import Honkio from '../../Honkio';

const getMerchantData = (options = {}, errorHandler = {}) => {
  const { id, debug } = options;
  return Honkio().mainShop.userFetch(
    'merchantget',
    { debug, id, version: 3, action: 'read' },
    errorHandler
  );
};
export default getMerchantData;
