import DataTable from 'react-data-table-component';
import { useEffect, useMemo, useState } from 'react';
import getMerchantInvoices from '../api/utils/merchant/invoice/getMerchantInvoices';
import useSearchString from '../utils/useSearchString';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import Wait from './UI/Wait';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const PaymentsTable = () => {
  const { merchant = null, merchant_name = '' } = useSearchString();
  const pageTitle = `Payments ${merchant_name ? `(${merchant_name})` : ''}`;
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  useEffect(() => {
    if (merchant) {
      setLoading(true);
      fetchPayments();
    }
  }, [merchant]);

  useEffect(() => {
    fetchPayments();
  }, [perPage]);

  const fetchPayments = async (page = 1) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept' && data.payment_invoices) {
        setPayments(data.payment_invoices);
        setTotalRows(data?.total);
        setLoading(false);
      } else {
        setLoading(false);
        setPayments([]);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setPayments([]);
      setLoading(false);
    };

    getMerchantInvoices(
      { merchant, query_skip: (+page - 1) * perPage, query_count: perPage },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const columns = useMemo(
    () => [
      {
        grow: 2,
        autoWidth: true,
        name: 'ID',
        cell: ({ _id }) => _id,
        sortable: true,
      },
      {
        grow: 1,
        name: 'email',
        cell: ({ email }) => email,
        sortable: true,
      },
      {
        name: 'Type',
        cell: ({ type }) => type,
        sortable: true,
      },
      {
        name: 'Status',
        cell: ({ status }) => status,
        sortable: true,
      },
      {
        name: 'Due date',
        cell: ({ due_date }) => due_date,
        sortable: true,
      },
      {
        name: 'Amount',
        cell: ({ amount }) => amount,
        sortable: true,
      },
    ],
    []
  );

  const handlePageChange = (page) => {
    fetchPayments(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  return (
    <div>
      <NotificationContainer />
      <h2>{pageTitle}</h2>
      <DataTable
        noHeader
        dense
        striped
        progressPending={loading}
        pagination={true}
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={PER_PAGE_CHOICES}
        onChangeRowsPerPage={handleRowsPerPage}
        paginationServer
        // onRowClicked={onRowClicked}
        onChangePage={handlePageChange}
        paginationTotalRows={totalRows}
        progressComponent={
          <div className="p-5">
            <Wait />
          </div>
        }
        data={payments}
        columns={columns}
      />
    </div>
  );
};

export default PaymentsTable;
