import Honkio from '../../Honkio';

const getAdminVelocityRule = (options = {}, errorHandler = {}) => {
  const { id } = options;
  return Honkio().mainShop.userFetch(
    'adminvelocityruleget',
    { id, version: 3, action: 'read' },
    errorHandler
  );
};

export default getAdminVelocityRule;
