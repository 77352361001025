import Honkio from '../../Honkio';

const getSchedulerScript = (options = {}, errorHandler = () => {}) => {
    const { query_name } = options;
    return Honkio().mainShop.userFetch(
        'adminappschedulerscriptsget',
        {
            query_name,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
export default getSchedulerScript;
