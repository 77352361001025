import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { useContext, useEffect, useState } from 'react';
import {
  ButtonActionForm,
  InputFieldWithValidation,
} from '../Form/elements/formElements';
import SmartForm from '../Form/SmartForm';
import setConsumer from '../../api/utils/consumer/setConsumer';

const ChangePassword = () => {
  const userInfo = useContext(UserInfoContext);
  const onSubmit = (data) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('New password saved', 'Success', 4000);
      }
    };
    const errorHandler = (error) => {
      if (error) {
        NotificationManager.error(error.description, 'Error', 4000);
      }
    };
    if (data.str_new_password1 === data.str_new_password2) {
      setConsumer(
        {
          newPassword: data.str_new_password1,
          password: data.str_old_password,
        },
        errorHandler
      ).then((data) => {
        return resultHandler(data);
      });
    } else {
      NotificationManager.error('Paswords do not match');
    }
  };

  return (
    <div className="w-100 h-100">
      <NotificationContainer />
      <div className="container-fluid">
        <h2>Change password</h2>
        <SmartForm onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-6">
              <InputFieldWithValidation
                className="mb-3"
                name="str_old_password"
                label="Old password"
                type="password"
                required
              />
              <InputFieldWithValidation
                className="mb-3"
                name="str_new_password1"
                label="New password"
                type="password"
                required
              />
              <InputFieldWithValidation
                className="mb-3"
                name="str_new_password2"
                label="Repeat new password"
                type="password"
                required
              />
              <ButtonActionForm
                label="Update password"
                name="active"
                value={true}
                type="submit"
              />
            </div>
          </div>
        </SmartForm>
      </div>
    </div>
  );
};
export default ChangePassword;
