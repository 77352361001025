import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import getWarehousesList from '../../api/utils/warehouse/getWarehousesList';
import WarehousesTable from './WarehousesTable';
import UserInfoContext from '../../contexts/UserInfoContext';
import { deleteWarehouse } from '../../api/utils/warehouse/setWarehouse';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const WarehousesList = () => {
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [warehouses, setWarehouses] = useState([]);

    const [loading, setLoading] = useState(false);

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState();

    const pageTitle = `Warehouses`;

    useEffect(() => {
        fetchWarehousesList(1);
    }, [perPage]);

    const fetchWarehousesList = async (page = 1) => {
        setLoading(true);

        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setWarehouses(data.warehouses);
                setTotalRows(data.total);
            } else {
                setWarehouses([]);
                setTotalRows(0);
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
            setWarehouses([]);
            setTotalRows(0);
        };

        try {
            const result = await getWarehousesList(
                {
                    query_application: appId,
                    query_merchant: merchantId,
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                },
                errorHandler
            );
            resultHandler(result);
        } catch (error) {
            errorHandler(error);
        }
    };

    const onDeleteClick = async (id) => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data?.status === 'accept') {
                fetchWarehousesList(1);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setLoading(true);
            const res = await deleteWarehouse({ id }, errorHandler);
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
            console.error(error);
        }
    };

    const handlePageChange = (page) => {
        fetchWarehousesList(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    return (
        <div>
            <NotificationContainer />
            <div className="row">
                <div className="col-12 col-md-8">
                    <h2>{pageTitle}</h2>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-end align-items-center">
                    <Button
                        variant="outline-primary"
                        as={Link}
                        to={`/warehouses/create`}
                        className="btn btn-outline-primary mt-1 mb-3"
                    >
                        <i className="bi-plus-square ml-1 mr-2 " /> Create new
                        warehouse
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <WarehousesTable
                        data={warehouses}
                        loading={loading}
                        onDeleteClick={onDeleteClick}
                        rowsCountPerPage={perPage}
                        handleRowsPerPage={handleRowsPerPage}
                        perPageChoices={PER_PAGE_CHOICES}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default WarehousesList;
