const init = ({ items = [], bounds, zoom }) => ({
  items,
  bounds,
  zoom,
});

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'change':
      const { zoom, bounds } = payload;
      return { ...state, zoom, bounds };
    case 'reset':
      return { ...state, ...payload };
    default:
      throw new Error('Uknown action type performed in DynamicMap component');
  }
};

export { reducer, init };
