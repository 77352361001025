import React, { FC, useContext } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { useTheme } from '@mui/material';
import UBOContext from '../../../../contexts/UBOGraphContext';

const CustomHandle = ({ type }) => {
  const theme = useTheme();

  const { layout } = useContext(UBOContext);

  const getPosition = () => {
    if (type === 'source') {
      return layout === 'horizontal' ? Position.Right : Position.Bottom;
    } else {
      return layout === 'horizontal' ? Position.Left : Position.Top;
    }
  };

  return (
    <Handle
      type={type}
      position={getPosition()}
      style={{
        border: 'none',
        borderRadius: '4px',
        backgroundColor: theme.palette.primary.light,
        width: layout === 'vertical' ? '2rem' : '0.75rem',
        height: layout === 'vertical' ? '0.75rem' : '2rem',
      }}
    ></Handle>
  );
};

export default CustomHandle;
