const mapPlainArrayDataToHookFormValsArray = (arr) => {
  return arr.map((item) => ({ value: item }));
};

// Prepare data before loading it into form
const parseRegAppSettingsData = (data) => {
  let parsedData = { ...data };
  parsedData.yearly_turnover = mapPlainArrayDataToHookFormValsArray(
    data.yearly_turnover
  );

  parsedData.risky_businesses_list = mapPlainArrayDataToHookFormValsArray(
    data.risky_businesses_list
  );

  parsedData.number_of_transactions = mapPlainArrayDataToHookFormValsArray(
    data.number_of_transactions
  );

  parsedData.number_of_returns = mapPlainArrayDataToHookFormValsArray(
    data.number_of_returns
  );

  parsedData.average_return_amount = mapPlainArrayDataToHookFormValsArray(
    data.average_return_amount
  );

  return parsedData;
};

export default parseRegAppSettingsData;
