import { useEffect, useState, useContext } from 'react';
import useSearchString from '../../utils/useSearchString';
import ScriptList from './ScriptList';
import UserInfoContext from '../../contexts/UserInfoContext';
import getScriptsList from '../../api/utils/app/scripts/getScriptsList';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { deleteScript } from '../../api/utils/app/scripts/setScript';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const Scripts = () => {
  const { appid, appname = null } = useSearchString();
  const [scriptList, setScriptList] = useState([]);
  const userInfo = useContext(UserInfoContext);

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  const [loading, setLoading] = useState(false);

  const pageTitle = appname
    ? `Scripts (${appname})`
    : appid
    ? 'Scripts'
    : userInfo.app?.name
    ? `Scripts (${userInfo.app.name})`
    : 'Merchants';

  const fetchScripts = async (page = 1) => {
    setLoading(true);
    const resultHandler = (data) => {
      setScriptList(data?.scripts);
      setTotalRows(data?.total);
      setLoading(false);
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setScriptList([]);
      setTotalRows(0);
      setLoading(false);
    };

    const res = await getScriptsList(
      {
        query_skip: (+page - 1) * perPage,
        query_count: perPage,
        query_application: appid,
      },
      errorHandler
    );
    if (res && res?.status === 'accept') resultHandler(res);
  };

  useEffect(() => {
    fetchScripts();
  }, [appid, perPage]);

  const handlePageChange = (page) => {
    fetchScripts(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  const handleDeleteScript = async (id) => {
    setLoading(true);
    const resultHandler = (data) => {
      setLoading(false);
      NotificationManager.success('Script removed');

      const removedId = data?.script?.id;
      setScriptList((prev) => prev.filter((item) => item?._id !== removedId));
    };

    const errorHandler = (error) => {
      setLoading(false);
      NotificationManager.error(error.description, 'Error', 4000);
    };

    try {
      const res = await deleteScript({ id }, errorHandler);
      if (res && res?.status === 'accept') {
        resultHandler(res);
      }
    } catch (error) {
      console.log('error removing script: ', error);
    }
  };

  return (
    <div>
      <NotificationContainer />

      <div className="row">
        <div className="col">
          <h2>{pageTitle}</h2>
        </div>
        <div className="col-3 d-flex justify-content-end align-items-center">
          <Button
            variant="outline-primary"
            as={Link}
            to={`/scripts/create?app=${appid}`}
          >
            <i className="bi-plus-square ml-1 mr-2 " /> Create script
          </Button>
        </div>
      </div>

      <ScriptList
        data={scriptList}
        loading={loading}
        rowsCountPerPage={perPage}
        handleRowsPerPage={handleRowsPerPage}
        perPageChoices={PER_PAGE_CHOICES}
        totalRows={totalRows}
        handlePageChange={handlePageChange}
        onDeleteClick={handleDeleteScript}
      />
    </div>
  );
};

export default Scripts;
