const prepareToPublish = (assetInfo, formData) => {
    console.log('prepareToPublish');
    console.log('assetInfo: ', assetInfo);
    console.log('formData: ', formData);

    const {
        latitude,
        longitude,
        name,
        description,
        type,
        visible,
        user_id,
        pos,
        external_reference,
        api_key,
        secret,
        properties,
        product,
    } = formData;
    const asset = {
        id: assetInfo._id,
        type: assetInfo.type,
        coordinates: assetInfo.coordinates,
        latitude,
        longitude,
        name,
        description,
        visible,
        user_id,
        pos,
        external_reference,
        api_key,
        secret,
        properties,
        product,
    };
    return { asset };
};

export default prepareToPublish;
