import { useContext, useEffect, useState } from 'react';
import useSearchString from '../../utils/useSearchString';
import getMerchantList from '../../api/utils/merchant/getMerchantList';
import MerchantList from './MerchantList';
import InlineSearch from '../UI/InlineSearch';
import { Link } from 'react-router-dom';
import UserInfoContext from '../../contexts/UserInfoContext';
import cn from 'classnames';

import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import MerchantModal from './MerchantModal';
import DeleteConfirmModel from '../Model/DeleteConfirmModel';
import deleteMerchant from '../../api/utils/merchant/deleteMerchant';
import TopUpModal from './TopUpModal';
import topUpMerchantBalance from '../../api/utils/merchant/balance/topUpMerchantBalance';
import { Button, FormGroup, Form } from 'react-bootstrap';
import cleanOutMerchant from '../../api/utils/merchant/cleanOutMerchant';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const Merchants = () => {
    const { setInfo } = useContext(UserInfoContext);
    const userInfo = useContext(UserInfoContext);

    const { appid = null, appname = null } = useSearchString();

    const [merchantsList, setMerchantsList] = useState([]);
    const [keyword, setKeyword] = useState('');
    const selectedAppId = appid
        ? appid
        : userInfo.app?.id
        ? userInfo.app.id
        : null;

    const selectedMerchantId = userInfo.merchant?.id;

    const [showMerchantPreview, setShowMerchantPreview] = useState(false);
    const handleShowMerchantPreview = () => setShowMerchantPreview(true);
    const handleCloseMerchantPreview = () => setShowMerchantPreview(false);
    const [currentMerchant, setCurrentMerchant] = useState();
    const { parent = null } = useSearchString();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [cleanOutId, setCleanOutId] = useState();
    const [showConfirmCleanOutModal, setShowConfirmCleanOutModal] =
        useState(false);

    const [topUpMerchantId, setTopUpMerchantId] = useState(null);
    const [showTopUpModal, setShowTopUpModal] = useState(false);

    const [showDeleted, setShowDeleted] = useState(false);

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState();

    const handleShowConfirmModal = () => setShowConfirmModal(true);
    const pageTitle = appname
        ? `Merchants (${appname})`
        : appid
        ? 'Merchants'
        : userInfo.app?.name
        ? `Merchants (${userInfo.app.name})`
        : 'Merchants';
    const [loading, setLoading] = useState(false);
    const [parentId, setParentId] = useState();

    useEffect(() => {
        if (selectedAppId) {
            setLoading(true);
            fetchMerchants();
        }
    }, [selectedAppId, keyword, perPage, showDeleted]);

    const onDeleteClick = (id) => {
        if (selectedMerchantId && selectedMerchantId === id) {
            NotificationManager.error(
                'Cannot remove currently selected merchant',
                'Error'
            );
            return;
        }

        handleShowConfirmModal();
        setDeleteId(id);
    };
    const handleCloseConfirmDelete = () => {
        const resultHandler = (data) => {
            if (data?.message === 'successfully deleted') {
                setShowConfirmModal(false);
                NotificationManager.success(data?.message);
                fetchMerchants();
            } else {
                NotificationManager.error(data?.message);
            }
        };
        deleteMerchant(deleteId).then((data) => {
            return resultHandler(data);
        });
    };

    const removeAndCleanMerchant = (id) => {
        if (selectedMerchantId && selectedMerchantId === id) {
            NotificationManager.error(
                'Cannot remove currently selected merchant',
                'Error'
            );
            return;
        }

        setCleanOutId(id);
        setShowConfirmCleanOutModal(true);
    };
    const confirmRemoveAndCleanMerchant = async () => {
        setShowConfirmCleanOutModal(false);

        if (!cleanOutId) return;

        const errorHandler = (error) => {
            console.log('error == ', error);
            NotificationManager.error('Error removing merchant', 'Error');
            setCleanOutId(null);
            return;
        };

        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Merchant and all his records removed',
                    'Success'
                );
                setCleanOutId(null);
                fetchMerchants();
            }
        };

        try {
            const result = await cleanOutMerchant(cleanOutId, errorHandler);
            resultHandler(result);
        } catch (error) {
            console.log('unexpected error', error);
        }
    };

    const onTopUpClick = (id) => {
        setTopUpMerchantId(id);
        setShowTopUpModal(true);
    };

    const handleCloseTopUpModal = () => {
        setTopUpMerchantId(null);
        setShowTopUpModal(false);
    };

    const handleSubmitTopUp = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Balance updated');
            } else {
                NotificationManager.error('Error');
            }
            setTopUpMerchantId(null);
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setTopUpMerchantId(null);
        };
        topUpMerchantBalance(data, errorHandler).then((res) =>
            resultHandler(res)
        );
    };

    const fetchMerchants = async (page = 1, newParent = null) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setMerchantsList(
                    data.merchants.reduce(
                        (carry, item) => [
                            ...carry,
                            {
                                id: item._id,
                                str_name: item.name,
                                payment: item?.last_payment?.due_date,
                                active: item?.active,
                                is_admin: item?.is_admin,
                                is_deleted:
                                    !!item?.email?.startsWith('deleted.'),
                            },
                        ],
                        []
                    )
                );
                setTotalRows(data.total);
                setLoading(false);
            } else {
                NotificationManager.error('Error');
                setLoading(false);
                setMerchantsList([]);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
            setMerchantsList([]);
        };

        const options = {
            query_application: selectedAppId,
            query_parent: newParent,
            query_skip: (+page - 1) * perPage,
            query_count: perPage,
            query_search: keyword,
            query_deleted: showDeleted,
        };

        try {
            const res = await getMerchantList(options, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.log('unexpected error == ', error);
        }
    };

    const handlePageChange = (page) => {
        fetchMerchants(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    const onMerchantSelected =
        () =>
        ({ id, str_name: name, is_admin }) => {
            setInfo({ merchant: { id, name, is_admin: !!is_admin } });
        };

    const onMerchantUnselected = () => () => {
        setInfo({ merchant: {} });
    };

    let onRowClick = (row) => {
        setParentId(row.id);
        // history.push(`/merchants?parent=${row.id}`);
        fetchMerchants(1, row.id);
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <div className="row">
                <div className="col-12 col-md-8">
                    <h2>{pageTitle}</h2>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-end align-items-center">
                    <Button
                        as={Link}
                        to={`/merchant/create?appid=${appid}&parent=${parent}`}
                        variant="outline-primary"
                        className=""
                    >
                        <i className="bi-plus-square ml-1 mr-2 " /> Create
                        merchant
                    </Button>
                </div>
            </div>
            {selectedAppId ? (
                <div>
                    <div className="row">
                        <div className="col">
                            <InlineSearch setKeyword={setKeyword} />
                        </div>
                        <div
                            className={cn(
                                'col',
                                'd-flex',
                                'justify-content-end',
                                'align-items-center'
                            )}
                        >
                            <Form.Check // prettier-ignore
                                type="switch"
                                name="show_deleted"
                                id="show_deleted"
                                label="Show deleted"
                                checked={showDeleted}
                                onChange={(e) =>
                                    setShowDeleted(e.target.checked)
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <MerchantList
                                loading={loading}
                                onRowClicked={onRowClick}
                                onMerchantSelected={onMerchantSelected()}
                                onMerchantUnselected={onMerchantUnselected()}
                                data={merchantsList}
                                onDeleteClick={onDeleteClick}
                                onCleanClick={removeAndCleanMerchant}
                                onTopUpClick={onTopUpClick}
                                rowsCountPerPage={perPage}
                                handleRowsPerPage={handleRowsPerPage}
                                perPageChoices={PER_PAGE_CHOICES}
                                totalRows={totalRows}
                                handlePageChange={handlePageChange}
                            />
                            {currentMerchant && (
                                <MerchantModal
                                    merchantId={currentMerchant.id}
                                    show={showMerchantPreview}
                                    onHide={handleCloseMerchantPreview}
                                ></MerchantModal>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                'Select application first'
            )}
            <DeleteConfirmModel
                modal={showConfirmModal}
                onModalClose={() => {
                    setShowConfirmModal(false);
                }}
                handleCloseConfirmDelete={handleCloseConfirmDelete}
            />
            <DeleteConfirmModel
                title="Clean out merchant"
                body="Are you sure you want to remove this merchant and all his database records? This operation cannot be reverted"
                modal={showConfirmCleanOutModal}
                onModalClose={() => {
                    setShowConfirmCleanOutModal(false);
                }}
                handleCloseConfirmDelete={confirmRemoveAndCleanMerchant}
                closeLabel="Cancel"
                confirmLabel="Erase"
            />
            <TopUpModal
                show={showTopUpModal}
                onModalClose={handleCloseTopUpModal}
                handleSubmit={handleSubmitTopUp}
                merchant_id={topUpMerchantId}
            />
        </div>
    );
};

export default Merchants;
