import { useFormContext } from 'react-hook-form';

const SelectFieldWithValidation = ({
    className,
    name,
    label,
    defaultValue,
    required,
    options = [],
    disabled,
    nameSelector = 'name',
    valueSelector = 'value',
    isMulti = false,
    error,
    onChange = () => {},
    value,
}) => {
    const { register } = useFormContext();
    const onChangeField = (data) => {
        onChange(name, data.target.value);
    };
    return (
        <div className={className}>
            {label && <label htmlFor={name}>{label}</label>}
            <select
                ref={register}
                className="custom-select d-block w-100"
                id={name}
                name={name}
                defaultValue={defaultValue}
                required={required}
                disabled={disabled}
                onChange={onChangeField}
                value={value}
                multiple={isMulti}
            >
                {options.map((opt, index) =>
                    typeof opt === 'object' ? (
                        <option key={index} value={opt[valueSelector]}>
                            {opt[nameSelector]}
                        </option>
                    ) : (
                        <option key={index}>{opt}</option>
                    )
                )}
            </select>
            {error && (
                <div className="invalid-feedback">Error message: $message</div>
            )}
        </div>
    );
};

export default SelectFieldWithValidation;
