import { useEffect, useState, useMemo, useContext } from 'react';
import useSearchString from '../../utils/useSearchString';
import ShopList from './ShopList';
import InlineSearch from '../UI/InlineSearch';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import { Link } from 'react-router-dom';
import getAdminShopList from './../../api/utils/shop/getAdminShopList';
import DeleteConfirmModel from '../Model/DeleteConfirmModel';
import { adminDeleteShop } from '../../api/utils/shop/setAdminShop';

const colsToSearch = ['str_name'];

const Shops = () => {
    const [shopsList, setShopsList] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [type, setType] = useState('');
    const [perPage, setPerPage] = useState(50);
    const [totalRows, setTotalRows] = useState(150);
    const [loading, setLoading] = useState(false);
    let isMounted = true;
    const userInfo = useContext(UserInfoContext);
    const { merchant = null } = useSearchString();
    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;

    const appid = userInfo?.app?.id;

    const pageTitle = merchant
        ? 'Shops'
        : userInfo.merchant?.name
        ? `Shops (${userInfo.merchant.name})`
        : 'Shops';
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const handleShowConfirmModal = () => setShowConfirmModal(true);

    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (merchantId && merchantId !== null) {
            fetchShops(1);
        }
    }, [merchantId]);
    const onDeleteClick = (id) => {
        handleShowConfirmModal();
        setDeleteId(id);
    };
    const handleCloseConfirmDelete = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setLoading(false);
                setShowConfirmModal(false);
                fetchShops(1);
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
            setShowConfirmModal(false);
        };
        const res = await adminDeleteShop(deleteId, errorHandler);
        resultHandler(res);
    };
    const fetchShops = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept') {
                    setShopsList(
                        data.shops?.map(
                            (shop) =>
                                ({
                                    ...shop,
                                    str_email: shop.settings.str_email,
                                    str_name: shop.settings.str_name,
                                    shop_type: shop.settings.shop_type,
                                    service_type: shop.settings.service_type,
                                } ?? [])
                        )
                    );
                    setTotalRows(data.total);
                } else {
                    NotificationManager.error('Error');
                }
                setLoading(false);
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
        };
        setLoading(true);
        if (isMounted) {
            getAdminShopList(
                {
                    page: page - 1,
                    query_count: perPage,
                    appid: appid,
                    merchant: merchantId,
                },
                errorHandler
            ).then((data) => {
                return resultHandler(data);
            });
        }
    };

    const handlePageChange = (page) => {
        fetchShops(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        getAdminShopList({
            page: page,
            query_count: newPerPage,
            appid: appid,
        })
            .then(({ shops }) => shops)
            .then((data) =>
                data.map((shop) => ({
                    ...shop,
                    str_email: shop.settings.str_email,
                    str_name: shop.settings.str_name,
                    shop_type: shop.settings.shop_type,
                    service_type: shop.settings.service_type,
                }))
            )
            .then(setShopsList)
            .catch(console.log);

        setPerPage(newPerPage);
        setLoading(false);
    };

    const displayedShops = useMemo(() => {
        const typifiedShops = searchFilterIfConsist(
            shopsList,
            colsToSearch,
            keyword
        );
        return typifyIfEquals(typifiedShops, type);
    }, [shopsList, keyword, type]);

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <h2>{pageTitle}</h2>
            {merchantId ? (
                <div>
                    <Link
                        to={`/shop/create?appid=${appid}&merchant=${merchantId}`}
                        className="btn btn-outline-primary mt-1 mb-3"
                    >
                        <i className="bi-plus-square ml-1 mr-2 " /> Create a
                        point of sales
                    </Link>
                    <div className="row">
                        <div className="col">
                            <InlineSearch setKeyword={setKeyword} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ShopList
                                data={displayedShops}
                                rowsCountPerPage={perPage}
                                totalRows={totalRows}
                                handlePerRowsChange={handlePerRowsChange}
                                handlePageChange={handlePageChange}
                                loading={loading}
                                onDeleteClick={onDeleteClick}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                'Select merchant first'
            )}
            <DeleteConfirmModel
                modal={showConfirmModal}
                onModalClose={() => {
                    setShowConfirmModal(false);
                }}
                handleCloseConfirmDelete={handleCloseConfirmDelete}
            />
        </div>
    );
};

export default Shops;
