import React, { useContext, useState, useEffect } from 'react';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import CustomersList from './CustomersList';
import { getCustomerList } from '../../api/utils/customer/getCustomerList';
import { Link } from 'react-router-dom';
import InlineSearch from '../UI/InlineSearch';
import cn from 'classnames';
import ConfirmModal from '../common/ConfirmModal';
import { deleteCustomer } from '../../api/utils/customer/setCustomer';
import CustomerTypesListModal from './CustomerTypesListModal';
import { useHistory } from 'react-router-dom';
import { getCustomerTypesList } from '../../api/utils/customer/type/getCustomerTypesList';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const Customers = () => {
    const history = useHistory();

    const {
        merchant: { id: merchantId },
        app: { id: appId },
    } = useContext(UserInfoContext);

    const [customerList, setCustomerList] = useState([]);

    const [sortField, setSortField] = useState('created_at');
    const [sortDirection, setSortDirection] = useState(-1);

    const [search, setSearch] = useState('');

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState(0);

    const [customer, setCustomer] = useState(null);

    const [showCustomerTypeListModal, setShowCustomerTypeListModal] =
        useState(false);
    const handleShowCustomerTypeListModal = () =>
        setShowCustomerTypeListModal(true);
    const handleCloseCustomerTypeListModal = () =>
        setShowCustomerTypeListModal(false);
    const [customerTypeList, setCustomerTypeList] = useState();

    const [loading, setLoading] = useState(false);

    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        if (merchantId) {
            fetchCustomersList();
        } else {
            setCustomerList([]);
            setTotalRows(0);
            setCustomer(null);
        }
    }, [merchantId, sortField, sortDirection, perPage, search]);

    useEffect(() => {
        if (merchantId && appId) {
            fetchCustomerTypes();
        }
    }, [merchantId, appId]);

    const fetchCustomersList = async (page = 1) => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setCustomerList(data.customers);
                setTotalRows(data.total);
            }
        };

        const errorHandler = (error) => {
            console.log('Error fetching files list', error);
            setLoading(false);
            setCustomerList([]);
            setTotalRows(0);
        };

        try {
            setLoading(true);
            const res = await getCustomerList(
                {
                    query_search: search,
                    query_merchant: merchantId,
                    query_application: appId,
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                    query_sort_field: sortField,
                    query_sort_direction: sortDirection,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('Unexpected error: ', error);
        }
    };

    const handlePageChange = (page) => {
        fetchCustomersList(page);
    };
    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    const handleSort = (column, sortDirection) => {
        setSortField(column?.init_name || 'created_at');
        setSortDirection(sortDirection === 'asc' ? -1 : 1);
    };

    const handleDelete = (customer_id) => {
        if (deleting) {
            NotificationManager.error('Deletion is still in process');
            return;
        }
        setCustomer(customer_id);
    };

    const handleConfirmDeletion = async () => {
        setDeleting(true);

        const resultHandler = (data) => {
            setDeleting(false);
            setCustomer(null);
            if (data && data.status === 'accept') {
                fetchCustomersList();
            }
        };

        const errorHandler = (error) => {
            setDeleting(false);
            setCustomer(null);
            console.log('Error while deleting customer: ', error);
        };

        try {
            const res = await deleteCustomer({ id: customer }, errorHandler);
            resultHandler(res);
        } catch (error) {
            setDeleting(false);
            console.error('Unexpected error while removing customer: ', error);
            setCustomer(null);
        }
    };

    const fetchCustomerTypes = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setCustomerTypeList(data.customer_types);
            }
        };

        const errorHandler = (error) => {
            setCustomerTypeList([]);
            console.log('Error while fetching customer types list: ', error);
        };

        try {
            const res = await getCustomerTypesList(
                {
                    query_merchant: merchantId,
                    query_application: appId,
                    query_skip: 0,
                    query_count: 10000,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.error(
                'Unexpected error while fetching customer types: ',
                error
            );
        }
    };

    const onCustomerTypeSelected = (customerType) => {
        history.push(`/customer/create?customer_type=${customerType._id}`);
    };

    return (
        <div className="container-fluid">
            <h2>Customers list</h2>

            <div className="row">
                <div className={cn('col-12', 'col-sm-6')}>
                    <InlineSearch ph="Search by name" setKeyword={setSearch} />
                </div>
                <div
                    className={cn(
                        'col-12',
                        'col-sm-6',
                        'd-flex',
                        'justify-content-end',
                        'align-items-start'
                    )}
                >
                    {merchantId && (
                        <Link
                            className="btn btn-outline-primary"
                            onClick={() => {
                                if (merchantId && appId) {
                                    handleShowCustomerTypeListModal();
                                } else {
                                    NotificationManager.error(
                                        'Select app and merchant'
                                    );
                                }
                            }}
                        >
                            <i className="bi-plus-square ml-1 mr-2" />
                            Create customer
                        </Link>
                    )}
                </div>
            </div>

            <NotificationContainer />

            <CustomersList
                data={customerList}
                loading={loading}
                totalRows={totalRows}
                handlePageChange={handlePageChange}
                rowsCountPerPage={perPage}
                merchant={merchantId}
                emptyText={'The customers list is empty'}
                handleRowsPerPage={handleRowsPerPage}
                perPageChoices={PER_PAGE_CHOICES}
                handleSort={handleSort}
                onDeleteClick={handleDelete}
            />

            <ConfirmModal
                title={`Delete #${customer}`}
                text={`Are you sure you want to delete customer #${customer}?`}
                show={!!customer}
                handleClose={() => setCustomer(null)}
                confirmAction={handleConfirmDeletion}
            />

            <CustomerTypesListModal
                customerTypeList={customerTypeList}
                show={showCustomerTypeListModal}
                onCustomerTypeSelected={onCustomerTypeSelected}
                onHide={handleCloseCustomerTypeListModal}
            />
        </div>
    );
};

export default Customers;
