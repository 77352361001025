import { useEffect, useState, useMemo } from 'react';
import LoadingBar from '../../../UI/LoadingBar';
import { Modal, Button, Col, Image, Table } from 'react-bootstrap';
import useLoader from '../../../UI/helpers/useLoader';
import { getAdminOrder } from '../../../../api/utils/order/getOrders';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import moment from 'moment';
import renderCurrency from '../../../../utils/renderCurrency';

const DATE_FORMAT = 'MMMM Do, YYYY, HH:mm';

const LostPasswordOrderModal = ({ orderId, show, onHide }) => {
    const [order, setOrder] = useState({});
    const [progress, done] = useLoader([orderId, order]);

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                if (isMounted) {
                    setOrder(data.order);
                }
            } else {
                setOrder({});
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setOrder({});
        };

        if (orderId) {
            getAdminOrder({ id: orderId }, errorHandler).then((data) => {
                return resultHandler(data);
            });
        }
    }, [orderId]);

    const container = (
        <div className="container">
            <div className="col-8">
                <label className="multiline">{order}</label>
            </div>
        </div>
    );

    const onClickHide = () => {
        onHide();
    };

    const renderDate = (date_str) => {
        const moment_date = moment(date_str);
        if (moment_date.isValid()) return moment_date.format(DATE_FORMAT);
        return '';
    };

    const renderOrder = (order) => {
        if (!order) return null;
        return (
            <Table
                striped
                bordered
                hover
                style={{
                    tableLayout: 'fixed',
                }}
            >
                <tbody>
                    <tr>
                        <td>ID</td>
                        <td style={{ wordWrap: 'break-word' }}>{order._id}</td>
                    </tr>
                    <tr>
                        <td>Amount</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {renderCurrency(order.currency)} {order.amount}
                        </td>
                    </tr>
                    <tr>
                        <td>Created</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {renderDate(order.creation_date)}
                        </td>
                    </tr>
                    <tr>
                        <td>Completed</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {renderDate(order.completion_date)}
                        </td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.user_info?.email}
                        </td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.user_info?.phone}
                        </td>
                    </tr>
                    <tr>
                        <td>First name</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.user_info?.firstname}
                        </td>
                    </tr>
                    <tr>
                        <td>Last name</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.user_info?.lastname}
                        </td>
                    </tr>
                    <tr>
                        <td>IP address</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.user_info?.ip_address}
                        </td>
                    </tr>
                    <tr>
                        <td>User agent</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.user_info?.useragent}
                        </td>
                    </tr>
                    <tr>
                        <td>Title</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.title}
                        </td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.description}
                        </td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.status}
                        </td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.order_type}
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    };

    return done ? (
        <Modal size="lg" show={show} onHide={onClickHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Lost password<span className="ml-3">#{order._id}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderOrder(order)}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    ) : (
        <LoadingBar progress={progress} />
    );
};

export default LostPasswordOrderModal;
