const statusesToNodeList = (statuses) => {
  return Object.entries(statuses).map(
    ([
      key,
      {
        capture,
        auth,
        chargeback,
        allow,
        reserve,
        public: pb,
        sales_point,
        position,
        bundled_products,
        send_push,
        send_message,
        service_models,
        velocity_rules,
      },
    ]) => ({
      id: key,
      data: {
        label: key,
        settings: {
          capture,
          auth,
          chargeback,
        },
        options: {
          edit_products: allow?.edit_products,
          edit_amount: allow?.edit_amount,
          reserve,
          public: pb || [],
          sales_point,
          bundled_products,
          send_push,
          send_message,
          service_models,
          velocity_rules,
        },
      },
      position: {
        x: position?.left || 0,
        y: position?.top || 0,
      },
    })
  );
};

export default statusesToNodeList;
