import {
    OAUTH_CLIENT_ID,
    OAUTH_CLIENT_SECRET,
    OAUTH_REDIRECT_URL,
    OAUTH_TOKEN_URL,
    OAUTH_REVOKE_TOKEN_URL,
    TOKEN_REFRESH_MARGIN,
} from '../config';
import getRoles from '../api/utils/role/getRoles';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';

async function authUser(code) {
    try {
        const formData = new FormData();
        formData.append('grant_type', 'authorization_code');
        formData.append('code', code);
        formData.append('client_id', OAUTH_CLIENT_ID);
        formData.append('client_secret', OAUTH_CLIENT_SECRET);
        formData.append('redirect_uri', OAUTH_REDIRECT_URL);

        let response = await fetch(OAUTH_TOKEN_URL, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('An Error has occurred, please try again.');
        }
        let json = await response.json();

        localStorage.clear();
        localStorage.setItem('token', json.access_token);
        localStorage.setItem('refresh_token', json.refresh_token);
        let expiryDate =
            Math.round(new Date().getTime() / 1000) + json.expires_in;

        localStorage.setItem('token_expires_in', expiryDate);
        return true;
    } catch (err) {
        // Here if user reloads the page, code param with single use will already
        // be used and user will require to get a new one
        console.error('Auth problems', err);
        localStorage.clear();
        window.location.href = '/';
    }
}

async function refreshToken() {
    try {
        const formData = new FormData();

        const access_token = localStorage.getItem('token');
        const refresh_token = localStorage.getItem('refresh_token');
        if (!refresh_token || !access_token) return;

        formData.append('grant_type', 'refresh_token');
        formData.append('access_token', access_token);
        formData.append('refresh_token', refresh_token);
        formData.append('client_id', OAUTH_CLIENT_ID);
        formData.append('client_secret', OAUTH_CLIENT_SECRET);

        console.log('Refreshing token...');

        const response = await fetch(OAUTH_TOKEN_URL, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(
                'An Error occurred during token refresh, please try again.'
            );
        }
        let json = await response.json();

        // localStorage.clear(); // This removes more info than required
        clearTokenInfo();
        localStorage.setItem('token', json.access_token);
        localStorage.setItem('refresh_token', json.refresh_token);
        let expiryDate =
            Math.round(new Date().getTime() / 1000) + json.expires_in;
        const resultHandler = (data) => {
            if (data && data.status === 'accept' && data.roles) {
                for (const role of data.roles) {
                    if (role.name === 'superadmin') {
                        localStorage.setItem('role', role.id);
                        window.dispatchEvent(new Event('role_is_set'));
                        return;
                    }
                }
                for (const role of data.roles) {
                    if (role.name === 'admin') {
                        localStorage.setItem('role', role.id);
                        window.dispatchEvent(new Event('role_is_set'));
                        return;
                    }
                    NotificationManager.error('You have no power here');
                }
            } else {
                NotificationManager.error('Error');
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };
        getRoles(errorHandler).then((data) => resultHandler(data));

        localStorage.setItem('token_expires_in', expiryDate);
        return true;
    } catch (error) {
        console.error('Error refreshing token: ', error);
        return false;
    }
}

async function revokeToken() {
    try {
        const formData = new FormData();

        const access_token = localStorage.getItem('token');
        const refresh_token = localStorage.getItem('refresh_token');
        if (!refresh_token) return;

        formData.append('token', refresh_token);
        formData.append('token_type_hint', 'refresh_token');
        formData.append('client_id', OAUTH_CLIENT_ID);
        formData.append('client_secret', OAUTH_CLIENT_SECRET);

        const response = await fetch(OAUTH_REVOKE_TOKEN_URL, {
            method: 'POST',
            headers: new Headers({
                Authorization: `Bearer ${access_token}`,
            }),
            body: formData,
        });

        if (!response.ok) {
            throw new Error(
                'An Error occurred during token revocation, please try again.'
            );
        }
    } catch (error) {
        console.error('Error revoking token: ', error);
    }
}

function LogoutAction() {
    return function (dispatch) {
        dispatch({ type: 'LOGOUT' });
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_expires_in');
        localStorage.removeItem('role');
        localStorage.removeItem('is_authorized');
        localStorage.removeItem('app_classname');
        localStorage.removeItem('is_superadmin');
        return Promise.resolve();
    };
}

export const clearTokenInfo = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token_expires_in');
    localStorage.removeItem('role');
    localStorage.removeItem('is_authorized');
    localStorage.removeItem('app_classname');
    localStorage.removeItem('is_superadmin');
};

// Returns true if expired
const checkTokenExpired = () => {
    const expiration_date_string = localStorage.getItem('token_expires_in');
    const expiration_date = moment.unix(expiration_date_string);

    if (expiration_date.isValid()) {
        return expiration_date
            .subtract(TOKEN_REFRESH_MARGIN, 'minutes')
            .isBefore(moment());
    } else {
        // Invalid date was set - re-login anyway
        return true;
    }
};

export { authUser, refreshToken, revokeToken, LogoutAction, checkTokenExpired };
