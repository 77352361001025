import { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import ButtonSignInOut from './ButtonSignInOut';

const AuthPanel = ({ className }) => {
  const { isAuth } = useContext(AuthContext);
  return (
    <div className={className}>
      <ButtonSignInOut />
      {!isAuth && (
        <button className="btn btn-outline-secondary">Sign up</button>
      )}
    </div>
  );
};

export default AuthPanel;
