import { useEffect, useState } from 'react';
import useSearchString from '../../utils/useSearchString';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import prepareToPublish from './helpers/prepareToPublish';
import { Modal, Button } from 'react-bootstrap';
// import assetTypeToState from './helpers/assetTypeToState';
import LoadingBar from '../UI/LoadingBar';
import useLoader from '../UI/helpers/useLoader';
import {
    deleteCustomerType,
    updateCustomerType,
} from '../../api/utils/customer/type/setCustomerType';
import { getCustomerType } from '../../api/utils/customer/type/getCustomerType';
import customerTypeToState from './helpers/customerTypeToState';
import CustomerTypeEditForm from './CustomerTypeEditForm';
import BackButton from '../UI/BackButton';

const CustomerTypeEdit = () => {
    const history = useHistory();
    const { id } = useSearchString();

    const [customerType, setCustomerType] = useState({ info: null, types: [] });

    const [progress, done] = useLoader([customerType]);

    const [
        showConfirmRemovecustomerTypeType,
        setShowConfirmRemovecustomerTypeType,
    ] = useState(false);
    const handleShowConfirmRemovecustomerTypeType = () =>
        setShowConfirmRemovecustomerTypeType(true);
    const handleCloseConfirmRemovecustomerTypeType = () =>
        setShowConfirmRemovecustomerTypeType(false);
    const showConfirmDialog = () => {
        handleShowConfirmRemovecustomerTypeType();
    };

    useEffect(() => {
        if (id) {
            fetchCustomerType();
        }
    }, [id]);

    const fetchCustomerType = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                const state = customerTypeToState(data.customer_type);
                console.log('state == ', state);
                setCustomerType({ ...state });
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setCustomerType();
        };

        try {
            const res = await getCustomerType({ id }, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.error(
                'Unexpected error while fetching custsomer type: ',
                error
            );
        }
    };

    const onSubmit = async (customerTypeData) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                const state = customerTypeToState(data.customer_type);
                setCustomerType({ ...state });
                NotificationManager.success('Saved');
            } else {
                NotificationManager.error('Error');
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            const res = await updateCustomerType(
                {
                    id,
                    merchant: customerType.merchant,
                    application: customerType.application,
                    ...prepareToPublish(id, customerTypeData),
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.error(
                'Unexpected error while updating customer type: ',
                error
            );
        }
    };

    const onDelete = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('customer type deleted');
                history.goBack();
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };
        deleteCustomerType(id, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton to="/customer/types" text="Back to list" /> Customer
                type edit (#{id})
            </h2>

            {done ? (
                <CustomerTypeEditForm
                    onSubmit={onSubmit}
                    onDelete={() => {
                        showConfirmDialog();
                    }}
                    defaultValues={customerType}
                />
            ) : (
                <LoadingBar progress={progress} />
            )}
            <Modal
                show={showConfirmRemovecustomerTypeType}
                onHide={handleCloseConfirmRemovecustomerTypeType}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to remove customer type?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseConfirmRemovecustomerTypeType}
                    >
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => onDelete()}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
export default CustomerTypeEdit;
