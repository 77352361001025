import { useEffect, useState } from 'react';
import { getAssets, getAsset } from '../../api/utils/asset/getAssets';
import useSearchString from '../../utils/useSearchString';
import ExpressForm from '../Form/ExpressForm';
import setAssetData from '../../api/utils/asset/setAssetData';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';

const AssetViewRaw = () => {
  const [info, setInfo] = useState({});
  const { asset, merchant } = useSearchString();
  const onSave = (data) => {
    setAssetData(data, errorHandler).then((data) => {
      return resultHandler(data);
    });
  };

  const resultHandler = (data) => {
    if (data && data.status === 'accept') {
      NotificationManager.success('Asset saved', 'Success', 4000);
    }
  };

  const errorHandler = (error) => {
    NotificationManager.error(error.description, 'Error', 4000);
  };

  useEffect(() => {
    getAssets({ merchant, id: asset })
      .then(({ assets: [asset] }) => asset)
      .then(setInfo);
  }, [asset, merchant]);

  return (
    <div>
      <NotificationContainer />
      <ExpressForm data={info} forbiddenFields={[]} onSave={onSave} />
    </div>
  );
};

export default AssetViewRaw;
