import Honkio from '../../Honkio';

const getModelSchema = (options = {}, errorHandler = () => {}) => {
    const { model_name } = options;
    return Honkio().mainShop.userFetch(
        'dataimportgetschema',
        {
            model_name,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};

export default getModelSchema;
