import React from 'react';
import ToolsLink from '../Tools/ToolsLink';

const Scheduler = () => {
    return (
        <div className="container">
            <div className="row row-cols-2">
                <ToolsLink to="/scheduler/jobs-list">Jobs</ToolsLink>
            </div>
        </div>
    );
};

export default Scheduler;
