import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import AnySelector from '../UI/AnySelector';
import mapResponseToState from './helpers/mapResponseToState';
import mapStateToRequest from './helpers/mapStateToRequest';
import PasswordPolicyEdit from './PasswordPolicyEdit';
import { init, initialState, reducer } from './reducer';
import getPasswordPolicy from '../../api/utils/settings/passwordpolicy/getPasswordPolicy';
import {
  createPasswordPolicy,
  updatePasswordPolicy,
  deletePasswordPolicy,
} from '../../api/utils/settings/passwordpolicy/setPasswordPolicy';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';

const DEFAULT_SETTINGS = {
  admins: {
    min_len: 6,
    max_len: 255,
    min_digits: 0,
    min_upper: 0,
    min_lower: 0,
    min_sym: 0,
    min_symbols: 0,
  },
  users: {
    min_len: 6,
    max_len: 255,
    min_digits: 0,
    min_upper: 0,
    min_lower: 0,
    min_sym: 0,
    min_symbols: 0,
  },
};

const PasswordPolicy = () => {
  const [isCreated, setIsCreated] = useState(false);
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);
  const [group, setGroup] = useState();
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const groups = useMemo(() => Object.keys(settings), [settings]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('groups ==', groups);
  }, [groups]);

  useEffect(() => {
    const resultHandler = (data) => {
      setSettings(mapResponseToState(data?.password_policy));
      setLoading(false);
      setIsCreated(true);
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setSettings(mapResponseToState(DEFAULT_SETTINGS));
      setLoading(false);
      setIsCreated(false);
    };

    getPasswordPolicy({}, errorHandler).then((res) => {
      if (res && res?.status === 'accept') {
        return resultHandler(res);
      }
    });
  }, []);

  useEffect(() => {
    if (settings && group) {
      dispatch({ type: 'reset', payload: settings[group] });
    }
  }, [group, settings]);

  useEffect(() => {
    const coercedLength = Object.values(state?.characters).reduce(
      (sum, item) => sum + item
    );
    if (state?.length?.min < coercedLength) {
      dispatch({
        type: 'length',
        payload: { min: coercedLength, max: coercedLength + 1 },
      });
    }
  }, [state?.characters, state?.length?.min]);

  const onChange = useCallback(
    (type, name) =>
      ({ target: { value } }) => {
        dispatch({ type: type, payload: { [name]: parseInt(value) } });
      },
    []
  );

  const onSubmit = async (data) => {
    setLoading(true);

    const resultHandler = (data) => {
      setSettings(mapResponseToState(data?.password_policy));
      setLoading(false);
      setIsCreated(true);
      NotificationManager.success('Updated successfully');
    };

    const errorHandler = (error) => {
      setSettings(mapResponseToState(DEFAULT_SETTINGS));
      setLoading(false);
      setIsCreated(false);
      NotificationManager.error(error.description, 'Error', 4000);
    };

    let submitFunction = () => {
      NotificationManager.info('Submit function not implemented');
    };

    if (isCreated) {
      submitFunction = updatePasswordPolicy;
    } else {
      submitFunction = createPasswordPolicy;
    }

    const submitData = mapStateToRequest(data);
    console.log('onSubmit: submitData ==', submitData);
    let res;
    if (group === 'admins') {
      console.log('submitting for admins');
      res = await submitFunction({ admins: submitData }, errorHandler);
    } else if (group === 'users') {
      console.log('submitting for users');
      res = await submitFunction({ users: submitData }, errorHandler);
    }

    if (res && res?.status === 'accept') {
      resultHandler(res);
    }
  };

  return (
    <div className="container p-3">
      <NotificationContainer />
      <div className="h2 d-flex align-items-center">
        <span className="mr-3">Password Policy for</span>
        <AnySelector
          label="select for whom"
          items={groups}
          nameSelector="name"
          valueSelector="value"
          setItem={setGroup}
        />
      </div>
      <PasswordPolicyEdit
        state={state}
        group={group}
        onChange={onChange}
        onSubmit={onSubmit}
        readyToSubmin={!!group && state?.length?.min}
        className="m-2"
      />
    </div>
  );
};

export default PasswordPolicy;
