import { useEffect, useState } from 'react';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import useSearchString from './../../../utils/useSearchString';
import SmartForm from './../../Form/SmartForm';
import { getDefaultOpenTimes, days } from './helpers/timeHelper';
import ButtonActionForm from './../../Form/elements/ButtonActionForm';
import OpenDaysElement from './OpenDaysElement';
import getAdminShop from './../../../api/utils/shop/getAdminShop';
import prepareToPublish from './../helpers/prepareToPublish';
import { adminUpdateShop } from '../../../api/utils/shop/setAdminShop';
import useLoader from './../../UI/helpers/useLoader';
import LoadingBar from './../../UI/LoadingBar';

const ShopOpenTime = () => {
  const [shopInfo, setShopInfo] = useState(null);
  const [progress, done] = useLoader([shopInfo]);
  const { shop = null } = useSearchString();

  const [currentOpenTimes, setCurrentOpenTimes] = useState(null);
  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const resultHandler = (data) => {
      if (isMounted) {
        if (data?.status === 'accept' && data?.shop) {
          setShopInfo(data.shop);
          if (
            data.shop.settings?.open_time &&
            data.shop.settings?.open_time.length > 0
          ) {
            setCurrentOpenTimes(data.shop.settings.open_time);
          } else {
            setCurrentOpenTimes(getDefaultOpenTimes());
          }
        } else {
          NotificationManager.error('Error');
        }
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    getAdminShop({ id: shop }, errorHandler).then((data) => {
      return resultHandler(data);
    });
  }, [shop]);

  const saveShop = (data) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Saved');
      } else {
        NotificationManager.error('Error');
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    const settings = {
      open_time: data,
    };
    adminUpdateShop(
      { ...prepareToPublish(shopInfo.merchant, shop, { settings }) },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const onSubmit = (data) => {
    saveShop(currentOpenTimes);
  };

  const onChange = (dayName, dayIndex, isActive, interval1, interval2) => {
    let items = [...currentOpenTimes];
    items[dayIndex] = {
      day: dayName,
      is_active: isActive,
      interval: [interval1, interval2],
    };
    setCurrentOpenTimes(items);
  };

  if (done && shop && shopInfo != null && currentOpenTimes != null) {
    return (
      <div>
        <h4>
          Point of sales {shopInfo.settings.str_name} (
          {shopInfo.settings.str_address})
        </h4>
        <NotificationContainer />
        <label className="mb-3">Opening hours</label>
        <SmartForm onSubmit={onSubmit} defaultValues={{}}>
          <div className="col">
            {days.map((day, index) => (
              <OpenDaysElement
                dayIndex={index}
                dayName={day}
                defaActive={currentOpenTimes[index].is_active}
                defaValues={[
                  currentOpenTimes[index].interval[0],
                  currentOpenTimes[index].interval[1],
                ]}
                onChange={onChange}
              />
            ))}
          </div>
          <ButtonActionForm
            label="Save changes"
            name="active"
            value={true}
            type="submit"
          />
        </SmartForm>
      </div>
    );
  } else {
    if (!done) {
      return <LoadingBar progress={progress} />;
    } else {
      return 'Shop is not found';
    }
  }
};

export default ShopOpenTime;
