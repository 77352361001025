import { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import UserInfoContext from '../../contexts/UserInfoContext';
import Selector from './Selector';
import useSearchString from '../../utils/useSearchString';
import { Modal, Button } from 'react-bootstrap';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import getAdminRolesList from '../../api/utils/role/getAdminRolesList';
import { deleteAdminRole } from '../../api/utils/role/setAdminRole';
import RoleModal from './RoleModal';
import _ from 'lodash';
import setDefaultRecord from '../../api/utils/app/default/setDefaultRecord';

const MerchantRolesList = ({ language = 'en' }) => {
    const [roles, setRoles] = useState(null);
    const [loading, setLoading] = useState(false);

    const userInfo = useContext(UserInfoContext);

    const { appid = null, appname = '' } = useSearchString();
    const appId = appid
        ? appid
        : userInfo.app?.id
        ? userInfo.app.id
        : undefined;
    const appName = appid ? appname : userInfo.app?.id ? userInfo.app.name : '';

    const { merchant = null, merchant_name = '' } = useSearchString();
    const merchantId = merchant
        ? merchant
        : appid
        ? -1
        : userInfo.merchant?.id
        ? userInfo.merchant.id
        : -1;
    const merchantName = merchant
        ? merchant_name
        : appid
        ? ''
        : userInfo.merchant
        ? userInfo.merchant.name
        : '';

    const pageTitle = `User groups ${appId ? `${appName}` : '-'} / ${
        merchantName ? `${merchantName}` : '-'
    }`;

    const [showConfirmRemoveRole, setShowConfirmRemoveRole] = useState(false);
    const handleShowConfirmRemoveRole = () => setShowConfirmRemoveRole(true);
    const handleCloseConfirmRemoveRole = () => setShowConfirmRemoveRole(false);
    const [roleToRemove, setRoleToRemove] = useState({});

    const [showRolePreview, setShowRolePreview] = useState(false);
    const handleShowRolePreview = () => setShowRolePreview(true);
    const handleCloseRolePreview = () => setShowRolePreview(false);
    const [currentRole, setCurrentRole] = useState();

    useEffect(() => {
        if (merchantId && appId) {
            setLoading(true);
            fetchRoles();
        }
    }, [merchantId, appId]);

    const fetchRoles = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept' && data.roles) {
                setRoles(data.roles);
                setLoading(false);
            } else {
                setLoading(false);
                setRoles([]);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setRoles([]);
            setLoading(false);
        };

        try {
            const res = await getAdminRolesList(
                {
                    appid: appId,
                    merchant: merchantId === -1 ? null : merchantId,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log(
                'Unexpected error while fetching merchant roles: ',
                error
            );
        }
    };

    const onDeleteClick = (id, name) => {
        setRoleToRemove({ id, name });
        handleShowConfirmRemoveRole();
    };

    const deleteRole = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Role removed', 'Success', 4000);
                handleCloseConfirmRemoveRole();
                fetchRoles();
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        deleteAdminRole(
            {
                id: roleToRemove.id,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .then(handleCloseConfirmRemoveRole);
    };

    let onRowClick = (row) => {
        setCurrentRole(row);
        handleShowRolePreview();
    };

    const onSetDefault = async (id, is_default) => {
        const resultHandler = (res) => {
            if (res && res.status === 'accept') {
                let newRoles = _.cloneDeep(roles);
                const idx = newRoles.findIndex(
                    (item) => item._id === res.record._id
                );
                newRoles[idx].is_default = res.record.is_default;

                setRoles(newRoles);

                setLoading(false); // this helps re-render table

                // Alternative in case table starts glitching again
                // fetchRoles(1);
            } else {
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
        };

        try {
            setLoading(true);
            const res = await setDefaultRecord(
                {
                    id,
                    is_default,
                    model_name: 'role',
                    merchant: merchantId,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            console.log('Error setting record as default: ', error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>{pageTitle}</h2>
            {appId && (
                <div className="m-1">
                    <Link
                        to={`/role/create?appid=${appId}${
                            merchantId !== -1 ? `&merchant=${merchantId}` : ''
                        }`}
                        className="btn btn-outline-primary m-1"
                    >
                        <i className="bi-plus-square ml-1 mr-2" />{' '}
                        {merchantId !== -1
                            ? `Create role for ${merchantName}`
                            : 'Create template role'}
                    </Link>
                </div>
            )}
            <Selector
                loading={loading}
                items={roles}
                appid={appId}
                onDeleteClick={onDeleteClick}
                onRowClicked={onRowClick}
                language={language}
                onSetDefault={onSetDefault}
                isAdmin={!!userInfo?.merchant?.is_admin}
            />
            {currentRole && (
                <RoleModal
                    roleId={currentRole._id}
                    show={showRolePreview}
                    onHide={handleCloseRolePreview}
                ></RoleModal>
            )}
            <Modal
                show={showConfirmRemoveRole}
                onHide={handleCloseConfirmRemoveRole}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Do you want to remove role{' '}
                        <b>{roleToRemove ? roleToRemove.name : ''}</b>?{' '}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseConfirmRemoveRole}
                    >
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => deleteRole()}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MerchantRolesList;
