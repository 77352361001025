import Icon from '../../UI/Icon';
import { Button, ListGroup } from 'react-bootstrap';
import ConditionItem from './ConditionItem';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';

const ConditionsList = ({
  accessRule,
  addCondition = () => {},
  removeCondition = () => {},
  className,
  editConditionField = () => {},
}) => {
  const handleAddCondition = () => {
    addCondition({
      id: uuidv4(),
      subject_field: '_id',
      object_field: 'user_owner',
      subject_constant: '',
      object_constant: '',
      operator: 'eq',
      field_type: 'object_id',
    });
  };

  const handleRemoveCondition = (index) => {
    removeCondition(index);
  };

  return (
    <div className={cn(className)}>
      <div className="row">
        <div className="col d-flex align-items-center">
          <h4>Conditions</h4>
        </div>
        <div>
          <Button
            onClick={handleAddCondition}
            variant="outline"
            className="shadow-none"
          >
            <Icon iconName="bi-plus-circle" size="2" />
          </Button>
        </div>
      </div>
      <ListGroup>
        {accessRule?.conditions?.length ? (
          accessRule.conditions.map((item, idx) => (
            <ConditionItem
              key={`${item?.id}`}
              item={item}
              idx={idx}
              onUpdate={editConditionField}
              onDelete={() => handleRemoveCondition(item?.id)}
            />
          ))
        ) : (
          <ListGroup.Item>No conditions added yet...</ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
};

export default ConditionsList;
