import React, { Component } from 'react';
import WidgetComponent from './WidgetComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Pie } from 'react-chartjs-2';
import { TimeRange } from 'pondjs';

import moment from 'moment';
import 'moment/locale/fi';
import HonkioAPI from '../../../api/Honkio';

const actualLocale = moment.locale(localStorage.getItem('i18nextLng') ?? 'en');

const baselineStyle = {
  line: {
    stroke: 'gray',
    strokeWidth: 1,
  },
};

const chartColors = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
};

class BuildingIncomePieComponent extends Component {
  constructor(props) {
    super(props);
    this.refreshData = this.refreshData.bind(this);
    let start = moment()
      .subtract(9, 'month')
      .startOf('month')
      .toDate()
      .getTime();
    let end = moment().add(3, 'month').startOf('month').toDate().getTime();
    // alert(start)
    // alert(end)
    this.state = {
      loading: true,
      timerange: new TimeRange([start, end]),
    };
  }

  componentDidMount() {
    // this.refreshData();
    let that = this;
    this.setContainerRef = (element) => {
      // alert(1)
      that.container = element;
      // alert(that.container)
    };
    this.loadBuildings();
  }

  loadBuildings() {
    let that = this;
    let merchantgetbuildings = async function () {
      let response = await HonkioAPI().userShop.userFetch(
        'merchantbuildinglist',
        { merchant_id: localStorage.getItem('merchant_id') }
      );
      let buildings = response.buildings;
      let buildingCategories = [];
      response.buildings.forEach((building) => {
        buildingCategories.push({
          key: building.name,
          label: building.name,
        });
      });
      that.setState({
        buildings: buildings,
        buildingCategories: buildingCategories,
        loading: false,
      });
      that.refreshData(buildings);
    };
    merchantgetbuildings();
  }

  refreshData(buildingsList) {
    let that = this;
    that.setState({ loading: true });
    let merchantarealist = async function () {
      let buildings = buildingsList ? buildingsList : that.state.buildings;
      let buildingNames = {};
      let data = {};
      let points = [];
      let min = 0,
        max = 0;
      let indexes = [];
      // alert(buildings.length)
      let values = {};
      for (var i = 0; i < buildings.length; i++) {
        let building = buildings[i];
        buildingNames[building.id] = building.name;
        let response = await HonkioAPI().userShop.userFetch(
          'merchantpaymentinvoicereport',
          {
            query_properties: { building: { eq: building.id } },
          }
        );

        // let a = val

        response.payment_invoices.forEach((val) => {
          // const index = Index.getIndexString("1m", moment(val.timestamp).startOf('month').toDate());
          // if (indexes.indexOf(index) < 0) {
          //   indexes.push(index)
          // }
          // alert(val.data ? 'data': 'i')
          // const payment = parseInt(val.payment || 0);
          // const invoice = parseInt(val.invoice || 0);
          const value = parseInt(val.invoice || 0);
          // alert(value)
          values[building.name] = value;
          // if (value > max)  max = value
          // if (value < min)  min = value
          // values.push([index, value])
        });

        // data[building.id] = values
        // points.push([building.name, ...values])
      }
      // alert('k' + )
      // alert('v' + chartColors[Math.floor(Math.random() * chartColors.length)])
      // let colors = []
      // let vals = []
      // let keys = Object.keys(chartColors)
      // Object.keys(values).forEach(o => colors.push(chartColors[keys[ keys.length * Math.random() << 0]]))
      // Object.values(values).forEach(o => vals.push(`${o}`))
      // let dataPie = {
      // labels: [...Object.keys(values)],
      // datasets: [{
      //   data: vals,
      //   backgroundColor: colors,
      //
      //   label: 'Rented Area' }] };

      // alert(dataPie)
      // alert(Object.keys(dataPie))
      // alert(Object.values(dataPie))
      let colors = [];
      let keys = Object.keys(chartColors);
      Object.keys(values).forEach((o) =>
        colors.push(chartColors[keys[(keys.length * Math.random()) << 0]])
      );
      that.setState({ dataPie: values, colors: colors, loading: false });
    };
    merchantarealist();
  }
  onResize = (a, b, c) => {
    console.error('dddddddddd');
    console.error(a);
    console.error(b);
    console.error(c);
  };

  render() {
    let drawChart = (height, width) => {
      console.error('DATA PIE');
      console.error(this.state.dataPie);

      let vals = [];

      // Object.values(this.state.dataPie).forEach(o => vals.push(`${o}`))
      // alert(Object.keys(this.state.dataPie))
      Object.values(this.state.dataPie).forEach((val) =>
        vals.push(parseInt(val))
      );

      // alert(Object.keys(this.state.dataPie))
      let dataPie = {
        labels: Object.keys(this.state.dataPie),
        datasets: [
          {
            data: vals, //[1,2,3,4],
            backgroundColor: this.state.colors,

            label: 'Rented area',
          },
        ],
      };

      let optionsPie = {
        hover: {
          mode: 'nearest',
          intersect: true,
        },

        title: {
          display: true,
        },

        legend: {
          display: true,
        },

        tooltips: {
          enabled: true,
        },

        scales: {
          xAxes: [
            {
              display: false,
            },
          ],

          yAxes: [
            {
              display: false,
            },
          ],
        },
      };
      //
      // let wh = height < width ? height : width;
      // console.error()
      // alert(wh + ' ' + width + ' ' + height)
      return (
        <Pie
          data={dataPie}
          title="asd"
          options={optionsPie}
          // width={wh}
          // height={wh}
        />
      );
    };

    // alert(this.state.incomeSeries)
    return (
      <WidgetComponent
        position={this.props.position}
        height={this.props.height}
        width={this.props.width}
        icon="fas fa-money-bill-wave fa-2x"
        name={this.props.name}
        type="Building income"
        onRename={this.props.onRename}
        onRemoveClick={this.props.onRemoveClick}
        onSettingsClick={this.props.onSettingsClick}
        summary1={null}
        chart={
          this.state.loading || !this.state.dataPie
            ? () => <CircularProgress size={35} />
            : drawChart
        }
        configFields={[]}
      />
    );
  }
}

export default BuildingIncomePieComponent;
