// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';
// import { getMessaging } from 'firebase/messaging';

import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken } from '@firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: 'AIzaSyD_WSI8ICOcErhSVtp0ZElWJebFX5Awqtg',

    authDomain: 'honkio-push-notifications.firebaseapp.com',

    projectId: 'honkio-push-notifications',

    storageBucket: 'honkio-push-notifications.appspot.com',

    messagingSenderId: '515457080512',

    appId: '1:515457080512:web:ae90568e81a5fff851e125',

    measurementId: 'G-9MG3D8JW6Q',
};

// Initialize Firebase

try {
    const app = initializeApp(firebaseConfig);

    const analytics = getAnalytics(app);
} catch (error) {
    console.log('Error initializing firebase app');
}

export const messaging = getMessaging();

export const VAPID_KEY =
    'BC7f5gv2CmMkB1iLEyhLCQ1m-KIXJurnMMaAEw62oBXiBOOiXaOxIPMDH-Qt9U-kya3Ik7Jgdgpdr2t3td2avsU';
