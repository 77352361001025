import {
  ButtonActionForm,
  CheckboxSwitch,
  InputFieldWithValidation,
} from '../Form/elements/formElements';
import SmartForm from '../Form/SmartForm';
import prepareToEdit from './helpers/prepareToEdit';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { getMerchantOrderProcessList } from '../../api/utils/orderModel/getMerchantOrderProcessList';
import { NotificationManager } from 'react-notifications';

const VelocityRuleEditForm = (props) => {
  const { velocityRule, onDelete } = props;
  const [velocityRuleId, setVelocityRuleId] = useState(velocityRule?._id);
  const [orderTypeList, setOrderTypeList] = useState([]);
  const [orderModels, setOrderModels] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  //    const defaultValues = prepareToEdit(velocityRule);

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (velocityRule?.order_types) {
      setOrderTypeList(velocityRule.order_types);
    } else {
      setOrderTypeList([]);
    }
    setDefaultValues(prepareToEdit(velocityRule));
  }, [velocityRule]);

  useEffect(() => {
    if (velocityRule) {
      fetchOrderModelList();
    }
  }, [velocityRule]);

  const fetchOrderModelList = () => {
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    getMerchantOrderProcessList({}, errorHandler).then((res) => {
      console.log(res);
      if (res.status === 'accept') {
        setOrderModels(res.orderprocesses);
      } else {
        NotificationManager.error('Error getting order models', 'Error', 4000);
      }
    });
  };

  const orderTypesToOptions = (what) => {
    if (what)
      return Object.keys(what).map((k) => {
        return { value: what[k].id, label: what[k].name };
      });
  };

  const orderTypesToValues = (what) => {
    if (what) {
      return what.map((k) => {
        const type = orderModels.find((e) => e.id === k);
        if (type) {
          return { value: k, label: type.name };
        }
      });
    } else {
      return null;
    }
  };

  const onSubmit = (data) => {
    props.onSubmit({
      ...data,
      order_types: orderTypeList,
    });
  };
  const pageHeader = velocityRule?._id
    ? `Velocity rule (ID#${velocityRuleId})`
    : 'New velocity rule';
  return (
    velocityRule && (
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-md-1">
            <h4>{pageHeader}</h4>
            <SmartForm
              onValid={() => {}}
              onSubmit={onSubmit}
              defaultValues={defaultValues}
            >
              <div className="row">
                <div className="col-md-12">
                  <CheckboxSwitch
                    name="active"
                    label="Active"
                    className="mb-3"
                  />
                  <InputFieldWithValidation
                    className="mb-3"
                    name="name"
                    label="Velocity Rule's name"
                    type="text"
                    required={true}
                  />
                  <InputFieldWithValidation
                    className="mb-3"
                    name="description"
                    label="Velocity Rule's description"
                    type="text"
                    required={true}
                  />
                  <InputFieldWithValidation
                    className="mb-3"
                    name="order_sum_limit"
                    label="Order sum limit"
                    type="number"
                  />
                  Order types
                  <Select
                    isMulti
                    className="mb-3"
                    name="order_types"
                    label="Order Types"
                    options={orderTypesToOptions(orderModels)}
                    type="text"
                    value={orderTypesToValues(orderTypeList)}
                    onChange={(e) => {
                      setOrderTypeList(e.map((e) => e.value));
                    }}
                  />
                  <hr />
                  USAGE LIMIT
                  <div className="row">
                    <InputFieldWithValidation
                      className="col-md-5 mb-3 mr-3"
                      name="usage_limit_period"
                      label="Period (days)"
                      type="number"
                    />
                    <InputFieldWithValidation
                      className="col-md-5 mb-3 mr-3"
                      name="usage_limit_amount"
                      label="Amount"
                      type="number"
                    />
                  </div>
                  <hr />
                  SUM BY PERIOD LIMIT
                  <div className="row">
                    <InputFieldWithValidation
                      className="col-md-5 mb-3 mr-3"
                      name="sum_by_period_limit_period"
                      label="Period (days)"
                      type="number"
                    />
                    <InputFieldWithValidation
                      className="col-md-5 mb-3 mr-3"
                      name="sum_by_period_limit_sum"
                      label="Sum"
                      type="number"
                    />
                  </div>
                  <hr />
                  ACTIONS
                  <div className="row">
                    <InputFieldWithValidation
                      className="col-md-5 mb-3 mr-3"
                      name="action_change_risk_rating"
                      label="Change risk rating"
                      type="number"
                    />
                    <div className="col-md-5 mb-3 mr-3">
                      <CheckboxSwitch name="action_abort" label="Abort" />
                      <CheckboxSwitch
                        name="action_notify_aml"
                        label="Notify aml"
                      />
                    </div>
                  </div>
                </div>
                <hr className="mb-4" />
                <ButtonActionForm
                  label="Save changes"
                  name="active"
                  value={true}
                  type="submit"
                />
                <button
                  type="button"
                  className="btn btn-danger btn-block"
                  onClick={onDelete}
                >
                  Delete
                </button>
              </div>
            </SmartForm>
          </div>
        </div>
      </div>
    )
  );
};

export default VelocityRuleEditForm;
