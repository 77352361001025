import React from 'react';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import DataTable from 'react-data-table-component';
import { useLayoutEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Icon from '../../UI/Icon';
import Wait from '../../UI/Wait';

const StatusesTable = ({
    data,
    filterSearch = (i) => i,
    filterType = (i) => i,
    keyword,
    type,
    loading,
    onRowClicked,
    totalRows,
    rowsCountPerPage,
    handleRowsPerPage = () => {},
    onDeleteClick = () => {},
    perPageChoices = [25],
    handlePageChange = () => {},
}) => {
    const columns = useMemo(
        () => [
            {
                name: 'ID',
                grow: 2,
                cell: ({ _id }, index) => _id,
            },
            {
                name: 'Status',
                grow: 1,
                cell: ({ status }) => status,
            },
            {
                name: 'Creation date',
                grow: 1,
                cell: ({ creation_date }) => creation_date,
            },
        ],
        []
    );
    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            onChangeRowsPerPage={handleRowsPerPage}
            paginationServer
            progressPending={loading}
            onRowClicked={onRowClicked}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default StatusesTable;
