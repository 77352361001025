const colorMap = {
  payments: 'green',
  tokenization: 'blue',
  login: 'gray',
  registration: 'red',
  lostpassword: 'orange',
  order: 'lime',
  bonus: 'crimson',
  mlrequest: 'cyan',
};

const Legend = ({ setItem = () => { } }) => {
  return (
    <div className="border rounded d-flex">
      {Object.entries(colorMap).map(([type, color], index) => (
        <div
          className="border rounded m-1 px-2 w-25 flex-grow-1 text-center"
          style={{ color }}
          key={index}
          onClick={() => setItem(type)}
        >
          {type}
        </div>
      ))}
    </div>
  );
};

export { colorMap };
export default Legend;
