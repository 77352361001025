import { useContext, useEffect, useState } from 'react';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import getAssetTypeList from '../../api/utils/asset/type/getAssetTypeList';
import AssetTypeList from './AssetTypeList';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import UserInfoContext from '../../contexts/UserInfoContext';
import { Modal, Button } from 'react-bootstrap';
import { deleteAssetType } from '../../api/utils/asset/type/setAssetType';
import AssetTypeModal from './AssetTypeModal';
import _ from 'lodash';
import setDefaultRecord from '../../api/utils/app/default/setDefaultRecord';

const AssetTypes = () => {
    const { app, merchant } = useContext(UserInfoContext);
    const merchantId = merchant?.id;
    const appId = app?.id;

    const pageTitle = `Asset types (${appId ? app.name : '-'} / ${
        merchantId ? merchant.name : '-'
    })`;

    const [assetTypeLst, setAssetTypeLst] = useState();
    const [loading, setLoading] = useState(false);

    const [showConfirmRemoveAssetType, setShowConfirmRemoveAssetType] =
        useState(false);
    const handleShowConfirmRemoveAssetType = () =>
        setShowConfirmRemoveAssetType(true);
    const handleCloseConfirmRemoveAssetType = () =>
        setShowConfirmRemoveAssetType(false);
    const [assetTypeToRemove, setAssetTypeToRemove] = useState({});

    const [showAssetTypePreview, setShowAssetTypePreview] = useState(false);
    const handleShowAssetTypePreview = () => setShowAssetTypePreview(true);
    const handleCloseAssetTypePreview = () => setShowAssetTypePreview(false);
    const [currentAssetType, setCurrentAssetType] = useState();

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        fetchAssetTypes();
    }, [merchantId, appId]);

    const fetchAssetTypes = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept' && data.asset_types) {
                    setAssetTypeLst(data.asset_types);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setAssetTypeLst([]);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setAssetTypeLst([]);
            setLoading(false);
        };
        if (appId) {
            setLoading(true);
            try {
                const res = await getAssetTypeList(
                    { query_merchant: merchantId, query_application: appId },
                    errorHandler
                );
                resultHandler(res);
            } catch (error) {
                console.log(
                    'Unexpected error while fetching asset types: ',
                    error
                );
            }
        } else {
            setLoading(false);
            setAssetTypeLst([]);
        }
    };

    const onDeleteClick = (id, name) => {
        setAssetTypeToRemove({ id, name });
        handleShowConfirmRemoveAssetType();
    };

    const onSetDefault = async (id, is_default) => {
        const resultHandler = (res) => {
            if (res && res.status === 'accept') {
                let newAssetTypeList = _.cloneDeep(assetTypeLst);
                const idx = newAssetTypeList.findIndex(
                    (item) => item._id === res.record._id
                );
                newAssetTypeList[idx].is_default = res.record.is_default;

                setAssetTypeLst(newAssetTypeList);

                setLoading(false);
            } else {
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
        };

        try {
            setLoading(true);
            const res = await setDefaultRecord(
                {
                    id,
                    is_default,
                    model_name: 'asset_type',
                    merchant: merchantId,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            console.log('Error setting record as default: ', error);
        }
    };

    const deleteSelectedAssetType = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Asset type removed',
                    'Success',
                    4000
                );
                handleCloseConfirmRemoveAssetType();
                fetchAssetTypes();
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        deleteAssetType(assetTypeToRemove.id, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .then(handleCloseConfirmRemoveAssetType);
    };

    let onRowClick = (row) => {
        setCurrentAssetType(row);
        handleShowAssetTypePreview();
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <h2>{pageTitle}</h2>
            {appId ? (
                <div>
                    <Link
                        to={`/asset/type/create`}
                        className="btn btn-outline-primary mt-1 mb-3"
                    >
                        <i className="bi-plus-square ml-1 mr-2 " /> Create asset
                        type
                    </Link>

                    <Link
                        to={`/asset/types/scheme`}
                        className="btn btn-outline-primary mt-1 mb-3 ml-2"
                    >
                        <i className="bi-diagram-2 ml-1 mr-2 " /> Asset types
                        hierarchy
                    </Link>

                    <AssetTypeList
                        assetTypes={assetTypeLst}
                        loading={loading}
                        onRowClicked={onRowClick}
                        onDeleteClick={onDeleteClick}
                        emptyText="The asset types list is empty"
                        onSetDefault={onSetDefault}
                    />
                </div>
            ) : (
                <label>Please, select application first</label>
            )}
            {currentAssetType && (
                <AssetTypeModal
                    assetTypeId={currentAssetType._id}
                    show={showAssetTypePreview}
                    onHide={handleCloseAssetTypePreview}
                ></AssetTypeModal>
            )}
            <Modal
                show={showConfirmRemoveAssetType}
                onHide={handleCloseConfirmRemoveAssetType}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Do you want to remove asset type{' '}
                        <b>{assetTypeToRemove ? assetTypeToRemove.name : ''}</b>
                        ?{' '}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseConfirmRemoveAssetType}
                    >
                        Close
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => deleteSelectedAssetType()}
                    >
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AssetTypes;
