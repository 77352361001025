import { useEffect, useState, useContext } from 'react';
import { getOrders } from '../../api/utils/order/getOrders';
import useSearchString from '../../utils/useSearchString';
import ExpressForm from '../Form/ExpressForm';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import UserInfoContext from '../../contexts/UserInfoContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getAdminOrder } from './../../api/utils/order/getOrders';

const OrderEdit = () => {
  const [info, setInfo] = useState({});
  const { order, merchant } = useSearchString();

  const userInfo = useContext(UserInfoContext);
  const merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;

  const onSave = (data) => {
    console.log({ data });
    NotificationManager.error('Not implemented');
  };

  const resultHandler = (data) => {
    if (data && data.status === 'accept') {
      setInfo(data.order);
    }
  };

  const errorHandler = (error) => {
    NotificationManager.error(error.description, 'Error', 4000);
  };

  useEffect(() => {
    const params = {
      id: order,
    };
    getAdminOrder(params, errorHandler).then((data) => {
      return resultHandler(data);
    });
  }, [order, merchant]);

  if (!Object.keys(info).length) {
    return <label>No Data</label>;
  }

  return (
    <div>
      <NotificationContainer />
      <Row className="pt-2 pb-2">
        <Col md={3}>
          <b>Status: </b>
        </Col>
        <Col md={9}>{info.order_status}</Col>
      </Row>
      <Row className="pt-2 pb-2">
        <Col md={3}>
          <b>Status Before: </b>
        </Col>
        <Col md={9}>{info.order_status_before}</Col>
      </Row>
      <Row className="pt-2 pb-2">
        <Col md={3}>
          <b>Start date: </b>
        </Col>
        <Col md={9}>{info.start_date}</Col>
      </Row>
      <Row className="pt-2 pb-2">
        <Col md={3}>
          <b>End date: </b>
        </Col>
        <Col md={9}>{info.end_date}</Col>
      </Row>
      <Row className="pt-2 pb-2">
        <Col md={3}>
          <b>Expire date: </b>
        </Col>
        <Col md={9}>{info.expire_date}</Col>
      </Row>
      <Row className="pt-2 pb-2">
        <Col md={3}>
          <b>Amount: </b>
        </Col>
        <Col md={9}>
          {info.amount} {info.currency}
        </Col>
      </Row>
      <Row className="pt-2 pb-2">
        <Col md={3}>
          <b>Products: </b>
        </Col>
        <Col md={9}>
          {info.products.map((product) => {
            return (
              <fieldset className="border mb-2 pl-2">
                <Row className="pt-2 pb-2">
                  <Col md={3}>
                    <b>Name: </b>
                  </Col>
                  <Col md={9}>{product.name}</Col>
                </Row>
                <Row className="pt-2 pb-2">
                  <Col md={3}>
                    <b>Amount: </b>
                  </Col>
                  <Col md={9}>
                    {product.amount} {product.currency}
                  </Col>
                </Row>
                <Row className="pt-2 pb-2">
                  <Col md={3}>
                    <b>Count: </b>
                  </Col>
                  <Col md={9}>{product.count}</Col>
                </Row>
              </fieldset>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

export default OrderEdit;
