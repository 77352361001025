import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Icon from '../../../UI/Icon';
import { Button } from 'react-bootstrap';
import NumberOfReturnsItem from './NumberOfReturnsItem';

const NumberOfReturns = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'number_of_returns',
  });

  const handleAddNumberOfReturnsItem = () => {
    append({ value: 1000000 });
  };
  const handleDeleteNumberOfReturnsItem = (index) => {
    remove(index);
  };

  return (
    <>
      <div className="row">
        <div className="col d-flex align-items-center">
          <h4>Number of returns a month</h4>
        </div>
        <div>
          <Button
            onClick={handleAddNumberOfReturnsItem}
            variant="outline"
            className="shadow-none"
          >
            <Icon iconName="bi-plus-circle" size="2" />
          </Button>
        </div>
      </div>
      <ListGroup>
        {fields.length ? (
          fields.map((item, idx) => (
            <NumberOfReturnsItem
              key={item.id}
              item={item}
              idx={idx}
              onDelete={handleDeleteNumberOfReturnsItem}
            />
          ))
        ) : (
          <ListGroup.Item>
            No number of returns items added yet...
          </ListGroup.Item>
        )}
      </ListGroup>
    </>
  );
};

export default NumberOfReturns;
