const PaymentsOverviewEntry = ({
  payment: { name, date, amount, discount },
  sign,
}) => {
  return (
    <li className="list-group-item d-flex justify-content-between lh-condensed">
      <div className={discount && 'text-success'}>
        <h6 className="my-0">{name}</h6>
        <small className="text-muted">{date}</small>
      </div>
      <span className="text-muted">
        {sign}
        {amount}
      </span>
    </li>
  );
};

export default PaymentsOverviewEntry;
