import React, { Component } from 'react';
import WidgetComponent from './WidgetComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  LineChart,
  ScatterChart,
  ChartContainer,
  Charts,
  ChartRow,
  YAxis,
  styler,
  Baseline,
  EventMarker,
} from 'react-timeseries-charts';
import { TimeSeries, Index, TimeRange } from 'pondjs';
import moment from 'moment';
import HonkioAPI from '../../../api/Honkio';

const baselineStyle = {
  line: {
    stroke: 'gray',
    strokeWidth: 1,
  },
};

class TemperatureWidgetComponent extends Component {
  constructor(props) {
    super(props);
    this.refreshData = this.refreshData.bind(this);
    this.state = {
      loading: true,
      timerange: new TimeRange(
        moment(this.props.start_date).toDate(),
        moment(this.props.end_date).toDate()
      ),
    };
  }

  componentDidMount() {
    this.refreshData(true);
    let that = this;
    this.setContainerRef = (element) => {
      that.container = element;
    };
  }

  handleTrackerChanged = (t) => {
    try {
      if (t) {
        const e = this.state.temperatureSeries.atTime(t);
        const eventTime = new Date(
          e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
        );

        const eventValue = e.get('temperature');

        const v = `${eventValue > 0 ? '+' : ''}${parseFloat(eventValue).toFixed(
          2
        )}°C ${moment(eventTime).format('LLL')}`;
        this.setState({ tracker: eventTime, trackerValue: v, trackerEvent: e });
      } else {
        this.setState({
          tracker: null,
          trackerValue: null,
          trackerEvent: null,
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({ tracker: null, trackerValue: null, trackerEvent: null });
    }
  };
  renderMarker = () => {
    if (this.state.markerMode === 'flag') {
      return (
        <EventMarker
          type="flag"
          axis="axis"
          event={this.state.trackerEvent}
          column="temperature"
          markerLabelAlign="bottom"
          info={[{ label: 'Anomaly', value: this.state.trackerValue }]}
          infoTimeFormat="%Y"
          infoWidth={120}
          markerRadius={2}
          markerStyle={{ fill: 'black' }}
        />
      );
    } else {
      return (
        <EventMarker
          type="point"
          axis="axis"
          event={this.state.trackerEvent}
          column="temperature"
          markerLabel={this.state.trackerValue}
          markerLabelAlign="bottom"
          markerLabelStyle={{ fill: '#1f2c82', stroke: 'white' }}
          markerRadius={3}
          markerStyle={{ fill: 'black' }}
        />
      );
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.refreshData(
      true,
      new TimeRange(
        moment(this.props.start_date).toDate(),
        moment(this.props.end_date).toDate()
      )
    );
  }

  refreshData(loader, queryTimerange) {
    let that = this;
    if (loader) {
      that.setState({ loading: true });
    }
    let load = async function () {
      let query = {
        query_aggregate: ['VALUE'],
        // query_merchant: localStorage.getItem('merchant_id'),
        query_asset: that.props.assetId,
      };

      // let period = extractPeriod('s')
      // if (that.props.start_date) {
      let timerange = queryTimerange ? queryTimerange : that.state.timerange;
      // if (!timerange) {
      //     timerange = that.state.timerange;
      // }
      query['query_start_date'] = moment(timerange.begin()).format(
        'YYYY-MM-DD hh:mm:ss'
      );
      // }
      // if (that.props.end_date) {
      query['query_end_date'] = moment(timerange.end())
        .add(12, 'hours')
        .format('YYYY-MM-DD hh:mm:ss');
      // }
      let response = await HonkioAPI().userShop.userFetch(
        'merchantasseteventlist',
        query
      );
      let min = null;
      let max = 0;
      let avg = 0;
      let points = [];
      if (response.events && response.events.length > 0) {
        let events = response.events;
        // events.sort(function(a,b){
        //   return moment(a.timestamp).toDate() - moment(b.timestamp).toDate();
        // });
        events.forEach((val) => {
          const index = Index.getIndexString(
            '1m',
            moment(val.timestamp).add(moment().utcOffset(), 'minutes').toDate()
          );
          const temperature = parseFloat(val.VALUE);
          points.push([index, temperature]);
          if (temperature > max) max = temperature;
          if (temperature < min || min === null) min = temperature;
          avg += temperature;
        });
        avg = parseFloat(avg / response.events.length);

        const temperatureSeries = new TimeSeries({
          name: 'Temperature',
          columns: ['index', 'temperature'],
          points: points,
        });
        let start = moment(temperatureSeries.timerange().begin());
        let end = moment(temperatureSeries.timerange().end());
        var duration = moment.duration(end.diff(start));
        var hours = parseInt(Math.abs(duration.asHours() * 0.05));

        that.setState({
          events: response.events,
          temperatureSeries: temperatureSeries,
          timerange: queryTimerange
            ? queryTimerange
            : new TimeRange(start, end.add(hours, 'hours')),
          min: min,
          max: max,
          avg: avg,
          loading: false,
        });
      } else {
        that.setState({
          loading: false,
          temperatureSeries: new TimeSeries({
            name: 'Temperature',
            columns: ['index', 'temperature'],
            points: [],
          }),
        });
      }
    };
    load();
  }

  handleTimeRangeChange = (timerange) => {
    let that = this;
    if (this.state.zooming) {
      clearTimeout(this.state.zooming);
    }
    this.setState({
      timerange,
      zooming: setTimeout(function () {
        let start = moment(timerange.begin()).toDate();
        let end = moment(timerange.end());
        var duration = moment.duration(end.diff(start));
        var hours = parseInt(Math.abs(duration.asHours() * 0.1));
        that.refreshData(
          undefined,
          new TimeRange(start, end.add(hours, 'hours').toDate())
        );
      }, 200),
    });
  };

  render() {
    const points = [];
    let line;
    let summary1, summary2;
    let drawChart = (height, width) => {
      const style = styler([
        { key: 'temperature', color: '#80cdc0', width: 3 },
      ]);
      const axisStyle = {
        values: {
          labelColor: 'grey',
          labelWeight: 100,
          labelSize: 11,
        },
        axis: {
          axisColor: 'grey',
          axisWidth: 1,
        },
      };
      let dateFormat = undefined;

      // if (this.state.temperatureSeries && this.state.temperatureSeries.timerange()) {
      //     let r = this.state.temperatureSeries.timerange()
      //     var duration = moment.duration(moment(r.end()).diff(moment(r.begin())));
      //     var days = duration.asDays();
      // //     // alert(days)
      // //     if (days > parseInt(365/2)) dateFormat = 'year'
      // //     else if (days > 60) dateFormat = 'month'
      // //     else if (days <= 7 && days > 3) dateFormat = ''
      // //      if (days <= 7  && days >= 3) {
      // //          alert('week')
      //          // dateFormat = '%A'
      //      // }
      // //     else if (days <= 3) dateFormat = undefined
      // //
      // //
      // //     // alert(dateFormat)
      // }

      const min = this.state.min;
      const max = this.state.max;
      return this.state.temperatureSeries.collection.length > 0 ? (
        <ChartContainer
          timeRange={this.state.timerange}
          timeAxisStyle={axisStyle}
          format={dateFormat}
          minDuration={1000 * 60 * 5}
          maxTime={moment(this.props.end_date).toDate()}
          minTime={moment(this.props.start_date).toDate()}
          enablePanZoom={true}
          onTimeRangeChanged={this.handleTimeRangeChange}
          onTrackerChanged={this.handleTrackerChanged}
          width={width}
          height={height}
          padding={10}
        >
          <ChartRow height={height} width={width}>
            <YAxis
              id="axis"
              label="Temperature"
              transition={300}
              f
              style={axisStyle}
              labelOffset={0}
              min={parseInt(min > 0 ? min * 0.7 : min * 1.1)}
              max={parseInt(max * 1.1)}
              format={',.1f'}
              width="60"
              type="linear"
            />
            <Charts>
              {/*<LineChart*/}
              {/*    axis="axis"*/}
              {/*    series={this.state.temperatureSeries}*/}
              {/*    columns={["temperature"]}*/}
              {/*    style={style}/>*/}
              <ScatterChart
                axis="axis"
                series={this.state.temperatureSeries}
                columns={['temperature']}
                style={style}
              />
              <LineChart
                axis="axis"
                series={this.state.temperatureSeries}
                columns={['temperature']}
                style={style}
                interpolation="curveBasis"
              />
              <Baseline
                axis="axis"
                value={0.0}
                label=""
                style={baselineStyle}
              />
              {this.renderMarker()}
            </Charts>
          </ChartRow>
        </ChartContainer>
      ) : (
        <span>No data</span>
      );
    };
    if (!this.state.loading) {
      summary1 =
        this.state.temperatureSeries.collection.length > 0 ? (
          <div className="text-center">
            <span>
              <span className="text-muted">Max</span>
              <b>{parseFloat(this.state.max).toFixed(1)}C</b>
            </span>
            <br />
            <span>
              <span className="text-muted">Avg</span>
              <b>{parseFloat(this.state.avg).toFixed(1)}C</b>
            </span>
            <br />
            <span>
              <span className="text-muted">Min</span>
              <b>{parseFloat(this.state.min).toFixed(1)}C</b>
            </span>
            <br />
          </div>
        ) : (
          ''
        );
      summary2 =
        this.state.temperatureSeries.collection.length > 0 ? (
          <div className="text-center">
            <span>10</span>
            <br />
            <span className="text-muted">Minutes ago</span>
          </div>
        ) : (
          ''
        );
    }
    return (
      <WidgetComponent
        position={this.props.position}
        height={this.props.height}
        width={this.props.width}
        icon="fas fa-thermometer-half fa-2x"
        name={this.props.name}
        type="Temperature"
        summary1={summary1}
        summary2={summary2}
        onRemoveClick={this.props.onRemoveClick}
        chart={
          this.state.loading ? () => <CircularProgress size={35} /> : drawChart
        }
      />
    );
  }
}

export default TemperatureWidgetComponent;
