import React, { Component } from 'react';
import WidgetComponent from './WidgetComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';
import 'moment/locale/fi';
import HonkioAPI from '../../../api/Honkio';

class TenantsWidgetComponent extends Component {
  constructor(props) {
    super(props);
    this.refreshData = this.refreshData.bind(this);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.refreshData();
    let that = this;
    this.setContainerRef = (element) => {
      // alert(1)
      that.container = element;
      // alert(that.container)
    };
  }

  refreshData() {
    let that = this;
    that.setState({ loading: true });
    let merchanttenantlist = async function () {
      let response = await HonkioAPI().userShop.userFetch(
        'merchanttenantlist',
        {}
      );
      // console.log(response);
      let total = response.total;
      that.setState({ total: total, loading: false });
    };
    merchanttenantlist();
  }
  onResize = (a, b, c) => {
    console.error('dddddddddd');
    console.error(a);
    console.error(b);
    console.error(c);
  };
  render() {
    // if (this.container) alert(this.container.offsetHeight)
    const points = [];
    let line;
    let summary;
    let drawChart = (height, width) => {
      return (
        <div className="d-flex justify-content-center w-100 h-100">
          <span className="justify-content-center align-self-center h5">
            {this.state.total}
          </span>
        </div>
      );
    };

    return (
      <WidgetComponent
        position={this.props.position}
        height={this.props.height}
        width={this.props.width}
        icon="fas fa-user-friends fa-2x"
        name={this.props.name}
        onRename={this.props.onRename}
        type="Tennants"
        summary1={drawChart()}
        onRemoveClick={this.props.onRemoveClick}
        chart={
          this.state.loading ? () => <CircularProgress size={35} /> : drawChart
        }
      />
    );
  }
}

export default TenantsWidgetComponent;
