import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Icon from './Icon';
import { v4 as uuid4 } from 'uuid';

const BackButton = ({ to = '/', text = 'Go back' }) => {
    const [id, setId] = useState('defaultTip');

    useEffect(() => {
        const newId = uuid4();
        setId(newId);
    }, []);

    return (
        <Link data-tip data-for={id} to={to}>
            <Icon iconName={`btn bi-chevron-left`} />
            <ReactTooltip id={id} place="right" effect="solid">
                {text}
            </ReactTooltip>
        </Link>
    );
};

export default BackButton;
