import Honkio from '../../Honkio';

const setWarehouse = async function (options = {}, errorHandler = {}) {
    const {
        id,
        name,
        address,
        coordinates,
        products,
        application,
        merchant,
        action,
    } = options;

    return Honkio().mainShop.userFetch(
        'warehouseset',
        {
            id,
            name,
            address,
            coordinates,
            application,
            merchant,
            products,
            action,
            version: 3,
        },
        errorHandler
    );
};

export const createWarehouse = (options = {}, errorHandler = () => {}) => {
    return setWarehouse(
        {
            ...options,
            action: 'create',
        },
        errorHandler
    );
};

export const updateWarehouse = (options = {}, errorHandler = () => {}) => {
    return setWarehouse(
        {
            ...options,
            action: 'update',
        },
        errorHandler
    );
};

export const deleteWarehouse = (options = {}, errorHandler = () => {}) => {
    return setWarehouse(
        {
            ...options,
            action: 'delete',
        },
        errorHandler
    );
};
