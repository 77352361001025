import { errorHandler, resultHandler } from '../../../utils/reqHandlers';
import UserInfoContext from '../../../contexts/UserInfoContext';
import { Button, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import setMerchantTransactionStatus from '../../../api/utils/transactions/merchant/setMerchantTransactionStatus';
import getMerchantTransaction from '../../../api/utils/transactions/merchant/getMerchantTransaction';

let PENDING = -100;
let REJECT = 501;
let ACCEPT = 502;
let DISPUTE = 503;
let CHARGEBACK = 1000;
let CAPTURE = 1001;

let getStatus = (transaction_status) => {
  if (transaction_status == PENDING) {
    return 'Pending';
  } else if (transaction_status == REJECT) {
    return 'Reject';
  } else if (transaction_status == ACCEPT) {
    return 'Accept';
  } else if (transaction_status == DISPUTE) {
    return 'Dispute';
  } else if (transaction_status == CHARGEBACK) {
    return 'Chargeback';
  } else if (transaction_status == CAPTURE) {
    return 'Capture';
  }
};

const TransactionRegisterMerchantDetails = () => {
  let { transactionID } = useParams();
  const [transaction, setTransaction] = useState({});
  const userInfo = useContext(UserInfoContext);
  let merchantId = userInfo.merchant ? userInfo.merchant.id : null;

  useEffect(() => {
    fetchTransactions();
  }, [merchantId, transactionID]);

  let fetchTransactions = async () => {
    getMerchantTransaction(
      {
        id: transactionID,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data, (data) => {
        setTransaction(data.transaction);
      });
    });
  };

  let onAccept = () => {
    setMerchantTransactionStatus(
      {
        id: transactionID,
        status: true,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data, (data) => {
        setTransaction(data.transaction);
      });
    });
  };

  let onReject = () => {
    setMerchantTransactionStatus(
      {
        id: transactionID,
        status: false,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data, (data) => {
        setTransaction(data?.transaction);
      });
    });
  };

  if (!transaction?._id) {
    return <div></div>;
  }
  return (
    <Table>
      <tbody>
        <tr>
          <th>Time</th>
          <td>{transaction.timestamp}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>{transaction?.consumer?.email}</td>
        </tr>
        <tr>
          <th>First name</th>
          <td>{transaction?.consumer?.firstname}</td>
        </tr>
        <tr>
          <th>Last name</th>
          <td>{transaction?.consumer?.lastname}</td>
        </tr>
        <tr>
          <th>Merchant Name</th>
          <td>{transaction?.merchant?.name}</td>
        </tr>
        <tr>
          <th>Business ID</th>
          <td>{transaction?.merchant?.businessid}</td>
        </tr>
        <tr>
          <th>Telephone</th>
          <td>{transaction?.merchant?.telephone}</td>
        </tr>
        <tr>
          <th>Status</th>
          <td>{getStatus(transaction.status)}</td>
        </tr>
        <tr>
          <th>IP</th>
          <td>{transaction?.ip}</td>
        </tr>
        <tr>
          <th>User-Agent</th>
          <td>{transaction?.user_agent}</td>
        </tr>
        {transaction.status === PENDING &&
          !transaction?.plugins?.merchant_manual_validation && (
            <tr>
              <th></th>
              <td>
                <Button variant="success" onClick={onAccept}>
                  Accept
                </Button>
                <Button variant="danger" onClick={onReject}>
                  Reject
                </Button>
              </td>
            </tr>
          )}
      </tbody>
    </Table>
  );
};

export default TransactionRegisterMerchantDetails;
