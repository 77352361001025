import React, { useEffect, useState } from 'react';
import { Country } from 'country-state-city';
import Select from 'react-select';

const CountriesList = ({ selectedCountries, setSelectedCountries }) => {
  const [countriesOptions, setCountriesOptions] = useState([]);

  useEffect(() => {
    if (Country) {
      const allCountries = Country.getAllCountries();
      const opts = allCountries.map((item) => ({
        label: item.name,
        value: item.name,
      }));
      setCountriesOptions(opts);
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="col d-flex align-items-center">
          <h4>Countries</h4>
        </div>
        <div> </div>
      </div>
      <Select
        value={selectedCountries}
        onChange={(val) => setSelectedCountries(val)}
        isMulti
        options={countriesOptions}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </>
  );
};

export default CountriesList;
