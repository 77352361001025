import ToolsLink from './ToolsLink';

const Tools = () => {
    return (
        <div className="container">
            <div className="row row-cols-2">
                <ToolsLink to="/chat">Chat</ToolsLink>
                <ToolsLink to="/data-importer">Data import</ToolsLink>
            </div>
        </div>
    );
};

export default Tools;
