import React, { useState } from 'react';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import _ from 'lodash';
import BackButton from '../UI/BackButton';
import useSearchString from '../../utils/useSearchString';
import { updateWarehouse } from '../../api/utils/warehouse/setWarehouse';
import WarehouseForm from './WarehouseForm';

const WarehouseEdit = () => {
    const { id } = useSearchString();

    const [submitLoading, setSubmitLoading] = useState(false);

    const handleSubmitForm = async (submitData) => {
        const resultHandler = (data) => {
            setSubmitLoading(false);
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Updated successfully',
                    'Success',
                    3000
                );
            }
            return data;
        };

        const errorHandler = (error) => {
            setSubmitLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
            console.log('error: ', error);
            return null;
        };

        try {
            setSubmitLoading(true);
            const res = await updateWarehouse(
                { ...submitData, id },
                errorHandler
            );
            return resultHandler(res);
        } catch (error) {
            return errorHandler(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton to="/warehouses" text="Back to list" />
                Edit warehouse
            </h2>

            <WarehouseForm
                id={id}
                onSubmit={handleSubmitForm}
                submitLoading={submitLoading}
            />
        </div>
    );
};

export default WarehouseEdit;
