import React, { useEffect, useState } from 'react';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import LoadingBar from '../UI/LoadingBar';
import useLoader from '../UI/helpers/useLoader';
import SettingsInfoForm from './SettingsInfoForm';
import getGlobalSettings from '../../api/utils/settings/getGlobalSettings';
import setGlobalSettings from '../../api/utils/settings/setGlobalSettings';

const SettingsInfo = () => {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(null);
  const [progress, done] = useLoader([settings]);

  const onSubmit = (data) => {
    setLoading(true);
    const errorHandler = (error) => {
      setLoading(false);
      NotificationManager.error(error.description, 'Error', 4000);
    };
    setGlobalSettings(data, errorHandler).then((res) => {
      setLoading(false);
      if (res.status === 'accept') {
        NotificationManager.success('Updated successfully', 'Success', 4000);
        fetchGlobalSettings();
      } else {
        NotificationManager.error('Failed to save settings', 'Error', 4000);
      }
    });
  };

  useEffect(() => {
    fetchGlobalSettings();
  }, []);

  const fetchGlobalSettings = () => {
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    getGlobalSettings({}, errorHandler).then((res) => {
      console.log('res ==', res);
      if (res.status === 'accept') {
        setSettings(res.settings);
      } else {
        setSettings(null);
        NotificationManager.error(
          'Failed to load global settings',
          'Error',
          4000
        );
      }
    });
  };

  return (
    <div>
      <NotificationContainer />
      <h2>Global settings</h2>
      {done ? (
        settings?._id ? (
          <SettingsInfoForm
            settings={settings}
            onSubmit={onSubmit}
            disableSubmit={loading}
          />
        ) : (
          'not found'
        )
      ) : (
        <LoadingBar progress={progress} />
      )}
    </div>
  );
};

export default SettingsInfo;
