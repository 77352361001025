import Honkio from '../../Honkio';

const activateVelocityRule = async function (options = {}, errorHandler = {}) {
  return Honkio().mainShop.userFetch(
    'adminvelocityruleactivate',
    {
      ...options,
      version: 3,
      action: 'update',
    },
    errorHandler
  );
};

export default activateVelocityRule;
