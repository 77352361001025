import { useEffect, useState, useContext } from 'react';
import getGreyList from '../../api/utils/greylist/getGreyList';
import useSearchString from '../../utils/useSearchString';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import GreyListTable from './GreyListTable';
import {
  GREY_LIST_TYPE_ADDRESS,
  GREY_LIST_TYPE_COORDINATES,
  GREY_LIST_TYPE_EMAIL,
  GREY_LIST_TYPE_NETWORK,
  GREY_LIST_TYPE_NAME,
} from './GreyListTypes';
import {
  createGreylist,
  updateGreylist,
  deleteGreyList,
} from '../../api/utils/greylist/setGreyList';
import EmailGreyListForm from './GreyListCreation/EmailGreyListForm';
import CoordinatesGreyListForm from './GreyListCreation/CoordinatesGreyListForm';
import AddressGreyListForm from './GreyListCreation/AddressGreyListForm';
import NetworkGreyListForm from './GreyListCreation/NetworkGreyListForm';
import NameGreyListForm from './GreyListCreation/NameGreyListForm';
import GreylistEditModal from './GreylistEditModal';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const GreyLists = () => {
  const [newGreyList, setNewGreyList] = useState({
    address: '',
    score: 1,
    radius: 1000,
    email: '',
    reason: '',
  });
  const [greyListList, setGreyListList] = useState([]);
  const userInfo = useContext(UserInfoContext);
  const { merchant = null, type } = useSearchString();
  const merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;

  const [loading, setLoading] = useState(false);
  let isMounted = true;

  const [editItem, setEditItem] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  useEffect(() => {
    setLoading(true);
    fetchGreyList(1);
    return () => {
      isMounted = false;
    };
  }, [merchant, type]);

  const fetchGreyList = async (page = 1) => {
    const resultLoadingHandler = (data) => {
      if (isMounted) {
        if (data && data.status === 'accept') {
          setGreyListList(data.greylist);
          setTotalRows(data?.total);
          setLoading(false);
        } else {
          setGreyListList([]);
          setLoading(false);
        }
      }
    };

    const errorLoadingHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setGreyListList([]);
      setLoading(false);
    };

    if (isMounted) {
      getGreyList(
        {
          merchantId: merchantId,
          greylistType: type,
          query_skip: (+page - 1) * perPage,
          query_count: perPage,
        },
        errorLoadingHandler
      )
        .then((data) => {
          return resultLoadingHandler(data);
        })
        .catch(console.log);
    }
  };

  const reloadList = () => {
    fetchGreyList();
  };

  const handlePageChange = (page) => {
    fetchGreyList(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };
  useEffect(() => {
    fetchGreyList(1);
  }, [perPage]);

  const title = () => {
    switch (type) {
      case GREY_LIST_TYPE_EMAIL:
        return userInfo.merchant?.name
          ? `Email greylist (${userInfo.merchant.name})`
          : 'Email greylist';
      case GREY_LIST_TYPE_COORDINATES:
        return userInfo.merchant?.name
          ? `Coordinates greylist (${userInfo.merchant.name})`
          : 'Coordinates greylist';
      case GREY_LIST_TYPE_ADDRESS:
        return userInfo.merchant?.name
          ? `Address greylist (${userInfo.merchant.name})`
          : 'Address greylist';
      case GREY_LIST_TYPE_NETWORK:
        return userInfo.merchant?.name
          ? `Network greylist (${userInfo.merchant.name})`
          : 'Network greylist';
      case GREY_LIST_TYPE_NAME:
        return userInfo.merchant?.name
          ? `Name greylist (${userInfo.merchant.name})`
          : 'Name greylist';
      default:
        return 'invalid type greylist';
    }
  };
  const onDelete = (id) => {
    console.log('onDelete: id ==', id);

    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Deleted');
        reloadList();
      } else {
        NotificationManager.error('Error');
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      reloadList();
    };

    deleteGreyList({ id }, errorHandler).then((data) => {
      return resultHandler(data);
    });
  };

  const onEdit = (item) => {
    setEditItem(item);
    setShowEditModal(true);
  };

  const onSave = (data) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Saved');
        reloadList();
      } else {
        NotificationManager.error('Error');
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      reloadList();
    };

    let saveFunction = () => {
      NotificationManager.error('Not implemented');
    };
    if (data?.id) {
      saveFunction = updateGreylist;
    } else {
      saveFunction = createGreylist;
    }

    switch (type) {
      case GREY_LIST_TYPE_EMAIL:
        saveFunction(
          {
            id: data?.id,
            merchant_id: merchantId,
            greylist_type: type,
            email_email: data.email,
            email_reason: data.reason,
            email_score: data.score,
          },
          errorHandler
        ).then((data) => {
          return resultHandler(data);
        });
        break;
      case GREY_LIST_TYPE_COORDINATES:
        saveFunction({
          id: data?.id,
          merchant_id: merchantId,
          greylist_type: type,
          coordinates_lat: data.latitude,
          coordinates_long: data.longitude,
          coordinates_radius: data.radius,
          coordinates_score: data.score,
          errorHandler,
        }).then((data) => {
          return resultHandler(data);
        });
        break;
      case GREY_LIST_TYPE_ADDRESS:
        saveFunction({
          id: data?.id,
          merchant_id: merchantId,
          greylist_type: type,
          address_address: data.address,
          address_score: data.score,
          errorHandler,
        }).then((data) => {
          return resultHandler(data);
        });
        break;
      case GREY_LIST_TYPE_NETWORK:
        saveFunction({
          id: data?.id,
          merchant_id: merchantId,
          greylist_type: type,
          network_address: data.network,
          network_score: data.score,
          errorHandler,
        }).then((data) => {
          return resultHandler(data);
        });
        break;
      case GREY_LIST_TYPE_NAME:
        saveFunction({
          id: data?.id,
          merchant_id: merchantId,
          greylist_type: type,
          name_firstname: data.firstname,
          name_lastname: data.lastname,
          name_reason: data.reason,
          name_score: data.score,
          errorHandler,
        }).then((data) => {
          return resultHandler(data);
        });
        break;
      default:
        NotificationManager.error('Greylist type is invalid!');
        return;
    }
  };
  const GreyListForm = ({}) => {
    switch (type) {
      case GREY_LIST_TYPE_EMAIL:
        return <EmailGreyListForm greyList={newGreyList} onSave={onSave} />;
      case GREY_LIST_TYPE_COORDINATES:
        return (
          <CoordinatesGreyListForm greyList={newGreyList} onSave={onSave} />
        );
      case GREY_LIST_TYPE_ADDRESS:
        return <AddressGreyListForm greyList={newGreyList} onSave={onSave} />;
      case GREY_LIST_TYPE_NETWORK:
        return <NetworkGreyListForm greyList={newGreyList} onSave={onSave} />;
      case GREY_LIST_TYPE_NAME:
        return <NameGreyListForm greyList={newGreyList} onSave={onSave} />;
      default:
        return <label>Invalid grey list column type</label>;
    }
  };

  //   TODO: Add pagination here
  return (
    <div className="w-100 h-100">
      <NotificationContainer />
      <div className="container-fluid">
        <h2>{title()}</h2>
        {merchantId ? <GreyListForm /> : null}
        <div className="row">
          <div className="col">
            <GreyListTable
              data={greyListList}
              type={type}
              loading={loading}
              merchant={merchantId}
              onDelete={onDelete}
              onEdit={onEdit}
              emptyText={'The list is empty'}
              rowsCountPerPage={perPage}
              handleRowsPerPage={handleRowsPerPage}
              perPageChoices={PER_PAGE_CHOICES}
              totalRows={totalRows}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
        <GreylistEditModal
          item={editItem}
          show={showEditModal}
          onSubmit={onSave}
          onHide={() => {
            setEditItem(null);
            setShowEditModal(false);
          }}
        />
      </div>
    </div>
  );
};

export default GreyLists;
