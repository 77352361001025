import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import useSearchString from '../../utils/useSearchString';
import Icon from '../UI/Icon';
import { getNotifyMessageList } from '../../api/utils/merchant/notifymessage/notifyMessages';

const columns = () => [
  {
    name: '#',
    width: '40px',
    cell: (_, index) => index + 1,
  },
  {
    name: 'Name',
    cell: ({ name }) => name,
  },
  {
    name: 'Actions',
    cell: (row) => (
      <div>
        <Link to={`/message/edit?id=${row['_id']}`}>
          <Icon iconName="bi-pen" />
        </Link>
      </div>
    ),
  },
];

const MerchantMessagesList = () => {
  const [templatesList, setTemplatesList] = useState();
  const { merchant } = useSearchString();

  useEffect(() => {
    getNotifyMessageList({ merchant }).then(({ notify_messages }) => {
      setTemplatesList(notify_messages);
    });
  }, [merchant]);
  return (
    <DataTable
      dense
      striped
      highlightOnHover
      data={templatesList}
      columns={columns()}
    />
  );
};

export default MerchantMessagesList;
