import Honkio from '../../../../Honkio';

const setUserRegSettings = (options = {}, errorHandler = () => {}) => {
    const {
        id,
        name,
        merchant,
        application,
        userStatusGroups,
        userRegistrationRequirements,
        userRegistrationModel,
        defaultUserGroup,
        destinationUserGroup,
        action,
    } = options;
    return Honkio().mainShop.userFetch(
        'adminmerchantuserregistrationsettingsset',
        {
            id,
            name,
            merchant,
            application,
            user_status_groups: userStatusGroups,
            user_registration_requirements: userRegistrationRequirements,
            user_registration_model: userRegistrationModel,
            default_user_group: defaultUserGroup,
            destination_user_group: destinationUserGroup,
            action,
            version: 3,
        },
        errorHandler,
        true
    );
};

export const createUserRegSettings = (
    options = {},
    errorHandler = () => {}
) => {
    return setUserRegSettings(
        {
            ...options,
            action: 'create',
        },
        errorHandler
    );
};

export const updateUserRegSettings = (
    options = {},
    errorHandler = () => {}
) => {
    return setUserRegSettings(
        {
            ...options,
            action: 'update',
        },
        errorHandler
    );
};

export const deleteUserRegSettings = (
    options = {},
    errorHandler = () => {}
) => {
    return setUserRegSettings(
        {
            ...options,
            action: 'delete',
        },
        errorHandler
    );
};
