import Honkio from '../../../Honkio';

const createServiceKey = (
  { merchant, name, query_access, debug } = {},
  errorHandler = () => {}
) =>
  Honkio().mainShop.userFetch(
    'adminsettingsservicekeycreate',
    {
      debug,
      merchant,
      name,
      query_access,
      version: 3,
      action: 'create',
    },
    errorHandler
  );

export default createServiceKey;
