import Honkio from '../../Honkio';

const deleteMessage = (options, errorHandler = {}) => {
  const { id, application, merchant } = options;
  return Honkio().mainShop.userFetch(
    'chatmessageset',
    {
      id,
      delete: true,
      application,
      merchant,
      version: 3,
      action: 'delete',
    },
    errorHandler
  );
};

export default deleteMessage;
