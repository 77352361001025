import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SmartForm from '../Form/SmartForm';
import Fields from './Fields';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import IdLists from './IdLists';

const AssetTypeEditForm = (props) => {
  return (
    <SmartForm
      defaultValues={props.defaultValues}
      onSubmit={props.onSubmit}
      className="p-3"
    >
      <div className="form-row align-items-end">
        <div className="col-10">
          <InputFieldWithValidation
            className="mb-3"
            name="name"
            label="Asset type name"
            type="text"
            required={true}
          />
          <InputFieldWithValidation
            className="mb-3"
            name="description"
            label="Asset type description"
            type="text"
            required={true}
          />
          <SelectFieldWithValidation
            name="accessibility"
            label="Public/Private"
            options={['public', 'private']}
          />
        </div>
      </div>
      <Fields />
      <IdLists />
      <br />
      <button className="btn btn-primary">Submit</button>
    </SmartForm>
  );
};

export default AssetTypeEditForm;
