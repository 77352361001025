import Honkio from '../../Honkio';

const getEvents = (options, errorHandler = {}) => {
  const {
    asset,
    id,
    page = 0,
    query_count = 0,
    merchantId,
    appid,
    query_start_date,
    query_end_date,
    query_aggregate,
  } = options;
  return Honkio().mainShop.userFetch(
    'eventassetlist',
    {
      query_asset: asset,
      query_id: id,
      query_skip: +page * query_count,
      query_count,
      query_merchant: merchantId,
      query_start_date,
      query_end_date,
      query_aggregate,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getEvents;
