import React, { useContext, useEffect, useState } from 'react';
import PaymentTable from './PaymentTable';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import {
    deletePaymentProvider,
    getPaymentList,
} from '../../api/utils/payments/getPayment';
import useSearchString from '../../utils/useSearchString';
import UserInfoContext from '../../contexts/UserInfoContext';
import { Link } from 'react-router-dom';
import DeleteConfirmModel from '../Model/DeleteConfirmModel';

const Payment = () => {
    const [paymentList, setPaymentList] = useState([]);
    const [loading, setLoading] = useState();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState();

    const { merchant = null } = useSearchString();
    const userInfo = useContext(UserInfoContext);

    const handleShowConfirmModal = () => setShowConfirmModal(true);

    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;

    const fetchPaymentList = () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                const applications = data?.payment_provider;
                setPaymentList(applications);
                setLoading(false);
            } else {
                setPaymentList([]);
                setLoading(false);
                NotificationManager.error('Error');
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setPaymentList([]);
            setLoading(false);
        };
        getPaymentList(
            { query_all: true, merchant: merchantId, debug: true },
            errorHandler
        ).then(resultHandler);
    };

    useEffect(() => {
        fetchPaymentList();
    }, []);

    const handleCloseConfirmDelete = () => {
        const resultHandler = (data) => {
            if (data?.status === 'accept') {
                setShowConfirmModal(false);
                NotificationManager.success('payment provider removed');
                fetchPaymentList();
            } else {
                NotificationManager.error(data?.message);
            }
        };
        deletePaymentProvider({ id: deleteId }).then((data) => {
            return resultHandler(data);
        });
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <h2>Payment Service Provider</h2>
            <Link
                to={`/payment/create`}
                className="btn btn-outline-primary mt-1 mb-3"
            >
                <i className="bi-plus-square ml-1 mr-2 " /> Create payment
                provider
            </Link>
            <PaymentTable
                paymentList={paymentList}
                loading={loading}
                emptyText="Payment Provider list is empty"
                onDeleteClick={(id) => {
                    handleShowConfirmModal();
                    setDeleteId(id);
                }}
            />

            <DeleteConfirmModel
                modal={showConfirmModal}
                onModalClose={() => {
                    setShowConfirmModal(false);
                }}
                handleCloseConfirmDelete={handleCloseConfirmDelete}
            />
        </div>
    );
};

export default Payment;
