import React from 'react';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';

const UserHomeInfoForm = ({ className = '', lat, setLat, lon, setLon }) => {
  const handleChangeLat = (e) => {
    setLat(e.target.value);
  };
  const handleChangeLon = (e) => {
    setLon(e.target.value);
  };

  return (
    <div className={className}>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="home_location_lat">Home location latitude</label>
            <input
              type="number"
              className="form-control mb-3"
              name="settings.home_location_lat"
              id="home_location_lat"
              step={0.1}
              value={lat}
              onChange={handleChangeLat}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="home_location_lon">Home location longitude</label>
            <input
              type="number"
              className="form-control mb-3"
              label="Home location longitude"
              name="settings.home_location_lon"
              id="home_location_lon"
              step={0.1}
              value={lon}
              onChange={handleChangeLon}
            />
          </div>
        </div>
      </div>
      <InputFieldWithValidation
        className="mb-3"
        label="Home address"
        name="settings.home_address"
      />
    </div>
  );
};

export default UserHomeInfoForm;
