import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Form, Button, Spinner, InputGroup } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';
import cn from 'classnames';
import getWarehouse from '../../api/utils/warehouse/getWarehouse';
import { GOOGLE_API_KEY } from '../../config';
import WarehouseAddressesModal from './WarehouseAddressesModal';
import Map from '../Map';
import UserInfoContext from '../../contexts/UserInfoContext';
import ProductsInput from './ProductsInput';
import getProductList from '../../api/utils/product/getProductList';
import ReactTooltip from 'react-tooltip';

const BASE_GEO_CODE_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}`;

const INITIAL_WAREHOUSE = {
    name: '',
    application: '',
    merchant: '',
    address: '',
    coordinates: [0.0, 0.0],
    products: [],
};

const PRODUCT_NAME_UPDATED_MSG =
    'One or several product names were updated. Please, submit the form';
const PRODUCTS_REMOVED_MSG =
    'Some products were removed from database. Please, remove them from the warehouse';

const WarehouseForm = ({
    id = null,
    onSubmit = () => {},
    submitLoading = false,
}) => {
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [warehouse, setWarehouse] = useState(INITIAL_WAREHOUSE);

    const [coordinatesInput, setCoordinatesInput] = useState(
        INITIAL_WAREHOUSE.coordinates
    );
    const [coordUpdated, setCoordUpdated] = useState(false);

    const [addressInput, setAddressInput] = useState('');
    const [addressUpdated, setAddressUpdated] = useState(false);

    const [addressList, setAddressList] = useState();
    const [showAddressListModal, setShowAddressListModal] = useState(false);
    const handleShowAddressListModal = () => setShowAddressListModal(true);
    const handleCloseAddressListModal = () => setShowAddressListModal(false);

    const [loading, setLoading] = useState(false);

    // Products - start
    // Products and their quantities
    const [productNameUpdated, setProductNameUpdated] = useState(false);
    const [removedProductsIds, setRemovedProductsIds] = useState([]);

    const [selectedProducts, setSelectedProducts] = useState([]);
    // Products - end

    useEffect(() => {
        if (id) fetchWarehouse(id);
    }, [id]);

    useEffect(() => {
        if (!_.isEmpty(removedProductsIds)) {
            NotificationManager.error(PRODUCTS_REMOVED_MSG);
        }
    }, [removedProductsIds]);

    const fetchWarehouse = async (id) => {
        const resultHandler = async (data) => {
            setLoading(false);
            if (data && data?.status === 'accept') {
                setWarehouse(data.warehouse);
                setCoordinatesInput(data.warehouse?.coordinates);
                setAddressInput(data.warehouse?.address);
                if (data.warehouse?.products) {
                    // Override names in case changed in database
                    const currentProductsRes = await fetchWarehouseProducts(
                        data.warehouse
                    );
                    const currentProducts = currentProductsRes?.product;

                    const updatedProducts = data.warehouse.products?.map(
                        (item) => {
                            let resItem = {};
                            let updatedName = item.product_name;
                            const currItemName = currentProducts?.find(
                                (currentItem) =>
                                    currentItem._id == item.product_id
                            )?.settings?.str_name;

                            if (!currItemName) {
                                setRemovedProductsIds((prev) => {
                                    return _.uniqBy(
                                        [...prev, item],

                                        (a) => a?.product_id
                                    ).map((item) => item.product_id);
                                });
                            }

                            if (currItemName !== item.product_name) {
                                if (currItemName) {
                                    updatedName = currItemName;

                                    setProductNameUpdated(true);
                                    NotificationManager.info(
                                        PRODUCT_NAME_UPDATED_MSG
                                    );
                                }
                            }

                            resItem = {
                                id: item.product_id,
                                name: updatedName,
                                quantity: item.quantity,
                            };
                            return resItem;
                        }
                    );

                    setSelectedProducts(updatedProducts);
                }
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setWarehouse(INITIAL_WAREHOUSE);
            setCoordinatesInput(INITIAL_WAREHOUSE.coordinates);
            setAddressInput(INITIAL_WAREHOUSE.address);
            setSelectedProducts(INITIAL_WAREHOUSE.products);
        };

        try {
            setLoading(true);
            const res = await getWarehouse({ id }, errorHandler);
            await resultHandler(res);
        } catch (error) {
            console.log(error);
            errorHandler(error);
            setWarehouse(INITIAL_WAREHOUSE);
            setCoordinatesInput(INITIAL_WAREHOUSE.coordinates);
            setAddressInput(INITIAL_WAREHOUSE.address);
            setSelectedProducts(INITIAL_WAREHOUSE.products);
        }
    };

    const fetchWarehouseProducts = async (warehouse) => {
        const resultHandler = (data) => {
            if (data && data?.status === 'accept') {
                return data;
            } else {
                return [];
            }
        };

        const errorHandler = (error) => {
            return [];
        };

        if (!merchantId || !appId) return;

        let productIds = null;
        if (warehouse.products?.length) {
            productIds = warehouse.products.map((item) => item.id);
        }

        const res = await getProductList(
            {
                query_skip: 0,
                query_count: 1000,
                query_merchant: warehouse?.merchant,
                query_application: warehouse?.application,
                query_ids: productIds,
            },
            errorHandler
        );
        if (res && res?.status === 'accept') {
            return resultHandler(res);
        }
    };

    const handleSubmit = async (data) => {
        const submitSettings = _.cloneDeep(data);

        if (!appId) {
            NotificationManager.error('You must select application first');
            return;
        }
        if (!merchantId) {
            NotificationManager.error('You must select merchant first');
            return;
        }

        submitSettings.application = appId;
        submitSettings.merchant = merchantId;

        if (
            _.isArray(coordinatesInput) &&
            !_.isEqual(submitSettings.coordinates, coordinatesInput) &&
            !_.isEmpty(coordinatesInput)
        ) {
            submitSettings.coordinates = coordinatesInput;
        }

        if (addressInput && addressInput !== submitSettings.address) {
            submitSettings.address = addressInput;
        }

        submitSettings.products = selectedProducts.map((item) => ({
            product_id: item.id,
            product_name: item.name,
            quantity: item.quantity,
        }));

        try {
            const res = await onSubmit(submitSettings);
            if (res && res.warehouse) {
                setWarehouse(res.warehouse);
                setProductNameUpdated(false);
            }
        } catch (error) {
            console.log('error submitting warehouse data: ', error);
        }
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        await handleSubmit(warehouse);
    };

    const handleChangeWarehouse = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        setWarehouse((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    const handleAddressChanged = async (address) => {
        const adr = `${BASE_GEO_CODE_URL}&address="${address}"`;

        setAddressUpdated(false);

        await handleGoogleRequest(adr);
    };

    const handleCoordinatesChanged = async (newCoordinates = [0.0, 0.0]) => {
        setCoordUpdated(false);

        const adr = `${BASE_GEO_CODE_URL}&latlng=${newCoordinates[0]},${newCoordinates[1]}`;

        await handleGoogleRequest(adr);
    };

    const handleGoogleRequest = async (adr) => {
        const requestOptions = {
            method: 'POST',
        };
        const response = await fetch(adr, requestOptions);
        const result = await response.json();

        if (result.status === 'OK' && result.results.length > 0) {
            if (result.results.length > 1) {
                setAddressList(result.results);
                handleShowAddressListModal();
            } else {
                const coordinates = [
                    result.results[0].geometry.location.lat,
                    result.results[0].geometry.location.lng,
                ];
                const address = result.results[0].formatted_address;

                setAddressInput(address);
                setCoordinatesInput(coordinates);

                setWarehouse((prev) => ({
                    ...prev,
                    address,
                    coordinates,
                }));
            }
        } else {
            setWarehouse((prev) => ({
                ...prev,
                address: '',
                coordinates: [0.0, 0.0],
            }));
            NotificationManager.warning(
                result.error_message || 'Geocode was not successful',
                'Geocode error',
                5000
            );
        }
    };

    const onAddressSelected = async (address) => {
        handleCloseAddressListModal();
        const coordinates = [
            address.geometry.location.lat,
            address.geometry.location.lng,
        ];
        const submitAddress = address.formatted_address;

        const submitData = _.cloneDeep(warehouse);
        submitData.coordinates = coordinates;
        submitData.address = submitAddress;

        setAddressInput(submitAddress);
        setCoordinatesInput(coordinates);

        setWarehouse(submitData);
    };

    return (
        <>
            <Form onSubmit={handleSubmitForm}>
                <Form.Group className="mb-3" controlId="data.name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Warehouse name"
                        name="name"
                        value={warehouse?.name || ''}
                        onChange={handleChangeWarehouse}
                    />
                    <Form.Text className="text-muted">
                        Name of the created warehouse
                    </Form.Text>
                </Form.Group>

                <InputGroup className="mb-3">
                    <InputGroup.Text>Search by address</InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="Address"
                        name="address"
                        value={addressInput}
                        onChange={(e) => {
                            setAddressInput(e.target.value);
                            setAddressUpdated(true);
                        }}
                    />

                    <Button
                        variant={
                            addressUpdated ? 'danger' : 'outline-secondary'
                        }
                        id="find-coordinates"
                        type="button"
                        onClick={(e) =>
                            handleAddressChanged(
                                addressInput || INITIAL_WAREHOUSE.address
                            )
                        }
                    >
                        Find
                    </Button>
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Text>Search by coordinates</InputGroup.Text>
                    <Form.Control
                        aria-label="Lattitude"
                        type="text"
                        placeholder="60.xxxx"
                        name="coordinates.0"
                        value={coordinatesInput?.[0]}
                        onChange={(e) => {
                            setCoordinatesInput((prev) => [
                                e.target.value,
                                prev?.[1],
                            ]);
                            setCoordUpdated(true);
                        }}
                    />
                    <Form.Control
                        aria-label="Longitude"
                        type="text"
                        placeholder="24.xxxx"
                        name="coordinates.1"
                        value={coordinatesInput?.[1]}
                        onChange={(e) => {
                            setCoordinatesInput((prev) => [
                                prev?.[0],
                                e.target.value,
                            ]);
                            setCoordUpdated(true);
                        }}
                    />
                    <Button
                        variant={coordUpdated ? 'danger' : 'outline-secondary'}
                        id="find-coordinates"
                        type="button"
                        onClick={(e) =>
                            handleCoordinatesChanged(
                                coordinatesInput ||
                                    INITIAL_WAREHOUSE.coordinates
                            )
                        }
                    >
                        Find
                    </Button>
                </InputGroup>

                <div>
                    <h4>Location</h4>
                    {warehouse.coordinates ?? null ? (
                        <Map
                            latitude={warehouse.coordinates[0]}
                            longitude={warehouse.coordinates[1]}
                        />
                    ) : (
                        <div className="text-muted">'no location provided'</div>
                    )}
                </div>

                <h3>Products</h3>
                <ProductsInput
                    className={'my-3'}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    removedProductsIds={removedProductsIds}
                />

                <Button
                    className={cn('mt-3')}
                    type="submit"
                    variant={productNameUpdated ? 'danger' : 'primary'}
                    disabled={loading || submitLoading}
                    data-tip
                    data-for="updatedProductTip"
                >
                    {productNameUpdated ? (
                        <ReactTooltip
                            id="updatedProductTip"
                            place="top"
                            effect="solid"
                        >
                            {PRODUCT_NAME_UPDATED_MSG}
                        </ReactTooltip>
                    ) : null}
                    Submit{' '}
                    {loading || submitLoading ? (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : null}
                </Button>
            </Form>
            <WarehouseAddressesModal
                addressList={addressList}
                show={showAddressListModal}
                onAddressSelected={onAddressSelected}
                onHide={handleCloseAddressListModal}
            ></WarehouseAddressesModal>
        </>
    );
};

export default WarehouseForm;
