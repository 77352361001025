import Honkio from '../../Honkio';

const getAdminRolesList = (options = {}, errorHandler = {}) => {
    const {
        appid,
        merchant,
        query_applications,
        query_merchants,
        debug,
        id,
        query_skip,
        query_count,
    } = options;
    return Honkio().mainShop.userFetch(
        'adminrolelist',
        {
            debug,
            query_application: appid,
            query_merchant: merchant,
            query_applications,
            query_merchants,
            query_skip,
            query_count,
            id,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
export default getAdminRolesList;
