import { Component } from 'react';
import { FormInput } from 'shards-react';

const validationMap = {
  number: 'number',
  email: 'email',
  notNull: 'notNull',
};

class FormInputValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isNumber = this.isNumber.bind(this);
    this.isEmail = this.isEmail.bind(this);
    this.isFilled = this.isFilled.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  isNumber(e) {
    const regex = /^[0-9]+$/;
    if (e.match(regex)) {
      return true;
    }
    return false;
  }

  isEmail(e) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.match(regex)) {
      return true;
    }
    return false;
  }

  isFilled(e) {
    if (e.length > 0) {
      return 1;
    }
    return -1;
  }

  onChange(e) {
    let valid;
    this.props.onChange(e);
    if (e) {
      switch (this.props.validationType) {
        case validationMap.number:
          valid = this.isNumber(e.target.value);

          break;
        case validationMap.email:
          valid = this.isEmail(e.target.value);
          break;
        case validationMap.notNull:
          valid = this.isFilled(e.target.value);
          break;
      }

      if (this.props.handleValidation) {
        this.props.handleValidation(e.target.id, valid);
      }
      this.setState({ valid: valid });
    }
  }

  render() {
    return (
      <FormInput
        id={this.props.id}
        value={this.props.value}
        valid={this.state.valid === true}
        invalid={this.state.valid === false}
        placeholder={this.props.placeholder}
        onChange={(e) => this.onChange(e)}
      />
    );
  }
}

export default FormInputValidation;

FormInputValidation.defaultProps = {
  placeholder: '',
};
