import Honkio from '../../../Honkio';

const getMerchantRegStatus = (options = {}, errorHandler = {}) => {
  const { email, merchant } = options;
  return Honkio().mainShop.userFetch(
    'merchantregistrationstatus',
    { email, merchant, version: 3, action: 'read' },
    errorHandler
  );
};
export default getMerchantRegStatus;
