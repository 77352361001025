const variables = [
    { value: '[app_name]', name: '[app_name]' },
    { value: '[order_owner_name]', name: '[order_owner_name]' },
    { value: '[order_owner_email]', name: '[order_owner_email]' },
    { value: '[reservation_start]', name: '[reservation_start]' },
    { value: '[reservation_end]', name: '[reservation_end]' },
    { value: '[reservation_start_date]', name: '[reservation_start_date]' },
    { value: '[reservation_end_date]', name: '[reservation_end_date]' },
    { value: '[point_of_sales_address]', name: '[point_of_sales_address]' },
    { value: '[keybox_code]', name: '[keybox_code]' },
    { value: '[merchant_support_email]', name: '[merchant_support_email]' },
    { value: '[order_id]', name: '[order_id]' },
    { value: '[order_title]', name: '[order_title]' },
    { value: '[asset_name]', name: '[asset_name]' },
    { value: '[merchant_phone]', name: '[merchant_phone]' },
    { value: '[auth_link]', name: '[auth_link]' },
    { value: '[docs_upload_link]', name: '[docs_upload_link]' },
    { value: '[merchant_id]', name: '[merchant_id]' },
    { value: '[pos]', name: '[pos]' },
    { value: '[email_verify_link]', name: '[email_verify_link]' },
    { value: '[photos_upload_url]', name: '[photos_upload_url]' },
    { value: '[start_date]', name: '[start_date]' },
    { value: '[end_date]', name: '[end_date]' },
    { value: '[start_time]', name: '[start_time]' },
    { value: '[end_time]', name: '[end_time]' },
    { value: '[subscriber]', name: '[subscriber]' },
    { value: '[order_type]', name: '[order_type]' },
    { value: '[driver]', name: '[driver]' },
    { value: '[bus]', name: '[bus]' },
    { value: '[customer_phone]', name: '[customer_phone]' },
    { value: '[license_number]', name: '[license_number]' },
    { value: '[license_plate]', name: '[license_plate]' },
    { value: '[number_of_passengers]', name: '[number_of_passengers]' },
    { value: '[starting_location]', name: '[starting_location]' },
    { value: '[destination_location]', name: '[destination_location]' },
    { value: '[reservation]', name: '[reservation]' },
    { value: '[starting_coordinates]', name: '[starting_coordinates]' },
    { value: '[waypoints]', name: '[waypoints]' },
];

export default variables;
