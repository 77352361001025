import React from 'react';
import { useEffect, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

const AddressTableModal = ({ addressList, show, onHide, onAddressSelected }) => {

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    /*useEffect(() => {
       
    }, [addressList]);*/

    const columns = useMemo(
        () => [
            {
                name: 'Address',
                selector: 'formatted_address',
                sortable: true,
            }
        ]
    );

    const container = <div className="container">
        <div className="col-13">
            <DataTable
                dense
                striped
                noHeader
                noDataComponent="no permissions"
                highlightOnHover
                onRowClicked={onAddressSelected}
                data={addressList}
                columns={columns}
            />
        </div>
    </div>

    const onClickHide = () => {
        onHide()
    }

    return <Modal
        show={show}
        onHide={onClickHide}>
        <Modal.Header closeButton>
            <Modal.Title>Please select your address from list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {addressList ? container : 'not found'}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClickHide}>Close</Button>
        </Modal.Footer>
    </Modal>
};

export default AddressTableModal