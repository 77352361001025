// const dotenv = require('dotenv');
// dotenv.config();

// Oauth url in order to authenticate
export const OAUTH_TOKEN_URL =
  process.env.REACT_APP_OAUTH_TOKEN_URL || 'https://oauth.genii-solutions.com/token';
export const OAUTH_REDIRECT_URL =
  process.env.REACT_APP_OAUTH_REDIRECT_URL ||
  'https://admin.genii-solutions.com/authorized';

export const OAUTH_CLIENT_ID =
  process.env.REACT_APP_OAUTH_CLIENT_ID ||
  'Gps5aNiYuuOICgGSBpMGxhMAdS64ZJz5EaaenUzP'; // local
export const OAUTH_CLIENT_SECRET =
  process.env.REACT_APP_OAUTH_CLIENT_SECRET ||
  'wnjlH9CyGjIhASgwzGyJ3e56ev3ZLzZfdhGnWFLX '; // local
export const OAUTH_URL =
  process.env.REACT_APP_OAUTH_URL ||
  [
    `https://oauth.genii-solutions.com/authorize?platform=web`,
    `scope=email`,
    `response_type=code`,
    `client_id=Gps5aNiYuuOICgGSBpMGxhMAdS64ZJz5EaaenUzP`,
    `redirect_uri=https%3A%2F%2Fadmin.genii-solutions.com%2Fauthorized`,
  ].join('&');
export const REGISTRATION_URL = 'https://oauth.genii-solutions.com/register/?platform=web&response_type=code&client_id=Gps5aNiYuuOICgGSBpMGxhMAdS64ZJz5EaaenUzP&redirect_uri=https://admin.genii-solutions.com/authorized&scope=email';
export const MERCHANT_REGISTER_URL =
'https://oauth.genii-solutions.com/register/company/?platform=web&response_type=code&client_id=Gps5aNiYuuOICgGSBpMGxhMAdS64ZJz5EaaenUzP&redirect_uri=https://admin.genii-solutions.com/authorized&scope=email';
// Internal API URL (Should be replaced in production)
export const INTERNAL_API_URL = 'https://api.genii-solutions.com'; // replace in prod
export const GOOGLE_API_KEY = 'AIzaSyAK5T79Q9FhqPuYZ4cHl8rxFOX4Ck9KDHY';
// Main API URL
export const GOOGLE_ANALYTICS_ID = '';
export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || 'https://admin.genii-solutions.com/api/';
export const CONSUMER_URL =
  process.env.REACT_APP_CONSUMER_URL || 'https://consumer.2.genii-solutions.com/';
export const UPLOAD_URL =
  process.env.REACT_APP_CONSUMER_URL ||
  'https://media1.genii-solutions.com/rental_dev/upload'; // fixme ??

// Authentication redirect path
export const REDIRECT_URL = '/landing';
export const APP_PACKAGE = 'server';
