import Honkio from '../../Honkio';

const getConsumerFull = (options = {}, errorHandler = {}) => {
    const { id } = options;
    return Honkio().mainShop.userFetch(
        'adminuserget',
        {
            id,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};

export default getConsumerFull;
