import Honkio from '../../Honkio';

const setSchedulerJob = (options = {}, errorHandler = () => {}) => {
    const {
        id,
        name,
        trigger,
        interval,
        interval_name,
        cron_args,
        run_date,
        start_date,
        end_date,
        timezone,
        description,
        active,
        scheduler_script_name,
        extra_arguments,
        merchant,
        application,
        action,
    } = options;
    return Honkio().mainShop.userFetch(
        'adminappschedulerjobset',
        {
            id,
            name,
            trigger,
            interval,
            interval_name,
            cron_args,
            run_date,
            start_date,
            end_date,
            timezone,
            description,
            active,
            scheduler_script_name,
            extra_arguments,
            merchant,
            application,
            action,
            version: 3,
        },
        errorHandler
    );
};

export const createSchedulerJob = (options = {}, errorHandler = () => {}) => {
    return setSchedulerJob({ ...options, action: 'create' }, errorHandler);
};

export const updateSchedulerJob = (options = {}, errorHandler = () => {}) => {
    return setSchedulerJob({ ...options, action: 'update' }, errorHandler);
};

export const deleteSchedulerJob = (options = {}, errorHandler = () => {}) => {
    return setSchedulerJob({ ...options, action: 'delete' }, errorHandler);
};
