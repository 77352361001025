import Honkio from '../../../Honkio';

const updateRegDocsReviewStatuses = (options, errorHandler = {}) => {
  const { order_id, reviewed_docs, verified } = options;
  return Honkio().mainShop.userFetch(
    'adminmerchantupdatedocverification',
    {
      order_id,
      reviewed_docs,
      verified,
      version: 3,
      action: 'update',
    },
    errorHandler
  );
};

export default updateRegDocsReviewStatuses;
