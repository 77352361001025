import React from 'react';

const ConsumerInfoContext = React.createContext({
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    role: '',
    is_superadmin: false,
    setConsumerInfo: ({ id, firstname, lastname, email }) => {},
    setRole: (role) => {},
    setIsSuperadmin: (val = false) => {},
});

export default ConsumerInfoContext;
