import { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import getProductTagList from '../../api/utils/tag/getProductTagList';
import useSearchString from '../../utils/useSearchString';
import LoadingBar from '../UI/LoadingBar';
import useLoader from '../UI/helpers/useLoader';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import Wait from '../UI/Wait';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const ProductTagList = () => {
  const [tags, setTags] = useState();
  const location = useLocation();

  const [progress, done] = useLoader([tags]);

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  const [loading, setLoading] = useState(false);

  const columns = useMemo(
    () => [
      { name: 'ID', grow: 1, autoSize: true, cell: ({ _id }) => _id },
      { name: 'Name', grow: 2, cell: ({ name }) => name },
      { name: 'Category', grow: 2, cell: ({ category }) => category },
    ],
    []
  );
  const { merchant } = useSearchString();

  const fetchTags = async (page = 1, merchants) => {
    setLoading(true);

    const resultHandler = (data) => {
      setTags(data?.tags);
      setTotalRows(data?.total);
      setLoading(false);
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setTags([]);
      setTotalRows(0);
      setLoading(false);
    };

    const res = await getProductTagList(
      {
        query_merchants: merchants,
        query_skip: (+page - 1) * perPage,
        query_count: perPage,
      },
      errorHandler
    );

    if (res && res.status === 'accept') {
      return resultHandler(res);
    }
  };

  const handlePageChange = (page) => {
    fetchTags(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  useEffect(() => {
    fetchTags();
  }, [merchant, perPage]);

  return done ? (
    <div>
      <NotificationContainer />
      <h2>Product tags</h2>
      <Link to={`${location.pathname}/add?merchant=${merchant}`}>
        <button className="btn btn-primary">Add tag</button>
      </Link>
      <DataTable
        dense
        striped
        highlightOnHover
        data={tags}
        columns={columns}
        pagination={true}
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={PER_PAGE_CHOICES}
        onChangeRowsPerPage={handleRowsPerPage}
        paginationServer
        progressPending={loading}
        onChangePage={handlePageChange}
        paginationTotalRows={totalRows}
        progressComponent={
          <div className="p-5">
            <Wait />
          </div>
        }
      />
    </div>
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default ProductTagList;
