import Honkio from '../../../Honkio';

const assetTypeSet = async function (options, errorHandler) {
  return Honkio().mainShop.userFetch(
    'adminassettypeset',
    {
      ...options,
      version: 3,
    },
    errorHandler
  );
};

const updateAssetType = (assetType, errorHandler) => {
  return assetTypeSet(
    {
      ...assetType,
      action: 'update',
    },
    errorHandler
  );
};

const createAssetType = (merchantId, appId, assetType, errorHandler) => {
  return assetTypeSet(
    {
      merchant: merchantId,
      application: appId,
      ...assetType,
      action: 'create',
    },
    errorHandler
  );
};

const deleteAssetType = (id, errorHandler) => {
  return assetTypeSet(
    {
      id,
      action: 'delete',
    },
    errorHandler
  );
};

export { updateAssetType, createAssetType, deleteAssetType };
