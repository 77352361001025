import React from 'react';
import { Button, Row, Col, Spinner, ButtonGroup } from 'react-bootstrap';
import cn from 'classnames';

const ActionButtons = ({
    previousStep = () => {},
    nextStep = () => {},
    lastStep = () => {},
    currentStep = 1,
    totalSteps = 1,
    loading = false,
    disableFinal = false,
}) => {
    const handleBack = () => {
        previousStep();
    };

    const handleNext = () => {
        nextStep();
    };

    const handleFinish = () => {
        lastStep();
    };

    return (
        <div>
            <ButtonGroup aria-label="Basic example">
                {currentStep > 1 && <Button onClick={handleBack}>Back</Button>}

                {loading ? (
                    <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Loading...
                    </Button>
                ) : (
                    <>
                        {currentStep < totalSteps && (
                            <Button onClick={handleNext}>Next</Button>
                        )}
                        {currentStep === totalSteps && !disableFinal && (
                            <Button onClick={handleFinish}>Save</Button>
                        )}
                    </>
                )}
            </ButtonGroup>
        </div>
    );
};

export default ActionButtons;
