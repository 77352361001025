import Honkio from '../../../Honkio';

const setMerchantTransactions = (options = {}, errorHandler = {}) => {
  const { id, status } = options;
  return Honkio().mainShop.userFetch(
    'merchanttransactionmanualverify',
    {
      id,
      status,
      version: 3,
      action: 'update',
    },
    errorHandler
  );
};

export default setMerchantTransactions;
