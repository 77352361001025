import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DataTable from 'react-data-table-component';
import { CONSUMER_URL } from '../../../config';
import CheckIcon from '@material-ui/icons/Check';
import { TimeRange } from 'pondjs';
import {
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'shards-react';
import ClearIcon from '@material-ui/icons/Clear';

import moment from 'moment';
import 'moment/locale/fi';
import HonkioAPI from '../../../api/Honkio';
import PageableDatatablesComponent from '../components/PageableDatatablesComponent';

const Circular = () => (
  // we need to add some padding to circular progress to keep it from activating our scrollbar
  <div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>
);

class AssetSensorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.loadAssets();
  }

  loadAssets(page, search) {
    if (!page) page = 0;
    let that = this;
    that.setState({ loading: true });
    let loadAsset = async function () {
      let query = {
        query_skip: parseInt(page * 10),
        query_count: 10,
      };
      if (search) {
        query['query_search'] = search;
      }
      let response = await HonkioAPI().userShop.userFetch(
        'merchantassetlist',
        query
      );
      that.setState({
        assets: response.assets,
        total_count: response.total_count,
        loading: false,
      });
    };
    loadAsset();
  }

  getColumns() {
    let state = this.state;
    let that = this;
    return [
      {
        name: '#',
        selector: 'id',
        cell: function (d) {
          let icon;
          switch (d.group) {
            case 'temperature':
              icon = 'fas fa-thermometer-half';
              break;
            case 'humidity':
              icon = 'fas fa-tint fa-2x';
              break;
            case 'proximity':
              icon = 'fas fa-door-open fa-2x';
              break;
            case 'touch':
              icon = 'fas fa-hand-point-up fa-2x';
              break;
          }

          return <i className={icon}>&nbsp;</i>;
        },
        //
        width: '10%',
        sortable: false,
      },

      {
        name: 'Name',
        width: '75%',
        cell: function (d) {
          return d.name;
        },
        sortable: true,
      },

      {
        name: 'Action',
        selector: 'action',
        width: '15%',
        cell: function (d) {
          // console.log('d');
          // console.log(d);

          return (
            <span className="pointer">
              <CheckIcon
                onClick={(e) => {
                  that.props.onAssetSelected(d);
                  that.props.nextStep();
                }}
              />
            </span>
          );
        },
      },
    ];
  }

  onSearch = (e) => {
    let that = this;
    let val = e.target.value;
    if (this.state.searching) {
      clearTimeout(this.state.searching);
    }
    this.setState({
      search: val,
      searching: setTimeout(function () {
        that.loadAssets(0, val);
      }, 200),
    });
  };

  onClearSearch = (e) => {
    if (this.state.searching) {
      clearTimeout(this.state.searching);
    }
    this.setState({ search: '' });
    this.loadAssets(0);
  };

  render() {
    return (
      <div>
        <InputGroup className="mb-2">
          <FormInput
            placeholder="Search"
            id="search"
            onChange={this.onSearch}
            value={this.state.search}
          />
          <InputGroupAddon type="append">
            <InputGroupText onClick={this.onClearSearch}>
              <ClearIcon />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <PageableDatatablesComponent
          // title="Employees"
          noHeader={true}
          columns={this.getColumns()}
          background="#ffffff00"
          items={this.state.assets}
          itemsPerPage={10}
          progressPending={this.state.loading}
          progressComponent={<Circular />}
          total={this.state.total_count}
          loadItems={(page) => {
            this.loadAssets(page);
          }}
        />
      </div>
    );
  }
}

export default AssetSensorList;
