const defaultModel = {
  merchant: 'merchant_id',
  name: 'Model name',
  order_type: 'Reservation',
  statuses: [],
};

const prepareToPublish = (
  { _id = null, merchant, application },
  name,
  statuses,
  order_type,
  destinationGroup
) =>
  Object.assign({}, defaultModel, {
    _id,
    name: name,
    merchant,
    application,
    statuses,
    order_type,
    destination_group: destinationGroup?.value,
  });

export default prepareToPublish;
