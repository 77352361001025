import { errorHandler, resultHandler } from '../../../utils/reqHandlers';
import UserInfoContext from '../../../contexts/UserInfoContext';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { getStatus } from '../utils';
import getMerchantTransaction from '../../../api/utils/transactions/merchant/getMerchantTransaction';

const TransactionRegisterMerchantDetails = () => {
  let { transactionID } = useParams();
  const [transaction, setTransaction] = useState({});
  const userInfo = useContext(UserInfoContext);
  let merchantId = userInfo.merchant ? userInfo.merchant.id : null;

  useEffect(() => {
    fetchTransactions();
  }, [merchantId, transactionID]);

  let fetchTransactions = async () => {
    getMerchantTransaction(
      {
        id: transactionID,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data, (data) => {
        setTransaction(data.transaction);
      });
    });
  };

  if (!transaction._id) {
    return <div></div>;
  }
  return (
    <Table>
      <tbody>
        <tr>
          <th>Time</th>
          <td>{transaction.timestamp}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>{transaction.identity.email}</td>
        </tr>
        <tr>
          <th>First name</th>
          <td>{transaction.identity.firstname}</td>
        </tr>
        <tr>
          <th>Last name</th>
          <td>{transaction.identity.lastname}</td>
        </tr>
        <tr>
          <th>Status</th>
          <td>{getStatus(transaction.status)}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TransactionRegisterMerchantDetails;
