import React, { useEffect, useState } from 'react';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AssetSelectList from './AssetSelectList';
import getRoomsList from '../../api/utils/chat/getRoomsList';
import RoomsList from './Rooms/RoomsList';
import createRoom from '../../api/utils/chat/createRoom';
import CreateRoomModal from './Room/CreateRoomModal';
import Honkio from '../../api/Honkio';
import getConsumerInfo from '../../api/utils/consumer/getConsumerInfo';

const Chat = () => {
  const history = useHistory();
  const [selectedAssetsList, setSelectedAssetsList] = useState([]);
  const [roomsList, setRoomsList] = useState([]);
  const [total, setTotal] = useState(0);
  const [appInfo, setAppInfo] = useState(null);
  const [info, setInfo] = useState(null);

  const [selectedRoom, setSelectedRoom] = useState(null);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [loadings, setLoadings] = useState({
    rooms: false,
    createRoom: false,
  });

  const [showAlert, setShowAlert] = useState(true);

  const fetchRoomsForAsset = async (asset_id) => {
    const errorHandler = (err) => {
      if (err) {
        console.log(`Error getting rooms list for asset`, err);
        setRoomsList([]);
        setLoadings({ ...loadings, rooms: false });
      }
    };
    setLoadings({ ...loadings, rooms: true });
    const res = await getRoomsList({ query_object: asset_id }, errorHandler);
    setLoadings({ ...loadings, rooms: false });
    if (res.status === 'accept') {
      setRoomsList(res.rooms);
      setTotal(res.total);
    }
  };

  useEffect(() => {
    if (selectedAssetsList.length) {
      fetchRoomsForAsset(selectedAssetsList?.[0]?._id);
    } else {
      setRoomsList([]);
    }
  }, [selectedAssetsList]);

  const handleSelectRoom = (room) => {
    setSelectedRoom(room);
    history.push(`/chat-room?id=${room._id}`);
  };

  const fetchCurrentUser = async () => {
    const options = {};
    const handleAuthError = (err) => {
      console.log(`Error getting user info ${err}`);
      setLoadings({ ...loadings, user: false });
    };
    setLoadings({ ...loadings, user: true });
    const res = await getConsumerInfo(options, handleAuthError);
    setLoadings({ ...loadings, user: false });
    setInfo(res?.consumer);
  };

  useEffect(() => {
    fetchCurrentUser();

    const notParsedInfo = localStorage.getItem('app_merchant_info');
    if (notParsedInfo) {
      setAppInfo(JSON.parse(notParsedInfo));
    }
  }, []);

  const handleCreateRoom = async ({ name, is_private }) => {
    let newParticipants = [];
    if (is_private) {
      newParticipants = [info._id, selectedAssetsList?.[0]?.properties.userid];
    }
    const data = {
      name,
      is_private,
      object_type: 'asset',
      object: selectedAssetsList?.[0]?._id,
      application: appInfo.app.id,
      merchant: appInfo.merchant.id,
      participants: newParticipants,
    };
    const errorHandler = (err) => {
      if (err) {
        console.log(`Error creating room`, err);
        setRoomsList([]);
        setLoadings({ ...loadings, createRoom: false });
      }
    };
    setLoadings({ ...loadings, createRoom: true });
    const res = await createRoom(data, errorHandler);
    setLoadings({ ...loadings, createRoom: false });
    NotificationManager.success('Room created!');
    if (res.status === 'accept') {
      if (selectedAssetsList.length) {
        fetchRoomsForAsset(selectedAssetsList?.[0]._id);
      } else {
        setRoomsList([]);
      }
    }
  };

  const handleSetSelectedAssetsList = (newVal = []) => {
    // Get rid of empty items
    const newSelected = newVal.filter((item) => {
      if (item && item?._id) return item;
    });
    setSelectedAssetsList(newSelected);
  };

  return (
    <div className="w-100 h-100">
      <NotificationContainer />
      <div className="container-fluid">
        <h2>Chat</h2>

        {showAlert ? (
          <div
            className="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            <strong>Warning</strong> Currently only works for INDX app assets.
            App and merchant should be set
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={(e) => setShowAlert(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : null}

        <AssetSelectList
          selectedAssetsList={selectedAssetsList}
          setSelectedAssetsList={handleSetSelectedAssetsList}
        />
        {roomsList.length ? (
          <RoomsList
            data={roomsList}
            ready={!loadings.rooms}
            onRowClicked={handleSelectRoom}
            emptyText="There are no rooms yet..."
          />
        ) : selectedAssetsList?.length ? (
          <h4>There are no rooms yet...</h4>
        ) : (
          <h4>Select an asset</h4>
        )}
        {selectedAssetsList?.length ? (
          <button
            className="btn btn-primary mt-3"
            onClick={() => setShowCreateModal(true)}
          >
            Create room
          </button>
        ) : null}
        <CreateRoomModal
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
          onSubmit={handleCreateRoom}
        />
      </div>
    </div>
  );
};

export default Chat;
