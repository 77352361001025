import Honkio from '../../Honkio';

const issuePayment = (options = {}, errorHandler = {}) => {
  const { order_id, customer_issue_description, owner_issue_description } =
    options;
  const data = {
    order_id,
    customer_issue_description,
    owner_issue_description,
    version: 3,
    action: 'update',
  };

  return Honkio().mainShop.userFetch('adminpaymentsissue', data, errorHandler);
};
export default issuePayment;
