import Honkio from '../../Honkio';

const getRolesInfo = async function (options = {}, errorHandler = () => {}) {
    const { query_all = false, query_info = false } = options;

    return Honkio().mainShop.userFetch(
        'rolelist',
        {
            query_all,
            query_info,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};

export default getRolesInfo;
