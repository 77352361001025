import { useMemo } from 'react';
import QueryString from 'qs';
import DataTable from 'react-data-table-component';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Wait from '../UI/Wait';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

const EventList = ({
  data,
  loading,
  emptyText,
  rowsCountPerPage,
  totalRows,
  handlePageChange,
  merchant,
  perPageChoices = [25],
  handleRowsPerPage,
  handleDeleteEvent,
}) => {
  const history = useHistory();
  const location = useLocation();

  const columns = useMemo(
    () => [
      {
        name: 'Start date',
        grow: 1,
        cell: ({ start_date }) => moment(start_date).format('DD-MM-YYYY'),
      },
      {
        name: 'End date',
        grow: 1,
        cell: ({ end_date }) => moment(end_date).format('DD-MM-YYYY'),
      },
      {
        name: 'Name',
        grow: 3,
        cell: ({ _id, name }) => (
          <div data-tip data-for={`${_id}_name_tip`}>
            {typeof name == 'string' ? name.slice(0, 25) + '...' : ''}
            <ReactTooltip id={`${_id}_name_tip`} place="top" effect="solid">
              {name}
            </ReactTooltip>
          </div>
        ),
      },
      {
        name: 'Assets',
        grow: 1,
        cell: ({ _id, assets }) => (
          <div data-tip data-for={`${_id}_assets_tip`} className="w-100">
            {assets?.length ? assets.length : 0}
            <ReactTooltip id={`${_id}_assets_tip`} place="top" effect="solid">
              {assets?.map((item) => item?.name)?.join('; ')}
            </ReactTooltip>
          </div>
        ),
      },
      {
        name: 'Merchant',
        grow: 1,
        cell: ({ merchant }) => merchant?.settings?.str_name,
      },
      {
        name: 'Payload',
        grow: 1,
        cell: (data) => (
          <div data-tip data-for={`${data?._id}_data_tip`} className="">
            <ReactTooltip
              id={`${data?._id}_data_tip`}
              place="bottom"
              effect="solid"
            >
              <pre style={{ color: 'white' }}>
                {JSON.stringify(data, null, 2)}
              </pre>
            </ReactTooltip>
            <Icon iconName="bi-info-circle" />
          </div>
        ),
      },
      {
        name: 'Actions',
        grow: 0,
        right: true,
        cell: ({ _id }) => {
          const link = `/events/edit?event=${_id}${
            merchant ? `&merchant=${merchant}` : ''
          }`;
          return (
            <div>
              <Link to={link} data-tip data-for="editTip">
                <Icon iconName="bi-pencil" />
                <ReactTooltip id="editTip" place="top" effect="solid">
                  Edit Event
                </ReactTooltip>
              </Link>
              <span
                data-tip
                data-for="deleteTip"
                onClick={() => handleDeleteEvent(_id)}
              >
                <Icon iconName="bi-trash" />
                <ReactTooltip id="deleteTip" place="top" effect="solid">
                  Delete Event
                </ReactTooltip>
              </span>
            </div>
          );
        },
      },
    ],
    [loading]
  );

  return (
    <DataTable
      dense
      striped
      highlightOnHover
      data={data}
      columns={columns}
      pagination={true}
      paginationPerPage={rowsCountPerPage}
      paginationRowsPerPageOptions={perPageChoices}
      onChangeRowsPerPage={handleRowsPerPage}
      paginationServer
      onChangePage={handlePageChange}
      paginationTotalRows={totalRows}
      progressPending={loading}
      noDataComponent={emptyText}
      progressComponent={
        <div className="p-5">
          <Wait />
        </div>
      }
      onRowClicked={({ id }) => {
        const { pathname, search } = location;
        const qsObject = {
          ...QueryString.parse(search, { ignoreQueryPrefix: true }),
          parent: id,
        };
        history.push(`${pathname}?${QueryString.stringify(qsObject)}`);
      }}
    />
  );
};

export default EventList;
