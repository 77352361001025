import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Image, Row, Table } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import getConsumer from '../../api/utils/consumer/getConsumer';
import useSearchString from '../../utils/useSearchString';
import BackButton from '../UI/BackButton';

const UserDetails = () => {
    const { user_id } = useSearchString();
    const [consumer, setConsumer] = useState(null);
    const [accesses, setAccesses] = useState([]);
    const fetchConsumer = async (id) => {
        const resultHandler = (data) => {
            setConsumer(data.consumer);
            setAccesses(data.accesses);
        };

        const errorHandler = (error) => {
            setConsumer({});
            NotificationManager.error(error.description, 'Error', 4000);
        };

        getConsumer({ id, accesses: true }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    useEffect(() => {
        if (user_id) {
            fetchConsumer(user_id);
        } else {
            setConsumer(null);
        }
    }, [user_id]);

    const columns = useMemo(
        () => [
            {
                name: 'Merchant',
                grow: 3,
                cell: (e) => {
                    return e.merchant_name;
                },
            },
            {
                name: 'Role',
                grow: 3,
                cell: (e) => {
                    return e.role_name;
                },
            },
        ],
        [accesses]
    );

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <h3 className="d-flex gap-2">
                <span>
                    <BackButton to="/users" text="Back to users list" />
                </span>
                <span>User Details: (ID#{user_id})</span>
            </h3>

            <Row>
                <Col xs={12} md={6}>
                    <Container>
                        <Image
                            src={consumer?.settings?.str_logo}
                            fluid
                            alt="Logo"
                        />
                    </Container>
                </Col>
            </Row>
            <Table
                striped
                hover
                style={{
                    tableLayout: 'fixed',
                    marginTop: '25px',
                }}
            >
                <tbody>
                    <tr>
                        <td colSpan={3}>
                            <b>Email: </b>
                        </td>
                        <td colSpan={9}>{consumer?.email}</td>
                    </tr>

                    <tr>
                        <td colSpan={3}>
                            <b>Active: </b>
                        </td>
                        <td colSpan={9}>
                            <input
                                onClick="return false"
                                type="checkbox"
                                checked={consumer?.active}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={3}>
                            <b>Name: </b>
                        </td>
                        <td colSpan={9}>{`${
                            consumer?.settings?.str_firstname || '-'
                        } ${consumer?.settings?.str_lastname || '-'} `}</td>
                    </tr>

                    <tr>
                        <td colSpan={3}>
                            <b>Phone: </b>
                        </td>
                        <td colSpan={9}>{consumer?.settings?.str_telephone}</td>
                    </tr>

                    <tr>
                        <td colSpan={3}>
                            <b>Language: </b>
                        </td>
                        <td colSpan={9}>{consumer?.settings?.str_language}</td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <b>Timezone: </b>
                        </td>
                        <td colSpan={9}>{consumer?.settings?.str_timezone}</td>
                    </tr>

                    <tr>
                        <td colSpan={3}>
                            <b>Country: </b>
                        </td>
                        <td colSpan={9}>{consumer?.settings?.str_country}</td>
                    </tr>

                    <tr>
                        <td colSpan={3}>
                            <b>Address: </b>
                        </td>
                        <td colSpan={9}>{` ${
                            consumer?.settings?.str_zip || '-'
                        } ${consumer?.settings?.str_city || '-'} ${
                            consumer?.settings?.str_address1 || '-'
                        }`}</td>
                    </tr>
                </tbody>
            </Table>

            <DataTable
                dense
                striped
                title="Accesses"
                noDataComponent="No permissions"
                highlightOnHover
                data={accesses}
                columns={columns}
            />
        </div>
    );
};

export default UserDetails;
