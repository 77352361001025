import MerchantCreate from '../../MerchantInfo/MerchantCreate';
import MerchantInfo from '../../MerchantInfo/MerchantInfo';
import Merchants from '../../Merchants/Merchants';
import MerchantMessagesList from '../../Messages/MerchantMessagesList';
import PaymentsTable from '../../PaymentsTable';
import MerchantRolesList from '../../Roles/MerchantRoleList';
import MerchantUsersList from '../../Roles/MerchantUsersList';
import Shops from '../../Shops/Shops';

export const routesMerchant = [
  {
    path: '/merchants',
    label: 'Merchants',
    component: Merchants,
  },
  {
    path: '/childMerchants',
    label: 'Merchants',
    component: Merchants,
  },
  {
    path: '/merchant/create',
    label: 'Merchant Create Form',
    component: MerchantCreate,
    hidden: true,
  },
  {
    path: '/merchant/role/create',
    label: 'Role create form',
    component: MerchantRolesList,
    hidden: true,
  },
  {
    path: '/merchant/role',
    label: 'Roles',
    component: MerchantRolesList,
    hidden: true,
  },
  {
    path: '/merchant/users',
    label: 'Merchant Users',
    component: MerchantUsersList,
    hidden: true,
  },
  {
    path: '/merchant/payments',
    label: 'Payments',
    component: PaymentsTable,
    hidden: true,
  },
  {
    path: '/merchant/edit',
    label: 'Merchant Edit Form',
    component: MerchantInfo,
    hidden: true,
  },
  {
    path: '/merchant/shops',
    label: "Merchant's shops list",
    component: Shops,
    hidden: true,
  },
  {
    path: '/merchant/messages',
    label: 'Merchant Messages List',
    component: MerchantMessagesList,
    hidden: true,
  },
];
