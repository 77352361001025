import Honkio from '../../Honkio';

const getConsumerApps = (options = {}, errorHandler = {}) => {
  const { debug, id } = options;
  return Honkio().mainShop.userFetch(
    'adminconsumergetapps',
    {
      debug,
      id,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getConsumerApps;
