import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Wait from '../UI/Wait';
import { defaultStyles, FileIcon } from 'react-file-icon';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';
import './file_manager.css';

export default class ImageBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fromDataToItems = this.fromDataToItems.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.data !== undefined && nextProps.data !== null) {
      this.fromDataToItems(nextProps.data);
    }
  }

  fromDataToItems(data) {
    let that = this;
    let items = [];
    let imgExtensions = ['jpg', 'png'];
    if (data.length > 0) {
      for (const [index, file] of data.entries()) {
        let size = (
          parseInt(file.metadata.size ? file.metadata.size : 0) / 1024
        ).toFixed(2);
        size = size.length < 7 ? size + 'Kb' : (size / 1024).toFixed(2) + 'Mb';
        let title = `${file.display_name}.${file.extension}`;
        let uploaded = file.timestamp;
        let ext = file.extension;
        let filePreview = <FileIcon extension={ext} {...defaultStyles[ext]} />;
        if (imgExtensions.indexOf(ext) >= 0 && this.props.preview) {
          filePreview = (
            <img
              className="mb-0 preview"
              src={file.url}
              alt="loading..."
              onClick={() => that.props.onPreviewClick(file)}
            />
          );
        }
        items.push(
          <div className="col-lg-3 col-xl-2" key={file._id}>
            <div className="file-man-box zoom-in">
              <Link
                to="#"
                onClick={() => that.props.onDelete(file)}
                className="file-close"
              >
                <span data-tip data-for="deleteTip">
                  <Icon iconName="bi bi-x-circle-fill" />
                  <ReactTooltip id="deleteTip" place="top" effect="solid">
                    Delete
                  </ReactTooltip>
                </span>
              </Link>
              <div className="file-img-box">{filePreview}</div>
              <Link
                to="#"
                onClick={() => that.props.onDownload(file)}
                className="file-download"
              >
                <span data-tip data-for="downloadTip">
                  <Icon iconName="bi bi-download" />
                  <ReactTooltip id="downloadTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </span>
              </Link>
              <div className="file-man-title">
                <p
                  id="title"
                  data-text={title}
                  data-tip
                  data-for="titleTip"
                  className="mb-0 file-man-text photo-man-text overflow-hidden"
                >
                  {title}
                  <ReactTooltip id="titleTip" place="top" effect="solid">
                    {title}
                  </ReactTooltip>
                </p>
                {/* <p id="size" className="mb-0"><small>{size}</small></p> */}
                <p id="date" className="mb-0">
                  <small>{uploaded}</small>
                </p>
              </div>
            </div>
          </div>
        );
      }
      this.setState({ items: items });
      return 0;
    }

    items.push(
      <div className="text-center container align-middle">
        <div className="jumbotron">
          <h5 className="display-4 text-muted">There are no images yet</h5>
          <p className="lead">When you upload a image it will be here</p>
          <hr className="my-4" />
          <p className="lead">
            <button
              className="btn btn-outline-primary btn-lg"
              onClick={() => that.props.onUploadClick()}
              role="button"
              data-bcup-haslogintext="no"
            >
              Upload image
            </button>
          </p>
        </div>
      </div>
    );

    this.setState({ items: items });
    return 0;
  }

  render() {
    let main = (
      <div style={{ marginLeft: '45%' }}>
        <Wait />
      </div>
    );

    if (this.state.items) {
      main = this.state.items;
    }

    return <div className="row mx-auto">{main}</div>;
  }
}
