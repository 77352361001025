const alphabet = {
  uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  lowercase: 'abcdefghijklmnopqrstuvwxyz',
  digits: '0123456789',
  symbols: `!@#$%^&*()_+<>,./?{}[];:'"/*-`,
};

const charactersRequiredDefault = {
  digits: 0,
  uppercase: 0,
  lowercase: 0,
  symbols: 0,
};

const lengthOptionsDefault = {
  min: 0,
  max: 16,
};

const getCharacters = (set = [], count = 0) => {
  const { length } = set;
  const result = Array(count).fill();
  for (const index in result) {
    result[index] = set[randomInt(length)];
  }
  return result;
};

const shuffle = (coll) => {
  const result = coll.slice();
  result.sort(() => Math.random() - 0.5);
  return result;
};

const randomInt = (max) => Math.floor(Math.random() * max);

const genPasswordByPolicy = (
  options = { characters: {}, length: {} },
  fillerGroups = ['lowercase']
) => {
  const { characters, length } = options;
  const characterPolicy = {
    ...charactersRequiredDefault,
    ...characters,
  };
  const lengthPolicy = { ...lengthOptionsDefault, ...length };
  const required = Object.entries(characterPolicy).reduce(
    (carry, [key, value]) => [...carry, ...getCharacters(alphabet[key], value)],
    []
  );
  const rest = Math.max(lengthPolicy.min - required.length, 0);
  const fillerCharacters = Object.entries(alphabet)
    .filter(([key]) => fillerGroups.includes(key))
    .reduce((carry, [_key, value]) => [...carry, ...value], []);
  const filler = getCharacters(fillerCharacters, rest);
  return shuffle([...required, ...filler]).join('');
};

export default genPasswordByPolicy;
