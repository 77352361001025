import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { ListGroup, Form, InputGroup, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import cn from 'classnames';

const ListStrInput = ({
    label = 'List of strings',
    info = '',
    name = '',
    value = [],
    onChange = () => {},
}) => {
    const handleAddItem = () => {
        const newValue = _.cloneDeep(value);
        newValue.push('');
        onChange(newValue);
    };

    const handleChangeItem = (val, i) => {
        const newValue = _.cloneDeep(value);
        newValue[i] = val;
        onChange(newValue);
    };

    const handleDeleteItem = (i) => {
        const newValue = _.cloneDeep(value);
        newValue.splice(i, 1);
        onChange(newValue);
    };

    return (
        <Form.Group controlId={name}>
            <Form.Label>{label}</Form.Label>
            <ListGroup>
                {value.map((item, i) => (
                    <ListGroup.Item key={`list-item-${i}`}>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                value={item}
                                onChange={(e) =>
                                    handleChangeItem(e.target.value, i)
                                }
                                placeholder="Enter text here..."
                                className={cn('border-0')}
                            />
                            <Button
                                size="sm"
                                variant="outline-danger"
                                id={`list-item-delete-${i}`}
                                onClick={(e) => handleDeleteItem(i)}
                            >
                                Delete
                            </Button>
                        </InputGroup>
                    </ListGroup.Item>
                ))}
                <ListGroup.Item key="action-buttons">
                    <Button variant="outline-success" onClick={handleAddItem}>
                        Add
                    </Button>
                </ListGroup.Item>
            </ListGroup>
            {info ? <Form.Text className="text-muted">{info}</Form.Text> : null}
        </Form.Group>
    );
};

export default ListStrInput;
