import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Col, Image } from 'react-bootstrap';
import useLoader from '../../UI/helpers/useLoader';
import { NotificationManager } from 'react-notifications';

const EditMessageModal = ({ id, item, show, onHide, onSubmit }) => {
  const [text, setText] = useState('');

  // TODO: Implement + room edit/delete
  const handleEditMessage = (e) => {
    if (!text) {
      NotificationManager.error('You must enter the text!');
      return;
    }
    onSubmit(e, { text });
    setText('');
    onHide();
  };

  useEffect(() => {
    setText(item?.text);
  }, [item]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            <label for="text">Text</label>
            <input
              type="text"
              className="form-control"
              id="text"
              placeholder="Enter text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleEditMessage}>
          Submit
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditMessageModal;
