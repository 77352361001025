import Honkio from '../../../Honkio';

const approveOrRejectMerchant = (options, errorHandler = {}) => {
  const { order_id, approved = false, rejected = false } = options;
  return Honkio().mainShop.userFetch(
    'adminmerchantapprovereject',
    {
      order_id,
      approved,
      rejected,
      version: 3,
      action: 'update',
    },
    errorHandler
  );
};

export default approveOrRejectMerchant;
