const customerTypeToState = (customerType) => {
    const {
        _id,
        name: name1,
        description: description1,
        merchant,
        application,
    } = customerType;

    const fields = customerType.properties.map((e) => {
        return {
            name: e.property_name,
            description: e.property_description,
            required: e.property_required ? 'yes' : 'no',
            type: e.property_type,
        };
    });
    const id_lists = customerType.id_lists || [];

    return {
        _id,
        name: name1,
        description: description1,
        merchant,
        application,
        fields,
        id_lists,
    };
};

export default customerTypeToState;
