import Honkio from '../../Honkio';

/**
 *
 * @param {*} options
 * @param {*} errorHandler
 * @returns
 */
const freezeOrder = async (options, errorHandler) => {
    const { id, is_frozen } = options;

    return Honkio().mainShop.userFetch(
        'adminorderfreeze',
        {
            id,
            is_frozen,
            action: 'update',
            version: 3,
        },
        errorHandler
    );
};

export default freezeOrder;
