const appParse = (acc, item) => [
  ...acc,
  {
    id: item['_id'],
    name: item['name'],
    appClass: item['class'],
    logo: item['str_logo_small'],
    active: item['active'].toString(),
  },
];

export default appParse;
