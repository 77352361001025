const mapHookFormValsArrayToPlain = (arr) => {
  return arr.map((item) => item?.value);
};

// Convert data before submittin form
const prepareSettingsData = (data) => {
  const submitYearlyTurnover = mapHookFormValsArrayToPlain(
    data.yearly_turnover
  );

  const submitRiskyBusinesses = mapHookFormValsArrayToPlain(
    data.risky_businesses_list
  );

  const submitNumberOfTransactions = mapHookFormValsArrayToPlain(
    data.number_of_transactions
  );

  const submitNumberOfReturns = mapHookFormValsArrayToPlain(
    data.number_of_returns
  );

  const submitAvgReturnAmount = mapHookFormValsArrayToPlain(
    data.average_return_amount
  );

  const submitCountries = data.countries.map((item) => item.value);

  const preparedData = {
    pricing_plans: data.pricing_plans,
    yearly_turnover: submitYearlyTurnover,
    risky_businesses_list: submitRiskyBusinesses,
    number_of_transactions: submitNumberOfTransactions,
    number_of_returns: submitNumberOfReturns,
    average_return_amount: submitAvgReturnAmount,
    countries: submitCountries,
    privacy_policy: data.privacy_policy,
  };

  return preparedData;
};

export default prepareSettingsData;
