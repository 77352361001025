import React, { useEffect, useState } from 'react';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import LoadingBar from '../../UI/LoadingBar';
import useLoader from '../../UI/helpers/useLoader';
import useSearchString from '../../../utils/useSearchString';
import { getAdminOrder } from '../../../api/utils/order/getOrders';
import DocsInfoForm from './DocsInfoForm';
import updateRegDocsReviewStatuses from '../../../api/utils/merchant/registration/updateRegDocsReviewStatuses';

const DocsInfo = () => {
  const { id: order_id } = useSearchString();
  const [loading, setLoading] = useState(false);
  const [regOrder, setRegOrder] = useState(null);
  const [progress, done] = useLoader([regOrder]);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    fetchOrderInfo();
  }, [order_id]);

  const fetchOrderInfo = () => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        setRegOrder(data.order);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    const params = {
      id: order_id,
    };
    getAdminOrder(params, errorHandler).then((data) => {
      return resultHandler(data);
    });
  };

  const handleSubmitDocVerifications = (data) => {
    setLoading(true);
    console.log('handleSubmitDocVerifications: data ==', data);
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setLoading(false);
    };
    const options = {
      order_id,
      reviewed_docs: data,
      verified,
    };
    updateRegDocsReviewStatuses(options, errorHandler).then((res) => {
      console.log('res == ', res);
      if (res?.status === 'accept') {
        NotificationManager.success('Saved successfully', 'Success', 4000);
      } else {
        NotificationManager.error('Error saving changes', 'Error', 4000);
      }

      setLoading(false);
      fetchOrderInfo();
    });
  };

  const getConsumerFullname = () => {
    if (regOrder?.consumer?.settings) {
      return `${regOrder?.consumer?.settings?.str_firstname} ${regOrder?.consumer.settings?.str_lastname}`;
    }
    return '';
  };

  return (
    <div>
      <NotificationContainer />
      <h2>Documents Info (ID#{order_id})</h2>
      {done ? (
        regOrder ? (
          <div>
            <div className="inline-flex text-muted mb-3">
              Merchant: {regOrder.user_owner}, {getConsumerFullname()}
            </div>
            <DocsInfoForm
              regOrder={regOrder}
              onSubmit={handleSubmitDocVerifications}
              loading={loading}
              verified={verified}
              setVerified={setVerified}
            />
          </div>
        ) : (
          'not found'
        )
      ) : (
        <LoadingBar progress={progress} />
      )}
    </div>
  );
};

export default DocsInfo;
