import { useContext, useEffect, useMemo, useState } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal, Button } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import getAssetType from './../../api/utils/asset/type/getAssetType';
import DataTable from 'react-data-table-component';
import { NotificationManager } from 'react-notifications';
import { getAssets } from '../../api/utils/asset/getAssets';
import UserInfoContext from '../../contexts/UserInfoContext';
import getConsumer from '../../api/utils/consumer/getConsumer';
import _ from 'lodash';
import Wait from '../UI/Wait';
import setAssetData from '../../api/utils/asset/setAssetData';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const UserVouchersModal = ({
  title = '',
  user = {},
  show = false,
  onHide = () => {},
}) => {
  const userInfo = useContext(UserInfoContext);
  const [assets, setAssets] = useState([]);
  const [progress, done] = useLoader([assets]);
  const merchantId = userInfo.merchant ? userInfo.merchant.id : null;

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  const [loading, setLoading] = useState(false);

  const [selectedVauchers, setSelectedVouchers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (user?.id) fetchUser(user.id);
  }, [user]);

  const fetchUser = async (id) => {
    const resultHandler = (data) => {
      setCurrentUser(data.consumer);
    };

    const errorHandler = (error) => {
      setCurrentUser();
      NotificationManager.error(error.description, 'Error', 4000);
    };

    try {
      const res = await getConsumer({ id }, errorHandler);
      if (res && res?.status === 'accept') resultHandler(res);
    } catch (error) {
      console.log('error getting user: ', error);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (show && currentUser) fetchAssets();
  }, [show, perPage, currentUser]);

  const fetchAssets = async (page = 1) => {
    setLoading(true);

    const resultHandler = (data) => {
      setLoading(false);
      if (data && data.status === 'accept') {
        setAssets(data.assets);
        setTotalRows(data?.total);
      } else {
        setAssets([]);
        setTotalRows(0);
      }
    };

    const errorHandler = (error) => {
      setLoading(false);
      NotificationManager.error(error.description, 'Error', 4000);
      setAssets([]);
      setTotalRows(0);
    };

    getAssets(
      {
        query_type: 'voucher',
        query_properties: {
          consumer_id: { $eq: currentUser._id },
        },
        query_skip: (+page - 1) * perPage,
        query_count: perPage,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const handlePageChange = (page) => {
    fetchAssets(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  const columns = useMemo(() => [
    {
      name: 'Image',
      cell: ({ properties }, index) => (
        <img
          height="32px"
          width="32px"
          src={`${properties?.image_url}`}
          alt="User's avatar"
        />
      ),
    },
    {
      name: 'Name',
      cell: ({ name }, index) => name,
      sortable: true,
    },
    {
      name: 'Amount',
      cell: ({ properties }, index) => properties?.amount || 0,
      sortable: true,
      grow: 0,
      autoWidth: true,
    },
    {
      name: 'Description',
      cell: ({ description }, index) => description,
      sortable: true,
      grow: 2,
    },
  ]);

  const onRowsSelected = ({
    allSelected,
    selectedCount,
    selectedRows = [],
  }) => {
    const voucherIds = selectedRows.map((item) => item?._id);
    setSelectedVouchers(voucherIds);
  };

  const handleDeleteAsset = async (id) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        console.log(`Asset ${id} removed`);
        return data;
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    try {
      const res = await setAssetData(
        { id },
        null,
        null,
        errorHandler,
        'delete'
      );
      if (res && res?.status == 'accept') {
        resultHandler(res);
      } else {
        return res;
      }
    } catch (error) {
      console.log(`Error removing asset: `, error);
    }
  };

  const onDeleteVouchers = async () => {
    console.log('remove vouchers', selectedVauchers);
    let removedCount = 0;
    let errorsCount = 0;
    for (let i in selectedVauchers) {
      const res = await handleDeleteAsset(selectedVauchers[i]);
      if (res && res?.status === 'accept') {
        removedCount += 1;
      } else {
        errorsCount += 1;
      }
    }

    NotificationManager.info(`Removed ${removedCount}; errors ${errorsCount}`);
    fetchAssets();
  };

  const container = (
    <div className="container">
      <DataTable
        selectableRows
        selectableRowsVisibleOnly
        selectableRowsHighlight
        onSelectedRowsChange={onRowsSelected}
        dense
        striped
        noHeader
        noDataComponent="no vouchers"
        highlightOnHover
        data={assets}
        pagination={true}
        columns={columns}
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={PER_PAGE_CHOICES}
        onChangeRowsPerPage={handleRowsPerPage}
        paginationServer
        progressPending={loading}
        onChangePage={handlePageChange}
        paginationTotalRows={totalRows}
        progressComponent={
          <div className="p-5">
            <Wait />
          </div>
        }
      />
    </div>
  );

  const onClickHide = () => {
    setAssets([]);
    onHide();
  };

  const handleRemoveSelected = async () => {
    if (selectedVauchers?.length) {
      await onDeleteVouchers(selectedVauchers, [user.id]);
      setSelectedVouchers([]);
      await fetchUser(user?.id);
    } else {
      NotificationManager.error('You must select vouchers first');
    }
  };

  return (
    <Modal show={show} onHide={onClickHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title ? title : 'User vouchers'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{loading ? <Wait /> : container}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleRemoveSelected}>
          Remove selected
        </Button>
        <Button variant="secondary" onClick={onClickHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserVouchersModal;
