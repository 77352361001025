import Honkio from '../../../Honkio';

const listSpecialTriggers = (options = {}, errorHandler = () => {}) => {
  return Honkio().mainShop.userFetch(
    'adminappspecialactionslistactions',
    {
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};
export default listSpecialTriggers;
