import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

const SmartForm = ({
    defaultValues,
    children,
    onSubmit,
    id,
    className,
    header,
    callback,
}) => {
    const methods = useForm({ defaultValues });
    const { reset, handleSubmit, watch } = methods;
    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const watchAllFields = watch();

    useEffect(() => {
        if (callback) {
            try {
                if (watchAllFields) {
                    callback(watchAllFields);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [watchAllFields]);

    return (
        <div className={className}>
            {header ? <h2>{header}</h2> : null}
            <FormProvider {...methods}>
                <form id={id} onSubmit={handleSubmit(onSubmit)}>
                    {children}
                </form>
            </FormProvider>
        </div>
    );
};

export default SmartForm;
