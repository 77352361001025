/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactFlow, {
  addEdge,
  removeElements,
  isNode,
  Controls,
  Background,
  useStoreActions,
  useStoreState,
} from 'react-flow-renderer';
import dagre from 'dagre';
import '../../App.css';
import defaultNode from './dafaultNode';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import cn from 'classnames';
import Select from 'react-select';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const getLayoutedElements = (elements, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });
  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: 150, height: 50 });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });
  dagre.layout(dagreGraph);
  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';
      // unfortunately we need this little hack to pass a slighltiy different position
      // in order to notify react flow about the change
      el.position = {
        x: nodeWithPosition.x + Math.random() / 1000,
        y: nodeWithPosition.y,
      };
    }
    return el;
  });
};

function countWords(s) {
  s = s.replace(/(^\s*)|(\s*$)/gi, ''); //exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi, ' '); //2 or more space to 1
  s = s.replace(/\n /, '\n'); // exclude newline with a start spacing
  return s.split(' ').filter(function (str) {
    return str != '';
  }).length;
}

const LayoutFlow = ({
  initialElements,
  orderModel,
  onPublish,
  onLoad,
  className,
  showAddNode = true,
  restrictStatuses = false,
  allowedStatuses = [],
  onDeleteNode,
  onResetNodes,
}) => {
  const [elements, setElements] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const ref = useRef();

  const selectedElements = useStoreState((store) => {
    return store.selectedElements;
  });

  const setSelectedElements = useStoreActions(
    (actions) => actions.setSelectedElements
  );

  useEffect(() => {
    setElements(initialElements);
  }, [initialElements]);

  const onConnect = (params) =>
    setElements((els) => addEdge({ ...params, animated: true }, els));

  const onElementsRemove = (elementsToRemove) => {
    console.log('onElementsRemove: elementsToRemove ==', elementsToRemove);
    setElements((els) => removeElements(elementsToRemove, els));
    setSelectedElements([]);
  };

  const onAdd = useCallback(() => {
    let nodeName = '';
    if (!restrictStatuses) nodeName = ref?.current?.value;
    if (restrictStatuses) {
      nodeName = selectedStatus?.value;
    }

    if (nodeName && nodeName.length > 0) {
      if (countWords(nodeName) === 1) {
        if (
          Object.keys(orderModel.statuses).findIndex((e) => e === nodeName) ===
          -1
        ) {
          const new_element = defaultNode(nodeName);
          setElements((elements) => [...elements, new_element]);
          if (ref?.current?.value) ref.current.value = '';
        } else {
          NotificationManager.warning('Status name is not unique');
        }
      } else {
        NotificationManager.warning('Status name should contain 1 word only');
      }
    } else {
      NotificationManager.warning('Status name is empty');
    }
  }, [setElements, restrictStatuses, selectedStatus]);

  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements, direction);
      setElements(layoutedElements);
    },
    [elements]
  );

  const renderAddNode = () => {
    if (restrictStatuses) {
      return (
        <div className="form-group mt-1">
          <Select
            className="bg-transparent"
            options={allowedStatuses}
            value={selectedStatus}
            onChange={(val) => {
              setSelectedStatus(val);
            }}
          />
          <button
            type="button"
            className="btn btn-outline-success btn-sm float-right mt-1"
            onClick={onAdd}
          >
            Add node
          </button>
        </div>
      );
    }
    return (
      <div className="form-group mt-1">
        <input
          type="text"
          id="name"
          placeholder="Enter new status name here"
          className="form-control my-1 bg-transparent"
          ref={ref}
          autoFocus
        />
        <div
          className="btn-group w-100"
          role="group"
          aria-label="Nodes actions"
        >
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={onAdd}
          >
            Add node
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-sm float-right"
            onClick={() => onDeleteNode(selectedElements?.[0]?.id)}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-outline-info btn-sm float-right"
            onClick={onResetNodes}
          >
            Reset
          </button>
        </div>
      </div>
    );
  };

  return (
    elements && (
      <div className={cn('layoutflow w-100', className)}>
        <NotificationContainer />
        <ReactFlow
          elements={elements}
          onConnect={onConnect}
          onElementsRemove={onElementsRemove}
          snapToGrid={true} // seems that it could be adjusted at dagreGraph
          onLoad={onLoad}
        >
          <Controls />
          <Background />
        </ReactFlow>
        <div className="controls">
          <div
            className="btn-group w-100"
            role="group"
            aria-label="Select layout direction"
          >
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => onLayout('TB')}
            >
              vertical layout
            </button>
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => onLayout('LR')}
            >
              horizontal layout
            </button>
          </div>
          {showAddNode ? renderAddNode() : null}
          {false && <button onClick={onPublish}>Save model</button>}
        </div>
      </div>
    )
  );
};
export default LayoutFlow;
