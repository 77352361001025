import { useEffect, useState, useContext } from 'react';
import UserInfoContext from '../../../../contexts/UserInfoContext';
import { Col, Row, Container } from 'react-bootstrap';
import InlineSearch from '../../../UI/InlineSearch';
import { errorHandler, resultHandler } from '../../../../utils/reqHandlers';
import useSearchString from '../../../../utils/useSearchString';
import ExternalList from './ExternalList';
import getExternalTrOrders from '../../../../api/utils/transactions/external';
import { NotificationContainer } from 'react-notifications';

const External = (options) => {
    const [keyword, setKeyword] = useState('');
    const [querySerial, setQuerySerial] = useState('');
    const [transactionsList, setTransactionsList] = useState([]);
    const { merchant } = useSearchString();
    const userInfo = useContext(UserInfoContext);
    let merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;

    // Orders list
    const [selected, setSelected] = useState({});
    const [loading, setLoading] = useState(false);
    //   Pagination
    const [totalRows, setRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);

    useEffect(() => {
        fetchTransactions();
    }, [querySerial, merchantId]);

    let fetchTransactions = async () => {
        setLoading(true);
        getExternalTrOrders(
            {
                page,
                perPage,
                keyword,
                merchantId,
                serial: querySerial,
            },
            errorHandler
        ).then((data) => {
            setLoading(false);
            return resultHandler(data, (data) => {
                setTransactionsList(data.orders);
                setRows(data.total);
            });
        });
    };
    const handlePageChange = (page) => {
        setPage(page);
    };
    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };
    useEffect(() => {
        fetchTransactions();
    }, [perPage, page]);

    return (
        <>
            <NotificationContainer />

            <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <InlineSearch
                            keyword={querySerial}
                            setKeyword={setQuerySerial}
                            ph="Serial number"
                        />
                    </Col>
                </Row>
            </Container>

            <Row>
                <Col sm={12}>
                    <ExternalList
                        data={transactionsList}
                        selectItem={setSelected}
                        id={selected.id}
                        loading={loading}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        handleRowsPerPage={handleRowsPerPage}
                        rowsCountPerPage={perPage}
                        merchant={merchantId}
                        emptyText={'The order list is empty'}
                    />
                </Col>
            </Row>
        </>
    );
};

export default External;
