import routes from '../AdminView/routes/routes';
import SettingsLink from './SettingsLink';

const Settings = () => {
    const renderLinks = () => {
        if (!Array.isArray(routes)) return null;

        return routes
            .filter((item) => !item.hidden)
            .map((item) => (
                <SettingsLink key={`settings-link-${item.path}`} to={item.path}>
                    {item.label}
                </SettingsLink>
            ));
    };

    return (
        <div className="container">
            <div className="row row-cols-2">
                {/* <SettingsLink to="/products/types">Product types</SettingsLink>
                <SettingsLink to="/servicekeys">Service keys</SettingsLink>
                <SettingsLink to="/password">Security</SettingsLink> */}
                {renderLinks()}
            </div>
        </div>
    );
};

export default Settings;
