import React, { useContext, useEffect, useState } from 'react';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import modelNames from '../../../__fixtures__/modelNames';
import Select from 'react-select';
import RoleSelect from '../../common/RoleSelect';
import _ from 'lodash';
import getAccessRule from '../../../api/utils/accessRules/getAccessRule';
import { Form, Button, Row } from 'react-bootstrap';
import LoadingBar from '../../UI/LoadingBar';
import useLoader from '../../UI/helpers/useLoader';
import getSchedulerJob from '../../../api/utils/scheduler/getJob';
import IntervalFields from './TriggerFields/IntervalFields';
import CronFields from './TriggerFields/CronFields';
import DateFields from './TriggerFields/DateFields';
import { getAllTimezones } from 'countries-and-timezones';
import UserInfoContext from '../../../contexts/UserInfoContext';
import AsyncSelect from 'react-select/async';
import getSchedulerScriptsList from '../../../api/utils/scheduler/getSchedulerScriptsList';
import moment from 'moment';
import JobExtraArgInput from './JobExtraArgInput';
import { v4 as uuidv4 } from 'uuid';
import getSchedulerScript from '../../../api/utils/scheduler/getSchedulerScript';

const TRIGGERS_LIST = [
    {
        label: 'Interval',
        value: 'interval',
    },
    {
        label: 'Cron',
        value: 'cron',
    },
    {
        label: 'Date',
        value: 'date',
    },
];

const PER_PAGE = 100;

const JobForm = ({ job_id, onSubmit, submitLoading }) => {
    const {
        app: { id: appId, name: appName },
        merchant: { id: merchantId, name: merchantName },
    } = useContext(UserInfoContext);

    const [job, setJob] = useState(null);
    const [extraArgs, setExtraArgs] = useState([]);

    const [timezoneOptions, setTimezoneOptions] = useState([]);

    // Async scheduler scripts list - start
    const [scriptsList, setScriptList] = useState([]);
    const [scriptsTotal, setScriptsTotal] = useState(0);
    const [scriptsLoaded, setScriptsLoaded] = useState(0);
    const [loadingScripts, setLoadingScripts] = useState(false);
    const [selectedScript, setSelectedScript] = useState();
    const [page, setPage] = useState(1);
    // Async scheduler scripts list - end

    const [loading, setLoading] = useState(false);

    const [progress, done] = useLoader([job]);

    useEffect(() => {
        fetchScriptsList();
    }, [page]);

    const fetchScriptsList = async (search = '') => {
        setLoadingScripts(true);
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setScriptList((prev) =>
                    _.uniqBy([...prev, ...data.scripts], (o) => o.name)
                );
                setScriptsTotal(data?.total);
                if (scriptsLoaded < data?.total)
                    setScriptsLoaded((prev) => {
                        return prev + data?.scripts?.length;
                    });
                setLoadingScripts(false);
                return data.scripts;
            } else {
                setScriptList([]);
                setScriptsTotal(0);
                setLoadingScripts(false);
                return [];
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setScriptList([]);
            setScriptsTotal(0);
            setLoadingScripts(false);
            return [];
        };

        if (!merchantId || !appId) return;

        const res = await getSchedulerScriptsList(
            {
                query_skip: (+page - 1) * PER_PAGE,
                query_count: PER_PAGE,
                query_search: search,
            },
            errorHandler
        );
        if (res && res.status === 'accept') {
            return resultHandler(res);
        } else {
            return [];
        }
    };

    useEffect(() => {
        setTimezoneOptions(getAllTimezones());
    }, []);

    const handleChangeJob = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        setJob((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        let submitJob = _.cloneDeep(job);

        if (submitJob && appId) {
            submitJob.application = appId;
        }
        if (submitJob && merchantId) {
            submitJob.merchant = merchantId;
        }
        if (selectedScript) {
            submitJob.scheduler_script_name = selectedScript.name;
        }
        if (submitJob?.start_date) {
            submitJob.start_date = moment(submitJob.start_date).format(
                'DD-MM-YYYY HH:mm'
            );
        }
        if (submitJob?.end_date) {
            submitJob.end_date = moment(submitJob.end_date).format(
                'DD-MM-YYYY HH:mm'
            );
        }

        if (submitJob?.run_date) {
            submitJob.run_date = moment(submitJob.run_date).format(
                'DD-MM-YYYY HH:mm'
            );
        }

        if (submitJob?._id) {
            submitJob.id = submitJob._id;
        }

        if (extraArgs.length) {
            let submitArgs = {};
            extraArgs.forEach((item) => {
                submitArgs[item.name] = item.value;
            });
            submitJob.extra_arguments = submitArgs;
        } else {
            submitJob.extra_arguments = { hello: 'World' };
        }

        await onSubmit(submitJob);

        if (job_id) fetchJob(job_id);
    };

    useEffect(() => {
        if (job_id) {
            fetchJob(job_id);
        } else {
            // To hide loader
            setJob({});
        }
    }, [job_id]);

    const setCurrentScript = async () => {
        if (job?.script_name && scriptsList?.length) {
            let currentScript = scriptsList.find(
                (item) => item.name === job.script_name
            );
            if (currentScript) {
                setSelectedScript(currentScript);
            } else {
                try {
                    const res = await getSchedulerScript(
                        { query_name: job.script_name },
                        (error) => console.log(error)
                    );
                    if (res && res.status === 'accept') {
                        setSelectedScript(res.script);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    useEffect(() => {
        setCurrentScript();
    }, [job, scriptsList]);

    const fetchJob = async (id) => {
        setLoading(true);
        const errorHandler = (error) => {
            NotificationManager.error(error.description);
        };

        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                let loadedJob = _.cloneDeep(data?.job || {});
                if (loadedJob?.start_date) {
                    loadedJob.start_date = moment(
                        loadedJob.start_date
                    ).toDate();
                }
                if (loadedJob?.end_date) {
                    loadedJob.end_date = moment(loadedJob.end_date).toDate();
                }
                if (loadedJob?.run_date) {
                    loadedJob.run_date = moment(loadedJob.run_date).toDate();
                }
                if (loadedJob?.extra_arguments) {
                    setExtraArgs(
                        Object.keys(loadedJob.extra_arguments).map((item) => ({
                            id: uuidv4(),
                            name: item,
                            value: loadedJob.extra_arguments[item],
                        }))
                    );
                }
                setJob(loadedJob);
                setLoading(false);
            } else {
                setLoading(false);
                setJob(null);
            }
        };

        try {
            const res = await getSchedulerJob({ id }, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.error('error fetching job: ', error);
            errorHandler(error);
        }
    };

    const renderTriggerFields = (trigger) => {
        if (!trigger)
            return (
                <Form.Text id="triggerHelp" className="mb-3">
                    Select trigger
                </Form.Text>
            );

        switch (trigger) {
            case 'interval':
                return (
                    <IntervalFields
                        job={job}
                        handleChangeJob={handleChangeJob}
                    />
                );
            case 'cron':
                return (
                    <CronFields job={job} handleChangeJob={handleChangeJob} />
                );
            case 'date':
                return (
                    <DateFields job={job} handleChangeJob={handleChangeJob} />
                );
            default:
                return <>Unsupported trigger type</>;
        }
    };

    const handleScrollToBottomScripts = (e) => {
        if (setScriptsLoaded < scriptsTotal) {
            setPage((prev) => prev + 1);
        }
    };

    const promiseOptionsScripts = async (inputValue) => {
        setScriptList([]);
        setScriptsLoaded(0);
        setScriptsTotal(0);

        return await fetchScriptsList(inputValue);
    };

    const handleChangeTrigger = (e) => {
        e.preventDefault();
        const newTrigger = e.target.value;
        let clearTriggerFields = {};
        if (newTrigger === 'interval') {
            clearTriggerFields = {
                cron_args: undefined,
                run_date: undefined,
            };
        } else if (newTrigger === 'cron') {
            clearTriggerFields = {
                interval: undefined,
                interval_name: undefined,
                run_date: undefined,
            };
        } else if (newTrigger === 'date') {
            clearTriggerFields = {
                cron_args: undefined,
                interval: undefined,
                interval_name: undefined,
                start_date: undefined,
                end_date: undefined,
            };
        } else {
            console.error('unknown trigger name');
        }
        setJob((prev) => ({
            ...prev,
            ...clearTriggerFields,
            trigger: newTrigger,
        }));
    };

    const handleAddExtraArg = () => {
        setExtraArgs((prev) => [
            ...prev,
            {
                id: uuidv4(),
                name: 'new_arg',
                value: 'value',
            },
        ]);
    };

    const handleChangeExtraArg = (idx, value) => {
        setExtraArgs((prev) => {
            const updatedExtra = _.cloneDeep(prev);
            updatedExtra[idx] = value;

            return updatedExtra;
        });
    };

    const handleRemoveExtraArg = (id) => {
        setExtraArgs((prev) => prev?.filter((item) => item?.id !== id));
    };

    const renderExtraArgs = () => {
        return extraArgs.map((item, idx) => (
            <JobExtraArgInput
                key={`${item?.id}-${idx}-job-extra-arg`}
                arg={item}
                index={idx}
                onChange={handleChangeExtraArg}
                onRemove={handleRemoveExtraArg}
            />
        ));
    };

    return done ? (
        <Form onSubmit={handleSubmitForm}>
            {job?.next_run_time ? (
                <Form.Text className="">
                    Next run time: {job.next_run_time}
                </Form.Text>
            ) : null}
            <Form.Group className="mb-3" controlId="data.name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={job?.name || ''}
                    onChange={handleChangeJob}
                    required
                />
                <Form.Text className="text-muted">Name of the job</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.trigger">
                <Form.Label>Trigger</Form.Label>
                <Form.Control
                    as={'select'}
                    aria-label="Trigger"
                    name="trigger"
                    value={job?.trigger || ''}
                    onChange={handleChangeTrigger}
                    className="custom-select"
                >
                    <option value="">&mdash; Select job trigger &mdash;</option>
                    {TRIGGERS_LIST.map((item, index) => (
                        <option
                            key={`${index}-${item?.value}-trigger-option`}
                            value={item?.value}
                        >
                            {item?.label}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text className="text-muted">Job trigger type</Form.Text>
            </Form.Group>

            {renderTriggerFields(job?.trigger)}

            <Form.Group className="mb-3" controlId="data.timezone">
                <Form.Label>Timezone</Form.Label>
                <Form.Control
                    as={'select'}
                    aria-label="Timezone"
                    name="timezone"
                    value={job?.timezone || ''}
                    onChange={handleChangeJob}
                    className="custom-select"
                >
                    <option value="">
                        &mdash; Select job timezone &mdash;
                    </option>
                    {Object.entries(timezoneOptions).map((item, index) => (
                        <option
                            key={`${index}-${item?.[1]?.name}-timezone-option`}
                            value={item?.[1]?.name}
                        >
                            {item?.[1]?.name}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text className="text-muted">Job trigger type</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="This job does something important..."
                    name="description"
                    value={job?.description || ''}
                    onChange={handleChangeJob}
                />
            </Form.Group>

            <Form.Check // prettier-ignore
                type="switch"
                id="active"
                name="active"
                label="Active"
                // value={!!job?.active}
                checked={!!job?.active}
                onChange={(e) =>
                    handleChangeJob({
                        target: { name: 'active', value: !job?.active },
                    })
                }
            />

            <AsyncSelect
                key="script_select"
                onMenuScrollToBottom={handleScrollToBottomScripts}
                cacheOptions={scriptsList}
                defaultOptions={scriptsList}
                isLoading={loadingScripts}
                value={selectedScript}
                onChange={(val) => {
                    setSelectedScript(val);
                }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.name}
                isSearchable={true}
                loadOptions={promiseOptionsScripts}
                isClearable={true}
                required
                className="my-3"
                placeholder="Select a script for this job"
            />

            <hr />
            {renderExtraArgs()}
            <Button
                type="button"
                variant="primary"
                size="sm"
                onClick={handleAddExtraArg}
            >
                Add extra argument
            </Button>
            <hr />

            <Button
                type="submit"
                variant="primary"
                disabled={submitLoading}
                className="float-right"
            >
                Submit
            </Button>
        </Form>
    ) : (
        <LoadingBar progress={progress} />
    );
};

export default JobForm;
