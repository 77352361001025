import Honkio from '../../Honkio';

export const setAppInfo = (options = {}, errorHandler = () => {}) => {
  return Honkio().mainShop.userFetch(
    'adminappset',
    { ...options, version: 3 },
    errorHandler
  );
};

export const createApp = (options = {}, errorHandler = () => {}) => {
  return setAppInfo({ ...options, action: 'create' }, errorHandler);
};

export const updateApp = (options = {}, errorHandler = () => {}) => {
  return setAppInfo({ ...options, action: 'update' }, errorHandler);
};

export const deleteApp = (options = {}, errorHandler = () => {}) => {
  return setAppInfo({ ...options, action: 'delete' }, errorHandler);
};
