import React, { useEffect, useMemo, useState } from 'react';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import qs from 'qs';
import getMerchantData from '../../api/utils/merchant/getMerchantData';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';
import ReactFlow, {
  isNode,
  Controls,
  Background,
  ControlButton,
} from 'react-flow-renderer';
import dagre from 'dagre';
import LegalEntityNode from './components/CustomNodes/LegalEntityNode';
import NaturalEntityNode from './components/CustomNodes/NaturalEntityNode';
import RootNode from './components/CustomNodes/RootNode';
import UBOContext from '../../contexts/UBOGraphContext';
import Icon from '../UI/Icon';
import EntityModal from './components/EntityModal/EntityModal';
import _ from 'lodash';

const NODE_HEIGHT = 200;
const NODE_WIDTH = 300;
const UBO_LAYOUT = 'vertical';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const getLayoutedElements = (elements, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });
  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });
  dagre.layout(dagreGraph);
  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';
      // unfortunately we need this little hack to pass a slighltiy different position
      // in order to notify react flow about the change
      el.position = {
        x: nodeWithPosition.x + Math.random() / 1000,
        y: nodeWithPosition.y,
      };
    }
    return el;
  });
};

const UBOGraph = ({ uboContext, setUBOContext }) => {
  const { merchant_id } = qs.parse(useLocation().search, {
    ignoreQueryPrefix: true,
  });

  const [merchantInfo, setMerchantInfo] = useState(null);

  const [rfInstance, setRfInstance] = useState(null);

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [currentEntity, setCurrentEntity] = useState(null);

  const [progress, done] = useLoader([merchant_id, merchantInfo, nodes, edges]);

  const handleShowNodeInfo = (data, entityType) => {
    const newData = _.cloneDeep(data);
    newData.entityType = entityType;
    setCurrentEntity(newData);
  };
  const handleHideEntityModal = () => {
    setCurrentEntity(null);
  };

  const nodeTypes = useMemo(
    () => ({
      rootNode: (props) => (
        <RootNode {...props} handleShowNodeInfo={handleShowNodeInfo} />
      ),
      legalEntityNode: (props) => (
        <LegalEntityNode {...props} handleShowNodeInfo={handleShowNodeInfo} />
      ),
      naturalEntityNode: (props) => (
        <NaturalEntityNode {...props} handleShowNodeInfo={handleShowNodeInfo} />
      ),
    }),
    []
  );

  useEffect(() => {
    if (merchant_id) {
      fetchMerchant();
    }
    setUBOContext({ layout: UBO_LAYOUT, NODE_HEIGHT, NODE_WIDTH });
  }, [merchant_id]);

  const fetchMerchant = async () => {
    const resultHandler = (data) => {
      if (data && data?.status === 'accept' && data?.merchant) {
        setMerchantInfo(data?.merchant);
      } else {
        setMerchantInfo({});
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setMerchantInfo({});
    };

    getMerchantData({ id: merchant_id }, errorHandler).then((data) => {
      return resultHandler(data);
    });
  };

  useEffect(() => {
    if (merchantInfo?.custom_fields?.graph?.nodes) {
      setNodes(merchantInfo?.custom_fields?.graph?.nodes);
    }
    if (merchantInfo?.custom_fields?.graph?.edges) {
      setEdges(merchantInfo?.custom_fields?.graph?.edges);
    }
  }, [merchantInfo]);

  const onLoad = (instance) => {
    setRfInstance(instance);
    if (instance) instance.fitView();
  };

  const handleChangeLayout = (direction = 'vertical') => {
    setUBOContext((prevContext) => ({ ...prevContext, layout: direction }));
  };

  useEffect(() => {
    if (rfInstance) rfInstance.fitView();
  }, [uboContext?.layout]);

  return (
    <div className="container-fluid">
      <NotificationContainer />
      <h2>UBO Graph</h2>
      {done ? (
        <div className="w-100" style={{ height: '500px' }}>
          UBO Graph for {merchantInfo?.settings?.str_email || 'Anonymous'}{' '}
          <ReactFlow
            elements={getLayoutedElements(
              [...nodes, ...edges],
              uboContext.layout === 'vertical' ? 'TB' : 'LR'
            )}
            snapToGrid={true} // seems that it could be adjusted at dagreGraph
            onLoad={onLoad}
            nodeTypes={nodeTypes}
          >
            <Controls>
              <ControlButton
                onClick={() => handleChangeLayout('vertical')}
                title="Vertical layout"
              >
                <Icon iconName="bi-grip-vertical" />
              </ControlButton>
              <ControlButton
                onClick={() => handleChangeLayout('horizontal')}
                title="Horizontal layout"
              >
                <Icon iconName="bi-grip-horizontal" />
              </ControlButton>
            </Controls>
            <Background />
          </ReactFlow>
        </div>
      ) : (
        <LoadingBar progress={progress} />
      )}

      <EntityModal
        data={currentEntity}
        show={!_.isEmpty(currentEntity)}
        onHide={handleHideEntityModal}
      />
    </div>
  );
};

const WrappedUBOGraph = () => {
  const [uboContext, setUBOContext] = useState();

  return (
    <UBOContext.Provider value={uboContext}>
      <UBOGraph uboContext={uboContext} setUBOContext={setUBOContext} />
    </UBOContext.Provider>
  );
};

export default WrappedUBOGraph;
