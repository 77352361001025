import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import prepareToPublish from './helpers/prepareToPublish';
import VelocityRuleEditForm from './VelocityRuleEditForm';
import { createVelocityRule } from '../../api/utils/velocityRule/setAdminVelocityRule';

const VelocityRuleCreate = () => {
  const history = useHistory();
  const [velocityRule, setVelocityRule] = useState({
    name: '',
    description: '',
  });

  const onCreate = (velocityRule) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Saved');
        history.goBack();
      } else {
        NotificationManager.error('Error');
      }
    };
    const errorHandler = (error) => {
      console.log('Error creating velocity fule: e ==', error);
      NotificationManager.error(error.description, 'Error', 4000);
    };
    createVelocityRule(
      { ...prepareToPublish(null, velocityRule) },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  return (
    <div>
      <NotificationContainer />
      <VelocityRuleEditForm onSubmit={onCreate} velocityRule={velocityRule} />
    </div>
  );
};

export default VelocityRuleCreate;
