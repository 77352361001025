import React, { useEffect, useState, useContext } from 'react';
import { getOrders } from '../../api/utils/order/getOrders';
import useSearchString from '../../utils/useSearchString';
import ExpressForm from '../Form/ExpressForm';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import UserInfoContext from '../../contexts/UserInfoContext';
import { Modal, Button, Image, Table, Row, Col } from 'react-bootstrap';
import { getAdminOrder } from './../../api/utils/order/getOrders';
import renderCurrency from '../../utils/renderCurrency';
import setDecimals from '../../utils/setDecimals';

const OrderView = () => {
  const [info, setInfo] = useState({});
  const { order, merchant } = useSearchString();

  const userInfo = useContext(UserInfoContext);
  const merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;

  const resultHandler = (data) => {
    if (data && data.status === 'accept') {
      setInfo(data.order);
    }
  };

  const errorHandler = (error) => {
    NotificationManager.error(error.description, 'Error', 4000);
  };

  useEffect(() => {
    const params = {
      id: order,
    };
    getAdminOrder(params, errorHandler).then((data) => {
      return resultHandler(data);
    });
  }, [order, merchant]);

  if (!Object.keys(info).length) {
    return <label>No Data</label>;
  }

  const renderCustomFields = (customFields = {}) => {
    // return <pre>{JSON.stringify(customFields, null, 2)}</pre>;

    if (Object.keys(customFields).length) {
      return (
        <Table
          bordered
          striped
          size="sm"
          style={{
            tableLayout: 'fixed',
          }}
        >
          <tbody>
            {Object.entries(customFields).map(([key, value]) => (
              <tr key={key}>
                <td colSpan={4}>{key}</td>
                <td colSpan={8}>
                  {typeof value === 'string' ? (
                    value
                  ) : (
                    <pre className="break-word">
                      {JSON.stringify(value, null, 2)}
                    </pre>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }
    return null;
  };

  return (
    <Table
      striped
      hover
      style={{
        tableLayout: 'fixed',
      }}
    >
      <NotificationContainer />

      <tbody>
        <tr>
          <td colSpan={3}>
            <b>Title: </b>
          </td>
          <td colSpan={9}>{info.title}</td>
        </tr>

        <tr>
          <td colSpan={3}>
            <b>Status: </b>
          </td>
          <td colSpan={9}>{info.status}</td>
        </tr>

        <tr>
          <td colSpan={3}>
            <b>Owner: </b>
          </td>
          <td colSpan={9}>{info.user_owner}</td>
        </tr>

        <tr>
          <td colSpan={3}>
            <b>Model</b>
          </td>
          <td colSpan={9}>
            {info.model}{' '}
            {info?.model_reference ? (
              `(${info?.model_reference})`
            ) : (
              <>&mdash;</>
            )}
          </td>
        </tr>

        <tr>
          <td colSpan={3}>
            <b>Custom fields</b>
          </td>
          <td colSpan={9}>{renderCustomFields(info?.custom_fields)}</td>
        </tr>

        <tr>
          <td colSpan={3}>
            <b>Start date: </b>
          </td>
          <td colSpan={9}>{info.start_date}</td>
        </tr>

        {info?.end_date ? (
          <tr>
            <td colSpan={3}>
              <b>End date: </b>
            </td>
            <td colSpan={9}>{info.end_date}</td>
          </tr>
        ) : null}

        <tr>
          <td colSpan={3}>
            <b>Creation date: </b>
          </td>
          <td colSpan={9}>{info.creation_date}</td>
        </tr>

        <tr>
          <td colSpan={3}>
            <b>Completion date: </b>
          </td>
          <td colSpan={9}>{info.completion_date}</td>
        </tr>

        {info?.expire_date ? (
          <tr>
            <td colSpan={3}>
              <b>Expire date: </b>
            </td>
            <td colSpan={9}>{info.expire_date}</td>
          </tr>
        ) : null}

        <tr>
          <td colSpan={3}>
            <b>Amount: </b>
          </td>
          <td colSpan={9}>
            {renderCurrency(info.currency)}
            {setDecimals(info.amount)}
          </td>
        </tr>

        <tr>
          <td colSpan={3}>
            <b>Description: </b>
          </td>
          <td colSpan={9}>{info.description}</td>
        </tr>
        <tr>
          <td colSpan={3}>
            <b>Products: </b>
          </td>
          <td colSpan={9}>
            {info.products.map((product) => {
              return (
                <Table
                  size="sm"
                  bordered
                  style={{
                    tableLayout: 'fixed',
                  }}
                >
                  <tbody>
                    <tr>
                      <td colSpan={4}>
                        <b>Name: </b>
                      </td>
                      <td colSpan={8}>{product.name}</td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <b>Amount: </b>
                      </td>
                      <td colSpan={8}>
                        {renderCurrency(product.currency)}
                        {setDecimals(product.amount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <b>Count: </b>
                      </td>
                      <td colSpan={8}>{product.count || 1}</td>
                    </tr>
                  </tbody>
                </Table>
              );
            })}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default OrderView;
