import _ from 'lodash';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import cn from 'classnames';
import { NotificationContainer } from 'react-notifications';

const PaymentForm = ({ onSubmit = () => {}, paymentInfo = () => {} }) => {
    const [payment, setPayment] = useState(paymentInfo);

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = _.cloneDeep(payment);

        onSubmit(data);
    };

    const handleChangeAppField = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setPayment((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            name="title"
                            type="text"
                            label="Title"
                            value={payment?.title}
                            onChange={handleChangeAppField}
                            placeholder="Stripe"
                            required
                        />
                    </Col>
                </Row>

                <Button
                    type="submit"
                    variant="primary"
                    className={cn('float-right')}
                >
                    Save changes
                </Button>
            </Form>
        </div>
    );
};

export default PaymentForm;
