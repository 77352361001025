import Honkio from '../../Honkio';

const getServerAppList = (options = {}, errorHandler = {}) => {
  const { query_all, merchant, debug, appid, id } = options;
  return Honkio().mainShop.userFetch(
    'adminapplist',
    {
      debug,
      query_all,
      query_merchant_list: merchant ? [merchant] : [],
      appid,
      id,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getServerAppList;
