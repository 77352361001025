import { useEffect, useState } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal, Button } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import getMerchantData from './../../api/utils/merchant/getMerchantData';
import getServerAppList from './../../api/utils/app/getServerAppList';

const MerchantModal = ({ merchantId, show, onHide }) => {
  const [merchant, setMerchant] = useState();
  const [progress, done] = useLoader([merchantId, merchant]);
  const [appList, setAppList] = useState([]);

  let isMounted = true;

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (merchantId) {
      getMerchantData({ id: merchantId })
        .then(({ merchant }) => merchant)
        .then(setMerchant);
    }
  }, [merchantId]);

  useEffect(() => {
    if (merchant?.application_id) {
      getServerAppList({ id: merchant?.application_id })
        .then(({ apps }) => apps, [])
        .then((data) => {
          if (isMounted) {
            setAppList(
              data.reduce(function (map, obj) {
                map[obj._id] = obj.name;
                return map;
              }, {})
            );
          }
        })
        .catch(console.error);
    }
  }, [merchant]);

  const container = (
    <div className="container">
      <div className="row">
        <div className="col-3">
          <img
            className="d-inline-block img-fluid m-2 p-1 shadow rounded"
            src={
              merchant?.settings?.str_logo ? merchant?.settings?.str_logo : ''
            }
            alt="Merchant photo"
            style={{ maxHeight: '100px' }}
          />
        </div>
        <div className="col-8">
          <label className="font-weight-bold mr-2">Application</label>
          <label>{appList[merchant?.application_id]}</label>
          <br />
          <label className="font-weight-bold mr-2">Name</label>
          <label>{merchant?.settings?.str_name}</label>
          <br />
          <input
            onclick="return false"
            type="checkbox"
            checked={merchant?.active}
          />
          <label className="font-weight-bold mr-3">active</label>
          <br />
          <label className="font-weight-bold mr-3">Business ID</label>
          <label>{merchant?.settings?.str_businessid}</label>
          <br />
          <label className="font-weight-bold mr-3">VAT ID</label>
          <label>{merchant?.settings?.str_vatnumber}</label>
          <br />
          <input
            onclick="return false"
            type="checkbox"
            checked={merchant?.is_default}
          />
          <label className="font-weight-bold mr-3">is default</label>
          <br />
          <label className="font-weight-bold mr-3">Phone number</label>
          <label>{merchant?.settings?.str_telephone}</label>
          <br />
          <label className="font-weight-bold mr-3">Email</label>
          <label>{merchant?.settings?.str_email}</label>
          <hr />
          <label className="font-weight-bold mr-3">Address</label>
          <label>{`${merchant?.settings?.str_country} ${merchant?.settings?.str_city} ${merchant?.settings?.str_address1}  ${merchant?.settings?.str_address2} `}</label>
        </div>
      </div>
    </div>
  );

  const onClickHide = () => {
    onHide();
  };

  return done ? (
    <Modal show={show} onHide={onClickHide}>
      <Modal.Header closeButton>
        <Modal.Title>{merchant?.settings?.str_name || 'Merchant'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{merchant ? container : 'not found'}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClickHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default MerchantModal;
