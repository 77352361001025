import { useEffect, useState } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal, Button, Col, Image } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import getProduct from './../../api/utils/product/getProduct';

const ProductModal = ({ productId, show, onHide }) => {
  const [product, setProduct] = useState({ info: null, types: [] });
  const [progress, done] = useLoader([product]);

  useEffect(() => {
    if (productId) {
      getProduct({ id: productId })
        .then(({ product, types }) => ({ info: product, types }))
        .then(setProduct);
    }
  }, [productId]);

  const container = (
    <div className="container">
      <div className="col-8">
        <label className="font-weight-bold mr-2">Name</label>
        <label>{product?.info?.settings?.str_name}</label>
        <br />
        <label className="font-weight-bold mr-3">Description</label>
        <label>{product?.info?.settings?.str_description}</label>
        <br />
        <input
          onclick="return false"
          type="checkbox"
          checked={product?.info?.settings?.bool_visible}
        />
        <label className="font-weight-bold mr-3">Active</label>
        <br />
        <label className="font-weight-bold mr-3">Amount</label>
        <label>{product?.info?.settings?.amount}</label>
        <br />
        <label className="font-weight-bold mr-3">Amount VAT</label>
        <label>{product?.info?.settings?.amount_vat}</label>
        <br />
        <label className="font-weight-bold mr-3">Type</label>
        <label>
          {product?.types
            ? product?.types.find(
                (e) => e._id === product.info?.settings?.id_type
              )?.name
            : ''}
        </label>
        <br />
        <label className="font-weight-bold mr-3">EAN</label>
        <label>{product?.info?.settings?.str_ean}</label>
        <br />
        <label className="font-weight-bold mr-3">Bonus amount</label>
        <label>{product?.info?.settings?.int_bonus_amount}</label>
        <br />
        <label className="font-weight-bold mr-3">Bonus value</label>
        <label>{product?.info?.settings?.int_bonus_value}</label>
        <br />
        <label className="font-weight-bold mr-3">Bonus type</label>
        <label>{product?.info?.settings?.int_bonus_type}</label>
        <br />
        <label className="font-weight-bold mr-3">Unit type</label>
        <label>{product?.info?.settings?.str_unit_type}</label>
        <br />
        <label className="font-weight-bold mr-3">Vendor code</label>
        <label>{product?.info?.settings?.str_vendorcode}</label>
        <br />
        <label className="font-weight-bold mr-3">Notes</label>
        <label>{product?.info?.settings?.str_notes}</label>
        <br />
      </div>
    </div>
  );

  const onClickHide = () => {
    onHide();
  };

  return done ? (
    <Modal show={show} onHide={onClickHide}>
      <Modal.Header closeButton>
        <Modal.Title>Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>{product ? container : 'not found'}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClickHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default ProductModal;
