import { useEffect, useState } from 'react';
import {
    getNotifyMessage,
    updateNotifyMessage,
} from '../../../api/utils/merchant/notifymessage/notifyMessages';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';

import languages from '../../../__fixtures__/languages';
import variables from '../../../__fixtures__/variables';
import NotifyMessageEditForm from './NotifyMessageEditForm';
import BackButton from '../../UI/BackButton';

const LANGUAGES = [{ value: '', name: 'Default' }, ...languages];

const NotifyMessageEdit = () => {
    let { notifyMessageID } = useParams();
    const history = useHistory();
    const [language, setLanguage] = useState('');
    const [notifyMessage, setNotifyMessage] = useState({});

    const onSubmit = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Saved');
                history.goBack();
            } else {
                NotificationManager.error('Error');
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        const submitData = { ...notifyMessage, ...data };
        submitData.id = submitData._id;
        delete submitData._id;

        updateNotifyMessage(submitData, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    useEffect(() => {
        getNotifyMessage({ notifyMessageID }).then(({ notify_message }) =>
            setNotifyMessage(notify_message)
        );
    }, [notifyMessageID]);

    if (!notifyMessage._id) {
        return <div></div>;
    }

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton
                    to="/messages"
                    text="Back to list of message templates"
                />{' '}
                Message Editor (ID#{notifyMessageID})
            </h2>
            <NotifyMessageEditForm
                languages={LANGUAGES}
                onSubmitForm={onSubmit}
                language={language}
                setLanguage={setLanguage}
                notifyMessage={notifyMessage}
                setNotifyMessage={setNotifyMessage}
                variables={variables}
            />
        </div>
    );
};

export default NotifyMessageEdit;
