import { useFormContext } from 'react-hook-form';

const UploadFileWithPreview = ({ name, className, type, error, disabled }) => {
  const { register, watch } = useFormContext();
  return (
    <fieldset className={className} disabled={disabled}>
      <div className="card p-2 h-100">
        <div className="border mb-2 p-3 bg-light text-info h-100">
          {/* <img className="img-fluid" src={watch(name)?.trim()} alt={name} /> */}
          <img
            className="w-100 h-100"
            style={{ objectFit: 'contain' }}
            src={watch(name)?.trim()}
            alt={name}
          />
        </div>
        <div className="input-group">
          <input
            ref={register}
            id={name}
            name={name}
            type={type}
            className="form-control"
          />
          {error && (
            <div className="invalid-feedback">Error message: $message</div>
          )}
        </div>
      </div>
    </fieldset>
  );
};

export default UploadFileWithPreview;
