import React, { useState } from 'react';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import JobForm from './JobForm';
import { createSchedulerJob } from '../../../api/utils/scheduler/setJob';

const CreateJob = () => {
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const handleSubmitForm = async (data) => {
        let dt = _.cloneDeep(data);

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description);
        };

        console.log('handleSubmitForm: data ==', data);
        // return;

        setLoading(true);
        const res = await createSchedulerJob(dt, errorHandler);

        setLoading(false);
        if (res && res?.status === 'accept') {
            NotificationManager.success('Job created');
        }

        if (res?.job?._id) {
            NotificationManager.info('Redirecting...');
            setTimeout(() => {
                history.push(`/scheduler/job/edit?job_id=${res.job?._id}`);
            }, 1500);
        }
    };

    return <JobForm onSubmit={handleSubmitForm} submitLoading={loading} />;
};

export default CreateJob;
