import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const UploadImage = ({ name, className, type, error, disabled, ...rest }) => {
  const { register, watch } = useFormContext();
  const [currentValue, setCurrentValue] = useState(null);

  useEffect(() => {
    console.log('file input name == ', name);
    console.log('file input rest == ', rest);
  }, [name, rest]);

  useEffect(() => {
    console.log('currentValue ==', currentValue);
  }, [currentValue]);

  const handleSetPreview = (e) => {
    setCurrentValue(e.target.files?.[0]);
  };

  return (
    <fieldset className={className} disabled={disabled}>
      <div className="card p-2 h-100">
        <div className="border mb-2 p-3 bg-light text-info h-100">
          {/* <img className="img-fluid" src={watch(name)?.trim()} alt={name} /> */}
          <img
            className="w-100 h-100"
            style={{ objectFit: 'contain' }}
            // src={watch(name)?.trim()}
            // src={currentValue}
            src={currentValue ? URL.createObjectURL(currentValue) : ''}
            alt={name}
          />
        </div>
        <div className="input-group">
          <input
            ref={register}
            id={name}
            name={name}
            type="file"
            className="form-control"
            onChange={handleSetPreview}
          />
          {error && (
            <div className="invalid-feedback">Error message: $message</div>
          )}
        </div>
      </div>
    </fieldset>
  );
};

export default UploadImage;
