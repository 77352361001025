import Honkio from '../../Honkio';

const sendMessage = (options, errorHandler = {}) => {
  const { room, text, sender_id, receiver_id, application, merchant } = options;
  return Honkio().mainShop.userFetch(
    'chatmessageset',
    {
      room,
      text,
      sender_id,
      receiver_id,
      application,
      merchant,
      version: 3,
      action: 'create',
    },
    errorHandler
  );
};

export default sendMessage;
