import { useEffect, useState } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal, Button, Col, Image } from 'react-bootstrap';
import getAdminVelocityRule from './../../api/utils/velocityRule/getAdminVelocityRule';
import useLoader from '../UI/helpers/useLoader';
import { getMerchantOrderProcessList } from '../../api/utils/orderModel/getMerchantOrderProcessList';

const VelocityRuleModal = ({ velocityRuleId, show, onHide }) => {
  const [velocityRule, setVelocityRule] = useState();
  const [progress, done] = useLoader([velocityRuleId, velocityRule]);
  const [orderModels, setOrderModels] = useState([]);
  const [orderModelNames, setOrderModelNames] = useState([]);

  useEffect(() => {
    if (velocityRuleId) {
      getAdminVelocityRule({ id: velocityRuleId })
        .then(({ velocity_rule }) => velocity_rule)
        .then(setVelocityRule);
    }
  }, [velocityRuleId]);

  useEffect(() => {
    if (velocityRule && orderModels) {
      if (velocityRule?.order_types && velocityRule.order_types.length > 0) {
        setOrderModelNames(
          velocityRule.order_types
            .map((model_id) => orderModels.find((e) => e.id === model_id)?.name)
            .join(', ')
        );
      } else {
        setOrderModelNames('-');
      }
    }
  }, [velocityRule]);

  useEffect(() => {
    getMerchantOrderProcessList()
      .then((data) => {
        console.log(data);
        return data;
      })
      .then(({ orderprocesses }) => orderprocesses)
      .then(setOrderModels)
      .catch(console.error);
  }, []);

  const container = (
    <div className="container">
      <div className="col-8">
        <label className="font-weight-bold mr-2">Name</label>
        <label>{velocityRule?.name}</label>
        <br />
        <label className="font-weight-bold mr-3">Description</label>
        <label>{velocityRule?.description}</label>
        <br />
        <label className="font-weight-bold mr-2">Order sum limit</label>
        <label>{velocityRule?.order_sum_limit}</label>
        <br />
        <br />
        Action
        <br />
        <input
          onclick="return false"
          type="checkbox"
          checked={velocityRule?.action.abort}
        />
        <label className="font-weight-bold mr-3">Abort</label>
        <input
          onclick="return false"
          type="checkbox"
          checked={velocityRule?.action.notify_aml}
        />
        <label className="font-weight-bold">Notify aml</label>
        <br />
        <label className="font-weight-bold mr-2">Change risk rating</label>
        <label>{velocityRule?.action.change_risk_rating}</label>
        <br />
        <br />
        Sum by period limit
        <br />
        <label className="font-weight-bold mr-3">Period (days)</label>
        <label className="mr-3">
          {velocityRule?.sum_by_period_limit.period}
        </label>
        <label className="font-weight-bold mr-3">Sum</label>
        <label>{velocityRule?.sum_by_period_limit.sum}</label>
        <br />
        <br />
        Usage limit
        <br />
        <label className="font-weight-bold mr-3">Period (days)</label>
        <label className="mr-3">{velocityRule?.usage_limit.period}</label>
        <label className="font-weight-bold mr-3">Amount</label>
        <label>{velocityRule?.usage_limit.amount}</label>
        <br />
        Order types
        <br />
        {orderModelNames && orderModelNames.length > 0 ? orderModelNames : ''}
      </div>
    </div>
  );

  const onClickHide = () => {
    onHide();
  };

  return done ? (
    <Modal show={show} onHide={onClickHide}>
      <Modal.Header closeButton>
        <Modal.Title>Velocity Rule</Modal.Title>
      </Modal.Header>
      <Modal.Body>{velocityRule ? container : 'not found'}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClickHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default VelocityRuleModal;
