import React, { useState } from 'react';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import _ from 'lodash';
import BackButton from '../../UI/BackButton';
import useSearchString from '../../../utils/useSearchString';
import { updateNotifymessageTemplate } from '../../../api/utils/templateModels/templateNotifymessages/setNotifymessageTemplate';
import TemplateNotifymessageForm from './TemplateNotifymessageForm';

const TemplateNotifymessageEdit = () => {
    const { id } = useSearchString();

    const [submitLoading, setSubmitLoading] = useState(false);

    const handleSubmitForm = async (submitData) => {
        console.log('submitData == ', submitData);
        const resultHandler = (data) => {
            setSubmitLoading(false);
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Updated successfully',
                    'Success',
                    3000
                );
            }
        };

        const errorHandler = (error) => {
            setSubmitLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setSubmitLoading(true);
            const res = await updateNotifymessageTemplate(
                { ...submitData, id },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton
                    to="/template-models/notifymessages"
                    text="Back to list"
                />
                Edit notifymessage template
            </h2>

            <TemplateNotifymessageForm
                id={id}
                onSubmit={handleSubmitForm}
                submitLoading={submitLoading}
            />
        </div>
    );
};

export default TemplateNotifymessageEdit;
