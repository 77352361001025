import Honkio from '../../../Honkio';

const setMerchantRegAppSettings = (options = {}, errorHandler = {}) => {
  return Honkio().mainShop.userFetch(
    'adminsettingsmerchantregisterappset',
    { ...options, action: 'update', version: 3 },
    errorHandler
  );
};

export default setMerchantRegAppSettings;
