import Honkio from '../../../Honkio';

const setAssetStructure = (options = {}, errorHandler = () => {}) => {
  return Honkio().mainShop.userFetch(
    'assetstructuresset',
    {
      ...options,
      version: 3,
    },
    errorHandler
  );
};

export const createAssetStructure = (options = {}, errorHandler = () => {}) => {
  return setAssetStructure({ ...options, action: 'create' }, errorHandler);
};

export const updateAssetStructure = (options = {}, errorHandler = () => {}) => {
  return setAssetStructure({ ...options, action: 'update' }, errorHandler);
};

export const deleteAssetStructure = ({ id }, errorHandler = () => {}) => {
  return setAssetStructure({ id, action: 'delete' }, errorHandler);
};
