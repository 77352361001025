import Honkio from '../../../Honkio';

const setRoleTemplate = (options = {}, errorHandler = () => {}) => {
    const {
        id,
        name,
        merchant,
        application,
        active,
        object_access,
        display_name,
        description,
        display_name__fi,
        display_name__sv,
        display_name__en,
        display_name__no,
        display_name__da,
        description__fi,
        description__sv,
        description__en,
        description__no,
        description__da,
        action,
    } = options;
    return Honkio().mainShop.userFetch(
        'admintemplatemodelsroleset',
        {
            id,
            name,
            merchant,
            application,
            active,
            object_access,
            display_name,
            description,
            display_name__fi,
            display_name__sv,
            display_name__en,
            display_name__no,
            display_name__da,
            description__fi,
            description__sv,
            description__en,
            description__no,
            description__da,
            action,
            version: 3,
        },
        errorHandler,
        true
    );
};

export const createRoleTemplate = (options = {}, errorHandler = () => {}) => {
    return setRoleTemplate(
        {
            ...options,
            action: 'create',
        },
        errorHandler
    );
};

export const updateRoleTemplate = (options = {}, errorHandler = () => {}) => {
    return setRoleTemplate(
        {
            ...options,
            action: 'update',
        },
        errorHandler
    );
};

export const deleteRoleTemplate = (options = {}, errorHandler = () => {}) => {
    return setRoleTemplate(
        {
            ...options,
            action: 'delete',
        },
        errorHandler
    );
};
