import Honkio from '../../../Honkio';

const setNotifymessageTemplate = (options = {}, errorHandler = () => {}) => {
    const {
        id,
        name,
        merchant,
        application,
        active,
        message,
        subject,
        subject__fi,
        subject__sv,
        subject__en,
        subject__no,
        subject__da,
        message__fi,
        message__sv,
        message__en,
        message__no,
        message__da,
        action,
    } = options;
    return Honkio().mainShop.userFetch(
        'admintemplatemodelsnotifymessageset',
        {
            id,
            name,
            merchant,
            application,
            active,
            message,
            subject,
            subject__fi,
            subject__sv,
            subject__en,
            subject__no,
            subject__da,
            message__fi,
            message__sv,
            message__en,
            message__no,
            message__da,
            action,
            version: 3,
        },
        errorHandler,
        true
    );
};

export const createNotifymessageTemplate = (
    options = {},
    errorHandler = () => {}
) => {
    return setNotifymessageTemplate(
        {
            ...options,
            action: 'create',
        },
        errorHandler
    );
};

export const updateNotifymessageTemplate = (
    options = {},
    errorHandler = () => {}
) => {
    return setNotifymessageTemplate(
        {
            ...options,
            action: 'update',
        },
        errorHandler
    );
};

export const deleteNotifymessageTemplate = (
    options = {},
    errorHandler = () => {}
) => {
    return setNotifymessageTemplate(
        {
            ...options,
            action: 'delete',
        },
        errorHandler
    );
};
