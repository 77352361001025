import { useContext, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import getPermissions from '../../api/utils/settings/servicekey/getPermissions';
import createServiceKey from '../../api/utils/settings/servicekey/createServiceKey';
import _ from 'lodash';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import UserInfoContext from '../../contexts/UserInfoContext';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';

const ServiceKeys = () => {
  const [permissions, setPermissions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [key, setKey] = useState(null);

  const [progress, done] = useLoader([permissions]);
  const [loading, setLoading] = useState(false);

  const userInfo = useContext(UserInfoContext);
  const merchantId = userInfo.merchant ? userInfo.merchant.id : null;

  const columns = useMemo(
    () => [
      { name: '#', grow: 0, cell: (_, index) => index + 1 },
      { name: 'Name', grow: 1, cell: (row) => row },
    ],
    []
  );

  const onRowSelect = (state) => {
    if (state.selectedCount === selectedRows?.length) return;
    setSelectedRows(state.selectedRows);
  };

  const onCreate = async () => {
    setLoading(true);

    const resultHandler = (data) => {
      setKey(data?.key?.key);
      setLoading(false);
      NotificationManager.success('Service key created');
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setKey(null);
      setLoading(false);
    };

    const res = await createServiceKey(
      {
        query_access: selectedRows,
        merchant: merchantId,
        name: 'Service key 101',
      },
      errorHandler
    );

    if (res && res.status === 'accept') {
      resultHandler(res);
    }
  };

  const fetchPermissions = async () => {
    const resultHandler = (data) => {
      setPermissions(data?.permissions);
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setPermissions(null);
    };

    const res = await getPermissions({}, errorHandler);
    if (res && res.status === 'accept') return resultHandler(res);
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  const handleCopyToClipboard = (val) => {
    navigator.clipboard.writeText(val);
    NotificationManager.success('Copied to clipboard!');
  };

  return done ? (
    <div className="container p-3">
      <NotificationContainer />
      <DataTable
        dense
        striped
        highlightOnHover
        data={permissions}
        columns={columns}
        selectableRows={true}
        onSelectedRowsChange={onRowSelect}
        selectableRowSelected={(row) => selectedRows.includes(row)}
      />
      {key && (
        <div
          className="alert alert-primary text-break my-3 d-flex"
          role="alert"
        >
          <span>{key}</span>
          <button
            onClick={() => handleCopyToClipboard(key)}
            className="btn btn-link btn-sm justify-self-end align-self-start padding-0"
            data-tip
            data-for="copyTip"
          >
            <Icon iconName="bi-clipboard" size="1" />
            <ReactTooltip id="copyTip" place="top" effect="solid">
              Copy to clipboard
            </ReactTooltip>
          </button>
        </div>
      )}
      <button
        className="btn btn-primary btn-block mt-3"
        disabled={loading}
        onClick={onCreate}
      >
        Create
      </button>
    </div>
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default ServiceKeys;
