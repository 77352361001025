import React from 'react';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SmartForm from '../Form/SmartForm';

const SettingsInfoForm = ({ settings, onSubmit, disableSubmit }) => {
  const handleSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <div>
      <SmartForm defaultValues={settings} onSubmit={handleSubmit}>
        <InputFieldWithValidation
          className="col-12 mb-3"
          name="customer_funds_account"
          label="Customer funds account amount"
          type="number"
          required="true"
          step="5.00"
          min="0.00"
        />
        <h4>Greylist limits</h4>
        <InputFieldWithValidation
          className="col-12 mb-3"
          name="greylist_initial"
          label="Greylist initial score"
          type="number"
          required="true"
          step="1"
          min="0"
          additional="Initial score added to each payment"
        />
        <InputFieldWithValidation
          className="col-12 mb-3"
          name="greylist_min"
          label="Greylist minimum score limit"
          type="number"
          required="true"
          step="1"
          min="0"
          additional="If score is less than this value - auto-accept payment"
        />
        <InputFieldWithValidation
          className="col-12 mb-3"
          name="greylist_max"
          label="Greylist maximum score limit"
          type="number"
          required="true"
          step="1"
          min="0"
          additional="If score is more than this value - auto-reject payment"
        />
        <div className="form-row row-cols-1">
          <div className="col pl-2">
            <button
              className="btn btn-primary btn-block btn-lg"
              disabled={disableSubmit}
            >
              Save changes
            </button>
          </div>
        </div>
      </SmartForm>
    </div>
  );
};

export default SettingsInfoForm;
