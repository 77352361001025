import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import AddressGreyListForm from './GreyListCreation/AddressGreyListForm';
import CoordinatesGreyListForm from './GreyListCreation/CoordinatesGreyListForm';
import EmailGreyListForm from './GreyListCreation/EmailGreyListForm';
import NameGreyListForm from './GreyListCreation/NameGreyListForm';
import NetworkGreyListForm from './GreyListCreation/NetworkGreyListForm';

const GreylistEditModal = ({
  item,
  show,
  onHide = () => {},
  onSubmit = () => {},
}) => {
  const [itemType, setItemType] = useState('email');

  const handleSubmit = (data) => {
    console.log('data ==', data);
    data.id = item.id;
    onSubmit(data);
    onHide();
  };

  useEffect(() => {
    console.log('item ==', item);
    if (item?.email) setItemType('email');
    if (item?.latitude) setItemType('coordinates');
    if (item?.address) setItemType('address');
    if (item?.network) setItemType('network');
    if (item?.firstname && item?.lastname) setItemType('name');
  }, [item]);

  const GreyListForm = ({}) => {
    switch (itemType) {
      case 'email':
        return (
          <EmailGreyListForm
            greyList={item}
            onSave={handleSubmit}
            btnLabel="Update"
          />
        );
      case 'coordinates':
        return (
          <CoordinatesGreyListForm
            greyList={item}
            onSave={handleSubmit}
            btnLabel="Update"
          />
        );
      case 'address':
        return (
          <AddressGreyListForm
            greyList={item}
            onSave={handleSubmit}
            btnLabel="Update"
          />
        );
      case 'network':
        return (
          <NetworkGreyListForm
            greyList={item}
            onSave={handleSubmit}
            btnLabel="Update"
          />
        );
      case 'name':
        return (
          <NameGreyListForm
            greyList={item}
            onSave={handleSubmit}
            btnLabel="Update"
          />
        );
      default:
        return <label>Invalid grey list column type</label>;
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit greylist item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GreyListForm />
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default GreylistEditModal;
