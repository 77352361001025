import { useEffect, useState, useMemo } from 'react';
import LoadingBar from '../UI/LoadingBar';
import ReactTooltip from 'react-tooltip';
import { Modal, Button, Col, Image, Table } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import { getAdminOrder } from './../../api/utils/order/getOrders';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import moment from 'moment';
import renderCurrency from '../../utils/renderCurrency';
import { Link } from 'react-router-dom';
import Icon from '../UI/Icon';
import freezeOrder from '../../api/utils/order/freeze';
import _ from 'lodash';

const DATE_FORMAT = 'MMMM Do, YYYY, HH:mm';

const OrderModal = ({ orderId, show, onHide }) => {
    const [order, setOrder] = useState({});
    const [progress, done] = useLoader([orderId, order]);

    const [loading, setLoading] = useState(false);

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                if (isMounted) {
                    setOrder(data.order);
                }
            } else {
                setOrder({});
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setOrder({});
        };

        if (orderId) {
            getAdminOrder({ id: orderId }, errorHandler).then((data) => {
                return resultHandler(data);
            });
        }
    }, [orderId]);

    const container = (
        <div className="container">
            <div className="col-8">
                <label className="multiline">{order}</label>
            </div>
        </div>
    );

    const onClickHide = () => {
        onHide();
    };

    const renderDate = (date_str) => {
        const moment_date = moment(date_str);
        if (moment_date.isValid()) return moment_date.format(DATE_FORMAT);
        return '';
    };

    const renderOrder = (order) => {
        if (!order) return null;
        return (
            <Table
                striped
                bordered
                hover
                style={{
                    tableLayout: 'fixed',
                }}
            >
                <tbody>
                    <tr>
                        <td>ID</td>
                        <td style={{ wordWrap: 'break-word' }}>{order._id}</td>
                    </tr>
                    <tr>
                        <td>Amount</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {renderCurrency(order.currency)} {order.amount}
                        </td>
                    </tr>
                    <tr>
                        <td>Created</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {renderDate(order.creation_date)}
                        </td>
                    </tr>
                    <tr>
                        <td>Completed</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {renderDate(order.completion_date)}
                        </td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.consumer?.email}
                        </td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.custom_fields?.user_phone}
                        </td>
                    </tr>
                    <tr>
                        <td>First name</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.custom_fields?.user_firstname}
                        </td>
                    </tr>
                    <tr>
                        <td>Last name</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.custom_fields?.user_lastname}
                        </td>
                    </tr>
                    <tr>
                        <td>City</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.custom_fields?.city}
                        </td>
                    </tr>
                    <tr>
                        <td>Country</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.custom_fields?.country}
                        </td>
                    </tr>
                    <tr>
                        <td>Street</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.custom_fields?.street}
                        </td>
                    </tr>
                    <tr>
                        <td>Zip code</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.custom_fields?.zip_code}
                        </td>
                    </tr>
                    <tr>
                        <td>Invoice</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.invoice_status}
                        </td>
                    </tr>
                    <tr>
                        <td>Bank</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.consumer?.bank_name}
                        </td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            {order?.description}
                        </td>
                    </tr>
                    <tr>
                        <td>Parent order</td>
                        <td style={{ wordWrap: 'break-word' }}>
                            <Link
                                to={`/orders/view?order=${order?.parent_order}`}
                            >
                                {order?.parent_order}
                            </Link>
                        </td>
                    </tr>
                    {order?.custom_fields?.photos ? (
                        <tr>
                            <td>Photos</td>
                            <td>
                                {order?.custom_fields?.photos.map((item) => (
                                    <img
                                        src={item.src}
                                        alt={item.id}
                                        className="orderModalPhoto"
                                    />
                                ))}
                            </td>
                        </tr>
                    ) : null}
                </tbody>
            </Table>
        );
    };

    const setFrozen = async (isFrozen = false) => {
        console.log('setting is_frozen to ', isFrozen);

        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setOrder((prev) => {
                    const udpatedOrder = _.cloneDeep(prev);
                    udpatedOrder.is_frozen = data.is_frozen;
                    return udpatedOrder;
                });
                NotificationManager.success(
                    `Order is ${
                        data.is_frozen ? 'frozen' : 'unfrozen'
                    } successfully`
                );
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setLoading(true);
            const res = await freezeOrder(
                { id: order._id, is_frozen: isFrozen },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            console.log('Error freezing/unfreezing order: ', error);
        }
    };

    const renderFrozenState = (is_frozen = false) => {
        return is_frozen ? (
            <Button
                size="sm"
                variant="link"
                className="mr-1"
                data-tip
                data-for="frozenTip"
                onClick={(e) => setFrozen(false)}
            >
                <Icon iconName="bi-lock" color="black" />
                <ReactTooltip id="frozenTip" place="bottom" effect="solid">
                    Order is frozen. Click to unfreeze
                </ReactTooltip>
            </Button>
        ) : (
            <Button
                size="sm"
                variant="link"
                className="mr-1"
                data-tip
                data-for="frozenTip"
                onClick={(e) => setFrozen(true)}
            >
                <Icon iconName="bi-unlock" color="black" />
                <ReactTooltip id="frozenTip" place="bottom" effect="solid">
                    Order is not frozen. Click to freeze
                </ReactTooltip>
            </Button>
        );
    };

    return done ? (
        <Modal show={show} onHide={onClickHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {renderFrozenState(order?.is_frozen)}
                    Order {order?._id}{' '}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderOrder(order)}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    ) : (
        <LoadingBar progress={progress} />
    );
};

export default OrderModal;
