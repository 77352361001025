import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import AnySelector from '../UI/AnySelector';
import languages from '../../__fixtures__/languages';
import SmartForm from '../Form/SmartForm';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';

const MessageEditorForm = ({
  merchantsList,
  templatesList,
  templateInfo,
  editorData,
  setSelectedMerchant,
  setSelectedTemplate,
  setEditorData,
  onSubmit,
  selectors,
}) => {
  return (
    <div>
      <h2>Message Editor</h2>
      {selectors && (
        <AnySelector
          label="Select Merchant"
          items={merchantsList}
          valueSelector="id"
          nameSelector="name"
          setItem={setSelectedMerchant}
        />
      )}
      {selectors && (
        <AnySelector
          label="Select Template"
          items={templatesList}
          valueSelector="_id"
          nameSelector="name"
          setItem={setSelectedTemplate}
        />
      )}
      {selectors && (
        <AnySelector
          label="Select Language"
          items={languages}
          valueSelector="value"
          nameSelector="name"
        />
      )}
      <SmartForm onSubmit={onSubmit} defaultValues={templateInfo}>
        <InputFieldWithValidation label="Name" name="name" />
        <InputFieldWithValidation label="Subject" name="subject" />
        <div className="my-3">
          <CKEditor
            editor={ClassicEditor}
            data={editorData}
            onChange={(_, editor) => {
              setEditorData(editor.getData());
            }}
          />
        </div>
        <div className="my-3">
          <AnySelector label="Select Variable" />
        </div>
        <button className="btn btn-primary">Save</button>
      </SmartForm>
    </div>
  );
};

export default MessageEditorForm;
