import Honkio from '../../Honkio';

const getOrders = (options, errorHandler = {}) => {
    const {
        page = 0,
        query_count = 0,
        query_merchant,
        query_application,
        query_type,
        query_statuses,
        query_start_date,
        query_end_date,
        query_parent,
        query_search,
    } = options;
    return Honkio().mainShop.userFetch(
        'adminorderlist',
        {
            query_skip: +page * query_count,
            query_count,
            query_merchant,
            query_application,
            query_statuses,
            query_type,
            query_statuses,
            query_start_date,
            query_end_date,
            query_parent,
            query_search,
            action: 'read',
            version: 3,
        },
        errorHandler
    );
};

const getOrder = (options, errorHandler = {}) => {
    const { id } = options;
    return Honkio().mainShop.userFetch(
        'orderget',
        {
            id: id,
            action: 'read',
            version: 3,
        },
        errorHandler
    );
};

const getAdminOrder = (options, errorHandler = {}) => {
    const { id } = options;
    return Honkio().mainShop.userFetch(
        'adminorderget',
        {
            id: id,
            action: 'read',
            version: 3,
        },
        errorHandler
    );
};

export { getOrders, getOrder, getAdminOrder };
