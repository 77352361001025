const getFormDefaultValues = ({ data: { label, options, settings } }) => ({
  label: label,
  edit_amount: options.edit_amount,
  edit_products: options.edit_products,
  //send_push: options.send_push,
  reserve: options.reserve?.length ? true : false,
  sales_point: options.sales_point?.length ? true : false,
  public: options.public?.length ? true : false,
  capture: settings.capture,
  auth: settings.auth,
  chargeback: settings.chargeback,
});

export default getFormDefaultValues;
