import React from 'react';
import { Modal, Button, Table, Col } from 'react-bootstrap';

const AccessRuleDocModal = ({ show, onHide }) => {
  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Access rule documentation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped hover>
          <tbody>
            <tr>
              <td>0.</td>
              <td>
                Try to keep Access rules unambiguous and prefere one Access rule
                with multiple conditions over multiple Access rules with few
                conditions
              </td>
            </tr>
            <tr>
              <td>1.</td>
              <td>At least one condition must be set for each Access rule</td>
            </tr>
            <tr>
              <td>2.</td>
              <td>`is_global` flag and `roles` field are mutually exclusive</td>
            </tr>
            <tr>
              <td>4.</td>
              <td>
                `is_global` flag makes Access rule be applied when there are no
                role-specific Access rules for current `role`
              </td>
            </tr>
            <tr>
              <td>5.</td>
              <td>
                <Table>
                  <tr>
                    <td colSpan={2}>
                      <span className="font-weight-bold">
                        Allowed operators for types
                      </span>{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>'str', 'bool', 'object_id'</td>
                    <td>'eq', 'ne', 'exists'</td>
                  </tr>
                  <tr>
                    <td>'int', 'float', 'date'</td>
                    <td>
                      'gt', 'gte', 'lte', 'lt', 'eq', 'ne', 'exists', 'in'
                    </td>
                  </tr>
                  <tr>
                    <td>'list[object_id]', 'list'</td>
                    <td>'in'</td>
                  </tr>
                </Table>
              </td>
            </tr>
            <tr>
              <td>6.</td>
              <td>`is_active` flag adds Access rule to ABAC access test</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccessRuleDocModal;
