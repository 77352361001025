import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Icon from '../UI/Icon';
import Wait from '../UI/Wait';
import ReactTooltip from 'react-tooltip';

const ScriptList = ({
  data,
  loading,
  rowsCountPerPage,
  handleRowsPerPage,
  perPageChoices,
  totalRows,
  handlePageChange,
  onDeleteClick,
}) => {
  const columns = useMemo(
    () => [
      { name: 'ID', grow: 2, cell: ({ _id }) => _id },
      { name: 'Name', grow: 1, cell: ({ name }) => name },
      { name: 'Type', grow: 1, cell: ({ type }) => type },
      {
        name: 'Active',
        grow: 0,
        cell: ({ active }) => (active ? 'Yes' : 'No'),
      },
      { name: 'Debug', grow: 0, cell: ({ debug }) => (debug ? 'Yes' : 'No') },
      {
        name: 'Actions',
        grow: 1,
        cell: ({ _id }) => (
          <div>
            <Link
              to={`/scripts/edit?script=${_id}`}
              data-tip
              data-for="editTip"
            >
              <Icon iconName="bi-pencil" />
              <ReactTooltip id="editTip" place="top" effect="solid">
                Edit
              </ReactTooltip>
            </Link>
            <span
              data-tip
              data-for="deleteTip"
              onClick={() => {
                onDeleteClick(_id);
              }}
            >
              <Icon iconName="bi bi-trash" />
              <ReactTooltip id="deleteTip" place="top" effect="solid">
                Delete
              </ReactTooltip>
            </span>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <DataTable
      dense
      striped
      highlightOnHover
      data={data}
      columns={columns}
      pagination={true}
      paginationPerPage={rowsCountPerPage}
      paginationRowsPerPageOptions={perPageChoices}
      onChangeRowsPerPage={handleRowsPerPage}
      paginationServer
      progressPending={loading}
      onChangePage={handlePageChange}
      paginationTotalRows={totalRows}
      progressComponent={
        <div className="p-5">
          <Wait />
        </div>
      }
    />
  );
};

export default ScriptList;
