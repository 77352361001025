import React, { useState } from 'react';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import _ from 'lodash';
import { updateUserRegSettings } from '../../api/utils/merchant/user/registration/setUserRegSettings';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSearchString from '../../utils/useSearchString';
import BackButton from '../UI/BackButton';
import UserRegSettingsForm from './UserRegSettingsForm';

const UserRegSettingsEdit = () => {
    const { id } = useSearchString();

    const history = useHistory();

    const [submitLoading, setSubmitLoading] = useState(false);

    const handleSubmitForm = async (submitData) => {
        const resultHandler = (data) => {
            setSubmitLoading(false);
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Updated successfully',
                    'Success',
                    3000
                );
                if (data?.user_reg_settings?._id)
                    history.push(`/merchant/user/registration-settings`);
            }
        };

        const errorHandler = (error) => {
            setSubmitLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setSubmitLoading(true);
            const res = await updateUserRegSettings(submitData, errorHandler);
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton
                    to="/merchant/user/registration-settings"
                    text="Back to list"
                />
                Edit user registration settings
            </h2>

            <UserRegSettingsForm
                id={id}
                onSubmit={handleSubmitForm}
                submitLoading={submitLoading}
            />
        </div>
    );
};

export default UserRegSettingsEdit;
