import React, { useContext } from 'react';
import PushNotificationsConfigForm from './PushNotificationsConfigForm';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import UserInfoContext from '../../contexts/UserInfoContext';

const PushNotificationsConfig = () => {
    const {
        app: { id: appId, name },
    } = useContext(UserInfoContext);

    return (
        <div>
            <NotificationContainer />

            <h2>Application "{name}" push notifications settings</h2>

            <span className="text-muted">
                Backend settings for integration with push notifications service
                provider
            </span>

            <PushNotificationsConfigForm applicationId={appId} />
        </div>
    );
};

export default PushNotificationsConfig;
