import { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Col, Image } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';

const IssueModal = ({ order, show, onHide, onSubmit }) => {
  const [customerDesc, setCustomerDesc] = useState('');
  const [ownerDesc, setOwnerDesc] = useState('');

  const onClickHide = () => {
    setCustomerDesc('');
    setOwnerDesc('');
    onHide();
  };

  const handleSubmit = () => {
    onSubmit({
      order_id: order._id,
      customer_issue_description: customerDesc,
      owner_issue_description: ownerDesc,
    });

    onClickHide();
  };

  return (
    <Modal show={show} onHide={onClickHide}>
      <Modal.Header closeButton>
        <Modal.Title>Issue #{order?._id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Add issue descriptions</h5>
        <Form>
          <Form.Group className="mb-3" controlId="customer.desc">
            <Form.Label>Customer issue description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={customerDesc}
              onChange={(e) => setCustomerDesc(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="owner.desc">
            <Form.Label>Owner issue description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={ownerDesc}
              onChange={(e) => setOwnerDesc(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button variant="secondary" onClick={onClickHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IssueModal;
