import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import useSearchString from '../../utils/useSearchString';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import {
    ButtonActionForm,
    CheckboxSwitch,
    InputFieldWithValidation,
    SelectFieldWithValidation,
} from '../Form/elements/formElements';
import SmartForm from '../Form/SmartForm';
import { getConsumerPreviewsList } from '../../api/utils/consumer/getConsumerList';
import { getAssets } from '../../api/utils/asset/getAssets';
import UserInfoContext from '../../contexts/UserInfoContext';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { GOOGLE_API_KEY } from '../../config';
import { InputGroup, Form, Button } from 'react-bootstrap';
import Map from '../Map';
import EventAddressesModal from './EventAddressesModal';

const PER_PAGE = 100;

const BASE_GEO_CODE_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}`;

const EventEditForm = ({ defaultValues, onSubmit }) => {
    // Async users loading
    const [userList, setUserList] = useState([]);
    const [usersTotal, setUsersTotal] = useState(0);
    const [usersLoaded, setUsersLoaded] = useState(0);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [selectedOwner, setSelectedOwner] = useState();
    const [page, setPage] = useState(1);

    // Async assets loading
    const [assetList, setAssetList] = useState([]);
    const [assetsTotal, setAssetsTotal] = useState(0);
    const [assetsLoaded, setAssetsLoaded] = useState(0);
    const [loadingAssets, setLoadingAssets] = useState(false);
    const [selectedAssets, setSelectedAssets] = useState();
    const [assetsPage, setAssetsPage] = useState(1);

    // Dates
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    // Address search
    const [coordinatesInput, setCoordinatesInput] = useState([0.0, 0.0]);
    const [coordUpdated, setCoordUpdated] = useState(false);

    const [addressInput, setAddressInput] = useState('');
    const [addressUpdated, setAddressUpdated] = useState(false);

    const [addressList, setAddressList] = useState();
    const [showAddressListModal, setShowAddressListModal] = useState(false);
    const handleShowAddressListModal = () => setShowAddressListModal(true);
    const handleCloseAddressListModal = () => setShowAddressListModal(false);

    const { event: event_id, merchant, application } = useSearchString();

    const userInfo = useContext(UserInfoContext);
    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;
    const appId = application
        ? application
        : userInfo?.app?.id
        ? userInfo.app.id
        : null;

    useEffect(() => {
        console.log('defaultValues ==', defaultValues);
        if (!defaultValues) return;
        try {
            setStartDate(moment(defaultValues?.start_date).toDate());
            setEndDate(moment(defaultValues?.end_date).toDate());
            setSelectedOwner(defaultValues?.owner?.[0]);
            setSelectedAssets(defaultValues?.assets);
            setAddressInput(defaultValues?.address);
            setCoordinatesInput(defaultValues?.coordinates);
        } catch (error) {
            console.log('error loading prev info', error);
        }
    }, [defaultValues]);

    useEffect(() => {
        fetchUsers();
    }, [merchantId, appId, page, userInfo]);

    useEffect(() => {
        fetchAssets();
    }, [merchantId, appId, assetsPage]);

    const fetchUsers = async (search = '') => {
        setLoadingUsers(true);
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setUserList((prev) =>
                    _.uniqBy([...prev, ...data.consumers], (o) => o.id)
                );
                setUsersTotal(data?.total);
                if (usersLoaded < data?.total)
                    setUsersLoaded((prev) => {
                        return prev + data?.consumers?.length;
                    });
                setLoadingUsers(false);
                return data.consumers;
            } else {
                setUserList([]);
                setUsersTotal(0);
                setLoadingUsers(false);
                return [];
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setUserList([]);
            setUsersTotal(0);
            setLoadingUsers(false);
            return [];
        };

        if (!merchantId || !appId) return;

        const res = await getConsumerPreviewsList(
            {
                query_skip: (+page - 1) * PER_PAGE,
                query_count: PER_PAGE,
                query_merchant: merchantId,
                query_application: appId,
                search: search,
            },
            errorHandler
        );
        if (res && res.status === 'accept') {
            return resultHandler(res);
        } else {
            return [];
        }
    };

    const fetchAssets = async (search = '') => {
        setLoadingAssets(true);
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setAssetList((prev) =>
                    _.uniqBy([...prev, ...data.assets], (a) => a._id)
                );
                setAssetsTotal(data?.total);
                if (assetsLoaded < data?.total)
                    setAssetsLoaded((prev) => {
                        return prev + data?.assets?.length;
                    });
                setLoadingAssets(false);
                return data.assets;
            } else {
                setAssetList([]);
                setAssetsTotal(0);
                setLoadingAssets(false);
                return [];
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setAssetList([]);
            setAssetsTotal(0);
            setLoadingAssets(false);
        };

        if (!merchantId || !appId) return;

        const res = await getAssets(
            {
                query_skip: (+assetsPage - 1) * PER_PAGE,
                query_count: PER_PAGE,
                merchant: merchantId,
                appid: appId,
                search,
            },
            errorHandler
        );
        if (res && res?.status === 'accept') {
            return resultHandler(res);
        } else {
            return [];
        }
    };

    const handleSubmit = (data) => {
        data.owner = selectedOwner?.id || selectedOwner?._id;
        data.assets = selectedAssets.map((item) => item._id);
        data.start_date = moment(startDate).format('YYYY-MM-DD');
        data.end_date = moment(endDate).format('YYYY-MM-DD');
        data.merchant = defaultValues?.merchant || merchantId;
        data.application = defaultValues?.application || appId;
        data.coordinates = coordinatesInput;
        data.address = addressInput;
        onSubmit(data);
    };

    const handleScrollToBottomUsers = (e) => {
        if (usersLoaded < usersTotal) {
            console.log('loading more users...');
            setPage((prev) => prev + 1);
        }
    };

    const handleScrollToBottomAssets = (e) => {
        if (assetsLoaded < assetsTotal) {
            console.log('loading more assets...');
            setAssetsPage((prev) => prev + 1);
        }
    };

    const promiseOptionsUsers = async (inputValue) => {
        setUserList([]);
        setUsersLoaded(0);
        setUsersTotal(0);

        return await fetchUsers(inputValue);
    };

    const promiseOptionsAssets = async (inputValue) => {
        setAssetList([]);
        setAssetsLoaded(0);
        setAssetsTotal(0);

        return await fetchAssets(inputValue);
    };

    const handleAddressChanged = async (address) => {
        const adr = `${BASE_GEO_CODE_URL}&address="${address}"`;

        setAddressUpdated(false);

        await handleGoogleRequest(adr);
    };

    const handleCoordinatesChanged = async (newCoordinates = [0.0, 0.0]) => {
        setCoordUpdated(false);

        const adr = `${BASE_GEO_CODE_URL}&latlng=${newCoordinates[0]},${newCoordinates[1]}`;

        await handleGoogleRequest(adr);
    };

    const handleGoogleRequest = async (adr) => {
        const requestOptions = {
            method: 'POST',
        };
        const response = await fetch(adr, requestOptions);
        const result = await response.json();

        if (result.status === 'OK' && result.results.length > 0) {
            if (result.results.length > 1) {
                setAddressList(result.results);
                handleShowAddressListModal();
            } else {
                const coordinates = [
                    result.results[0].geometry.location.lat,
                    result.results[0].geometry.location.lng,
                ];
                const address = result.results[0].formatted_address;

                setAddressInput(address);
                setCoordinatesInput(coordinates);
            }
        } else {
            NotificationManager.warning(
                result.error_message || 'Geocode was not successful',
                'Geocode error',
                5000
            );
        }
    };

    const onAddressSelected = async (address) => {
        handleCloseAddressListModal();
        const coordinates = [
            address.geometry.location.lat,
            address.geometry.location.lng,
        ];
        const submitAddress = address.formatted_address;

        setAddressInput(submitAddress);
        setCoordinatesInput(coordinates);
    };

    return (
        <div>
            <NotificationContainer />

            <SmartForm onSubmit={handleSubmit} defaultValues={defaultValues}>
                <InputFieldWithValidation
                    className="mb-3"
                    name="name"
                    label="Name"
                    type="text"
                />

                <CheckboxSwitch
                    name="visible"
                    label="Visible"
                    className="mb-3"
                />

                <AsyncSelect
                    key="users_select"
                    placeholder="Event owner"
                    onMenuScrollToBottom={handleScrollToBottomUsers}
                    cacheOptions={userList}
                    defaultOptions={userList}
                    isLoading={loadingUsers}
                    value={selectedOwner}
                    onChange={(val) => {
                        setSelectedOwner(val);
                    }}
                    getOptionLabel={(option) => option.email}
                    getOptionValue={(option) => option.id}
                    isSearchable={true}
                    loadOptions={promiseOptionsUsers}
                    isClearable={true}
                />

                <AsyncSelect
                    className="mt-3"
                    key="assets_select"
                    placeholder="Event assets"
                    onMenuScrollToBottom={handleScrollToBottomAssets}
                    cacheOptions={assetList}
                    defaultOptions={assetList}
                    isLoading={loadingAssets}
                    value={selectedAssets}
                    defaultValue={defaultValues?.assets}
                    onChange={setSelectedAssets}
                    options={assetList}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option._id}
                    isSearchable
                    isMulti
                    loadOptions={promiseOptionsAssets}
                />

                <DatePicker
                    name="start_date"
                    showIcon
                    className="form-control mt-3"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                />

                <DatePicker
                    name="end_date"
                    showIcon
                    className="form-control mt-3"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                />

                <InputGroup className="my-3">
                    <InputGroup.Text>Search by address</InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="Address"
                        name="address"
                        value={addressInput}
                        onChange={(e) => {
                            setAddressInput(e.target.value);
                            setAddressUpdated(true);
                        }}
                    />

                    <Button
                        variant={
                            addressUpdated ? 'danger' : 'outline-secondary'
                        }
                        id="find-coordinates"
                        type="button"
                        onClick={(e) => handleAddressChanged(addressInput)}
                    >
                        Find
                    </Button>
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Text>Search by coordinates</InputGroup.Text>
                    <Form.Control
                        aria-label="Lattitude"
                        type="text"
                        placeholder="60.xxxx"
                        name="coordinates.0"
                        value={coordinatesInput?.[0]}
                        onChange={(e) => {
                            setCoordinatesInput((prev) => [
                                e.target.value,
                                prev?.[1],
                            ]);
                            setCoordUpdated(true);
                        }}
                    />
                    <Form.Control
                        aria-label="Longitude"
                        type="text"
                        placeholder="24.xxxx"
                        name="coordinates.1"
                        value={coordinatesInput?.[1]}
                        onChange={(e) => {
                            setCoordinatesInput((prev) => [
                                prev?.[0],
                                e.target.value,
                            ]);
                            setCoordUpdated(true);
                        }}
                    />
                    <Button
                        variant={coordUpdated ? 'danger' : 'outline-secondary'}
                        id="find-coordinates"
                        type="button"
                        onClick={(e) =>
                            handleCoordinatesChanged(coordinatesInput)
                        }
                    >
                        Find
                    </Button>
                </InputGroup>

                <div>
                    <h4>Location</h4>
                    {coordinatesInput ?? null ? (
                        <Map
                            latitude={coordinatesInput[0]}
                            longitude={coordinatesInput[1]}
                        />
                    ) : (
                        <div className="text-muted">'no location provided'</div>
                    )}
                </div>

                <ButtonActionForm
                    className="mt-3"
                    label="Save changes"
                    name="active"
                    value={true}
                    type="submit"
                />
            </SmartForm>

            <EventAddressesModal
                addressList={addressList}
                show={showAddressListModal}
                onAddressSelected={onAddressSelected}
                onHide={handleCloseAddressListModal}
            ></EventAddressesModal>
        </div>
    );
};

export default EventEditForm;
