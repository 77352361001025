import Honkio from '../../../../Honkio';

const getUserRegSettingsList = (options = {}, errorHandler = {}) => {
    const { query_merchant, query_skip = 0, query_count = 25 } = options;
    return Honkio().mainShop.userFetch(
        'adminmerchantuserregistrationsettingslist',
        {
            query_merchant,
            query_skip,
            query_count,
            version: 3,
            action: 'read',
        },
        errorHandler,
        true
    );
};

export default getUserRegSettingsList;
