const mapDataToGeoJSON = (transactionsData = []) => {
    return transactionsData.map((item) => {
        const { _id } = item;
        const {
            location: {
                coordinates: [lng, lat] = [0,0],
            },
        } = item;
        return {
            type: 'Feature',
            properties: { cluster: false, id: _id },
            geometry: {
                type: 'Point',
                coordinates: [parseFloat(lng), parseFloat(lat)],
            },
        };
    });
};

export default mapDataToGeoJSON;
