import clsx from 'clsx';
import { Link } from 'react-router-dom';

const SettingsLink = ({ className = 'col mb-4', children, to = '/' }) => {
  return (
    <div className={className}>
      <div className="card h-100">
        <Link
          to={to}
          className={clsx(
            'card-body',
            'btn btn-light',
            'd-flex justify-content-center align-items-center'
          )}
        >
          {children}
        </Link>
      </div>
    </div>
  );
};

export default SettingsLink;
