import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Icon from '../UI/Icon';
import DataTable from 'react-data-table-component';
import Wait from '../UI/Wait';
import ReactTooltip from 'react-tooltip';

const UserRegSettingsTable = ({
    current,
    onSelect,
    data,
    loading,
    onRowClicked,
    totalRows,
    rowsCountPerPage,
    handleRowsPerPage = () => {},
    onDeleteClick = () => {},
    perPageChoices = [25],
    handlePageChange = () => {},
}) => {
    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };
    const conditionalRowStyles = useMemo(
        () => [
            {
                when: ({ _id }) => _id === current,
                style: {
                    color: 'gray',
                },
            },
            {
                when: ({ _id }) => _id === current,
                style: {
                    backgroundColor: 'antiquewhite',
                },
            },
        ],
        [current]
    );

    const columns = useMemo(
        () => [
            {
                name: 'Select',
                grow: 0,
                cell: (row, _index, _column) => {
                    const iconName =
                        row._id === current ? 'bi-check2-square' : 'bi-square';
                    return (
                        <div
                            onClick={() => onSelect(row._id)}
                            data-tip
                            data-for="selectTip"
                        >
                            <Icon iconName={`btn ${iconName}`} />
                            <ReactTooltip
                                id="selectTip"
                                place="top"
                                effect="solid"
                            >
                                Select settings
                            </ReactTooltip>
                        </div>
                    );
                },
            },
            {
                name: 'ID',
                grow: 2,
                cell: ({ _id }, index) => _id,
            },
            {
                name: 'Name',
                grow: 3,
                cell: ({ name }) => name,
            },
            {
                name: 'Action',
                grow: 1,
                right: true,
                cell: ({ _id }) => (
                    <div>
                        <Link
                            to={`/merchant/user/registration-settings/edit?id=${_id}`}
                            data-tip
                            data-for="editTip"
                        >
                            <Icon iconName="bi-pencil" />
                            <ReactTooltip
                                id="editTip"
                                place="top"
                                effect="solid"
                            >
                                Edit
                            </ReactTooltip>
                        </Link>
                        <span
                            data-tip
                            data-for="deleteTip"
                            onClick={() => {
                                onDeleteClick(_id);
                            }}
                        >
                            <Icon iconName="bi bi-trash" />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </span>
                    </div>
                ),
            },
        ],
        [current, onSelect]
    );
    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            conditionalRowStyles={conditionalRowStyles}
            customStyles={customStyles}
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            onChangeRowsPerPage={handleRowsPerPage}
            paginationServer
            progressPending={loading}
            onRowClicked={onRowClicked}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            noDataComponent={<div>No settings yet...</div>}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default UserRegSettingsTable;
