export const COLOR_BLUE = '#007bff';
export const COLOR_INDIGO = '#6610f2';
export const COLOR_PURPLE = '#6f42c1';
export const COLOR_PINK = '#e83e8c';
export const COLOR_RED = '#dc3545';
export const COLOR_ORANGE = '#fd7e14';
export const COLOR_YELLOW = '#ffc107';
export const COLOR_GREEN = '#28a745';
export const COLOR_TEAL = '#20c997';
export const COLOR_CYAN = '#17a2b8';
export const COLOR_WHITE = '#fff';
export const COLOR_GRAY = '#6c757d';
export const COLOR_GRAY_DARK = '#343a40';
export const COLOR_PRIMARY = '#007bff';
export const COLOR_SECONDARY = '#6c757d';
export const COLOR_SUCCESS = '#28a745';
export const COLOR_INFO = '#17a2b8';
export const COLOR_WARNING = '#ffc107';
export const COLOR_DANGER = '#dc3545';
export const COLOR_LIGHT = '#f8f9fa';
export const COLOR_DARK = '#343a40';
