import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import Wait from '../../../UI/Wait';
import OrderModal from '../OrderModal';
import { Link } from 'react-router-dom';
import Icon from '../../../UI/Icon';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import setDecimals from '../../../../utils/setDecimals';
import renderCurrency from '../../../../utils/renderCurrency';

const PaymentList = ({
    data,
    loading,
    emptyText,
    rowsCountPerPage,
    handleRowsPerPage,
    totalRows,
    handlePageChange,
    handleStartRefund,
    handleIssue,
}) => {
    const [showOrderPreview, setShowOrderPreview] = useState(false);
    const handleShowOrderPreview = () => setShowOrderPreview(true);
    const handleCloseOrderPreview = () => setShowOrderPreview(false);
    const [currenOrder, setCurrentOrder] = useState();

    const renderAmount = (val, currency) => {
        const updatedVal = setDecimals(val);
        const currencySymbol = renderCurrency(currency);
        if (updatedVal) {
            return `${currencySymbol}${updatedVal}`;
        }
        return <span>&mdash;</span>;
    };

    const columns = useMemo(
        () => [
            {
                name: 'Created',
                grow: 2,
                cell: ({ creation_date }) => creation_date,
            },
            {
                name: 'Email',
                grow: 2,
                cell: ({ user_info }) => user_info?.email || 'info@trumo.io',
            },
            {
                name: 'Balance before',
                grow: 1,
                cell: ({ custom_fields, currency }) =>
                    renderAmount(
                        custom_fields?.wallet_balance_before,
                        currency
                    ),
            },
            {
                name: 'Balance after',
                grow: 1,
                cell: ({ custom_fields, currency }) =>
                    renderAmount(custom_fields?.wallet_balance_after, currency),
            },
            {
                name: 'Bank',
                grow: 1,
                cell: ({ customer_bank }) => customer_bank,
            },
            {
                name: 'Amount',
                grow: 1,
                cell: ({ amount, currency }) => renderAmount(amount, currency),
            },
            {
                name: 'Actions',
                right: true,
                grow: 1,
                cell: (row) => (
                    <div>
                        {row.order_type === 'PAYMENT_ORDER_TYPE' &&
                        row.status !== 'refunded' ? (
                            <>
                                <span
                                    data-tip
                                    data-for="refund"
                                    onClick={() => handleStartRefund(row)}
                                >
                                    <Icon iconName="bi-wallet2" />
                                    <ReactTooltip
                                        id="refund"
                                        place="top"
                                        effect="solid"
                                    >
                                        Refund
                                    </ReactTooltip>
                                </span>

                                {row.status !== 'issued' ? (
                                    <span
                                        data-tip
                                        data-for="issue"
                                        onClick={() => handleIssue(row)}
                                    >
                                        <Icon iconName="bi-ticket-perforated" />
                                        <ReactTooltip
                                            id="issue"
                                            place="top"
                                            effect="solid"
                                        >
                                            Issue
                                        </ReactTooltip>
                                    </span>
                                ) : null}
                            </>
                        ) : null}
                        <Link
                            to={`/orders/view?order=${row?._id}`}
                            data-tip
                            data-for="viewOrderTip"
                        >
                            <Icon iconName="bi-info-circle" />
                            <ReactTooltip
                                id="viewOrdertip"
                                place="top"
                                effect="solid"
                            >
                                View Order
                            </ReactTooltip>
                        </Link>
                    </div>
                ),
            },
        ],
        [loading]
    );

    const conditionalRowStyles = [
        {
            when: (row) => row.order_type === 'PAYMENT_ORDER_TYPE',
            style: {
                backgroundColor: 'var(--teal)',
                color: 'white',
            },
        },
        {
            when: (row) => row.order_type === 'WITHDRAW_ORDER_TYPE',
            style: {
                backgroundColor: 'var(--cyan)',
                color: 'white',
            },
        },
        {
            when: (row) => row.order_type === 'REFUND_ORDER_TYPE',
            style: {
                backgroundColor: 'lightcoral',
                color: 'white',
            },
        },
        {
            when: (row) => row.status === 'refunded',
            style: {
                backgroundColor: 'var(--secondary)',
                color: 'white',
            },
        },
        {
            when: (row) => row.status === 'issued',
            style: {
                backgroundColor: 'var(--danger)',
                color: 'white',
            },
        },
    ];

    let onRowClick = (row) => {
        setCurrentOrder(row);
        handleShowOrderPreview();
    };

    return (
        <div>
            <DataTable
                dense
                striped
                highlightOnHover
                pointerOnHover
                data={data}
                columns={columns}
                pagination={true}
                paginationPerPage={rowsCountPerPage}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={(val) => handleRowsPerPage(val)}
                paginationServer
                onChangePage={handlePageChange}
                paginationTotalRows={totalRows}
                progressPending={loading}
                noDataComponent={emptyText}
                progressComponent={
                    <div className="p-5">
                        <Wait />
                    </div>
                }
                conditionalRowStyles={conditionalRowStyles}
                onRowClicked={onRowClick}
            />
            {currenOrder && (
                <OrderModal
                    orderId={currenOrder._id}
                    show={showOrderPreview}
                    onHide={handleCloseOrderPreview}
                ></OrderModal>
            )}
        </div>
    );
};

export default PaymentList;
