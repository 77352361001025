import { useMemo } from 'react';
import useSearchString from '../../utils/useSearchString';
import DataTable from 'react-data-table-component';
import Wait from '../UI/Wait';
import { Link } from 'react-router-dom';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';

const ShopList = ({ data, rowsCountPerPage, totalRows, handlePerRowsChange,
     handlePageChange, loading, onDeleteClick = () => {} }) => {
    const { appid, merchant } = useSearchString(); //todo
    
    const columns = useMemo(
        () => [
            {
                name: '#',
                grow: 0,
                cell: (_, index) => index + 1,
            },
            {
                name: 'Name',
                sortable: true,
                selector: row => row.str_name,
                grow: 2,
                cell: ({ str_name }) => str_name,
            },
            {
                name: 'Service type',
                sortable: true,
                selector: row => row.service_type,
                grow: 1,
                cell: ({ service_type }) => service_type,
            },
            {
                name: 'Shop type',
                sortable: true,
                selector: row => row.shop_type,
                grow: 1,
                cell: ({ shop_type }) => shop_type,
            },
            {
                name: 'Email',
                sortable: true,
                selector: row => row.str_email,
                grow: 2,
                cell: ({ str_email }) => str_email,
            },
            {
                name: 'Actions',
                right: true,
                grow: 2,
                cell: (row, _index, _column) => (
                    <div>
                        <Link to={`/shop/edit?merchant=${row.merchant}&shop=${row._id}`}>
                            <Icon iconName="btn bi-pen" />
                        </Link>
                        <Link to={`/shop/opentime?shop=${row._id}`}>
                            <Icon iconName="btn bi-clock" />
                        </Link>
                        <Link to={`/shop/closedays?shop=${row._id}`}>
                            <Icon iconName="bi bi-calendar4-week" />
                        </Link>
                        <span data-tip data-for="deleteTip" onClick={() => {
                onDeleteClick(row._id);
              }}>
                            <Icon iconName="bi bi-trash" />
                            <ReactTooltip id="deleteTip" place="top" effect="solid">Delete</ReactTooltip>
                        </span>
                    </div>
                ),
            },
        ],
        [appid]
    );
    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={[rowsCountPerPage]}
            progressPending={loading}
            paginationServer
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default ShopList;
