import Honkio from '../../Honkio';

const getWarehouse = async function (options = {}, errorHandler = {}) {
    const { id } = options;

    return Honkio().mainShop.userFetch(
        'warehouseget',
        {
            id,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};

export default getWarehouse;
