import React, { useState, useEffect } from 'react';
import StepWizard from 'react-step-wizard';
import { Stepper, Step } from 'react-form-stepper';
import { Row, Col, Button, FormGroup, Form } from 'react-bootstrap';
import Icon from '../UI/Icon';
import getServerAppList from '../../api/utils/app/getServerAppList';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import ApplicationSelector from './components/ApplicationSelector';
import ActionButtons from './components/ActionButtons';
import MerchantSelector from './components/MerchantSelector';
import RoleSelector from './components/RoleSelector';
import RolesSummary from './components/RolesSummary';
import _ from 'lodash';
import cn from 'classnames';
import {
    COLOR_DARK,
    COLOR_GRAY,
    COLOR_INFO,
    COLOR_PRIMARY,
    COLOR_SUCCESS,
    COLOR_WHITE,
} from '../../constants/bootstrap_colors';

const RolesSelector = ({
    selectedApps = [],
    setSelectedApps = () => {},
    selectedMerchants = [],
    setSelectedMerchants = () => {},
    selectedRoles = [],
    setSelectedRoles = () => {},
    saveUserApps = () => {},
    consumer,
    disableFinal = false,
}) => {
    const [appList, setAppList] = useState([]);
    const [rawMerchants, setRawMerchants] = useState([]);
    const [rolesList, setRolesList] = useState([]);

    const [stepWizard, setStepWizard] = useState(null);
    const [user, setUser] = useState({});
    const [activeStep, setActiveStep] = useState(0);

    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };

    const handleSelectApps = (val = []) => {
        if (!_.isEqual(val.sort(), selectedApps.sort())) {
            // Clear previously selected Merchants and Roles
            setSelectedMerchants([]);
            setSelectedRoles([]);
        }
        setSelectedApps(val);
    };

    const handleSelectMerchants = (val) => {
        if (!_.isEqual(val.sort(), selectedMerchants.sort())) {
            // Clear previously selected Roles
            setSelectedRoles([]);
        }
        setSelectedMerchants(val);
    };

    const handleSelectRoles = (val) => {
        setSelectedRoles(val);
    };

    const handleStepChange = (e) => {
        setActiveStep(e.activeStep - 1);
    };

    const handleComplete = () => {
        if (disableFinal) return;
        NotificationManager.success('User apps selected', 'Success', 4000);
        saveUserApps();
    };

    const styleConfig = {
        // Text
        activeTextColor: COLOR_WHITE,
        completedTextColor: COLOR_WHITE,
        inactiveTextColor: COLOR_WHITE,
        // Backgrounds
        activeBgColor: COLOR_PRIMARY,
        completedBgColor: COLOR_SUCCESS,
        inactiveBgColor: COLOR_GRAY,
    };
    const connectorStyles = {
        // Connections
        disabledColor: COLOR_GRAY,
        activeColor: COLOR_PRIMARY,
        completedColor: COLOR_SUCCESS,
        size: 3,
    };

    return (
        <div>
            <NotificationContainer />

            <Stepper
                activeStep={activeStep}
                connectorStateColors
                connectorStyleConfig={connectorStyles}
                styleConfig={styleConfig}
            >
                <Step
                    label="Select Application"
                    children={<Icon iconName="bi-box-fill" color="inherit" />}
                    onClick={(e) => e.preventDefault()}
                    onDoubleClick={(e) => e.preventDefault()}
                />
                <Step
                    label="Select Merchant"
                    children={<Icon iconName="bi-truck" color="inherit" />}
                    onClick={(e) => e.preventDefault()}
                    onDoubleClick={(e) => e.preventDefault()}
                />
                <Step
                    label="Select Role"
                    children={
                        <Icon iconName="bi-people-fill" color="inherit" />
                    }
                    onClick={(e) => e.preventDefault()}
                    onDoubleClick={(e) => e.preventDefault()}
                />
                <Step
                    label="Summary"
                    children={<Icon iconName="bi-check-lg" color="inherit" />}
                    onClick={(e) => e.preventDefault()}
                    onDoubleClick={(e) => e.preventDefault()}
                />
            </Stepper>

            {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}
            <StepWizard
                instance={assignStepWizard}
                onStepChange={handleStepChange}
            >
                <ApplicationSelector
                    appList={appList}
                    setAppList={setAppList}
                    appCallback={handleSelectApps}
                    selectedApps={selectedApps}
                />
                <MerchantSelector
                    appList={appList}
                    merchantCallback={handleSelectMerchants}
                    selectedApps={selectedApps}
                    selectedMerchants={selectedMerchants}
                    rawMerchants={rawMerchants}
                    setRawMerchants={setRawMerchants}
                />
                <RoleSelector
                    rolesList={rolesList}
                    setRolesList={setRolesList}
                    rawMerchants={rawMerchants}
                    roleCallback={handleSelectRoles}
                    selectedMerchants={selectedMerchants}
                    selectedRoles={selectedRoles}
                />
                <RolesSummary
                    selectedApps={selectedApps}
                    selectedMerchants={selectedMerchants}
                    selectedRoles={selectedRoles}
                    appList={appList}
                    merchantList={rawMerchants}
                    rolesList={rolesList}
                    completeCallback={handleComplete}
                    disableFinal={disableFinal}
                />
            </StepWizard>
        </div>
    );
};

export default RolesSelector;
