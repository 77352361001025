import Honkio from '../../../Honkio';

const setOrderModelTemplate = (options = {}, errorHandler = () => {}) => {
    const {
        id,
        merchant,
        application,
        name,
        active,
        statuses,
        order_type,
        action,
    } = options;
    return Honkio().mainShop.userFetch(
        'admintemplatemodelsordermodelset',
        {
            id,
            merchant,
            application,
            name,
            active,
            statuses,
            order_type,
            action,
            version: 3,
        },
        errorHandler,
        true
    );
};

export const createOrderModelTemplate = (
    options = {},
    errorHandler = () => {}
) => {
    return setOrderModelTemplate(
        {
            ...options,
            action: 'create',
        },
        errorHandler
    );
};

export const updateOrderModelTemplate = (
    options = {},
    errorHandler = () => {}
) => {
    return setOrderModelTemplate(
        {
            ...options,
            action: 'update',
        },
        errorHandler
    );
};

export const deleteOrderModelTemplate = (
    options = {},
    errorHandler = () => {}
) => {
    return setOrderModelTemplate(
        {
            ...options,
            action: 'delete',
        },
        errorHandler
    );
};
