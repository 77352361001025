import _ from 'lodash';
import React, { useContext, useState } from 'react';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import { addPaymentProvider } from '../../api/utils/payments/getPayment';
import BackButton from '../UI/BackButton';
import PaymentForm from './PaymentForm';
import useSearchString from '../../utils/useSearchString';
import UserInfoContext from '../../contexts/UserInfoContext';

const PaymentCreate = () => {
    const [loading, setLoading] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState();

    const { merchant = null } = useSearchString();
    const userInfo = useContext(UserInfoContext);

    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;

    const onSubmit = async (data) => {
        const appSubmitData = _.cloneDeep(data);

        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setPaymentInfo(data.payment_provider);
                NotificationManager.success(
                    'Payment provider saved',
                    'Success',
                    4000
                );
            } else {
                NotificationManager.error('Unknown error', 'Error', 4000);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setLoading(true);
            const res = await addPaymentProvider(
                { ...appSubmitData, merchant_id: merchantId },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton
                    to="/payment"
                    text="Back to payment provider list"
                />
                Create Payment provider
            </h2>

            <PaymentForm onSubmit={onSubmit} paymentInfo={paymentInfo} />
        </div>
    );
};

export default PaymentCreate;
