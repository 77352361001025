import clsx from 'clsx';

const Icon = ({
    iconName,
    size = 1,
    color = '#007bff',
    colorOnHover = 'black',
    className = '',
}) => (
    <i
        className={clsx(['btn btn-link p-1', iconName, className])}
        style={{ fontSize: `${size}rem`, color }}
        onMouseEnter={({ target }) => {
            target.style.color = colorOnHover;
        }}
        onMouseLeave={({ target }) => {
            target.style.color = color;
        }}
    />
);

export default Icon;
