const prepareInfo = (merchant, id, formData) => {
    const { settings, active, warehouses } = formData;
    const shop = {
        id,
        merchant,
        warehouses,
        active,
        settings,
    };
    return { ...shop };
};

export default prepareInfo;
