const resetingValues = [undefined, ''];

const ifBool = (type) => ({ trueBool: true, falseBool: false }[type] ?? type);

const typifyIfEquals = (data = [], col, type) => {
  type = ifBool(type);
  return data.filter((row) =>
    resetingValues.includes(type) ? true : row[col] === type
  );
};

export default typifyIfEquals;
