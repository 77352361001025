import Honkio from '../../Honkio';

/**
 *  Get room messages by `id`
 * @param {*} id : Room id
 * @param {*} errorHandler
 * @returns
 */
const getRoomMessages = (id, errorHandler = {}) => {
  return Honkio().mainShop.userFetch(
    'chatmessagelist',
    {
      query_room: id,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getRoomMessages;
