import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';

const RulesEditForm = ({ appInfo, setAppInfo, onSubmit }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(appInfo);
    };

    const onChangeAppLoginRules = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        let addLoginRules = {
            [name]: value,
        };
        let oldLoginRules = {};
        if (appInfo?.settings?.login_rules) {
            oldLoginRules = { ...appInfo.settings.login_rules };
        }

        const newLoginRules = {
            ...oldLoginRules,
            ...addLoginRules,
        };

        const newAppInfo = {
            ...appInfo,
            settings: {
                ...appInfo.settings,
                login_rules: newLoginRules,
            },
        };
        setAppInfo(newAppInfo);
    };

    return (
        <div>
            <Container fluid>
                <NotificationContainer />

                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="settings.str_email">
                                <Form.Label>Token lifespan</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="token_lifespan"
                                    value={
                                        appInfo.settings?.login_rules
                                            ?.token_lifespan || ''
                                    }
                                    onChange={onChangeAppLoginRules}
                                />
                                <Form.Text className="text-muted">
                                    Token life time in minutes
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button type="submit" variant="primary">
                        Submit
                    </Button>
                </Form>
            </Container>
        </div>
    );
};

export default RulesEditForm;
