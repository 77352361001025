const prepareInfo = (info) => {
    const {
      email,
      message
    } = info;
    return {
        email,
        message
    };
  };
  
  export default prepareInfo;
  