import React, { useEffect, useState, useContext } from 'react';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import getMerchantRegAppSettings from '../../api/utils/settings/merchantRegisterApp/getMerchantRegAppSettings';
import PricingPlansList from './components/PricingPlans/PricingPlansList';
import SmartForm from '../Form/SmartForm';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';
import setMerchantRegAppSettings from '../../api/utils/settings/merchantRegisterApp/setMerchantRegAppSettings';
import prepareSettingsData from './helpers/prepareSettingsData';
import YearlyTurnover from './components/YearlyTurnover/YearlyTurnover';
import parseRegAppSettingsData from './helpers/parseRegAppSettingsData';
import RiskyBusinesses from './components/RiskyBusinesses/RiskyBusinesses';
import NumberOfTransactions from './components/NumberOfTransactions/NumberOfTransactions';
import NumberOfReturns from './components/NumberOfReturns/NumberOfReturns';
import AvgReturnAmount from './components/AvgReturnAmount/AvgReturnAmount';
import CountriesList from './components/Countries/CountriesList';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

const MerchantRegAppSettings = () => {
  const userInfo = useContext(UserInfoContext);

  const [pageTitle, setPageTitle] = useState(
    'Merchant register application settings'
  );
  const [regAppSettings, setRegAppSettings] = useState(null);

  const [selectedCountries, setSelectedCountries] = useState([]);

  const [loading, setLoading] = useState(false);

  const [progress, done] = useLoader([userInfo, regAppSettings]);

  useEffect(() => {
    document.title = 'Register app settings';
  }, []);

  useEffect(() => {
    if (userInfo?.app?.id) {
      fetchRegAppSettings(userInfo.app.id);
    }
    if (userInfo?.app?.name) {
      setPageTitle(
        `Merchant register application settings for '${userInfo?.app?.name}'`
      );
    }
  }, [userInfo]);

  const fetchRegAppSettings = async (appId) => {
    setLoading(true);

    const res = await getMerchantRegAppSettings({ applicationId: appId });

    if (res?.status === 'accept') {
      const parsedSettings = parseRegAppSettingsData(res.settings);
      setRegAppSettings(parsedSettings);
    }

    setLoading(false);
  };

  const handleSubmit = async (data) => {
    const errorHandler = (error) => {
      console.log(`Error submitting merchant app settings: `, error);
      NotificationManager.error(error.description || 'Error');
      setLoading(false);
    };

    const submitData = prepareSettingsData({
      ...data,
      countries: selectedCountries,
    });
    submitData.application_id = userInfo?.app?.id || '';

    console.log('submitData ==', submitData);

    setLoading(true);
    const res = await setMerchantRegAppSettings(submitData, errorHandler);

    setLoading(false);
    if (res?.status === 'accept') {
      NotificationManager.success('Settings saved');
      if (res?.settings) {
        const parsedSettings = parseRegAppSettingsData(res.settings);
        setRegAppSettings(parsedSettings);
      }
    } else {
      NotificationManager.error('Error saving settings');
    }
  };

  useEffect(() => {
    // console.log('regAppSettings == ', regAppSettings);
    if (regAppSettings?.countries?.length) {
      setSelectedCountries(getCurrentCountries());
    }
  }, [regAppSettings]);

  const getCurrentCountries = () => {
    if (regAppSettings?.countries?.length) {
      return regAppSettings.countries.map((item) => ({
        value: item,
        label: item,
      }));
    }
    return [];
  };

  return done ? (
    <div className="container-fluid">
      <NotificationContainer />
      <h2>{pageTitle}</h2>
      <div className="row">
        <div className="col"></div>
        <div></div>
      </div>
      <div className="row">
        {userInfo?.app?.id && regAppSettings ? (
          <div className="col">
            <SmartForm defaultValues={regAppSettings} onSubmit={handleSubmit}>
              <CountriesList
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
              />

              <PricingPlansList label="Pricing plans" />

              <YearlyTurnover />

              <RiskyBusinesses />

              <NumberOfTransactions />

              <NumberOfReturns />

              <AvgReturnAmount />

              <PrivacyPolicy />

              <button
                className="btn btn-primary btn-block btn-lg mt-3 mb-10"
                disabled={loading}
              >
                Save changes
              </button>
            </SmartForm>
          </div>
        ) : (
          <div className="col">
            <label>Please, select application first</label>
          </div>
        )}
      </div>
    </div>
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default MerchantRegAppSettings;
