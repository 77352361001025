import React, { useState } from 'react';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import _ from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BackButton from '../../UI/BackButton';
import TemplateNotifymessageForm from './TemplateNotifymessageForm';
import { createNotifymessageTemplate } from '../../../api/utils/templateModels/templateNotifymessages/setNotifymessageTemplate';

const TemplateNotifymessageCreate = () => {
    const history = useHistory();

    const [submitLoading, setSubmitLoading] = useState(false);

    const handleSubmitForm = async (submitData) => {
        const resultHandler = (data) => {
            setSubmitLoading(false);
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Created successfully',
                    'Success',
                    3000
                );
                if (data?.notify_message_template?._id)
                    history.push(`/template-models/notifymessages`);
            }
        };

        const errorHandler = (error) => {
            setSubmitLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setSubmitLoading(true);
            const res = await createNotifymessageTemplate(
                submitData,
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton
                    to="/template-models/notifymessages"
                    text="Back to list"
                />
                Create notifymessage template
            </h2>

            <TemplateNotifymessageForm
                onSubmit={handleSubmitForm}
                submitLoading={submitLoading}
            />
        </div>
    );
};

export default TemplateNotifymessageCreate;
