import Honkio from '../../Honkio';

const getGreyList = (options = {}, errorHandler = {}) => {
  const {
    merchantId,
    greylistType,
    applicationId,
    id_list,
    query_skip,
    query_count,
  } = options;
  return Honkio().mainShop.userFetch(
    'admingreylistlist',
    {
      merchant_id: merchantId,
      application_id: applicationId,
      greylist_type: greylistType,
      id_list,
      query_skip,
      query_count,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getGreyList;
