import { useEffect, useState, useContext, useMemo } from 'react';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import useSearchString from '../../utils/useSearchString';
import { Link } from 'react-router-dom';
import { CONSUMER_URL } from '../../config';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';
import DataTable from 'react-data-table-component';
import { Modal, Button } from 'react-bootstrap';
import { getConsumerList } from '../../api/utils/consumer/getConsumerList';
import { removeRoleFromUser } from '../../api/utils/role/setUserRole';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const MerchantUsersList = () => {
  const [users, setUsers] = useState([]);
  const { role = null, title = 'Users' } = useSearchString();
  const [userId, setUserId] = useState(null);
  const [userToRemove, setUserToRemove] = useState(null);
  const [showConfirmRemoveUser, setShowConfirmRemoveUser] = useState(false);
  const handleShowConfirmRemoveUser = () => setShowConfirmRemoveUser(true);
  const handleCloseConfirmRemoveUser = () => setShowConfirmRemoveUser(false);

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    role && fetchUsers(1, role);
  }, [role, perPage]);

  useEffect(() => {
    console.log('userId ==', userId);
  }, [userId]);

  const showConfirmDialog = (user_id, email) => {
    setUserId(user_id);
    setUserToRemove({ user_id, email });
    handleShowConfirmRemoveUser();
  };

  const deleteUserFromGroup = () => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Role removed', 'Success', 4000);
        handleCloseConfirmRemoveUser();
        fetchUsers(1, role);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    removeRoleFromUser(
      {
        role_id: role,
        user_id: userId,
      },
      errorHandler
    )
      .then((data) => {
        return resultHandler(data);
      })
      .then(handleCloseConfirmRemoveUser);
  };

  const fetchUsers = async (page = 1, role_id) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        setUsers(data?.consumers);
        setTotalRows(data?.total);
        setLoading(false);
      } else {
        setUsers([]);
        setTotalRows(0);
        setLoading(false);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setUsers([]);
      setLoading(false);
    };
    const res = await getConsumerList(
      {
        query_role: role_id,
        query_skip: (+page - 1) * perPage,
        query_count: perPage,
      },
      errorHandler
    );
    resultHandler(res);
  };

  const columns = useMemo(() => [
    {
      name: 'Serial',
      sortable: true,
      autoWidth: true,
      grow: 2,
      cell: ({ serial_number }) => serial_number,
    },
    {
      name: 'Avatar',
      autoWidth: true,
      grow: 0,
      cell: ({ settings }) => (
        <img
          height="32px"
          width="32px"
          src={`${settings?.str_photo_data}`}
          alt="User's avatar"
        />
      ),
    },
    {
      name: 'Active',
      sortable: true,
      cell: ({ active }) => (active ? 'Yes' : 'No'),
    },
    {
      name: 'First name',
      autoWidth: true,
      sortable: true,
      cell: ({ settings }) => settings?.str_firstname,
    },
    {
      name: 'Last name',
      autoWidth: true,
      sortable: true,
      cell: ({ settings }) => settings?.str_lastname,
    },
    {
      name: 'email',
      autoWidth: true,
      grow: 2,
      sortable: true,
      cell: ({ email }) => email,
    },
    {
      name: 'Actions',
      cell: ({ _id, email, serial_number }) => (
        // <div className="flex">
        <div>
          <Link
            // to={`/user/transactions?consumerId=${id}`}
            to={`/user/transactions?serial=${serial_number}`}
            data-tip
            data-for="transactionsTip"
          >
            <Icon iconName="bi-card-checklist" />
            <ReactTooltip id="transactionsTip" place="top" effect="solid">
              Transactions
            </ReactTooltip>
          </Link>
          <span
            onClick={() => {
              showConfirmDialog(_id, email);
            }}
            data-tip
            data-for="deleteTip"
          >
            <Icon iconName="btn bi-trash" />
            <ReactTooltip id="deleteTip" place="top" effect="solid">
              Delete from group
            </ReactTooltip>
          </span>
        </div>
      ),
    },
  ]);

  const handlePageChange = (page) => {
    fetchUsers(page, role);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  return (
    <div className="container-fluid">
      <NotificationContainer />
      <h2>{`Users (${title})`}</h2>
      <div>
        <Link
          to={`/invitation/create?role_id=${role}&role_name=${title}`}
          className="btn btn-outline-primary mt-3 mb-3"
        >
          <i className="bi-plus-square ml-1 mr-2 " /> Invite User
        </Link>

        <DataTable
          notitle
          dense
          striped
          data={users}
          columns={columns}
          pagination={true}
          paginationPerPage={PER_PAGE_CHOICES[0]}
          paginationRowsPerPageOptions={PER_PAGE_CHOICES}
          onChangeRowsPerPage={handleRowsPerPage}
          paginationServer
          progressPending={loading}
          onChangePage={handlePageChange}
          paginationTotalRows={totalRows}
        />

        <Modal
          show={showConfirmRemoveUser}
          onHide={handleCloseConfirmRemoveUser}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Do you want to remove user{' '}
              <b>{userToRemove ? userToRemove.email : ''}</b> from group{' '}
              <b>{title}</b>? The user will not be deleted from the system
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseConfirmRemoveUser}>
              Close
            </Button>
            <Button variant="danger" onClick={() => deleteUserFromGroup()}>
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default MerchantUsersList;
