import Honkio from '../../Honkio';

const getWarehousesList = async function (options = {}, errorHandler = {}) {
    const { query_merchant, query_application, query_skip, query_count } =
        options;

    return Honkio().mainShop.userFetch(
        'warehouselist',
        {
            query_merchant,
            query_application,
            query_skip,
            query_count,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};

export default getWarehousesList;
