import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Icon from '../UI/Icon';
import Wait from '../UI/Wait';
import ReactTooltip from 'react-tooltip';

import {
  GREY_LIST_TYPE_ADDRESS,
  GREY_LIST_TYPE_COORDINATES,
  GREY_LIST_TYPE_EMAIL,
  GREY_LIST_TYPE_NAME,
  GREY_LIST_TYPE_NETWORK,
} from './GreyListTypes';

const GreyListTable = ({
  data,
  type,
  loading,
  emptyText,
  onDelete,
  onEdit,
  rowsCountPerPage,
  handleRowsPerPage = () => {},
  perPageChoices = [25],
  handlePageChange = () => {},
  totalRows = 0,
}) => {
  const columnsAddress = useMemo(
    () => [
      { name: 'Address', grow: 2, cell: ({ address }) => address },
      { name: 'Score', grow: 1, cell: ({ score }) => score },
      {
        name: 'Actions',
        grow: 1,
        right: true,
        cell: (row) => (
          <>
            <div
              onClick={() => {
                onEdit(row);
              }}
              data-tip
              data-for="editTip"
            >
              <Icon iconName="btn bi-pencil" />
              <ReactTooltip id="editTip" place="top" effect="solid">
                Edit entry
              </ReactTooltip>
            </div>
            <div
              onClick={() => {
                onDelete(row._id);
              }}
              data-tip
              data-for="deleteTip"
            >
              <Icon iconName="btn bi-trash" />
              <ReactTooltip id="deleteTip" place="top" effect="solid">
                Delete from greylist
              </ReactTooltip>
            </div>
          </>
        ),
      },
    ],
    [loading]
  );
  const columnsNetwork = useMemo(
    () => [
      { name: 'Network', grow: 2, cell: ({ network }) => network },
      { name: 'Score', grow: 1, cell: ({ score }) => score },
      {
        name: 'Actions',
        grow: 0,
        right: true,
        cell: (row) => (
          <>
            <div
              onClick={() => {
                onEdit(row);
              }}
              data-tip
              data-for="editTip"
            >
              <Icon iconName="btn bi-pencil" />
              <ReactTooltip id="editTip" place="top" effect="solid">
                Edit entry
              </ReactTooltip>
            </div>
            <div
              onClick={() => {
                onDelete(row._id);
              }}
              data-tip
              data-for="deleteTip"
            >
              <Icon iconName="btn bi-trash" />
              <ReactTooltip id="deleteTip" place="top" effect="solid">
                Delete from greylist
              </ReactTooltip>
            </div>
          </>
        ),
      },
    ],
    [loading]
  );
  const columnsEmail = useMemo(
    () => [
      { name: 'Email', grow: 2, cell: ({ email }) => email },
      { name: 'Reason', grow: 2, cell: ({ email_reason }) => email_reason },
      { name: 'Score', grow: 1, cell: ({ score }) => score },
      {
        name: 'Actions',
        grow: 0,
        right: true,
        cell: (row) => (
          <>
            <div
              onClick={() => {
                onEdit(row);
              }}
              data-tip
              data-for="editTip"
            >
              <Icon iconName="btn bi-pencil" />
              <ReactTooltip id="editTip" place="top" effect="solid">
                Edit entry
              </ReactTooltip>
            </div>
            <div
              onClick={() => {
                onDelete(row._id);
              }}
              data-tip
              data-for="deleteTip"
            >
              <Icon iconName="btn bi-trash" />
              <ReactTooltip id="deleteTip" place="top" effect="solid">
                Delete from greylist
              </ReactTooltip>
            </div>
          </>
        ),
      },
    ],
    [loading]
  );
  const columnsName = useMemo(
    () => [
      { name: 'First name', grow: 2, cell: ({ firstname }) => firstname },
      { name: 'Last name', grow: 2, cell: ({ lastname }) => lastname },
      { name: 'Reason', grow: 2, cell: ({ name_reason }) => name_reason },
      { name: 'Score', grow: 1, cell: ({ score }) => score },
      {
        name: 'Actions',
        grow: 0,
        right: true,
        cell: (row) => (
          <>
            <div
              onClick={() => {
                onEdit(row);
              }}
              data-tip
              data-for="editTip"
            >
              <Icon iconName="btn bi-pencil" />
              <ReactTooltip id="editTip" place="top" effect="solid">
                Edit entry
              </ReactTooltip>
            </div>
            <div
              onClick={() => {
                onDelete(row._id);
              }}
              data-tip
              data-for="deleteTip"
            >
              <Icon iconName="btn bi-trash" />
              <ReactTooltip id="deleteTip" place="top" effect="solid">
                Delete from greylist
              </ReactTooltip>
            </div>
          </>
        ),
      },
    ],
    [loading]
  );
  const columnsCoordinates = useMemo(
    () => [
      { name: 'Latitude', grow: 2, cell: ({ latitude }) => latitude },
      { name: 'Longitude', grow: 2, cell: ({ longitude }) => longitude },
      { name: 'Radius', grow: 2, cell: ({ radius }) => radius },
      { name: 'Score', grow: 1, cell: ({ score }) => score },
      {
        name: 'Actions',
        grow: 0,
        right: true,
        cell: (row) => (
          <>
            <div
              onClick={() => {
                onEdit(row);
              }}
              data-tip
              data-for="editTip"
            >
              <Icon iconName="btn bi-pencil" />
              <ReactTooltip id="editTip" place="top" effect="solid">
                Edit entry
              </ReactTooltip>
            </div>

            <div
              onClick={() => {
                onDelete(row._id);
              }}
              data-tip
              data-for="deleteTip"
            >
              <Icon iconName="btn bi-trash" />
              <ReactTooltip id="deleteTip" place="top" effect="solid">
                Delete from greylist
              </ReactTooltip>
            </div>
          </>
        ),
      },
    ],
    [loading]
  );

  const columns = () => {
    switch (type) {
      case GREY_LIST_TYPE_EMAIL:
        return columnsEmail;
      case GREY_LIST_TYPE_COORDINATES:
        return columnsCoordinates;
      case GREY_LIST_TYPE_ADDRESS:
        return columnsAddress;
      case GREY_LIST_TYPE_NETWORK:
        return columnsNetwork;
      case GREY_LIST_TYPE_NAME:
        return columnsName;
      default:
        return <label>Invalid grey list column type</label>;
    }
  };

  return (
    <div>
      <DataTable
        dense
        striped
        highlightOnHover
        data={data}
        columns={columns()}
        pagination={true}
        progressPending={loading}
        noDataComponent={emptyText}
        paginationPerPage={rowsCountPerPage}
        paginationRowsPerPageOptions={perPageChoices}
        onChangeRowsPerPage={handleRowsPerPage}
        paginationTotalRows={totalRows}
        paginationServer
        onChangePage={handlePageChange}
        progressComponent={
          <div className="p-5">
            <Wait />
          </div>
        }
      />
    </div>
  );
};

export default GreyListTable;
