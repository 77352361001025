import Honkio from '../../Honkio';

const setMerchantRegModel = (options = {}, errorHandler = {}) => {
  const { application_id, merchant_registration_model } = options;
  return Honkio().mainShop.userFetch(
    'adminappregmodelsmerchantset',
    {
      application_id,
      merchant_registration_model,
      version: 3,
      action: 'update',
    },
    errorHandler
  );
};

export default setMerchantRegModel;
