import Honkio from '../../Honkio';

const getAdminVelocityRulesList = (options = {}, errorHandler = () => {}) => {
  const { query_merchant, query_application, query_count, query_skip } =
    options;
  return Honkio().mainShop.userFetch(
    'adminvelocityrulelist',
    {
      query_merchant,
      query_application,
      query_skip,
      query_count,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getAdminVelocityRulesList;
