import Honkio from '../../../Honkio';

const sendPushToUserDevice = async function (options = {}, errorHandler = {}) {
    const { id, title, message } = options;

    return Honkio().mainShop.userFetch(
        'userdevicesendpush',
        {
            id,
            title,
            message,
            version: 3,
            action: 'update',
        },
        errorHandler
    );
};

export default sendPushToUserDevice;
