import React, { useState } from 'react';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import BackButton from '../../UI/BackButton';
import { createProductTemplate } from '../../../api/utils/templateModels/templateProducts/setProductTemplate';
import ProductTemplateForm from './ProductTemplateForm';

const ProductTemplateCreate = () => {
    const history = useHistory();
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleSubmitForm = async (submitData) => {
        const resultHandler = (data) => {
            setSubmitLoading(false);
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Updated successfully',
                    'Success',
                    3000
                );
                history.push(`/template-models/products`);
            }
        };

        const errorHandler = (error) => {
            setSubmitLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setSubmitLoading(true);
            const res = await createProductTemplate(submitData, errorHandler);
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton
                    to="/template-models/products"
                    text="Back to list"
                />
                Create product template
            </h2>

            <ProductTemplateForm
                onSubmit={handleSubmitForm}
                submitLoading={submitLoading}
            />
        </div>
    );
};

export default ProductTemplateCreate;
