import { useEffect, useState, useMemo } from 'react';
import getConsumer from '../../api/utils/consumer/getConsumer';
import { Modal, Button, Col, Image } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import DataTable from 'react-data-table-component';
import { NotificationManager } from 'react-notifications';

const UserInfoModal = ({ consumerId, show, onHide }) => {
  const [consumer, setConsumer] = useState();
  const [progress, done] = useLoader([consumerId, consumer]);
  const [accesses, setAccesses] = useState([]);

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const columns = useMemo(() => [
    {
      name: 'Merchant',
      grow: 3,
      cell: (e) => {
        return e.merchant_name;
      },
    },
    {
      name: 'Role',
      grow: 3,
      cell: (e) => {
        return e.role_name;
      },
    },
  ]);

  useEffect(() => {
    if (consumerId) {
      fetchConsumer();
    }
  }, [consumerId]);

  const fetchConsumer = async () => {
    const resultHandler = (data) => {
      if (isMounted) {
        if (data && data.status === 'accept') {
          setConsumer(data.consumer);
          setAccesses(data.accesses);
        } else {
          setConsumer([]);
          setAccesses([]);
        }
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setConsumer([]);
      setAccesses([]);
    };

    getConsumer({ id: consumerId, accesses: true }, errorHandler).then(
      (data) => {
        return resultHandler(data);
      }
    );
  };

  const container = (
    <div className="container">
      <div className="row">
        <div className="col-3">
          <img
            className="d-inline-block img-fluid m-2 p-1 shadow rounded"
            src=""
            alt="Consumer photo"
            style={{ maxHeight: '100px' }}
          />
        </div>
        <div className="col-8">
          <label className="font-weight-bold mr-2">Email</label>
          <label>{consumer?.email}</label>
          <br />
          <input
            onclick="return false"
            type="checkbox"
            checked={consumer?.active}
          />
          <label className="font-weight-bold">Active</label>
          <br />
          <label className="font-weight-bold mr-2">Name</label>
          <label>{`${consumer?.settings?.str_firstName || '-'} ${
            consumer?.settings?.str_lastName || '-'
          } `}</label>
          <br />
          <label className="font-weight-bold mr-3">Phone</label>
          <label>{consumer?.settings?.str_telephone}</label>
          <br />
          <label className="font-weight-bold mr-3">Language</label>
          <label>{consumer?.settings?.str_language}</label>
          <br />
          <label className="font-weight-bold mr-3">Timezone</label>
          <label>{consumer?.settings?.str_timezone}</label>
          <br />
          <label className="font-weight-bold mr-3">Country</label>
          <label>{consumer?.settings?.str_country}</label>
          <br />
          <label className="font-weight-bold mr-3">Address</label>
          <label>{` ${consumer?.settings?.str_zip || '-'} ${
            consumer?.settings?.str_city || '-'
          } ${consumer?.settings?.str_address1 || '-'}`}</label>
          <DataTable
            dense
            striped
            title="Accesses"
            noDataComponent="no permissions"
            highlightOnHover
            data={accesses}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>User info</Modal.Title>
      </Modal.Header>
      <Modal.Body>{consumer ? container : 'not found'}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserInfoModal;
