import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Col, Image, Table, Form } from 'react-bootstrap';
import cn from 'classnames';
import _ from 'lodash';
import UserInfoContext from '../../../contexts/UserInfoContext';
import getAdminRolesList from '../../../api/utils/role/getAdminRolesList';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import Icon from '../../UI/Icon';
import ReactTooltip from 'react-tooltip';

const RolesMapperForm = ({
    mappings = [],
    setMappings = () => {},
    label = '',
    subheader = '',
    className = '',
    onRemove = () => {},
}) => {
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchRoles();
    }, [merchantId, appId]);

    const fetchRoles = async () => {
        if (!merchantId) return;

        const resultHandler = (data) => {
            if (data && data.status === 'accept' && data.roles) {
                setRoles(
                    data.roles?.map((item) => ({
                        value: item._id,
                        label: item.name,
                    }))
                );
                setLoading(false);
            } else {
                setLoading(false);
                setRoles([]);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setRoles([]);
            setLoading(false);
        };

        try {
            const res = await getAdminRolesList(
                {
                    appid: appId,
                    merchant: merchantId,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log(
                'Unexpected error while fetching merchant roles: ',
                error
            );
        }
    };

    const handleChangeMappingKey = (id, key) => {
        const newMappings = _.cloneDeep(mappings);
        const idx = newMappings.findIndex((item) => item.id === id);
        newMappings[idx] = {
            ...newMappings[idx],
            key,
        };

        setMappings(newMappings);
    };
    const handleChangeMappingValue = (id, value) => {
        const newMappings = _.cloneDeep(mappings);
        const idx = newMappings.findIndex((item) => item.id === id);
        newMappings[idx] = {
            ...newMappings[idx],
            value,
        };
        setMappings(newMappings);
    };

    const renderRolesMapper = (mp = {}) => {
        if (mp.length) {
            return mp.map(({ id, key, value }, index) => (
                <tr key={`${value}-${key}-${id}`}>
                    <td colSpan={1}>
                        <Form.Control
                            as="select"
                            name={`settings.roles_mapper.${key}`}
                            value={key}
                            onChange={(e) =>
                                handleChangeMappingKey(id, e.target.value)
                            }
                            className={cn('custom-select', 'crsr', 'm-0')}
                        >
                            <option
                                key={`role-mapper-key-option-zero-first`}
                                value={''}
                            >
                                Select role from
                            </option>
                            {roles.map((opt, i) => (
                                <option
                                    key={`role-mapper-key-option-${i}-${opt?.value}`}
                                    value={opt?.value}
                                >
                                    {opt?.label}
                                </option>
                            ))}
                        </Form.Control>
                    </td>
                    <td colSpan={1}>
                        <Form.Group
                            controlId="merchant.settings.roles_mapper[1]"
                            className="m-0"
                        >
                            <Select
                                name={`settings.roles_mapper.[1]`}
                                value={
                                    value?.length
                                        ? roles.filter((c) =>
                                              value?.includes(c.value)
                                          )
                                        : []
                                }
                                onChange={(val) =>
                                    handleChangeMappingValue(
                                        id,
                                        val?.length
                                            ? val.map((item) => item.value)
                                            : []
                                    )
                                }
                                isMulti
                                options={roles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={'— Select roles —'}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 2,
                                    }),
                                }}
                            />
                        </Form.Group>
                    </td>
                    <td colSpan={1} className="align-middle">
                        <span
                            className={cn(
                                'd-flex',
                                'justify-content-center',
                                'align-items-center'
                            )}
                            data-tip
                            data-for="deleteTip"
                            onClick={() => {
                                onRemove(id);
                            }}
                        >
                            <Icon
                                iconName="bi bi-trash"
                                color="var(--danger)"
                            />
                            <ReactTooltip
                                id="deleteTip"
                                place="left"
                                effect="solid"
                            >
                                Delete mapping
                            </ReactTooltip>
                        </span>
                    </td>
                </tr>
            ));
        } else {
            return (
                <tr>
                    <td colSpan={2}>Not found</td>
                </tr>
            );
        }
    };

    return (
        <div className={className}>
            {label ? <h4 className="my-3">{label}</h4> : null}
            {subheader ? (
                <div className="my-3 text-muted text-small">*{subheader}</div>
            ) : null}
            <Table striped hover size="sm">
                <thead>
                    <tr>
                        <th colSpan={1} className="w-50">
                            Initial role
                        </th>
                        <th colSpan={1} className="w-50">
                            Role to add
                        </th>
                    </tr>
                </thead>
                <tbody>{renderRolesMapper(mappings)}</tbody>
            </Table>
        </div>
    );
};

export default RolesMapperForm;
