import Honkio from '../../Honkio';

const getMerchantPayments = (options, errorHandler = {}) => {
  const {
    page = 0,
    query_count = 25,
    query_skip = 0,
    query_merchant = '',
    query_keyword = '',
    query_application = '',
    query_status = '',
    query_statuses = null,
  } = options;
  return Honkio().mainShop.userFetch(
    'adminpaymentslist',
    {
      query_skip: page
        ? (+page - 1) * query_count
        : query_skip
        ? query_skip
        : 0,
      query_count,
      query_merchant,
      query_keyword,
      query_application,
      query_status,
      query_statuses,
      action: 'read',
      version: 3,
    },
    errorHandler
  );
};

export default getMerchantPayments;
