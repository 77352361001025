import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { useFormContext, useController } from 'react-hook-form';
import Icon from '../../../UI/Icon';

const FeatureItem = ({ idx, index, onDelete }) => {
  const { control } = useFormContext();

  const { field } = useController({
    name: `pricing_plans[${idx}].features[${index}].value`,
    control,
  });

  return (
    <ListGroup.Item>
      <div className="row">
        <div className="col">
          <input
            key={`${idx}-${index}`}
            className="form-control p-2"
            label="Feature label"
            defaultValue={field.value || ''}
            name={`pricing_plans[${idx}].features[${index}]`}
            onChange={field.onChange}
            onBlur={field.onBlur}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Button
            onClick={() => onDelete(index)}
            variant="outline"
            className="p-0"
          >
            <Icon iconName="bi-trash-fill" size="1" color="var(--danger)" />
          </Button>
        </div>
      </div>
    </ListGroup.Item>
  );
};

export default FeatureItem;
