import { useCallback, useEffect, useReducer, useState } from 'react';
import useSearchString from '../../utils/useSearchString';
import Editor from './Editor';
import SmartForm from '../Form/SmartForm';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import CheckboxSwitch from '../Form/elements/CheckboxSwitch';
import getScript from '../../api/utils/app/scripts/getScript';
import {
  createScript,
  updateScript,
} from '../../api/utils/app/scripts/setScript';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { useHistory } from 'react-router-dom';

const init = (initialState) => initialState;

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'update':
      return { ...state, ...payload };
    default:
      return state;
  }
};

const ScriptCreate = () => {
  const { app } = useSearchString();
  const [state, dispatch] = useReducer(
    reducer,
    {
      text: '// code',
      trigger: '',
      type: '',
    },
    init
  );
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const onSubmit = async (data) => {
    setLoading(true);

    const resultHandler = (data) => {
      NotificationManager.success('Script updated');
      const { text, name, type, active, debug, settings, trigger } =
        data.script;
      dispatch({
        type: 'update',
        payload: { text, name, type, active, debug, settings, trigger },
      });
      setLoading(false);
      history.push(`/scripts?appid=${app}`);
    };

    const errorHandler = (error) => {
      console.log('error updating script ==', error);
      NotificationManager.error(error.description, 'Error', 4000);
      setLoading(false);
    };

    const res = await createScript(
      { ...data, application_id: app },
      errorHandler
    );
    if (res && res.status === 'accept') {
      resultHandler(res);
    }
  };

  return (
    <SmartForm
      defaultValues={state}
      className="container h-100"
      onSubmit={onSubmit}
    >
      <NotificationContainer />
      <Editor name="text" />
      <fieldset className="container p-3">
        <div className="row">
          <div className="col-5">
            <InputFieldWithValidation name="name" type="text" label="Name" />
          </div>
          <div className="col-5">
            <SelectFieldWithValidation
              name="type"
              label="Type"
              options={[
                { name: 'Scheduler script', value: 'scheduler' },
                { name: 'Report', value: 'report' },
                { name: 'API event', value: 'event' },
                { name: 'Select type...', value: '' },
              ]}
            />
          </div>
          <div className="col-2">
            <div className="row row-cols-1">
              <CheckboxSwitch name="active" label="Active" />
              <CheckboxSwitch name="debug" label="Debug" />
            </div>
          </div>
          <div className="col-5">
            <SelectFieldWithValidation
              name="trigger"
              label="Script trigger"
              options={[
                { name: 'User registration', value: 'user_register' },
                { name: 'Manual', value: 'manual' },
                { name: 'Other', value: 'other' },
                { name: 'Select trigger...', value: '' },
              ]}
            />
          </div>
        </div>
      </fieldset>
      <button
        type="submit"
        className="btn btn-block btn-primary mt-3"
        disabled={loading}
      >
        Save
      </button>
    </SmartForm>
  );
};

export default ScriptCreate;
