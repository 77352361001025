import Honkio from '../../Honkio';

const merchantProductSet = async function (options, errorHandler) {
    return Honkio().mainShop.userFetch(
        'adminproductset',
        {
            ...options,
            version: 3,
        },
        errorHandler
    );
};

const merchantUpdateProduct = (product, errorHandler) => {
    return merchantProductSet(
        {
            ...product,
            action: 'update',
        },
        errorHandler
    );
};

const merchantCreateProduct = (product, errorHandler) => {
    return merchantProductSet(
        {
            ...product,
            action: 'create',
        },
        errorHandler
    );
};

const merchantDeleteProduct = (product = null, errorHandler) => {
    return merchantProductSet(
        {
            ...product,
            action: 'delete',
        },
        errorHandler
    );
};

export { merchantUpdateProduct, merchantCreateProduct, merchantDeleteProduct };
