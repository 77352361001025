import React, { Component, useState, useEffect } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import Wait from '../UI/Wait';
import HonkioAPI from '../../api/Honkio';
import { uploadFile } from '../../api/utils/files/uploadFile';

const ImageUploadModalComponent = (props) => {
    const [appInfo, setAppInfo] = useState(null);
    const [files, setFiles] = useState([]);
    const [doc_type, setDocType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [consumerInfo, setConsumerInfo] = useState(null);

    const handleChange = (files) => {
        setFiles(files);
    };

    useEffect(() => {
        const notParsedInfo = localStorage.getItem('app_merchant_info');
        const notParsedConsumer = localStorage.getItem('consumer_info');
        if (notParsedInfo) {
            setAppInfo(JSON.parse(notParsedInfo));
        }
        if (notParsedConsumer) {
            setConsumerInfo(JSON.parse(notParsedConsumer));
        }
    }, []);

    useEffect(() => {
        console.log(`** appInfo == `, appInfo);
    }, [appInfo]);

    const handleButtonUpload = () => {
        let itemsProcessed = 0;
        let allowedExtensions = ['jpg', 'jpeg', 'png'];
        console.log(`Starting upload files...`);
        files.forEach((file) => {
            console.log(`Uploading file ==`, file);
            let filename = file.name.split('.')[0];
            let filepath = file.path;
            let filetype = doc_type;

            let inArray = allowedExtensions.includes(file.name.split('.')[1]);
            if (inArray) {
                setLoading(true);

                let savefile = async function () {
                    console.log('appInfo == ', appInfo);
                    const merchant_id = appInfo?.merchant?.id;
                    const app_id = appInfo?.app?.id;
                    if (!merchant_id) {
                        NotificationManager.warning(
                            `Please select merchant first`
                        );
                        return;
                    }
                    if (!app_id) {
                        NotificationManager.warning(`Please select app first`);
                        return;
                    }

                    let message = {
                        id: consumerInfo.id,
                        file: file,
                        'content-type': 'multipart/form-data',
                        // object: APP_ID,
                        object: app_id,
                        object_type: props.objectType,
                        access: 'public',
                        file_type: 'image',
                        extension: file.name.split('.')[1],
                        display_name: filename,
                        metadata: { document_type: filetype },
                        // query_application: appInfo?.app?.id || '',
                    };
                    let url, thumb;
                    //   let r = await HonkioAPI()
                    //     .userShop.userFetch('fileupload', message)
                    const r = uploadFile(message).then(
                        (uploadFile) => {
                            url = uploadFile.file.url;
                            thumb = uploadFile.file.url;
                        },
                        (error) => {
                            NotificationManager.warning(
                                'Image upload failed',
                                `${error.description}`
                            );
                            setLoading(false);
                        }
                    );
                    itemsProcessed++;
                    NotificationManager.success(
                        `${'Image upload success'} ${filename}`,
                        'Success'
                    );
                    if (itemsProcessed === files.length) {
                        setLoading(false);
                        props.onMediaUploaded();
                    }
                };
                savefile();
            } else {
                itemsProcessed++;
                NotificationManager.warning(
                    `Unsupported extension ${filename}`,
                    'Image upload failed'
                );
                if (itemsProcessed === files.length) {
                    setLoading(false);
                    props.onMediaUploaded();
                }
            }
        });
    };

    const handleChangeType = (e = null) => {
        setDocType(e.value);
    };

    const options = [
        {
            value: 'image',
            label: 'Image',
        },
    ];

    return (
        <Modal show={props.visible} onHide={props.toggle}>
            <Modal.Header closeButton>
                <Modal.Title>Upload image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DropzoneArea
                    dropzoneText={'Drag and drop the image here or click'}
                    onChange={handleChange}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    maxFileSize={100000000}
                    useChipsForPreview
                    filesLimit={10}
                    previewGridProps={{
                        container: { spacing: 1, direction: 'row' },
                    }}
                    previewText="Selected image files"
                    showAlerts={false}
                    styles={{
                        'MuiDropzoneArea-root': {
                            maxHeight: 150,
                            minHeight: 150,
                        },
                    }}
                />
                <hr />
                <Select
                    options={options}
                    defaultValue={{
                        value: -1,
                        label: 'Please select image type',
                    }}
                    onChange={(e) => {
                        handleChangeType(e);
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleButtonUpload}>
                    Save
                </Button>
                <Button variant="secondary" onClick={props.toggle}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImageUploadModalComponent;
