import React, { useContext, useEffect, useState } from 'react';
import { authUser } from '../../auth/authUser';
import { Redirect, useHistory } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import useSearchString from '../../utils/useSearchString';
import Wait from '../UI/Wait';
import { REDIRECT_URL } from '../../config';
import { Container, Button, Form } from 'react-bootstrap';
import getRolesInfo from '../../api/utils/role/getRolesInfo';
import Select, { components } from 'react-select';
import cn from 'classnames';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import _ from 'lodash';

const GroupHeading = (props) => {
    return (
        <div>
            <components.GroupHeading {...props} onClick={() => {}}>
                {props?.data?.name || 'Unknown application'}
            </components.GroupHeading>
        </div>
    );
};

const CustomOption = (props) => {
    return (
        <>
            <components.Option {...props}>{props.children}</components.Option>
        </>
    );
};

const UserAppAndRole = () => {
    const history = useHistory();

    const [authStatus, setAuthStatus] = useState(false);
    const { code } = useSearchString();
    const { signIn } = useContext(AuthContext);

    const [userRoles, setUserRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);

    const [selectedApplication, setSelectedApplication] = useState({
        name: '',
        classname: '',
        id: '',
    });

    const [loadingRoles, setLoadingRoles] = useState(false);

    useEffect(() => {
        authUser(code).then(setAuthStatus);
    }, [code]);

    const generateRolesOptions = (roles = []) => {
        const grouped = _.groupBy(roles, 'application_name');
        const res = Object.keys(grouped).map((item) => ({
            name: item,
            options: grouped[item],
        }));

        return res;
    };

    // Use it in HonkioAPI
    const fetchUserRoles = async () => {
        setLoadingRoles(true);

        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                const opts = generateRolesOptions(data.roles);

                setUserRoles(opts);
                setLoadingRoles(false);

                // Auto-select the only role
                if (data.roles.length === 1) {
                    const theRole = data.roles[0];
                    setSelectedRole(theRole);
                    setSelectedApplication({
                        id: theRole.application || '',
                        name: theRole.application_name || 'Unknown app',
                        classname: theRole.application_classname || '',
                    });
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setUserRoles([]);
            setLoadingRoles(false);
        };

        try {
            const res = await getRolesInfo(
                { query_all: true, query_info: true },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        if (authStatus) fetchUserRoles();
    }, [authStatus]);

    const handleSelectRoleAndApp = (val) => {
        setSelectedApplication({
            id: val?.application || '',
            name: val?.application_name || 'Unknown app',
            classname: val?.application_classname || '',
        });

        if (!val?.application_classname)
            NotificationManager.error(
                'Application has no classname. Contact administrator',
                'Error',
                5000
            );

        setSelectedRole(val);
    };

    const handleSubmitRoleAndAppSelection = (e) => {
        e.preventDefault();

        if (!authStatus) {
            console.log('authStatus is false, returning...');
            return;
        }

        localStorage.setItem('role', selectedRole.id);
        localStorage.setItem('is_authorized', true);
        localStorage.setItem('app_classname', selectedApplication.classname);

        // Check if superadmin
        if (
            selectedApplication.classname === 'server' &&
            selectedRole.name === 'superadmin'
        ) {
            localStorage.setItem('is_superadmin', true);
        }

        window.dispatchEvent(new Event('role_is_set'));
        signIn();

        history.push(REDIRECT_URL);
    };

    return (
        <Container className={cn('h-100', 'w-100')}>
            <NotificationContainer />

            <Form
                onSubmit={handleSubmitRoleAndAppSelection}
                className={cn(
                    'd-flex',
                    'flex-column',
                    'justify-content-center',
                    'align-items-center',
                    'h-100',
                    'w-100'
                )}
            >
                <Form.Group className="mb-3" controlId="apps.roles">
                    <h2>{selectedApplication.name}</h2>
                </Form.Group>

                <Form.Group className="mb-3" controlId="apps.roles">
                    <Form.Label>Choose one of your roles</Form.Label>
                    <Select
                        id="action-type-select"
                        value={selectedRole}
                        onChange={handleSelectRoleAndApp}
                        options={userRoles}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={'— Select Application and Role —'}
                        menuPortalTarget={document.body}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isLoading={loadingRoles}
                        required
                        components={{
                            GroupHeading: GroupHeading,
                            Option: CustomOption,
                        }}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 2 }),
                            groupHeading: (base) => ({
                                ...base,
                                flex: '1 0',
                                flexWrap: 'nowrap',
                                color: 'var(--white)',
                                backgroundColor: 'var(--info)',
                                margin: 0,
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                padding: '0.5rem',
                                zIndex: 1000,
                            }),
                            option: (styles, state) => ({
                                ...styles,
                                backgroundColor: state.isSelected
                                    ? 'var(--primary)'
                                    : 'inherit',
                                cursor: 'pointer',
                            }),
                            control: (styles) => ({
                                ...styles,
                                cursor: 'pointer',
                                minWidth: '25rem',
                            }),
                        }}
                    />
                    <Form.Text className="text-muted">
                        Automatically selects application
                    </Form.Text>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={loadingRoles}>
                    Continue
                </Button>
            </Form>
        </Container>
    );
};

export default UserAppAndRole;
