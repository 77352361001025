const prepareInfo = (id, formData) => {
  const { name, description, accessibility, fields, id_lists } = formData;
  const properties = fields
    ? fields.map((e) => {
        return {
          property_name: e.name.toLowerCase().split(' ').join('_'),
          property_description: e.description,
          property_required: e.required == 'yes' ? true : false,
          property_type: e.type,
        };
      })
    : [];
  if (id) {
    const assetType = {
      _id: id,
      name,
      description,
      accessibility,
      properties,
      id_lists,
    };
    return { ...assetType };
  } else {
    const assetType = {
      name,
      description,
      accessibility,
      properties,
      id_lists,
    };
    return { ...assetType };
  }
};

export default prepareInfo;
