import React, { useContext, useEffect, useState } from 'react';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import LoadingBar from '../UI/LoadingBar';
import useLoader from '../UI/helpers/useLoader';
import getAppInfo from '../../api/utils/app/getAppInfo';
import UserInfoContext from '../../contexts/UserInfoContext';
import getMerchantData from '../../api/utils/merchant/getMerchantData';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';

const APP_SECRETS_FIELDS = [
    '_id',
    'settings.str_password',
    'settings.str_oauth_id',
    'settings.str_oauth_secret',
    'classname',
];
const APP_SECRETS_ALIASES = {
    _id: 'ID',
    'settings.str_password': 'API key',
    'settings.str_oauth_id': 'Client ID',
    'settings.str_oauth_secret': 'Client secret',
    classname: 'Application class',
};
const APP_SECRETS_RENDERERS = {
    //   classname: (value) => `${value};web;2.0`,
};

const MERCHANT_SECRETS_FIELDS = ['_id', 'api_key'];
const MERCHANT_SECRETS_ALIASES = { _id: 'ID', api_key: 'API key' };
const MERCHANT_SECRETS_RENDERERS = {};

const AppSecrets = () => {
    const userInfo = useContext(UserInfoContext);
    const [loading, setLoading] = useState(false);
    const [application, setApplication] = useState(null);
    const [merchant, setMerchant] = useState(null);
    const [appSecrets, setAppSecrets] = useState([]);
    const [merchantSecrets, setMerchantSecrets] = useState([]);
    const [progress, done] = useLoader([
        application,
        merchant,
        appSecrets,
        merchantSecrets,
    ]);

    useEffect(() => {
        console.log('userInfo ==', userInfo);
        if (userInfo) {
            const appId = userInfo?.app?.id;
            const merchantId = userInfo?.merchant?.id;
            if (appId) fetchAppInfo(appId);
            if (merchantId) fetchMerchant(merchantId);
        }
    }, [userInfo]);

    const fetchAppInfo = async (appId) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setApplication(data.application);
            } else {
                setApplication(null);
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setApplication(null);
        };

        if (appId) {
            getAppInfo({ appid: appId }, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setApplication(null);
        }
    };

    const fetchMerchant = async (merchantId) => {
        const resultHandler = (data) => {
            if (data && data?.status === 'accept' && data?.merchant) {
                setMerchant(data?.merchant);
            } else {
                setMerchant(null);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setMerchant(null);
        };

        getMerchantData({ id: merchantId }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    useEffect(() => {
        if (application?._id && merchant?._id) {
            // Generate secrets list
            const genAppSecrets = APP_SECRETS_FIELDS.map((item) => ({
                name: item,
                value: _.get(application, item),
            }));
            const genMerchantSecrets = MERCHANT_SECRETS_FIELDS.map((item) => ({
                name: item,
                value: _.get(merchant, item),
            }));
            setAppSecrets(genAppSecrets);
            setMerchantSecrets(genMerchantSecrets);
        }
    }, [application, merchant]);

    const handleCopyToClipboard = (val) => {
        navigator.clipboard.writeText(val);
        NotificationManager.success('Copied to clipboard!');
    };

    const renderField = (name, value) => {
        const itemId = uuidv4();
        return (
            <tr key={`secrets-${name}-${itemId}`}>
                <td>{name}</td>
                <td>
                    <div className="d-flex justify-content-between align-items-center">
                        <span>{value}</span>
                        <span
                            data-tip
                            data-for={`secrets-${name}-${itemId}`}
                            onClick={() => handleCopyToClipboard(value)}
                        >
                            <Icon iconName="bi-clipboard" size="1" />
                        </span>
                        <ReactTooltip
                            id={`secrets-${name}-${itemId}`}
                            place="top"
                            effect="solid"
                        >
                            Copy to clipboard
                        </ReactTooltip>
                    </div>
                </td>
            </tr>
        );
    };

    const renderSecrets = (secrets = [], aliases = {}) => {
        return secrets.map((item, idx) => {
            const valRenderer = _.get(APP_SECRETS_RENDERERS, item?.name);
            let val = item?.value;
            if (valRenderer) {
                val = valRenderer(val);
            }
            return (
                <tr key={`secrets-${item?.name}-${idx}`}>
                    <td>{_.get(aliases, item?.name) || item?.name}</td>
                    <td>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>{val}</span>
                            <span
                                data-tip
                                data-for={`secrets-${item?.name}-${idx}`}
                                onClick={() => handleCopyToClipboard(val)}
                            >
                                <Icon iconName="bi-clipboard" size="1" />
                            </span>
                            <ReactTooltip
                                id={`secrets-${item?.name}-${idx}`}
                                place="top"
                                effect="solid"
                            >
                                Copy to clipboard
                            </ReactTooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                Application secrets{' '}
                {!application ? 'Select application!' : null}{' '}
                {!merchant ? 'Select merchant!' : null}
            </h2>
            {done ? (
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>Secret</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                <h3>Application fields</h3>
                            </th>
                        </tr>
                        {renderSecrets(appSecrets, APP_SECRETS_ALIASES)}
                        <tr>
                            <th colSpan={2}>
                                <h3>
                                    Merchant fields{' '}
                                    {merchant?.is_admin ? (
                                        <>
                                            <span
                                                data-tip
                                                data-for={`isAdminTip`}
                                            >
                                                (App admin)
                                            </span>
                                            <ReactTooltip
                                                id={`isAdminTip`}
                                                place="top"
                                                effect="solid"
                                            >
                                                This is application admin
                                                merchant. `api_key` can be used
                                                as `admin_api_key` request
                                                parameter
                                            </ReactTooltip>
                                        </>
                                    ) : null}
                                </h3>
                            </th>
                        </tr>
                        {renderSecrets(
                            merchantSecrets,
                            MERCHANT_SECRETS_ALIASES
                        )}
                        <tr>
                            <th colSpan={2}>
                                <h3>API request parameters</h3>
                            </th>
                        </tr>
                        {renderField('version', '3')}
                        {renderField('command', 'URL without hostname')}
                        {renderField(
                            'identity_client',
                            `${application.classname};web;2.0`
                        )}
                        {renderField(
                            'action',
                            'create | read | update | delete'
                        )}
                        {renderField('role', '<user role _id>')}
                        {renderField(
                            'access_token',
                            '<token returned after authentication>'
                        )}
                    </tbody>
                </Table>
            ) : (
                <LoadingBar progress={progress} />
            )}
        </div>
    );
};

export default AppSecrets;
