import Honkio from '../../Honkio';

const getOrderModel = (options = {}, errorHandler = () => {}) => {
    const { id } = options;
    return Honkio().mainShop.userFetch(
        'adminordermodelget',
        {
            id,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};

const getMerchantOrderProcessList = (options = {}, errorHandler = () => {}) => {
    const { merchantId, appid, debug, order_type } = options;
    return Honkio().mainShop.userFetch(
        'adminordermodellist',
        {
            debug,
            merchant: merchantId,
            appid: appid,
            query_order_type: order_type,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};

export { getMerchantOrderProcessList, getOrderModel };
