import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Icon from '../UI/Icon';
import Wait from '../UI/Wait';

const PaymentTable = ({
    paymentList = [],
    loading,
    emptyText,
    onDeleteClick = () => {},
}) => {
    const columns = useMemo(
        () => [
            { name: '#', grow: 0, cell: (_, index) => index + 1 },
            { name: 'Title', grow: 1, cell: ({ title }) => title },
            {
                name: 'Actions',
                grow: 0,
                cell: ({ _id }) => (
                    <div>
                        <Link
                            to={`/payment/edit?payment_providerId=${_id}`}
                            data-tip
                            data-for="editTip"
                        >
                            <Icon iconName="bi-pen" />
                            <ReactTooltip
                                id="editTip"
                                place="top"
                                effect="solid"
                            >
                                Edit
                            </ReactTooltip>
                        </Link>

                        <span
                            data-tip
                            data-for="deleteTip"
                            onClick={() => {
                                onDeleteClick(_id);
                            }}
                        >
                            <Icon iconName="bi bi-trash" />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </span>
                    </div>
                ),
            },
        ],
        []
    );

    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={paymentList}
            columns={columns}
            progressPending={loading}
            emptyText={emptyText}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default PaymentTable;
