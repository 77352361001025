import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import DataTable from 'react-data-table-component';
import { useMemo } from 'react';
import Icon from '../../UI/Icon';
import Wait from '../../UI/Wait';
import ReactTooltip from 'react-tooltip';

const FilesTable = ({
    user,
    data,
    loading,
    onRowClicked,
    totalRows,
    rowsCountPerPage,
    handleRowsPerPage = () => {},
    onDeleteClick = () => {},
    perPageChoices = [25],
    handlePageChange = () => {},
    conditionalRowStyles = {},
}) => {
    const columns = useMemo(
        () => [
            {
                name: 'ID',
                grow: 1,
                cell: ({ _id }, index) => _id,
            },
            {
                name: 'Name',
                grow: 1,
                cell: ({ display_name }) => display_name,
            },
            {
                name: 'Action',
                grow: 1,
                right: true,
                cell: ({ _id }) => (
                    <div>
                        <Link
                            to={`/user/document/view?user_id=${user?._id}&id=${_id}`}
                            data-tip
                            data-for="viewTip"
                        >
                            <Icon iconName="bi-info-circle" />
                            <ReactTooltip
                                id="viewTip"
                                place="top"
                                effect="solid"
                            >
                                View
                            </ReactTooltip>
                        </Link>
                    </div>
                ),
            },
        ],
        [user]
    );
    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            onChangeRowsPerPage={handleRowsPerPage}
            paginationServer
            progressPending={loading}
            onRowClicked={onRowClicked}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            conditionalRowStyles={conditionalRowStyles}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default FilesTable;
