import HonkioAPI from '../../../Honkio';

/**
 *
 * @param {*} options - {object: string, type: string }
 * @param {*} options.query_skip pagination skip documents count
 * @param {*} options.query_count pagination returned number of documents
 * @param {*} options.query_sort_field - field to sort by
 * @param {*} options.query_sort_direction - -1 for ascending, 1 - for descending order
 * @param {*} options.query_merchant - merchant._id value
 * @param {*} options.query_application - application._id value
 * @param {*} errorHandler - callback function, triggered on error in the response
 * @returns Promise
 */
export const getCustomerTypesList = (options = {}, errorHandler = () => {}) => {
    const {
        query_merchant,
        query_application,
        query_sort_direction,
        query_sort_field,
        query_skip,
        query_count,
    } = options;
    return HonkioAPI().mainShop.userFetch(
        'admincustomertypelist',
        {
            query_merchant,
            query_application,
            query_sort_direction,
            query_sort_field,
            query_skip,
            query_count,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
