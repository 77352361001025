const defaultNode = (id) => ({
  id: id,
  data: {
    label: id,
    settings: {
      capture: [],
      auth: [],
      chargeback: [],
    },
    options: {
      edit_products: [],
      edit_amount: [],
      reserve: [],
      public: [],
      sales_point: [],
      send_push: [],
      send_message: {},
      bundled_products: [],
    },
  },
  position: {
    x: 0,
    y: 0,
  },
});

export default defaultNode;
