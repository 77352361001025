import React, { Component } from 'react';
import WidgetComponent from './WidgetComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Index, TimeRange, TimeSeries } from 'pondjs';
import {
  Baseline,
  ChartContainer,
  ChartRow,
  Charts,
  EventMarker,
  LineChart,
  ScatterChart,
  styler,
  YAxis,
} from 'react-timeseries-charts';
import moment from 'moment';
import 'moment/locale/fi';
import HonkioAPI from '../../../api/Honkio';

const baselineStyle = {
  line: {
    stroke: 'gray',
    strokeWidth: 1,
  },
};

class HumidityWidgetComponent extends Component {
  constructor(props) {
    super(props);
    this.refreshData = this.refreshData.bind(this);
    this.state = {
      loading: true,
      timerange: new TimeRange(
        moment(this.props.start_date).toDate(),
        moment(this.props.end_date).toDate()
      ),
    };
  }

  componentDidMount() {
    this.refreshData();
    let that = this;
    this.setContainerRef = (element) => {
      that.container = element;
    };
  }

  handleTrackerChanged = (t) => {
    if (t) {
      const e = this.state.humiditySeries.atTime(t);
      const eventTime = new Date(
        e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
      );
      const tempValue = e.get('temperature');
      const humidityValue = e.get('humidity');
      // const v = `${eventValue > 0 ? "+" : ""}${eventValue}°C`;
      const v = `${parseFloat(humidityValue).toFixed(2)}% - ${
        tempValue > 0 ? '+' : ''
      }${parseFloat(tempValue).toFixed(2)}°C at ${moment(eventTime).format(
        'YYYY-MM-DD hh:mm:ss'
      )}`;
      this.setState({ tracker: eventTime, trackerValue: v, trackerEvent: e });
    } else {
      this.setState({ tracker: null, trackerValue: null, trackerEvent: null });
    }
  };
  renderMarker = () => {
    if (this.state.markerMode === 'flag') {
      return (
        <EventMarker
          type="flag"
          axis="axis"
          event={this.state.trackerEvent}
          column="temperature"
          info={[{ label: 'Anomaly', value: this.state.trackerValue }]}
          infoTimeFormat="%Y"
          infoWidth={120}
          markerRadius={2}
          markerStyle={{ fill: 'black' }}
        />
      );
    } else {
      return (
        <EventMarker
          type="point"
          axis="axis"
          event={this.state.trackerEvent}
          column="temperature"
          markerLabel={this.state.trackerValue}
          markerLabelAlign="left"
          markerLabelStyle={{ fill: '#1f2c82', stroke: 'white' }}
          markerRadius={3}
          markerStyle={{ fill: 'black' }}
        />
      );
    }
  };

  refreshData(loader) {
    let that = this;
    if (loader) {
      that.setState({ loading: true });
    }
    let load = async function () {
      let query = {
        // query_merchant: localStorage.getItem('merchant_id'),
        query_asset: that.props.assetId,
        query_aggregate: ['VALUE', 'TEMPERATURE_VALUE'],
      };
      if (that.props.start_date) {
        query['query_start_date'] = moment(that.state.timerange.begin()).format(
          'YYYY-MM-DD hh:mm:ss'
        );
      }
      if (that.props.end_date) {
        query['query_end_date'] = moment(that.state.timerange.end()).format(
          'YYYY-MM-DD hh:mm:ss'
        );
      }
      let response = await HonkioAPI().userShop.userFetch(
        'merchantasseteventlist',
        query
      );
      let humidityMin = 0,
        humidityMax = 0,
        humidityAvg = 0,
        tempMin = 0,
        tempMax = 0,
        tempAvg = 0;
      let points = [];
      response.events.forEach((val) => {
        const index = Index.getIndexString(
          '1m',
          moment(val.timestamp).add(moment().utcOffset(), 'minutes').toDate()
        );
        const humidity = parseFloat(val.VALUE);
        const temperature = parseFloat(val.TEMPERATURE_VALUE);
        // const fiveyear = val.fiveyr;
        points.push([index, humidity, temperature]);
        if (humidity > humidityMax) humidityMax = humidity;
        if (humidity < humidityMin) humidityMin = humidity;
        humidityAvg += humidity;
        if (temperature > tempMax) tempMax = temperature;
        if (temperature < tempMin) tempMin = temperature;
        humidityAvg += temperature;
      });
      humidityAvg = parseFloat(humidityAvg / response.events.length);
      if (response.events.length > 0) {
        const humiditySeries = new TimeSeries({
          name: 'Temperature',
          columns: ['index', 'humidity', 'temperature'],
          points: points,
        });
        that.setState({
          events: response.events,
          humiditySeries: humiditySeries,
          timerange: humiditySeries.timerange(),
          tempMin: parseInt(tempMin * 1.1),
          tempMax: parseInt(tempMax * 1.1),
          tempAvg: tempAvg,
          humidityMin: parseInt(humidityMin * 1.1),
          humidityMax: parseInt(humidityMax * 1.1),
          humidityAvg: humidityAvg,
          loading: false,
        });
      } else {
        that.setState({ loading: false, humiditySeries: null });
      }
    };
    load();
  }

  handleTimeRangeChange = (timerange) => {
    let that = this;
    if (this.state.zooming) {
      clearTimeout(this.state.zooming);
    }
    this.setState({
      timerange,
      zooming: setTimeout(function () {
        that.refreshData();
      }, 200),
    });
  };

  render() {
    const points = [];
    let line;
    let summary;
    let drawChart = (height, width) => {
      const style = styler([
        { key: 'temperature', color: '#444', width: 1 },
        { key: 'humidity', color: '#3498DB', width: 2 },
      ]);
      const axisStyle = {
        values: {
          labelColor: 'grey',
          labelWeight: 100,
          labelSize: 11,
        },
        axis: {
          axisColor: 'grey',
          axisWidth: 1,
        },
      };
      return this.state.humiditySeries ? (
        <ChartContainer
          timeRange={this.state.timerange}
          timeAxisStyle={axisStyle}
          maxTime={moment(this.props.end_date).toDate()}
          minTime={moment(this.props.start_date).toDate()}
          enablePanZoom={true}
          onTimeRangeChanged={this.handleTimeRangeChange}
          onTrackerChanged={this.handleTrackerChanged}
          width={width}
          height={height}
          padding={10}
        >
          <ChartRow
            // height={this.props.height * ROW_HEIGHT - 75}
            // width={this.props.width * 100 }
            height={height}
            width={width}
            // width={this.container.offsetWidth}
          >
            <YAxis
              id="humidity"
              label="Humidity"
              transition={300}
              style={axisStyle}
              labelOffset={0}
              min={this.state.humidityMin}
              max={this.state.humidityMax}
              format=",.1f"
              width="60"
              type="linear"
            />
            <Charts>
              <LineChart
                axis="humidity"
                series={this.state.humiditySeries}
                columns={['humidity']}
                style={style}
              />

              <ScatterChart
                axis="axis"
                series={this.state.humiditySeries}
                columns={['temperature']}
                style={style}
              />

              <LineChart
                axis="axis"
                series={this.state.humiditySeries}
                columns={['temperature']}
                style={style}
                interpolation="curveBasis"
              />

              <Baseline
                axis="axis"
                value={0.0}
                label=""
                style={baselineStyle}
              />

              {this.renderMarker()}
            </Charts>
            <YAxis
              id="axis"
              label="Temperature"
              transition={300}
              style={axisStyle}
              labelOffset={0}
              min={this.state.tempMin}
              max={this.state.tempMax}
              format=",.1f"
              width="60"
              type="linear"
            />
          </ChartRow>
        </ChartContainer>
      ) : (
        <span className="justify-content-center align-self-center">
          No data
        </span>
      );
    };
    if (!this.state.loading) {
      line = this.state.humiditySeries
        ? (summary = (
            <div className="d-flex justify-content-center">
              <div className="w-100 h-100">
                <div className="text-center">
                  <span>10</span>
                  <br />
                  <span className="text-muted">Minutes ago</span>
                </div>
                <hr />
                <div className="text-center">
                  <span>
                    <span className="text-muted">Max</span>
                    <b>{parseFloat(this.state.humidityMax).toFixed(1)}%</b>
                  </span>
                  <br />
                  <span>
                    <span className="text-muted">Avg</span>
                    <b>{parseFloat(this.state.humidityAvg).toFixed(1)}%</b>
                  </span>
                  <br />
                  <span>
                    <span className="text-muted">Min</span>
                    <b>{parseFloat(this.state.humidityMin).toFixed(1)}%</b>
                  </span>
                  <br />
                </div>
              </div>
            </div>
          ))
        : '';
    }
    return (
      <WidgetComponent
        position={this.props.position}
        height={this.props.height}
        width={this.props.width}
        icon="fas fa-tint fa-2x"
        name={this.props.name}
        onRename={this.props.onRename}
        type="Humidity"
        summary1={summary}
        onRemoveClick={this.props.onRemoveClick}
        chart={
          this.state.loading ? () => <CircularProgress size={35} /> : drawChart
        }
      />
    );
  }
}

export default HumidityWidgetComponent;
