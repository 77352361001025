import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import AssetTypeEditForm from './AssetTypeEditForm';
import { createAssetType } from '../../api/utils/asset/type/setAssetType';
import prepareToPublish from './helpers/prepareToPublish';

const AssetTypeCreate = () => {
  const history = useHistory();
  const { app, merchant } = useContext(UserInfoContext);
  const merchantId = merchant?.id;
  const appId = app?.id;

  const [assetType, setAssetType] = useState({
    merchant: merchantId ? merchantId : null,
    name: 'In new test assetType',
    application: appId,
    accessibility: 'private',
  });

  const onCreate = (assetType) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Saved');
        history.goBack();
      } else {
        NotificationManager.error('Error');
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    createAssetType(
      merchantId,
      appId,
      { ...prepareToPublish(null, assetType) },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const pageTitle = `New asset type`;
  return appId ? (
    <div>
      <NotificationContainer />
      <h2>{pageTitle}</h2>
      <AssetTypeEditForm onSubmit={onCreate} defaultValues={assetType} />
    </div>
  ) : (
    ' application not selected'
  );
};

export default AssetTypeCreate;
