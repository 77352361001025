import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Icon from '../UI/Icon';
import DataTable from 'react-data-table-component';
import { useLayoutEffect, useMemo, useState } from 'react';
import Wait from '../UI/Wait';
import ReactTooltip from 'react-tooltip';

const colsToSearch = [
    'str_firstname',
    'str_lastname',
    'email',
    'serial_number',
];
const typedCol = 'active';

const UsersTable = ({
    data,
    filterSearch = (i) => i,
    filterType = (i) => i,
    keyword,
    type,
    loading,
    onRowClicked,
    totalRows,
    rowsCountPerPage,
    handleRowsPerPage = () => {},
    onDeleteClick = () => {},
    perPageChoices = [25],
    handlePageChange = () => {},
}) => {
    const columns = useMemo(
        () => [
            {
                name: 'Serial',
                grow: 0,
                cell: ({ serial_number }, index) => serial_number,
            },
            {
                name: 'First name',
                grow: 1,
                cell: ({ str_firstname: firstname }) => firstname,
            },
            {
                name: 'Last name',
                grow: 1,
                cell: ({ str_lastname: lastname }) => lastname,
            },
            { name: 'Email', grow: 1, cell: ({ email }) => email ?? 'n/a' },
            {
                name: 'Active',
                grow: 0,
                cell: ({ active }) => (active === 'true' ? 'yes' : 'no'),
            },
            {
                name: 'Action',
                grow: 1,
                cell: ({ id, serial_number }) => (
                    <div>
                        <Link
                            to={`/user/edit?id=${id}`}
                            data-tip
                            data-for="editTip"
                        >
                            <Icon iconName="bi-pencil" />
                            <ReactTooltip
                                id="editTip"
                                place="top"
                                effect="solid"
                            >
                                Edit
                            </ReactTooltip>
                        </Link>
                        <Link
                            to={`/user/transactions?serial=${
                                serial_number ? serial_number : ''
                            }`}
                            data-tip
                            data-for="transactionsTip"
                        >
                            <Icon iconName="bi-card-checklist" />
                            <ReactTooltip
                                id="transactionsTip"
                                place="top"
                                effect="solid"
                            >
                                Transactions
                            </ReactTooltip>
                        </Link>
                        {/* <Link
                            to={`/assets?user_id=${id}`}
                            data-tip
                            data-for="userAssets"
                        >
                            <Icon iconName="bi-list-stars" />
                            <ReactTooltip
                                id="userAssets"
                                place="top"
                                effect="solid"
                            >
                                User assets
                            </ReactTooltip>
                        </Link> */}
                        <Link
                            to={`/user/details?user_id=${id}`}
                            data-tip
                            data-for="userDetails"
                        >
                            <Icon iconName="bi-list-stars" />
                            <ReactTooltip
                                id="userDetails"
                                place="top"
                                effect="solid"
                            >
                                User Details
                            </ReactTooltip>
                        </Link>
                        <Link
                            to={`/user/documents?id=${id}`}
                            data-tip
                            data-for="documentsTip"
                        >
                            <Icon iconName="bi-archive" />
                            <ReactTooltip
                                id="documentsTip"
                                place="top"
                                effect="solid"
                            >
                                Documents
                            </ReactTooltip>
                        </Link>
                        <span
                            data-tip
                            data-for="deleteTip"
                            onClick={() => {
                                onDeleteClick(id);
                            }}
                        >
                            <Icon iconName="bi bi-trash" />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </span>
                    </div>
                ),
            },
        ],
        []
    );
    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            onChangeRowsPerPage={handleRowsPerPage}
            paginationServer
            progressPending={loading}
            onRowClicked={onRowClicked}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default UsersTable;
