import { Controller, useFormContext } from 'react-hook-form';

const InputFieldArray = ({ label, className, name, defaultValue }) => {
  const { control } = useFormContext();
  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <Controller
        render={(props) => <input className="form-control ml-2" {...props} />}
        defaultValue={defaultValue}
        name={name}
        control={control}
      />
    </div>
  );
};

export default InputFieldArray;
