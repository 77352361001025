const ExpressTable = ({ fields, data, label }) => {
  if (!(fields && data)) return null;
  return (
    <div>
      <h3>{label}</h3>
      <table className="table">
        <thead>
          <tr>
            {fields.map((field, index) => {
              const label = Array.isArray(field)
                ? field[1]
                  ? field[1]
                  : field[0]
                : field;
              return <th key={index}>{label}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {fields.map((field, index) => {
              const key = Array.isArray(field) ? field[0] : field;
              return (
                <td key={index}>
                  {data[key] ? data[key].toString() : 'No data'}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ExpressTable;
