import { useContext } from 'react';
import { useUpdateNodeInternals } from 'react-flow-renderer';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { IconButton, useTheme } from '@mui/material';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import InfoIcon from '@material-ui/icons/Info';
import CustomHandle from './CustomHandle';
import UBOContext from '../../../../contexts/UBOGraphContext';

const RootNode = ({ id, data, handleShowNodeInfo, selected }) => {
  const { NODE_HEIGHT, NODE_WIDTH } = useContext(UBOContext);

  return (
    <Box sx={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
        }}
      >
        <Paper
          elevation={0}
          variant="elevation"
          sx={{
            padding: (theme) => theme.spacing(1),
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
            borderRadius: (theme) => theme.shape.borderRadius,
            boxShadow: selected ? 10 : 1,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <WorkOutlineIcon />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <IconButton
              aria-label="add"
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
              }}
              onClick={() => handleShowNodeInfo(data, 'root')}
            >
              <InfoIcon />
            </IconButton>
          </Box>
        </Paper>
      </Box>

      <CustomHandle type="source" />
    </Box>
  );
};

export default RootNode;
