const prepareInfo = (id, formData) => {
  const {
    email,
    str_address1,
    str_address2,
    str_country,
    str_city,
    str_zip,
    str_firstname,
    str_language,
    str_lastname,
    str_telephone,
    str_timezone,
    str_ssn,
    str_photo_data,
  } = formData;
  const result = {
    id,
    email,
    settings: {
      str_address1,
      str_address2,
      str_country,
      str_city,
      str_zip,
      str_firstname,
      str_language,
      str_lastname,
      str_telephone,
      str_timezone,
      str_ssn,
      str_photo_data,
    },
  };
  return result;
};

export default prepareInfo;
