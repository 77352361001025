const modelNames = [
  { value: 'access_rules', name: 'Access rules' },
  { value: 'applicant', name: 'Applicant' },
  { value: 'application', name: 'Application' },
  { value: 'asset', name: 'Asset' },
  { value: 'asset_group', name: 'Asset group' },
  { value: 'asset_notification', name: 'Asset notification' },
  { value: 'asset_structure', name: 'Asset structure' },
  { value: 'asset_type', name: 'Asset type' },
  { value: 'building_data', name: 'Building data' },
  { value: 'chat_message', name: 'Chat message' },
  { value: 'chat_room', name: 'Chat room' },
  { value: 'country', name: 'country' },
  { value: 'currency_exchange', name: 'Currency exchange' },
  { value: 'event', name: 'Event' },
  { value: 'file', name: 'File' },
  { value: 'global_settings', name: 'Global settings' },
  { value: 'greylist', name: 'Greylist' },
  { value: 'invite', name: 'Invite' },
  { value: 'lostpassword', name: 'Lostpassword' },
  { value: 'merchant', name: 'Merchant' },
  { value: 'merchant_app_clearance', name: 'Merchant app clearance' },
  {
    value: 'merchant_register_app_settings',
    name: 'Merchant reg app settings',
  },
  { value: 'merchantinvoice', name: 'Merchant invoice' },
  { value: 'merchantsubscription', name: 'Merchant subscription' },
  { value: 'notification', name: 'Notification' },
  { value: 'notifymessage', name: 'Notifymessage' },
  { value: 'oauth_theme', name: 'Oauth theme' },
  { value: 'order', name: 'Order' },
  { value: 'ordermodel', name: 'Order model' },
  { value: 'poll', name: 'Poll' },
  { value: 'poll_results', name: 'Poll results' },
  { value: 'product', name: 'Product' },
  { value: 'producttags', name: 'Product tags' },
  { value: 'producttype', name: 'Product type' },
  { value: 'report_template', name: 'Report template' },
  { value: 'reservation', name: 'Reservation' },
  { value: 'role', name: 'Role' },
  { value: 'script', name: 'Script' },
  { value: 'service_key', name: 'Service key' },
  { value: 'session', name: 'Session' },
  { value: 'settings', name: 'Settings' },
  { value: 'shop', name: 'Shop' },
  { value: 'tags', name: 'Tags' },
  { value: 'terms_of_use', name: 'Terms of use' },
  { value: 'transaction', name: 'Transaction' },
  { value: 'user', name: 'User' },
  { value: 'user_device', name: 'User device' },
  { value: 'user_group', name: 'User group' },
  { value: 'user_role', name: 'User role' },
  { value: 'user_settings', name: 'User settings' },
  { value: 'velocity_rule', name: 'Velocity rule' },
];

export default modelNames;
