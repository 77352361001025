import React, { useState, useEffect } from 'react';
import useSearchString from '../../../utils/useSearchString';
import getStatusesHistory from '../../../api/utils/order/status/getStatusesHistory';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import StatusesTable from './StatusesTable';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const StatusesHistory = () => {
    const { order_id } = useSearchString();

    const [statusesHistory, setStatusesHistory] = useState([]);
    const [pageTitle, setPageTitle] = useState('Order statuses history');

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (order_id) {
            fetchStatusesHistory(1);
        }
    }, [order_id, perPage]);

    const fetchStatusesHistory = async (page = 1) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setStatusesHistory(data?.statuses || []);
                setTotalRows(data?.total);
                setLoading(false);
            } else {
                setStatusesHistory([]);
                setTotalRows(0);
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setStatusesHistory([]);
            setLoading(false);
        };

        try {
            const res = await getStatusesHistory(
                {
                    id: order_id,
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('error getting statuses history: ', error);
        }
    };

    const handlePageChange = (page) => {
        fetchStatusesHistory(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <h2>{pageTitle}</h2>
            <div className="row">
                <div className="col">
                    <StatusesTable
                        data={statusesHistory}
                        loading={loading}
                        rowsCountPerPage={perPage}
                        handleRowsPerPage={handleRowsPerPage}
                        perPageChoices={PER_PAGE_CHOICES}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default StatusesHistory;
