import { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import {
    getNotifyMessageList,
    deleteNotifyMessage,
} from '../../../api/utils/merchant/notifymessage/notifyMessages';
import useSearchString from '../../../utils/useSearchString';
import Icon from '../../UI/Icon';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import UserInfoContext from '../../../contexts/UserInfoContext';
import { Modal, Button, Form } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import languages from '../../../__fixtures__/languages';
import AnySelector from '../../UI/AnySelector';
import ReactTooltip from 'react-tooltip';
import Wait from './../../UI/Wait';
import setDefaultRecord from '../../../api/utils/app/default/setDefaultRecord';
import _ from 'lodash';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const LANGUAGES = [{ value: '', name: 'Default' }, ...languages];

const NotifyMessageList = () => {
    const { applicationId } = useSearchString();
    const [language, setLanguage] = useState('en');
    const [templatesList, setTemplatesList] = useState();
    const userInfo = useContext(UserInfoContext);
    const { merchant = null, merchant_name = '' } = useSearchString();
    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;
    const appid = applicationId ? applicationId : userInfo.app?.id;
    const merchantName = merchant
        ? merchant_name
        : userInfo.merchant
        ? userInfo.merchant.name
        : '';

    const pageTitle = `Messages ${merchantName ? `(${merchantName})` : ''}`;

    const [showMessagePreview, setShowMessagePreview] = useState(false);
    const handleShowMessagePreview = () => setShowMessagePreview(true);
    const handleCloseMessagePreview = () => setShowMessagePreview(false);
    const [currentMessage, setCurrentMessage] = useState();

    const [showConfirmRemoveMessage, setShowConfirmRemoveMessage] =
        useState(false);
    const handleShowConfirmRemoveMessage = () =>
        setShowConfirmRemoveMessage(true);
    const handleCloseConfirmRemoveMessage = () =>
        setShowConfirmRemoveMessage(false);
    const [messageToRemove, setMessageToRemove] = useState({});
    const [loading, setLoading] = useState(false);

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState(0);

    const fetchMessages = async (page = 1) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === 'accept' && data.notify_messages) {
                setTemplatesList(data.notify_messages);
                setTotalRows(data?.total);
                setLoading(false);
            } else {
                setTotalRows(0);
                setLoading(false);
                setTemplatesList([]);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setTemplatesList([]);
            setTotalRows(0);
            setLoading(false);
        };

        try {
            const res = await getNotifyMessageList(
                {
                    merchant: merchantId,
                    application: appid,
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            console.log(
                'Unexpected error while getting notify messages: ',
                error
            );
        }
    };

    const deleteMessage = () => {
        const resultHandler = (data) => {
            handleCloseConfirmRemoveMessage();
            if (data && data.status === 'accept') {
                NotificationManager.success('Message removed', 'Success', 4000);
                fetchMessages();
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            handleCloseConfirmRemoveMessage();
        };

        deleteNotifyMessage({ id: messageToRemove.id }, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .then(handleCloseConfirmRemoveMessage);
    };

    const onDeleteClick = (id, name) => {
        setMessageToRemove({ id, name });
        handleShowConfirmRemoveMessage();
    };

    useEffect(() => {
        fetchMessages();
    }, [merchantId, appid, perPage]);

    const onSetDefault = async (id, is_default) => {
        const resultHandler = (res) => {
            if (res && res.status === 'accept') {
                let newTemplatesList = _.cloneDeep(templatesList);
                const idx = newTemplatesList.findIndex(
                    (item) => item._id === res.record._id
                );
                newTemplatesList[idx].is_default = res.record.is_default;

                setTemplatesList(newTemplatesList);

                setLoading(false); // this helps re-render table

                // Alternative in case table starts glitching again
                // fetchMessages(1);
            } else {
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
        };

        try {
            setLoading(true);
            const res = await setDefaultRecord(
                {
                    id,
                    is_default,
                    model_name: 'notifymessage',
                    merchant: merchantId,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            console.log('Error setting record as default: ', error);
        }
    };

    const columns = () => [
        {
            name: '#',
            grow: 0,
            cell: (_, index) => index + 1,
        },
        {
            name: 'Name',
            grow: 2,
            cell: ({ name }) => name,
        },
        {
            name: 'Default',
            grow: 0,
            omit: !userInfo?.merchant?.is_admin,
            cell: ({ _id, is_default }) => (
                <Form.Switch
                    id={`is-default-switch-${_id}`}
                    defaultChecked={is_default}
                    onChange={() => {
                        onSetDefault(_id, !is_default);
                    }}
                    className="m-0"
                    name="is_default"
                    label=""
                />
            ),
        },
        {
            name: 'Actions',
            right: true,
            grow: 1,
            cell: ({ _id, name }) => (
                <div className="d-flex">
                    <Link to={`/messages/${_id}`}>
                        <Icon iconName="bi-pen" />
                    </Link>
                    <div
                        onClick={() => {
                            onDeleteClick(_id, name);
                        }}
                        data-tip
                        data-for="deleteTip"
                    >
                        <Icon iconName="btn bi-trash" />
                        <ReactTooltip id="deleteTip" place="top" effect="solid">
                            Delete
                        </ReactTooltip>
                    </div>
                </div>
            ),
        },
    ];

    let onRowClick = (row) => {
        setCurrentMessage(row);
        handleShowMessagePreview();
    };
    const createMessageLink = () => {
        if (merchantId) {
            return `/messages/create?merchant=${merchantId}&merchant_name=${merchantName}`;
        } else {
            return `/messages/create`;
        }
    };

    const handlePageChange = (page) => {
        fetchMessages(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <h2>{pageTitle}</h2>
            {
                <div>
                    <Link
                        to={createMessageLink}
                        className="btn btn-outline-primary mt-1 mb-3"
                    >
                        <i className="bi-plus-square ml-1 mr-2 " /> Create
                        message
                    </Link>
                    <DataTable
                        dense
                        striped
                        highlightOnHover
                        data={templatesList}
                        columns={columns()}
                        onRowClicked={onRowClick}
                        pagination={true}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={PER_PAGE_CHOICES}
                        onChangeRowsPerPage={handleRowsPerPage}
                        paginationServer
                        progressPending={loading}
                        onChangePage={handlePageChange}
                        paginationTotalRows={totalRows}
                        progressComponent={
                            <div className="p-5">
                                <Wait />
                            </div>
                        }
                    />
                    <Modal
                        show={showMessagePreview}
                        onHide={handleCloseMessagePreview}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-3">
                                <label className="font-weight-bold mr-2">
                                    Name
                                </label>
                                <label>{currentMessage?.name}</label>
                                <br />
                                <AnySelector
                                    label="Select Language"
                                    items={LANGUAGES}
                                    valueSelector="value"
                                    nameSelector="name"
                                    setItem={setLanguage}
                                />
                                <br />
                                <label className="font-weight-bold mr-3">
                                    Subject
                                </label>
                                <label>
                                    {currentMessage &&
                                    language &&
                                    language.length > 0
                                        ? currentMessage[
                                              language
                                                  ? `subject__${language}`
                                                  : 'subject'
                                          ]
                                        : currentMessage?.subject}
                                </label>
                                <br />
                                <label className="font-weight-bold mr-3">
                                    Message (general)
                                </label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={
                                        currentMessage &&
                                        language &&
                                        language.length > 0
                                            ? currentMessage[
                                                  `message__${language}`
                                                      ? `message__${language}`
                                                      : 'message'
                                              ]
                                            : currentMessage?.message
                                    }
                                    disabled
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleCloseMessagePreview}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={showConfirmRemoveMessage}
                        onHide={handleCloseConfirmRemoveMessage}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Warning</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                Do you want to remove message{' '}
                                <b>
                                    {messageToRemove
                                        ? messageToRemove.name
                                        : ''}
                                </b>
                                ?{' '}
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleCloseConfirmRemoveMessage}
                            >
                                Close
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => deleteMessage()}
                            >
                                Remove
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
            {/* : 'Select merchant first'} */}
        </div>
    );
};

export default NotifyMessageList;
