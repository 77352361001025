import Honkio from '../../Honkio';

const importColdCalls = (options = {}, errorHandler = () => {}) => {
    const { data, merchant, list_name } = options;
    return Honkio().mainShop.userFetch(
        'dataimportcoldcalls',
        {
            data,
            merchant,
            list_name,
            version: 3,
            action: 'create',
        },
        errorHandler
    );
};

export default importColdCalls;
