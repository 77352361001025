import Honkio from '../../Honkio';

const createRoom = (options = {}, errorHandler = {}) => {
  const {
    name,
    object,
    object_type,
    participants,
    application,
    merchant,
    is_private,
  } = options;

  return Honkio().mainShop.userFetch(
    'chatroomset',
    {
      name,
      object,
      object_type,
      participants,
      application,
      merchant,
      is_private,
      version: 3,
      action: 'create',
    },
    errorHandler
  );
};

export default createRoom;
