import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const INTERVAL_NAMES = [
    {
        label: 'Seconds',
        value: 'seconds',
    },
    {
        label: 'Minutes',
        value: 'minutes',
    },
    {
        label: 'Hours',
        value: 'hours',
    },
    {
        label: 'Days',
        value: 'days',
    },
    {
        label: 'Weeks',
        value: 'weeks',
    },
];

const IntervalFields = ({ job, handleChangeJob = () => {} }) => {
    return (
        <>
            <Form.Group className="mb-3" controlId="data.interval">
                <Form.Label>Interval</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Interval"
                    name="interval"
                    value={job?.interval || ''}
                    onChange={handleChangeJob}
                    min={1}
                    required
                />
                <Form.Text muted>time interval</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="data.interval_name">
                <Form.Label>Interval name</Form.Label>
                <Form.Control
                    as={'select'}
                    aria-label="Interval name"
                    name="interval_name"
                    value={job?.interval_name || ''}
                    onChange={handleChangeJob}
                    className="custom-select"
                    required
                >
                    <option value="">
                        &mdash; Select interval name &mdash;
                    </option>
                    {INTERVAL_NAMES.map((item, index) => (
                        <option
                            key={`${index}-${item?.value}-interval-name-option`}
                            value={item?.value}
                        >
                            {item?.label}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text className="text-muted">Job interval name</Form.Text>
            </Form.Group>

            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="data.start_date">
                        <Form.Label>Start date</Form.Label>
                        <Form.Control
                            as={DatePicker}
                            aria-label="Start date"
                            name="start_date"
                            selected={job?.start_date || ''}
                            onChange={(date) =>
                                handleChangeJob({
                                    target: { value: date, name: 'start_date' },
                                })
                            }
                            required
                            showTimeSelect
                            dateFormat="dd-MM-yyyy HH:mm"
                            timeFormat="HH:mm"
                            placeholderText="Select start date"
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            Job start date
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="data.end_date">
                        <Form.Label>End date</Form.Label>
                        <Form.Control
                            as={DatePicker}
                            aria-label="End date"
                            name="end_date"
                            selected={job?.end_date || ''}
                            onChange={(date) =>
                                handleChangeJob({
                                    target: { value: date, name: 'end_date' },
                                })
                            }
                            required
                            showTimeSelect
                            dateFormat="dd-MM-yyyy HH:mm"
                            timeFormat="HH:mm"
                            placeholderText="Select end date"
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            Job end date
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default IntervalFields;
