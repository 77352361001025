import React, { useEffect, useState, useMemo } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal, Button, Col, Image, Table } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import moment from 'moment';
import { ReactFlowProvider } from 'react-flow-renderer';
import LayoutFlow from '../OrderModel/LayoutFlow';
import statusesToNodeList from '../OrderModel/helpers/statusesToNodeList';
import statusesToEdgeList from '../OrderModel/helpers/statusesToEdgeList';

const DATE_FORMAT = 'MMMM Do, YYYY, HH:mm';

const RolePreviewModal = ({ model, show, onHide }) => {
  const [nodes, setNodes] = useState(null);

  const onClickHide = () => {
    onHide();
  };

  useEffect(() => {
    if (!model) return;
    const { statuses, order_type } = model;
    const nodeList = statusesToNodeList(statuses);
    const edgesList = statusesToEdgeList(statuses);
    setNodes([...nodeList, ...edgesList]);
  }, [model]);

  return model ? (
    <ReactFlowProvider>
      <Modal size="lg" show={show} onHide={onClickHide}>
        <Modal.Header closeButton>
          <Modal.Title>{model?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="height-500">
          <LayoutFlow
            className="height-500"
            initialElements={nodes}
            orderModel={model}
            showAddNode={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClickHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </ReactFlowProvider>
  ) : null;
};

export default RolePreviewModal;
