import Honkio from '../../Honkio';

const setMerchantUserInvite = (
  { role_id, email, message, ...rest },
  errorHandler = {}
) => {
  const params = {
    role_id: role_id,
    email: email,
    message: message,
    ...rest,
    version: 3,
    action: 'create',
  };

  console.log(JSON.stringify(params));

  return Honkio().mainShop.userFetch('consumerinvite', params, errorHandler);
};

export default setMerchantUserInvite;
