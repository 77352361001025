import { useFormContext } from 'react-hook-form';

const InputRange = ({
  name,
  min,
  max,
  step,
  value,
  onChange,
  className,
  label = () => '',
}) => {
  const { register } = useFormContext();
  return (
    <div className={className}>
      <label htmlFor="formControlRange">{label()}</label>
      <input
        ref={register}
        name={name}
        type="range"
        className="form-control-range"
        id="formControlRange"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputRange;
