import QueryString from 'qs';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Icon from '../../UI/Icon';
import Wait from '../../UI/Wait';
import ReactTooltip from 'react-tooltip';

const AssetList = ({
  data,
  ready,
  emptyText,
  selectedAssetsList,
  setSelectedAssetsList,
}) => {
  const columns = useMemo(
    () => [
      { name: '#', grow: 0, cell: (_, index) => index + 1 },
      { name: 'Name', grow: 2, cell: ({ name }) => name },
      {
        name: 'Type',
        sortable: true,
        grow: 1,
        cell: ({ type }) => type,
      },
      {
        name: 'Visible',
        grow: 0,
        cell: ({ visible }) => (visible ? 'Yes' : 'No'),
      },
    ],
    [selectedAssetsList]
  );

  const onAssetSelected = (data) => {
    if (data.selectedRows?.[0]?._id === selectedAssetsList?.[0]?._id) return;
    setSelectedAssetsList([data.selectedRows[0]]);
  };

  const onRowClicked = (row) => {
    if (row?._id === selectedAssetsList?.[0]?._id) {
      setSelectedAssetsList([]);
      return;
    }
    setSelectedAssetsList([row]);
  };

  const rowSelectCritera = (row) => {
    if (!selectedAssetsList.length) return false;
    const selected = selectedAssetsList.filter((item) => {
      const isSelected = item?._id === row?._id;

      return isSelected;
    });
    return selected.length > 0;
  };

  const conditionalRowStyles = [
    {
      when: (row) => row._id === selectedAssetsList?.[0]?._id,
      style: {
        backgroundColor: '#28a745',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  return (
    <DataTable
      dense
      striped
      highlightOnHover
      data={data}
      columns={columns}
      pagination={true}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 50, 100]}
      progressPending={!ready}
      noDataComponent={emptyText}
      progressComponent={
        <div className="p-5">
          <Wait />
        </div>
      }
      onRowClicked={onRowClicked}
      selectableRows
      onSelectedRowsChange={(e) => onAssetSelected(e)}
      selectableRowSelected={rowSelectCritera}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

export default AssetList;
