import Honkio from '../../Honkio';

const refundPayment = (options = {}, errorHandler = {}) => {
  const { order_id, fixed_amount, amount_percentage } = options;
  const req_data = { order_id, action: 'update', version: 3 };
  if (fixed_amount) req_data.fixed_amount = fixed_amount;
  if (!fixed_amount && amount_percentage)
    req_data.amount_percentage = amount_percentage;

  return Honkio().mainShop.userFetch(
    'adminpaymentsrefund',
    req_data,
    errorHandler
  );
};
export default refundPayment;
