import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { useFormContext, useController } from 'react-hook-form';
import Icon from '../../../UI/Icon';

const NumberOfTransactionsItem = ({ item, idx, onDelete }) => {
  const { control } = useFormContext();
  const { field } = useController({
    name: `number_of_returns[${idx}].value`,
    control,
  });

  return (
    <ListGroup.Item>
      <div className="row">
        <div className="col">
          <input
            name={`number_of_returns[${idx}].value`}
            key={`${idx}`}
            className="form-control p-2"
            label="Number of tr label"
            defaultValue={field.value || ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Button
            className="p-0"
            onClick={() => onDelete(idx)}
            variant="outline"
          >
            <Icon
              iconName="bi-trash-fill"
              size="1"
              color="var(--danger)"
              className="py-0"
            />
          </Button>
        </div>
      </div>
    </ListGroup.Item>
  );
};

export default NumberOfTransactionsItem;
