import { useEffect, useMemo, useState, useContext } from 'react';
import { getAssets, getAsset } from '../../api/utils/asset/getAssets';
import useSearchString from '../../utils/useSearchString';
import Map from '../Map/Map';
import AnySelector from '../UI/AnySelector';
import InlineSearch from '../UI/InlineSearch';
import AssetList from './AssetList';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AssetTypesListModal from './AssetTypesListModal';
import getAssetTypeList from '../../api/utils/asset/type/getAssetTypeList';
import setAssetData from '../../api/utils/asset/setAssetData';
import getConsumer from '../../api/utils/consumer/getConsumer';
import setDefaultRecord from '../../api/utils/app/default/setDefaultRecord';
import _ from 'lodash';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const getConsumerName = (c) => {
    try {
        let fullName =
            '' + c?.settings?.str_firstname + ' ' + c?.settings?.str_lastname;
        fullName = fullName.trim();
        if (fullName) fullName += ' - ';
        return `${fullName}${c?.email}`;
    } catch (error) {
        return 'Unknown user';
    }
};

const Assets = () => {
    const [assetList, setAssetList] = useState([]);
    const [selected, setSelected] = useState({});
    const [keyword, setKeyword] = useState('');
    const [type, setType] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [ready, setReady] = useState(false);
    const userInfo = useContext(UserInfoContext);
    const {
        merchant = null,
        parent = 'None',
        merchant_name = '',
        user_id,
    } = useSearchString();
    const appid = userInfo.app ? userInfo.app.id : null;

    const history = useHistory();

    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;
    const merchantName = merchant
        ? merchant_name
        : userInfo.merchant
        ? userInfo.merchant.name
        : '';

    const [showAssetTypeListModal, setShowAssetTypeListModal] = useState(false);
    const handleShowAssetTypeListModal = () => setShowAssetTypeListModal(true);
    const handleCloseAssetTypeListModal = () =>
        setShowAssetTypeListModal(false);
    const [assetTypeList, setAssetTypeList] = useState();

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState();

    const [consumer, setConsumer] = useState(null);

    const [pageTitle, setPageTitle] = useState('Assets');

    const getPageTitle = () => {
        if (consumer) {
            return (
                `Assets` + (consumer ? `  (${getConsumerName(consumer)})` : '')
            );
        } else if (merchantName) {
            return `Assets` + `(${merchantName})`;
        }
    };

    useEffect(() => {
        setPageTitle(getPageTitle());
    }, [userInfo, user_id, consumer]);

    useEffect(() => {
        fetchAssetTypes();
    }, [merchantId, parent]);

    useEffect(() => {
        setReady(false);
        fetchAssets();
    }, [merchantId, parent, selectedType, perPage, type, keyword, user_id]);

    useEffect(() => {
        if (user_id) {
            fetchConsumer(user_id);
        } else {
            setConsumer(null);
        }
    }, [user_id]);

    const fetchConsumer = async (id) => {
        const resultHandler = (data) => {
            setConsumer(data.consumer);
        };

        const errorHandler = (error) => {
            setConsumer({});
            NotificationManager.error(error.description, 'Error', 4000);
        };

        getConsumer({ id }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const fetchAssets = async (page = 1) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setAssetList(data.assets);
                setTotalRows(data.total);
                setReady(true);
            } else {
                NotificationManager.error('Error');
                setAssetList([]);
                setTotalRows(0);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setAssetList([]);
            setTotalRows(0);
            setReady(true);
        };
        if (merchantId) {
            try {
                const res = await getAssets(
                    {
                        merchant: merchantId,
                        query_parent: parent,
                        query_type: selectedType,
                        query_skip: (+page - 1) * perPage,
                        query_count: perPage,
                        query_visible:
                            type == 'true'
                                ? true
                                : type == 'false'
                                ? false
                                : type,
                        query_owner: user_id,
                        query_linked: !!user_id,
                        search: keyword,
                    },
                    errorHandler
                );
                resultHandler(res);
            } catch (error) {
                console.log('Unexpected error while fetching assets: ', error);
            }
        } else {
            setReady(true);
            setAssetList([]);
        }
    };

    const handlePageChange = (page) => {
        fetchAssets(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    const fetchAssetTypes = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setAssetTypeList(
                    data.asset_types.reduce(
                        (types, item) => [
                            ...types,
                            { name: item.name, value: item.name },
                        ],
                        []
                    )
                );
                setAssetTypeList(data.asset_types);
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setAssetTypeList([]);
        };

        try {
            const res = await getAssetTypeList(
                { query_application: appid },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('Unexpected error while fetching asset types: ', error);
        }
    };

    const onAssetTypeSelected = (assetType) => {
        history.push(`/assets/create?assetType=${assetType._id}`);
    };

    const handleDeleteAsset = (id) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Asset removed', 'Success', 4000);
                fetchAssets();
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        setAssetData({ id }, null, null, errorHandler, 'delete').then(
            (data) => {
                return resultHandler(data);
            }
        );
    };

    const onSetDefault = async (id, is_default) => {
        const resultHandler = (res) => {
            if (res && res.status === 'accept') {
                let newAssetsList = _.cloneDeep(assetList);
                const idx = newAssetsList.findIndex(
                    (item) => item._id === res.record._id
                );
                newAssetsList[idx].is_default = res.record.is_default;
                setAssetList(newAssetsList);
                setReady(true); // this helps re-render table
            } else {
                setReady(true);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setReady(true);
        };

        try {
            setReady(false);
            const res = await setDefaultRecord(
                {
                    id,
                    is_default,
                    model_name: 'asset',
                    merchant: merchantId,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setReady(true);
            console.log('Error setting record as default: ', error);
        }
    };

    return (
        <div className="w-100 h-100">
            <NotificationContainer />
            <div className="container-fluid">
                <h2>{pageTitle}</h2>
                {merchantId && (
                    <Link
                        onClick={() => handleShowAssetTypeListModal()}
                        className="btn btn-outline-primary mt-1 mb-3"
                    >
                        <i className="bi-plus-square ml-1 mr-2 " /> Create asset
                    </Link>
                )}
                <div className="row">
                    <div className="col">
                        <InlineSearch setKeyword={setKeyword} />
                    </div>

                    <div className="mr-3">
                        <AnySelector
                            label="Type"
                            items={assetTypeList?.map((item) => ({
                                name: item.name,
                                value: item.name,
                            }))}
                            resetItem={{ name: 'All', value: '' }}
                            nameSelector="name"
                            valueSelector="value"
                            setItem={setSelectedType}
                            onSetDefault={onSetDefault}
                        />
                    </div>
                    <div>
                        <AnySelector
                            label="Visibility"
                            items={[
                                { name: 'Visible', value: true },
                                { name: 'Not visible', value: false },
                            ]}
                            resetItem={{ name: 'All', value: '' }}
                            nameSelector="name"
                            valueSelector="value"
                            setItem={setType}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <AssetList
                            data={assetList}
                            ready={ready}
                            selectItem={setSelected}
                            id={selected.id}
                            emptyText={
                                merchantId
                                    ? 'The asset list is empty'
                                    : 'Please, select merchant first'
                            }
                            onDelete={handleDeleteAsset}
                            rowsCountPerPage={perPage}
                            handleRowsPerPage={handleRowsPerPage}
                            perPageChoices={PER_PAGE_CHOICES}
                            totalRows={totalRows}
                            handlePageChange={handlePageChange}
                            isAdmin={!!userInfo?.merchant?.is_admin}
                            onSetDefault={onSetDefault}
                        />
                    </div>
                </div>
            </div>
            <div className="h-50 w-100">
                <Map
                    defaultCenter={{
                        lat: selected?.latitude ?? 0,
                        lng: selected?.latitude ?? 0,
                    }}
                />
            </div>
            <AssetTypesListModal
                assetTypeList={assetTypeList}
                show={showAssetTypeListModal}
                onAssetTypeSelected={onAssetTypeSelected}
                onHide={handleCloseAssetTypeListModal}
            ></AssetTypesListModal>
        </div>
    );
};

export default Assets;
