import { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import UserInfoContext from '../../../contexts/UserInfoContext';
import Selector from './Selector';
import useSearchString from '../../../utils/useSearchString';
import { Modal, Button } from 'react-bootstrap';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import getAccessRulesList from '../../../api/utils/accessRules/getAccessRulesList';
import deleteAccessRule from '../../../api/utils/accessRules/deleteAccessRule';
import AccessRulePreviewModal from './AccessRulePreviewModal';
import updateAccessRule from '../../../api/utils/accessRules/updateAccessRule';
import _ from 'lodash';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const AccessRulesList = ({ language = 'en' }) => {
  const [accessRules, setAccessRules] = useState(null);
  const [loading, setLoading] = useState(false);

  const userInfo = useContext(UserInfoContext);

  const { appid = null, appname = '' } = useSearchString();
  const appId = appid ? appid : userInfo.app?.id ? userInfo.app.id : undefined;
  const appName = appid ? appname : userInfo.app?.id ? userInfo.app.name : '';

  const pageTitle = `Access rules`;

  const [showConfirmRemoveAccessRule, setShowConfirmRemoveAccessRule] =
    useState(false);
  const handleShowConfirmRemoveAccessRule = () =>
    setShowConfirmRemoveAccessRule(true);
  const handleCloseConfirmRemoveAccessRule = () =>
    setShowConfirmRemoveAccessRule(false);
  const [accessRuleToRemove, setAccessRuleToRemove] = useState({});

  const [showAccessRulePreview, setShowAccessRulePreview] = useState(false);
  const handleShowAccessRulePreview = () => setShowAccessRulePreview(true);
  const handleCloseAccessRulePreview = () => setShowAccessRulePreview(false);
  const [currentAccessRule, setCurrentAccessRule] = useState();

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  useEffect(() => {
    fetchAccessRules();
  }, [perPage]);

  const fetchAccessRules = async (page = 1) => {
    setLoading(true);

    const resultHandler = (data) => {
      setAccessRules(data?.access_rules);
      setTotalRows(data?.total);
      setLoading(false);
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setAccessRules([]);
      setTotalRows(0);
      setLoading(false);
    };

    const res = await getAccessRulesList(
      {
        query_skip: (+page - 1) * perPage,
        query_count: perPage,
      },
      errorHandler
    );
    if (res && res?.status === 'accept') resultHandler(res);
  };

  const onDeleteClick = (id, name) => {
    setAccessRuleToRemove({ id, name });
    handleShowConfirmRemoveAccessRule();
  };

  const deleteRule = () => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Access rule removed', 'Success', 4000);
        handleCloseConfirmRemoveAccessRule();
        fetchAccessRules();
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    deleteAccessRule(
      {
        id: accessRuleToRemove.id,
      },
      errorHandler
    )
      .then((data) => {
        return resultHandler(data);
      })
      .then(handleCloseConfirmRemoveAccessRule);
  };

  const toggleActive = async (rule) => {
    if (!rule?._id || typeof rule?.is_active !== 'boolean') return;

    const errorHandler = (error) => {
      console.error(error.description);
    };

    const resultHandler = (data) => {
      if (data && data.status === 'accept' && data.access_rule) {
        NotificationManager.success('Access rule updated!');

        fetchAccessRules();
      } else {
        NotificationManager.error('Error updating Access rule');
      }
    };

    const res = await updateAccessRule(
      { id: rule._id, is_active: !rule?.is_active, is_global: rule.is_global },
      errorHandler
    );
    resultHandler(res);
  };

  let onRowClick = (row) => {
    setCurrentAccessRule(row);
    handleShowAccessRulePreview();
  };

  const handlePageChange = (page) => {
    fetchAccessRules(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  return (
    <div>
      <NotificationContainer />
      <h2>{pageTitle}</h2>
      {appId && (
        <div className="m-1">
          <Link
            to={`/accessrules/create`}
            className="btn btn-outline-primary m-1"
          >
            <i className="bi-plus-square ml-1 mr-2" /> Create access rule
          </Link>
        </div>
      )}
      <Selector
        loading={loading}
        items={accessRules}
        appid={appId}
        onDeleteClick={onDeleteClick}
        onRowClicked={onRowClick}
        language={language}
        toggleActive={toggleActive}
        rowsCountPerPage={perPage}
        handleRowsPerPage={handleRowsPerPage}
        perPageChoices={PER_PAGE_CHOICES}
        totalRows={totalRows}
        handlePageChange={handlePageChange}
      />
      <Modal
        show={showConfirmRemoveAccessRule}
        onHide={handleCloseConfirmRemoveAccessRule}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to remove this access rule{' '}
            <b>{accessRuleToRemove ? accessRuleToRemove.name : ''}</b>?{' '}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseConfirmRemoveAccessRule}
          >
            Close
          </Button>
          <Button variant="danger" onClick={() => deleteRule()}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>

      <AccessRulePreviewModal
        ruleId={currentAccessRule?._id}
        show={showAccessRulePreview}
        onHide={handleCloseAccessRulePreview}
      />
    </div>
  );
};

export default AccessRulesList;
