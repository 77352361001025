import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Icon from '../UI/Icon';
import Wait from './../UI/Wait';

function convertTZ(date, tzString) {
    const tzdate = new Date(date + ' GMT');
    return tzdate.toLocaleString('fi-FI', { timeZone: tzString });
}

const CustomerTypeList = ({
    customerTypes = [],
    emptyText = '',
    loading,
    onDeleteClick = () => {},
    onRowClicked = () => {},
    rowsCountPerPage,
    totalRows,
    handlePageChange,
    handleRowsPerPage,
    perPageChoices = [25],
    handleSort = () => {},
    isAdmin = false,
    onSetDefault = () => {},
}) => {
    const columns = useMemo(
        () => [
            {
                name: 'Name',
                init_name: 'name',
                grow: 2,
                sortable: true,
                selector: (row) => row.name,
                cell: ({ name }) => name,
            },
            {
                name: 'Description',
                init_name: 'description',
                sortable: true,
                selector: (row) => row.description,
                grow: 2,
                cell: ({ description }) => description,
            },
            {
                name: 'Created',
                init_name: 'created_at',
                grow: 1,
                sortable: true,
                selector: (row) => row.created_at,
                cell: ({ created_at }) =>
                    convertTZ(created_at, 'Europe/Helsinki'),
            },
            {
                name: 'Default',
                grow: 0,
                omit: !isAdmin,
                cell: ({ _id, is_default }) => (
                    <Form.Switch
                        id={`is-default-switch-${_id}`}
                        defaultChecked={is_default}
                        onChange={() => {
                            onSetDefault(_id, !is_default);
                        }}
                        className="m-0"
                        name="is_default"
                        label=""
                    />
                ),
            },
            {
                name: 'Actions',
                grow: 0,
                right: true,
                cell: ({ _id }) => (
                    <div className="d-flex">
                        <Link to={`/customer/type/edit?id=${_id}`}>
                            <Icon iconName="bi-pen" />
                        </Link>
                        <div
                            onClick={() => {
                                onDeleteClick(_id);
                            }}
                            data-tip
                            data-for="deleteTip"
                        >
                            <Icon
                                iconName="btn bi-trash"
                                color="var(--danger)"
                            />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </div>
                    </div>
                ),
            },
        ],
        [loading]
    );

    return (
        <DataTable
            dense
            striped
            noHeader
            highlightOnHover
            progressPending={loading}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
            data={customerTypes}
            columns={columns}
            emptyText={emptyText}
            onRowClicked={onRowClicked}
            pointerOnHover
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            paginationServer
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            noDataComponent={emptyText}
            sortServer
            onSort={handleSort}
            onChangeRowsPerPage={handleRowsPerPage}
        />
    );
};

export default CustomerTypeList;
