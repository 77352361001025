import Honkio from '../../../Honkio';

const listSpecialActions = (options = {}, errorHandler = () => {}) => {
  return Honkio().mainShop.userFetch(
    'adminappspecialactionslist',
    {
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};
export default listSpecialActions;
