import { useEffect, useState } from 'react';
import getAssetType from '../../api/utils/asset/type/getAssetType';
import {
  deleteAssetType,
  updateAssetType,
} from '../../api/utils/asset/type/setAssetType';
import useSearchString from '../../utils/useSearchString';
import AssetTypeEditForm from './AssetTypeEditForm';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import prepareToPublish from './helpers/prepareToPublish';
import { Modal, Button } from 'react-bootstrap';
import assetTypeToState from './helpers/assetTypeToState';
import LoadingBar from '../UI/LoadingBar';
import useLoader from '../UI/helpers/useLoader';

const AssetTypeEdit = () => {
  const history = useHistory();
  const [assetType, setAssetType] = useState({ info: null, types: [] });
  const { assetType: id } = useSearchString();

  const [progress, done] = useLoader([assetType]);

  const [showConfirmRemoveAssetTypeType, setShowConfirmRemoveAssetTypeType] =
    useState(false);
  const handleShowConfirmRemoveAssetTypeType = () =>
    setShowConfirmRemoveAssetTypeType(true);
  const handleCloseConfirmRemoveAssetTypeType = () =>
    setShowConfirmRemoveAssetTypeType(false);
  const showConfirmDialog = () => {
    handleShowConfirmRemoveAssetTypeType();
  };
  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (id) {
      fetchAssetType();
    }
  }, [id]);

  const fetchAssetType = async () => {
    const resultHandler = (data) => {
      if (isMounted) {
        if (data && data.status === 'accept' && data.asset_type) {
          const ate = assetTypeToState(data.asset_type);
          setAssetType({ ...ate });
        } else {
          setAssetType();
        }
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setAssetType();
    };

    getAssetType({ id }, errorHandler).then((data) => {
      return resultHandler(data);
    });
  };

  const onSubmit = (assetTypeData) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Saved');
        history.goBack();
      } else {
        NotificationManager.error('Error');
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    console.log('assetTypeData ==', assetTypeData);

    updateAssetType(
      {
        merchant: assetType.merchant,
        application: assetType.application,
        ...prepareToPublish(id, assetTypeData),
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const onDelete = () => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Asset type deleted');
        history.goBack();
      } else {
        NotificationManager.error('Error');
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    deleteAssetType(id, errorHandler)
      .then((data) => {
        return resultHandler(data);
      })
      .catch(console.error);
  };

  return (
    <div>
      <NotificationContainer />
      <h2>Asset type edit (ID#{id})</h2>
      {done ? (
        assetType ? (
          <AssetTypeEditForm
            onSubmit={onSubmit}
            onDelete={() => {
              showConfirmDialog();
            }}
            defaultValues={assetType}
          />
        ) : (
          'not found'
        )
      ) : (
        <LoadingBar progress={progress} />
      )}
      <Modal
        show={showConfirmRemoveAssetTypeType}
        onHide={handleCloseConfirmRemoveAssetTypeType}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to remove asset type?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseConfirmRemoveAssetTypeType}
          >
            Close
          </Button>
          <Button variant="danger" onClick={() => onDelete()}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default AssetTypeEdit;
