import Honkio from '../../../Honkio';

const setProductTemplate = (options = {}, errorHandler = () => {}) => {
    const {
        id,
        settings,
        accessibility,
        active,
        merchant,
        application,
        action,
    } = options;
    return Honkio().mainShop.userFetch(
        'admintemplatemodelsproductset',
        {
            id,
            settings,
            accessibility,
            active,
            merchant,
            application,
            action,
            version: 3,
        },
        errorHandler,
        true
    );
};

export const createProductTemplate = (
    options = {},
    errorHandler = () => {}
) => {
    return setProductTemplate(
        {
            ...options,
            action: 'create',
        },
        errorHandler
    );
};

export const updateProductTemplate = (
    options = {},
    errorHandler = () => {}
) => {
    return setProductTemplate(
        {
            ...options,
            action: 'update',
        },
        errorHandler
    );
};

export const deleteProductTemplate = (
    options = {},
    errorHandler = () => {}
) => {
    return setProductTemplate(
        {
            ...options,
            action: 'delete',
        },
        errorHandler
    );
};
