import React, { useContext } from 'react';
// import { useUpdateNodeInternals } from 'react-flow-renderer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import CustomHandle from './CustomHandle';
import UBOContext from '../../../../contexts/UBOGraphContext';
import InfoIcon from '@material-ui/icons/Info';

const LegalEntityNode = ({ id, data, handleShowNodeInfo, selected }) => {
  //   const updateNodeInternals = useUpdateNodeInternals();
  const { NODE_HEIGHT, NODE_WIDTH } = useContext(UBOContext);

  return (
    <Box sx={{ height: '100%' }}>
      <CustomHandle type="target" />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
        }}
      >
        <Paper
          elevation={0}
          variant="elevation"
          sx={{
            padding: (theme) => theme.spacing(1),
            backgroundColor: (theme) => theme.palette.grey[300],
            color: (theme) =>
              theme.palette.getContrastText(theme.palette.grey[300]),
            borderRadius: (theme) => theme.shape.borderRadius,
            boxShadow: selected ? 10 : 1,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <WorkOutlineIcon />

          <Typography variant="subtitle1" gutterBottom>
            INC
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {data.values.share} %
          </Typography>

          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <IconButton
              aria-label="add"
              sx={{
                color: (theme) =>
                  theme.palette.getContrastText(theme.palette.grey[300]),
              }}
              onClick={() => handleShowNodeInfo(data, 'legal')}
            >
              <InfoIcon />
            </IconButton>
          </Box>
        </Paper>
      </Box>

      <CustomHandle type="source" />
    </Box>
  );
};

export default LegalEntityNode;
