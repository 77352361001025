import HonkioAPI from '../../Honkio';

/**
 *
 * @param {*} options - {object: string, type: string }
 * @param {*} options.type - customer type name
 * @param {*} options.types - customer types' names
 * @param {*} options.query_owner email of user who owns this customer
 * @param {*} options.query_ids the list of customer ids
 * @param {*} options.query_merchant - merchant._id value
 * @param {*} options.query_application - application._id value
 * @param {*} options.query_skip pagination skip documents count
 * @param {*} options.query_count pagination returned number of documents
 * @param {*} options.query_sort_field - field to sort by
 * @param {*} options.query_sort_direction - -1 for ascending, 1 - for descending order
 * @param {*} options.query_search - search substring in customer name attribute
 * @param {*} options.query_user_id - user._id field value
 * @param {*} options.query_parent - parent customer's _id
 * @param {*} options.query_pos - shop _id
 * @param {*} options.query_visible - filter by visibility
 * @param {*} options.query_custom - search in custom_fields
 * @param {*} options.query_properties - search in properties
 * @param {*} errorHandler - callback function, triggered on error in the response
 * @returns Promise
 */
export const getCustomerList = (options = {}, errorHandler = () => {}) => {
    const {
        query_type,
        query_types,
        query_user_id,
        query_skip,
        query_count,
        query_sort_field,
        query_sort_direction,
        query_search,
        query_ids,
        query_merchant,
        query_application,
        query_pos,
        query_parent,
        query_visible,
        query_custom,
        query_properties,
    } = options;
    return HonkioAPI().mainShop.userFetch(
        'admincustomerlist',
        {
            query_type,
            query_types,
            query_user_id,
            query_skip,
            query_count,
            query_sort_field,
            query_sort_direction,
            query_search,
            query_ids,
            query_merchant,
            query_application,
            query_pos,
            query_parent,
            query_visible,
            query_custom,
            query_properties,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
