import Honkio from '../../Honkio';

const setAdminRole = (options = {}, errorHandler = () => {}) => {
  const params = {
    ...options,
    version: 3,
  };
  return Honkio().mainShop.userFetch('adminroleset', params, errorHandler);
};

export const createAdminRole = (options, errorHandler) => {
  const opts = {
    ...options,
    action: 'create',
  };
  return setAdminRole(opts, errorHandler);
};

export const updateAdminRole = (options, errorHandler) => {
  const opts = {
    ...options,
    action: 'update',
  };
  return setAdminRole(opts, errorHandler);
};

export const deleteAdminRole = (options, errorHandler) => {
  const opts = {
    ...options,
    action: 'delete',
  };
  return setAdminRole(opts, errorHandler);
};
