import Honkio from '../../../Honkio';

const getPasswordPolicy = (options = {}, errorHandler = () => {}) => {
  const { debug } = options;
  return Honkio().mainShop.userFetch(
    'settingspasswordpolicyget',
    {
      debug,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getPasswordPolicy;
