import Honkio from '../../../Honkio';

const resendUserVerification = (options = {}, errorHandler = {}) => {
  const { verification_method, email, application_id, force } = options;

  return Honkio().mainShop.userFetch(
    'merchantregistrationresendverification',
    {
      verification_method,
      email,
      application: application_id,
      force,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default resendUserVerification;
