import { useEffect, useState, useContext } from 'react';
import useSearchString from '../../utils/useSearchString';
import AnySelector from '../UI/AnySelector';
import InlineSearch from '../UI/InlineSearch';
import AssetStructuresList from './AssetStructuresList';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import getAssetStructuresList from '../../api/utils/asset/structures/getAssetStructuresList';
import { deleteAssetStructure } from '../../api/utils/asset/structures/setAssetStructure';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const AssetStructures = () => {
  const [assetList = [], setAssetList] = useState([]);
  const [selected, setSelected] = useState({});
  const [keyword, setKeyword] = useState('');
  const [type, setType] = useState('');
  const [ready, setReady] = useState(false);
  const userInfo = useContext(UserInfoContext);
  const { merchant = null, parent = 'None' } = useSearchString();

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  const merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;

  const pageTitle = merchant
    ? 'Asset structures'
    : userInfo.merchant?.name
    ? `Asset structures (${userInfo.merchant.name})`
    : 'Asset structures';

  useEffect(() => {
    setReady(false);
    fetchAssetStructures();
  }, [merchant, parent]);

  useEffect(() => {
    fetchAssetStructures(1);
  }, [perPage]);

  useEffect(() => {
    fetchAssetStructures(1);
  }, [keyword]);

  const fetchAssetStructures = async (page = 1) => {
    if (merchantId) {
      getAssetStructuresList(
        {
          merchant: merchantId,
          query_skip: (+page - 1) * perPage,
          query_count: perPage,
          query_search: keyword,
        },
        errorHandler
      )
        .then((data) => {
          return resultHandler(data);
        })
        .catch(console.error);
    } else {
      setReady(true);
    }
  };

  const resultHandler = (data) => {
    if (data && data.status === 'accept') {
      setAssetList(data.asset_structures);
      setTotalRows(data.total);
      setReady(true);
    } else {
      NotificationManager.error('Error');
    }
  };

  const errorHandler = (error) => {
    NotificationManager.error(error.description, 'Error', 4000);
    setAssetList([]);
    setReady(true);
  };

  const handlePageChange = (page) => {
    fetchAssetStructures(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  const handleDeleteStructure = async (id) => {
    const resultHandler = (data) => {
      NotificationManager.success('Structure removed');
      fetchAssetStructures();
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    const res = await deleteAssetStructure({ id }, errorHandler);
    if (res && res.status === 'accept') resultHandler(res);
  };

  return (
    <div className="w-100 h-100">
      <NotificationContainer />
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col">
            <h2>{pageTitle}</h2>
          </div>

          <div>
            <Button as={Link} to="/assetstructure/create" variant="primary">
              Create structure
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <InlineSearch setKeyword={setKeyword} />
          </div>
          <div>
            <AnySelector
              label="Visibility"
              items={[
                { name: 'Visible', value: 'trueBool' },
                { name: 'Not visible', value: 'falseBool' },
              ]}
              resetItem={{ name: 'All', value: '' }}
              nameSelector="name"
              valueSelector="value"
              setItem={setType}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <AssetStructuresList
              data={assetList}
              ready={ready}
              selectItem={setSelected}
              id={selected.id}
              rowsCountPerPage={perPage}
              handleRowsPerPage={handleRowsPerPage}
              perPageChoices={PER_PAGE_CHOICES}
              totalRows={totalRows}
              handlePageChange={handlePageChange}
              onDeleteClick={handleDeleteStructure}
              emptyText={
                merchantId
                  ? 'The structures list is empty'
                  : 'Please, select merchant first'
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetStructures;
