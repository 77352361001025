import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export default function DeleteOrderList({
  modal,
  onModalClose,
  handleConfirmDeleteOrder,
}) {
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    function addMonths(date, months) {
      date.setMonth(date.getMonth() + months);
      setStartDate(date);
      return date;
    }
    addMonths(new Date(), -6);
  }, []);

  return (
    <div>
      {modal && (
        <Modal show={true}>
          <Modal.Header>
            <Modal.Title>Choose Date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-group date" data-provide="datepicker">
              <DatePicker
                className="w-100 form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
              <div className="input-group-addon">
                <span className="glyphicon glyphicon-th cus-txt-smaller">
                  from the selected date all the orders will be deleted
                  permanently
                </span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onModalClose}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => handleConfirmDeleteOrder(startDate)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
