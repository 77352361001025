import { useState, useCallback } from 'react';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import useSearchString from '../../utils/useSearchString';
import OrderModelEditForm from './OrderModelEditForm';
import { NotificationManager } from 'react-notifications';
import { setAdminOrderModel } from '../../api/utils/orderModel/setAdminOrderModel';
import { useHistory } from 'react-router-dom';
import BackButton from '../UI/BackButton';

const OrderModelCreate = (props) => {
    const history = useHistory();
    const { appid, merchant = null } = useSearchString();
    const [orderModel, setOrderModel] = useState({
        merchant: merchant ? merchant : 'None',
        application: appid,
        name: 'test',
        statuses: [],
    });
    const onSave = useCallback((editedModel) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept' && data?.order_model) {
                if (orderModel._id) {
                    NotificationManager.success(
                        'Order model updated',
                        'Success',
                        4000
                    );
                } else {
                    NotificationManager.success(
                        'Order model created',
                        'Success',
                        4000
                    );
                }
                history.push(`/ordermodel/edit?omid=${data.order_model._id}`);
            } else {
                NotificationManager.error(
                    'Cannot update order model',
                    'Error',
                    4000
                );
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        setAdminOrderModel(editedModel, errorHandler).then((data) => {
            return resultHandler(data);
        });
    });

    return (
        <div className="w-100 h-50 flex-column">
            <NotificationContainer />

            <BackButton to="/ordermodel" text="Back to list" />

            {orderModel && (
                <OrderModelEditForm onSave={onSave} orderModel={orderModel} />
            )}
        </div>
    );
};

export default OrderModelCreate;
