import {
  ButtonActionForm,
  InputFieldWithValidation,
} from '../../Form/elements/formElements';
import SmartForm from '../../Form/SmartForm';
import { useEffect } from 'react';

const CoordinatesGreyListForm = (props) => {
  const { greyList, onSave, btnLabel } = props;

  let isMounted = true;

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  if (!greyList) return null;
  const { latitude, longitude, radius, score } = greyList;
  const defaultValues = { latitude, longitude, radius, score };

  return (
    <div className="container-fluid">
      <SmartForm onSubmit={onSave} defaultValues={defaultValues}>
        <div className="row mb-3">
          <InputFieldWithValidation
            className="col-3"
            name="score"
            label="Score"
            type="number"
            required="true"
          />
          <InputFieldWithValidation
            className="col-2"
            name="latitude"
            label="Latitude"
            type="number"
            required="true"
            step="0.000001"
          />
          <InputFieldWithValidation
            className="col-2"
            name="longitude"
            label="Longitude"
            type="number"
            required="true"
            step="0.000001"
          />
          <InputFieldWithValidation
            className="col-3"
            name="radius"
            label="Radius"
            type="number"
            required="true"
            min="0.00"
            step="1"
          />
          <div className="d-flex align-items-end col-2">
            <ButtonActionForm
              label={btnLabel ? btnLabel : 'Add'}
              name="add"
              value={true}
              className="btn btn-primary"
              type="submit"
            />
          </div>
        </div>
      </SmartForm>
    </div>
  );
};

export default CoordinatesGreyListForm;
