/**
 *
 * @param {*} businessId - string
 * @returns null | company info array
 */
const getInfoByBusinessId = async (businessId = '') => {
  const INFO_URL = `https://avoindata.prh.fi/bis/v1/${businessId.trim()}`;
  let response = await fetch(INFO_URL);
  let result = await response.json();
  if (result.results.length > 0) {
    return result.results;
  } else {
    return null;
  }
};

export default getInfoByBusinessId;
