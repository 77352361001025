import React from 'react';
import { useEffect, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import cn from 'classnames';

const CustomerTypesListModal = ({
    customerTypeList,
    show,
    onHide = () => {},
    onCustomerTypeSelected = () => {},
}) => {
    const columns = useMemo(() => [
        {
            name: 'Name',
            cell: ({ name }) => name,
            sortable: true,
        },
        {
            name: 'Description',
            cell: ({ description }) => description,
            sortable: true,
        },
    ]);

    const container = (
        <div className="container">
            <div>
                <DataTable
                    dense
                    striped
                    noHeader
                    noDataComponent="no permissions"
                    highlightOnHover
                    onRowClicked={onCustomerTypeSelected}
                    data={customerTypeList}
                    columns={columns}
                />
            </div>

            <div
                className={cn(
                    'mt-3',
                    'd-flex',
                    'justify-content-start',
                    'align-items-center'
                )}
            >
                <Link
                    to={`/customer/type/create`}
                    className="btn btn-outline-primary mt-1 mb-3"
                >
                    <i className="bi-plus-square ml-1 mr-2 " /> Create customer
                    type
                </Link>
            </div>
        </div>
    );

    const onClickHide = () => {
        onHide();
    };

    return (
        <Modal show={show} onHide={onClickHide}>
            <Modal.Header closeButton>
                <Modal.Title>Please select customer type from list</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {customerTypeList ? container : 'not found'}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomerTypesListModal;
