import Honkio from '../../../Honkio';

const getAssetStructuresList = (options, errorHandler = {}) => {
  const { merchant, query_skip, query_count, query_search, debug } = options;
  return Honkio().mainShop.userFetch(
    'assetstructureslist',
    {
      debug,
      query_merchant: merchant,
      query_search,
      query_skip,
      query_count,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getAssetStructuresList;
