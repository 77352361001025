import { useContext, useEffect, useState } from 'react';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import {
    ButtonActionForm,
    CheckboxSwitch,
    InputFieldWithValidation,
    SelectFieldWithValidation,
} from '../Form/elements/formElements';
import { Form, Button, Spinner, InputGroup } from 'react-bootstrap';
import SmartForm from '../Form/SmartForm';
import UploadFileWithPreview from '../Form/elements/UploadFileWithPreview';
import getAdminShopList from '../../api/utils/shop/getAdminShopList';
import AddressTableModal from '../ShopInfo/AddressTableModal';
import Map from '../Map';
import UserInfoContext from '../../contexts/UserInfoContext';
import getProductList from '../../api/utils/product/getProductList';

const DEFAULT_POS_OPT = {
    _id: '',
    label: '— Select point of sales —',
    value: '',
};

// TODO: Remove react hook form components, and use plain react-bootstrap for this form
const AssetEditForm = ({
    defaultValues,
    onSubmit,
    assetType,
    coordinatesInput,
    setCoordinatesInput,
    setCoordUpdated,
    coordUpdated,
    handleCoordinatesChanged,
    addressList,
    showAddressListModal,
    onAddressSelected,
    handleCloseAddressListModal,
}) => {
    const {
        merchant: { id: merchantId },
        app: { id: appId },
    } = useContext(UserInfoContext);

    const [assetTypePropertiesElements, setAssetTypePropertiesElements] =
        useState([]);

    const [idLists, setAssetIdLists] = useState([]);

    const [shopsList, setShopsList] = useState([]);

    const [pos, setPos] = useState('-1');

    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (defaultValues?.pos && shopsList?.length) {
            const currentPos = shopsList.find(
                (item) => item?._id === defaultValues?.pos
            );
            if (currentPos) setPos(currentPos._id);
        }
    }, [defaultValues, shopsList]);

    const renderPropertyName = (propName = '') => {
        try {
            let stringInput = String(propName);
            let wordsArray = stringInput.split('_');
            let result = '';
            if (wordsArray.length) {
                let firstWord = wordsArray.shift();
                firstWord = firstWord[0].toUpperCase() + firstWord.slice(1);
                wordsArray.unshift(firstWord);
                result = wordsArray.join(' ');
            }
            return result;
        } catch (error) {
            return propName;
        }
    };

    useEffect(() => {
        if (merchantId) {
            fetchAllMerchantProducts();
        } else {
            NotificationManager.info('Select the merchant', 'INFO');
        }
    }, [merchantId]);

    const fetchAllMerchantProducts = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setProducts(data.product);
            }
        };

        const errorHandler = (error) => {
            console.log('Error fetching products: ', error);
            setProducts([]);
        };

        try {
            const res = await getProductList(
                {
                    query_merchant: merchantId,
                    query_skip: 0,
                    query_count: 10000,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.error('Unexpected error while fetching products: ', error);
            setProducts([]);
        }
    };

    useEffect(() => {
        if (assetType) {
            if (assetType.properties && assetType.properties.length > 0) {
                const elements = assetType.properties.map((e, idx) => {
                    {
                        if (e.property_type === 'float') {
                            return (
                                <InputFieldWithValidation
                                    key={`asset_prop_float_${idx}`}
                                    className="mb-3"
                                    name={`properties.${e.property_name}`}
                                    required={e.property_required}
                                    label={`${renderPropertyName(
                                        e.property_name
                                    )}`}
                                    additional={`${e.property_description}(${e.property_type})`}
                                    type="number"
                                    step="0.1"
                                />
                            );
                        }
                        if (e.property_type === 'int') {
                            return (
                                <InputFieldWithValidation
                                    key={`asset_prop_int_${idx}`}
                                    className="mb-3"
                                    name={`properties.${e.property_name}`}
                                    required={e.property_required}
                                    label={`${renderPropertyName(
                                        e.property_name
                                    )}`}
                                    additional={`${e.property_description}(${e.property_type})`}
                                    type="number"
                                    step="1"
                                />
                            );
                        }
                        if (e.property_type === 'str') {
                            return (
                                <InputFieldWithValidation
                                    key={`asset_prop_str_${idx}`}
                                    className="mb-3"
                                    name={`properties.${e.property_name}`}
                                    required={e.property_required}
                                    label={`${renderPropertyName(
                                        e.property_name
                                    )}`}
                                    additional={`${e.property_description}(${e.property_type})`}
                                    type="text"
                                />
                            );
                        }
                        if (e.property_type === 'bool') {
                            return (
                                <CheckboxSwitch
                                    key={`asset_prop_bool_${idx}`}
                                    name={`properties.${e.property_name}`}
                                    label={renderPropertyName(
                                        `${e.property_name}; ${e.property_description}`
                                    )}
                                />
                            );
                        }
                        if (e.property_type === 'object_id') {
                            return (
                                <InputFieldWithValidation
                                    key={`asset_prop_object_id_${idx}`}
                                    className="mb-3"
                                    name={`properties.${e.property_name}`}
                                    required={e.property_required}
                                    label={`${renderPropertyName(
                                        e.property_name
                                    )}`}
                                    additional={`${e.property_description}(${e.property_type})`}
                                    type="text"
                                    step="1"
                                />
                            );
                        }
                        if (e.property_type === 'image') {
                            return (
                                <UploadFileWithPreview
                                    key={`asset_prop_image_${idx}`}
                                    className="col-md-6 mb-3"
                                    name={`properties.${e.property_name}`}
                                    type="text"
                                />
                            );
                        }
                    }
                });
                setAssetTypePropertiesElements(elements);
            }

            setAssetIdLists(assetType?.id_lists || []);
        }
    }, [assetType]);

    const fetchShops = async (app_id, merchant_id) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                let shopOpts = [];
                if (data?.shops?.length && Array.isArray(data.shops)) {
                    shopOpts = data?.shops?.map((item) => ({
                        _id: item?._id,
                        label: item?.settings?.str_name,
                        vlaue: item?._id,
                    }));
                }
                shopOpts.push(DEFAULT_POS_OPT);

                setShopsList(shopOpts);
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        getAdminShopList(
            {
                query_count: 1000,
                appid: app_id,
                merchant: merchant_id,
            },
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };

    useEffect(() => {
        if (defaultValues?.application && defaultValues?.merchant) {
            fetchShops(defaultValues.application, defaultValues.merchant);
        }
    }, [defaultValues]);

    const renderIdLists = () => {
        return idLists.map((item) => (
            <li key={item?.name}>
                {`${item?.name}: list of ids for model "${item?.id_model}"` ||
                    'Unknown list of ids'}
            </li>
        ));
    };

    const handleSubmit = (data) => {
        onSubmit(data);
    };

    return (
        <div>
            <NotificationContainer />
            <h4> Type: {`${defaultValues.type}`}</h4>
            <hr />
            <SmartForm onSubmit={handleSubmit} defaultValues={defaultValues}>
                <InputFieldWithValidation
                    className="mb-3"
                    name="name"
                    label="Name"
                    type="text"
                />
                <CheckboxSwitch
                    name="visible"
                    label="Visible"
                    className="mb-3"
                />
                <InputFieldWithValidation
                    className="mb-3"
                    name="description"
                    label="Description"
                    type="text"
                    required={true}
                />
                <InputFieldWithValidation
                    className="mb-3"
                    name="user_id"
                    label="User id"
                    type="text"
                    required={false}
                />
                {shopsList?.length ? (
                    <SelectFieldWithValidation
                        className="mb-3"
                        name="pos"
                        label="POS"
                        options={shopsList}
                        nameSelector="label"
                        valueSelector="_id"
                        defaultValue={pos}
                    />
                ) : null}

                {products?.length ? (
                    <SelectFieldWithValidation
                        className="mb-3"
                        name="product"
                        label="Product"
                        options={products}
                        nameSelector="name"
                        valueSelector="id"
                    />
                ) : null}

                <InputFieldWithValidation
                    className="mb-3"
                    name="external_reference"
                    label="External reference"
                    type="text"
                    required={false}
                />
                <InputFieldWithValidation
                    className="mb-3"
                    name="api_key"
                    label="API key"
                    type="text"
                    required={false}
                />
                <InputFieldWithValidation
                    className="mb-3"
                    name="secret"
                    label="Secret"
                    type="text"
                    required={false}
                />

                <InputGroup className="mb-3">
                    <InputGroup.Text>Coordinates</InputGroup.Text>
                    <Form.Control
                        aria-label="Lattitude"
                        type="text"
                        placeholder="60.xxxx"
                        name="coordinates.0"
                        value={coordinatesInput?.[0]}
                        onChange={(e) => {
                            setCoordinatesInput((prev) => [
                                e.target.value,
                                prev?.[1],
                            ]);
                            setCoordUpdated(true);
                        }}
                    />
                    <Form.Control
                        aria-label="Longitude"
                        type="text"
                        placeholder="24.xxxx"
                        name="coordinates.1"
                        value={coordinatesInput?.[1]}
                        onChange={(e) => {
                            setCoordinatesInput((prev) => [
                                prev?.[0],
                                e.target.value,
                            ]);
                            setCoordUpdated(true);
                        }}
                    />

                    <Button
                        variant={coordUpdated ? 'danger' : 'outline-secondary'}
                        id="find-coordinates"
                        type="button"
                        onClick={(e) =>
                            handleCoordinatesChanged(coordinatesInput || [0, 0])
                        }
                    >
                        Find
                    </Button>
                </InputGroup>
                <Map
                    className="mb-3"
                    latitude={coordinatesInput?.[0] || 0}
                    longitude={coordinatesInput?.[1] || 0}
                />

                {assetTypePropertiesElements}
                <span>Id lists for this asset (inherited from Asset type)</span>
                <br />
                <ul>{renderIdLists()}</ul>
                <ButtonActionForm
                    label="Save changes"
                    name="active"
                    value={true}
                    type="submit"
                />
            </SmartForm>

            <AddressTableModal
                addressList={addressList}
                show={showAddressListModal}
                onAddressSelected={onAddressSelected}
                onHide={handleCloseAddressListModal}
            ></AddressTableModal>
        </div>
    );
};

export default AssetEditForm;
