import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import RegModelsTable from './RegModelsTable';
import InlineSearch from '../../UI/InlineSearch';
import UserInfoContext from '../../../contexts/UserInfoContext';
import { NotificationManager } from 'react-notifications';
import { getMerchantOrderProcessList } from '../../../api/utils/orderModel/getMerchantOrderProcessList';
import setMerchantRegModel from '../../../api/utils/app/setMerchantRegModel';
import getAppInfo from '../../../api/utils/app/getAppInfo';
import RolePreviewModal from '../RolePreviewModal';

const MerchantRegModelSelector = (props) => {
    const pageTitle = `Merchant registration models`;

    const [currentModel, setCurrentModel] = useState(null);
    const [loading, setLoading] = useState();
    const [modelsList, setModelsList] = useState([]);
    const [keyword, setKeyword] = useState('');

    const [appFullInfo, setAppFullInfo] = useState(null);

    const [previewModel, setPreviewModel] = useState(null);
    const [showPreview, setShowPreview] = useState(false);

    const userInfo = useContext(UserInfoContext);
    const merchantId = userInfo?.merchant?.id;
    const appId = userInfo?.app?.id;

    useEffect(() => {
        if (appId) {
            setLoading(true);
            const errorHandler = (error) => {
                NotificationManager.error(error.description, 'Error', 4000);
                setLoading(false);
            };
            getAppInfo({ appid: appId }, errorHandler).then((res) => {
                if (res?.status === 'accept') setAppFullInfo(res?.application);
                setLoading(false);
            });
        }
    }, [appId, userInfo]);

    useEffect(() => {
        if (appFullInfo && modelsList.length) {
            const appModelId =
                appFullInfo?.settings?.merchant_registration_model;
            const currModel = modelsList.find(
                (item) => item?._id === appModelId
            );
            setCurrentModel(currModel);
        }
    }, [appFullInfo, modelsList]);

    const fetchOrderModelList = () => {
        setLoading(true);
        getMerchantOrderProcessList({
            merchantId: null,
            appid: appId,
            order_type: 'merchant_registration',
        })
            .then(({ orderprocesses }) => {
                setLoading(false);
                return orderprocesses;
            })
            .then((res) => {
                setModelsList(res);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchOrderModelList();
    }, [merchantId]);

    const handleUpdateMerchantRegModel = (item) => {
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
        };
        setCurrentModel(item);
        if (item && item?._id && appId) setLoading(true);
        setMerchantRegModel(
            {
                application_id: appId,
                merchant_registration_model: item?._id,
            },
            errorHandler
        ).then((res) => {
            setLoading(false);
        });
    };

    const onModelSelected = (item) => {
        handleUpdateMerchantRegModel(item);
    };

    let onRowClick = (row) => {
        handleUpdateMerchantRegModel(row);
    };

    const onPreviewClick = (row) => {
        setPreviewModel(row);
        setShowPreview(true);
    };

    const onHidePreview = () => {
        setPreviewModel(null);
        setShowPreview(false);
    };

    return (
        <div className="container-fluid">
            <h2>{pageTitle}</h2>
            <Link
                to={`/ordermodel`}
                className="btn btn-outline-primary mt-1 mb-3"
            >
                <i className="bi-plus-square ml-1 mr-2 " /> Create registration
                model
            </Link>
            <div className="row">
                <div className="col">
                    <InlineSearch setKeyword={setKeyword} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {appId ? (
                        <RegModelsTable
                            currentModel={currentModel}
                            models={modelsList}
                            keyword={keyword}
                            loading={loading}
                            emptyText="The models list is empty"
                            onModelSelected={onModelSelected}
                            onRowClicked={onRowClick}
                            onPreviewClick={onPreviewClick}
                        />
                    ) : (
                        <label>Please, select application first</label>
                    )}
                </div>
            </div>
            <RolePreviewModal
                model={previewModel}
                show={showPreview}
                onHide={onHidePreview}
            />
        </div>
    );
};

export default MerchantRegModelSelector;
