import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import CheckboxSwitch from '../Form/elements/CheckboxSwitch';
import _ from 'lodash';
import mapSettingName from './helpers/mapSettingName';

const UserPublicSettingsForm = ({
  settings = {},
  className = '',
  label = '',
}) => {
  const renderSettings = (st) => {
    if (_.isEmpty(st))
      return (
        <tr>
          <td colSpan={2}>No settings</td>
        </tr>
      );

    return Object.keys(st).map((item) => (
      <tr key={item}>
        <td style={{ verticalAlign: 'middle' }}>
          <span className="cap-first">{mapSettingName(item)}</span>
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <CheckboxSwitch
            className="m-0"
            name={`public_settings.${item || ''}`}
            label="Visible"
          />
        </td>
      </tr>
    ));
  };

  return (
    <div className={className}>
      <div className="my-1">{label}</div>
      <Table striped hover size="sm">
        <thead>
          <tr>
            <th colSpan={1} className="w-75">
              Setting name
            </th>
            <th colSpan={1} className="w-25">
              Public access
            </th>
          </tr>
        </thead>
        <tbody>{renderSettings(settings)}</tbody>
      </Table>
    </div>
  );
};

export default UserPublicSettingsForm;
