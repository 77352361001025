import { useEffect, useState } from 'react';
import ShopInfoForm from './ShopInfoForm';
import useSearchString from '../../utils/useSearchString';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import prepareToPublish from './helpers/prepareToPublish';
import getAdminShop from './../../api/utils/shop/getAdminShop';
import { adminUpdateShop } from '../../api/utils/shop/setAdminShop';

const ShopInfo = () => {
    const [shopInfo, setShopInfo] = useState(null);
    const { merchant = null, shop = null } = useSearchString();

    const [submitLoading, setSubmitLoading] = useState(false);

    const saveShop = async (data) => {
        setSubmitLoading(true);
        const resultHandler = (data) => {
            setSubmitLoading(false);
            if (data && data.status === 'accept') {
                NotificationManager.success('Saved');
                setShopInfo(data.shop);
                return data.shop;
            } else {
                NotificationManager.error('Error');
                return null;
            }
        };
        const errorHandler = (error) => {
            setSubmitLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };
        try {
            const res = await adminUpdateShop(
                { ...prepareToPublish(merchant, shop, data) },
                errorHandler
            );
            return resultHandler(res);
        } catch (error) {
            console.log('error updating shop: ', error);
            setSubmitLoading(false);
        }
    };
    return (
        <div>
            <NotificationContainer />
            <ShopInfoForm
                onSubmit={saveShop}
                id={shop}
                submitLoading={submitLoading}
            />
        </div>
    );
};

export default ShopInfo;
