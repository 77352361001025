import Honkio from '../../Honkio';

const editMessage = (options, errorHandler = {}) => {
  const { id, text, application, merchant } = options;
  return Honkio().mainShop.userFetch(
    'chatmessageset',
    {
      id,
      text,
      application,
      merchant,
      read: true,
      version: 3,
      action: 'update',
    },
    errorHandler
  );
};

export default editMessage;
