import Honkio from '../../Honkio';

// add/remove specified `asset_ids` to `user.customfields.<field_name>` field
// used for assets, coupons, etc. lists of ids on user customfields

/**
 *
 * @param {*} options - user_ids, asset_ids, role_id, asset_type, field_name
 * @param {*} errorHandler
 * @returns
 */
const grantAssets = async (options = {}, errorHandler = () => {}) => {
  const { role_id, user_ids, asset_ids, asset_type, merchant, asset_info } =
    options;
  return Honkio().mainShop.userFetch(
    'consumerassetgrant',
    {
      role_id,
      user_ids,
      asset_ids,
      asset_type,
      merchant,
      asset_info,
      version: 3,
      action: 'create',
    },
    errorHandler
  );
};

export default grantAssets;
