import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';
import getNotifymessageTemplate from '../../../api/utils/templateModels/templateNotifymessages/getNotifymessageTemplate';
import languages from '../../../__fixtures__/languages';
import variables from '../../../__fixtures__/variables';
import UserInfoContext from '../../../contexts/UserInfoContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import cn from 'classnames';

const INITIAL_NOTIFYMESSAGE_TEMPLATE = {
    active: true,

    name: '',

    subject: '',
    message: '',

    subject__fi: '',
    subject__sv: '',
    subject__en: '',
    subject__no: '',
    subject__da: '',

    message__fi: '',
    message__sv: '',
    message__en: '',
    message__no: '',
    message__da: '',
};

const LANGUAGES = [{ value: '', name: 'Default' }, ...languages];

const putIntoHtmlTag = (msg = '', variable = '') => {
    try {
        if (!msg || !variable) return `${msg}` + `${variable}`;
        const reg = /(<([^>]+)>)$/g;
        const closingTag = msg.match(reg);
        const msgNoClosingTag = msg.replace(reg, '');
        return msgNoClosingTag + variable + closingTag;
    } catch (error) {
        console.error('error in putIntoHtmlTag', error);
        return msg;
    }
};

const TemplateNotifymessageForm = ({
    id = null,
    onSubmit = async () => {},
    submitLoading = false,
}) => {
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [templateNotifymessage, setTemplateNotifymessage] = useState(
        INITIAL_NOTIFYMESSAGE_TEMPLATE
    );

    const [selectedLanguage, setSelectedLanguage] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id) fetchCurrentNotifymessageTemplate(id);
    }, [id]);

    const fetchCurrentNotifymessageTemplate = async (id) => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data?.status === 'accept') {
                const notifymessageData = data.notify_message_template;
                setTemplateNotifymessage(notifymessageData);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setTemplateNotifymessage({});
        };

        try {
            setLoading(true);
            const res = await getNotifymessageTemplate(
                { id, merchant: merchantId, application: appId },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
            setTemplateNotifymessage({});
        }
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        if (!merchantId || !appId) {
            NotificationManager.error(
                'Please, select app and merchant',
                'Error'
            );
            return;
        }

        const submitSettings = _.cloneDeep(templateNotifymessage);
        submitSettings.merchant = merchantId;
        submitSettings.application = appId;

        await onSubmit(submitSettings);
    };

    const handleChangeTemplateNotifymessage = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        setTemplateNotifymessage((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    const handleSelectLanguage = (e) => {
        setSelectedLanguage(e.target.value);
    };

    const handleMessageChange = (arg, editor, lang) => {
        const newMsgName = lang.value ? `message__${lang.value}` : 'message';
        setTemplateNotifymessage((prev) => ({
            ...prev,
            [newMsgName]: editor.getData(),
        }));
    };

    const handleChangeNotifymessage = (e) => {
        const newMsg = _.cloneDeep(templateNotifymessage);
        newMsg[e.target.name] = e.target.value;
        setTemplateNotifymessage(newMsg);
    };

    const handleAddVariable = (val, lang) => {
        const newMsg = _.cloneDeep(templateNotifymessage);
        newMsg[lang.value ? `message__${lang.value}` : 'message'] =
            putIntoHtmlTag(
                newMsg[lang.value ? `message__${lang.value}` : 'message'],
                `<span>${val}</span>`
            );
        setTemplateNotifymessage(newMsg);
    };

    return (
        <Form onSubmit={handleSubmitForm}>
            <Form.Group className="mb-3" controlId="data.name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Notifymessage name"
                    name="name"
                    value={templateNotifymessage?.name || ''}
                    onChange={handleChangeTemplateNotifymessage}
                />
                <Form.Text className="text-muted">
                    Name of the notify message created from this template
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.active">
                <Form.Label>Active</Form.Label>
                <Form.Check // prettier-ignore
                    type="switch"
                    name="active"
                    id="active"
                    checked={templateNotifymessage?.active || false}
                    onChange={(e) =>
                        handleChangeTemplateNotifymessage({
                            target: {
                                name: 'active',
                                value: !templateNotifymessage?.active,
                            },
                        })
                    }
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.language">
                <Form.Label>Language</Form.Label>
                <Form.Control
                    as={'select'}
                    aria-label="Language"
                    name="language"
                    value={selectedLanguage}
                    onChange={handleSelectLanguage}
                    className="custom-select"
                >
                    {LANGUAGES.map((item, index) => (
                        <option
                            key={`${index}-${item?.value}-${item?.name}-lang-option`}
                            value={item?.value}
                        >
                            {item?.name}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text className="text-muted">
                    Select language for subject and message
                </Form.Text>
            </Form.Group>

            {LANGUAGES.map((lang, index) => {
                let classname = lang.value != selectedLanguage ? 'd-none' : '';
                const messageText = `${
                    templateNotifymessage[
                        lang.value ? `message__${lang.value}` : 'message'
                    ] || ''
                }`;
                let ggg = '';
                if (messageText.includes('text:')) ggg = messageText;
                if (!messageText.includes('text:'))
                    ggg = `text: ${messageText}`;

                return (
                    <div className={classname} key={index}>
                        <Form.Group controlId="message.name">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                                name={
                                    lang.value
                                        ? `subject__${lang.value}`
                                        : 'subject'
                                }
                                value={
                                    lang.value
                                        ? templateNotifymessage[
                                              `subject__${lang.value}`
                                          ]
                                        : templateNotifymessage['subject'] || ''
                                }
                                onChange={handleChangeNotifymessage}
                                type="text"
                                placeholder="Subject"
                                required
                            />
                            <Form.Text className="text-muted">
                                Enter email subject
                            </Form.Text>
                        </Form.Group>

                        <a
                            href="#"
                            onClick={() => {
                                ggg = ggg + messageText;
                            }}
                        >
                            {ggg}
                        </a>
                        <div className="my-3">
                            <CKEditor
                                editor={ClassicEditor}
                                data={ggg}
                                onChange={(arg, editor) =>
                                    handleMessageChange(arg, editor, lang)
                                }
                            />
                        </div>

                        <Form.Group controlId="message.variable">
                            <Form.Label>Select variable</Form.Label>

                            <Form.Control
                                as="select"
                                name="message.variable"
                                value={''}
                                onChange={(e) =>
                                    handleAddVariable(e.target.value, lang)
                                }
                                className={cn('custom-select', 'crsr')}
                            >
                                <option value="">Add variable</option>
                                {variables.map((opt, i) => (
                                    <option
                                        key={`logo-option-${i}-${opt?.value}`}
                                        value={opt?.value}
                                    >
                                        {opt?.name}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Text className="text-muted">
                                Add variable to the message
                            </Form.Text>
                        </Form.Group>
                    </div>
                );
            })}

            <Button
                type="submit"
                variant="primary"
                disabled={loading || submitLoading}
                onClick={handleSubmitForm}
            >
                Submit{' '}
                {loading || submitLoading ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : null}
            </Button>
        </Form>
    );
};

export default TemplateNotifymessageForm;
