import { useEffect, useState } from 'react';
import useSearchString from '../../utils/useSearchString';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import getAssetStructure from '../../api/utils/asset/structures/getAssetStructure';
import { updateAssetStructure } from '../../api/utils/asset/structures/setAssetStructure';
import useLoader from '../UI/helpers/useLoader';
import SmartForm from '../Form/SmartForm';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import UploadFileWithPreview from '../Form/elements/UploadFileWithPreview';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import ButtonActionForm from '../Form/elements/ButtonActionForm';
import LoadingBar from '../UI/LoadingBar';

const AssetStructureEdit = () => {
  const { assetstructure, merchant } = useSearchString();
  const [formData, setFormData] = useState();
  const [info, setInfo] = useState({});
  const [progress, done] = useLoader([formData, info]);

  const fetchAssetStructure = async (id) => {
    const resultHandler = (data) => {
      setInfo(data?.asset_structure);
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    const res = await getAssetStructure({ id }, errorHandler);
    if (res && res.status === 'accept') {
      return resultHandler(res);
    }
  };

  useEffect(() => {
    if (assetstructure) fetchAssetStructure(assetstructure);
  }, [assetstructure]);

  useEffect(() => {
    if (info && Object.keys(info).length !== 0) {
      setFormData(info);
    } else {
      setFormData(null);
    }
  }, [info]);

  const onSubmit = async (data) => {
    console.log('onSubmit: data == ', data);

    const resultHandler = (data) => {
      NotificationManager.success('Updated successfully');
      setInfo(data?.asset_structure);
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    const res = await updateAssetStructure(
      { ...data, id: info?._id },
      errorHandler
    );
    if (res && res.status === 'accept') {
      return resultHandler(res);
    }
  };

  return done ? (
    formData ? (
      <div>
        <NotificationContainer />
        <SmartForm
          onValid={() => {}}
          onSubmit={onSubmit}
          defaultValues={formData}
        >
          <h3 className="d-flex justify-content-between">
            <span>Edit asset structure (ID#{info._id})</span>
          </h3>

          <hr />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <InputFieldWithValidation
                  className="mb-3"
                  label="name"
                  name="name"
                />
              </div>
            </div>
          </div>
          <ButtonActionForm
            label="Save changes"
            name="active"
            value={true}
            type="submit"
            className="btn btn-primary mt-2"
          />
          <br />
        </SmartForm>
      </div>
    ) : (
      'not found'
    )
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default AssetStructureEdit;
