import React, { useEffect, useState, useContext } from 'react';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import { getFile } from '../../../../api/utils/files/minio/getFile';
import useSearchString from '../../../../utils/useSearchString';
import UserInfoContext from '../../../../contexts/UserInfoContext';
import BackButton from '../../../UI/BackButton';

const VideoView = () => {
    const { file_id } = useSearchString();

    const [file, setFile] = useState(null);

    const [minioUrl, setMinioUrl] = useState('');

    const {
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const fetchFile = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setFile(data.file);
                setMinioUrl(data.minio_url);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setFile(null);
            setMinioUrl('');
        };

        try {
            const res = await getFile({ id: file_id }, errorHandler).then(
                (data) => {
                    return resultHandler(data);
                }
            );
            resultHandler(res);
        } catch (error) {
            console.log('Unexpected error while loading file: ', error);
        }
    };

    useEffect(() => {
        if (file_id && merchantId) {
            fetchFile();
        }
    }, [file_id, merchantId]);

    return (
        <div
            striped
            hover
            style={{
                tableLayout: 'fixed',
            }}
        >
            <h2>
                <BackButton to="/debug/video-streaming" text="Back to list" />{' '}
                {file_id ? `#${file_id}` : null}
            </h2>
            <NotificationContainer />
            <video controls>
                <source src={minioUrl}></source>
            </video>
        </div>
    );
};

export default VideoView;
