import { useEffect, useMemo, useState } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal, Button } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import DataTable from 'react-data-table-component';
import { NotificationManager } from 'react-notifications';
import { getCustomerType } from '../../api/utils/customer/type/getCustomerType';

const CustomerTypeModal = ({ customerTypeId, show, onHide }) => {
    const [customerType, setCustomerType] = useState();
    const [properties, setProperties] = useState([]);
    const [progress, done] = useLoader([customerType]);

    useEffect(() => {
        if (customerTypeId) {
            fetchCustomerType();
        }
    }, [customerTypeId]);

    const fetchCustomerType = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setCustomerType(data.customer_type);
            } else {
                setCustomerType({});
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setCustomerType({});
        };

        try {
            const res = await getCustomerType(
                { id: customerTypeId },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.error(
                'Unexpected error while fetching customer type: ',
                error
            );
        }
    };

    useEffect(() => {
        if (customerType?.properties) {
            setProperties(customerType.properties);
        } else {
            setProperties([]);
        }
    }, [customerType]);

    const columns = useMemo(() => [
        {
            name: 'Name',
            cell: ({ property_name }) => property_name,
            sortable: true,
        },
        {
            name: 'Description',
            cell: ({ property_description }) => property_description,
            sortable: true,
        },
        {
            name: 'Required',
            cell: ({ property_required }) => property_required,
            sortable: true,
        },
        {
            name: 'Type',
            cell: ({ property_type }) => property_type,
            sortable: true,
        },
    ]);

    const container = (
        <div className="container">
            <div className="col-13">
                <label className="font-weight-bold mr-2">Name</label>
                <label>{customerType?.name}</label>
                <br />
                <label className="font-weight-bold mr-3">Description</label>
                <label>{customerType?.description || '-'}</label>
                <br />
                <label className="font-weight-bold mr-3">Properties</label>
                <DataTable
                    dense
                    striped
                    noHeader
                    noDataComponent="no permissions"
                    highlightOnHover
                    data={properties}
                    columns={columns}
                />
            </div>
        </div>
    );

    const onClickHide = () => {
        onHide();
    };

    return done ? (
        <Modal show={show} onHide={onClickHide}>
            <Modal.Header closeButton>
                <Modal.Title>Customer type</Modal.Title>
            </Modal.Header>
            <Modal.Body>{customerType ? container : 'not found'}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    ) : (
        <LoadingBar progress={progress} />
    );
};

export default CustomerTypeModal;
