import Honkio from '../../Honkio';

const setUserRegModel = (options = {}, errorHandler = {}) => {
  const { application_id, user_registration_model } = options;
  return Honkio().mainShop.userFetch(
    'adminappregmodelsuserset',
    {
      application_id,
      user_registration_model,
      version: 3,
      action: 'update',
    },
    errorHandler
  );
};

export default setUserRegModel;
