import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Icon from '../../UI/Icon';
import _ from 'lodash';

const ExtraArgInput = ({
  arg,
  index,
  onChange = () => {},
  onRemove = () => {},
}) => {
  const handleChangeField = (e) => {
    const updatedArg = _.cloneDeep(arg);
    updatedArg[e.target.name] = e.target.value;
    onChange(index, updatedArg);
  };

  return (
    <InputGroup className="mb-3">
      <Form.Control
        aria-label="key"
        name="name"
        value={arg?.name}
        onChange={handleChangeField}
        required
      />
      <Form.Control
        aria-label="value"
        name="value"
        value={arg?.value}
        onChange={handleChangeField}
        required
      />
      <Button
        variant="light"
        type="button"
        className="m-0 py-0"
        onClick={() => onRemove(arg?.id)}
      >
        <Icon iconName="btn bi-trash" color="var(--danger)" />
      </Button>
    </InputGroup>
  );
};

export default ExtraArgInput;
