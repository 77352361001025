const InputField = ({
  item,
  index,
  field = 'field',
  group = 'default',
  type = 'text',
  name,
  label,
  className,
  register,
  defaultValue,
  required = false,
}) => {
  name = name ?? `${group}[${index}].${field}`;
  defaultValue = defaultValue ?? item?.[field];
  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        ref={register}
        type={type}
        className="form-control"
        name={name}
        defaultValue={defaultValue}
        required={required}
      />
    </div>
  );
};

export default InputField;
