import React from 'react';
import {
    Form,
    Button,
    Row,
    Col,
    Card,
    Table,
    Accordion,
} from 'react-bootstrap';
import Icon from '../../UI/Icon';

const CronFieldsInfo = () => {
    return (
        <Accordion>
            <Card>
                <Card.Header>
                    <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="0"
                        className="w-100 d-flex justify-content-between align-items-center"
                    >
                        <div>Cron expressions info</div>
                        <div>
                            <Icon iconName="bi-info-circle" />
                        </div>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div>
                            The following table lists all the available
                            expressions for use in the fields from year to
                            second. Multiple expression can be given in a single
                            field, separated by commas.
                        </div>
                        <Table striped bordered hover>
                            <thead valign="bottom">
                                <tr className="row-odd">
                                    <th className="head">Expression</th>
                                    <th className="head">Field</th>
                                    <th className="head">Description</th>
                                </tr>
                            </thead>
                            <tbody valign="top">
                                <tr className="row-even">
                                    <td>
                                        <code className="docutils literal notranslate">
                                            <span className="pre">*</span>
                                        </code>
                                    </td>
                                    <td>any</td>
                                    <td>Fire on every value</td>
                                </tr>
                                <tr className="row-odd">
                                    <td>
                                        <code className="docutils literal notranslate">
                                            <span className="pre">*/a</span>
                                        </code>
                                    </td>
                                    <td>any</td>
                                    <td>
                                        Fire every{' '}
                                        <code className="docutils literal notranslate">
                                            <span className="pre">a</span>
                                        </code>{' '}
                                        values, starting from the minimum
                                    </td>
                                </tr>
                                <tr className="row-even">
                                    <td>
                                        <code className="docutils literal notranslate">
                                            <span className="pre">a-b</span>
                                        </code>
                                    </td>
                                    <td>any</td>
                                    <td>
                                        Fire on any value within the{' '}
                                        <code className="docutils literal notranslate">
                                            <span className="pre">a-b</span>
                                        </code>{' '}
                                        range (a must be smaller than b)
                                    </td>
                                </tr>
                                <tr className="row-odd">
                                    <td>
                                        <code className="docutils literal notranslate">
                                            <span className="pre">a-b/c</span>
                                        </code>
                                    </td>
                                    <td>any</td>
                                    <td>
                                        Fire every{' '}
                                        <code className="docutils literal notranslate">
                                            <span className="pre">c</span>
                                        </code>{' '}
                                        values within the{' '}
                                        <code className="docutils literal notranslate">
                                            <span className="pre">a-b</span>
                                        </code>{' '}
                                        range
                                    </td>
                                </tr>
                                <tr className="row-even">
                                    <td>
                                        <code className="docutils literal notranslate">
                                            <span className="pre">xth</span>{' '}
                                            <span className="pre">y</span>
                                        </code>
                                    </td>
                                    <td>day</td>
                                    <td>
                                        Fire on the{' '}
                                        <code className="docutils literal notranslate">
                                            <span className="pre">x</span>
                                        </code>{' '}
                                        -th occurrence of weekday{' '}
                                        <code className="docutils literal notranslate">
                                            <span className="pre">y</span>
                                        </code>{' '}
                                        within the month
                                    </td>
                                </tr>
                                <tr className="row-odd">
                                    <td>
                                        <code className="docutils literal notranslate">
                                            <span className="pre">last</span>{' '}
                                            <span className="pre">x</span>
                                        </code>
                                    </td>
                                    <td>day</td>
                                    <td>
                                        Fire on the last occurrence of weekday{' '}
                                        <code className="docutils literal notranslate">
                                            <span className="pre">x</span>
                                        </code>{' '}
                                        within the month
                                    </td>
                                </tr>
                                <tr className="row-even">
                                    <td>
                                        <code className="docutils literal notranslate">
                                            <span className="pre">last</span>
                                        </code>
                                    </td>
                                    <td>day</td>
                                    <td>
                                        Fire on the last day within the month
                                    </td>
                                </tr>
                                <tr className="row-odd">
                                    <td>
                                        <code className="docutils literal notranslate">
                                            <span className="pre">x,y,z</span>
                                        </code>
                                    </td>
                                    <td>any</td>
                                    <td>
                                        Fire on any matching expression; can
                                        combine any number of any of the above
                                        expressions
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <a
                            target="_blank"
                            href="https://apscheduler.readthedocs.io/en/3.x/modules/triggers/cron.html#module-apscheduler.triggers.cron"
                        >
                            Apscheduler docs
                        </a>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default CronFieldsInfo;
