export const maxTimeIntervals = 48
export const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const hour8 = 8 * 2 * 100 / maxTimeIntervals
const hour18 = 18 * 2 * 100 / maxTimeIntervals
export const defaultActiveDays=[false, true, true, true, true, false, false]

function parseTime(time) {
    let timeInt = parseInt(time);
    let minutes = time.substring(3, 5);

    // you could then add or subtract time here as needed

    if (time > '12:00') {
        return `${timeInt - 12}:${minutes} PM`;
    } else {
        return `${timeInt}:${minutes} AM`;
    }
}
const zeroPad = num => String(num).padStart(2, "0");
export const timesObject = () => {
    let result = []
    for (let i = 0; i < maxTimeIntervals / 2; i++) {
        const timeNext = `${zeroPad(i)}:00`
        result.push({
            value: i*2,
            label: parseTime(timeNext),
            time: timeNext

        });
        const timeNextHalf = `${zeroPad(i)}:30`
        result.push({
            value: i*2+1,
            label: parseTime(timeNextHalf),
            time: timeNextHalf

        });
    }
    result.push({
        value: maxTimeIntervals,
        label: '11:59 PM',
        time: '23:59'
    })
    return result
}
export const marksObject = () => {
    let result = []
    for (let i = 0; i < maxTimeIntervals; i++) {
        result.push({
            value: i*100/maxTimeIntervals,
            label: ''
        });
    }
    result.push({
        value: 100,
        label: '',
    })
    return result
}
export const getDefaultOpenTimes  = () => {
    let result = []
    for (let i = 0; i < days.length; i++) {
        result.push( {
            day: days[i],
            is_active: defaultActiveDays[i],
            interval: ["08:00", "20:00"]
        });
    }
    return result
}
export const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();