import Honkio from '../../Honkio';

const getProduct = (options = {}, errorHandler = {}) => {
  const { id, debug } = options;
  return Honkio().mainShop.userFetch(
    'adminproductget',
    {
      debug,
      id,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};
export default getProduct;
