import Honkio from '../../Honkio';

const getAppInfo = (options = {}, errorHandler = {}) => {
    const { appid, classname, debug } = options;
    return Honkio().mainShop.userFetch(
        'adminappget',
        {
            debug,
            id: appid,
            classname,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
export default getAppInfo;
