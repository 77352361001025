import { useEffect, useState } from 'react';
import {
    useHistory,
    useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import getMerchantData from '../../api/utils/merchant/getMerchantData';
import MerchantInfoForm from './MerchantInfoForm';
import LoadingBar from '../UI/LoadingBar';
import useLoader from '../UI/helpers/useLoader';
import { NotificationManager } from 'react-notifications';
import qs from 'qs';
import { merchantSet } from '../../api/utils/merchant/setMerchant';
import prepareToPublish from './helpers/prepareToPublish';
import deleteMerchant from '../../api/utils/merchant/deleteMerchant';

const MerchantInfo = () => {
    const [merchantInfo, setMerchantInfo] = useState(null);
    const { merchant } = qs.parse(useLocation().search, {
        ignoreQueryPrefix: true,
    });
    const history = useHistory();
    const [progress, done] = useLoader([merchantInfo]);
    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (merchant) {
            fetchMerchant();
        }
    }, [merchant]);

    const fetchMerchant = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data?.status === 'accept' && data?.merchant) {
                    setMerchantInfo(data?.merchant);
                } else {
                    setMerchantInfo({});
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setMerchantInfo({});
        };

        getMerchantData({ id: merchant }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const onSubmit = (merchant) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Successfully saved');
                setMerchantInfo(data?.merchant);
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };
        const newMerchant = prepareToPublish(merchant);
        merchantSet(newMerchant, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const onDelete = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Successfully deleted');
                history.push('/merchants');
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            const res = await deleteMerchant(merchantInfo?._id, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.log('error removing merchant; ', error);
        }
    };

    return done ? (
        merchantInfo?._id ? (
            <div>
                <MerchantInfoForm
                    merchantInfo={merchantInfo}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                />
            </div>
        ) : (
            'not found'
        )
    ) : (
        <LoadingBar progress={progress} />
    );
};

export default MerchantInfo;
