import React, { useState, useEffect, useContext } from 'react';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import UserInfoContext from '../../contexts/UserInfoContext';
import cn from 'classnames';

const SelectAsync = ({
    className,
    placeholder = 'Items',
    isMulti = false,
    defaultValues = [],
    response_key = 'products',
    selectedItems = [],
    setSelectedItems = () => {},
    itemsFetcher = async () => {},
    getOptionLabel = (option) => option.label,
    getOptionValue = (option) => option.value,
    uniqueKey = '_id',
    perPage = 100,
}) => {
    const [itemsList, setItemsList] = useState([]);
    const [itemsTotal, setItemsTotal] = useState(0);
    const [itemsLoaded, setItemsLoaded] = useState(0);
    // const [selectedItems, setSelectedItems] = useState();
    const [itemsPage, setItemsPage] = useState(1);

    const [loading, setLoading] = useState(false);

    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async (search = '') => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data?.status === 'accept') {
                // , ...data?.additional]
                setItemsList((prev) => {
                    return _.uniqBy([
                        ...prev,
                        ...data?.[response_key],
                        (a) => a?.[uniqueKey],
                    ]);
                });
                setItemsTotal(data?.total);
                if (itemsLoaded < data?.total)
                    setItemsLoaded((prev) => {
                        return prev + data?.[response_key]?.length;
                    });
                setLoading(false);
                return data?.[response_key];
            } else {
                setItemsList([]);
                setItemsTotal(0);
                setLoading(false);
                return [];
            }
        };

        const errorHandler = (error) => {
            setItemsList([]);
            setItemsTotal(0);
            setLoading(false);
        };

        if (!merchantId || !appId) return;

        let additionalIds = null;
        if (selectedItems?.length) {
            additionalIds = selectedItems.map((item) => item.id);
        }

        const res = await itemsFetcher(
            {
                query_skip: (+itemsPage - 1) * perPage,
                query_count: perPage,
                query_merchant: merchantId,
                query_application: appId,
                search,
            },
            errorHandler
        );
        if (res && res?.status === 'accept') {
            return resultHandler(res);
        } else {
            return [];
        }
    };

    const handleScrollToBottomItems = (e) => {
        if (itemsLoaded < itemsTotal) {
            setItemsPage((prev) => prev + 1);
        }
    };

    const promiseOptionsItems = async (inputValue) => {
        setItemsList([]);
        setItemsLoaded(0);
        setItemsTotal(0);

        return await fetchItems(inputValue);
    };

    return (
        <AsyncSelect
            className={cn(className, 'flex-grow-1', 'flex-shrink-1')}
            placeholder={placeholder}
            onMenuScrollToBottom={handleScrollToBottomItems}
            cacheOptions={itemsList}
            defaultOptions={itemsList}
            isLoading={loading}
            value={selectedItems}
            defaultValue={defaultValues}
            onChange={setSelectedItems}
            options={itemsList}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isSearchable
            isMulti={isMulti}
            isClearable={true}
            loadOptions={promiseOptionsItems}
        />
    );
};

export default SelectAsync;
