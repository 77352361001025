const prepareInfo = (info) => {
  const {
    _id,
    name,
    description,
    order_sum_limit,
    usage_limit,
    sum_by_period_limit,
    action,
    order_types,
    active,
  } = info;
  const result = {
    _id,
    name,
    description,
    order_sum_limit: order_sum_limit || 0,
    usage_limit_period: usage_limit?.period || 0,
    usage_limit_amount: usage_limit?.amount || 0,
    sum_by_period_limit_period: sum_by_period_limit?.period || 0,
    sum_by_period_limit_sum: sum_by_period_limit?.sum || 0,
    action_notify_aml: action?.notify_aml || false,
    action_abort: action?.abort || false,
    action_change_risk_rating: action?.change_risk_rating || 0,
    order_types,
    active,
  };
  return result;
};

export default prepareInfo;
