const defaultUserIds = [
    'first_name_user',
    'last_name_user',
    'address_user',
    'phone_user',
    'alias_user',
    'day_of_birth_user',
    'password_user',
];

const defaultUserRegistrationRequirements = [
    {
        id: 'first_name_user',
        required: true,
        name: 'First name',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'last_name_user',
        required: true,
        name: 'Last name',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'address_user',
        required: false,
        name: 'Address',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'phone_user',
        required: false,
        name: 'Phone',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'alias_user',
        required: false,
        name: 'Alias',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'day_of_birth_user',
        required: false,
        name: 'Day Of Birth',
        mandatory: false,
        type: 'date',
    },
    {
        id: 'password_user',
        required: false,
        name: 'Password',
        mandatory: false,
        type: 'str',
    },
];
const convertToKeyValueDict = (arrayObj) => {
    const val = {};
    arrayObj.forEach((ob) => {
        val[ob.id] = ob;
    });
    return val;
};

const updateOrMerge = (a1, a2) => {
    const ob1 = convertToKeyValueDict(a1);
    const ob2 = convertToKeyValueDict(a2);
    const merged_obj = { ...ob1, ...ob2 };
    const val = Object.entries(merged_obj);
    return val.map((obj) => ({ ...obj[1] }));
};

const fillUserRegistrationRequirements = (regRequirements = []) => {
    const defaultRRUser = Array.isArray(regRequirements)
        ? regRequirements.filter((e) => {
              return defaultUserIds.includes(e.id);
          })
        : [];

    const customRRUser = Array.isArray(regRequirements)
        ? regRequirements.filter((e) => {
              return !defaultUserIds.includes(e.id);
          })
        : [];

    const user_registration_requirements = updateOrMerge(
        defaultUserRegistrationRequirements,
        defaultRRUser
    );

    const fields_user = customRRUser.map((e) => {
        return {
            name: e.name,
            mandatory: e.mandatory ? 'yes' : 'no',
            id: e.id,
            type: e.type,
        };
    });

    return {
        fields_user,
        user_registration_requirements,
    };
};

export default fillUserRegistrationRequirements;
