import './styles.css';
import Editor from '@monaco-editor/react';
import { useFormContext, useController } from 'react-hook-form';

const EditorComponent = ({ language = 'javascript', name }) => {
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <Editor
      height="75vh"
      defaultLanguage={language}
      name={name}
      id={name}
      value={field?.value || '// code'}
      onChange={field?.onChange}
      theme="vs-dark"
    />
  );
};

export default EditorComponent;
