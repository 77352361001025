import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Responsive as ResponsiveGridLayout,
    WidthProvider,
} from 'react-grid-layout';
import TemperatureWidgetComponent from './widgets/TemperatureWidgetComponent';
import { Button, ButtonGroup } from 'shards-react';
import TouchWidgetComponent from './widgets/TouchWidgetComponent';
import HumidityWidgetComponent from './widgets/HumidityWidgetComponent';

import ProximityWidgetComponent from './widgets/ProximityWidgetComponent';
import WidgetAddModal from './addModal/WidgetAddModal';
import AverageMaturityWidgetComponent from './widgets/AverageMaturityWidgetComponent';
import TenantsWidgetComponent from './widgets/TenantsWidgetComponent';
import TotalSquareWidgetComponent from './widgets/TotalSquareWidgetComponent';
import RentalCostWidgetComponent from './widgets/RentalCostWidgetComponent';
import BuildingInvoicesComponent from './widgets/BuildingInvoicesComponent';
import WidgetSettingsModal from './addModal/WidgetSettingsModal';
import BuildingIncomePieComponent from './widgets/BuildingIncomePieComponent';
import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';
import 'moment/locale/fi';
import Wait from '../UI/Wait';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';

import 'react-grid-layout/css/styles.css';
import getUserSettings from '../../api/utils/settings/user/get';
import {
    createUserSettings,
    updateUserSettings,
} from '../../api/utils/settings/user/set';
import UserInfoContext from '../../contexts/UserInfoContext';
import _ from 'lodash';
import NumUsersWidgetComponent from './widgets/NumUsersWidgetComponent';
import NumRegistrationsWidgetComponent from './widgets/NumRegistrationsWidgetComponent';

const ResponsiveReactGridLayout = WidthProvider(ResponsiveGridLayout);
const ROW_HEIGHT = 150;
const ROW_WIDTH = 125;
const Components = {
    temperature: TemperatureWidgetComponent,
    humidity: HumidityWidgetComponent,
    touch: TouchWidgetComponent,
    proximity: ProximityWidgetComponent,
    maturity: AverageMaturityWidgetComponent,
    tenants: TenantsWidgetComponent,
    square: TotalSquareWidgetComponent,
    rental_cost: RentalCostWidgetComponent,
    building_invoices: BuildingInvoicesComponent,
    building_income_pie: BuildingIncomePieComponent,
    num_users: NumUsersWidgetComponent,
    num_reg: NumRegistrationsWidgetComponent,
};

const DashboardComponent = (props) => {
    const userInfo = useContext(UserInfoContext);
    const [breakpoint, setBreakpoint] = useState('lg');
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [settingsModalVisible, setSettingsModalVisible] = useState(false);

    const [userSettings, setUserSettings] = useState(null);
    const [dashboard, setDashboard] = useState({
        positions: [],
        items: [],
    });
    const [period, setPeriod] = useState('month');
    const [startDate, setStartDate] = useState(
        moment().subtract(2, 'month').format('YYYY-MM-DD hh:mm:ss')
    );
    const [endDate, setEndDate] = useState(
        moment().add(15, 'days').format('YYYY-MM-DD hh:mm:ss')
    );

    useEffect(() => {
        // console.log('dashboard ==', dashboard);
    }, [dashboard]);

    const showSettingsModal = (i) => {
        setSettingsModalVisible(true);
    };

    const hideSettingsModal = () => {
        setSettingsModalVisible(false);
    };

    const toggleSettings = () => {
        setSettingsModalVisible((prev) => !prev);
    };

    const showModal = () => {
        setModalVisible(true);
    };

    const hideModal = () => {
        setModalVisible(false);
    };

    const toggle = () => {
        setModalVisible((prev) => !prev);
    };

    useEffect(() => {
        loadDashboard();
    }, [userInfo]);

    const loadDashboard = async () => {
        setLoading(true);

        let usersettingsget = async function () {
            try {
                const resultHandler = (data) => {
                    if (data && data?.status === 'accept') {
                        NotificationManager.success('Settings saved');
                        if (!_.isEmpty(data?.user_settings?.settings)) {
                            setDashboard(data?.user_settings?.settings);
                        }
                        setUserSettings(data?.user_settings);
                    }
                };

                const errorHandler = (error) => {
                    console.log('error', error);
                };
                const res = await getUserSettings(
                    {
                        application: userInfo?.app?.id,
                        merchant: userInfo?.merchant?.id,
                    },
                    errorHandler
                );

                resultHandler(res);

                setLoading(false);
            } catch (e) {
                console.error(e);
                setDashboard({
                    items: [],
                    positions: [],
                });
                setUserSettings(null);
                setLoading(false);
            }
        };

        usersettingsget();
    };

    const handleSetPeriod = (p) => {
        setPeriod(p);
        let newStartDate = '';
        let newEndDate = '';
        switch (p) {
            case 'day':
                newStartDate = moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD hh:mm:ss');
                newEndDate = moment().format('YYYY-MM-DD hh:mm:ss');
                break;
            case 'week':
                newStartDate = moment()
                    .subtract(7, 'days')
                    .format('YYYY-MM-DD hh:mm:ss');
                newEndDate = moment().format('YYYY-MM-DD hh:mm:ss');
                break;
            case 'month':
                newStartDate = moment()
                    .subtract(1, 'month')
                    .format('YYYY-MM-DD hh:mm:ss');
                newEndDate = moment().format('YYYY-MM-DD hh:mm:ss');
                break;
        }
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const onMoveCard = (newPosition) => {
        setDashboard((prev) => ({
            positions: newPosition,
            items: [...prev?.items],
        }));
    };

    const onResizeCard = (newPosition) => {
        setDashboard((prev) => ({
            positions: newPosition,
            items: [...dashboard?.items],
        }));
    };

    const removeWidet = (index) => {
        let positions = dashboard?.positions;
        let items = dashboard?.items;
        positions.splice(index, 1);
        items.splice(index, 1);
        setDashboard((prev) => ({
            positions: [...positions],
            items: [...items],
        }));
    };

    const onWidgetRename = (index, newName) => {
        let items = dashboard?.items;
        items[index]['name'] = newName;
        let newItems = [...items];
        let newDashboard = {
            positions: [...dashboard.positions],
            items: [...newItems],
        };
        setDashboard(newDashboard);
    };

    const addWidget = (name, asset) => {
        console.log('addWidget: name, asset ==', name, asset);
        if (!name) {
            NotificationManager.error('You need to provide widget name');
            return;
        }
        if (!asset?.group) {
            NotificationManager.error('You need to select widget type');
            return;
        }

        setDashboard((prev) => ({
            positions: [
                ...prev?.positions,
                {
                    w: 3,
                    h: 2,
                    x: 0,
                    y: 0,
                    i: uuidv4(),
                },
            ],
            items: [
                ...prev?.items,
                { type: asset.group, assetId: asset.id, name: name },
            ],
        }));

        hideModal();
    };

    const saveDashboard = async () => {
        const resultHandler = (data) => {
            if (data && data?.status === 'accept') {
                NotificationManager.success('Settings saved');
                setDashboard(data?.settings?.settings);
                setUserSettings(data?.settings);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            if (userSettings?._id) {
                // Update
                const res = await updateUserSettings(
                    {
                        id: userSettings?._id,
                        application: userInfo?.app?.id,
                        merchant: userInfo?.merchant?.id,
                        settings: dashboard,
                    },
                    errorHandler
                );
                resultHandler(res);
            } else {
                // Create
                const res = await createUserSettings(
                    {
                        application: userInfo?.app?.id,
                        merchant: userInfo?.merchant?.id,
                        settings: dashboard,
                    },
                    errorHandler
                );
                resultHandler(res);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onSaveSettingsClick = (newItems) => {
        setDashboard((prev) => ({
            positions: [...prev.positions],
            items: [...newItems],
        }));
        hideSettingsModal();
        // TODO: Save to database here
    };

    const createElem = (name, params) => {
        return Components[name]
            ? React.createElement(Components[name], { ...params })
            : null;
    };

    const onLayoutChange = (val) => {
        setDashboard((prev) => ({
            ...prev,
            positions: val,
        }));
    };

    return loading ? (
        <Wait />
    ) : userInfo?.app?.id && userInfo?.merchant?.id ? (
        <div className="m-3">
            <NotificationContainer />
            <h3>Dashboard</h3>
            <div className="d-flex">
                <div className="ml-auto p-2">
                    <ButtonGroup className="mr-2">
                        <Button
                            // disabled={true}
                            theme={period === 'day' ? 'dark' : 'light'}
                            onClick={() => handleSetPeriod('day')}
                        >
                            Day
                        </Button>
                        <Button
                            // disabled={true}
                            theme={period === 'week' ? 'dark' : 'light'}
                            onClick={() => handleSetPeriod('week')}
                        >
                            Week
                        </Button>
                        <Button
                            // disabled={true}
                            theme={period === 'month' ? 'dark' : 'light'}
                            onClick={() => handleSetPeriod('month')}
                        >
                            Month
                        </Button>
                    </ButtonGroup>

                    <Button
                        theme="secondary"
                        className="right"
                        onClick={showModal}
                    >
                        Create new card
                    </Button>

                    <Button
                        theme="success"
                        className="ml-2 right"
                        onClick={saveDashboard}
                    >
                        Save
                    </Button>
                </div>
            </div>

            <ResponsiveReactGridLayout
                className="position-relative"
                layouts={
                    dashboard && dashboard?.positions
                        ? dashboard?.positions
                        : []
                }
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                rowHeight={ROW_HEIGHT}
                measureBeforeMount={true}
                onBreakpointChange={(val) => {
                    console.log('onBreakpointChange; val ==', val);
                    setBreakpoint(val);
                }}
                onLayoutChange={(current, all) => {
                    console.log(
                        'onLayoutChange; current, all ==',
                        current,
                        all
                    );
                    onLayoutChange(current);
                }}
                onDrop={() => console.log('onDrop')}
                isDragable={true}
                isResizable={true}
                preventCollision={false}
                isDroppable={true}
                onDrag={() => console.log('onDragging')}
                onDragStop={onMoveCard}
                onResizeStop={onResizeCard}
                rowHe
            >
                {dashboard?.positions?.length &&
                    dashboard?.positions?.map((p, i) => {
                        return (
                            <div
                                key={i}
                                data-grid={{
                                    x: dashboard?.positions?.[i]?.x,
                                    y: dashboard?.positions?.[i]?.y,
                                    w: dashboard?.positions?.[i]?.w,
                                    h: dashboard?.positions?.[i]?.h,
                                }}
                            >
                                {createElem(dashboard?.items?.[i]?.type, {
                                    assetId: dashboard?.items?.[i]?.assetId,
                                    name: dashboard?.items?.[i]?.name,
                                    position: dashboard?.positions?.[i],
                                    height:
                                        (dashboard?.positions?.[i]?.h || 1) *
                                        ROW_HEIGHT,
                                    width:
                                        (dashboard?.positions?.[i]?.w || 1) *
                                        ROW_WIDTH,
                                    start_date: startDate,
                                    end_date: endDate,
                                    settings: dashboard?.items?.[i],
                                    onRemoveClick: () => {
                                        removeWidet(parseInt(i));
                                    },
                                    onSettingsClick: () => {
                                        showSettingsModal(i);
                                    },
                                    onRename: (name) => {
                                        onWidgetRename(i, name);
                                    },
                                })}
                            </div>
                        );
                        // ;
                    })}
            </ResponsiveReactGridLayout>
            <WidgetAddModal
                visible={modalVisible}
                open={toggle}
                toggle={hideModal}
                onRequestClose={hideModal}
                addWidget={addWidget}
            />
            <WidgetSettingsModal
                visible={settingsModalVisible}
                open={toggleSettings}
                toggle={hideSettingsModal}
                onRequestClose={hideSettingsModal}
                onSaveSettingsClick={onSaveSettingsClick}
                items={dashboard?.items || []}
                index={1}
            />
        </div>
    ) : (
        <div className="container w-100 h-100 d-flex flex-column justify-content-center align-items-center">
            <h3>Select App and Merchant</h3>
            <Link to="/apps">app selector</Link>
        </div>
    );
};

export default DashboardComponent;
