import Honkio from '../../Honkio';

const purchaseProduct = ({ product_id, ...rest }, errorHandler = {}) => {
  const params = {
    product_id,
    ...rest,
    version: 3,
    action: 'create',
  };
  console.log(JSON.stringify(params));
  return Honkio().mainShop.userFetch('productpurchase', params, errorHandler);
};

export default purchaseProduct;
