import Honkio from '../../Honkio';

const getRoles = async function (errorHandler) {
  return Honkio().mainShop.userFetch(
    'rolelist',
    {
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getRoles;
