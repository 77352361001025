const mapResponseToFormData = (data = {}) => {
  const {
    settings,
    email,
    user_group,
    merchants,
    app,
    apps,
    serial_number,
    public_settings,
    IBAN_Number,
    bank_name,
  } = data;
  const {
    str_firstname,
    str_lastname,
    str_country,
    str_timezone,
    str_address1,
    str_address2,
    str_city,
    str_telephone,
    str_language,
    str_zip,
    str_ssn,
    home_location,
    home_address,
  } = settings;
  return {
    user_group,
    email,
    merchants,
    app,
    str_address1,
    str_address2,
    str_city,
    str_country,
    str_firstname,
    str_lastname,
    str_timezone,
    str_telephone,
    str_language,
    str_zip,
    str_ssn,
    apps,
    serial_number,
    home_location,
    home_address,
    public_settings,
    IBAN_Number,
    bank_name,
  };
};

export default mapResponseToFormData;
