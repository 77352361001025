import Honkio from '../../Honkio';

const getAsset = (options = {}, errorHandler = {}) => {
  const { id, debug } = options;
  return Honkio().mainShop.userFetch(
    'assetget',
    {
      debug,
      id: id,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

const getAssets = (options = {}, errorHandler = {}) => {
  const {
    merchant,
    id,
    query_parent,
    query_skip,
    query_count,
    query_type,
    query_properties,
    query_visible,
    query_ids,
    query_owner,
    query_linked,
    debug,
    appid,
    search,
  } = options;
  return Honkio().mainShop.userFetch(
    'assetlist',
    {
      debug,
      query_application: appid,
      query_parent,
      query_merchant: merchant,
      query_search: search,
      query_skip,
      query_count,
      query_type,
      query_properties,
      query_visible,
      query_ids,
      query_owner,
      query_linked,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export { getAssets, getAsset };
