import { NotificationManager } from 'react-notifications';
import { useEffect, useMemo, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import ExtraArgInput from './ExtraArgInput';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

const SpecActionModal = ({
    specAction = null,
    scripts = [],
    triggers = [],
    specialActions = [],
    show,
    onHide = () => {},
    onSubmit = () => {},
}) => {
    const [action, setAction] = useState({
        action: '',
        triggers: [],
        extra: [],
    });

    const handleChangeAction = (val) => {
        setAction((prev) => ({
            ...prev,
            action: val,
        }));
    };

    const handleChangeTriggers = (val) => {
        setAction((prev) => ({
            ...prev,
            triggers: val?.map((itm) => itm?.value),
        }));
    };

    useEffect(() => {
        if (specAction) {
            const editAction = _.cloneDeep(specAction);
            if (Object.keys(specAction?.extra)?.length) {
                editAction.extra = Object.entries(specAction?.extra)?.map(
                    (item) => ({
                        name: item?.[0],
                        value: item?.[1],
                        id: uuidv4(),
                    })
                );
            } else {
                editAction.extra = [];
            }
            setAction(editAction);
        }
    }, [specAction]);

    const handleChangeExtraArg = (idx, value) => {
        setAction((prev) => {
            const updatedExtra = _.cloneDeep(prev?.extra);
            updatedExtra[idx] = value;

            return { ...prev, extra: updatedExtra };
        });
    };

    const handleRemoveExtraArg = (id) => {
        setAction((prev) => ({
            ...prev,
            extra: prev?.extra?.filter((item) => item?.id !== id),
        }));
    };

    const renderExtraArgs = () => {
        return action?.extra?.map((item, idx) => (
            <ExtraArgInput
                key={`${idx}-extra-arg-${item?.id}`}
                arg={item}
                index={idx}
                onChange={handleChangeExtraArg}
                onRemove={handleRemoveExtraArg}
            />
        ));
    };

    const handleAddExtraArg = () => {
        setAction((prev) => ({
            ...prev,
            extra: [
                ...prev?.extra,
                {
                    id: uuidv4(),
                    name: 'new_arg',
                    value: 'value',
                },
            ],
        }));
    };

    const handleSubmit = () => {
        let submitAction = _.cloneDeep(action);
        const extraObj = {};
        submitAction?.extra?.forEach(
            (item) => (extraObj[item?.name] = item?.value)
        );
        submitAction.extra = extraObj;
        setAction(null);
        onSubmit(submitAction);
    };

    const handleHide = () => {
        setAction(null);
        onHide();
    };

    return (
        <Modal show={show} onHide={handleHide}>
            <Modal.Header closeButton>
                <Modal.Title>Add special action</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="action">
                    <Form.Label>Action</Form.Label>
                    <Form.Control
                        as={'select'}
                        aria-label="Action"
                        name="action"
                        value={action?.action || ''}
                        onChange={(e) => handleChangeAction(e.target.value)}
                        className="custom-select"
                    >
                        <option value="">— Select action —</option>
                        {scripts.map((item, index) => (
                            <option
                                key={`${item?.name}-${item?.label}-${index}-action-option`}
                                value={item?.name}
                            >
                                {item?.label}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Text className="text-muted">
                        The script which will be triggered
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="triggers">
                    <Form.Label>Triggers</Form.Label>
                    <Select
                        options={triggers.map((item) => ({
                            value: item,
                            label: item,
                        }))}
                        isMulti={true}
                        onChange={handleChangeTriggers}
                        value={action?.triggers?.map((item) => ({
                            value: item,
                            label: item,
                        }))}
                    />
                    <Form.Text className="text-muted">
                        The trigger point of the script
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="triggers">
                    {action?.extra?.length ? (
                        <Form.Label>Extra arguments</Form.Label>
                    ) : (
                        <div>No extra args yet...</div>
                    )}
                    {renderExtraArgs()}
                    <Button
                        type="button"
                        variant="primary"
                        size="sm"
                        onClick={handleAddExtraArg}
                    >
                        Add
                    </Button>
                    <Form.Text className="text-muted">
                        Additional arguments passed to script
                    </Form.Text>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                    Confirm
                </Button>
                <Button variant="secondary" onClick={handleHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SpecActionModal;
