import { useEffect, useMemo, useState, useContext } from 'react';
import { getAssets, getAsset } from '../../api/utils/asset/getAssets';
import useSearchString from '../../utils/useSearchString';
import AnySelector from '../UI/AnySelector';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import InlineSearch from '../UI/InlineSearch';
import AssetList from './Assets/AssetList';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';

const colsToSearch = ['name'];
const typedCol = 'visible';

const AssetSelectList = ({ selectedAssetsList, setSelectedAssetsList }) => {
  const [assetList = [], setAssetList] = useState([]);
  //   const [selectedAssetsList, setSelectedAssetsList] = useState([]);
  const [selected, setSelected] = useState({});
  const [keyword, setKeyword] = useState('');
  const [type, setType] = useState('');
  const [ready, setReady] = useState(false);
  const userInfo = useContext(UserInfoContext);
  const {
    merchant = null,
    parent = 'None',
    merchant_name = '',
  } = useSearchString();

  const merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;
  const merchantName = merchant
    ? merchant_name
    : userInfo.merchant
    ? userInfo.merchant.name
    : '';

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setReady(false);
    fetchAssets();
  }, [merchantId, parent]);

  const fetchAssets = async () => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        if (isMounted) {
          setAssetList(data.assets);
          setReady(true);
        }
      } else {
        NotificationManager.error('Error');
        setAssetList([]);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setAssetList([]);
      setReady(true);
    };
    if (merchantId) {
      getAssets({ merchant: merchantId, query_parent: parent }, errorHandler)
        .then((data) => {
          return resultHandler(data);
        })
        .catch(console.error);
    } else {
      setReady(true);
      setAssetList([]);
    }
  };

  const displayedAssets = useMemo(() => {
    const typifiedMerchants = searchFilterIfConsist(
      assetList,
      colsToSearch,
      keyword
    );
    return typifyIfEquals(typifiedMerchants, typedCol, type);
  }, [assetList, keyword, type]);

  return (
    <div className="w-100 h-100 card p-3">
      <NotificationContainer />
      <div className="container-fluid">
        <h2>Select asset</h2>
        <div className="row">
          <div className="col">
            <InlineSearch setKeyword={setKeyword} />
          </div>
          <div>
            <AnySelector
              label="Visibility"
              items={[
                { name: 'Visible', value: 'trueBool' },
                { name: 'Not visible', value: 'falseBool' },
              ]}
              resetItem={{ name: 'All', value: '' }}
              nameSelector="name"
              valueSelector="value"
              setItem={setType}
              value={type}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <AssetList
              data={displayedAssets}
              ready={ready}
              //   selectItem={setSelected}
              id={selected.id}
              emptyText={
                merchantId
                  ? 'The asset list is empty'
                  : 'Please, select merchant first'
              }
              selectedAssetsList={selectedAssetsList}
              setSelectedAssetsList={setSelectedAssetsList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetSelectList;
