import { useCallback, useEffect, useMemo, useState } from 'react';
import getMerchantData from '../../api/utils/merchant/getMerchantData';
import useSearchString from '../../utils/useSearchString';
import languages from '../../__fixtures__/languages';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SmartForm from '../Form/SmartForm';
import SmartLanguageSelector from './SmartLanguageSelector';

const postfixes = [
  '',
  ...languages.map(({ value: language }) => `__${language}`),
];

const ProductTagEdit = () => {
  const [merchantInfo, setMerchantInfo] = useState();
  const [currentLanguage, setCurrentLanguage] = useState('');
  const { merchant } = useSearchString();

  useEffect(() => {
    getMerchantData({ id: merchant })
      .then(setMerchantInfo)
      .catch(console.error);
  }, [merchant]);

  const defaultValues = useMemo(
    () =>
      merchantInfo
        ? {
            merchantId: merchantInfo.id,
            merchantName: merchantInfo.merchant.settings.str_name,
          }
        : {},
    [merchantInfo]
  );
  const postfix = useMemo(
    () => (currentLanguage ? `__${currentLanguage}` : ''),
    [currentLanguage]
  );

  const onSubmit = useCallback((data) => {
    console.log(data);
  }, []);

  return (
    <SmartForm
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      className="p-3"
      header="Add product tag"
    >
      <div className="form-row align-items-end">
        <InputFieldWithValidation
          label="Merchant"
          name="merchantName"
          className="col p-2"
          disabled
        />
        <SmartLanguageSelector
          label="Language"
          name="currentLanguage"
          setItem={setCurrentLanguage}
          items={languages}
          valueSelector="value"
          nameSelector="name"
          resetItem={{ name: 'Default description', value: '' }}
          className="col-4 m-2 ml-3"
        />
      </div>
      {postfixes.map((currentPostfix, index) => (
        <div key={index} className="form-row row-cols-2">
          <InputFieldWithValidation
            label={`Name${currentPostfix}`}
            name={`tagName${currentPostfix}`}
            className="col p-2"
            hidden={postfix !== currentPostfix}
          />
          <InputFieldWithValidation
            label={`Description${currentPostfix}`}
            name={`tagDescription${currentPostfix}`}
            className="col p-2"
            hidden={postfix !== currentPostfix}
          />
        </div>
      ))}
      <button className="btn btn-primary btn-block my-3">Submit</button>
    </SmartForm>
  );
};

export default ProductTagEdit;
