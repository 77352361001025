import React, { useContext, useState, useEffect } from 'react';
import UserInfoContext from '../../../../contexts/UserInfoContext';
import { fileList } from '../../../../api/utils/files/minio/fileList';
import VideosList from './VideosList';
import { NotificationContainer } from 'react-notifications';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const VideoStreamingPage = () => {
    const {
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [filesList, setFilesList] = useState([]);

    const [sortField, setSortField] = useState('timestamp');
    const [sortDirection, setSortDirection] = useState(-1);

    const [search, setSearch] = useState('');

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState(0);

    const [file, setFile] = useState(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (merchantId) {
            fetchFilesList();
        } else {
            setFilesList([]);
            setTotalRows(0);
            setFile(null);
        }
    }, [merchantId, sortField, sortDirection]);

    const fetchFilesList = async (page = 1) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setFilesList(data.files);
                setTotalRows(data.total);
            }
        };

        const errorHandler = (error) => {
            console.log('Error fetching files list', error);
            setFilesList([]);
            setTotalRows(0);
        };

        try {
            const res = await fileList(
                {
                    query_merchant: merchantId,
                    query_category: 'video',
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                    query_sort_field: sortField,
                    query_sort_direction: sortDirection,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('Unexpected error: ', error);
        }
    };

    const handlePageChange = (page) => {
        fetchFilesList(page);
    };
    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    const handleSort = (column, sortDirection) => {
        setSortField(column?.init_name || 'creation_date');
        setSortDirection(sortDirection === 'asc' ? -1 : 1);
    };

    return (
        <div className="container-fluid">
            <h2>Videos list</h2>

            <NotificationContainer />

            <VideosList
                data={filesList}
                loading={loading}
                totalRows={totalRows}
                handlePageChange={handlePageChange}
                rowsCountPerPage={perPage}
                merchant={merchantId}
                emptyText={'The order list is empty'}
                handleRowsPerPage={handleRowsPerPage}
                perPageChoices={PER_PAGE_CHOICES}
                handleSort={handleSort}
            />
        </div>
    );
};

export default VideoStreamingPage;
