import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmModal = ({
    title = 'Modal title',
    text = 'Modal text',
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
    show = false,
    handleClose = () => {},
    confirmAction = () => {},
}) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{text}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={confirmAction}>
                    {confirmLabel}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    {cancelLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
