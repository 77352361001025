import {
    ButtonActionForm,
    CheckboxSwitch,
    InputFieldWithValidation,
    SelectFieldWithValidation,
    UploadFileWithPreview,
} from '../Form/elements/formElements';
import SmartForm from '../Form/SmartForm';
import prepareToEdit from './helpers/prepareToEdit';
import { useContext, useEffect, useState } from 'react';
import { Form, Button, Spinner, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NotificationManager } from 'react-notifications';
import Map from '../Map';
import AddressTableModal from './AddressTableModal';
import { GOOGLE_API_KEY } from './../../config';
import _ from 'lodash';
import getWarehousesList from '../../api/utils/warehouse/getWarehousesList';
import UserInfoContext from '../../contexts/UserInfoContext';
import Select from 'react-select';
import getAdminShop from '../../api/utils/shop/getAdminShop';
import cn from 'classnames';

const BASE_GEO_CODE_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}`;

const INITIAL_SHOP = {
    id: null,
    active: true,
    merchant: '',
    settings: {
        str_name: '',
        service_type: '',
        str_email: '',
        str_description: '',
        str_address: '',
        coordinates: [0.0, 0.0],
    },
    custom_fields: {},
    warehouses: [],
};

// TODO: Fix: Form values removed when there is no shopInfo (creation case)
const ShopInfoForm = ({
    id = null,
    onSubmit = () => {},
    submitLoading = false,
}) => {
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [shop, setShop] = useState(INITIAL_SHOP);
    const [loading, setLoading] = useState(false);

    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const [coordinatesInput, setCoordinatesInput] = useState(
        INITIAL_SHOP.settings.coordinates
    );
    const [coordUpdated, setCoordUpdated] = useState(false);

    const [addressInput, setAddressInput] = useState('');
    const [addressUpdated, setAddressUpdated] = useState(false);

    // Address fields
    const [showAddressListModal, setShowAddressListModal] = useState(false);
    const handleShowAddressListModal = () => setShowAddressListModal(true);
    const handleCloseAddressListModal = () => setShowAddressListModal(false);
    const [addressList, setAddressList] = useState();

    useEffect(() => {
        if (id) fetchShop(id);
    }, [id]);

    useEffect(() => {
        if (appId && merchantId) fetchWarehousesList();
    }, [appId, merchantId]);

    const handleGoogleRequest = async (adr, flag = 'address') => {
        const requestOptions = {
            method: 'POST',
        };
        const response = await fetch(adr, requestOptions);
        const result = await response.json();

        if (result.status === 'OK' && result.results.length > 0) {
            if (result.results.length > 1) {
                setAddressList(result.results);
                handleShowAddressListModal();
            } else {
                const newShop = _.cloneDeep(shop);

                const coordinates = [
                    result.results[0].geometry.location.lat,
                    result.results[0].geometry.location.lng,
                ];

                const address = result.results[0].formatted_address;

                newShop.settings.coordinates = coordinates;
                newShop.settings.str_address = address;

                setCoordinatesInput(coordinates);
                setAddressInput(address);

                setShop(newShop);
            }
        } else {
            const newShop = _.cloneDeep(shop);
            newShop.settings.str_address = '';
            newShop.settings.coordinates = INITIAL_SHOP.settings.coordinates;

            setShop(newShop);
            NotificationManager.warning(
                result.error_message || 'Geocode was not successful',
                'Geocode error',
                5000
            );
        }
    };

    const handleAddressChanged = async (address) => {
        setAddressUpdated(false);

        const newShop = _.cloneDeep(shop);
        newShop.settings.str_address = address;
        setShop(newShop);

        const adr = `${BASE_GEO_CODE_URL}&address="${address}"`;
        await handleGoogleRequest(adr, 'address');
    };

    const handleCoordinatesChanged = async (coordinates) => {
        setCoordUpdated(false);

        const adr = `${BASE_GEO_CODE_URL}&latlng=${coordinates[0]},${coordinates[1]}`;

        await handleGoogleRequest(adr, 'coordinates');
    };

    useEffect(() => {
        if (shop?.warehouses && warehouses.length) {
            setSelectedWarehouses(
                warehouses.filter((item) => shop.warehouses.includes(item._id))
            );
        }
    }, [warehouses, loading]);

    const handleChangeShop = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        let newShop = _.cloneDeep(shop);
        _.set(newShop, fieldName, value);
        setShop(newShop);
    };

    const fetchShop = async (id) => {
        const resultHandler = (data) => {
            if (data?.status === 'accept' && data?.shop) {
                setShop(data.shop);
                setCoordinatesInput(
                    data.shop?.settings?.coordinates ||
                        INITIAL_SHOP.settings.coordinates
                );
                setAddressInput(
                    data.shop.settings?.str_address ||
                        INITIAL_SHOP?.settings.str_address
                );
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
        };

        try {
            setLoading(true);
            const res = await getAdminShop({ id }, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.log('error fetching shop: ', error);
        }
    };

    const fetchWarehousesList = async (page = 1) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setWarehouses(data.warehouses);
            } else {
                setWarehouses([]);
            }
        };

        const errorHandler = (error) => {
            setWarehouses([]);
        };

        try {
            const result = await getWarehousesList(
                {
                    query_application: appId,
                    query_merchant: merchantId,
                    query_skip: 0,
                    query_count: 10000,
                },
                errorHandler
            );
            resultHandler(result);
        } catch (error) {
            errorHandler(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const submitData = _.cloneDeep(shop);
        submitData.warehouses = selectedWarehouses.map((item) => item._id);

        const updatedShop = await onSubmit(submitData);

        if (!_.isEmpty(updatedShop)) {
            setShop(updatedShop);

            setCoordinatesInput(
                updatedShop?.settings?.coordinates ||
                    INITIAL_SHOP.settings.coordinates
            );
            setAddressInput(
                updatedShop.settings?.str_address ||
                    INITIAL_SHOP?.settings.str_address
            );
        }
    };

    function onAddressSelected(address) {
        handleCloseAddressListModal();
        const coordinates = [
            address.geometry.location.lat,
            address.geometry.location.lng,
        ];
        const submitAddress = address.formatted_address;

        const submitData = _.cloneDeep(shop);
        submitData.settings.coordinates = coordinates;
        submitData.settings.str_address = submitAddress;

        setCoordinatesInput(coordinates);
        setAddressInput(submitAddress);

        setShop(submitData);
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="data.settings.str_name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Shop name"
                        name="settings.str_name"
                        value={shop?.settings?.str_name || ''}
                        onChange={handleChangeShop}
                    />
                    <Form.Text className="text-muted">
                        Name of the shop
                    </Form.Text>
                </Form.Group>

                <Form.Group
                    className="mb-3"
                    controlId="data.settings.service_type"
                >
                    <Form.Label>Service type</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Service type"
                        name="settings.service_type"
                        value={shop?.settings?.service_type || ''}
                        onChange={handleChangeShop}
                    />
                    <Form.Text className="text-muted">Service type</Form.Text>
                </Form.Group>

                <Form.Group
                    className="mb-3"
                    controlId="data.settings.str_email"
                >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Email"
                        name="settings.str_email"
                        value={shop?.settings?.str_email || ''}
                        onChange={handleChangeShop}
                    />
                    <Form.Text className="text-muted">Shop email</Form.Text>
                </Form.Group>

                <Form.Group
                    className="mb-3"
                    controlId="data.settings.str_description"
                >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Description"
                        name="settings.str_description"
                        value={shop?.settings?.str_description || ''}
                        onChange={handleChangeShop}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="data.warehouses">
                    <Form.Label>Warehouses</Form.Label>
                    <Select
                        id="warehouse-select"
                        value={selectedWarehouses}
                        name="warehouses"
                        onChange={setSelectedWarehouses}
                        isMulti
                        options={warehouses}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={'— Select action types —'}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 2 }),
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                    />
                    <Form.Text className="text-muted">Warehouses</Form.Text>
                </Form.Group>

                <InputGroup className="mb-3">
                    <InputGroup.Text>Search by address</InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="Address"
                        name="settings.str_address"
                        value={addressInput}
                        onChange={(e) => {
                            setAddressInput(e.target.value);
                            setAddressUpdated(true);
                        }}
                    />

                    <Button
                        variant={
                            addressUpdated ? 'danger' : 'outline-secondary'
                        }
                        id="find-coordinates"
                        type="button"
                        onClick={(e) =>
                            handleAddressChanged(
                                addressInput ||
                                    INITIAL_SHOP.settings.str_address
                            )
                        }
                    >
                        Find
                    </Button>
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Text>Search by coordinates</InputGroup.Text>
                    <Form.Control
                        aria-label="Lattitude"
                        type="text"
                        placeholder="60.xxxx"
                        name="coordinates.0"
                        value={coordinatesInput?.[0]}
                        onChange={(e) => {
                            setCoordinatesInput((prev) => [
                                e.target.value,
                                prev?.[1],
                            ]);
                            setCoordUpdated(true);
                        }}
                    />
                    <Form.Control
                        aria-label="Longitude"
                        type="text"
                        placeholder="24.xxxx"
                        name="coordinates.1"
                        value={coordinatesInput?.[1]}
                        onChange={(e) => {
                            setCoordinatesInput((prev) => [
                                prev?.[0],
                                e.target.value,
                            ]);
                            setCoordUpdated(true);
                        }}
                    />

                    <Button
                        variant={coordUpdated ? 'danger' : 'outline-secondary'}
                        id="find-coordinates"
                        type="button"
                        onClick={(e) =>
                            handleCoordinatesChanged(
                                coordinatesInput ||
                                    INITIAL_SHOP.settings.coordinates
                            )
                        }
                    >
                        Find
                    </Button>
                </InputGroup>

                <div>
                    <h4>Location</h4>
                    {shop.settings?.coordinates ?? null ? (
                        <Map
                            latitude={shop.settings.coordinates[0]}
                            longitude={shop.settings.coordinates[1]}
                        />
                    ) : (
                        <div className="text-muted">'no location provided'</div>
                    )}
                </div>

                <Button
                    variant="primary"
                    type="submit"
                    className={cn('mt-3')}
                    disabled={submitLoading}
                >
                    Submit
                </Button>
            </Form>

            <AddressTableModal
                addressList={addressList}
                show={showAddressListModal}
                onAddressSelected={onAddressSelected}
                onHide={handleCloseAddressListModal}
            ></AddressTableModal>
        </div>
    );
};

export default ShopInfoForm;
