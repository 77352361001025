import Icon from './UI/Icon';
import { GOOGLE_API_KEY } from './../config.prod';

const source = (latitude = '40.714728', longitude = '-73.998672') =>
  [
    'https://maps.googleapis.com/maps/api/staticmap?center=',
    `${latitude},${longitude}`,
    '&zoom=12&size=400x400',
    `&key=${GOOGLE_API_KEY}`,
  ].join('');

const Map = ({ latitude, longitude }) => {
  return (
    <div className="position-relative d-inline-block">
      <img src={source(latitude, longitude)} alt="Map" />
      <div
        className="position-absolute d-flex justify-content-center align-items-center"
        style={{ top: '45%', left: '45%' }}
      >
        <Icon iconName="bi-flag-fill" size="2" />
      </div>
    </div>
  );
};

export default Map;
