import Honkio from '../../Honkio';

const merchantSet = async function (options, errorHandler) {
  return Honkio().mainShop.userFetch(
    'adminmerchantset',
    {
      ...options,
      action: options?.action ? options.action : 'update',
      version: 3,
    },
    errorHandler
  );
};

const merchantCreate = (appid, merchant, errorHandler) => {
  return merchantSet(
    {
      ...merchant,
      application: appid,
      action: 'create',
    },
    errorHandler
  );
};

export { merchantCreate, merchantSet };
