import React, { useState, useEffect } from 'react';
import CardWrapperComponent from '../UI/CardWrapperComponent';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { Modal, Button } from 'react-bootstrap';
import ImageUploadComponent from './ImageUploadComponent';
import ImageBoxComponent from './ImageBoxComponent';
import { getFileList } from '../../api/utils/files/getFileList';
import { deleteFile } from '../../api/utils/files/setFile';
import useSearchString from '../../utils/useSearchString';
import useLoader from '../UI/helpers/useLoader';
import { getAsset } from '../../api/utils/asset/getAssets';
import LoadingBar from '../UI/LoadingBar';

const AssetImages = () => {
  const [appInfo, setAppInfo] = useState(null);
  const [consumerInfo, setConsumerInfo] = useState(null);
  const { id: assetId } = useSearchString();
  const [assetInfo, setAssetInfo] = useState({});
  const [fileList, setFileList] = useState({});
  const [progress, done] = useLoader([fileList]);
  const [showAddFileModal, setShowAddFileModal] = useState(false);
  const handleShowAddFileModal = () => setShowAddFileModal(true);
  const handleCloseAddFileModal = () => setShowAddFileModal(false);

  const [showConfirmRemoveDocument, setShowConfirmRemoveDocument] = useState({
    visible: false,
    document: undefined,
  });
  const handleShowConfirmRemoveDocument = (document) =>
    setShowConfirmRemoveDocument({ visible: true, document: document });
  const handleCloseConfirmRemoveDocument = () =>
    setShowConfirmRemoveDocument({ visible: false, document: undefined });

  let isMounted = true;

  const [forceRefresh, setForceRefresh] = useState(0);

  /*
    componentDidMount() {
        this.getInfo();
        this.handleShopName();
        window.addEventListener("merchant_name", this.handleShopName);
    }
    componentWillUnmount() {
        window.removeEventListener("merchant_name", this.handleShopName);
    }
    */

  useEffect(() => {
    const notParsedInfo = localStorage.getItem('app_merchant_info');
    const notParsedConsumer = localStorage.getItem('consumer_info');
    if (notParsedInfo) {
      setAppInfo(JSON.parse(notParsedInfo));
    }
    if (notParsedConsumer) {
      setConsumerInfo(JSON.parse(notParsedConsumer));
    }
  }, []);

  const handleForceRefresh = () => {
    if (forceRefresh <= 1000) {
      setForceRefresh((prev) => prev + 1);
    } else {
      setForceRefresh(0);
    }
  };

  useEffect(() => {
    window.addEventListener('merchant_name', handleForceRefresh);

    return () => {
      window.removeEventListener('merchant_name', handleForceRefresh);
    };
  }, []);

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    fetchAssetFiles();
  }, [assetId, appInfo, forceRefresh]);

  const fetchAsset = async () => {
    const resultHandler = (data) => {
      if (isMounted) {
        if (data && data.status === 'accept') {
          setAssetInfo(data.asset);
        } else {
          NotificationManager.error('Error');
        }
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setAssetInfo();
    };
    if (assetId) {
      getAsset({ id: assetId }, errorHandler)
        .then((data) => {
          return resultHandler(data);
        })
        .catch(console.error);
    } else {
      setAssetInfo();
    }
  };

  const fetchAssetFiles = async () => {
    const resultHandler = (data) => {
      if (isMounted) {
        if (data && data.status === 'accept') {
          setFileList(data.files);
        } else {
          NotificationManager.error('Error');
        }
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setFileList([]);
    };

    getFileList({ type: 'image', object: appInfo?.app?.id }, errorHandler)
      .then((data) => {
        return resultHandler(data);
      })
      .catch(console.error);
  };

  const deleteImage = () => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Video deleted');
        onFileRemoved();
      } else {
        NotificationManager.error('Error');
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    console.log('deleting file...');
    deleteFile(showConfirmRemoveDocument.document._id, errorHandler, true)
      .then((data) => {
        return resultHandler(data);
      })
      .catch(console.error);
  };

  const onFileRemoved = () => {
    fetchAssetFiles();
    handleCloseConfirmRemoveDocument();
  };

  const forceDownload = (blob, filename) => {
    let a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  const onLoadFile = (file) => {
    NotificationManager.success('Upload successfully');
    forceDownload(file.url, `${file.display_name}.${file.extension}`);
  };

  return (
    <div>
      <NotificationContainer />
      <br />
      <div>
        <CardWrapperComponent
          add_class="mb-5"
          header={true}
          card_title={
            done
              ? assetInfo
                ? `Images`
                : 'Images not found'
              : 'Loading images'
          }
        >
          {done ? (
            <div className="col-lg-12 justify-content-center align-items-center">
              <Button
                className="ml-3 mb-4"
                theme="info"
                onClick={handleShowAddFileModal}
              >
                + Upload image
              </Button>
              <ImageBoxComponent
                data={fileList}
                onDelete={(file) => handleShowConfirmRemoveDocument(file)}
                onDownload={(file) => onLoadFile(file)}
                onUploadClick={() => handleShowAddFileModal()}
              />
            </div>
          ) : (
            <LoadingBar progress={progress} />
          )}
        </CardWrapperComponent>
        <ImageUploadComponent
          visible={showAddFileModal}
          toggle={handleCloseAddFileModal}
          onRequestClose={handleCloseAddFileModal}
          objectId={assetId}
          objectType={'asset'}
          onMediaUploaded={() => {
            fetchAssetFiles();
            handleCloseAddFileModal();
          }}
        />
        {showConfirmRemoveDocument.document && (
          <Modal
            show={showConfirmRemoveDocument.visible}
            onHide={handleCloseConfirmRemoveDocument}
          >
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Do you want to remove document{' '}
                <b>{showConfirmRemoveDocument.document.display_name}</b>?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseConfirmRemoveDocument}
              >
                Close
              </Button>
              <Button variant="danger" onClick={() => deleteImage()}>
                Remove
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};
export default AssetImages;
