const init = (initialState) => {
  return initialState;
};

const reducer = (state, action) => {
  console.log('state == ', state);
  console.log('action == ', action);
  const { type, payload } = action;
  switch (type) {
    case 'characters':
      return { ...state, characters: { ...state.characters, ...payload } };
    case 'length':
      return { ...state, length: { ...state.length, ...payload } };
    case 'reset':
      return init(payload);
    default:
      return state;
  }
};

const initialState = {
  length: {
    min: 8,
    max: 16,
  },
  characters: {
    uppercase: 2,
    digits: 2,
    symbols: 2,
  },
};

export { initialState, init, reducer };
