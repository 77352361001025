import { useEffect, useState } from 'react';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import {
  ButtonActionForm,
  CheckboxSwitch,
  InputFieldWithValidation,
  SelectFieldWithValidation,
} from '../Form/elements/formElements';
import SmartForm from '../Form/SmartForm';
import UploadFileWithPreview from '../Form/elements/UploadFileWithPreview';
import getAdminShopList from '../../api/utils/shop/getAdminShopList';
import UploadImage from '../Form/elements/UploadImage';

const DEFAULT_POS_OPT = {
  _id: '-1',
  label: '— Select point of sales —',
  value: '',
};

const VoucherEditForm = ({ defaultValues, onSubmit, assetType }) => {
  const [assetTypePropertiesElements, setAssetTypePropertiesElements] =
    useState([]);

  const [idLists, setAssetIdLists] = useState([]);

  const [shopsList, setShopsList] = useState([]);

  const [pos, setPos] = useState('-1');

  useEffect(() => {
    if (defaultValues?.pos && shopsList?.length) {
      const currentPos = shopsList.find(
        (item) => item?._id === defaultValues?.pos
      );
      if (currentPos) setPos(currentPos._id);
    }
  }, [defaultValues, shopsList]);

  const renderPropertyName = (propName = '') => {
    try {
      let stringInput = String(propName);
      let wordsArray = stringInput.split('_');
      let result = '';
      if (wordsArray.length) {
        let firstWord = wordsArray.shift();
        firstWord = firstWord[0].toUpperCase() + firstWord.slice(1);
        wordsArray.unshift(firstWord);
        result = wordsArray.join(' ');
      }
      return result;
    } catch (error) {
      return propName;
    }
  };

  useEffect(() => {
    if (assetType) {
      if (assetType.properties && assetType.properties.length > 0) {
        const elements = assetType.properties.map((e, idx) => {
          {
            if (e.property_type === 'float') {
              return (
                <InputFieldWithValidation
                  key={`asset_prop_float_${idx}`}
                  className="mb-3"
                  name={`properties.${e.property_name}`}
                  required={e.property_required}
                  label={`${renderPropertyName(e.property_name)}`}
                  additional={`${e.property_description}(${e.property_type})`}
                  type="number"
                  step="0.1"
                />
              );
            }
            if (e.property_type === 'int') {
              return (
                <InputFieldWithValidation
                  key={`asset_prop_int_${idx}`}
                  className="mb-3"
                  name={`properties.${e.property_name}`}
                  required={e.property_required}
                  label={`${renderPropertyName(e.property_name)}`}
                  additional={`${e.property_description}(${e.property_type})`}
                  type="number"
                  step="1"
                />
              );
            }
            if (e.property_type === 'str') {
              return (
                <InputFieldWithValidation
                  key={`asset_prop_str_${idx}`}
                  className="mb-3"
                  name={`properties.${e.property_name}`}
                  required={e.property_required}
                  label={`${renderPropertyName(e.property_name)}`}
                  additional={`${e.property_description}(${e.property_type})`}
                  type="text"
                />
              );
            }
            if (e.property_type === 'bool') {
              return (
                <CheckboxSwitch
                  key={`asset_prop_bool_${idx}`}
                  name={`properties.${e.property_name}`}
                  label={renderPropertyName(
                    `${e.property_name}; ${e.property_description}`
                  )}
                />
              );
            }
            if (e.property_type === 'object_id') {
              return (
                <InputFieldWithValidation
                  key={`asset_prop_object_id_${idx}`}
                  className="mb-3"
                  name={`properties.${e.property_name}`}
                  required={e.property_required}
                  label={`${renderPropertyName(e.property_name)}`}
                  additional={`${e.property_description}(${e.property_type})`}
                  type="text"
                  step="1"
                />
              );
            }
            if (e.property_type === 'image') {
              return (
                <UploadImage
                  key={`asset_prop_image_${idx}`}
                  className="col-md-6 mb-3"
                  name={`properties.${e.property_name}`}
                />
              );
            }
          }
        });
        setAssetTypePropertiesElements(elements);
      }

      setAssetIdLists(assetType?.id_lists || []);
    }
  }, [assetType]);

  const fetchShops = async (app_id, merchant_id) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        let shopOpts = [];
        if (data?.shops?.length && Array.isArray(data.shops)) {
          shopOpts = data?.shops?.map((item) => ({
            _id: item?._id,
            label: item?.settings?.str_name,
            vlaue: item?._id,
          }));
        }
        shopOpts.push(DEFAULT_POS_OPT);

        setShopsList(shopOpts);
      } else {
        NotificationManager.error('Error');
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    getAdminShopList(
      {
        query_count: 1000,
        appid: app_id,
        merchant: merchant_id,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  useEffect(() => {
    if (defaultValues?.application && defaultValues?.merchant) {
      fetchShops(defaultValues.application, defaultValues.merchant);
    }
  }, [defaultValues]);

  const handleChangePos = (name, val) => {
    console.log('handleChangePos: val ==', val);
    setPos(val);
  };

  const handleSubmit = (data) => {
    data.pos = pos;
    data.properties.point_of_sales = pos;
    onSubmit(data);
  };

  return (
    <div>
      <NotificationContainer />
      <h4> Type: {assetType?.name}</h4>
      <hr />
      <SmartForm onSubmit={handleSubmit} defaultValues={defaultValues}>
        <InputFieldWithValidation
          className="mb-3"
          name="name"
          label="Name"
          type="text"
        />
        <CheckboxSwitch name="visible" label="Visible" />
        <InputFieldWithValidation
          className="mb-3"
          name="description"
          label="Description"
          type="text"
          required={true}
        />
        {assetTypePropertiesElements}
        <br />
        <ButtonActionForm
          label="Save changes"
          name="active"
          value={true}
          type="submit"
        />
      </SmartForm>
    </div>
  );
};

export default VoucherEditForm;
