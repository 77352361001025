
import {
  NotificationContainer,
} from 'react-notifications';


let ProductReport = () => {


  return (
    <div>
      <NotificationContainer />
      <h2>ProductReport</h2>
     
    </div>
  );
};

export default ProductReport;
