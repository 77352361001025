import Honkio from '../../Honkio';

/**
 *
 * @param {*} options
 * @param {*} errorHandler
 * @returns Returns a subset of orders with type 'REGISTRATION_ORDER_TYPE'
 */
const getRegistrationOrders = (options = {}, errorHandler = {}) => {
  const {
    page,
    perPage,
    keyword,
    merchantId,
    appid,
    query_count,
    query_skip,
    serial,
    query_start_date,
    query_end_date,
  } = options;
  return Honkio().mainShop.userFetch(
    'adminordertransactionsregistration',
    {
      query_skip: page ? (+page - 1) * perPage : query_skip ? query_skip : 0,
      query_count: perPage || query_count,
      keyword,
      query_merchant: merchantId,
      application: appid,
      query_serial: serial,
      query_start_date,
      query_end_date,
      action: 'read',
      version: 3,
    },
    errorHandler
  );
};

export default getRegistrationOrders;
