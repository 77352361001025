import { useEffect, useMemo, useState, useContext } from 'react';
import getConsumerTransactions from './../../api/utils/consumer/getConsumerTransactions';
import useSearchString from '../../utils/useSearchString';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import ConsumerTransactionsList from './ConsumerTransactionsList';
import ConsumerTransactionDetails from './ConsumerTransactionDetails';

const ConsumerTransactions = () => {
  const [transactionsList, setTransactionsList] = useState([]);
  const [browseView, setBrowseView] = useState(true);
  const [selectedTransacton, setSelectedTransaction] = useState();
  const [typeFilter, setTypeFilter] = useState('');
  const [shopFilter, setShopFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const { merchant } = useSearchString();
  const userInfo = useContext(UserInfoContext);
  const pageTitle = 'Transactions';
  const [loading, setLoading] = useState(false);

  let isMounted = true;
  useEffect(() => {
    setLoading(true);
    fetchTransactions();
    return () => {
      isMounted = false;
    };
  }, []);

  const fetchTransactions = async () => {
    const resultHandler = (data) => {
      if (isMounted) {
        if (data && data.status === 'accept') {
          setTransactionsList(data.transactions);
          setLoading(false);
        } else {
          setLoading(false);
          setTransactionsList([]);
        }
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setTransactionsList([]);
      setLoading(false);
    };

    getConsumerTransactions(
      {
        user_id: userInfo.id,
        query_skip: 0,
        query_count: 25,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  useEffect(() => {
    if (selectedTransacton === null) {
      setBrowseView(true);
    } else {
      setBrowseView(false);
    }
  }, [selectedTransacton]);

  useEffect(() => {
    console.log('userInfo ==', userInfo);
  }, [userInfo]);

  const transactionTypes = useMemo(
    () =>
      transactionsList
        .map(({ type }) => type)
        .reduce(
          (types, type) => (types.includes(type) ? types : [...types, type]),
          []
        ),
    [transactionsList]
  );
  const displayedTransactions = useMemo(
    () =>
      transactionsList
        .filter(({ type }) => !typeFilter || type === typeFilter)
        .filter(({ status }) => !statusFilter || status === statusFilter)
        .filter(({ shop }) => !shopFilter || shop.name === shopFilter),
    [typeFilter, statusFilter, shopFilter, transactionsList]
  );

  const transactionStatuses = useMemo(
    () =>
      transactionsList
        .map(({ status }) => status)
        .reduce(
          (statuses, status) =>
            statuses.includes(status) ? statuses : [...statuses, status],
          []
        ),
    [transactionsList]
  );
  const transactionShops = useMemo(
    () =>
      transactionsList
        .map(({ shop }) => shop.name)
        .reduce(
          (names, name) => (names.includes(name) ? names : [...names, name]),
          []
        ),
    [transactionsList]
  );

  return browseView ? (
    <div className="w-100 h-100">
      <NotificationContainer />
      <h2>{pageTitle}</h2>
      <div className="w-100">
        <ConsumerTransactionsList
          transactionsList={displayedTransactions}
          setSelectedTransaction={setSelectedTransaction}
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          optionsType={transactionTypes}
          shopFilter={shopFilter}
          setShopFilter={setShopFilter}
          optionsShop={transactionShops}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          optionsStatus={transactionStatuses}
          loading={loading}
          emptyText={'The list is empty'}
        />
      </div>
    </div>
  ) : (
    <ConsumerTransactionDetails
      transaction={selectedTransacton}
      close={() => setSelectedTransaction(null)}
      showPaymentInfo={selectedTransacton?.type === 'order'}
    />
  );
};

export default ConsumerTransactions;
