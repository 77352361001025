
import { NotificationManager } from "react-notifications";

let errorHandler = (error) => {
    return NotificationManager.error(error.description, "Error", 4000)
}

let resultHandler = (data, callback) => {
    if (data && data.status == 'accept') {
        callback(data)
    } else {
        NotificationManager.error("Error")
    }
}

export { errorHandler, resultHandler }