import InputField from './InputField';
import SelectField from './SelectField';

const CommonProperties = ({
  register,
  header,
  className,
  excludedFields = {},
  role
}) => {
  const { name, description, display_name, property } = excludedFields;
  return (
    <div className="container-fluid p-0">
      <h2>{header}</h2>
      <hr/>
      <div className="row">
        {!name && (
          <div className={className}>
            <InputField name="name" label="Name" register={register} />
          </div>
        )}
        {!display_name && (
          <div className={className}>
            <InputField
              name="display_name"
              label="Display name"
              register={register}
            />
          </div>
        )}
        {!description && (
          <div className={className}>
            <InputField
              name="description"
              label="Description"
              register={register}
            />
          </div>
        )}
        {!property && (
          <div className={className}>
            <SelectField
              name="property"
              register={register}
              label="Public/Private"
              defaultValue={role?.property}
              options={['public', 'private']}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonProperties;
