import { revokeToken } from './authUser';

const signOutAction = async () => {
    // Revoke token so noone could use it
    const res = await revokeToken();

    localStorage.clear();
};

const checkAuthState = () => Boolean(localStorage.getItem('is_authorized'));

export { checkAuthState, signOutAction };
