import Honkio from '../../../Honkio';

const getAssetType = (options = {}, errorHandler = {}) => {
  const { id, query_merchant, query_name, debug } = options;
  return Honkio().mainShop.userFetch(
    'adminassettypeget',
    {
      debug,
      id,
      query_merchant,
      query_name,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};
export default getAssetType;
