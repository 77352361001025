import Honkio from '../../Honkio';

export const getConsumerPreviewsList = (options = {}, errorHandler = {}) => {
  const {
    debug,
    query_skip,
    query_count,
    query_application,
    query_merchant,
    search,
    query_role,
    query_roles,
  } = options;
  return Honkio().mainShop.userFetch(
    'adminconsumerpreviews',
    {
      debug,
      query_application,
      query_merchant,
      query_count,
      query_skip,
      query_search: search,
      query_role,
      query_roles,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export const getConsumerList = (options = {}, errorHandler = {}) => {
  const { debug, query_skip, query_count, query_role, merchantId, search } =
    options;
  return Honkio().mainShop.userFetch(
    'adminconsumerlist',
    {
      debug,
      query_merchant: merchantId,
      query_role,
      query_count,
      query_skip,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};
