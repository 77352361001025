import { useFieldArray, useFormContext } from 'react-hook-form';
import ListOfIds from './ListOfIds';

const IdLists = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'id_lists',
  });
  return (
    <fieldset>
      <button
        type="button"
        className="btn btn-secondary btn-block my-3"
        onClick={() =>
          append({ name: '', type: 'list', description: '', required: 'yes' })
        }
      >
        Add lists of ids
      </button>
      {fields.map((field, index) => {
        return (
          <ListOfIds
            key={field.id}
            field={field}
            index={index}
            actions={{ remove }}
          />
        );
      })}
    </fieldset>
  );
};

export default IdLists;
