import HonkioAPI from '../../Honkio';

const uploadFile = async function (options, errorHandler) {
  return HonkioAPI().mainShop.userFetch(
    'userfileset',
    {
      ...options,
    },
    errorHandler
  );
};

const deleteFile = async function (id, errorHandler, undefOnly = false) {
  return HonkioAPI().mainShop.userFetch(
    'userfileset',
    {
      id: id,
      object: '',
      object_type: '',
      access: 'private',
      delete: true,
    },
    errorHandler,
    undefOnly
  );
};
export { uploadFile, deleteFile };
