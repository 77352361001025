import Honkio from '../../Honkio';

const setAdminOrderModel = async function (options, errorHandler) {
    if (options?.id || options?._id) {
        let old_models = await Honkio().mainShop.userFetch(
            'merchantorderprocesslist',
            { query_id: options.id },
            errorHandler
        );

        let res = await Honkio().mainShop.userFetch(
            'adminordermodelget',
            { id: options._id, version: 3, action: 'read' },
            errorHandler
        );
        const old_model = res.order_model;
        if (old_model && res.status === 'accept') {
            const old_model_statuses = old_model.statuses;

            const options_statuses = options.statuses;
            const new_statuses = Object.keys(options.statuses).map(function (
                status,
                i
            ) {
                return {
                    [status]: {
                        ...old_model_statuses[status],
                        ...options_statuses[status],
                    },
                };
            });
            const newStatusesObject = new_statuses.reduce(
                (obj, item) => ({
                    ...obj,
                    ...item,
                }),
                {}
            );
            const new_model = {
                order_type: '',
                ...options,
                statuses: newStatusesObject,
                version: 3,
                action: 'update',
            };
            return Honkio().mainShop.userFetch(
                'adminordermodelset',
                new_model,
                errorHandler
            );
        } else {
            //todo return error model not found
            return;
        }
    } else {
        return Honkio().mainShop.userFetch(
            'adminordermodelset',
            {
                ...options,
                version: 3,
                action: 'create',
            },
            errorHandler
        );
    }
};

const deleteAdminOrderModel = (id, errorHandler) => {
    return Honkio().mainShop.userFetch(
        'adminordermodelset',
        { id: id, version: 3, action: 'delete' },
        errorHandler
    );
};

export { setAdminOrderModel, deleteAdminOrderModel };
