import { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import useSearchString from '../../utils/useSearchString';
import Icon from '../UI/Icon';
import UserInfoContext from '../../contexts/UserInfoContext';
import { getNotifyMessageList } from '../../api/utils/merchant/notifymessage/notifyMessages';

const columns = () => [
  {
    name: '#',
    width: '40px',
    cell: (_, index) => index + 1,
  },
  {
    name: 'Name',
    cell: ({ name }) => name,
  },
  {
    name: 'Actions',
    cell: (row) => (
      <div>
        <Link to={`/message/edit?id=${row['_id']}`}>
          <Icon iconName="bi-pen" />
        </Link>
      </div>
    ),
  },
];

const AppMessagesList = () => {
  const [templatesList, setTemplatesList] = useState();
  const { appid, appname = null } = useSearchString();
  const userInfo = useContext(UserInfoContext);
  const pageTitle = appname
    ? `Messages (${appname})`
    : appid
    ? 'Messages'
    : userInfo.app?.name
    ? `Messages (${userInfo.app.name})`
    : 'Messages';

  useEffect(() => {
    getNotifyMessageList({ application: appid }).then(({ notify_messages }) => {
      setTemplatesList(notify_messages);
    });
  }, [appid]);

  return (
    <div>
      <h2>{pageTitle}</h2>
      <DataTable
        dense
        striped
        highlightOnHover
        data={templatesList}
        columns={columns()}
      />
    </div>
  );
};

export default AppMessagesList;
