import { useEffect, useState } from 'react';
import useSearchString from '../../utils/useSearchString';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';
import MessageEditorForm from './MessageEditorForm';
import {
  getNotifyMessage,
  updateNotifyMessage,
} from '../../api/utils/merchant/notifymessage/notifyMessages';

const MessageEditorById = () => {
  const [template, setTemnplate] = useState();
  const [editorData, setEditorData] = useState('');
  const { id } = useSearchString();

  const [editorDataLoaded, setEditorDataLoaded] = useState(false);

  const [progress, done] = useLoader([template, editorDataLoaded]);
  useEffect(() => {
    getNotifyMessage({ notifyMessageID: id }).then(({ notify_message }) => {
      setTemnplate(notify_message);
    });
  }, [id]);

  useEffect(() => {
    if (template) {
      setEditorData(template?.['message']);
      setEditorDataLoaded(true);
    } else {
      setEditorDataLoaded(false);
    }
  }, [template]);

  const onSubmit = (data) => {
    const request = { id, ...data, message: editorData };
    updateNotifyMessage(request).then(console.log);
  };

  return (
    <div>
      {done ? (
        <MessageEditorForm
          templateInfo={template}
          editorData={editorData}
          setEditorData={setEditorData}
          onSubmit={onSubmit}
        />
      ) : (
        <LoadingBar progress={progress} />
      )}
    </div>
  );
};

export default MessageEditorById;
