import { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Col, Image } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import useLoader from '../../../UI/helpers/useLoader';
import LoadingBar from '../../../UI/LoadingBar';
import LegalInfo from './LegalInfo';
import NaturalInfo from './NaturalInfo';
import RootInfo from './RootInfo';

const EntityModal = ({ data, show, onHide }) => {
  const [progress, done] = useLoader([data]);

  useEffect(() => {
    console.log('EntityModal; data ==', data);
  }, [data]);

  //   TODO: Check `entityType` field and render data accordingly
  //   entityTypes: 'root' | 'legal' | 'natural'

  const renderEntityInfo = (entityType) => {
    switch (entityType) {
      case 'legal':
        return <LegalInfo data={data} />;
      case 'natural':
        return <NaturalInfo data={data} />;
      case 'root':
        return <RootInfo data={data} />;
      default:
        return <div>Unknown entity type</div>;
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Entity info</Modal.Title>
      </Modal.Header>
      {done ? (
        <Modal.Body>{renderEntityInfo(data?.entityType)}</Modal.Body>
      ) : (
        <Modal.Body>
          <LoadingBar progress={progress} />
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EntityModal;
