import Honkio from '../../Honkio';

const createAccessRule = (options = {}, errorHandler = {}) => {
  const {
    name,
    description,
    model_name,
    conditions,
    actions,
    roles,
    is_global,
  } = options;
  return Honkio().mainShop.userFetch(
    'accessrulescreate',
    {
      name,
      description,
      model_name,
      conditions,
      actions,
      roles,
      is_global,
      action: 'create',
      version: 3,
    },
    errorHandler
  );
};
export default createAccessRule;
