import Honkio from '../../Honkio';

const getConsumer = (options = {}, errorHandler = {}) => {
  const { id, accesses = false } = options;
  return Honkio().mainShop.userFetch(
    'adminconsumerget',
    {
      id,
      accesses,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getConsumer;
