import { useCallback, useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import './App.css';
import AuthContext from './contexts/AuthContext';

import { checkAuthState, signOutAction } from './auth/authActions';
import { OAUTH_URL, TOKEN_EXPIRATION_CHECK_INTERVAL } from './config';
import AdminView from './components/AdminView/AdminView';
import AuthorizedContainer from './components/Auth/AuthorizedContainer';
import GuestContainer from './components/Auth/GuestContainer';
import LandingPage from './components/LandingPage';
import Viewport from './layouts/Viewport';
import UserInfoContext from './contexts/UserInfoContext';
import ConsumerInfoContext from './contexts/ConsumerInfoContext';
import {
    createStore,
    StateMachineProvider,
    useStateMachine,
} from 'little-state-machine';
import BellContext from './contexts/BellContext';
import RegistrationStatusContext from './contexts/RegistrationStatusContext';
import getBellNotifications from './api/utils/info/getBellNotifications';
import getMerchantRegStatus from './api/utils/merchant/registration/getMerchantRegStatus';
import moment from 'moment';
import { checkTokenExpired, refreshToken } from './auth/authUser';
import UserAppAndRole from './components/Auth/UserAppAndRole';

createStore(
    {
        app: {},
        merchant: {},
        language: 'en',
    },
    {
        name: 'app_merchant_info',
        middleWares: [console.log],
        storageType: localStorage,
    }
);

const update = (state, payload) => {
    return { ...state, ...payload };
};

const View = ({ data }) => {
    const [isRole, setIsRole] = useState(false);

    const { actions, state } = useStateMachine({ update });
    const [authContext, setAuthContext] = useState({
        isAuth: checkAuthState(),
        authURL: OAUTH_URL,
        signIn: () => {
            setAuthContext({ ...authContext, isAuth: true });
        },
        signOut: () => {
            signOutAction();
            setAuthContext({ ...authContext, isAuth: false });
        },
    });

    const [userInfoContext, setUserInfoContext] = useState({
        ...state,
        setInfo: (info) => {
            actions.update(info);
            setUserInfoContext((state) => ({ ...state, ...info }));
        },
    });

    const [consumerContext, setConsumerContext] = useState({
        id: '',
        firstname: '',
        lastname: '',
        email: '',
        role: '',
        is_superadmin: false,
        setConsumerInfo: (info) => {
            setConsumerContext((prev) => ({
                ...prev,
                ...info,
            }));

            localStorage.setItem(
                'consumer_info',
                JSON.stringify({
                    id: info.id,
                    firstname: info.firstname,
                    lastname: info.lastname,
                    email: info.email,
                })
            );
            window.dispatchEvent(new Event('consumer_info_set'));
        },
        setIsSuperadmin: (val = false) => {
            setConsumerContext((prev) => ({
                ...prev,
                is_superadmin: val,
            }));
            localStorage.setItem('is_superadmin', val);
        },
        setRole: (role) => {
            setConsumerContext((prev) => ({
                ...prev,
                role,
            }));
        },
    });

    const [bellInfoContext, setBellInfoContext] = useState(null);

    const [regStatusContext, setRegStatusContext] = useState(null);

    const handleCheckTokenAndRefresh = () => {
        const tokenExpired = checkTokenExpired();
        if (tokenExpired) {
            console.log('token is expired');
            refreshToken();
        }
    };

    useEffect(() => {
        let is_superadmin = localStorage.getItem('is_superadmin');
        try {
            if (is_superadmin) is_superadmin = JSON.parse(is_superadmin);
        } catch (error) {
            console.log('error parsing is_superadmin: ', error);
        }
        const role = localStorage.getItem('role');
        setConsumerContext((prev) => ({
            ...prev,
            is_superadmin,
            role,
        }));
    }, []);

    useEffect(() => {
        // Setup moment sandbox var
        window.moment = moment;

        window.addEventListener('role_is_set', () => {
            setIsRole(true);
            let is_superadmin = localStorage.getItem('is_superadmin');
            try {
                if (is_superadmin) is_superadmin = JSON.parse(is_superadmin);
            } catch (error) {
                console.log('error parsing is_superadmin: ', error);
            }
            const role = localStorage.getItem('role');
            setConsumerContext((prev) => ({
                ...prev,
                is_superadmin,
                role,
            }));
        });
        return () => {
            window.removeEventListener('role_is_set', () => {
                setIsRole(true);
                let is_superadmin = localStorage.getItem('is_superadmin');
                try {
                    if (is_superadmin)
                        is_superadmin = JSON.parse(is_superadmin);
                } catch (error) {
                    console.log('error parsing is_superadmin: ', error);
                }
                const role = localStorage.getItem('role');
                setConsumerContext((prev) => ({
                    ...prev,
                    is_superadmin,
                    role,
                }));
            });
        };
    }, []);

    useEffect(() => {
        const tokenInterval = setInterval(
            handleCheckTokenAndRefresh,
            TOKEN_EXPIRATION_CHECK_INTERVAL
        );
        return () => {
            clearInterval(tokenInterval);
        };
    }, []);

    useEffect(() => {
        if (authContext.isAuth) {
            fetchBellNotifications();
        }
    }, [userInfoContext, authContext, isRole]);

    const fetchBellNotifications = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setBellInfoContext({ data: data.data, errors: data.errors });
            }
        };

        const errorHandler = (error) => {
            console.error(error.description);
        };

        try {
            const res = await getBellNotifications(
                { query_application: userInfoContext?.app?.id },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('error == ', error);
        }
    };

    useEffect(() => {
        if (consumerContext?.email) fetchUserRegStatus(consumerContext.email);
    }, [consumerContext]);

    const fetchUserRegStatus = (email) => {
        const errorHandler = (error) => {
            console.error(error.description);
        };
        getMerchantRegStatus({ email }, errorHandler).then((res) => {
            if (res.status === 'accept') {
                setRegStatusContext(res);
            } else {
                // Error is returned for old Merchant accounts - ignore for now
                setRegStatusContext({
                    status: 'accept',
                    message: 'Skipped for old accounts',
                    result: true,
                    reg_status: 'documents_verified',
                    documents_verified: true,
                });
            }
        });
    };

    return (
        <Viewport>
            <Router>
                <AuthContext.Provider value={authContext}>
                    <GuestContainer>
                        <Switch>
                            <Route exact path="/" component={LandingPage} />
                            <Route
                                path="/authorized"
                                component={UserAppAndRole}
                            />
                            <Route path="/">
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </GuestContainer>
                    <AuthorizedContainer>
                        <UserInfoContext.Provider value={userInfoContext}>
                            <ConsumerInfoContext.Provider
                                value={consumerContext}
                            >
                                <BellContext.Provider value={bellInfoContext}>
                                    <RegistrationStatusContext.Provider
                                        value={regStatusContext}
                                    >
                                        <AdminView data="data" />
                                    </RegistrationStatusContext.Provider>
                                </BellContext.Provider>
                            </ConsumerInfoContext.Provider>
                        </UserInfoContext.Provider>
                    </AuthorizedContainer>
                </AuthContext.Provider>
            </Router>
        </Viewport>
    );
};

const App = () => (
    <StateMachineProvider>
        <View />
    </StateMachineProvider>
);

export default App;
