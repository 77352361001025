import Honkio from '../../../Honkio';

const getAssetTypeScheme = (
  application,
  merchant,
  options = {},
  errorHandler
) => {
  return Honkio().mainShop.userFetch(
    'adminassettypehierarchyget',
    {
      application,
      merchant,
      ...options,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

const setAssetTypeScheme = (application, merchant, options, errorHandler) => {
  return Honkio().mainShop.userFetch(
    'adminassettypehierarchyset',
    {
      application: application,
      merchant: merchant,
      ...options,
      version: 3,
      action: 'update',
    },
    errorHandler
  );
};

export { getAssetTypeScheme, setAssetTypeScheme };
