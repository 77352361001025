import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import DataTable from 'react-data-table-component';
import { useLayoutEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Icon from '../../UI/Icon';
import Wait from '../../UI/Wait';

const JobsTable = ({
    data,
    loading,
    onRowClicked,
    totalRows,
    rowsCountPerPage,
    handleRowsPerPage = () => {},
    onDeleteClick = () => {},
    perPageChoices = [25],
    handlePageChange = () => {},
}) => {
    const columns = useMemo(
        () => [
            {
                name: 'ID',
                grow: 1,
                cell: ({ _id }) => _id,
            },
            {
                name: 'Name',
                grow: 1,
                cell: ({ name }) => name,
            },
            {
                name: 'Active',
                grow: 0,
                cell: ({ active }) => (active ? 'yes' : 'no'),
            },
            {
                name: 'Actions',
                grow: 1,
                right: true,
                cell: ({ _id }) => (
                    <div>
                        <Link
                            to={`/scheduler/job/edit?job_id=${_id}`}
                            data-tip
                            data-for="editTip"
                        >
                            <Icon iconName="bi-pencil" />
                            <ReactTooltip
                                id="editTip"
                                place="top"
                                effect="solid"
                            >
                                Edit
                            </ReactTooltip>
                        </Link>
                        <span
                            data-tip
                            data-for="deleteTip"
                            onClick={() => {
                                onDeleteClick(_id);
                            }}
                        >
                            <Icon iconName="bi bi-trash" />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </span>
                    </div>
                ),
            },
        ],
        []
    );
    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            onChangeRowsPerPage={handleRowsPerPage}
            paginationServer
            progressPending={loading}
            onRowClicked={onRowClicked}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default JobsTable;
