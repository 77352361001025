import QueryString from 'qs';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Icon from '../../UI/Icon';
import Wait from '../../UI/Wait';
import ReactTooltip from 'react-tooltip';

const RoomsList = ({ data, ready, emptyText, onRowClicked }) => {
  const columns = useMemo(
    () => [
      { name: '#', grow: 0, cell: (_, index) => index + 1 },
      { name: 'Name', grow: 2, cell: ({ name }) => name },
      {
        name: 'Type',
        sortable: true,
        grow: 1,
        cell: ({ object_type }) => object_type,
      },
      {
        name: 'Private',
        sortable: true,
        grow: 1,
        cell: ({ is_private }) => (is_private ? 'Yes' : 'No'),
      },
      {
        name: 'Visible',
        grow: 0,
        cell: ({ visible }) => (visible ? 'Yes' : 'No'),
      },
    ],
    [data]
  );

  return (
    <div className="card p-3 mt-3">
      <DataTable
        dense
        striped
        highlightOnHover
        data={data}
        columns={columns}
        pagination={true}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 50, 100]}
        progressPending={!ready}
        noDataComponent={emptyText}
        progressComponent={
          <div className="p-5">
            <Wait />
          </div>
        }
        onRowClicked={onRowClicked}
      />
    </div>
  );
};

export default RoomsList;
