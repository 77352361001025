import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import { useHistory } from 'react-router-dom';
import { createApp } from '../../api/utils/app/setAppInfo';
import BackButton from '../UI/BackButton';
import LoadingBar from '../UI/LoadingBar';
import useLoader from '../UI/helpers/useLoader';
import AppCreateForm from './components/AppCreateForm';
import { defaultAppInfoRegistrationRequirements } from './helpers/fillAppInfoRegistrationRequirements';

const ApplicationCreate = () => {
    const [appInfo, setAppInfo] = useState({
        settings: {
            merchant_registration_requirements:
                defaultAppInfoRegistrationRequirements,
        },
    });
    const history = useHistory();
    const [language, setLanguage] = useState('en');
    const [editorData, setEditorData] = useState('');
    const [multiLanguageEditorData, setMultiLanguageEditorData] = useState({});
    const [progress, done] = useLoader([appInfo]);

    useEffect(() => {
        if (appInfo && language) {
            setEditorData(
                multiLanguageEditorData[language] ??
                    appInfo?.settings?.dict_tou_templates?.[
                        'str_tou_template__' + language
                    ] ??
                    ''
            );
        }
    }, [appInfo, language]);

    useEffect(() => {
        if (language && editorData) {
            setMultiLanguageEditorData((data) => ({
                ...data,
                [language]: editorData,
            }));
        }
    }, [editorData]);

    const onSubmit = async (data) => {
        const tou_arr = Object.entries(multiLanguageEditorData).map(
            ([key, value]) => ({
                key: 'str_tou_template__' + key,
                value: value,
            })
        );
        const tou = tou_arr.reduce(
            (obj, item) => ({
                ...obj,
                [item.key]: item.value,
            }),
            {}
        );

        const appSubmitData = _.cloneDeep(data);

        appSubmitData.settings.dict_tou_templates = tou;

        console.log('appSubmitData == ', appSubmitData);

        const resultHandler = (response) => {
            if (response && response.status === 'accept') {
                history.push(`/app/edit?appid=${response.application._id}`);
                NotificationManager.success(
                    'Application saved',
                    'Success',
                    4000
                );
            } else {
                NotificationManager.error('Unknown error', 'Error', 4000);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            const res = await createApp(appSubmitData, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton to="/apps" text="Back to Applications list" />
                Create Application
            </h2>
            {done ? (
                <AppCreateForm
                    appInfo={appInfo}
                    language={language}
                    setLanguage={setLanguage}
                    onSubmit={onSubmit}
                    editorData={editorData}
                    setEditorData={setEditorData}
                />
            ) : (
                <LoadingBar progress={progress} />
            )}
        </div>
    );
};

export default ApplicationCreate;
