import { useContext, useEffect, useState } from 'react';
import RoleForm from './RoleForm';
import useSearchString from '../../utils/useSearchString';
import getAdminRole from '../../api/utils/role/getAdminRole';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';
import UserInfoContext from '../../contexts/UserInfoContext';

const RoleEditor = () => {
    const { language, setInfo } = useContext(UserInfoContext);
    const { appid, role_id } = useSearchString();
    const [role, setRole] = useState();
    const [progress, done] = useLoader([role]);

    useEffect(() => {
        getAdminRole({ id: role_id }).then((answer) => {
            setRole(answer.role);
        });
    }, [role_id]);

    return done ? (
        <RoleForm role={role} appid={appid} language={language} />
    ) : (
        <LoadingBar progress={progress} />
    );
};

export default RoleEditor;
