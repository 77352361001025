import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const DateFields = ({ job, handleChangeJob = () => {} }) => {
    return (
        <>
            <Form.Group className="mb-3" controlId="data.run_date">
                <Form.Label>Run date</Form.Label>
                <Form.Control
                    as={DatePicker}
                    aria-label="Run date"
                    name="run_date"
                    selected={job?.run_date}
                    onChange={(date) =>
                        handleChangeJob({
                            target: { value: date, name: 'run_date' },
                        })
                    }
                    required
                    showTimeSelect
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    placeholderText="Select run date"
                ></Form.Control>
                <Form.Text className="text-muted">
                    Job will be executed once at the specified time
                </Form.Text>
            </Form.Group>
        </>
    );
};

export default DateFields;
