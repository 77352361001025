import React, { useState, useEffect, useContext } from 'react';
import * as xlsx from 'xlsx';
import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
import { Button, Row, Col, Form, Container, Spinner } from 'react-bootstrap';
import cn from 'classnames';
import Icon from '../../UI/Icon';
import ReactTooltip from 'react-tooltip';
import importColdCalls from '../../../api/utils/dataImport/importColdCalls';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import UserInfoContext from '../../../contexts/UserInfoContext';

const Importer = () => {
    const [headers, setHeaders] = useState(null); // data column names

    const [listName, setListName] = useState('');

    const [file, setFile] = useState(null); // sheet parsed into array of objects

    const [loading, setLoading] = useState(false);

    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    useEffect(() => {
        console.log('file == ', file);
    }, [file]);

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                setFile(json);

                const workbookHeaders = xlsx.read(data, {
                    sheetRows: 1,
                });
                const columnsArray = xlsx.utils.sheet_to_json(
                    workbookHeaders.Sheets[sheetName],
                    { header: 1 }
                )[0];
                setHeaders(columnsArray);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    };

    const handleSubmit = async () => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                NotificationManager.success('Records created', 'Success');
                setListName('');
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            console.log('error', error);
            NotificationManager.error('Error loading records', 'Error');
        };

        try {
            setLoading(true);
            const res = await importColdCalls(
                { data: file, merchant: merchantId, list_name: listName },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('unexpected error ', error);
            setLoading(false);
        }
    };

    return (
        <Container>
            <NotificationContainer />
            <Row className={cn('mb-3', 'gap-3')}>
                <Col sm={12} md={6}>
                    <Form.Control
                        className={cn('w-100', 'h-100', 'mb-0')}
                        type="text"
                        placeholder="My list of calls"
                        name="name"
                        value={listName}
                        onChange={(e) => setListName(e.target.value)}
                    />
                </Col>

                <Col sm={12} md={6}>
                    <Form.Control
                        name=""
                        type="file"
                        id="fileInputId"
                        hidden
                        onChange={readUploadFile}
                        accept=".xls,.xlsx,.xlsb,.xlsm,.ods,.csv,.dbf,.sylk"
                    />
                    <label
                        className={cn(
                            'btn btn-outline-success',
                            'w-100',
                            'mb-0'
                        )}
                        type="button"
                        variant="success"
                        htmlFor="fileInputId"
                        data-tip
                        data-for={`importFileTip`}
                    >
                        <Icon
                            iconName="bi-upload"
                            color="inherit"
                            colorOnHover="inherit"
                        />
                        <ReactTooltip
                            id={`importFileTip`}
                            place="top"
                            effect="solid"
                        >
                            Sheet must have column names and contain all the
                            rquired fields for model
                        </ReactTooltip>
                        Select file to extract data
                    </label>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6}></Col>
                <Col sm={12} md={6}>
                    <Button
                        type="button"
                        variant="primary"
                        className={cn('w-100', 'h-100', 'mb-0')}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : null}
                        Submit
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Importer;
