import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Form, Button, Table } from 'react-bootstrap';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import UserInfoContext from '../../contexts/UserInfoContext';
import { getMerchantOrderProcessList } from '../../api/utils/orderModel/getMerchantOrderProcessList';
import getAdminRolesList from '../../api/utils/role/getAdminRolesList';
import DataTable from 'react-data-table-component';
import Icon from '../UI/Icon';
import fillUserRegistrationRequirements from '../Application/helpers/fillUserRegistrationRequirements';
import defaultUserRegistrationRequirements from './defaultUserRegReq';
import CustomRegReqFields from './CustomRegReqFields';
import { v4 as uuid4 } from 'uuid';
import _ from 'lodash';
import {
    createUserRegSettings,
    updateUserRegSettings,
} from '../../api/utils/merchant/user/registration/setUserRegSettings';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSearchString from '../../utils/useSearchString';
import getUserRegSettings from '../../api/utils/merchant/user/registration/getUserRegSettings';
import BackButton from '../UI/BackButton';

const DEFAULT_REG_SETTINGS = {
    name: '',
    merchant: '',
    application: '',
    userStatusGroups: {},
    userRegistrationRequirements: defaultUserRegistrationRequirements,
    userRegistrationModel: '',
    defaultUserGroup: '',
    destinationUserGroup: '',
};

const UserRegSettingsForm = ({
    id = null,
    onSubmit = () => {},
    submitLoading = false,
}) => {
    const {
        app: { id: appId, name: appName },
        merchant: { id: merchantId, name: merchantName },
    } = useContext(UserInfoContext);
    const history = useHistory();

    const [modelsList, setModelsList] = useState([]);
    const [roles, setRoles] = useState([]);

    const [regSettings, setRegSettings] = useState(DEFAULT_REG_SETTINGS);

    const [customUserRegReq, setCustomUserRegReq] = useState([]);
    const [statusGroups, setStatusGroups] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingModels, setLoadingModels] = useState(false);
    const [loadingRoles, setLoadingRoles] = useState(false);

    useEffect(() => {
        if (id) fetchCurrentSettings(id);
    }, [id]);

    useEffect(() => {
        fetchOrderModelList();
    }, []);

    useEffect(() => {
        if (merchantId && appId) {
            setRegSettings((prev) => ({
                ...prev,
                merchant: merchantId,
                application: appId,
            }));
            fetchRoles(appId, merchantId);
        }
    }, [merchantId, appId]);

    const fetchCurrentSettings = async () => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data?.status === 'accept') {
                const loadedSettings = data.user_reg_settings;
                const newRegSettings = {
                    name: loadedSettings.name,
                    merchant: loadedSettings.merchant,
                    application: loadedSettings.application,
                    userStatusGroups: loadedSettings.user_status_groups,
                    userRegistrationRequirements:
                        loadedSettings.user_registration_requirements,
                    userRegistrationModel:
                        loadedSettings.user_registration_model,
                    defaultUserGroup: loadedSettings.default_user_group,
                    destinationUserGroup: loadedSettings.destination_user_group,
                };
                const { fields_user, user_registration_requirements } =
                    fillUserRegistrationRequirements(
                        newRegSettings.userRegistrationRequirements
                    );
                newRegSettings.userRegistrationRequirements =
                    user_registration_requirements;

                setRegSettings(newRegSettings);
                setStatusGroups(newRegSettings.userStatusGroups);
                setCustomUserRegReq(fields_user);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setRegSettings(DEFAULT_REG_SETTINGS);
        };

        try {
            setLoading(true);
            const res = await getUserRegSettings({ id }, errorHandler);
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    const fetchOrderModelList = async () => {
        const resultHandler = (data) => {
            setLoadingModels(false);
            if (data && data?.status === 'accept') {
                setModelsList(data?.orderprocesses);
            }
        };

        const errorHandler = (error) => {
            setLoadingModels(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setModelsList([]);
        };

        try {
            setLoadingModels(true);

            const res = await getMerchantOrderProcessList(
                {
                    merchantId: null,
                    appid: appId,
                    order_type: 'user_registration',
                },
                errorHandler
            );

            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    const fetchRoles = async (applicationId, merchantId) => {
        const resultHandler = (data) => {
            setLoadingRoles(false);
            if (data && data.status === 'accept' && data.roles) {
                setRoles(data.roles);
            }
        };

        const errorHandler = (error) => {
            setLoadingRoles(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setRoles([]);
        };

        try {
            setLoadingRoles(true);
            const result = await getAdminRolesList(
                { appid: applicationId, merchant: merchantId },
                errorHandler
            );
            resultHandler(result);
        } catch (error) {
            errorHandler(error);
        }
    };

    const onChangeRegistrationRequirements = (data) => {
        const newInfo = regSettings.userRegistrationRequirements.map((e) => {
            if (e.id === data.id) {
                return {
                    id: data.id,
                    required: data.required,
                    name: data.name,
                    mandatory: data.mandatory,
                    type: data.type,
                };
            } else {
                return e;
            }
        });

        setRegSettings((prev) => ({
            ...prev,
            userRegistrationRequirements: newInfo,
        }));
    };

    const columnsUser = useMemo(() => [
        {
            name: 'Is used',
            grow: 1,
            cell: (row) => {
                const iconName = row.required
                    ? 'bi-check2-square'
                    : 'bi-square';
                return (
                    <div
                        onClick={() => {
                            const new_row = Object.assign({}, row, {
                                required: !row.required,
                                mandatory: false,
                            });
                            return onChangeRegistrationRequirements(new_row);
                        }}
                        data-tip
                        data-for="selectTip"
                    >
                        <Icon iconName={`btn ${iconName}`} />
                    </div>
                );
            },
        },
        {
            name: 'Information',
            grow: 3,
            cell: (e) => {
                return e.name;
            },
        },
        {
            name: 'Is mandatory',
            grow: 1,
            cell: (row) => {
                const iconName = row.mandatory
                    ? 'bi-check2-square'
                    : 'bi-square';
                return (
                    <div
                        onClick={() => {
                            const new_row = Object.assign({}, row, {
                                required: true,
                                mandatory: !row.mandatory,
                            });
                            return onChangeRegistrationRequirements(new_row);
                        }}
                        data-tip
                        data-for="selectTip"
                    >
                        <Icon iconName={`btn ${iconName}`} />
                    </div>
                );
            },
        },
    ]);

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        const submitSettings = _.cloneDeep(regSettings);

        submitSettings.id = id;

        submitSettings.userRegistrationRequirements = [
            ...submitSettings.userRegistrationRequirements,
            ...customUserRegReq.map((item) => ({
                ...item,
                mandatory: item.mandatory === 'yes' ? true : false,
            })),
        ];

        submitSettings.userStatusGroups = statusGroups;

        await onSubmit(submitSettings);
    };

    const handleChangeRegSettings = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        setRegSettings((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    const handleChangeCustomRegReq = (e, idx) => {
        const rowIndex = idx;
        const value = e.target.value;
        const fieldName = e.target.name;

        setCustomUserRegReq((prev) => {
            const newCustomRegReq = _.cloneDeep(prev);
            if (newCustomRegReq && newCustomRegReq?.[rowIndex]) {
                newCustomRegReq[rowIndex][fieldName] = value;
                return newCustomRegReq;
            }
            return prev;
        });
    };

    const handleAddCustomRegReq = () => {
        setCustomUserRegReq((prev) => [
            ...prev,
            {
                id: uuid4().split('-')[0],
                name: 'some field',
                mandatory: false,
                type: 'str',
                required: true,
            },
        ]);
    };

    const handleDeleteCustomRegReq = (idx) => {
        setCustomUserRegReq((prev) => prev.filter((item, i) => i !== idx));
    };

    const getStatuses = () => {
        if (regSettings?.userRegistrationModel && modelsList?.length) {
            const currentModel = modelsList.find(
                (item) => item._id === regSettings.userRegistrationModel
            );
            if (currentModel) return currentModel?.statuses;
        }
        return {};
    };

    useEffect(() => {
        const currentStatuses = getStatuses();
        const newStatuses = {};

        Object.keys(currentStatuses).forEach(
            (item) => (newStatuses[item] = '')
        );
        if (!_.isEmpty(newStatuses)) setStatusGroups(newStatuses);
    }, [regSettings.userRegistrationModel]);

    const handleChangeStatusGroup = (statusName, e) => {
        const newStatusGroups = _.cloneDeep(statusGroups);
        newStatusGroups[statusName] = e.target.value;
        setStatusGroups(newStatusGroups);
    };

    const renderStatuses = () => {
        return Object.keys(statusGroups).map((item) => (
            <tr key={item}>
                <td>{item}</td>
                <td>
                    <Form.Control
                        as={'select'}
                        aria-label="Default user role"
                        name="defaultUserGroup"
                        value={statusGroups[item] || ''}
                        onChange={(e) => handleChangeStatusGroup(item, e)}
                        className="custom-select"
                    >
                        <option value="">
                            &mdash;{' '}
                            {loadingRoles ? 'Loading...' : 'Select role'}{' '}
                            &mdash;
                        </option>
                        {roles.map((item, index) => (
                            <option
                                key={`${index}-${item?._id}-status-group-option`}
                                value={item?._id}
                            >
                                {item?.name}
                            </option>
                        ))}
                    </Form.Control>
                </td>
            </tr>
        ));
    };

    return (
        <Form onSubmit={handleSubmitForm}>
            <Form.Group className="mb-3" controlId="data.name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Registration profile 1"
                    name="name"
                    value={regSettings?.name || ''}
                    onChange={handleChangeRegSettings}
                />
                <Form.Text className="text-muted">
                    User registration settings profile name
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.description">
                <Form.Label>Registration model</Form.Label>
                <Form.Control
                    as={'select'}
                    aria-label="Model name"
                    name="userRegistrationModel"
                    value={regSettings.userRegistrationModel || ''}
                    onChange={handleChangeRegSettings}
                    className="custom-select"
                    required
                >
                    <option value="">
                        &mdash; {loadingModels ? 'Loading...' : 'Select model'}{' '}
                        &mdash;
                    </option>
                    {modelsList.map((item, index) => (
                        <option
                            key={`${index}-${item?._id}-reg-model-option`}
                            value={item?._id}
                        >
                            {item?.name}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text className="text-muted">
                    Select user registration model
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.description">
                <Form.Label>Default user role</Form.Label>
                <Form.Control
                    as={'select'}
                    aria-label="Default user role"
                    name="defaultUserGroup"
                    value={regSettings.defaultUserGroup || ''}
                    onChange={handleChangeRegSettings}
                    className="custom-select"
                    required
                >
                    <option value="">
                        &mdash; {loadingRoles ? 'Loading...' : 'Select role'}{' '}
                        &mdash;
                    </option>
                    {roles.map((item, index) => (
                        <option
                            key={`${index}-${item?._id}-default-role-option`}
                            value={item?._id}
                        >
                            {item?.name}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text className="text-muted">
                    Select user role used by default
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.description">
                <Form.Label>Destination user role</Form.Label>
                <Form.Control
                    as={'select'}
                    aria-label="Destination user role"
                    name="destinationUserGroup"
                    value={regSettings.destinationUserGroup || ''}
                    onChange={handleChangeRegSettings}
                    className="custom-select"
                    required
                >
                    <option value="">
                        &mdash; {loadingRoles ? 'Loading...' : 'Select role'}{' '}
                        &mdash;
                    </option>
                    {roles.map((item, index) => (
                        <option
                            key={`${index}-${item?._id}-dest-role-option`}
                            value={item?._id}
                        >
                            {item?.name}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text className="text-muted">
                    Select user role after registration
                </Form.Text>
            </Form.Group>

            <hr />

            <DataTable
                dense
                striped
                title="User registration requirements"
                noDataComponent="No user registration requirements yet..."
                highlightOnHover
                data={regSettings.userRegistrationRequirements || []}
                columns={columnsUser}
            />

            <CustomRegReqFields
                fields={customUserRegReq}
                onFieldChange={handleChangeCustomRegReq}
                onFieldAdd={handleAddCustomRegReq}
                onFieldDelete={handleDeleteCustomRegReq}
            />

            <hr />

            <Table striped borderless hover size="sm">
                <thead>
                    <tr>
                        <th>Order model status</th>
                        <th>User role</th>
                    </tr>
                </thead>
                <tbody>{renderStatuses()}</tbody>
            </Table>

            <Button type="submit" variant="primary" disabled={submitLoading}>
                Submit
            </Button>
        </Form>
    );
};

export default UserRegSettingsForm;
