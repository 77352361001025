const getBonusAmount = (amount, { bonusValue = 0, bonusType = '' }) => {
  switch (bonusType) {
    case 'fixed':
      return bonusValue;
    case 'percentage':
      return amount * (bonusValue / 100);
    default:
      return 0;
  }
};

export default getBonusAmount;
