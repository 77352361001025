import { useEffect, useState, useContext } from 'react';
import UserInfoContext from '../../../../contexts/UserInfoContext';
import InlineSearch from '../../../UI/InlineSearch';
import getMerchantPayments from '../../../../api/utils/payments/getMerchantPayments';
import { errorHandler, resultHandler } from '../../../../utils/reqHandlers';
import useSearchString from '../../../../utils/useSearchString';
import fixEmail from '../../../../utils/fixEmail';
import PaymentList from './PaymentList';
import RefundModal from '../components/RefundModal';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import refundPayment from '../../../../api/utils/payments/refundPayment';
import IssueModal from '../components/IssueModal';
import issuePayment from '../../../../api/utils/payments/issuePayment';
import { Col, Row, Container } from 'react-bootstrap';

const Payment = () => {
    const [keyword, setKeyword] = useState('');
    const [transactionsList, setTransactionsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState();
    const [perPage, setPerPage] = useState(25);
    const [selected, setSelected] = useState({});

    const [refundPaymentOrder, setRefundPaymentOrder] = useState(null);
    const [showRefundModal, setShowRefundModal] = useState(false);

    const [issuePaymentOrder, setIssuePaymentOrder] = useState(null);
    const [showIssueModal, setShowIssueModal] = useState(false);

    const { merchant } = useSearchString();
    const userInfo = useContext(UserInfoContext);
    const { email } = useSearchString(fixEmail);
    let merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;

    useEffect(() => {
        fetchTransactions(1);
    }, [email, keyword, merchantId]);

    let fetchTransactions = async (page = 1) => {
        setLoading(true);
        getMerchantPayments(
            {
                page,
                query_count: perPage,
                query_keyword: keyword,
                query_merchant: merchantId,
            },
            (error) => {
                errorHandler(error);
                setLoading(false);
            }
        ).then((data) => {
            return resultHandler(data, (data) => {
                setTransactionsList(data.orders);
                setTotalRows(data.total);
                setLoading(false);
            });
        });
    };

    const handlePageChange = (page) => {
        fetchTransactions(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    useEffect(() => {
        fetchTransactions();
    }, [perPage]);

    const handleShowRefundModal = () => {
        setShowRefundModal(true);
    };
    const handleHideRefundModal = () => {
        setShowRefundModal(false);
    };

    const handleStartRefund = (order) => {
        setRefundPaymentOrder(order);
        handleShowRefundModal();
    };

    const handleRefund = (data) => {
        const resultHandler = (data) => {
            if (data.status === 'accept') {
                NotificationManager.success(
                    'Payment refunded',
                    'Success',
                    4000
                );
                fetchTransactions(1);
            } else {
                if (data.message)
                    NotificationManager.error(data.message, 'Error', 4000);
            }
            return;
        };
        const errorHandler = (error) => {
            NotificationManager.error(
                error.description || 'Refund failed',
                'Error',
                4000
            );
            return;
        };

        refundPayment(data, errorHandler).then((res) => resultHandler(res));
    };

    const handleShowIssueModal = () => {
        setShowIssueModal(true);
    };

    const handleHideIssueModal = () => {
        setShowIssueModal(false);
    };

    const handleIssueStart = (order) => {
        setIssuePaymentOrder(order);
        handleShowIssueModal();
    };

    const handleIssue = (data) => {
        const resultHandler = (data) => {
            if (data.status === 'accept') {
                NotificationManager.success('Issue created', 'Success', 4000);
                fetchTransactions(1);
            } else {
                if (data.message)
                    NotificationManager.error(data.message, 'Error', 4000);
            }
            return;
        };
        const errorHandler = (error) => {
            NotificationManager.error(
                error.description || 'Issuing failed',
                'Error',
                4000
            );
            return;
        };

        issuePayment(data, errorHandler).then((res) => resultHandler(res));
    };

    // Allow refund and issue only Payment orders and not Withdraw or Refund

    return (
        <>
            <NotificationContainer />
            <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <InlineSearch
                            keyword={keyword}
                            setKeyword={setKeyword}
                        />
                    </Col>
                </Row>
            </Container>

            {merchantId ? (
                <Row>
                    <Col sm={12}>
                        <PaymentList
                            data={transactionsList}
                            selectItem={setSelected}
                            id={selected.id}
                            loading={loading}
                            totalRows={totalRows}
                            handlePageChange={handlePageChange}
                            rowsCountPerPage={perPage}
                            handleRowsPerPage={handleRowsPerPage}
                            merchant={merchantId}
                            emptyText={'The order list is empty'}
                            handleStartRefund={handleStartRefund}
                            handleIssue={handleIssueStart}
                        />
                    </Col>
                </Row>
            ) : (
                'Please, select merchant first '
            )}

            <RefundModal
                order={refundPaymentOrder}
                show={showRefundModal}
                onHide={handleHideRefundModal}
                onSubmit={handleRefund}
            />

            <IssueModal
                order={issuePaymentOrder}
                show={showIssueModal}
                onHide={handleHideIssueModal}
                onSubmit={handleIssue}
            />
        </>
    );
};

export default Payment;
