const prepareInfo = (id, formData) => {
  const {
    name,
    name__da,
    name__fi,
    name__en,
    name__no,
    name__sv,
    description,
    description__da,
    description__en,
    description__fi,
    description__no,
    description__sv,
    fields,
  } = formData;
  if (id) {
    const productType = {
      id,
      name,
      name__da,
      name__fi,
      name__en,
      name__no,
      name__sv,
      description,
      description__da,
      description__en,
      description__fi,
      description__no,
      description__sv,
      fields: {
        ...fields,
      },
    };
    return { ...productType };
  } else {
    const productType = {
      name,
      name__da,
      name__fi,
      name__en,
      name__no,
      name__sv,
      description,
      description__da,
      description__en,
      description__fi,
      description__no,
      description__sv,
      fields: {
        ...fields,
      },
    };
    return { ...productType };
  }
};

export default prepareInfo;
