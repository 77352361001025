import Honkio from '../../Honkio';

// TODO: This should be replaced with another endpoint
// v3 `consumerget` is created for backwards compatibility only
const getConsumerInfo = (options = {}, errorHandler = {}) => {
  return Honkio().mainShop.userFetch(
    'consumerget',
    {
      ...options,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getConsumerInfo;
