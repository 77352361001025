import { errorHandler, resultHandler } from '../../../utils/reqHandlers';
import UserInfoContext from '../../../contexts/UserInfoContext';
import { Modal, Button, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import setMerchantTransactionStatus from '../../../api/utils/transactions/merchant/setMerchantTransactionStatus';
import { NotificationManager } from 'react-notifications';
import getMerchantTransaction from '../../../api/utils/transactions/merchant/getMerchantTransaction';

let PENDING = -100;
let REJECT = 501;
let ACCEPT = 502;
let DISPUTE = 503;
let CHARGEBACK = 1000;
let CAPTURE = 1001;

let getStatus = (transaction_status) => {
  if (transaction_status == PENDING) {
    return 'Pending';
  } else if (transaction_status == REJECT) {
    return 'Reject';
  } else if (transaction_status == ACCEPT) {
    return 'Accept';
  } else if (transaction_status == DISPUTE) {
    return 'Dispute';
  } else if (transaction_status == CHARGEBACK) {
    return 'Chargeback';
  } else if (transaction_status == CAPTURE) {
    return 'Capture';
  }
};

const TransactionRegisterMerchantDetails = () => {
  let { transactionID } = useParams();
  const [transaction, setTransaction] = useState({});
  const [showPictureModal, setShowPictureModal] = useState(false);
  const handleShowPictureModal = () => setShowPictureModal(true);
  const handleClosePictureModal = () => setShowPictureModal(false);
  const [pictureToShow, setPictureToShow] = useState('');
  const userInfo = useContext(UserInfoContext);
  let merchantId = userInfo.merchant ? userInfo.merchant.id : null;

  const [showConfirmAcceptTransaction, setShowConfirmAcceptTransaction] =
    useState(false);
  const handleShowConfirmAcceptTransaction = () =>
    setShowConfirmAcceptTransaction(true);
  const handleCloseConfirmAcceptTransaction = () =>
    setShowConfirmAcceptTransaction(false);

  const [showConfirmRejectTransaction, setShowConfirmRejectTransaction] =
    useState(false);
  const handleShowConfirmRejectTransaction = () =>
    setShowConfirmRejectTransaction(true);
  const handleCloseConfirmRejectTransaction = () =>
    setShowConfirmRejectTransaction(false);

  useEffect(() => {
    fetchTransactions();
  }, [merchantId, transactionID]);

  let fetchTransactions = async () => {
    getMerchantTransaction(
      {
        id: transactionID,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data, (data) => {
        setTransaction(data.transaction);
      });
    });
  };
  let onAccept = () => {
    handleShowConfirmAcceptTransaction();
  };
  let acceptTransaction = () => {
    handleCloseConfirmAcceptTransaction();
    const resultHandler = (response) => {
      if (response.status === 'accept') {
        if (response) {
          setTransaction(response.transaction);
        } else {
          NotificationManager.warning('Error');
        }
      } else {
        NotificationManager.error('Error', 4000);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    setMerchantTransactionStatus(
      {
        id: transactionID,
        status: true,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  let onReject = () => {
    handleShowConfirmRejectTransaction();
  };

  let rejectTransaction = () => {
    handleCloseConfirmRejectTransaction();
    const resultHandler = (response) => {
      if (response.status === 'accept') {
        if (response) {
          setTransaction(response.transaction);
        } else {
          NotificationManager.warning('Error');
        }
      } else {
        NotificationManager.error('Error', 4000);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    setMerchantTransactionStatus(
      {
        id: transactionID,
        status: false,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data, (data) => {
        return resultHandler(data);
      });
    });
  };

  let onPictureClick = (url) => {
    setPictureToShow(url);
    handleShowPictureModal();
  };

  let makeField = (item) => {
    if (item.id.startsWith('custom_picture')) {
      return (
        <tr>
          <th>{item.id}</th>
          <td>
            <Button onClick={() => onPictureClick(item.value)}>
              Show picture
            </Button>
          </td>
        </tr>
      );
    }
    return (
      <tr>
        <th>{item.id}</th>
        <td>{item.value}</td>
      </tr>
    );
  };

  if (!transaction._id) {
    return <div></div>;
  }
  let additional_data_section = [];
  if (transaction.additional_data) {
    for (const item in transaction.additional_data) {
      additional_data_section.push({
        id: item,
        value: transaction.additional_data[item],
      });
    }
  }
  return (
    <div>
      <Table>
        <tbody>
          <tr>
            <th>Time</th>
            <td>{transaction.timestamp}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{transaction.consumer.email}</td>
          </tr>
          <tr>
            <th>First name</th>
            <td>{transaction.consumer.firstname}</td>
          </tr>
          <tr>
            <th>Last name</th>
            <td>{transaction.consumer.lastname}</td>
          </tr>
          <tr>
            <th>Merchant Name</th>
            <td>{transaction.merchant.name}</td>
          </tr>
          <tr>
            <th>Business ID</th>
            <td>{transaction.merchant.businessid}</td>
          </tr>
          <tr>
            <th>Telephone</th>
            <td>{transaction.merchant.telephone}</td>
          </tr>
          {additional_data_section.map((item) => makeField(item))}
          <tr>
            <th>Status</th>
            <td>{getStatus(transaction.status)}</td>
          </tr>
          <tr>
            <th>IP</th>
            <td>{transaction.ip}</td>
          </tr>
          <tr>
            <th>User-Agent</th>
            <td>{transaction.user_agent}</td>
          </tr>
          {transaction.status === PENDING &&
            !transaction.plugins.merchant_manual_validation && (
              <tr>
                <th></th>
                <td>
                  <Button variant="success" onClick={onAccept}>
                    Accept
                  </Button>
                  <Button variant="danger" onClick={onReject}>
                    Reject
                  </Button>
                </td>
              </tr>
            )}
        </tbody>
      </Table>
      <Modal show={showPictureModal} onHide={handleClosePictureModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={pictureToShow} alt="Picture" className="img-fluid" />
        </Modal.Body>
        {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClosePictureModal}>Close</Button>
            </Modal.Footer> */}
      </Modal>
      <Modal
        show={showConfirmAcceptTransaction}
        onHide={handleCloseConfirmAcceptTransaction}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to accept this transaction?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseConfirmAcceptTransaction}
          >
            Close
          </Button>
          <Button variant="danger" onClick={() => acceptTransaction()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmRejectTransaction}
        onHide={handleCloseConfirmRejectTransaction}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to reject this transaction?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseConfirmRejectTransaction}
          >
            Close
          </Button>
          <Button variant="danger" onClick={() => rejectTransaction()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransactionRegisterMerchantDetails;
