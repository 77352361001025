import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useFieldArray, useFormContext, useController } from 'react-hook-form';
import Icon from '../../../UI/Icon';
import { Button } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

const PrivacyPolicy = () => {
  const { control } = useFormContext();
  const { field } = useController({
    name: 'privacy_policy',
    control,
  });

  return (
    <>
      <div className="row my-3">
        <div className="col d-flex align-items-center">
          <h4>Privacy policy</h4>
        </div>
      </div>
      <div className="form-group">
        <TextareaAutosize
          style={{ whiteSpace: 'pre-line' }}
          key={field.id}
          id="privacy_policy"
          name="privacy_policy"
          className="form-control"
          defaultValue={field.value || ''}
          onChange={field.onChange}
          onBlur={field.onBlur}
        ></TextareaAutosize>
      </div>
    </>
  );
};

export default PrivacyPolicy;
