import { useState, useEffect, useContext } from 'react';
import DropdownButton from '../DropdownButton';
import Honkio from '../../api/Honkio';
import AuthContext from '../../contexts/AuthContext';
import getConsumerInfo from '../../api/utils/consumer/getConsumerInfo';
import { refreshToken } from '../../auth/authUser';
import ConsumerInfoContext from '../../contexts/ConsumerInfoContext';

const UserWidget = () => {
    const [info, setInfo] = useState('');
    const [isOk, setOk] = useState(true);
    const { signOut } = useContext(AuthContext);
    const { firstname, lastname, setConsumerInfo } =
        useContext(ConsumerInfoContext);
    const handleAuthError = async ({ status }) => {
        setOk(false);
    };
    useEffect(() => {
        const options = {};
        getConsumerInfo(options, handleAuthError).then((res) => {
            const firstname = res?.consumer?.consumer_str_firstname
                ? res.consumer.consumer_str_firstname
                : 'Unknown';
            const lastname = res?.consumer?.consumer_str_lastname
                ? res?.consumer?.consumer_str_lastname
                : 'User';

            const email = res?.consumer?.login_identity
                ? res.consumer.login_identity
                : '';

            setConsumerInfo({
                id: res.consumer._id,
                firstname,
                lastname,
                email,
            });

            setInfo(`${firstname} ${lastname}`);
        });
    }, []);
    useEffect(() => {
        !isOk && signOut();
    });
    return (
        <DropdownButton label={info ? info : 'Loading...'}>
            <button className="btn btn-danger" onClick={signOut}>
                Sign out
            </button>
        </DropdownButton>
    );
};

export default UserWidget;
