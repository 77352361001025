import { useContext } from 'react';
import useSearchString from '../../utils/useSearchString';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import UserInfoContext from '../../contexts/UserInfoContext';
import EventEditForm from './EventEditForm';
import { createEvent } from '../../api/utils/event/setEvent';
import { useHistory } from 'react-router-dom';
import BackButton from '../UI/BackButton';

const EventCreate = () => {
    const history = useHistory();

    const { merchant } = useSearchString();

    const userInfo = useContext(UserInfoContext);
    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;

    const onSave = async (data) => {
        const resultHandler = (data) => {
            NotificationManager.success('Created successfully!');
            history.push('/events');
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };
        const res = await createEvent(data, errorHandler);
        if (res && res.status === 'accept') resultHandler(res);
    };

    return (
        <div>
            <BackButton to="/events" text="Back to events list" />

            <NotificationContainer />

            <EventEditForm onSubmit={onSave} />
        </div>
    );
};

export default EventCreate;
