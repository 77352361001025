import { useEffect, useState, useContext } from 'react';
import { getConsumerPreviewsList } from '../../api/utils/consumer/getConsumerList';
import UsersTable from './UsersTable';
import InlineSearch from '../UI/InlineSearch';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import useSearchString from '../../utils/useSearchString';
import UserInfoModal from './UserInfoModal';
import DeleteConfirmModel from '../Model/DeleteConfirmModel';
import { Link } from 'react-router-dom';
import { deleteConsumer } from '../../api/utils/consumer/setAdminConsumer';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const Users = () => {
  const [consumerList, setConsumerList] = useState();
  const [keyword, setKeyword] = useState('');
  const [type, setType] = useState('');
  const {
    app: { id: appid, name: appName },
  } = useContext(UserInfoContext);
  const userInfo = useContext(UserInfoContext);
  const { merchant = null } = useSearchString();
  const merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;
  // TODO: if merchant we have to ger merchant name someware to put it in title
  const pageTitle = merchant
    ? 'Users'
    : userInfo.merchant?.name
    ? `Users (${userInfo.merchant.name})`
    : 'Users';

  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const handleShowConfirmModal = () => setShowConfirmModal(true);
  const [showUserPreview, setShowUserPreview] = useState(false);
  const handleShowUserPreview = () => setShowUserPreview(true);
  const handleCloseUserPreview = () => setShowUserPreview(false);
  const [currentUser, setCurrentUser] = useState();

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  let onRowClick = (row) => {
    setCurrentUser(row);
    handleShowUserPreview();
  };

  useEffect(() => {
    console.log('appName ==', appName);
  }, [appName]);

  const fetchUsers = async (page = 1) => {
    setLoading(true);
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        setConsumerList(
          data.consumers.map((consumer) => ({
            ...consumer,
            active: consumer.active.toString(),
          }))
        );
        setTotalRows(data?.total);
        setLoading(false);
      } else {
        setConsumerList([]);
        setTotalRows(0);
        setLoading(false);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setConsumerList([]);
      setLoading(false);
    };

    getConsumerPreviewsList(
      {
        query_skip: (+page - 1) * perPage,
        query_count: perPage,
        search: keyword,
        query_merchant: appName && appName === 'server' ? null : merchantId,
        query_application: appName && appName === 'server' ? null : appid,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  useEffect(() => {
    fetchUsers(1);
  }, [userInfo, perPage, keyword]);

  const onDeleteClick = (id) => {
    handleShowConfirmModal();
    setDeleteId(id);
  };

  const handleCloseConfirmDelete = async () => {
    const resultHandler = (data) => {
      if (data && data?.status === 'accept') {
        setShowConfirmModal(false);
        NotificationManager.success(data?.message);
        fetchUsers();
      } else {
        NotificationManager.error(data?.message);
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    deleteConsumer({ id: deleteId }, errorHandler).then((data) => {
      return resultHandler(data);
    });
  };

  return (
    <div className="container-fluid">
      <NotificationContainer />
      <h2>{pageTitle}</h2>
      <Link to={`/user/create`} className="btn btn-outline-primary mt-1 mb-3">
        <i className="bi-plus-square ml-1 mr-2 " /> Create User
      </Link>
      <div className="row">
        <div className="col">
          <InlineSearch setKeyword={setKeyword} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <UsersTable
            data={consumerList}
            keyword={keyword}
            type={type}
            loading={loading}
            filterSearch={searchFilterIfConsist}
            filterType={typifyIfEquals}
            onRowClicked={onRowClick}
            onDeleteClick={onDeleteClick}
            rowsCountPerPage={perPage}
            handleRowsPerPage={handleRowsPerPage}
            perPageChoices={PER_PAGE_CHOICES}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
      {currentUser && (
        <UserInfoModal
          consumerId={currentUser.id}
          show={showUserPreview}
          onHide={handleCloseUserPreview}
        ></UserInfoModal>
      )}
      <DeleteConfirmModel
        modal={showConfirmModal}
        onModalClose={() => {
          setShowConfirmModal(false);
        }}
        handleCloseConfirmDelete={handleCloseConfirmDelete}
      />
    </div>
  );
};

export default Users;
