const prepareToPublish = (customerInfo, formData) => {
    const {
        latitude,
        longitude,
        name,
        description,
        type,
        visible,
        user_id,
        pos,
        external_reference,
        properties,
        merchant,
        application,
    } = formData;
    const customer = {
        id: customerInfo._id,
        type: customerInfo.type,
        coordinates: customerInfo.coordinates,
        latitude,
        longitude,
        name,
        description,
        visible,
        user_id,
        pos,
        external_reference,
        properties,
        merchant,
        application,
    };
    return customer;
};

export default prepareToPublish;
