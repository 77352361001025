import Honkio from '../../Honkio';

const getAccessRulesList = (options = {}, errorHandler = {}) => {
  const { query_count = 25, query_skip = 0 } = options;
  return Honkio().mainShop.userFetch(
    'accessruleslist',
    {
      query_count,
      query_skip,
      action: 'read',
      version: 3,
    },
    errorHandler
  );
};
export default getAccessRulesList;
