import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ReactTooltip from 'react-tooltip';
import Icon from '../../UI/Icon';
import Wait from '../../UI/Wait';
import OrderModal from './OrderModal';

const RegOrderApprovalList = ({
  data,
  loading,
  emptyText,
  rowsCountPerPage,
  totalRows,
  handlePageChange,
  merchant,
  onApprove,
  onReject,
}) => {
  const history = useHistory();
  const [showOrderPreview, setShowOrderPreview] = useState(false);
  const handleShowOrderPreview = () => setShowOrderPreview(true);
  const handleCloseOrderPreview = () => setShowOrderPreview(false);
  const [currenOrder, setCurrentOrder] = useState();

  const onInfoClick = (row) => {
    setCurrentOrder(row);
    handleShowOrderPreview();
  };

  const conditionalRowStyles = [
    {
      when: (row) => row?.status === 'final_merchant_created',
      style: {
        color: 'var(--gray)',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  const columns = useMemo(
    () => [
      { name: 'Created', grow: 2, cell: ({ creation_date }) => creation_date },
      { name: 'Title', grow: 2, cell: ({ title }) => title },
      { name: 'Owner', grow: 2, cell: ({ user_owner }) => user_owner || '' },
      { name: 'Status', grow: 1, cell: ({ status }) => status },
      { name: 'Model', grow: 1, cell: ({ model }) => model },
      {
        name: 'Action',
        grow: 1,
        cell: (row) => (
          <div>
            <span
              data-tip
              data-for="infoTipApprove"
              onClick={() => {
                onApprove(row);
              }}
            >
              <Icon iconName="bi-check-circle" />
              <ReactTooltip id="infoTipApprove" place="top" effect="solid">
                Approve this merchant
              </ReactTooltip>
            </span>
            <span
              data-tip
              data-for="infoTipReject"
              onClick={() => {
                onReject(row);
              }}
            >
              <Icon iconName="bi-x-circle" />
              <ReactTooltip id="infoTipReject" place="top" effect="solid">
                Reject this merchant
              </ReactTooltip>
            </span>

            <span
              data-tip
              data-for="infoTip"
              onClick={() => {
                onInfoClick(row);
              }}
            >
              <Icon iconName="bi-info-circle" />
              <ReactTooltip id="infoTip" place="top" effect="solid">
                Info
              </ReactTooltip>
            </span>
          </div>
        ),
      },
    ],
    [loading]
  );

  const onRowClick = (row) => {
    handleReviewDocuments(row);
  };

  const handleReviewDocuments = (row) => {
    history.push(`/registrations/merchant/document_review?id=${row._id}`);
  };

  return (
    <div>
      <DataTable
        dense
        striped
        highlightOnHover
        data={data}
        columns={columns}
        pagination={true}
        paginationPerPage={rowsCountPerPage}
        paginationRowsPerPageOptions={[rowsCountPerPage, 50, 100, 500]}
        paginationServer
        onChangePage={handlePageChange}
        paginationTotalRows={totalRows}
        progressPending={loading}
        noDataComponent={emptyText}
        progressComponent={
          <div className="p-5">
            <Wait />
          </div>
        }
        onRowClicked={onRowClick}
        conditionalRowStyles={conditionalRowStyles}
        pointerOnHover={true}
      />
      {currenOrder && (
        <OrderModal
          orderId={currenOrder._id}
          show={showOrderPreview}
          onHide={handleCloseOrderPreview}
        ></OrderModal>
      )}
    </div>
  );
};

export default RegOrderApprovalList;
