import Honkio from '../../Honkio';

const getAmlOrderInfo = (options, errorHandler = {}) => {
  const { order_id } = options;
  return Honkio().mainShop.userFetch(
    'adminpaymentsgetamlinfo',
    {
      order_id,
      action: 'read',
      version: 3,
    },
    errorHandler
  );
};

export default getAmlOrderInfo;
