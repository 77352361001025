import { useEffect, useRef } from 'react';
import cn from 'classnames';

const InlineSearch = ({
    className,
    keyword,
    setKeyword = () => {},
    ph = 'search for...',
}) => {
    const searchInput = useRef();
    useEffect(() => {
        if (keyword) searchInput.current.value = keyword;
    }, [keyword]);
    const onSubmit = (e) => {
        e.preventDefault();
        const {
            current: { value },
        } = searchInput;
        setKeyword(value);
    };

    const onReset = (e) => {
        setKeyword('');
    };

    return (
        <form
            onSubmit={onSubmit}
            onReset={onReset}
            className={cn(className, 'form-inline', 'flex-nowrap')}
        >
            <label className="sr-only" htmlFor="inlineFormSeach">
                Username
            </label>
            <div className="input-group mb-2 mr-sm-2">
                <div className="input-group-prepend">
                    <div className="input-group-text">
                        <i className="bi-search" />
                    </div>
                </div>
                <input
                    ref={searchInput}
                    type="text"
                    className="form-control"
                    id="inlineFormSeach"
                    placeholder={ph}
                />
                <button
                    type="reset"
                    className="position-absolute close my-auto text-muted p-2"
                    style={{
                        top: '0',
                        bottom: '0',
                        right: '0',
                        zIndex: '3',
                    }}
                >
                    &times;
                </button>
            </div>
            <button type="submit" className="btn btn-primary mb-2">
                Search
            </button>
        </form>
    );
};

export default InlineSearch;
