import React, { useContext, useMemo, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Icon from '../../UI/Icon';
import UserInfoContext from '../../../contexts/UserInfoContext';
import Wait from '../../UI/Wait';
import ReactTooltip from 'react-tooltip';
import searchFilterIfConsist from '../../UI/helpers/searchFilterIfConsist';

const RegModelsTable = ({
  models = [],
  keyword,
  emptyText,
  loading,
  currentModel,
  onModelSelected = () => {},
  onRowClicked = () => {},
  onPreviewClick = () => {},
}) => {
  const { app } = useContext(UserInfoContext);

  const colsToSearch = ['name'];
  const displayedModels = useMemo(() => {
    return searchFilterIfConsist(models, colsToSearch, keyword);
  }, [models, keyword]);

  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer',
      },
    },
  };

  const conditionalRowStyles = useMemo(
    () => [
      {
        when: ({ _id }) => _id === currentModel?._id,
        style: {
          backgroundColor: 'antiquewhite',
        },
      },
    ],
    [currentModel]
  );

  const columns = useMemo(
    () => [
      { name: '#', grow: 0, cell: (_, index) => index + 1 },
      {
        name: 'Select',
        grow: 0,
        cell: (row, _index, _column, id) => {
          const iconName =
            row._id === currentModel?._id ? 'bi-check2-square' : 'bi-square';
          return (
            <div
              onClick={() => {
                onModelSelected(row);
              }}
              data-tip
              data-for="selectTip"
            >
              <Icon iconName={`btn ${iconName}`} />
              <ReactTooltip id="selectTip" place="top" effect="solid">
                Select model
              </ReactTooltip>
            </div>
          );
        },
      },
      { name: 'Name', grow: 1, cell: ({ name }) => name },
      { name: 'order_type', grow: 1, cell: ({ order_type }) => order_type },
      { name: 'id', grow: 1, cell: ({ _id }) => _id },
      {
        name: 'Actions',
        grow: 1,
        cell: (row) => (
          <div>
            <span
              data-tip
              data-for="viewTip"
              onClick={() => {
                onPreviewClick(row);
              }}
            >
              <Icon iconName="bi bi-info-circle" />
              <ReactTooltip id="viewTip" place="top" effect="solid">
                View
              </ReactTooltip>
            </span>
          </div>
        ),
      },
    ],
    [currentModel]
  );

  return (
    <DataTable
      dense
      striped
      highlightOnHover
      data={displayedModels}
      columns={columns}
      conditionalRowStyles={conditionalRowStyles}
      customStyles={customStyles}
      onRowClicked={onRowClicked}
      progressPending={loading}
      emptyText={emptyText}
      progressComponent={
        <div className="p-5">
          <Wait />
        </div>
      }
    />
  );
};

export default RegModelsTable;
