import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

const CheckboxSwitch = ({
  className,
  name,
  value,
  label,
  defaultChecked,
  onChange = () => {},
}) => {
  const { register } = useFormContext();
  const onChangeField = (data) => {
    onChange(name, data?.target?.checked);
  };
  return (
    <div className={clsx('custom-control custom-switch', className)}>
      <input
        ref={register}
        name={name}
        checked={value}
        className="custom-control-input"
        type="checkbox"
        id={name}
        value={value}
        onChange={onChangeField}
        defaultChecked={defaultChecked}
      />
      <label className="custom-control-label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export default CheckboxSwitch;
