import ExpressTable from '../Transactions/ExpressTable';
import { Modal, Button } from 'react-bootstrap';

const ConsumerTransactionDetails = ({
  transaction,
  close,
  showPaymentInfo,
}) => {
  if (!transaction) {
    close();
    return null;
  }
  return (
    <Modal show={!!transaction} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Consumer transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ExpressTable
          label="Transaction info"
          data={transaction}
          fields={[['id', 'ID'], 'timestamp', 'status', 'type']}
        />

        <ExpressTable
          label="Shop info"
          data={transaction.shop}
          fields={['name', 'reference']}
        />
        {showPaymentInfo ? (
          <ExpressTable
            label="Payment info"
            data={transaction}
            fields={[
              'account_number',
              'account_type',
              'amount',
              'currency',
              'order',
            ]}
          />
        ) : (
          ''
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConsumerTransactionDetails;
