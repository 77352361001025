

let PENDING = -100;
let REJECT = 501;
let ACCEPT = 502;
let DISPUTE = 503;
let CHARGEBACK = 1000;
let CAPTURE = 1001;


let getStatus = (transaction_status) => {
    if (transaction_status == PENDING) {
        return "Pending"
    } else if (transaction_status == REJECT) {
        return "Reject"
    } else if (transaction_status == ACCEPT) {
        return "Accept"
    } else if (transaction_status == DISPUTE) {
        return "Dispute"
    } else if (transaction_status == CHARGEBACK) {
        return "Chargeback"
    } else if (transaction_status == CAPTURE) {
        return "Capture"
    }
};

export { getStatus }