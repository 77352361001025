import { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { NotificationManager } from 'react-notifications';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import { Link } from 'react-router-dom';
import getConsumer from '../../api/utils/consumer/getConsumer';
import getConsumerApps from '../../api/utils/consumer/getConsumerApps';
import getConsumerDevice from '../../api/utils/consumer/getConsumerDevice';
import useSearchString from '../../utils/useSearchString';
import languages from '../../__fixtures__/languages';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import SmartForm from '../Form/SmartForm';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';
import mapResponseToFormData from './helpers/mapResponseToFormData';
import moment from 'moment-timezone';
import prepareToPublish from './helpers/prepareToPublish';
import ButtonActionForm from '../Form/elements/ButtonActionForm';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UploadFileWithPreview from '../Form/elements/UploadFileWithPreview';
import { Modal, Button, Col, Image } from 'react-bootstrap';
import setConsumerCommand from '../../api/utils/consumer/setConsumer';
import { updateConsumer } from '../../api/utils/consumer/setAdminConsumer';

const ConsumerEdit = () => {
  const history = useHistory();
  const [consumer, setConsumer] = useState();
  const [consumerDevices, setConsumerDevices] = useState();
  const [consumerApps, setConsumerApps] = useState();
  const [formData, setFormData] = useState();
  const [progress, done] = useLoader([formData, consumer]);
  const { id } = useSearchString();

  const [showPswrdChange, setShowPswrdChange] = useState(false);
  const handleShowPswrdChange = () => setShowPswrdChange(true);
  const handleClosePswrdChange = () => setShowPswrdChange(false);

  const devicesColumns = useMemo(
    () => [
      { name: '#', cell: (_, index) => index + 1 },
      { name: 'Name', cell: ({ name }) => name },
      { name: 'Type', cell: ({ type }) => type },
    ],
    []
  );

  const appsColumns = useMemo(
    () => [
      { name: '#', cell: (_, index) => index + 1 },
      { name: 'Name', cell: ({ name }) => name },
      { name: 'Class', cell: ({ class: cls }) => cls },
      { name: 'Active', cell: ({ active }) => active?.toString() },
    ],
    []
  );

  useEffect(() => {
    const resultHandler = (data) => {
      setConsumer(data.consumer);
    };

    const errorHandler = (error) => {
      setConsumer({});
      NotificationManager.error(error.description, 'Error', 4000);
    };

    getConsumer({ id }, errorHandler).then((data) => {
      return resultHandler(data);
    });
  }, [id]);

  useEffect(() => {
    getConsumerDevice({ id })
      .then(({ devices }) => devices)
      .then(setConsumerDevices);
  }, [id]);

  useEffect(() => {
    getConsumerApps({ id })
      .then(({ apps }) => apps)
      .then(setConsumerApps);
  }, [id]);

  useEffect(() => {
    if (consumer && Object.keys(consumer).length !== 0) {
      console.info(consumer);
      setFormData(mapResponseToFormData(consumer));
    } else {
      setFormData(null);
    }
  }, [consumer]);

  let timezones = moment.tz.names();

  const onSubmit = (data) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Saved');
        history.goBack();
      } else {
        NotificationManager.error('Error');
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    const submitData = prepareToPublish(id, data);

    console.log('onSubmit: data ==', data);
    console.log('onSubmit: submitData ==', submitData);

    updateConsumer(submitData, errorHandler).then((data) => {
      return resultHandler(data);
    });
  };

  let onChangePswrdClick = () => {
    handleShowPswrdChange();
  };

  const onSubmitPassword = (data) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('New password saved', 'Success', 4000);
      }
      handleClosePswrdChange();
    };
    const errorHandler = (error) => {
      if (error) {
        NotificationManager.error(error.description, 'Error', 4000);
      }
    };
    setConsumerCommand(
      {
        newPassword: data.str_new_password1,
        password: data.str_old_password,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  return done ? (
    formData ? (
      <div>
        <NotificationContainer />
        <SmartForm
          onValid={() => {}}
          onSubmit={onSubmit}
          defaultValues={formData}
        >
          <h3>Edit Consumer Info (ID#{id})</h3>
          <Link
            to={`/user/transactions?consumerId=${consumer?._id}`}
            className="btn btn-primary"
          >
            Show transactions
          </Link>
          <button
            className="btn btn-warning m-2"
            type="button"
            onClick={onChangePswrdClick}
          >
            Change password
          </button>
          <hr />
          <div className="container">
            <div className="row row-cols-2">
              <div className="col">
                <h5>Devices</h5>
                <DataTable
                  dense
                  striped
                  highlightOnHover
                  data={consumerDevices}
                  columns={devicesColumns}
                />
              </div>
              <div className="col">
                <h5>Applications</h5>
                <DataTable
                  dense
                  striped
                  highlightOnHover
                  data={consumerApps}
                  columns={appsColumns}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-2">
                <UploadFileWithPreview
                  name="settings.str_photo_data"
                  style={{ maxHeight: '100px' }}
                  type="text"
                />
              </div>
              <div className="col-5">
                <InputFieldWithValidation label="Email" name="email" />
                <InputFieldWithValidation
                  label="First name"
                  name="str_firstname"
                />
                <InputFieldWithValidation
                  label="Last name"
                  name="str_lastname"
                />
                <InputFieldWithValidation
                  label="Telephone"
                  name="str_telephone"
                />
                <SelectFieldWithValidation
                  label="Language"
                  name="str_language"
                  options={languages}
                />
                <SelectFieldWithValidation
                  label="Timezone"
                  options={timezones}
                  name="str_timezone"
                />
                <InputFieldWithValidation label="Ssn" name="str_ssn" />
              </div>
              <div className="col-5">
                <InputFieldWithValidation
                  label="Street Address"
                  name="str_address1"
                />
                <InputFieldWithValidation
                  label="Address additional"
                  name="str_address2"
                />
                <InputFieldWithValidation label="City" name="str_city" />
                <InputFieldWithValidation label="Zip" name="str_zip" />
                <SelectFieldWithValidation
                  label="Country"
                  name="str_country"
                  options={['ENG', 'RUS', 'FIN']}
                />
              </div>
            </div>
          </div>
          <ButtonActionForm
            label="Save changes"
            name="active"
            value={true}
            type="submit"
            className="btn btn-primary mt-2"
          />
        </SmartForm>
        <Modal show={showPswrdChange} onHide={handleClosePswrdChange}>
          <SmartForm onSubmit={onSubmitPassword}>
            <Modal.Header closeButton>
              <Modal.Title>User info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container-fluid">
                <h2>Change password</h2>
                <div className="row">
                  <div className="col-md-6">
                    <InputFieldWithValidation
                      className="mb-3"
                      name="str_new_password1"
                      label="New password"
                      type="password"
                      required
                    />
                    <InputFieldWithValidation
                      className="mb-3"
                      name="str_new_password2"
                      label="Repeat new password"
                      type="password"
                      required
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ButtonActionForm
                label="Update password"
                name="active"
                value={true}
                type="submit"
              />
            </Modal.Footer>
          </SmartForm>
        </Modal>
      </div>
    ) : (
      'not found'
    )
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default ConsumerEdit;
