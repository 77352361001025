import Honkio from '../../Honkio';

const getScheduledJobsList = (options = {}, errorHandler = () => {}) => {
  const {
    query_merchant,
    query_application,
    query_search,
    query_skip,
    query_count,
  } = options;
  return Honkio().mainShop.userFetch(
    'adminappschedulerjoblist',
    {
      query_application,
      query_merchant,
      query_search,
      query_skip,
      query_count,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};
export default getScheduledJobsList;
