import { useState, useEffect } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import statusesToEdgeList from './helpers/statusesToEdgeList';
import statusesToNodeList from './helpers/statusesToNodeList';
import LayoutFlow from './LayoutFlow';
import { isNode, getOutgoers } from 'react-flow-renderer';
import getStatus from './helpers/getStatus';
import prepareToPublish from './helpers/prepareToPublish';

const AssetTypesSchemeEditForm = (props) => {
  const { assetTypesScheme, onSave } = props;
  const [nodes, setNodes] = useState(null);

  const [rfInstance, setRfInstance] = useState(null);

  useEffect(() => {
    if (!assetTypesScheme || assetTypesScheme.length < 1) return;
    const nodeList = statusesToNodeList(assetTypesScheme);
    const edgesList = statusesToEdgeList(assetTypesScheme);
    setNodes([...nodeList, ...edgesList]);
  }, [assetTypesScheme]);

  const onPublish = () => {
    if (rfInstance) {
      const { elements } = rfInstance.toObject();
      const nodes = elements.filter(isNode);
      const asset_types_hierarchy = nodes.reduce(
        (asset_types_hierarchy, node) => {
          const parent_of = getOutgoers(node, elements).map((outgoer) => {
            return outgoer.id;
          });
          const hierarchy = getStatus({ ...node, parent_of });
          return { ...asset_types_hierarchy, [node.id]: hierarchy };
        },
        {}
      );
      const editedModel = prepareToPublish(asset_types_hierarchy);
      onSave(editedModel);
    }
  };

  const onLoad = (instance) => {
    setRfInstance(instance);
  };

  return assetTypesScheme && Object.keys(assetTypesScheme).length > 0 ? (
    <div className="w-100 h-50 flex-column">
      {nodes && (
        <ReactFlowProvider>
          <LayoutFlow
            onLoad={onLoad}
            onPublish={onPublish}
            initialElements={nodes}
            assetTypesScheme={assetTypesScheme}
          />
        </ReactFlowProvider>
      )}
    </div>
  ) : (
    'not found'
  );
};

export default AssetTypesSchemeEditForm;
