import Honkio from '../../Honkio';

const getRoomsList = (options = {}, errorHandler = {}) => {
  const { query_object, query_user } = options;

  return Honkio().mainShop.userFetch(
    'chatroomlist',
    {
      query_object,
      query_user,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getRoomsList;
