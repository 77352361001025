import { useMemo, useState, useContext, useEffect } from 'react';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import DataTable from 'react-data-table-component';
import InlineSearch from '../UI/InlineSearch';
import Wait from './../UI/Wait';
import UserInfoContext from '../../contexts/UserInfoContext';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';

const colsToSearch = ['name', 'vendorcode'];
const typedCol = 'id_type';

const AssetTypeList = ({
    assetTypes = [],
    emptyText = '',
    loading,
    onDeleteClick = () => {},
    onRowClicked = () => {},
    onSetDefault = () => {},
}) => {
    const {
        merchant: { id: merchantId, is_admin },
    } = useContext(UserInfoContext);

    const [keyword, setKeyword] = useState('');
    const [typeFilter, setTypeFilter] = useState('');

    const columns = [
        {
            name: '#',
            grow: 0,
            cell: (_, index) => index + 1,
        },
        { name: 'Name', grow: 3, cell: ({ name }) => name },
        {
            name: 'Description',
            grow: 1,
            cell: ({ description }) => description,
        },
        {
            name: 'Default',
            grow: 0,
            omit: !is_admin,
            cell: ({ _id, is_default }) => (
                <Form.Switch
                    id={`is-default-switch-${_id}`}
                    defaultChecked={is_default}
                    onChange={() => {
                        onSetDefault(_id, !is_default);
                    }}
                    className="m-0"
                    name="is_default"
                    label=""
                />
            ),
        },
        {
            name: 'Actions',
            grow: 1,
            right: true,
            cell: ({ _id, name }) => (
                <div className="d-flex">
                    <Link to={`/asset/type/edit?assetType=${_id}`}>
                        <Icon iconName="bi-pen" />
                    </Link>
                    <div
                        onClick={() => {
                            onDeleteClick(_id, name);
                        }}
                        data-tip
                        data-for="deleteTip"
                    >
                        <Icon iconName="btn bi-trash" />
                        <ReactTooltip id="deleteTip" place="top" effect="solid">
                            Delete
                        </ReactTooltip>
                    </div>
                </div>
            ),
        },
    ];

    const displayedAssetTypes = useMemo(() => {
        const searchFiltered = searchFilterIfConsist(
            assetTypes,
            colsToSearch,
            keyword
        );
        const typifiedAssetTypes = typifyIfEquals(
            searchFiltered,
            typedCol,
            typeFilter
        );
        return typifiedAssetTypes;
    }, [keyword, assetTypes, typeFilter, columns]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <InlineSearch setKeyword={setKeyword} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <DataTable
                        dense
                        striped
                        noHeader
                        highlightOnHover
                        progressPending={loading}
                        progressComponent={
                            <div className="p-5">
                                <Wait />
                            </div>
                        }
                        data={displayedAssetTypes}
                        // data={assetTypes}
                        columns={columns}
                        emptyText={emptyText}
                        onRowClicked={onRowClicked}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssetTypeList;
