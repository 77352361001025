import { useContext, useEffect } from 'react';
import UserInfoContext from '../../contexts/UserInfoContext';
import Icon from '../UI/Icon';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const Selected = () => {
  const { app, merchant, setInfo } = useContext(UserInfoContext);
  const onDeleteClick = () => {
    setInfo({ merchant: {} });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [app, merchant]);

  return (
    <div>
      {app?.id && merchant?.id ? (
        <Link to="/app_secrets" data-tip data-for="app_secrets_link">
          <Icon iconName="bi bi-incognito" />
          <ReactTooltip id="app_secrets_link" place="bottom" effect="solid">
            View app {app?.name ? `"${app.name}"` : null} secrets
          </ReactTooltip>
        </Link>
      ) : null}
      {app?.name ?? `Application hasn't been set`}
      {' / '}
      {merchant?.name ?? `Merchant hasn't been set`}
      <a
        onClick={() => {
          onDeleteClick();
        }}
      >
        <Icon iconName="bi bi-x" />
      </a>
    </div>
  );
};

export default Selected;
