const statusesToEdgeList = (statuses) => {
  return Object.keys(statuses).flatMap((key) =>
    statuses[key]['can_change_to'].map((id) => ({
      id: `${key}-${id}`,
      source: key,
      target: id,
      arrowHeadType: 'arrow',
    }))
  );
};

export default statusesToEdgeList;
