import React, { useContext, useEffect, useState, useMemo } from 'react';
import useSearchString from '../../../utils/useSearchString';
import getConsumerFull from '../../../api/utils/consumer/getConsumerFull';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import BackButton from '../../UI/BackButton';
import _ from 'lodash';
import cn from 'classnames';
import { getFile } from '../../../api/utils/files/getFile';
import Wait from '../../UI/Wait';
import { Table, Image } from 'react-bootstrap';

const ViewFile = () => {
    const { user_id, id } = useSearchString();

    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(null);

    const [user, setUser] = useState(null);
    const [loadingUser, setLoadingUser] = useState(false);

    useEffect(() => {
        if (id) {
            fetchFile();
        }
    }, [id]);

    useEffect(() => {
        if (user_id) {
            fetchUser();
        }
    }, [user_id]);

    const fetchFile = async () => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setFile(data.file);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(
                error?.description || 'File not found',
                'Error'
            );
            setFile(null);
        };

        try {
            setLoading(true);
            const res = await getFile({ id }, errorHandler);
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            setFile(null);
            console.log(error);
        }
    };

    const fetchUser = async () => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setUser(data.user);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            console.log('error: ', error);
            setUser(null);
            NotificationManager.error('Error fetching user', 'Error');
        };

        try {
            setLoading(true);
            const res = await getConsumerFull({ id: user_id }, errorHandler);
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            NotificationManager.error('Error fetching user', 'Error');
            console.log('Error fetching a consumer: ', error);
            setUser(null);
        }
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <div
                className={cn(
                    'd-flex',
                    'justify-content-between',
                    'align-items-center'
                )}
            >
                <h2>
                    <BackButton
                        to={`/user/documents?id=${user_id}`}
                        text="Back to users list"
                    />
                    Document
                </h2>
            </div>

            <div className="row">
                {loading ? (
                    <Wait />
                ) : (
                    <Table striped bordered hover variant="light">
                        <thead>
                            <tr>
                                <th>Field</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>{file?.display_name}</td>
                            </tr>
                            <tr>
                                <td>owner</td>
                                <td>{file?.user_owner}</td>
                            </tr>
                            {file?.file_type === 'image' ? (
                                <tr>
                                    <td colSpan={2}>
                                        <a
                                            href={file?.url || ''}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Image
                                                src={file.url}
                                                rounded
                                                fluid
                                                alt="Image"
                                                width="100%"
                                            />
                                        </a>
                                    </td>
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan={2}>
                                        <a
                                            href={file?.url || ''}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {file?.url || 'download'}
                                        </a>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                )}
            </div>
        </div>
    );
};

export default ViewFile;
