import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Honkio from '../../../api/Honkio';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import getRoomMessages from '../../../api/utils/chat/getRoomMessages';
import MessageItem from './MessageItem';
import getRoom from '../../../api/utils/chat/getRoom';
import Wait from '../../UI/Wait';
import cn from 'classnames';
import sendMessage from '../../../api/utils/chat/sendMessage';
import { getAsset } from '../../../api/utils/asset/getAssets';
import deleteMessage from '../../../api/utils/chat/deleteMessage';
import EditMessageModal from './EditMessageModal';
import editMessage from '../../../api/utils/chat/editMessage';
import getConsumerInfo from '../../../api/utils/consumer/getConsumerInfo';

const Room = () => {
  const msgRef = useRef(null);
  const [appInfo, setAppInfo] = useState(null);
  const [info, setInfo] = useState(null);
  const search = useLocation().search;
  const room_id = new URLSearchParams(search).get('id');

  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  const [messages, setMessages] = useState([]);
  const [totalMsgs, setTotalMsgs] = useState(0);

  const [asset, setAsset] = useState(null);

  const [newMessage, setNewMessage] = useState('');
  const [editedMessage, setEditedMessage] = useState(null);
  const [showEditMsgModal, setShowEditMsgModal] = useState(false);

  const [loadings, setLoadings] = useState({
    user: false,
    room: false,
    messages: false,
    asset: false,
  });

  const [isSendingMsg, setIsSendingMsg] = useState(false);

  const fetchCurrentUser = async () => {
    const options = {};
    const handleAuthError = (err) => {
      console.log(`Error getting user info ${err}`);
      setLoadings({ ...loadings, user: false });
    };
    setLoadings({ ...loadings, user: true });
    const res = await getConsumerInfo(options, handleAuthError);
    setLoadings({ ...loadings, user: false });
    setInfo(res?.consumer);
  };

  useEffect(() => {
    fetchCurrentUser();

    const notParsedInfo = localStorage.getItem('app_merchant_info');
    if (notParsedInfo) {
      setAppInfo(JSON.parse(notParsedInfo));
    }
  }, []);

  const fetchRoomById = async (id) => {
    const errorHandler = (error) => {
      if (error) NotificationManager.error(error.description, 'Error', 4000);
      setLoadings({ ...loadings, room: false });
      setRoom(null);
      setParticipants([]);
    };
    setLoadings({ ...loadings, room: true });
    const res = await getRoom({ id }, errorHandler);
    setLoadings({ ...loadings, room: false });
    setRoom(res.room);
    setParticipants(res.participants);
    return res;
  };

  useEffect(() => {
    if (room_id) {
      fetchRoomById(room_id);
    }
  }, [room_id]);

  const fetchRoomMsgs = async (id) => {
    const errorHandler = (error) => {
      if (error) NotificationManager.error(error.description, 'Error', 4000);
      setLoadings({ ...loadings, messages: false });
    };
    setLoadings({ ...loadings, messages: true });
    const res = await getRoomMessages(id, errorHandler);
    setLoadings({ ...loadings, messages: false });
    setMessages(res.messages);
    setTotalMsgs(res.total);
  };

  const fetchRoomAsset = async (id) => {
    console.log(`fetchRoomAsset; id = ${id}`);
    const errorHandler = (error) => {
      if (error) NotificationManager.error(error.description, 'Error', 4000);
      setLoadings({ ...loadings, asset: false });
    };
    setLoadings({ ...loadings, asset: true });
    const res = await getAsset({ id }, errorHandler);
    setLoadings({ ...loadings, asset: false });
    setAsset(res.asset);
  };

  useEffect(() => {
    if (room?._id && room?.object) {
      fetchRoomMsgs(room._id);
      fetchRoomAsset(room.object);
    }
  }, [room]);

  const checkMsg = (sender) => {
    return sender === info?._id;
  };

  const getParticipant = (id) => {
    return participants.find((item) => item._id === id);
  };

  const renderMessages = () => {
    if (!messages.length) return <h3>No messages yet...</h3>;
    return messages.map((item) => {
      const belongsToCurrent = checkMsg(item.sender);
      const participant = getParticipant(item.sender);
      return (
        <MessageItem
          _id={item._id}
          onEdit={() => {
            setEditedMessage(item);
            setShowEditMsgModal(true);
          }}
          onDelete={handleDeleteMessage}
          className={cn({
            'align-self-end': belongsToCurrent,
            'align-self-start': !belongsToCurrent,
          })}
          key={`message-item-${item._id}`}
          isOwner={belongsToCurrent}
          author={`${participant?.settings?.str_firstname || 'Anonymous'} ${
            participant?.settings?.str_lastname || 'Anonymous'
          }`}
          text={item.text}
          created_at={item.created_at}
        />
      );
    });
  };

  /**
   *
   * @returns boolean, true if loading
   */
  const checkIfLoading = () => {
    return Object.values(loadings).some((item) => item === true);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage) {
      NotificationManager.error('You need to enter message', 'Error', 4000);
      return;
    }
    if (!asset?.properties?.userid) {
      NotificationManager.error(
        'Asset has no properties.userid!',
        'Error',
        4000
      );
      return;
    }
    const errorHandler = (err) => {
      setIsSendingMsg(false);
      if (err) {
        NotificationManager.error(err.message, 'Error', 4000);
      }
    };
    setIsSendingMsg(true);
    const res = await sendMessage(
      {
        room: room_id,
        sender_id: info._id,
        receiver_id: asset.properties.userid,
        text: newMessage,
        application: appInfo.app.id,
        merchant: appInfo.merchant.id,
      },
      errorHandler
    );
    setNewMessage('');
    setIsSendingMsg(false);
    if (res.message) {
      setMessages([...messages, res.message]);
    }
  };

  const handleEditMessage = async (e, data) => {
    e.preventDefault();
    const errorHandler = (err) => {
      setIsSendingMsg(false);
      if (err) {
        NotificationManager.error(err.message, 'Error', 4000);
      }
    };
    const res = await editMessage(
      {
        ...data,
        id: editedMessage._id,
        application: appInfo.app.id,
        merchant: appInfo.merchant.id,
      },
      errorHandler
    );
    NotificationManager.success('Message edited', '', 4000);
    fetchRoomMsgs(room?._id);
  };

  const handleDeleteMessage = async (e, id) => {
    e.preventDefault();

    const errorHandler = (err) => {
      setIsSendingMsg(false);
      if (err) {
        NotificationManager.error(err.message, 'Error', 4000);
      }
    };
    const res = await deleteMessage(
      {
        id,
        application: appInfo.app.id,
        merchant: appInfo.merchant.id,
      },
      errorHandler
    );

    NotificationManager.success('Message deleted', '', 4000);
    fetchRoomMsgs(room?._id);
  };

  useEffect(() => {
    if (messages.length && msgRef.current) {
      msgRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [messages, msgRef]);

  return (
    <div className="w-100 h-100">
      <NotificationContainer />
      <EditMessageModal
        item={editedMessage}
        onHide={() => setShowEditMsgModal(false)}
        show={showEditMsgModal}
        onSubmit={handleEditMessage}
      />
      {checkIfLoading() ? (
        <Wait />
      ) : (
        <div className="container-fluid">
          <h2>
            {room?.name ? `${room.name}. Subject: ${asset?.name}` : 'Chat room'}
          </h2>
          <div className="d-flex bd-highlight mb-3 flex-column card p-3 messages_list">
            {renderMessages()}
            <div ref={msgRef}></div>
          </div>
          <form onSubmit={handleSendMessage}>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter message"
                aria-label="Enter message here"
                aria-describedby="button-addon2"
                vlaue={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="submit"
                  id="button-addon2"
                  disabled={isSendingMsg}
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Room;
