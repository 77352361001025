import Honkio from '../../Honkio';
import changePassword from '../user/changePassword';
import getConsumerInfo from './getConsumerInfo';

const setConsumer = async function (options = {}, errorHandler = {}) {
  const { password, newPassword } = options;

  const consumergetErrorHandler = (error) => {
    console.error('Failed to get consumer for consumerset: ', error);
  };

  const res = await getConsumerInfo(null, consumergetErrorHandler);

  let consumer = {};
  if (res && res.status === 'accept') {
    consumer = res.consumer;
  } else {
    return;
  }

  return changePassword(
    { user_id: consumer?._id, password: newPassword },
    errorHandler
  );
};

export default setConsumer;
