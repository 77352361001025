import Honkio from '../../Honkio';

const setUserRole = async function (
  { user_id, role_id, action },
  errorHandler
) {
  const options = {
    user_id,
    role_id,
    version: 3,
    action,
  };
  return Honkio().mainShop.userFetch(
    'userroleset',
    {
      ...options,
    },
    errorHandler
  );
};

export const addRoleToUser = async (options = {}, errorHandler = () => {}) => {
  return setUserRole({ ...options, action: 'create' }, errorHandler);
};

export const removeRoleFromUser = async (
  options = {},
  errorHandler = () => {}
) => {
  return setUserRole({ ...options, action: 'delete' }, errorHandler);
};
