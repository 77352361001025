import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useSearchString from '../../utils/useSearchString';
import getMerchantList from '../../api/utils/merchant/getMerchantList';
import MerchantList from './components/MerchantList';
import InlineSearch from '../UI/InlineSearch';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import UserInfoContext from '../../contexts/UserInfoContext';

import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';

const colsToSearch = ['str_name', 'str_email'];
const typedCol = 'payment';

const PER_PAGE_CHOICES = [1, 25, 50, 100, 500];

const RegMerchants = () => {
  const userInfo = useContext(UserInfoContext);

  const { appid = null, appname = null } = useSearchString();

  const history = useHistory();

  const [merchantsList, setMerchantsList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [type, setType] = useState('');
  const selectedAppId = appid
    ? appid
    : userInfo.app?.id
    ? userInfo.app.id
    : null;

  const pageTitle = appname
    ? `Registered merchants (${appname})`
    : appid
    ? 'Registered merchants'
    : userInfo.app?.name
    ? `Registered merchants (${userInfo.app.name})`
    : 'Registered merchants';
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  useEffect(() => {
    if (selectedAppId) {
      setLoading(true);
      fetchMerchants();
    }
  }, [selectedAppId, perPage, keyword]);

  const fetchMerchants = async (page = 1, newParent = null) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        setMerchantsList(
          data.merchants.reduce(
            (carry, item) => [
              ...carry,
              {
                id: item.id,
                str_name: item?.settings?.str_name,
                str_email: item?.settings?.str_email,
                payment: item?.lastpayment?.due_date,
                active: item?.active,
              },
            ],
            []
          )
        );
        setTotalRows(data?.total);
        setLoading(false);
      } else {
        NotificationManager.error('Error');
        setLoading(false);
        setMerchantsList([]);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setLoading(false);
      setMerchantsList([]);
    };

    const options = {
      query_application: selectedAppId,
      query_parent: newParent,
      query_non_shareholders: true,
      query_skip: (+page - 1) * perPage,
      query_count: perPage,
      query_search: keyword,
    };
    getMerchantList(options, errorHandler).then((data) => {
      return resultHandler(data);
    });
  };

  let onRowClick = (row) => {
    history.push(`/registrations/merchant/ubo_graph?merchant_id=${row.id}`);
    return;
  };

  const handlePageChange = (page) => {
    fetchMerchants(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  return (
    <div className="container-fluid">
      <NotificationContainer />
      <h2>{pageTitle}</h2>
      {selectedAppId ? (
        <div>
          <div className="row">
            <div className="col">
              <InlineSearch setKeyword={setKeyword} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <MerchantList
                loading={loading}
                onRowClicked={onRowClick}
                data={merchantsList}
                rowsCountPerPage={perPage}
                handleRowsPerPage={handleRowsPerPage}
                perPageChoices={PER_PAGE_CHOICES}
                totalRows={totalRows}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      ) : (
        'Select application first'
      )}
    </div>
  );
};

export default RegMerchants;
