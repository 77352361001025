import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const Select = forwardRef((props, ref) => {
  const { options, defaultValue, ...rest } = props;
  return (
    <select className="custom-select d-block w-100 ml-2" {...rest} ref={ref}>
      <option className="d-none">{defaultValue}</option>
      {options.map((opt, index) =>
        typeof opt === 'object' ? (
          <option key={index} value={opt.value}>
            {opt.name}
          </option>
        ) : (
          <option key={index}>{opt}</option>
        )
      )}
    </select>
  );
});

const SelectFieldArray = ({
  className,
  label,
  name,
  defaultValue,
  options,
}) => {
  const { control } = useFormContext();
  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <Controller
        render={(props) => (
          <Select options={options} defaultValue={defaultValue} {...props} />
        )}
        control={control}
        name={name}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default SelectFieldArray;
