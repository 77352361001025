import Honkio from '../../Honkio';

const getMerchantPSPsPayments = (options = {}, errorHandler = () => {}) => {
    const {
        query_skip = 0,
        query_count = 25,
        query_merchant = '',
        query_keyword = '',
        query_application = '',
        query_status = '',
        query_statuses = null,
    } = options;
    return Honkio().mainShop.userFetch(
        'adminpaymentspsps',
        {
            query_skip,
            query_count,
            query_merchant,
            query_keyword,
            query_application,
            query_status,
            query_statuses,
            action: 'read',
            version: 3,
        },
        errorHandler
    );
};

export default getMerchantPSPsPayments;
