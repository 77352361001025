import Honkio from '../../Honkio';

const getEvent = (options = {}, errorHandler = () => {}) => {
  const { id, query_info } = options;
  return Honkio().mainShop.userFetch(
    'eventassetget',
    {
      id,
      query_info,
      action: 'read',
      version: 3,
    },
    errorHandler
  );
};

export default getEvent;
