import Honkio from '../../Honkio';
const getConsumerTransactions = (options = {}, errorHandler = {}) => {
  const {
    page = 0,
    query_count = 0,
    debug,
    query_timestamp,
    user_id,
    query_id,
    query_valid,
    query_status,
    query_all = true,
  } = options;
  return Honkio().mainShop.userFetch(
    'consumertransactionlist',
    {
      debug,
      user_id,
      query_skip: +page * query_count,
      query_count,
      query_status,
      query_valid,
      query_id,
      query_timestamp,
      query_all,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};
export default getConsumerTransactions;
