import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { Form, Button, Spinner } from 'react-bootstrap';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import getAppInfo from '../../api/utils/app/getAppInfo';
import _ from 'lodash';
import { updateApp } from '../../api/utils/app/setAppInfo';

const AVAILABLE_SERVICE_PROVIDERS = [
    {
        label: 'Firebase',
        value: 'firebase',
    },
];

const INITIAL_PUSH_NOTIFICATIONS_CONFIG = {
    firebase: '',
};

const PushNotificationsConfigForm = ({
    applicationId,
    submitLoading = false,
}) => {
    const [application, setApplication] = useState();

    const [serviceProvider, setServiceProvider] = useState(
        AVAILABLE_SERVICE_PROVIDERS[0].value
    );
    const [pushNotificationsConfig, setPushNotificationsConfig] = useState(
        INITIAL_PUSH_NOTIFICATIONS_CONFIG
    );

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (applicationId) {
            fetchApp();
        }
    }, [applicationId]);

    const fetchApp = async () => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setApplication(data.application);

                let resultingConfig = {};
                let loadedConfig =
                    data.application?.settings?.push_notifications;
                Object.keys(loadedConfig).forEach((item) => {
                    resultingConfig[item] = JSON.stringify(
                        loadedConfig[item],
                        null,
                        2
                    );
                });

                setPushNotificationsConfig(resultingConfig);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setApplication({});
            setPushNotificationsConfig(INITIAL_PUSH_NOTIFICATIONS_CONFIG);
        };

        if (applicationId) {
            try {
                setLoading(true);
                const res = await getAppInfo(
                    { appid: applicationId },
                    errorHandler
                );
                resultHandler(res);
            } catch (error) {
                console.log('error fetching app', error);
                setPushNotificationsConfig(INITIAL_PUSH_NOTIFICATIONS_CONFIG);
            }
        } else {
            setApplication({});
            setPushNotificationsConfig(INITIAL_PUSH_NOTIFICATIONS_CONFIG);
        }
    };

    const handleUpdateApp = async (data = {}) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setApplication(data.application);
                NotificationManager.success(
                    'Application saved',
                    'Success',
                    4000
                );
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            const res = await updateApp(
                { id: applicationId, ...data },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('error updating app: ', error);
        }
    };

    const handleChangeProviderConfig = (val) => {
        if (!serviceProvider) {
            NotificationManager.error('You must select service provider first');
            return;
        }
        setPushNotificationsConfig((prev) => ({
            ...prev,
            [serviceProvider]: val,
        }));
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if (!serviceProvider) {
            NotificationManager.error('You must select service provider first');
            return;
        }

        let submitApplication = { id: applicationId, settings: {} };

        let newPushNotificationsConfig = {};
        Object.keys(pushNotificationsConfig).forEach((item) => {
            newPushNotificationsConfig[item] = JSON.parse(
                pushNotificationsConfig[item]
            );
        });

        submitApplication.settings.push_notifications =
            newPushNotificationsConfig;

        handleUpdateApp(submitApplication);
    };

    return (
        <div>
            <Form onSubmit={handleSubmitForm}>
                <Form.Group className="mb-3" controlId="operator">
                    <Form.Label>Service provider</Form.Label>
                    <Form.Control
                        as={'select'}
                        aria-label="Operator"
                        name="operator"
                        value={serviceProvider || ''}
                        onChange={(e) => setServiceProvider(e.target.value)}
                        className="custom-select"
                    >
                        <option value="">
                            &mdash; Select operator &mdash;
                        </option>
                        {AVAILABLE_SERVICE_PROVIDERS.map((item, index) => (
                            <option
                                key={`${item.value}-${index}-service-provider-option`}
                                value={item.value}
                            >
                                {item.label}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Select push notifications service provider here
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="data.name">
                    <Form.Label>Settings</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={10}
                        placeholder={
                            serviceProvider === 'firebase'
                                ? 'Firebase service account JSON config'
                                : 'Config json'
                        }
                        name="firebase"
                        value={pushNotificationsConfig?.[serviceProvider] || ''}
                        onChange={(e) =>
                            handleChangeProviderConfig(e.target.value)
                        }
                    />
                    <Form.Text className="text-muted">
                        {serviceProvider === 'firebase'
                            ? 'Paste your firebase service account json config file contents here'
                            : 'Paste your json config here'}
                    </Form.Text>
                </Form.Group>
                <Button
                    className={cn('mt-3')}
                    type="submit"
                    variant="primary"
                    disabled={loading || submitLoading}
                >
                    Submit
                    {loading || submitLoading ? (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : null}
                </Button>
            </Form>
        </div>
    );
};

export default PushNotificationsConfigForm;
