import { useLocation } from 'react-router-dom';

const useSearchString = () => {
  let searchParams = new URLSearchParams(useLocation().search)

  let result = []
  for (let [key, value] of searchParams.entries()) {
    result[key] = value
  }
  return result
};

export default useSearchString;
