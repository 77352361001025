import Honkio from '../../../Honkio';

const getSalesReport = (options = {}, errorHandler = {}) => {
  const { start_date, end_date, merchant, point_of_sales, customer } = options;
  return Honkio().mainShop.userFetch(
    'merchantsalesreportget',
    {
      start_date,
      end_date,
      merchant,
      point_of_sales,
      customer,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getSalesReport;
