import Honkio from '../../../Honkio';

const getPermissions = ({ debug } = {}, errorHandler = {}) =>
  Honkio().mainShop.userFetch(
    'adminsettingsservicekeypermissionslist',
    {
      debug,
      version: 3,
      action: 'read',
    },
    errorHandler
  );

export default getPermissions;
