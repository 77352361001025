import Honkio from '../../../Honkio';

const listProductTemplates = (options = {}, errorHandler = () => {}) => {
    const { query_merchant, query_application, query_skip, query_count } =
        options;
    return Honkio().mainShop.userFetch(
        'admintemplatemodelsproductlist',
        {
            query_merchant,
            query_application,
            query_skip,
            query_count,
            action: 'read',
            version: 3,
        },
        errorHandler,
        true
    );
};

export default listProductTemplates;
