import Honkio from '../../../Honkio';

const setScript = (options = {}, errorHandler = () => {}) => {
  const {
    id,
    debug,
    active,
    name,
    text,
    type,
    trigger,
    application_id,
    settings,
    action,
  } = options;
  return Honkio().mainShop.userFetch(
    'adminappscriptsset',
    {
      debug,
      id,
      active,
      name,
      type,
      trigger,
      text,
      application_id,
      settings,
      action,
      version: 3,
    },
    errorHandler
  );
};

export const createScript = (options = {}, errorHandler = () => {}) => {
  return setScript({ ...options, action: 'create' }, errorHandler);
};

export const updateScript = (options = {}, errorHandler = () => {}) => {
  return setScript({ ...options, action: 'update' }, errorHandler);
};

export const deleteScript = (options = {}, errorHandler = () => {}) => {
  return setScript({ ...options, action: 'delete' }, errorHandler);
};
