import { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UserInfoContext from '../../contexts/UserInfoContext';
import { merchantCreate } from '../../api/utils/merchant/setMerchant';
import { NotificationManager } from 'react-notifications';
import useSearchString from '../../utils/useSearchString';
import MerchantInfoForm from './MerchantInfoForm';

const MerchantCreate = () => {
  const userInfo = useContext(UserInfoContext);
  const selectedAppId = userInfo.app?.id;
  const history = useHistory();
  const { parent } = useSearchString();

  const onCreate = (data) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Successfully saved');
        history.goBack();
      } else {
        NotificationManager.error('Error');
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    merchantCreate(selectedAppId, data, errorHandler).then((data) => {
      return resultHandler(data);
    });
  };

  return (
    <div>
      <MerchantInfoForm
        merchantInfo={{ parent: parent, active: true, settings: {} }}
        onSubmit={onCreate}
      />
    </div>
  );
};

export default MerchantCreate;
