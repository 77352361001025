import Honkio from '../../Honkio';

const updateAccessRule = (options = {}, errorHandler = {}) => {
  const {
    id,
    name,
    description,
    model_name,
    conditions,
    actions,
    roles,
    is_global,
    is_active,
  } = options;

  return Honkio().mainShop.userFetch(
    'accessrulesupdate',
    {
      id,
      name,
      description,
      model_name,
      conditions,
      actions,
      roles,
      is_global,
      is_active,
      action: 'update',
      version: 3,
    },
    errorHandler
  );
};
export default updateAccessRule;
