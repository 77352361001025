import { useEffect, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Icon from '../../UI/Icon';
import useSearchString from '../../../utils/useSearchString';
import DataTable from 'react-data-table-component';
import Wait from '../../UI/Wait';
import ReactTooltip from 'react-tooltip';

const MerchantList = ({
  data,
  onRowClicked,
  loading,
  totalRows,
  rowsCountPerPage,
  handleRowsPerPage = () => {},
  perPageChoices = [25],
  handlePageChange = () => {},
}) => {
  const { appid } = useSearchString();

  const columns = useMemo(
    () => [
      {
        name: '#',
        grow: 0,
        cell: (_, index) => index + 1,
      },
      {
        name: 'Name',
        sortable: true,
        selector: (row) => row.str_name,
        grow: 2,
        cell: ({ str_name }) => str_name,
      },
      {
        name: 'Email',
        sortable: true,
        selector: (row) => row.str_email,
        grow: 2,
        cell: ({ str_email }) => str_email,
      },
      {
        name: 'Active',
        sortable: true,
        grow: 0,
        selector: (row) => row.active,
        cell: (row) => <span>{row.active ? 'yes' : 'no'}</span>,
      },
      {
        name: 'Actions',
        right: true,
        grow: 2,
        cell: (row, _index, _column, id) => (
          <div>
            <Link
              to={`/registrations/merchant/ubo_graph?merchant_id=${row.id}`}
              data-tip
              data-for="shareholdersGraphTip"
            >
              <Icon iconName="btn bi-diagram-3-fill" />
              <ReactTooltip
                id="shareholdersGraphTip"
                place="top"
                effect="solid"
              >
                Shareholders graph
              </ReactTooltip>
            </Link>
          </div>
        ),
      },
    ],
    [appid]
  );

  return (
    <DataTable
      dense
      striped
      highlightOnHover
      data={data}
      columns={columns}
      onRowClicked={onRowClicked}
      pagination={true}
      progressPending={loading}
      paginationPerPage={rowsCountPerPage}
      paginationRowsPerPageOptions={perPageChoices}
      onChangeRowsPerPage={handleRowsPerPage}
      paginationServer
      onChangePage={handlePageChange}
      paginationTotalRows={totalRows}
      emptyText={'The list is empty'}
      progressComponent={
        <div className="p-5">
          <Wait />
        </div>
      }
    />
  );
};

export default MerchantList;
