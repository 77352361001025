import Honkio from '../../Honkio';

const setAdminVelocityRule = (options = {}, errorHandler = () => {}) => {
  console.log('setAdminVelocityRule: options ==', options);
  return Honkio().mainShop.userFetch(
    'adminvelocityruleset',
    {
      ...options,
      version: 3,
    },
    errorHandler
  );
};

export const createVelocityRule = (opts = {}, errorHandler = () => {}) => {
  return setAdminVelocityRule({ ...opts, action: 'create' }, errorHandler);
};

export const updateVelocityRule = (opts = {}, errorHandler = () => {}) => {
  return setAdminVelocityRule({ ...opts, action: 'update' }, errorHandler);
};

export const deleteVelocityRule = (opts = {}, errorHandler = () => {}) => {
  return setAdminVelocityRule({ ...opts, action: 'delete' }, errorHandler);
};
