import Honkio from '../../../Honkio';

const setUserSettings = async (options = {}, errorHandler = () => {}) => {
  const { application, merchant, settings, id, action } = options;

  return Honkio().mainShop.userFetch(
    'settingsuserset',
    {
      id,
      application,
      merchant,
      settings,
      version: 3,
      action,
    },
    errorHandler
  );
};

export const createUserSettings = (options = {}, errorHandler = () => {}) => {
  return setUserSettings({ ...options, action: 'create' }, errorHandler);
};

export const updateUserSettings = (options = {}, errorHandler = () => {}) => {
  return setUserSettings({ ...options, action: 'update' }, errorHandler);
};

export const deleteUserSettings = (options = {}, errorHandler = () => {}) => {
  return setUserSettings({ ...options, action: 'delete' }, errorHandler);
};
