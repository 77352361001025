import React, { useContext, useEffect, useState } from 'react';
import { Container, Button, ListGroup, Form } from 'react-bootstrap';
import cn from 'classnames';
import { VAPID_KEY, messaging } from '../../firebaseInit';
import { getToken, onMessage } from '@firebase/messaging';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import ConsumerInfoContext from '../../contexts/ConsumerInfoContext';
import addUserDevice from '../../api/utils/user/device/addUserDevice';
import listUserDevices from '../../api/utils/user/device/listUserDevices';
import sendPushToUserDevice from '../../api/utils/user/device/sendPushToUserDevice';
import UserInfoContext from '../../contexts/UserInfoContext';

const DebugPage = () => {
    const consumerInfo = useContext(ConsumerInfoContext);
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [devices, setDevices] = useState([]);

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        console.log('consumerInfo == ', consumerInfo);
        if (consumerInfo.id) {
            fetchUserDevices(1);
        }
    }, [consumerInfo]);

    const onMessageListener = () =>
        new Promise((resolve) => {
            onMessage(messaging, (payload) => {
                console.log('payload', payload);
                resolve(payload);
                NotificationManager.success(
                    payload?.data?.message,
                    payload?.data?.title,
                    4000
                );
            });
        });

    const fetchUserDevices = async (page = 1) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setDevices(data.devices);
            }
        };

        const errorHandler = (error) => {
            console.error(error.description);
        };

        try {
            const res = await listUserDevices(
                {
                    user_id: consumerInfo.id,
                    query_merchant: merchantId,
                    query_application: appId,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.error('Error connecting to firebase API: ', error);
        }
    };

    const handleAddCurrentDevice = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Device added', 'Success', 4000);
            }
        };

        const errorHandler = (error) => {
            console.error(error.description);
        };

        console.log('handleAddCurrent method called...');
        try {
            const token = await getToken(messaging, { vapidKey: VAPID_KEY });
            console.log('token == ', token);
            const res = await addUserDevice(
                {
                    token,
                    provided_user_agent: navigator.userAgent,
                    merchant: merchantId,
                    application: appId,
                },
                errorHandler
            );
            resultHandler(res);
            fetchUserDevices(1);
        } catch (error) {
            console.error('Error connecting to firebase API: ', error);
        }
    };

    onMessageListener()
        .then((payload) => {
            console.log('onMessageListener: payload == ', payload);
        })
        .catch((err) => console.log('failed: ', err));

    const handleSendPush = async (id) => {
        NotificationManager.info(`TODO: send push; create endpoint for this`);
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Push notification sent',
                    'Success',
                    4000
                );
            }
        };

        const errorHandler = (error) => {
            console.error(error.description);
        };

        try {
            const res = await sendPushToUserDevice(
                { id, title, message },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.error(error);
        }
    };

    const renderUserDevices = () => {
        return devices.map((item) => (
            <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
                key={`device-item-${item?._id}`}
            >
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {item?.name || 'Nameless device'}
                    </div>
                    <span>{item?.type}</span>
                </div>
                <Button
                    type="button"
                    onClick={(e) => handleSendPush(item?._id)}
                >
                    Send push
                </Button>
            </ListGroup.Item>
        ));
    };

    return (
        <Container fluid>
            <NotificationContainer />
            <Button onClick={handleAddCurrentDevice}>Add current device</Button>
            <Form>
                <Form.Group className="mb-3" controlId="pushForm.title">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Push title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="pushForm.message">
                    <Form.Label>Push message</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </Form.Group>
            </Form>
            <ListGroup as="ol" numbered="true" className={cn('mt-3')}>
                {renderUserDevices()}
            </ListGroup>
        </Container>
    );
};

export default DebugPage;
