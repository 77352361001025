import clsx from 'clsx';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

const InputFieldWithValidation = ({
    className,
    inputClassName = '',
    name,
    label,
    type,
    defaultValue,
    required,
    additional,
    error,
    disabled,
    hidden,
    min,
    step,
    max,
    placeholder,
    k,
    onLostFocus = () => {},
    value,
    onChange = () => {},
}) => {
    const { register } = useFormContext();
    const onChangeField = (data) => {
        onChange(name, data.target.value);
    };
    return (
        <div key={k} className={cn(className, { 'd-none': hidden })}>
            {label && <label htmlFor={name}>{label}</label>}
            <input
                ref={register}
                type={type}
                className={cn(
                    'form-control',
                    { 'is-invalid': error },
                    inputClassName
                )}
                id={name}
                name={name}
                defaultValue={defaultValue}
                required={required}
                disabled={disabled}
                min={min}
                step={step}
                max={max}
                value={value}
                placeholder={placeholder}
                onBlur={onLostFocus}
                onChange={onChangeField}
            />
            {additional && !error ? (
                <small className="text-muted">{additional}</small>
            ) : null}
            {error ? <div className="invalid-feedback">{error}</div> : null}
        </div>
    );
};

export default InputFieldWithValidation;
