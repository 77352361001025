import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function DeleteConfirmModel({
    modal = false,
    onModalClose = () => {},
    handleCloseConfirmDelete = () => {},
    title = 'Warning',
    body = 'Are you sure you want to remove this item?',
    closeLabel = 'Close',
    confirmLabel = 'Remove',
}) {
    return (
        <div>
            {modal && (
                <Modal show={true} onHide={onModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{body}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onModalClose}>
                            {closeLabel}
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => handleCloseConfirmDelete()}
                        >
                            {confirmLabel}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}
