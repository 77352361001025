import React, { useContext, useEffect, useState } from 'react';
import * as xlsx from 'xlsx';
import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
import useSearchString from '../../utils/useSearchString';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import BackButton from '../UI/BackButton';
import _ from 'lodash';
import cn from 'classnames';
import { getFile } from '../../api/utils/files/getFile';
import Wait from '../UI/Wait';
import Icon from '../UI/Icon';
import {
    Form,
    Image,
    Button,
    Container,
    Col,
    Row,
    Stack,
} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { MODELS_FOR_IMPORT } from './constants';
import getModelSchema from '../../api/utils/dataImport/getModelSchema';
import Select from 'react-select';
import DataMapperForm from './components/DataMapperForm';
import UserInfoContext from '../../contexts/UserInfoContext';

xlsx.set_cptable(cpexcel);

const ImporterPage = () => {
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [file, setFile] = useState(null); // sheet parsed into array of objects
    const [headers, setHeaders] = useState(null); // data column names
    const [modelName, setModelName] = useState(null);
    const [modelSchema, setModelSchema] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingSchema, setLoadingSchema] = useState(false);

    // Defines relations between model fields and data columns
    // key - model field name, value - column name
    const [dataMapper, setDataMapper] = useState({});

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                setFile(json);

                const workbookHeaders = xlsx.read(data, {
                    sheetRows: 1,
                });
                const columnsArray = xlsx.utils.sheet_to_json(
                    workbookHeaders.Sheets[sheetName],
                    { header: 1 }
                )[0];
                setHeaders(columnsArray);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    };

    useEffect(() => {
        if (modelName) fetchModelSchema(modelName.value);
    }, [modelName]);

    useEffect(() => {
        console.log('dataMapper == ', dataMapper);
    }, [dataMapper]);

    const fetchModelSchema = async (name) => {
        const resultHandler = (data) => {
            setLoadingSchema(false);
            if (data && data.status === 'accept') {
                setModelSchema(data.model_schema);
            }
        };

        const errorHandler = (error) => {
            setLoadingSchema(false);
            console.log(error);
            NotificationManager.error('Cannot load model schema', 'Error');
            setModelSchema(null);
        };

        try {
            setLoadingSchema(true);
            const res = await getModelSchema(
                { model_name: name },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoadingSchema(false);
            console.log(error);
            setModelSchema(null);
        }
    };

    const handleChangeModelName = (val) => {
        if (!val) {
            setModelName('');
            setModelSchema(null);
        } else {
            setModelName(val);
        }
        setDataMapper({});
    };

    const handleSubmit = () => {
        console.log('Data import submitted...');
        console.log('dataMapper == ', dataMapper);
        console.log('model_name == ', modelName.value);
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <div
                className={cn(
                    'd-flex',
                    'justify-content-between',
                    'align-items-center'
                )}
            >
                <h2>Data importer</h2>
            </div>

            <div className="row">
                {loading ? (
                    <Wait />
                ) : (
                    <Container>
                        <Row className={cn('mb-3', '')}>
                            <Col sm={12} md={6}>
                                <Select
                                    isLoading={loadingSchema}
                                    id="model_name_select"
                                    value={modelName}
                                    onChange={handleChangeModelName}
                                    isClearable={true}
                                    options={MODELS_FOR_IMPORT}
                                    className={cn('basic-single', 'h-100')}
                                    classNamePrefix="select"
                                    placeholder={'— Select model name —'}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 2,
                                        }),
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: '100% !important',
                                        }),
                                    }}
                                />
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Control
                                    name=""
                                    type="file"
                                    id="fileInputId"
                                    hidden
                                    onChange={readUploadFile}
                                    accept=".xls,.xlsx,.xlsb,.xlsm,.ods,.csv,.dbf,.dif,.sylk,.office"
                                />
                                <label
                                    className={cn(
                                        'btn btn-outline-success',
                                        'w-100',
                                        'mb-0'
                                    )}
                                    type="button"
                                    variant="success"
                                    htmlFor="fileInputId"
                                    data-tip
                                    data-for={`importFileTip`}
                                >
                                    <Icon
                                        iconName="bi-upload"
                                        color="inherit"
                                        colorOnHover="inherit"
                                    />
                                    <ReactTooltip
                                        id={`importFileTip`}
                                        place="top"
                                        effect="solid"
                                    >
                                        Sheet must have column names and contain
                                        all the rquired fields for model
                                    </ReactTooltip>
                                    Select file to extract data
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DataMapperForm
                                    columnNames={headers}
                                    modelSchema={modelSchema}
                                    dataMapper={dataMapper}
                                    setDataMapper={setDataMapper}
                                    handleSubmit={handleSubmit}
                                />
                            </Col>
                        </Row>

                        {/* Debug output */}
                        <Row>
                            <Col>
                                <h4>
                                    TODO: Link model fields names and excel
                                    table columns; then extract data and send to
                                    server
                                </h4>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {modelSchema ? (
                                    <pre>
                                        {JSON.stringify(modelSchema, null, 4)}
                                    </pre>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {headers ? (
                                    <pre>
                                        {JSON.stringify(headers, null, 4)}
                                    </pre>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {file ? (
                                    <pre>{JSON.stringify(file, null, 4)}</pre>
                                ) : (
                                    <span>Select the file</span>
                                )}
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        </div>
    );
};

export default ImporterPage;
