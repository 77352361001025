import React, { useEffect } from 'react';
import SpecialActionItem from './SpecialActionItem';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Icon from '../../UI/Icon';
import _ from 'lodash';

const SpecialActionsInput = ({
  triggers = [],
  scripts = [],
  specialActions = [],
  setAppSpecialActions = () => {},
  handleEditSpecialAction = () => {},
}) => {
  const getLabel = (action = '') => {
    const label = scripts.find((item) => item?.name === action)?.label;
    return label ? label : action;
  };

  const handleRemoveSpecialAction = (index) => {
    setAppSpecialActions((prev) => {
      let newSpecActions = _.cloneDeep(prev);
      newSpecActions?.splice(index, 1);
      return newSpecActions;
    });
  };

  const renderSpecActions = () => {
    return specialActions.map((item, idx) => (
      <ListGroup.Item
        action
        key={`spec-action-${item?.action}-${item?.trigger}-${idx}`}
        onClick={(e) => {
          e.preventDefault();
        }}
        className="d-flex justify-content-start align-items-center"
      >
        <div>{getLabel(item?.action)}</div>
        <div className="ml-3">
          (
          {item?.triggers?.map((t, i) => (
            <span key={`${t}-${i}-trigger`}>{t}</span>
          ))}
          )
        </div>
        <ButtonGroup aria-label="spec actions actions" className="ml-auto">
          <Button
            variant="light"
            type="button"
            className="m-0 py-0"
            onClick={() => handleEditSpecialAction(idx)}
          >
            <Icon iconName="btn bi-pencil" />
          </Button>
          <Button
            variant="light"
            type="button"
            className="m-0 py-0"
            onClick={() => handleRemoveSpecialAction(idx)}
          >
            <Icon iconName="btn bi-trash" color="var(--danger)" />
          </Button>
        </ButtonGroup>
      </ListGroup.Item>
    ));
  };

  return (
    <ListGroup>
      {specialActions?.length ? (
        renderSpecActions()
      ) : (
        <ListGroup.Item className="border-0">
          No special actions yet...
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default SpecialActionsInput;
