const prepareInfo = (id, formData) => {
  const {
    name,
    description,
    order_types,
    order_sum_limit = 0,
    usage_limit_period = 0,
    usage_limit_amount = 0,
    sum_by_period_limit_period = 0,
    sum_by_period_limit_sum = 0,
    action_notify_aml = false,
    action_abort = false,
    action_change_risk_rating = 0,
    active,
  } = formData;
  const velocityRule = {
    name,
    description,
    order_sum_limit,
    order_types,
    usage_limit: {
      period: usage_limit_period,
      amount: usage_limit_amount,
    },
    sum_by_period_limit: {
      period: sum_by_period_limit_period,
      sum: sum_by_period_limit_sum,
    },
    aml_action: {
      notify_aml: action_notify_aml,
      abort: action_abort,
      change_risk_rating: action_change_risk_rating,
    },
    active,
  };
  if (id) {
    return {
      ...velocityRule,
      id,
    };
  } else {
    return velocityRule;
  }
};

export default prepareInfo;
