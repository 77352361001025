import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Icon from '../UI/Icon';
import Wait from './../UI/Wait';
import cn from 'classnames';

const VelocityRulesList = ({
  velocityRules,
  loading,
  onRowClicked = () => {},
  onDeleteClick = () => {},
  onActivateClick = () => {},
  totalRows,
  rowsCountPerPage,
  handleRowsPerPage = () => {},
  perPageChoices = [25],
  handlePageChange = () => {},
}) => {
  const columns = useMemo(
    () => [
      {
        name: 'ID',
        grow: 2,
        cell: ({ _id }) => _id,
      },
      { name: 'Name', grow: 3, cell: ({ name }) => name },
      { name: 'Description', grow: 3, cell: ({ description }) => description },
      {
        name: 'Actions',
        grow: 1,
        right: true,
        cell: ({ _id, name, active }) => (
          <div className="d-flex justify-content-start align-items-center">
            <div className={cn('custom-control custom-switch')}>
              <input
                className="custom-control-input"
                type="checkbox"
                name={`active-${_id}`}
                id={`active-${_id}`}
                defaultChecked={active}
                onChange={(e) => onActivateClick(_id, !active)}
              />
              <label
                className="custom-control-label"
                htmlFor={`active-${_id}`}
              ></label>
            </div>
            <Link to={`/velocityRule/edit?id=${_id}`}>
              <Icon iconName="bi-pen" />
            </Link>
            <div
              onClick={() => {
                onDeleteClick(_id, name);
              }}
              data-tip
              data-for="deleteTip"
            >
              <Icon iconName="btn bi-trash" />
              <ReactTooltip id="deleteTip" place="top" effect="solid">
                Delete
              </ReactTooltip>
            </div>
          </div>
        ),
      },
    ],
    [velocityRules, loading, onActivateClick]
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <DataTable
            dense
            striped
            noHeader
            progressPending={loading}
            progressComponent={
              <div className="p-5">
                <Wait />
              </div>
            }
            highlightOnHover
            data={velocityRules}
            columns={columns}
            onRowClicked={onRowClicked}
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            onChangeRowsPerPage={handleRowsPerPage}
            paginationServer
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
          />
        </div>
      </div>
    </div>
  );
};

export default VelocityRulesList;
