import QueryString from 'qs';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Icon from '../UI/Icon';
import Wait from '../UI/Wait';
import ReactTooltip from 'react-tooltip';
import { Form } from 'react-bootstrap';

const AssetList = ({
    data,
    ready,
    selectItem,
    id,
    className,
    emptyText,
    onDelete,
    totalRows,
    rowsCountPerPage,
    isAdmin = false,
    handleRowsPerPage = () => {},
    onDeleteClick = () => {},
    perPageChoices = [25],
    handlePageChange = () => {},
    onSetDefault = () => {},
}) => {
    const history = useHistory();
    const location = useLocation();
    const columns = useMemo(
        () => [
            {
                name: 'ID',
                grow: 1,
                cell: ({ _id }, index) => (
                    <span className="text-truncate">{_id}</span>
                ),
            },
            {
                name: 'Name',
                grow: 2,
                cell: ({ name }) => (
                    <span className="text-truncate">{name}</span>
                ),
            },
            {
                name: 'Type',
                sortable: true,
                grow: 1,
                cell: ({ type }) => type,
            },
            {
                name: 'Visible',
                grow: 0,
                cell: ({ visible }) => (visible ? 'Yes' : 'No'),
            },
            {
                name: 'Default',
                grow: 0,
                omit: !isAdmin,
                cell: ({ _id, is_default }) => (
                    <Form.Switch
                        id={`is-default-switch-${_id}`}
                        defaultChecked={is_default}
                        onChange={() => {
                            onSetDefault(_id, !is_default);
                        }}
                        className="m-0"
                        name="is_default"
                        label=""
                    />
                ),
            },
            {
                name: 'Actions',
                grow: 1,
                right: true,
                cell: ({ _id, merchant, name }) => (
                    <div>
                        <Link
                            to={`/assets/edit?asset=${_id}`}
                            data-tip
                            data-for="editTip"
                        >
                            <Icon iconName="bi-pencil" />
                            <ReactTooltip
                                id="editTip"
                                place="top"
                                effect="solid"
                            >
                                Edit Asset
                            </ReactTooltip>
                        </Link>
                        <span
                            onClick={() => selectItem(_id)}
                            data-tip
                            data-for="mapTip"
                        >
                            <Icon iconName="bi-map" />
                            <ReactTooltip
                                id="mapTip"
                                place="top"
                                effect="solid"
                            >
                                Show map
                            </ReactTooltip>
                        </span>
                        <Link
                            to={`/assets/viewraw?asset=${_id}&merchant=${merchant}`}
                            data-tip
                            data-for="viewTip"
                        >
                            <Icon iconName="bi-eye" />
                            <ReactTooltip
                                id="viewTip"
                                place="top"
                                effect="solid"
                            >
                                View raw data
                            </ReactTooltip>
                        </Link>
                        <Link
                            to={`/asset/events?asset=${_id}&asset_name=${name}`}
                            data-tip
                            data-for="eventsTip"
                        >
                            <Icon iconName="btn bi-calendar-event" />
                            <ReactTooltip
                                id="eventsTip"
                                place="top"
                                effect="solid"
                            >
                                Asset Events
                            </ReactTooltip>
                        </Link>
                        <span
                            onClick={() => onDelete(_id)}
                            data-tip
                            data-for="deleteTip"
                        >
                            <Icon iconName="bi-trash" />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete asset
                            </ReactTooltip>
                        </span>
                    </div>
                ),
            },
        ],
        [selectItem, data, ready, isAdmin]
    );
    return (
        <DataTable
            dense
            striped
            highlightOnHover
            data={data}
            columns={columns}
            pagination={true}
            progressPending={!ready}
            noDataComponent={emptyText}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            onChangeRowsPerPage={handleRowsPerPage}
            paginationServer
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
            onRowClicked={({ id }) => {
                const { pathname, search } = location;
                const qsObject = {
                    ...QueryString.parse(search, { ignoreQueryPrefix: true }),
                    parent: id,
                };
                history.push(`${pathname}?${QueryString.stringify(qsObject)}`);
            }}
        />
    );
};

export default AssetList;
