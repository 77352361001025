import React, { useContext, useEffect, useState, useMemo } from 'react';
import useSearchString from '../../utils/useSearchString';
import UserInfoContext from '../../contexts/UserInfoContext';
import getConsumerFull from '../../api/utils/consumer/getConsumerFull';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import BackButton from '../UI/BackButton';
import { fileList } from '../../api/utils/files/fileList';
import FilesTable from './components/FilesTable';
import _ from 'lodash';
import cn from 'classnames';
import { Button, Form } from 'react-bootstrap';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const UserDocuments = () => {
    const { id } = useSearchString();

    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [user, setUser] = useState(null);

    const [files, setFiles] = useState([]);

    const [loading, setLoading] = useState(false);

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState();

    const [regFilesIds, setRegFilesIds] = useState([]);
    const [showRegFields, setShowRegFields] = useState(false);

    const conditionalRowStyles = useMemo(
        () => [
            {
                when: (row) => regFilesIds.includes(row._id),
                style: {
                    backgroundColor: 'AntiqueWhite',
                },
            },
        ],
        [regFilesIds]
    );

    useEffect(() => {
        if (id) {
            fetchUser();
        }
    }, [id]);

    useEffect(() => {
        if (user?._id && !_.isEmpty(user.customfields)) {
            const names = getRegFieldsNames(user);
            const keys = Object.keys(user.customfields).filter((item) =>
                names.includes(item)
            );
            const ids = [];
            keys.forEach((k) => ids.push(user.customfields[k]));
            setRegFilesIds(ids);
        }
    }, [user, merchantId]);

    useEffect(() => {
        if (user && appId) {
            fetchFiles();
        }
    }, [user, appId, perPage, showRegFields]);

    const getRegFieldsNames = (usr) => {
        if (!usr || !merchantId || !usr?.customfields) return [];

        const allUsedReq = usr.customfields?.used_requirements;

        const reqUsed = allUsedReq[merchantId];

        const fieldsNames = reqUsed
            .filter((item) =>
                ['picture', 'document'].includes(item?.type || 'unknown')
            )
            .map((item) => item.id);

        return fieldsNames;
    };

    const fetchUser = async () => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setUser(data.user);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            console.log('error: ', error);
            setUser(null);
            setFiles([]);
            NotificationManager.error('Error fetching user', 'Error');
        };

        try {
            setLoading(true);
            const res = await getConsumerFull({ id }, errorHandler);
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            NotificationManager.error('Error fetching user', 'Error');
            console.log('Error fetching a consumer: ', error);
            setUser(null);
            setFiles([]);
        }
    };

    const fetchFiles = async (page = 1) => {
        if (!user?.email) return;

        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setFiles(data.files);
                setTotalRows(data.total);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            console.log('error: ', error);
            NotificationManager.error('Error fetching files', 'Error');
            setFiles([]);
            setTotalRows(0);
        };

        try {
            setLoading(true);
            const res = await fileList(
                {
                    query_owner: user.email,
                    query_application: appId,
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                    query_ids: showRegFields ? regFilesIds : undefined,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            NotificationManager.error('Error fetching files', 'Error');
            console.log('Error fetching files: ', error);
            setFiles([]);
            setTotalRows(0);
        }
    };

    const handlePageChange = (page) => {
        fetchFiles(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <div
                className={cn(
                    'd-flex',
                    'justify-content-between',
                    'align-items-center'
                )}
            >
                <h3>
                    <BackButton to="/users" text="Back to users list" />
                    User files
                </h3>
                <span>Serial number: {user?.serial_number || 'No number'}</span>
                <Form.Check
                    type="switch"
                    id="showRegFields"
                    name="showRegFields"
                    label="Show registration files"
                    checked={showRegFields}
                    onChange={(e) => setShowRegFields(e.target.checked)}
                />
            </div>
            <small className="d-flex justify-content-between ml-3 text-muted text-small">
                *user registration files highlighted
            </small>

            <div className="row">
                <div className="col">
                    <FilesTable
                        user={user}
                        data={files}
                        loading={loading}
                        // onRowClicked={onRowClick}
                        // onDeleteClick={onDeleteClick}
                        rowsCountPerPage={perPage}
                        handleRowsPerPage={handleRowsPerPage}
                        perPageChoices={PER_PAGE_CHOICES}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        conditionalRowStyles={
                            showRegFields ? {} : conditionalRowStyles
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default UserDocuments;
