import React from 'react';
import { ListGroup } from 'react-bootstrap';
import ActionButtons from './ActionButtons';
import _ from 'lodash';
import cn from 'classnames';

const RolesSummary = ({
    selectedApps = [],
    selectedMerchants = [],
    selectedRoles = [],
    appList = [],
    merchantList = [],
    rolesList = [],
    lastStep = () => {},
    completeCallback = () => {},
    disableFinal = false,
    ...rest
}) => {
    const handleLastStep = () => {
        lastStep();
        completeCallback();
    };

    const renderRoles = (roles = []) => {
        return (
            <>
                <span className={cn('text-muted')}>Roles</span>
                <ListGroup>
                    {roles.map((r) => {
                        return (
                            <ListGroup.Item key={`summary-role-item-${r._id}`}>
                                <h6>{r.name}</h6>
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            </>
        );
    };

    const renderMerchants = (merchants = []) => {
        return (
            <>
                <span className={cn('text-muted')}>Merchants</span>
                <ListGroup>
                    {merchants.map((merc) => {
                        const rolesForCurrentMerchant = selectedRoles.filter(
                            (item) => item.merchant === merc._id
                        );

                        return (
                            <ListGroup.Item
                                key={`summary-merchant-item-${merc._id}`}
                            >
                                <h5>{merc.name}</h5>
                                {renderRoles(rolesForCurrentMerchant)}
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            </>
        );
    };

    const renderSelectedUserApps = () => {
        const fullSelectedApps = appList.filter((item) =>
            selectedApps.includes(item._id)
        );
        const fullSelectedMerchants = merchantList.filter((item) =>
            selectedMerchants.includes(item._id)
        );

        return (
            <ListGroup className={cn('mb-3')}>
                {fullSelectedApps.map((app) => {
                    const merchantsForCurrentApp = fullSelectedMerchants.filter(
                        (item) => item.application_id === app._id
                    );

                    return (
                        <ListGroup.Item key={`summary-app-item-${app._id}`}>
                            <h4>{app.name}</h4>
                            {renderMerchants(merchantsForCurrentApp)}
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        );
    };

    return (
        <div>
            <h2>Summary detail</h2>
            <span className={cn('text-muted')}>Applications</span>
            {renderSelectedUserApps()}

            <ActionButtons
                {...rest}
                lastStep={handleLastStep}
                disableFinal={disableFinal}
            />
        </div>
    );
};

export default RolesSummary;
