import Honkio from '../../Honkio';

/**
 *
 * @param {*} options
 * @param {*} options.greylist_type email | name | network | coordinates | address
 * @param {*} errorHandler
 * @returns
 */
const greyListSet = async (options, errorHandler) => {
  return Honkio().mainShop.userFetch(
    'admingreylistset',
    {
      ...options,
      version: 3,
    },
    errorHandler
  );
};

export const createGreylist = (options, errorHandler) => {
  return greyListSet({ ...options, action: 'create' }, errorHandler);
};

export const updateGreylist = (options, errorHandler) => {
  return greyListSet({ ...options, action: 'update' }, errorHandler);
};

export const deleteGreyList = ({ id }, errorHandler = {}) => {
  return greyListSet({ id, action: 'delete' }, errorHandler);
};
