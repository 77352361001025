export const MODELS_FOR_IMPORT = [
    {
        value: 'asset',
        label: 'Asset',
    },
    {
        value: 'greylist',
        label: 'Grey list',
    },
    {
        value: 'merchant',
        label: 'Merchant',
    },
];
