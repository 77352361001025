import { useState, useEffect, useCallback } from 'react';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import { getOrderModel } from '../../api/utils/orderModel/getMerchantOrderProcessList';
import useSearchString from '../../utils/useSearchString';
import OrderModelEditForm from './OrderModelEditForm';
import { NotificationManager } from 'react-notifications';
import { setAdminOrderModel } from '../../api/utils/orderModel/setAdminOrderModel';
import BackButton from '../UI/BackButton';

const OrderModelView = (props) => {
    const [orderModel, setOrderModel] = useState(null);
    const { omid } = useSearchString();

    useEffect(() => {
        if (omid) {
            getOrderModel({ id: omid })
                .then(({ order_model: model }) => model)
                .then(setOrderModel);
        }
    }, [omid]);

    const onSave = useCallback((editedModel) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setOrderModel(data.order_model);
                NotificationManager.success(
                    'Order model saved',
                    'Success',
                    4000
                );
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        setAdminOrderModel(editedModel, errorHandler).then((data) => {
            return resultHandler(data);
        });
    });

    return (
        <div className="w-100 h-50 flex-column">
            <NotificationContainer />

            <BackButton to="/ordermodel" text="Back to list" />

            {orderModel && (
                <OrderModelEditForm onSave={onSave} orderModel={orderModel} />
            )}
        </div>
    );
};

export default OrderModelView;
