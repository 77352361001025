import { useEffect, useState } from 'react';
import getTransactionsWithLocations from '../../api/utils/transactions/getTransactionsWithLocations';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';

import mapDataToGeoJSON from './helpers/mapDataToGeoJSON';
import DynamicMap from './DynamicMap';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';

const PER_PAGE_CHOICES = [10000];

const AllTransactionsMap = () => {
  const [transactionsList, setTransactionsList] = useState([]);
  const [markersData, setMarkersData] = useState([]);
  const [progress, done] = useLoader([transactionsList, markersData]);
  const [loading, setLoading] = useState(false);

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  const fetchTransactions = async (page = 1) => {
    setLoading(true);
    const resultHandler = (data) => {
      setTransactionsList(data?.transactions);
      setTotalRows(data?.total);
      setLoading(false);
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setTransactionsList([]);
      setTotalRows(0);
      setLoading(false);
    };

    const res = await getTransactionsWithLocations(
      {
        query_skip: (+page - 1) * perPage,
        query_count: perPage,
        debug: true,
      },
      errorHandler
    );

    if (res && res.status === 'accept') return resultHandler(res);
  };

  const handlePageChange = (page) => {
    fetchTransactions(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  useEffect(() => {
    fetchTransactions();
  }, [perPage]);

  useEffect(() => {
    setMarkersData(mapDataToGeoJSON(transactionsList));
  }, [transactionsList]);

  return done ? (
    <div className="h-100 w-100">
      <NotificationContainer />
      <DynamicMap data={markersData} />
    </div>
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default AllTransactionsMap;
