import { useEffect, useState, useContext } from 'react';
import { getConsumerPreviewsList } from '../../api/utils/consumer/getConsumerList';
import InlineSearch from '../UI/InlineSearch';
import { Form, Button, InputGroup } from 'react-bootstrap';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import useSearchString from '../../utils/useSearchString';
import VoucherUsersTable from './VoucherUsersTable';
import VouchersModal from './VouchersModal';
import UserVouchersModal from './UserVouchersModal';
import getAdminRolesList from '../../api/utils/role/getAdminRolesList';
import grantAssets from '../../api/utils/consumer/grantAsset';
import AddVouchersModal from './AddVouchersModal';
import _ from 'lodash';
import { uploadFile } from '../../api/utils/files/uploadFile';

const PER_PAGE_CHOICES = [25, 50, 100, 500, 10000];

const VoucherUsers = () => {
  // Filter users by role
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');

  const [consumerList, setConsumerList] = useState();
  const [keyword, setKeyword] = useState('');
  const {
    app: { id: appid },
  } = useContext(UserInfoContext);
  const userInfo = useContext(UserInfoContext);
  const { merchant = null } = useSearchString();
  const merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;

  const applicationId = userInfo.app ? userInfo.app.id : null;

  const pageTitle = merchant
    ? 'Grant vouchers'
    : userInfo.merchant?.name
    ? `Grant vouchers (${userInfo.merchant.name})`
    : 'Grant vouchers';

  const [loading, setLoading] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  const [showVouchersModal, setShowVouchersModal] = useState(false);
  const [showDeleteVouchersModal, setShowDeleteVouchersModal] = useState(false);

  const [showGroupVouchersModal, setShowGroupVouchersModal] = useState(false);
  const [showGroupDeleteVouchersModal, setShowGroupDeleteVouchersModal] =
    useState(false);

  const [inspectedUser, setInspectedUser] = useState(null);

  const onRowsSelected = ({
    allSelected,
    selectedCount,
    selectedRows = [],
  }) => {
    const userIds = selectedRows.map((item) => item?.id);
    setSelectedUsers(userIds);
  };

  const fetchUsers = async (page = 1) => {
    setLoading(true);
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        setConsumerList(
          data.consumers.map((consumer) => ({
            ...consumer,
            active: consumer.active.toString(),
          }))
        );
        setTotalRows(data?.total);
        setLoading(false);
      } else {
        setConsumerList([]);
        setTotalRows(0);
        setLoading(false);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setConsumerList([]);
      setLoading(false);
    };

    getConsumerPreviewsList(
      {
        query_skip: (+page - 1) * perPage,
        query_count: perPage,
        search: keyword,
        query_role: selectedRole,
        query_merchant: merchantId,
        query_application: appid,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  useEffect(() => {
    fetchUsers(1);
  }, [perPage, keyword, selectedRole, userInfo]);

  useEffect(() => {
    if (merchantId && appid) fetchRoles();
  }, [merchantId, appid]);

  const fetchRoles = async (page = 1) => {
    setLoading(true);
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        setRoles(data.roles);
      } else {
        setRoles([]);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setRoles([]);
    };

    getAdminRolesList(
      {
        query_skip: 0,
        query_count: 10000,
        merchant: merchantId,
        appid,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const uploadImage = async (imgFile) => {
    console.log('uploading image...');
    let allowedExtensions = ['jpg', 'jpeg', 'png'];
    let filename = imgFile.name.split('.')[0];
    let filepath = imgFile.path;
    let filetype = 'image';
    let url = '',
      thumb = '';

    let inArray = allowedExtensions.includes(imgFile.name.split('.')[1]);
    if (inArray) {
      const merchant_id = merchantId;
      const app_id = applicationId;
      if (!merchant_id) {
        NotificationManager.warning(`Please select merchant first`);
        return;
      }
      if (!app_id) {
        NotificationManager.warning(`Please select app first`);
        return;
      }

      let message = {
        file: imgFile,
        'content-type': 'multipart/form-data',
        object: app_id,
        object_type: 'asset',
        access: 'public',
        file_type: 'image',
        extension: imgFile.name.split('.')[1],
        display_name: filename,
        metadata: { document_type: filetype },
      };

      try {
        const r = await uploadFile(message);
        url = r.file.url;
        thumb = r.file.url;
      } catch (error) {
        NotificationManager.warning(
          'Image upload failed',
          `${error.description}`
        );
      }
    }
    return url;
  };

  const handleAddVouchers = async (voucherData, userIds, assetType) => {
    setShowVouchersModal(false);

    if (!selectedUsers?.length && !userIds.length) {
      NotificationManager.info('No users selected');
      return;
    }
    if (!voucherData) {
      NotificationManager.info('Voucher data is not provided');
      return;
    }

    const resultHandler = (data) => {
      NotificationManager.success('Vouchers added');
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    // Upload images to server
    // Get image fields names
    if (!assetType) {
      NotificationManager.info('Asset type is not selected');
      return;
    }
    const imgFieldsNames = assetType?.properties
      ?.filter((item) => item?.property_type === 'image')
      ?.map((item) => item.property_name);

    let voucherSubmitData = _.cloneDeep(voucherData);

    for (let item of imgFieldsNames) {
      if (voucherSubmitData?.properties?.[item]) {
        let imgUrl = '';
        try {
          let imgFileItem = Array.from(voucherSubmitData.properties[item])?.[0];
          imgUrl = await uploadImage(imgFileItem);
          NotificationManager.success(`Image for ${item} uploaded!`);
        } catch (error) {
          console.log('Error uploading file: ', error);
        }
        voucherSubmitData.properties[item] = imgUrl;
      }
    }

    try {
      const res = await grantAssets(
        {
          user_ids: userIds ? userIds : selectedUsers,
          asset_info: voucherSubmitData,
          asset_type: 'voucher',
          merchant: merchantId,
        },
        errorHandler
      );
      if (res && res?.status === 'accept') return resultHandler(res);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleAddVouchersToGroup = async (voucherData, userIds, assetType) => {
    setShowGroupVouchersModal(false);

    if (!selectedRole) {
      NotificationManager.info('No role selected');
      return;
    }
    if (!voucherData) {
      NotificationManager.info('Voucher data is not specified');
      return;
    }

    const resultHandler = (data) => {
      NotificationManager.success('Vouchers added');
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    const imgFieldsNames = assetType?.properties
      ?.filter((item) => item?.property_type === 'image')
      ?.map((item) => item.property_name);

    let voucherSubmitData = _.cloneDeep(voucherData);

    for (let item of imgFieldsNames) {
      if (voucherSubmitData?.properties?.[item]) {
        let imgUrl = '';
        try {
          let imgFileItem = Array.from(voucherSubmitData.properties[item])?.[0];
          imgUrl = await uploadImage(imgFileItem);
          NotificationManager.success(`Image for ${item} uploaded!`);
        } catch (error) {
          console.log('Error uploading file: ', error);
        }
        voucherSubmitData.properties[item] = imgUrl;
      }
    }

    try {
      const res = await grantAssets(
        {
          role_id: selectedRole,
          asset_info: voucherSubmitData,
          asset_type: 'voucher',
          merchant: merchantId,
          asset_type: 'voucher',
        },
        errorHandler
      );
      if (res && res?.status === 'accept') return resultHandler(res);
    } catch (error) {
      errorHandler(error);
    }
  };

  const onRowClick = (row) => {
    setInspectedUser(row);
  };

  return (
    <div className="container-fluid">
      <NotificationContainer />
      <h2>{pageTitle}</h2>
      <div className="row">
        <div className="col-12 col-md-4 d-flex justify-content-start align-items-start">
          <InlineSearch setKeyword={setKeyword} className="flex-nowrap" />
        </div>

        <div className="col-12 col-md-8 d-flex justify-content-end align-items-start">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => setShowVouchersModal(true)}
            >
              <i className="bi-plus-square ml-1 mr-2 " /> Add vouchers to
              selected
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 cold-md-6">
          <InputGroup className="mb-3">
            <Form.Control
              as={'select'}
              aria-label="Role select"
              aria-describedby="Role select to display users"
              name="role"
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              className="custom-select"
            >
              <option value="">&mdash; Select role &mdash;</option>
              {roles?.map((item, index) => (
                <option
                  key={`${index}-${item?._id}-role-select-option`}
                  value={item?._id}
                >
                  {item?.display_name || item?.display_name__en || item?.name}
                </option>
              ))}
            </Form.Control>
            <Button
              variant="outline-primary"
              onClick={() => setShowGroupVouchersModal(true)}
            >
              <i className="bi-plus-square ml-1 mr-2 " /> Add vouchers to group
            </Button>
          </InputGroup>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <VoucherUsersTable
            data={consumerList}
            keyword={keyword}
            loading={loading}
            filterSearch={searchFilterIfConsist}
            filterType={typifyIfEquals}
            rowsCountPerPage={perPage}
            handleRowsPerPage={handleRowsPerPage}
            perPageChoices={PER_PAGE_CHOICES}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
            onRowsSelected={onRowsSelected}
            onRowClick={onRowClick}
          />
        </div>
      </div>
      {/* Add Vouchers */}
      <AddVouchersModal
        key="add-vouchers-modal"
        title="Add vouchers"
        show={showVouchersModal}
        onHide={() => setShowVouchersModal(false)}
        onConfirm={handleAddVouchers}
      />
      {/* Add Vouchers to group */}
      <AddVouchersModal
        key="add-vouchers-modal-group"
        title="Add vouchers to group"
        show={showGroupVouchersModal}
        onHide={() => setShowGroupVouchersModal(false)}
        onConfirm={handleAddVouchersToGroup}
      />
      {/* Inspect user vouchers */}
      <UserVouchersModal
        key="user-vouchers-info-modal"
        user={inspectedUser}
        title={`${inspectedUser?.str_firstname} ${inspectedUser?.str_lastname} vouchers`}
        show={!!inspectedUser}
        onHide={() => setInspectedUser(null)}
      />
    </div>
  );
};

export default VoucherUsers;
