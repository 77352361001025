const TypeSelector = ({
  title = '',
  type,
  setType,
  options = [
    'payments',
    'tokenization',
    'login',
    'registration',
    'lostpassword',
    'order',
    'bonus',
    'mlrequest',
  ],
}) => {
  const onChange = ({ target: { value } }) => {
    setType(value);
  };
  return (
    <div>
    {title.length>0 ? <h5>{title}</h5> : ''}
    <select className="custom-select" onChange={onChange} value={type}>
      <option value="">All</option>
      {options.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
    </div>
  );
};

export default TypeSelector;
