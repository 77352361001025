import React, { useState, useEffect } from 'react';
import { Modal, Button, Col, Image, Table, Form } from 'react-bootstrap';
import cn from 'classnames';

const RegStatusesForm = ({
    statuses = [],
    rolesOptions = [],
    label = '',
    className = '',
    currentStatuses = [],
    namePrefix = '',
    onChange = () => {},
}) => {
    const getCurrentGroup = (name) => {
        const curr = currentStatuses?.[name];
        if (curr) return curr;
        return -1;
    };

    const renderStatuses = (st) => {
        if (st.length) {
            return st.map((item) => (
                <tr key={item.value}>
                    <td colSpan={1} style={{ verticalAlign: 'middle' }}>
                        {item.label}
                    </td>
                    <td colSpan={1}>
                        <Form.Control
                            as="select"
                            name={`${namePrefix}.${item.value}`}
                            value={getCurrentGroup(item.value)}
                            onChange={onChange}
                            className={cn('custom-select', 'crsr', 'mb-3')}
                        >
                            {rolesOptions.map((opt, i) => (
                                <option
                                    key={`logo-option-${i}-${opt?.value}`}
                                    value={opt?.value}
                                >
                                    {opt?.label}
                                </option>
                            ))}
                        </Form.Control>
                    </td>
                </tr>
            ));
        } else {
            return (
                <tr>
                    <td colSpan={2}>Not found</td>
                </tr>
            );
        }
    };

    return (
        <div className={className}>
            {label ? <h4 className="my-3">{label}</h4> : null}
            <Table striped hover size="sm">
                <thead>
                    <tr>
                        <th colSpan={1} className="w-50">
                            Registration status
                        </th>
                        <th colSpan={1} className="w-50">
                            User group
                        </th>
                    </tr>
                </thead>
                <tbody>{renderStatuses(statuses)}</tbody>
            </Table>
        </div>
    );
};

export default RegStatusesForm;
