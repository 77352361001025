const defaultStatus = {
    parent_of: [],
    position: [
        {
            x: 0,
            y: 0,
        },
    ],
};

const makeStatus = ({
    position,
    parent_of,
}) => {
    const result = {
        parent_of,
        position: { left: Math.round(position.x), top: Math.round(position.y) },
    };
    return result;
};

const getStatus = (data) => {
    const result = Object.assign({}, defaultStatus, makeStatus(data));
    return result;
};

export default getStatus;
