import Honkio from '../../Honkio';

const getTransactionsWithLocations = (
  options = {},
  errorHandler = () => {}
) => {
  const { debug, query_skip, query_count } = options;
  return Honkio().mainShop.userFetch(
    'admintransactionlocations',
    {
      debug,
      query_skip,
      query_count,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getTransactionsWithLocations;
