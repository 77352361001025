import Honkio from '../../Honkio';

const getProductList = (options = {}, errorHandler = {}) => {
    const {
        query_merchant,
        query_application,
        query_skip = 0,
        query_count = 25,
        query_ids,
        debug = true,
    } = options;
    return Honkio().mainShop.userFetch(
        'adminproductlist',
        {
            query_count,
            query_skip,
            debug,
            query_merchant,
            query_application,
            query_ids,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
export default getProductList;
