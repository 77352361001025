import React from "react";

const CardWrapperComponent = ({
    header,
    footer,
    card_title,
    card_footer,
    add_class,
    rightIcon,
    rightIconPre,
    children,
}) => {
    const renderHeader = () => {
        if (!header) return null;
        return (
            <div className="card-header border-bottom bg-light d-flex">
                <h6 className="text-muted mb-0 text-uppercase">{card_title}</h6>
                <div className="col">
                    {rightIconPre}
                    {rightIcon}
                </div>
            </div>
        );
    };

    const renderFooter = () => {
        if (!footer) return null;
        return (
            <div className="card-footer border-top bg-light">{card_footer}</div>
        );
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className={`card ${add_class}`}>
                        {renderHeader()}
                        <div className="card-body">{children}</div>
                        {renderFooter()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardWrapperComponent;
