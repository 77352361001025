import { useState, useContext } from 'react';
import ShopInfoForm from './ShopInfoForm';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSearchString from '../../utils/useSearchString';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import { adminCreateShop } from '../../api/utils/shop/setAdminShop';

const ShopCreate = () => {
    const history = useHistory();
    const userInfo = useContext(UserInfoContext);
    const { merchant = null } = useSearchString();
    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;

    const [submitLoading, setSubmitLoading] = useState(false);

    const saveShop = async (shop) => {
        const resultHandler = (data) => {
            setSubmitLoading(false);

            if (data && data.status === 'accept') {
                NotificationManager.success('Saved');
                history.push(
                    `/shop/edit?merchant=${data.shop.merchant}&shop=${data.shop._id}`
                );
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            setSubmitLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        const req_data = { ...shop, merchant: merchantId };

        try {
            setSubmitLoading(true);
            const res = await adminCreateShop(req_data, errorHandler);
            resultHandler(res);
        } catch (error) {
            setSubmitLoading(false);
            console.log('error creating shop: ', error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <ShopInfoForm onSubmit={saveShop} submitLoading={submitLoading} />
        </div>
    );
};

export default ShopCreate;
