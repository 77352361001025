import React, { Component, useContext, useEffect, useState } from 'react';
import WidgetComponent from './WidgetComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';
import 'moment/locale/fi';
import HonkioAPI from '../../../api/Honkio';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { getConsumerList } from '../../../api/utils/consumer/getConsumerList';
import UserInfoContext from '../../../contexts/UserInfoContext';

const NumUsersWidgetComponent = ({
  position,
  height,
  width,
  name,
  onRename,
  onRemoveClick,
}) => {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const userInfo = useContext(UserInfoContext);

  useEffect(() => {
    if (userInfo?.merchant?.id) refreshData();
  }, [userInfo]);

  const refreshData = async () => {
    setLoading(true);

    const resultHandler = (data) => {
      if (data && data?.status === 'accept') {
        setUsers(data?.consumers);
        setTotal(data?.total);
        setLoading(false);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setUsers([]);
      setTotal(0);
      setLoading(false);
    };
    try {
      const res = await getConsumerList(
        {
          merchantId:
            userInfo?.app?.name && userInfo.app.name === 'server'
              ? null
              : userInfo?.merchant?.id,
          query_count: 10,
        },
        errorHandler
      );
      resultHandler(res);
    } catch (error) {
      errorHandler(error);
    }
  };

  const onResize = (a, b, c) => {
    // TODO
  };

  let drawChart = (height, width) => {
    return (
      <div className="d-flex justify-content-center w-100 h-100">
        <span className="justify-content-center align-self-center h5">
          {total}
        </span>
      </div>
    );
  };

  return (
    <WidgetComponent
      position={position}
      height={height}
      width={width}
      icon="fas fa-user-friends fa-2x"
      name={name}
      onRename={onRename}
      type="Number of users"
      summary1={
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          total
        </div>
      }
      onRemoveClick={onRemoveClick}
      chart={
        loading
          ? () => (
              <CircularProgress
                size={35}
                style={{
                  position: 'absolute',
                  top: '65%',
                  left: '50%',
                }}
              />
            )
          : drawChart
      }
    />
  );
};

export default NumUsersWidgetComponent;
