import { useEffect, useState, useMemo } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal, Button, Col, Image } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import DataTable from 'react-data-table-component';
import getProductType from '../../api/utils/product/type/getProductType';

const ProductTypeModal = ({ productTypeId, show, onHide }) => {
    const [productType, setProductType] = useState();
    const [progress, done] = useLoader([productTypeId, productType]);

    useEffect(() => {
        if (productTypeId) {
            getProductType({ id: productTypeId })
                .then(({ type }) => type)
                .then(setProductType);
        }
    }, [productTypeId]);

    const columns = useMemo(() => [
        {
            name: '#',
            width: '50px',
            grow: 0,
            right: true,
            cell: (_, index) => index + 1,
        },
        {
            name: 'label',
            grow: 3,
            cell: (e) => {
                return e.label;
            },
        },
        {
            name: 'name',
            grow: 3,
            cell: (e) => {
                return e.name;
            },
        },
        {
            name: 'type',
            grow: 3,
            cell: (e) => {
                return e.type;
            },
        },
    ]);

    const container = (
        <div className="container">
            <div className="col-12">
                <label className="font-weight-bold mr-2">Name</label>
                <label>{productType?.name}</label>
                <br />
                <label className="font-weight-bold mr-3">Description</label>
                <label>{productType?.description}</label>
                <br />
                <label className="font-weight-bold mr-2">Fields</label>
                <br />
                <DataTable
                    dense
                    striped
                    noHeader
                    noDataComponent="no fields"
                    highlightOnHover
                    data={
                        productType?.fields
                            ? Object.values(productType.fields)
                            : []
                    }
                    columns={columns}
                />
            </div>
        </div>
    );

    const onClickHide = () => {
        onHide();
    };

    return done ? (
        <Modal show={show} onHide={onClickHide}>
            <Modal.Header closeButton>
                <Modal.Title>Product type</Modal.Title>
            </Modal.Header>
            <Modal.Body>{productType ? container : 'not found'}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    ) : (
        <LoadingBar progress={progress} />
    );
};

export default ProductTypeModal;
