import Honkio from '../../../../Honkio';

const getUserRegSettings = (options = {}, errorHandler = {}) => {
    const { id } = options;
    return Honkio().mainShop.userFetch(
        'adminmerchantuserregistrationsettingsget',
        {
            id,
            version: 3,
            action: 'read',
        },
        errorHandler,
        true
    );
};

export default getUserRegSettings;
