import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import prepareToPublish from './helpers/prepareToPublish';
import CustomerTypeEditForm from './CustomerTypeEditForm';
import { createCustomerType } from '../../api/utils/customer/type/setCustomerType';
import BackButton from '../UI/BackButton';

const CustomerTypeCreate = () => {
    const history = useHistory();

    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [customerType, setCustomerType] = useState({
        merchant: merchantId ? merchantId : null,
        name: 'A new CustomerType',
        application: appId,
        accessibility: 'private',
    });

    const onCreate = async (customerType) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Saved');
                history.push(
                    `/customer/type/edit?id=${data.customer_type._id}`
                );
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            const res = await createCustomerType(
                {
                    merchant: merchantId,
                    application: appId,
                    ...prepareToPublish(null, customerType),
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.error(
                'Unexpected error while creating customer type: ',
                error
            );
        }
    };

    const pageTitle = `New customer type`;
    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton to="/customer/types" text="Back to list" />
                {pageTitle}
            </h2>

            <CustomerTypeEditForm
                onSubmit={onCreate}
                defaultValues={customerType}
            />
        </div>
    );
};

export default CustomerTypeCreate;
