import {
  ButtonActionForm,
  InputFieldWithValidation,
} from '../Form/elements/formElements';
import SmartForm from '../Form/SmartForm';
import setMerchantUserInvite from '../../api/utils/consumer/setMerchantUserInvite';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import prepareToEdit from './helpers/prepareToEdit';
import prepareToPublish from './helpers/prepareToPublish';
import TextareaSmart from '../Form/elements/TextareaSmart';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';

const InvitationForRoleForm = (props) => {
  const { invitation } = props;
  const history = useHistory();
  const defaultValues = prepareToEdit(invitation);

  if (!invitation.role_id) return null;

  const onSubmit = (data) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Saved');
        history.goBack();
      } else {
        NotificationManager.error('Error');
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    setMerchantUserInvite(
      { ...prepareToPublish(invitation.role_id, data) },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  return (
    <div className="container">
      <NotificationContainer />
      <div className="row">
        <div className="col-md-8 order-md-1">
          <h4>Invitation for role {invitation.role_name}</h4>

          <SmartForm defaultValues={defaultValues} onSubmit={onSubmit}>
            <div className="col-md-14">
              <InputFieldWithValidation
                className="mb-3"
                name="email"
                label="Email"
                type="text"
                required="true"
              />
              <TextareaSmart
                className="mb-3 ml-1"
                name="message"
                label="Message"
                style={{ minHeight: '16em' }}
              />
            </div>

            <hr className="mb-4" />

            <ButtonActionForm
              label="Send invite"
              name="active"
              value={true}
              type="submit"
            />
          </SmartForm>
        </div>
      </div>
    </div>
  );
};

export default InvitationForRoleForm;
