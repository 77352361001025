const debugOutput = (params) => {
  const { debug, ...rest } = params;
  if (debug) {
    params = { ...rest };
    console.debug(params);
  }
  return params;
};

export default debugOutput;
