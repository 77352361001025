import { useContext, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import getProductTypeList from '../../api/utils/product/type/getProductTypeList';
import useSearchString from '../../utils/useSearchString';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import { merchantDeleteProductType } from '../../api/utils/product/type/setAdminProductTag';
import ProductTypeModal from './ProductTypeModal';
import { Modal, Button } from 'react-bootstrap';
import Wait from '../UI/Wait';
import UserInfoContext from '../../contexts/UserInfoContext';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const ProductTypeList = () => {
    const userInfo = useContext(UserInfoContext);

    const { merchant } = useSearchString();

    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;

    const [types, setTypes] = useState();

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState();

    const location = useLocation();

    const [showConfirmRemoveProductType, setShowConfirmRemoveProductType] =
        useState(false);
    const handleShowConfirmRemoveProductType = () =>
        setShowConfirmRemoveProductType(true);
    const handleCloseConfirmRemoveProductType = () =>
        setShowConfirmRemoveProductType(false);
    const [productTypeToRemove, setProductTypeToRemove] = useState({});

    const [showProductTypePreview, setShowProductTypePreview] = useState(false);
    const handleShowProductTypePreview = () => setShowProductTypePreview(true);
    const handleCloseProductTypePreview = () =>
        setShowProductTypePreview(false);
    const [currentProductType, setCurrentProductType] = useState();

    const [loading, setLoading] = useState(false);

    const columns = useMemo(
        () => [
            { name: 'ID', grow: 2, cell: ({ _id }) => _id },
            { name: 'Name', grow: 2, cell: ({ name }) => name },
            {
                name: 'Label',
                grow: 3,
                cell: ({ fields = {} }) =>
                    Object.values(fields)
                        .map(({ label }) => label)
                        .join(', '),
            },
            {
                name: 'Actions',
                grow: 1,
                right: true,
                cell: ({ _id, name }) => (
                    <div className="d-flex">
                        <div>
                            <Link to={`/products/types/edit?type=${_id}`}>
                                <Icon iconName="bi-pen" />
                            </Link>
                        </div>
                        <div
                            onClick={() => {
                                onDeleteClick(_id, name);
                            }}
                            data-tip
                            data-for="deleteTip"
                        >
                            <Icon iconName="btn bi-trash" />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </div>
                    </div>
                ),
            },
        ],
        []
    );

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (merchantId) {
            fetchProductTypes(1);
        }
    }, [merchantId, perPage]);

    const fetchProductTypes = async (page = 1) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept') {
                    setTypes(data.types);
                    setTotalRows(data?.total);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setTotalRows(0);
                    setTypes([]);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setTypes([]);
            setTotalRows(0);
            setLoading(false);
        };

        getProductTypeList(
            {
                query_merchant: merchantId,
                query_skip: (+page - 1) * perPage,
                query_count: perPage,
            },
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };

    const onDeleteClick = (id, name) => {
        setProductTypeToRemove({ id, name });
        handleShowConfirmRemoveProductType();
    };

    const deleteProductType = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Product type removed',
                    'Success',
                    4000
                );
                handleCloseConfirmRemoveProductType();
                fetchProductTypes();
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        merchantDeleteProductType(productTypeToRemove.id, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .then(handleCloseConfirmRemoveProductType);
    };

    let onRowClicked = (row) => {
        setCurrentProductType(row);
        handleShowProductTypePreview();
    };

    const handlePageChange = (page) => {
        fetchProductTypes(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    return (
        <div>
            <NotificationContainer />
            <h2>Product types</h2>
            <Link
                to={`${location.pathname}/add`}
                className="btn btn-outline-primary mt-1 mb-3"
            >
                <i className="bi-plus-square ml-1 mr-2 " /> Add type
            </Link>
            <DataTable
                dense
                striped
                highlightOnHover
                data={types}
                emptyText={'The list is empty'}
                columns={columns}
                onRowClicked={onRowClicked}
                progressPending={loading}
                pagination={true}
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={PER_PAGE_CHOICES}
                onChangeRowsPerPage={handleRowsPerPage}
                paginationServer
                onChangePage={handlePageChange}
                paginationTotalRows={totalRows}
                progressComponent={
                    <div className="p-5">
                        <Wait />
                    </div>
                }
            />
            {currentProductType && (
                <ProductTypeModal
                    productTypeId={currentProductType._id}
                    show={showProductTypePreview}
                    onHide={handleCloseProductTypePreview}
                ></ProductTypeModal>
            )}
            <Modal
                show={showConfirmRemoveProductType}
                onHide={handleCloseConfirmRemoveProductType}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Do you want to remove product type{' '}
                        <b>
                            {productTypeToRemove
                                ? productTypeToRemove.name
                                : ''}
                        </b>
                        ?{' '}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseConfirmRemoveProductType}
                    >
                        Close
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => deleteProductType()}
                    >
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ProductTypeList;
