import { useCallback } from 'react';
import InputFieldWithValidation from './elements/InputFieldWithValidation';
import SmartForm from './SmartForm';

const ExpressForm = ({ data = {}, forbiddenFields = [], onSave }) => {
  //const onSubmit = useCallback((data) => console.log(data), []);
  return (
    <SmartForm defaultValues={data} onSubmit={onSave}>
      {Object.entries(data)
        .filter(([key]) => !forbiddenFields.includes(key))
        .map(([key, value]) => {
          if (value != null && typeof value === 'object') {
            return (
              <fieldset className="container p-3 m-2 border rounded">
                {key}
                {Object.entries(value).map(([nestedKey]) => (
                  <InputFieldWithValidation
                    label={nestedKey}
                    type="text"
                    name={`${key}.${nestedKey}`}
                  />
                ))}
              </fieldset>
            );
          }
          return (
            <InputFieldWithValidation
              label={key}
              type="text"
              name={key}
              className="d-flex align-items-baseline my-2"
            />
          );
        })}
      {onSave && <button type="submit" className="btn btn-primary" >
        Save
      </button>}
    </SmartForm>
  );
};

export default ExpressForm;
