import { useEffect, useMemo, useState } from 'react';
import LoadingBar from '../UI/LoadingBar';
import { Modal, Button } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import getAssetType from './../../api/utils/asset/type/getAssetType';
import DataTable from 'react-data-table-component';
import { NotificationManager } from 'react-notifications';

const AssetTypeModal = ({ assetTypeId, show, onHide }) => {
  const [assetType, setAssetType] = useState();
  const [properties, setProperties] = useState([]);
  const [progress, done] = useLoader([assetType]);

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (assetTypeId) {
      fetchAssetType();
    }
  }, [assetTypeId]);

  const fetchAssetType = async () => {
    const resultHandler = (data) => {
      if (isMounted) {
        if (data && data.status === 'accept') {
          setAssetType(data.asset_type);
        } else {
          setAssetType({});
        }
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setAssetType({});
    };

    getAssetType({ id: assetTypeId }, errorHandler).then((data) => {
      return resultHandler(data);
    });
  };

  useEffect(() => {
    if (assetType?.properties) {
      setProperties(assetType.properties);
    } else {
      setProperties([]);
    }
  }, [assetType]);

  const columns = useMemo(() => [
    {
      name: 'Name',
      cell: ({ property_name }) => property_name,
      sortable: true,
    },
    {
      name: 'Description',
      cell: ({ property_description }) => property_description,
      sortable: true,
    },
    {
      name: 'Required',
      cell: ({ property_required }) => property_required,
      sortable: true,
    },
    {
      name: 'Type',
      cell: ({ property_type }) => property_type,
      sortable: true,
    },
  ]);

  const container = (
    <div className="container">
      <div className="col-13">
        <label className="font-weight-bold mr-2">Name</label>
        <label>{assetType?.name}</label>
        <br />
        <label className="font-weight-bold mr-3">Description</label>
        <label>{assetType?.description || '-'}</label>
        <br />
        <label className="font-weight-bold mr-3">Properties</label>
        <DataTable
          dense
          striped
          noHeader
          noDataComponent="no permissions"
          highlightOnHover
          data={properties}
          columns={columns}
        />
      </div>
    </div>
  );

  const onClickHide = () => {
    onHide();
  };

  return done ? (
    <Modal show={show} onHide={onClickHide}>
      <Modal.Header closeButton>
        <Modal.Title>Asset type</Modal.Title>
      </Modal.Header>
      <Modal.Body>{assetType ? container : 'not found'}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClickHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default AssetTypeModal;
