import Honkio from '../../../Honkio';

const getAssetTypeList = (options = {}, errorHandler = () => {}) => {
    const { query_application, query_merchant } = options;
    return Honkio().mainShop.userFetch(
        'adminassettypelist',
        {
            query_merchant,
            query_application,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
export default getAssetTypeList;
