import React from 'react';
import cn from 'classnames';
import './style.css';
import Icon from '../../UI/Icon';

const MessageItem = ({
  isOwner,
  _id,
  text,
  author,
  created_at,
  className,
  onEdit,
  onDelete,
}) => {
  return (
    <div
      className={cn(className, 'd-flex flex-column card mt-3', 'message_item')}
    >
      <div className="card-body">
        <span>{text}</span>
      </div>
      <div className="card-footer">
        <span className="mr-3">{author}</span>
        <span className="text-small text-muted">{created_at}</span>
        {isOwner ? (
          <div className="d-inline-flex">
            <div onClick={(e) => onEdit(e, _id, text)}>
              <Icon iconName="ml-3 bi bi-pencil" size="1" />
            </div>
            <div onClick={(e) => onDelete(e, _id)}>
              <Icon iconName="bi bi-trash" size="1" />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MessageItem;
