import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

const SelectRole = ({
    roles = [],
    selected = [],
    onChange = () => {},
    required = false,
    loading = false,
}) => {
    return (
        <Form.Group className="mb-3" controlId="apps.roles">
            <Form.Label>Select roles</Form.Label>
            <Select
                isLoading={loading}
                id="action-type-select"
                value={selected}
                onChange={onChange}
                isMulti
                options={roles}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={'— Select roles —'}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                required={required}
            />
            <Form.Text className="text-muted">Select user roles</Form.Text>
        </Form.Group>
    );
};

export default SelectRole;
