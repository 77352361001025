import { useContext, useEffect, useState } from 'react';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import getProductList from '../../api/utils/product/getProductList';
import ProductList from './ProductList';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import UserInfoContext from '../../contexts/UserInfoContext';
import { Modal, Button } from 'react-bootstrap';
import { merchantDeleteProduct } from '../../api/utils/product/setMerchantProduct';
import ProductModal from './ProductModal';
import purchaseProduct from '../../api/utils/product/purchaseProduct';
import getProduct from '../../api/utils/product/getProduct';
import useSearchString from '../../utils/useSearchString';
import setDefaultRecord from '../../api/utils/app/default/setDefaultRecord';
import _ from 'lodash';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const Products = () => {
    const { app, merchant } = useContext(UserInfoContext);
    const userInfo = useContext(UserInfoContext);

    const searchParams = useSearchString();

    const merchantId = searchParams.merchant
        ? searchParams.merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;

    const appId = app?.id;

    const pageTitle = `Products (${appId ? app.name : '-'} / ${
        merchantId ? merchant.name : '-'
    })`;

    const [products, setProducts] = useState({
        productList: [],
        productTypes: {},
    });

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState();

    const [loading, setLoading] = useState(false);

    const [showConfirmRemoveProduct, setShowConfirmRemoveProduct] =
        useState(false);
    const handleShowConfirmRemoveProduct = () =>
        setShowConfirmRemoveProduct(true);
    const handleCloseConfirmRemoveProduct = () =>
        setShowConfirmRemoveProduct(false);
    const [productToRemove, setProductToRemove] = useState({});

    const [showProductPreview, setShowProductPreview] = useState(false);
    const handleShowProductPreview = () => setShowProductPreview(true);
    const handleCloseProductPreview = () => setShowProductPreview(false);
    const [currentProduct, setCurrentProduct] = useState();

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchProducts();
    }, [merchantId, appId, perPage]);

    const fetchProducts = async (page = 1) => {
        setLoading(true);
        setCurrentPage(page);

        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                const { product: productList, producttype: productTypes } =
                    data;
                setProducts({ productList, productTypes });
                setTotalRows(data?.total);
                setLoading(false);
            } else {
                setLoading(false);
                setProducts({
                    productList: [],
                    productTypes: {},
                });
                setTotalRows(0);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setProducts({
                productList: [],
                productTypes: {},
            });
            setTotalRows(0);
            setLoading(false);
        };

        try {
            const res = await getProductList(
                {
                    query_application: appId,
                    query_merchant: merchantId,
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('Unexpected error while fetching products: ', error);
        }
    };

    const onDeleteClick = (id, name) => {
        setProductToRemove({ id, name });
        handleShowConfirmRemoveProduct();
    };

    const onBuyClick = async (product) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Product purchased',
                    'Success',
                    4000
                );
                console.log('ORDER');
                console.log(data);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            const res = await purchaseProduct(
                { product_id: product },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('Unexpected error while purchasing product: ', error);
        }
    };

    const deleteProduct = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Product removed', 'Success', 4000);
                handleCloseConfirmRemoveProduct();
                fetchProducts();
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        const productResponse = await getProduct({ id: productToRemove.id });

        const productObject = productResponse.product;

        productObject.product = { _id: productToRemove.id };
        productObject.id = productToRemove.id;

        try {
            const res = await merchantDeleteProduct(
                productObject,
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('Unexpected error while deleting product: ', error);
        }
    };

    const onRowClick = (row) => {
        setCurrentProduct(row);
        handleShowProductPreview();
    };

    const handlePageChange = (page) => {
        fetchProducts(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    const onSetDefault = async (id, is_default) => {
        const resultHandler = (res) => {
            if (res && res.status === 'accept') {
                let newProducts = _.cloneDeep(products);
                let newProductsList = _.cloneDeep(newProducts.productList);
                const idx = newProductsList.findIndex((item) => item.id === id);
                newProductsList[idx].is_default = res.record.is_default;
                newProducts.productList = newProductsList;

                setProducts(newProducts);
                setLoading(false); // this helps re-render table

                // Alternative in case table starts glitching again
                // fetchProducts(currentPage);
            } else {
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
        };

        try {
            setLoading(true);
            const res = await setDefaultRecord(
                { id, is_default, model_name: 'product', merchant: merchantId },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            console.log('Error setting record as default: ', error);
        }
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <Link
                to={`/product/create?appid=${appId}`}
                className="btn btn-outline-primary mt-1 mb-3"
            >
                <i className="bi-plus-square ml-1 mr-2 " /> Create product
            </Link>

            <h2>{pageTitle}</h2>

            <ProductList
                products={products}
                loading={loading}
                onRowClicked={onRowClick}
                onDeleteClick={onDeleteClick}
                onBuyClick={onBuyClick}
                rowsCountPerPage={perPage}
                handleRowsPerPage={handleRowsPerPage}
                perPageChoices={PER_PAGE_CHOICES}
                totalRows={totalRows}
                handlePageChange={handlePageChange}
                onSetDefault={onSetDefault}
                isAdmin={!!userInfo?.merchant?.is_admin}
            />

            {currentProduct && (
                <ProductModal
                    productId={currentProduct.id}
                    show={showProductPreview}
                    onHide={handleCloseProductPreview}
                ></ProductModal>
            )}
            <Modal
                show={showConfirmRemoveProduct}
                onHide={handleCloseConfirmRemoveProduct}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Do you want to remove product{' '}
                        <b>{productToRemove ? productToRemove.name : ''}</b>?{' '}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseConfirmRemoveProduct}
                    >
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => deleteProduct()}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Products;
