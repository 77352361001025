import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Wait from '../../../UI/Wait';

function convertTZ(date, tzString) {
    const tzdate = new Date(date + ' GMT');
    return tzdate.toLocaleString('fi-FI', { timeZone: tzString });
}

const VideosList = ({
    data,
    loading,
    emptyText,
    rowsCountPerPage,
    totalRows,
    handlePageChange,
    merchant,
    handleRowsPerPage,
    perPageChoices = [25],
    handleSort = () => {},
}) => {
    const history = useHistory();

    const columns = useMemo(
        () => [
            {
                name: 'Name',
                init_name: 'display_name',
                grow: 2,
                sortable: true,
                selector: (row) => row.display_name,
                cell: ({ display_name }) => display_name,
            },
            {
                name: 'Timestamp',
                init_name: 'timestamp',
                grow: 2,
                sortable: true,
                selector: (row) => row.timestamp,
                cell: ({ timestamp }) =>
                    convertTZ(timestamp, 'Europe/Helsinki'),
            },
            {
                name: 'Owner',
                init_name: 'user_owner',
                sortable: true,
                selector: (row) => row.user_owner,
                grow: 2,
                cell: ({ user_owner }) => user_owner,
            },
            {
                name: 'Type',
                init_name: 'file_type',
                sortable: true,
                selector: (row) => row.file_type,
                grow: 1,
                cell: ({ file_type }) => file_type,
            },
        ],
        [loading]
    );

    let onRowClick = (row) => {
        history.push(`/debug/video-streaming/view?file_id=${row._id}`);
    };

    return (
        <DataTable
            dense
            striped
            highlightOnHover
            pointerOnHover
            data={data}
            columns={columns}
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            paginationServer
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            progressPending={loading}
            noDataComponent={emptyText}
            sortServer
            onSort={handleSort}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
            onRowClicked={onRowClick}
            onChangeRowsPerPage={handleRowsPerPage}
        />
    );
};

export default VideosList;
