import Honkio from '../../../Honkio';

const getScriptsList = (options = {}, errorHandler = {}) => {
  const { query_application, query_skip, query_count, debug } = options;
  return Honkio().mainShop.userFetch(
    'adminappscriptslist',
    {
      debug,
      query_application,
      query_skip,
      query_count,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getScriptsList;
