import React, { useEffect, useCallback } from 'react';
import Icon from '../../UI/Icon';
import {
    Form,
    Image,
    Button,
    Container,
    Col,
    Row,
    Stack,
} from 'react-bootstrap';
import _ from 'lodash';
import cn from 'classnames';

const DataMapperForm = ({
    columnNames = [],
    modelSchema = {},
    dataMapper = {},
    setDataMapper = () => {},
    handleSubmit = () => {},
}) => {
    const handleChangeMapper = (e) => {
        console.log('handleChangeMapper');
        const newDataMapper = _.cloneDeep(dataMapper);
        _.set(newDataMapper, e.target.name, e.target.value);
        setDataMapper(newDataMapper);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit();
    };

    const checkRender = useCallback(() => {
        if (_.isEmpty(modelSchema)) {
            return false;
        } else if (_.isEmpty(columnNames)) {
            return false;
        } else if (_.isEmpty(dataMapper)) return false;
        return true;
    }, [modelSchema, columnNames, dataMapper]);

    const parseSchema = (schema = {}) => {
        let result = {};
        for (const [key, value] of Object.entries(schema)) {
            if (value?.type === 'boolean') {
                result[key] = value?.default ? value.default : false;
            } else if (value?.type === 'string') {
                result[key] = value?.default ? value.default : '';
            } else if (value?.type === 'array') {
                result[key] = [];
            } else if (value?.type === 'object') {
                result[key] = parseSchema(value.properties);
            }
        }
        return result;
    };

    useEffect(() => {
        if (!_.isEmpty(modelSchema?.properties || {})) {
            const newDataMapper = parseSchema(modelSchema?.properties || {});
            setDataMapper(newDataMapper);
        }
    }, [columnNames, modelSchema]);

    const getFieldType = (val) => {
        if (_.isArray(val)) {
            return 'array';
        } else if (_.isObject(val)) {
            return 'object';
        }
        // TODO:
    };

    const renderModelFields = (schema = {}, prefix = '') => {
        if (!checkRender()) return <span>Select model and file</span>;

        const requiredFieldsNames = modelSchema.required || [];

        let result = [];

        for (const [key, value] of Object.entries(schema)) {
            const inputName = prefix ? `${prefix}.${key}` : key;

            if (_.isObject(value)) {
                let newPrefix = prefix ? `${prefix}.${key}` : key;
                let subResult = renderModelFields(value, newPrefix);
                result = [...result, ...subResult];
            } else if (_.isArray(value)) {
                result.push(
                    <Form.Group
                        key={`${prefix}.${key}`}
                        as={Row}
                        className="mb-3"
                        controlId={`${key}_control_id`}
                    >
                        <Form.Label column sm={6}>
                            {inputName}
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control
                                name={inputName}
                                plaintext
                                readOnly
                                defaultValue="Array fields are not supported"
                            />
                        </Col>
                    </Form.Group>
                );
            } else {
                result.push(
                    <Form.Group
                        key={`${prefix}.${key}`}
                        as={Row}
                        className="mb-3"
                        controlId={`${key}_control_id`}
                    >
                        <Form.Label column sm={6}>
                            {inputName}
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control
                                as="select"
                                name={inputName}
                                value={_.get(dataMapper, `${prefix}.${key}`)}
                                onChange={handleChangeMapper}
                                className={cn('custom-select', 'crsr', {
                                    'border-danger':
                                        requiredFieldsNames.includes(key),
                                })}
                                required={requiredFieldsNames.includes(key)}
                            >
                                <option value="">Choose...</option>
                                {columnNames.map((opt, i) => (
                                    <option
                                        key={`colump-name-option-${i}-${opt}`}
                                        value={opt}
                                    >
                                        {opt}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                );
            }
        }

        return result;
    };

    return (
        <Form onSubmit={onSubmit}>
            {renderModelFields(dataMapper)}
            {checkRender() ? (
                <Button variant="primary" type="submit" className="float-right">
                    Submit
                </Button>
            ) : null}
        </Form>
    );
};

export default DataMapperForm;
