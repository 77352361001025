import { useEffect, useState, useContext } from 'react';
import InlineSearch from '../../../UI/InlineSearch';
import getUserTransactionOrders from '../../../../api/utils/transactions/user';
import { errorHandler, resultHandler } from '../../../../utils/reqHandlers';
import useSearchString from '../../../../utils/useSearchString';
import fixEmail from '../../../../utils/fixEmail';
import TransactionList from './TransactionList';
import UserInfoContext from '../../../../contexts/UserInfoContext';

const Transactions = () => {
  const userInfo = useContext(UserInfoContext);
  const merchantId = userInfo.merchant ? userInfo.merchant.id : null;
  const appid = userInfo.app ? userInfo.app.id : null;
  const [keyword, setKeyword] = useState('');
  const [transactionsList, setTransactionsList] = useState([]);
  const { consumerId, serial } = useSearchString(fixEmail);
  // Orders list
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  //   Pagination
  const [totalRows, setRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  useEffect(() => {
    fetchTransactions();
  }, [keyword, serial, merchantId]);

  let fetchTransactions = async () => {
    setLoading(true);
    getUserTransactionOrders(
      {
        page,
        perPage,
        keyword,
        merchantId,
        type_: 'all',
        serial,
      },
      errorHandler
    ).then((data) => {
      setLoading(false);
      return resultHandler(data, (data) => {
        setTransactionsList(data.orders);
        setRows(data.total);
      });
    });
  };
  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  useEffect(() => {
    fetchTransactions();
  }, [perPage, page]);

  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="row">
            <InlineSearch keyword={keyword} setKeyword={setKeyword} />
          </div>
        </div>
      </div>

      <div className="col-12">
        <TransactionList
          data={transactionsList}
          selectItem={setSelected}
          id={selected.id}
          loading={loading}
          totalRows={totalRows}
          handlePageChange={handlePageChange}
          handleRowsPerPage={handleRowsPerPage}
          rowsCountPerPage={perPage}
          merchant={merchantId}
          emptyText={'The order list is empty'}
        />
      </div>
    </div>
  );
};

export default Transactions;

//   <Table striped bordered hover>
//     <thead>
//       <tr>
//         <th>Date</th>
//         <th>Email</th>
//         <th>IP</th>
//         <th>Target App</th>
//       </tr>
//     </thead>
//     <tbody>
//       {transactionsList.map((item) => {
//         return (
//           <tr>
//             <td>
//               <Link
//                 to={`/transactions/${item?.type_ || item?.type}/${
//                   item._id
//                 }`}
//               >
//                 {item.timestamp}
//               </Link>
//             </td>
//             <td>{item?.identity?.email}</td>
//             <td>{item?.ip}</td>
//             <td>{item?.app}</td>
//           </tr>
//         );
//       })}
//     </tbody>
//   </Table>
