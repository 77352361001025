import { useEffect, useState, useContext, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import appParse from './helpers/appParse';
import getServerAppList from '../../api/utils/app/getServerAppList';
import AppTable from './AppTable';
import useSearchString from '../../utils/useSearchString';
import appCompare from './helpers/appCompare';
import InlineSearch from '../UI/InlineSearch';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import AnySelector from '../UI/AnySelector';
import UserInfoContext from '../../contexts/UserInfoContext';
import ApplicationModal from './ApplicationModal';
import { NotificationManager } from 'react-notifications';
import DeleteConfirmModel from '../Model/DeleteConfirmModel';
import { deleteApp } from '../../api/utils/app/setAppInfo';

const AppSelector = (props) => {
    const [appList, setAppList] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [type, setType] = useState('true');
    const { merchant = null, merchant_name = '' } = useSearchString();
    const userInfo = useContext(UserInfoContext);

    const history = useHistory();
    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;
    const merchantName = merchant
        ? merchant_name
        : userInfo.merchant
        ? userInfo.merchant.name
        : '';
    const pageTitle = `Applications ${merchantName ? `(${merchantName})` : ''}`;

    const [showApplicationPreview, setShowApplicationPreview] = useState(false);
    const handleShowApplicationPreview = () => setShowApplicationPreview(true);
    const handleCloseApplicationPreview = () =>
        setShowApplicationPreview(false);
    const [currentApplication, setCurrentApplication] = useState();
    const [loading, setLoading] = useState();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState();

    const handleShowConfirmModal = () => setShowConfirmModal(true);
    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    const fetchAppList = () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                if (isMounted) {
                    const applications = data?.applications
                        .reduce(appParse, [])
                        .sort(appCompare);
                    setAppList(applications);
                    setLoading(false);
                    if (applications) {
                        applications?.map((app) => {
                            if (app?.name === 'server') {
                                localStorage.setItem('serverAppId', app?.id);
                            }
                            //   if (app?.id) {
                            //     fetchServer(app?.id);
                            //   }
                        });
                    }
                }
            } else {
                setAppList([]);
                setLoading(false);
                NotificationManager.error('Error');
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setAppList([]);
            setLoading(false);
        };
        getServerAppList(
            { query_all: true, merchant: merchantId, debug: true },
            errorHandler
        ).then(resultHandler);
    };

    useEffect(() => {
        fetchAppList();
    }, [merchantId, setAppList]);

    useEffect(() => {
        fetchAppList();
    }, []);

    const onApplicationSelected = useCallback(
        ({ id, name }) => {
            if (id !== userInfo.app.id) {
                userInfo.setInfo({ app: { id, name }, merchant: {} });
                history.push(`/merchants?appid=${id}&appname=${name}`);
            }
        },
        [userInfo.app]
    );

    let onRowClick = (row) => {
        setCurrentApplication(row);
        handleShowApplicationPreview();
    };
    const onDeleteClick = (id) => {
        handleShowConfirmModal();
        setDeleteId(id);
    };

    const handleCloseConfirmDelete = () => {
        const resultHandler = (data) => {
            if (data?.status === 'accept') {
                setShowConfirmModal(false);
                NotificationManager.success('Application removed');
                fetchAppList();
            } else {
                NotificationManager.error(data?.message);
            }
        };
        deleteApp({ id: deleteId }).then((data) => {
            return resultHandler(data);
        });
    };
    return (
        <div className="container-fluid">
            <h2>{pageTitle}</h2>
            <Link
                to={`/app/create`}
                className="btn btn-outline-primary mt-1 mb-3"
            >
                <i className="bi-plus-square ml-1 mr-2 " /> Create application
            </Link>
            <div className="row">
                <div className="col">
                    <InlineSearch setKeyword={setKeyword} />
                </div>
                <div>
                    <AnySelector
                        label="Filter by state"
                        items={[
                            { name: 'Active only', value: 'true' },
                            { name: 'Disabled only', value: 'false' },
                        ]}
                        resetItem={{ name: 'Show all apps', value: '' }}
                        nameSelector="name"
                        valueSelector="value"
                        defaultValue={'true'}
                        setItem={setType}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <AppTable
                        apps={appList}
                        selected
                        keyword={keyword}
                        type={type}
                        loading={loading}
                        emptyText="The applications list is empty"
                        filterSearch={searchFilterIfConsist}
                        filterType={typifyIfEquals}
                        onApplicationSelected={onApplicationSelected}
                        onRowClicked={onRowClick}
                        onDeleteClick={onDeleteClick}
                    />
                    {currentApplication && (
                        <ApplicationModal
                            applicationId={currentApplication.id}
                            show={showApplicationPreview}
                            onHide={handleCloseApplicationPreview}
                        ></ApplicationModal>
                    )}
                </div>
            </div>
            <DeleteConfirmModel
                modal={showConfirmModal}
                onModalClose={() => {
                    setShowConfirmModal(false);
                }}
                handleCloseConfirmDelete={handleCloseConfirmDelete}
            />
        </div>
    );
};

export default AppSelector;
