import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import Wait from '../../../UI/Wait';
import OrderModal from '../OrderModal';
import renderCurrency from '../../../../utils/renderCurrency';

const TransactionList = ({
  data,
  loading,
  emptyText,
  rowsCountPerPage,
  totalRows,
  handlePageChange,
  merchant,
  handleRowsPerPage,
}) => {
  const [showOrderPreview, setShowOrderPreview] = useState(false);
  const handleShowOrderPreview = () => setShowOrderPreview(true);
  const handleCloseOrderPreview = () => setShowOrderPreview(false);
  const [currenOrder, setCurrentOrder] = useState();

  const columns = useMemo(
    () => [
      { name: 'Date', grow: 2, cell: ({ creation_date }) => creation_date },
      { name: 'Status', grow: 2, cell: ({ status }) => status },
      {
        name: 'Email',
        grow: 2,
        cell: ({ user_owner, user_info }) =>
          user_owner || user_info?.email || '',
      },
      {
        name: 'Phone',
        grow: 1,
        cell: ({ user_info }) => user_info?.phone || '',
      },
      {
        name: 'Amount',
        grow: 1,
        cell: ({ amount, currency }) =>
          `${renderCurrency(currency || 'EUR')}${amount}`,
      },
    ],
    [loading]
  );

  let onRowClick = (row) => {
    setCurrentOrder(row);
    handleShowOrderPreview();
  };

  return (
    <div>
      <DataTable
        dense
        striped
        highlightOnHover
        data={data}
        columns={columns}
        pagination={true}
        paginationPerPage={rowsCountPerPage}
        paginationRowsPerPageOptions={[25, 50, 100, 500]}
        paginationServer
        onChangePage={handlePageChange}
        onChangeRowsPerPage={(val) => handleRowsPerPage(val)}
        paginationTotalRows={totalRows}
        progressPending={loading}
        noDataComponent={emptyText}
        progressComponent={
          <div className="p-5">
            <Wait />
          </div>
        }
        onRowClicked={onRowClick}
      />
      {currenOrder && (
        <OrderModal
          orderId={currenOrder._id}
          show={showOrderPreview}
          onHide={handleCloseOrderPreview}
        ></OrderModal>
      )}
    </div>
  );
};

export default TransactionList;
