import React from 'react';
import { checkAuthState } from '../auth/authActions';
import { OAUTH_URL } from '../config';

const isAuth = checkAuthState();
const authURL = OAUTH_URL;

const context = {
  isAuth,
  authURL,
};

const AuthContext = React.createContext(context);

export default AuthContext;
