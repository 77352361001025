import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Container,
    Form,
    Button,
    InputGroup,
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import _ from 'lodash';
import cn from 'classnames';

const NotifyMessageEditForm = ({
    languages,
    onSubmitForm,
    notifyMessage,
    setNotifyMessage,
    language,
    setLanguage,
    fixedName = false,
    variables,
}) => {
    const putIntoHtmlTag = (msg = '', variable = '') => {
        try {
            if (!msg || !variable) return `${msg}` + `${variable}`;
            const reg = /(<([^>]+)>)$/g;
            const closingTag = msg.match(reg);
            const msgNoClosingTag = msg.replace(reg, '');
            return msgNoClosingTag + variable + closingTag;
        } catch (error) {
            console.error('error in putIntoHtmlTag', error);
            return msg;
        }
    };

    const handleMessageChange = (arg, editor, lang) => {
        const newMessage = _.cloneDeep(notifyMessage);
        newMessage[lang.value ? `message__${lang.value}` : 'message'] =
            editor.getData();
        setNotifyMessage(newMessage);
    };

    const handleAddVariable = (val, lang) => {
        const newMsg = _.cloneDeep(notifyMessage);
        newMsg[lang.value ? `message__${lang.value}` : 'message'] =
            putIntoHtmlTag(
                newMsg[lang.value ? `message__${lang.value}` : 'message'],
                `<span>${val}</span>`
            );
        setNotifyMessage(newMsg);
    };

    const handleChangeNotifymessage = (e) => {
        const newMsg = _.cloneDeep(notifyMessage);
        newMsg[e.target.name] = e.target.value;
        setNotifyMessage(newMsg);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log('handleSubmit called');
        console.log('notifyMessage == ', notifyMessage);

        onSubmitForm(notifyMessage);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="message.language">
                <Form.Label>Select Language</Form.Label>

                <Form.Control
                    as="select"
                    name="message.language"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    className={cn('custom-select', 'crsr')}
                >
                    {languages.map((opt, i) => (
                        <option
                            key={`logo-option-${i}-${opt?.value}`}
                            value={opt?.value}
                        >
                            {opt?.name}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text className="text-muted">
                    Select a language for subject and message
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="message.name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    name="name"
                    type="text"
                    value={notifyMessage.name}
                    onChange={handleChangeNotifymessage}
                    placeholder="Message to merchant"
                    required
                />
                <Form.Text className="text-muted">
                    Enter email message name
                </Form.Text>
            </Form.Group>

            {languages.map((lang, index) => {
                let classname = lang.value != language ? 'd-none' : '';
                const messageText = `${
                    notifyMessage[
                        lang.value ? `message__${lang.value}` : 'message'
                    ] || ''
                }`;
                let ggg = '';
                if (messageText.includes('text:')) ggg = messageText;
                if (!messageText.includes('text:'))
                    ggg = `text: ${messageText}`;

                return (
                    <div className={classname} key={index}>
                        <Form.Group controlId="message.name">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                                name={
                                    lang.value
                                        ? `subject__${lang.value}`
                                        : 'subject'
                                }
                                value={
                                    lang.value
                                        ? notifyMessage[
                                              `subject__${lang.value}`
                                          ]
                                        : notifyMessage['subject'] || ''
                                }
                                onChange={handleChangeNotifymessage}
                                type="text"
                                placeholder="Subject"
                                required
                            />
                            <Form.Text className="text-muted">
                                Enter email subject
                            </Form.Text>
                        </Form.Group>

                        <a
                            href="#"
                            onClick={() => {
                                ggg = ggg + messageText;
                                console.log(ggg);
                            }}
                        >
                            {ggg}
                        </a>
                        <div className="my-3">
                            <CKEditor
                                editor={ClassicEditor}
                                data={ggg}
                                onChange={(arg, editor) =>
                                    handleMessageChange(arg, editor, lang)
                                }
                            />
                        </div>

                        <Form.Group controlId="message.variable">
                            <Form.Label>Select variable</Form.Label>

                            <Form.Control
                                as="select"
                                name="message.variable"
                                value={''}
                                onChange={(e) =>
                                    handleAddVariable(e.target.value, lang)
                                }
                                className={cn('custom-select', 'crsr')}
                            >
                                <option value="">Add variable</option>
                                {variables.map((opt, i) => (
                                    <option
                                        key={`logo-option-${i}-${opt?.value}`}
                                        value={opt?.value}
                                    >
                                        {opt?.name}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Text className="text-muted">
                                Add variable to the message
                            </Form.Text>
                        </Form.Group>
                    </div>
                );
            })}

            <Button variant="primary" type="submit" onClick={handleSubmit}>
                Save
            </Button>
        </Form>
    );
};

export default NotifyMessageEditForm;
