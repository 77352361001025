import React, { useState, useEffect } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import ActionButtons from './ActionButtons';
import getMerchantList from '../../../api/utils/merchant/getMerchantList';
import Select, { components } from 'react-select';
import _ from 'lodash';
import getAdminRolesList from '../../../api/utils/role/getAdminRolesList';
import Icon from '../../UI/Icon';
import cn from 'classnames';

const groupStyles = {
    border: `2px dotted red !important`,
    borderRadius: '5px',
    background: '#f2fcff',
};

const GroupHeading = (props) => {
    return (
        <div style={groupStyles}>
            <components.GroupHeading
                {...props}
                onClick={() => console.log('heading clicked')}
            >
                {props?.data?.name || ' '}
            </components.GroupHeading>
        </div>
    );
};

const CustomOption = (props) => {
    return (
        <>
            <components.Option {...props}>{props.children}</components.Option>
        </>
    );
};

const RoleSelector = ({
    rolesList = [],
    setRolesList = () => {},
    nextStep = () => {},
    roleCallback = () => {},
    selectedMerchants = [],
    selectedRoles = [],
    rawMerchants = [],
    ...rest
}) => {
    const [error, setError] = useState('');

    const [rolesSelected, setRolesSelected] = useState([]);

    const [loading, setLoading] = useState(false);

    const validate = () => {
        if (_.isEmpty(rolesSelected)) setError('Please, select roles');
        else {
            const selectedRolesMerchantIds = [
                ...new Set(rolesSelected.map((item) => item.merchant)),
            ];

            if (
                !_.isEqual(
                    selectedMerchants.sort(),
                    selectedRolesMerchantIds.sort()
                )
            ) {
                setError('Please, select role for each merchant');
                return;
            }

            setError('');
            nextStep();
            roleCallback(rolesSelected);
        }
    };

    useEffect(() => {
        if (selectedMerchants) fetchRoles();
    }, [selectedMerchants]);

    useEffect(() => {
        if (
            _.isArray(selectedRoles) &&
            !_.isEmpty(selectedRoles) &&
            _.isArray(rolesList) &&
            !_.isEmpty(rolesList)
        ) {
            setRolesSelected(selectedRoles);
        } else {
            setRolesSelected([]);
        }
    }, [selectedRoles, rolesList]);

    const generateRolesOptions = (roles = []) => {
        const grouped = _.groupBy(roles, 'merchant_name');
        const res = Object.keys(grouped).map((item) => ({
            name: item,
            options: grouped[item],
        }));

        return res;
    };

    const fetchRoles = async () => {
        const resultHandler = (res) => {
            if (res?.status === 'accept' && res.roles) {
                const opts = generateRolesOptions(res.roles);
                setRolesList(opts);
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            setRolesList([]);
            setLoading(false);
        };

        try {
            const res = await getAdminRolesList(
                {
                    query_merchants: selectedMerchants,
                    query_skip: 0,
                    query_count: 10000,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setRolesList([]);
            setLoading(false);
        }
    };

    const renderMerchantsProgress = () => {
        const currentMerchants = rawMerchants.filter((item) =>
            selectedMerchants.includes(item._id)
        );
        const currentSelected = rolesSelected.map((item) => item.merchant);

        return (
            <ListGroup defaultActiveKey="#link1">
                {currentMerchants.map((item) => {
                    const currentIncluded = currentSelected.includes(item._id);
                    return (
                        <ListGroup.Item
                            key={item._id}
                            variant={currentIncluded ? 'primary' : 'secondary'}
                            onClick={(e) => e.preventDefault()}
                            className={cn(
                                'd-flex',
                                'justify-content-between',
                                'align-items-center'
                            )}
                        >
                            <div>{item.name || 'No name'}</div>
                            <div>
                                <Icon
                                    iconName={
                                        currentIncluded
                                            ? 'bi-check-circle-fill'
                                            : 'bi-circle'
                                    }
                                    color={
                                        currentIncluded
                                            ? 'var(--success)'
                                            : 'var(--secondary)'
                                    }
                                />
                            </div>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        );
    };

    return (
        <div>
            <h2>Select roles</h2>

            <Form.Group className="mb-3" controlId="apps.merchants">
                <Form.Label>Merchants progress</Form.Label>
                {renderMerchantsProgress()}
            </Form.Group>
            <Form.Group className="mb-3" controlId="apps.roles">
                <Form.Label>Roles</Form.Label>
                <Select
                    id="action-type-select"
                    value={rolesSelected}
                    onChange={(val) => {
                        setRolesSelected(val);
                        setError('');
                    }}
                    isMulti
                    options={rolesList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={'— Select roles —'}
                    menuPortalTarget={document.body}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option._id}
                    isLoading={loading}
                    components={{
                        GroupHeading: GroupHeading,
                        Option: CustomOption,
                    }}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 2 }),
                        groupHeading: (base) => ({
                            ...base,
                            flex: '1 1',
                            color: 'var(--white)',
                            backgroundColor: 'var(--primary)',
                            margin: 0,
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            padding: '0.5rem',
                            zIndex: 1000,
                        }),
                    }}
                    required
                />
                {error ? (
                    <Form.Text className="text-danger">{error}</Form.Text>
                ) : null}
            </Form.Group>

            <ActionButtons {...rest} nextStep={validate} />
        </div>
    );
};

export default RoleSelector;
