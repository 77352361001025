/**
 *
 * @param {*} currCode - currency 3-letter code, like EUR, USD
 */
const renderCurrency = (currCode = 'EUR') => {
  if (currCode == 'EUR') return '€';
  if (currCode == 'USD') return '$';
  if (currCode) return currCode;
  return '€';
};

export default renderCurrency;
