import Honkio from '../../Honkio';

const getProductTagList = (options = {}, errorHandler = {}) => {
  const { query_merchants, query_skip, query_count, debug } = options;
  return Honkio().mainShop.userFetch(
    'admintaglist',
    {
      debug,
      query_merchants,
      query_skip,
      query_count,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};
export default getProductTagList;
