const getRfData = ({
  label,
  capture,
  auth,
  chargeback,
  edit_products,
  send_push,
  edit_amount,
  reserve,
  pb,
  sales_point,
  bundled_products,
  send_message,
  service_models,
  velocity_rules,
}) => {
  return {
    data: {
      label,
      settings: {
        capture,
        auth,
        chargeback,
      },
      options: {
        edit_products,
        send_push,
        edit_amount,
        reserve: reserve ? ['yes'] : [],
        public: pb ? ['yes'] : [],
        sales_point: sales_point ? ['yes'] : [],
        bundled_products,
        send_message,
        service_models,
        velocity_rules,
      },
    },
  };
};

export default getRfData;
