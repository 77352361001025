const appCompare = (a, b) => {
  return a.active === b.active
    ? a.name < b.name
      ? -1
      : 1
    : a.active === 'true'
    ? -1
    : 1;
};

export default appCompare;
