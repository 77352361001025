import { useEffect, useState, useContext } from 'react';
import useSearchString from '../../utils/useSearchString';
import prepareToPublish from './helpers/prepareToPublish';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';

import UserInfoContext from '../../contexts/UserInfoContext';
// import setAssetData from '../../api/utils/asset/setAssetData';
// import getAssetType from '../../api/utils/asset/type/getAssetType';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';
import BackButton from '../../components/UI/BackButton';
import _ from 'lodash';
import { GOOGLE_API_KEY } from '../../config';
import { getCustomer } from '../../api/utils/customer/getCustomer';
import CustomerEditForm from './CustomerEditForm';
import { updateCustomer } from '../../api/utils/customer/setCustomer';
import { getCustomerType } from '../../api/utils/customer/type/getCustomerType';
const BASE_GEO_CODE_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}`;

const CustomerEdit = () => {
    const [selectedCustomerType, setSelectedCustomerType] = useState([]);

    const [customerInfo, setCustomerInfo] = useState({});
    const { id: customerId } = useSearchString();
    const [progress, done] = useLoader([customerInfo, selectedCustomerType]);

    const [coordinatesInput, setCoordinatesInput] = useState([0, 0]);
    const [coordUpdated, setCoordUpdated] = useState(false);
    const [addressInput, setAddressInput] = useState('');
    const [addressUpdated, setAddressUpdated] = useState(false);

    // Address fields
    const [showAddressListModal, setShowAddressListModal] = useState(false);
    const handleShowAddressListModal = () => setShowAddressListModal(true);
    const handleCloseAddressListModal = () => setShowAddressListModal(false);
    const [addressList, setAddressList] = useState();

    const userInfo = useContext(UserInfoContext);
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    useEffect(() => {
        if (customerInfo?.type_reference) fetchCustomerType();
    }, [customerInfo]);

    const fetchCustomerType = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept' && data.customer_type) {
                setSelectedCustomerType(data.customer_type);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            const res = await getCustomerType(
                { id: customerInfo.type_reference },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.error(
                'Unexpected error while fetchin customer type: ',
                error
            );
        }
    };

    const onSave = async (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('customer saved', 'Success', 4000);
                setCustomerInfo(data.customer);
                if (data?.customer?.coordinates) {
                    setCoordinatesInput(data.customer.coordinates);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        const customerData = prepareToPublish(customerInfo, data);
        customerData.merchant = merchantId;
        customerData.application = appId;
        console.log('customerData ==', customerData);

        try {
            const res = await updateCustomer(customerData, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.error('Unexpected error while updating customer', error);
        }
    };

    useEffect(() => {
        fetchCustomer();
    }, [customerId]);

    const fetchCustomer = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setCustomerInfo(data.customer);
                if (data?.customer?.coordinates) {
                    setCoordinatesInput(data.customer.coordinates);
                }
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setCustomerInfo();
        };
        if (customerId) {
            try {
                const res = await getCustomer({ id: customerId }, errorHandler);
                resultHandler(res);
            } catch (error) {
                console.error(
                    'Unexpected error while fetching customer: ',
                    error
                );
            }
        } else {
            setCustomerInfo();
        }
    };

    const handleGoogleRequest = async (adr, flag = 'address') => {
        const requestOptions = {
            method: 'POST',
        };
        const response = await fetch(adr, requestOptions);
        const result = await response.json();

        if (result.status === 'OK' && result.results.length > 0) {
            if (result.results.length > 1) {
                setAddressList(result.results);
                handleShowAddressListModal();
            } else {
                const newCustomer = _.cloneDeep(customerInfo);

                const coordinates = [
                    result.results[0].geometry.location.lat,
                    result.results[0].geometry.location.lng,
                ];

                const address = result.results[0].formatted_address;

                newCustomer.coordinates = coordinates;
                newCustomer.str_address = address;

                setCoordinatesInput(coordinates);
                setAddressInput(address);

                setCustomerInfo(newCustomer);
            }
        } else {
            const newCustomer = _.cloneDeep(customerInfo);
            newCustomer.coordinates = [0, 0];

            setCustomerInfo(newCustomer);
            NotificationManager.warning(
                result.error_message || 'Geocode was not successful',
                'Geocode error',
                5000
            );
        }
    };

    const handleCoordinatesChanged = async (coordinates) => {
        setCoordUpdated(false);

        const adr = `${BASE_GEO_CODE_URL}&latlng=${coordinates[0]},${coordinates[1]}`;

        await handleGoogleRequest(adr, 'coordinates');
    };

    function onAddressSelected(address) {
        handleCloseAddressListModal();
        const coordinates = [
            address.geometry.location.lat,
            address.geometry.location.lng,
        ];
        const submitAddress = address.formatted_address;

        const submitData = _.cloneDeep(customerInfo);
        submitData.coordinates = coordinates;

        setCoordinatesInput(coordinates);
        setAddressInput(submitAddress);

        setCustomerInfo(submitData);
    }

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton to="/customers" text="Back to list" /> Customer edit
                (#{customerId})
            </h2>
            {done ? (
                customerInfo ? (
                    <CustomerEditForm
                        onSubmit={onSave}
                        defaultValues={customerInfo}
                        customerType={selectedCustomerType}
                        coordinatesInput={coordinatesInput}
                        setCoordinatesInput={setCoordinatesInput}
                        setCoordUpdated={setCoordUpdated}
                        handleCoordinatesChanged={handleCoordinatesChanged}
                        addressList={addressList}
                        showAddressListModal={showAddressListModal}
                        onAddressSelected={onAddressSelected}
                        handleCloseAddressListModal={
                            handleCloseAddressListModal
                        }
                    />
                ) : (
                    'not found'
                )
            ) : (
                <LoadingBar progress={progress} />
            )}
        </div>
    );
};

export default CustomerEdit;
