import Honkio from '../../Honkio';

const deleteOldOrders = (options = {}, errorHandler = () => {}) => {
  const { date, merchant_id, app_id } = options;
  return Honkio().mainShop.userFetch(
    'adminorderdeleteold',
    { date, merchant_id, app_id, version: 3, action: 'delete' },
    errorHandler
  );
};
export default deleteOldOrders;
