const defaultUserRegistrationRequirements = [
    {
        id: 'first_name_user',
        required: true,
        name: 'First name',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'last_name_user',
        required: true,
        name: 'Last name',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'address_user',
        required: false,
        name: 'Address',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'phone_user',
        required: false,
        name: 'Phone',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'alias_user',
        required: false,
        name: 'Alias',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'day_of_birth_user',
        required: false,
        name: 'Day Of Birth',
        mandatory: false,
        type: 'date',
    },
    {
        id: 'password_user',
        required: false,
        name: 'Password',
        mandatory: false,
        type: 'str',
    },
];

export const defaultUserRegFieldIds = [
    'first_name_user',
    'last_name_user',
    'address_user',
    'phone_user',
    'alias_user',
    'day_of_birth_user',
    'password_user',
];

export default defaultUserRegistrationRequirements;
