import { errorHandler, resultHandler } from '../../../utils/reqHandlers';
import UserInfoContext from '../../../contexts/UserInfoContext';
import { Button, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext } from 'react';
import getMerchantTransaction from '../../../api/utils/transactions/merchant/getMerchantTransaction';

let PENDING = -100;
let REJECT = 501;
let ACCEPT = 502;
let DISPUTE = 503;
let CHARGEBACK = 1000;
let CAPTURE = 1001;

let getStatus = (transaction_status) => {
  if (transaction_status == PENDING) {
    return 'Pending';
  } else if (transaction_status == REJECT) {
    return 'Reject';
  } else if (transaction_status == ACCEPT) {
    return 'Accept';
  } else if (transaction_status == DISPUTE) {
    return 'Dispute';
  } else if (transaction_status == CHARGEBACK) {
    return 'Chargeback';
  } else if (transaction_status == CAPTURE) {
    return 'Capture';
  }
};

const TransactionRegisterMerchantDetails = () => {
  let { transactionID } = useParams();
  const [transaction, setTransaction] = useState({});
  const userInfo = useContext(UserInfoContext);
  let merchantId = userInfo.merchant ? userInfo.merchant.id : null;

  useEffect(() => {
    fetchTransactions();
  }, [merchantId, transactionID]);

  let fetchTransactions = async () => {
    getMerchantTransaction(
      {
        id: transactionID,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data, (data) => {
        setTransaction(data?.transaction);
      });
    });
  };

  if (!transaction._id) {
    return <div></div>;
  }
  return (
    <Table>
      <tbody>
        <tr>
          <th>Time</th>
          <td>{transaction.timestamp}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>{transaction.identity.email}</td>
        </tr>
        <tr>
          <th>First name</th>
          <td>{transaction.identity.firstname}</td>
        </tr>
        <tr>
          <th>Last name</th>
          <td>{transaction.identity.lastname}</td>
        </tr>
        <tr>
          <th>Status</th>
          <td>{getStatus(transaction.status)}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TransactionRegisterMerchantDetails;
