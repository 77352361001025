import Honkio from '../../../Honkio';

const setPasswordPolicy = (options = {}, errorHandler = () => {}) => {
  return Honkio().mainShop.userFetch(
    'settingspasswordpolicyset',
    { ...options, version: 3 },
    errorHandler
  );
};

export const createPasswordPolicy = (options = {}, errorHandler = () => {}) => {
  return setPasswordPolicy({ ...options, action: 'create' }, errorHandler);
};

export const updatePasswordPolicy = (options = {}, errorHandler = () => {}) => {
  return setPasswordPolicy({ ...options, action: 'update' }, errorHandler);
};

export const deletePasswordPolicy = (options = {}, errorHandler = () => {}) => {
  return setPasswordPolicy({ ...options, action: 'delete' }, errorHandler);
};
