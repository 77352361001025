import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

const TextareaSmart = ({
  className,
  name,
  label,
  defaultValue,
  required,
  additional,
  error,
  disabled,
  style,
  hidden,
}) => {
  const { register } = useFormContext();
  return (
    <div
      className={clsx(className, {
        'd-none': hidden,
        'd-flex flex-column': !hidden,
      })}
      style={style}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        ref={register}
        className="form-control flex-grow-1"
        id={name}
        name={name}
        defaultValue={defaultValue}
        required={required}
        disabled={disabled}
      />
      {additional && <small className="text-muted">{additional}</small>}
      {error && <div className="invalid-feedback">Error message: $message</div>}
    </div>
  );
};

export default TextareaSmart;
