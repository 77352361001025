import UserInfoContext from '../../../contexts/UserInfoContext';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { NotificationManager } from 'react-notifications';
import getMerchantTransaction from '../../../api/utils/transactions/merchant/getMerchantTransaction';

const TransactionRegisterMerchantDetails = () => {
  let { transactionID } = useParams();
  const [transaction, setTransaction] = useState({});
  const userInfo = useContext(UserInfoContext);
  let merchantId = userInfo.merchant ? userInfo.merchant.id : null;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (transactionID) fetchTransactions();
  }, [merchantId, transactionID]);

  let fetchTransactions = async () => {
    console.log('transaction id ==', transactionID);
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        setTransaction(data?.transaction);
        setLoading(false);
      } else {
        setTransaction(null);
        setLoading(false);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setTransaction(null);
      setLoading(false);
    };
    getMerchantTransaction(
      {
        id: transactionID,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data, (data) => {
        setTransaction(data?.transaction);
      });
    });
  };

  if (!transaction._id) {
    return <div></div>;
  }

  return (
    <Table>
      <tbody>
        <tr>
          <th>Time</th>
          <td>{transaction.timestamp}</td>
        </tr>
        <tr>
          <th>Consumer</th>
          <td>
            <p>{transaction?.consumer?.email}</p>
            <p>{transaction?.consumer?.firstname}</p>
            <p>{transaction?.consumer?.lastname}</p>
            <p>{transaction?.consumer?.telephone}</p>
          </td>
        </tr>
        <tr>
          <th>Merchant</th>
          <td>
            <p>{transaction?.merchant?.email}</p>
            <p>{transaction?.merchant?.name}</p>
            <p>{transaction?.merchant?.businessid}</p>
            <p>{transaction?.merchant?.telephone}</p>
          </td>
        </tr>
        <tr>
          <th>Authentication provider</th>
          <td>Telia: {transaction.acr_value}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TransactionRegisterMerchantDetails;
