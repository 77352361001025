import { useEffect, useState, useContext } from 'react';
import { getOrders } from '../../api/utils/order/getOrders';
import useSearchString from '../../utils/useSearchString';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import DeleteOrderList from '../Model/DeleteOrderList';
import moment from 'moment';
import OrderList from '../Orders/OrderList';
import deleteOldOrders from '../../api/utils/order/deleteOldOrders';

const Reservations = () => {
  const [orderList = [], setOrderList] = useState([]);
  const [selected, setSelected] = useState({});
  const userInfo = useContext(UserInfoContext);
  const { merchant = null, application = null } = useSearchString();
  const merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;
  const applicationId = application
    ? application
    : userInfo.app
    ? userInfo.app.id
    : null;
  //todo if merchant we have to ger merchant name someware to put it in title
  const pageTitle = merchant
    ? 'Reservations'
    : userInfo.merchant?.name
    ? `Reservations (${userInfo.merchant.name})`
    : 'Reservations';

  const [loading, setLoading] = useState(false);
  let isMounted = true;
  const [perPage, setPerPage] = useState(25);
  const [totalRows, setTotalRows] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (merchantId) {
      fetchOrders(1);
    }
  }, [merchantId]);

  const fetchOrders = async (page) => {
    setLoading(true);
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        if (isMounted) {
          setOrderList(data.orders);
          setTotalRows(data.orders_count);
          setLoading(false);
        }
      } else {
        NotificationManager.error('Error');
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setOrderList([]);
      setTotalRows(0);
      setLoading(false);
    };

    if (isMounted) {
      getOrders(
        {
          query_merchant: merchantId,
          query_application: applicationId,
          page: page - 1,
          query_count: perPage,
          query_child_merchants: true,
        },
        errorHandler
      )
        .then((data) => {
          return resultHandler(data);
        })
        .catch(console.log);
    }
  };

  const handlePageChange = (page) => {
    fetchOrders(page);
  };
  const handleDeleteOrders = () => {
    setShowConfirmModal(true);
  };
  const handleConfirmDeleteOrder = (deleteDate) => {
    const dateResult = moment(deleteDate).format('YYYY-MM-DD').toLocaleString();
    const resultHandler = (data) => {
      if (data && data?.status === 'accept') {
        setShowConfirmModal(false);
        NotificationManager.success(data?.order?.message);
        fetchOrders(1);
      } else {
        NotificationManager.error(data?.order?.message);
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    deleteOldOrders(
      { date: dateResult, app_id: applicationId, merchant_id: merchantId },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };
  return (
    <div className="w-100 h-100">
      <NotificationContainer />
      <div className="container-fluid">
        <div className="cus-flex">
          <h2>{pageTitle}</h2>{' '}
          <button
            className="btn btn-primary btn-purple"
            onClick={handleDeleteOrders}
          >
            Delete old orders
          </button>
        </div>

        {merchantId ? (
          <div className="row">
            <div className="col">
              <OrderList
                data={orderList}
                selectItem={setSelected}
                id={selected.id}
                loading={loading}
                totalRows={totalRows}
                handlePageChange={handlePageChange}
                rowsCountPerPage={perPage}
                merchant={merchantId}
                emptyText={'The order list is empty'}
              />
            </div>
          </div>
        ) : (
          'Please, select merchant first '
        )}
      </div>
      <DeleteOrderList
        modal={showConfirmModal}
        onModalClose={() => {
          setShowConfirmModal(false);
        }}
        handleConfirmDeleteOrder={handleConfirmDeleteOrder}
      />
    </div>
  );
};

export default Reservations;
