import Honkio from '../../../Honkio';

const merchantProductTypeSet = async function (options, errorHandler) {
  return Honkio().mainShop.userFetch(
    'adminproducttypeset',
    {
      ...options,
      version: 3,
    },
    errorHandler
  );
};

const merchantUpdateProductType = (productType, errorHandler) => {
  return merchantProductTypeSet(
    {
      ...productType,
      action: 'update',
    },
    errorHandler
  );
};

const merchantCreateProductType = (merchantId, productType, errorHandler) => {
  return merchantProductTypeSet(
    {
      merchant: merchantId,
      ...productType,
      action: 'create',
    },
    errorHandler
  );
};

const merchantDeleteProductType = (productTypeId, errorHandler) => {
  return merchantProductTypeSet(
    {
      id: productTypeId,
      action: 'delete',
    },
    errorHandler
  );
};

export {
  merchantUpdateProductType,
  merchantCreateProductType,
  merchantDeleteProductType,
};
