import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../UI/Icon';
import ReactTooltip from 'react-tooltip';
import { Form } from 'react-bootstrap';
import cn from 'classnames';

const useProductListColumns = ({
    products,
    onDeleteClick = () => {},
    onBuyClick = () => {},
    onSetDefault = () => {},
    isAdmin,
}) =>
    useMemo(
        () => [
            {
                name: '#',
                width: '50px',
                grow: 0,
                right: true,
                cell: (_, index) => index + 1,
            },
            { name: 'Name', grow: 3, cell: ({ name }) => name },
            {
                name: 'Type',
                grow: 0,
                cell: ({ type_id }) => products.productTypes[type_id],
            },
            {
                name: 'Vendor code',
                grow: 0,
                cell: ({ vendorcode }) => vendorcode,
            },
            { name: 'Amount', grow: 0, cell: ({ amount }) => amount },
            {
                name: 'Active',
                grow: 0,
                cell: ({ active }) => (active ? 'Yes' : 'No'),
            },
            {
                name: 'Default',
                grow: 0,
                omit: !isAdmin,
                cell: ({ id, is_default }) => (
                    <Form.Switch
                        id={`is-default-switch-${id}`}
                        defaultChecked={is_default}
                        onChange={() => {
                            onSetDefault(id, !is_default);
                        }}
                        className="m-0"
                        name="is_default"
                        label=""
                    />
                ),
            },
            {
                name: 'Actions',
                grow: 1,
                right: true,
                cell: ({ id, name, is_default }) => (
                    <div className="d-flex">
                        <Link to={`/products/edit?product=${id}`}>
                            <span data-tip data-for="editTip">
                                <Icon iconName="bi-pen" />
                            </span>
                            <ReactTooltip
                                id="editTip"
                                place="top"
                                effect="solid"
                            >
                                Edit
                            </ReactTooltip>
                        </Link>
                        <div
                            onClick={() => {
                                onBuyClick(id, name);
                            }}
                            data-tip
                            data-for="buyTip"
                        >
                            <Icon iconName="btn bi-cart" />
                            <ReactTooltip
                                id="buyTip"
                                place="top"
                                effect="solid"
                            >
                                Purchase
                            </ReactTooltip>
                        </div>
                        <div
                            onClick={() => {
                                onDeleteClick(id, name);
                            }}
                            data-tip
                            data-for="deleteTip"
                        >
                            <Icon iconName="btn bi-trash" />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </div>
                    </div>
                ),
            },
        ],
        [products.productTypes]
    );

export default useProductListColumns;
