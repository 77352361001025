import { useState } from 'react';

const DropdownButton = ({
  children,
  label,
  className,
  toggleClass,
  collapseClass,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      className={className}
      onPointerLeave={() => {
        setExpanded(false);
      }}
    >
      <button
        className={toggleClass ?? 'btn btn-primary dropdown-toggle'}
        onPointerUp={() => {
          setExpanded(!expanded);
        }}
      >
        {label}
      </button>
      <div className="position-relative">
        <div
          style={{ zIndex: 9000 }}
          className={
            collapseClass ??
            'w-100 d-flex flex-column align-items-stretch position-absolute py-2'
          }
        >
          {expanded && children}
        </div>
      </div>
    </div>
  );
};

export default DropdownButton;
