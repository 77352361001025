/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactFlow, {
  addEdge,
  removeElements,
  isNode,
  Controls,
  Background,
  useStoreActions,
} from 'react-flow-renderer';
import dagre from 'dagre';
import '../../App.css';
import { NotificationContainer } from 'react-notifications';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const getLayoutedElements = (elements, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });
  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: 150, height: 50 });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });
  dagre.layout(dagreGraph);
  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';
      // unfortunately we need this little hack to pass a slighltiy different position
      // in order to notify react flow about the change
      el.position = {
        x: nodeWithPosition.x + Math.random() / 1000,
        y: nodeWithPosition.y,
      };
    }
    return el;
  });
};

function countWords(s) {
  s = s.replace(/(^\s*)|(\s*$)/gi, ''); //exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi, ' '); //2 or more space to 1
  s = s.replace(/\n /, '\n'); // exclude newline with a start spacing
  return s.split(' ').filter(function (str) {
    return str != '';
  }).length;
}

const LayoutFlow = ({
  initialElements,
  assetTypesScheme,
  onPublish,
  onLoad,
}) => {
  const [elements, setElements] = useState([]);
  //const [rfInstance, setRfInstance] = useState(null);
  const ref = useRef();

  const setSelectedElements = useStoreActions(
    (actions) => actions.setSelectedElements
  );

  useEffect(() => {
    setElements(initialElements);
  }, [initialElements]);

  const onConnect = (params) =>
    setElements((els) => addEdge({ ...params, animated: true }, els));

  const onElementsRemove = (elementsToRemove) => {
    setElements((els) => removeElements(elementsToRemove, els));
    setSelectedElements([]);
  };

  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements, direction);
      setElements(layoutedElements);
    },
    [elements]
  );

  return (
    elements && (
      <div className="layoutflowhigh w-100 ">
        <NotificationContainer />
        <ReactFlow
          elements={elements}
          onConnect={onConnect}
          onElementsRemove={onElementsRemove}
          snapToGrid={true} // seems that it could be adjusted at dagreGraph
          onLoad={onLoad}
        >
          <Controls />
          <Background />
        </ReactFlow>
        <div className="d-flex justify-content-center align-items-center">
          <div className="btn-group" role="group" aria-label="Nodes actions">
            <button
              onClick={() => onLayout('TB')}
              type="button"
              className="btn btn-outline-primary btn-sm"
            >
              vertical layout
            </button>
            <button
              onClick={() => onLayout('LR')}
              type="button"
              className="btn btn-outline-primary btn-sm"
            >
              horizontal layout
            </button>
            <button
              onClick={onPublish}
              type="button"
              className="btn btn-outline-success btn-sm"
            >
              Save model
            </button>
          </div>
        </div>
      </div>
    )
  );
};
export default LayoutFlow;
