import React, { useState, useEffect } from 'react';
import { ListGroup, Button, Form, InputGroup } from 'react-bootstrap';
import ProductsItemInput from './ProductsItemInput';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import _ from 'lodash';
import SelectAsync from '../common/SelectAsync';
import getProductList from '../../api/utils/product/getProductList';
import { NotificationManager } from 'react-notifications';
import ReactTooltip from 'react-tooltip';

const ProductsInput = ({
    className,
    selectedProducts = [],
    setSelectedProducts = (prev = []) => {},
    removedProductsIds = [],
}) => {
    const [selectedProduct, setSelectedProduct] = useState();

    const handleCopyIdToClipboard = (val) => {
        navigator.clipboard.writeText(val);
        NotificationManager.success('id copied to clipboard!');
    };

    const renderProducts = () => {
        return selectedProducts.map((item, index) => (
            <ListGroup.Item
                key={`products-item-${item?._id}-${index}`}
                variant={
                    removedProductsIds.includes(item.id)
                        ? 'danger'
                        : 'secondary'
                }
                data-tip
                data-for={`productTip-${item.id}`}
            >
                <ProductsItemInput
                    product={item}
                    onDelete={handleDeleteProduct}
                    onEdit={handleEditProduct}
                    onClick={(e) => handleCopyIdToClipboard(item.id)}
                />
                {removedProductsIds.includes(item.id) ? (
                    <ReactTooltip
                        id={`productTip-${item.id}`}
                        place="top"
                        effect="solid"
                    >
                        Product "{item.name}" was removed from database. Please,
                        removed it from this list
                    </ReactTooltip>
                ) : (
                    <ReactTooltip
                        id={`productTip-${item.id}`}
                        place="top"
                        effect="solid"
                    >
                        <pre className={cn('text-white')}>
                            {JSON.stringify(item, null, 2)}
                        </pre>
                    </ReactTooltip>
                )}
            </ListGroup.Item>
        ));
    };

    const handleAddProduct = (e) => {
        e.preventDefault();

        if (!selectedProduct) {
            NotificationManager.error('You must select product first');
            return;
        }
        const existing = selectedProducts.find(
            (item) => item.id === selectedProduct.id
        );
        if (existing) {
            NotificationManager.error('This product is already in list');
            return;
        }

        const newProduct = {
            id: selectedProduct.id,
            name: selectedProduct.name,
            quantity: 0,
        };
        setSelectedProducts((prev) => [...prev, newProduct]);
    };

    const handleEditProduct = (updatedProduct = { id: null, quantity: 0 }) => {
        const newProducts = _.cloneDeep(selectedProducts);

        const idx = newProducts.findIndex(
            (item) => item?.id === updatedProduct?.id
        );

        if (idx !== 0 && !idx) return;
        let newProduct = newProducts[idx];
        newProduct = { ...newProduct, ...updatedProduct };
        newProducts[idx] = newProduct;

        setSelectedProducts(newProducts);
    };

    const handleDeleteProduct = (id) => {
        setSelectedProducts((prev) => prev?.filter((item) => item.id !== id));
    };

    return (
        <div className={cn(className)}>
            <Form>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Product</InputGroup.Text>
                    <SelectAsync
                        placeholder="Select product to add"
                        itemsFetcher={getProductList}
                        response_key="product"
                        getOptionLabel={(opt) => opt?.name}
                        getOptionValue={(opt) => opt.id}
                        isMulti={false}
                        selectedItems={selectedProduct}
                        setSelectedItems={setSelectedProduct}
                        uniqueKey="id"
                        perPage={25}
                    />
                    <Button
                        onClick={handleAddProduct}
                        variant="primary"
                        type="button"
                    >
                        Add
                    </Button>
                </InputGroup>
            </Form>
            <ListGroup>{renderProducts()}</ListGroup>
        </div>
    );
};

export default ProductsInput;
