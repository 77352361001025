import { useEffect, useState, useContext } from 'react';
import useSearchString from '../../utils/useSearchString';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import UserInfoContext from '../../contexts/UserInfoContext';
import getEvent from '../../api/utils/event/getEvent';
import EventEditForm from './EventEditForm';
import { editEvent } from '../../api/utils/event/setEvent';
import BackButton from '../UI/BackButton';

const EventEdit = () => {
    const [event, setEvent] = useState();

    const { event: event_id, merchant } = useSearchString();

    const userInfo = useContext(UserInfoContext);
    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;

    const onSave = async (data) => {
        console.log('EventEdit: onSave: data == ', data);

        const resultHandler = (data) => {
            NotificationManager.success('Updated successfully!');
            fetchEvent();
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };
        const res = await editEvent({ id: event._id, ...data }, errorHandler);
        if (res && res.status === 'accept') resultHandler(res);
    };

    const fetchEvent = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setEvent(data.event);
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };
        const res = await getEvent(
            { id: event_id, query_info: true },
            errorHandler
        );
        resultHandler(res);
    };

    useEffect(() => {
        if (event_id) fetchEvent(event_id);
    }, [event_id]);

    return (
        <div>
            <NotificationContainer />

            <BackButton to="/events" text="Back to events list" />

            {event ? (
                <EventEditForm defaultValues={event} onSubmit={onSave} />
            ) : (
                <label>no data</label>
            )}
        </div>
    );
};

export default EventEdit;
