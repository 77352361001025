import { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Col, Image } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';

const RefundModal = ({ order, show, onHide, onSubmit }) => {
  const PERCENTAGE_TYPE = 'PERCENTAGE_TYPE';
  const FIXED_AMOUNT_TYPE = 'FIXED_AMOUNT_TYPE';
  const [refundType, setRefundType] = useState(FIXED_AMOUNT_TYPE); // or PERCENTAGE_TYPE
  const [fixedAmount, setFixedAmount] = useState(0.0);
  const [percentage, setPercentage] = useState(0.0);

  const onClickHide = () => {
    setRefundType(FIXED_AMOUNT_TYPE);
    setFixedAmount(0.0);
    setPercentage(0.0);
    onHide();
  };

  const checkFixedAmount = (val) => {
    const valNum = parseFloat(val);
    if (valNum > order.amount || valNum < 0) {
      return false;
    }
    return true;
  };

  const checkPercentage = (val) => {
    const valNum = parseFloat(val);
    if (valNum > 100 || valNum < 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const re = /^[0-9]*[\.]?[0-9]{0,2}$/gm;

    if (refundType === FIXED_AMOUNT_TYPE) {
      if (re.test(fixedAmount)) {
        const is_f_valid = checkFixedAmount(fixedAmount);
        if (!is_f_valid) {
          NotificationManager.error(
            `Enter minimum 0 and maximum ${order?.amount}`,
            'Error'
          );
          return;
        }

        onSubmit({
          order_id: order._id,
          fixed_amount: fixedAmount,
          amount_percentage: 0,
        });
      } else {
        NotificationManager.error(
          'You must specify float or integer number for fixed amount',
          'Error'
        );
        return;
      }
    }
    if (refundType === PERCENTAGE_TYPE) {
      if (re.test(percentage)) {
        const is_p_valid = checkPercentage(percentage);
        if (!is_p_valid) {
          NotificationManager.error(`Enter minimum 0 and maximum 100`, 'Error');
          return;
        }

        onSubmit({
          order_id: order._id,
          fixed_amount: 0,
          amount_percentage: percentage,
        });
      } else {
        NotificationManager.error(
          'You must specify float or integer number for percentage',
          'Error'
        );
        return;
      }
    }

    onClickHide();
  };

  const handleSetFixedAmount = (e) => {
    const val = e.target.value;

    setFixedAmount(val);
  };

  const handleSetPercentage = (e) => {
    const val = e.target.value;

    setPercentage(val);
  };

  return (
    <Modal show={show} onHide={onClickHide}>
      <Modal.Header closeButton>
        <Modal.Title>Refund #{order?._id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="fixed"
          id="-tab-example"
          className="mb-3"
          activeKey={refundType}
          onSelect={(k) => setRefundType(k)}
        >
          <Tab title="Fixed amount" eventKey={FIXED_AMOUNT_TYPE}>
            <Form.Label htmlFor="fixed_amt">Fixed amount</Form.Label>
            <Form.Control
              type="text"
              id="fixed_amt"
              aria-describedby="fixedAmountHelpBlock"
              value={fixedAmount}
              onChange={handleSetFixedAmount}
            />
            <Form.Text id="fixedAmountHelpBlock" muted>
              Select fixed part of order amount ({order?.amount})
            </Form.Text>
          </Tab>
          <Tab title="Percentage" eventKey={PERCENTAGE_TYPE}>
            <Form.Label htmlFor="percentage">Percentage</Form.Label>
            <Form.Control
              type="text"
              id="percentage"
              aria-describedby="percentageHelpBlock"
              value={percentage}
              onChange={handleSetPercentage}
            />
            <Form.Text id="percentageHelpBlock" muted>
              Percentage of order amount (0 - 100)
            </Form.Text>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button variant="secondary" onClick={onClickHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RefundModal;
