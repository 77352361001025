import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import getProductTypeList from '../../api/utils/product/type/getProductTypeList';
import useSearchString from '../../utils/useSearchString';
import languages from '../../__fixtures__/languages';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SmartForm from '../Form/SmartForm';
import Fields from './Fields';
import SmartLanguageSelector from './SmartLanguageSelector';
import prepareToPublishProductType from './helpers/prepareToPublishProductType';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  merchantCreateProductType,
  merchantDeleteProductType,
  merchantUpdateProductType,
} from '../../api/utils/product/type/setAdminProductTag';
import UserInfoContext from '../../contexts/UserInfoContext';
import { Modal, Button } from 'react-bootstrap';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';
import getProductType from '../../api/utils/product/type/getProductType';

const postfixes = [
  '',
  ...languages.map(({ value: language }) => `__${language}`),
];

const propertiesFieldName = 'fields';

const ProductTypeEdit = () => {
  const history = useHistory();
  const userInfo = useContext(UserInfoContext);
  const { merchant = null, merchant_name = '' } = useSearchString();
  const merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;
  const merchantName = merchant
    ? merchant_name
    : userInfo.merchant
    ? userInfo.merchant.name
    : '';

  const [productTypeInfo, setProductTypeInfo] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState('');
  const { type } = useSearchString();

  const [showConfirmRemoveProductType, setShowConfirmRemoveProductType] =
    useState(false);
  const handleShowConfirmRemoveProductType = () =>
    setShowConfirmRemoveProductType(true);
  const handleCloseConfirmRemoveProductType = () =>
    setShowConfirmRemoveProductType(false);

  const [progress, done] = useLoader([productTypeInfo]);

  const showConfirmDialog = () => {
    handleShowConfirmRemoveProductType();
  };

  useEffect(() => {
    if (type) {
      getProductType({ id: type })
        .then(({ type }) => type)
        .then(setProductTypeInfo)
        .catch(console.error);
    }
  }, [type]);

  const defaultValues = useMemo(() => {
    if (!productTypeInfo) return;
    const { fields = {} } = productTypeInfo;
    const fieldArray = Object.entries(fields).map(
      ([i, { name, label, type }]) => ({
        name,
        type,
        label,
      })
    );
    return { ...productTypeInfo, [propertiesFieldName]: fieldArray };
  }, [productTypeInfo]);

  const postfix = useMemo(
    () => (currentLanguage ? `__${currentLanguage}` : ''),
    [currentLanguage]
  );

  const onSubmit = (data) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Saved');
        history.goBack();
      } else {
        NotificationManager.error('Error');
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    if (productTypeInfo?._id) {
      merchantUpdateProductType(
        { ...prepareToPublishProductType(productTypeInfo?._id, data) },
        errorHandler
      ).then((data) => {
        return resultHandler(data);
      });
    } else {
      merchantCreateProductType(
        merchantId,
        { ...prepareToPublishProductType(null, data) },
        errorHandler
      ).then((data) => {
        return resultHandler(data);
      });
    }
  };

  const onDelete = () => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Product deleted');
        history.goBack();
      } else {
        NotificationManager.error('Error');
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    merchantDeleteProductType(productTypeInfo._id, errorHandler)
      .then((data) => {
        return resultHandler(data);
      })
      .catch(console.error);
  };

  const pageTitle = productTypeInfo?._id
    ? 'Edit product type'
    : `Add product type `;
  const title =
    merchantId && !productTypeInfo?._id
      ? `${pageTitle} (${merchantName})`
      : pageTitle;

  return done ? (
    !type || productTypeInfo?._id ? (
      <div>
        <NotificationContainer />
        <h2>{title}</h2>
        <SmartForm
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="p-3"
        >
          <div className="form-row align-items-end">
            <div className="col-3">
              {postfixes.map((currentPostfix, index) => (
                <InputFieldWithValidation
                  key={index}
                  label={`Name${currentPostfix}`}
                  name={`name${currentPostfix}`}
                  className="p-2"
                  hidden={postfix !== currentPostfix}
                />
              ))}
            </div>
            <div className="col-6">
              {postfixes.map((currentPostfix, index) => (
                <InputFieldWithValidation
                  key={index}
                  label={`Description${currentPostfix}`}
                  name={`description${currentPostfix}`}
                  className="p-2"
                  hidden={postfix !== currentPostfix}
                />
              ))}
            </div>
            <SmartLanguageSelector
              label="Language"
              name="currentLanguage"
              setItem={setCurrentLanguage}
              items={languages}
              valueSelector="value"
              nameSelector="name"
              defaultValue={languages[0]}
              resetItem={{ name: 'Default description', value: '' }}
              className="col-2 mb-2 ml-auto"
            />
          </div>
          <Fields />
          <br />
          <button className="btn btn-primary">Submit</button>
          {productTypeInfo?._id && (
            <button
              type="button"
              className="btn btn-danger disabled ml-3"
              onClick={() => {
                showConfirmDialog();
              }}
            >
              Delete
            </button>
          )}
        </SmartForm>

        <Modal
          show={showConfirmRemoveProductType}
          onHide={handleCloseConfirmRemoveProductType}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Do you want to remove product type?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseConfirmRemoveProductType}
            >
              Close
            </Button>
            <Button variant="danger" onClick={() => onDelete()}>
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    ) : (
      'not found'
    )
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default ProductTypeEdit;
