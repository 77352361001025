import React, { useMemo, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { ListGroup, Form, InputGroup, Button } from 'react-bootstrap';
import cn from 'classnames';
import _ from 'lodash';

const CustomRegReqList = ({
    customRegReq = [],
    setCustomRegReq = () => {},
}) => {
    useEffect(() => {
        console.log('customRegReq changed: ', customRegReq);
    }, [customRegReq]);

    const types = useMemo(
        () => ['str', 'float', 'int', 'bool', 'document', 'picture'],
        []
    );
    const mandatory = useMemo(() => ['yes', 'no'], []);

    const handleAddRequirement = () => {
        setCustomRegReq([
            ...customRegReq,
            { id: '', type: 'str', name: '', mandatory: true },
        ]);
    };

    const handleChangeItem = (value, name, index) => {
        const newRegReq = _.cloneDeep(customRegReq);
        newRegReq[index][name] = value;
        setCustomRegReq(newRegReq);
    };

    const handleDeleteItem = (index) => {
        let newRegReq = _.cloneDeep(customRegReq);
        newRegReq.splice(index, 1);
        setCustomRegReq(newRegReq);
    };

    return (
        <Form.Group controlId="custom_reg_req">
            <Form.Label>Custom registration requirements</Form.Label>
            <ListGroup variant="flush">
                {!_.isEmpty(customRegReq) ? (
                    customRegReq.map((item, i) => (
                        <ListGroup.Item key={`list-item-${i}`}>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    name="id"
                                    value={item?.id || ''}
                                    onChange={(e) =>
                                        handleChangeItem(
                                            e.target.value,
                                            e.target.name,
                                            i
                                        )
                                    }
                                    placeholder="id"
                                    required
                                />
                                <Form.Control
                                    as={'select'}
                                    aria-label="requirement type"
                                    name="type"
                                    value={item?.type}
                                    onChange={(e) =>
                                        handleChangeItem(
                                            e.target.value,
                                            e.target.name,
                                            i
                                        )
                                    }
                                    className={cn('custom-select')}
                                    required
                                >
                                    <option value="">Select type</option>
                                    {types.map((item, index) => (
                                        <option
                                            key={`${index}-${item}-req-type-option`}
                                            value={item}
                                        >
                                            {item}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={item?.name || ''}
                                    onChange={(e) =>
                                        handleChangeItem(
                                            e.target.value,
                                            e.target.name,
                                            i
                                        )
                                    }
                                    placeholder="name"
                                    required
                                />
                                <Form.Control
                                    as={'select'}
                                    aria-label="requirement is mandatory"
                                    name="mandatory"
                                    value={item?.mandatory ? 'yes' : 'no'}
                                    onChange={(e) =>
                                        handleChangeItem(
                                            e.target.value === 'yes',
                                            e.target.name,
                                            i
                                        )
                                    }
                                    className={cn('custom-select')}
                                    required
                                >
                                    <option value="">Select</option>
                                    {mandatory.map((item, index) => (
                                        <option
                                            key={`${index}-${item}-req-mandatory-option`}
                                            value={String(item)}
                                        >
                                            {item}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Button
                                    size="sm"
                                    variant="outline-danger"
                                    id={`list-item-delete-${i}`}
                                    onClick={(e) => handleDeleteItem(i)}
                                >
                                    Delete
                                </Button>
                            </InputGroup>
                        </ListGroup.Item>
                    ))
                ) : (
                    <ListGroup.Item>No requirements yet...</ListGroup.Item>
                )}
                <ListGroup.Item key="action-buttons">
                    <Button
                        variant="outline-success"
                        onClick={handleAddRequirement}
                    >
                        Add
                    </Button>
                </ListGroup.Item>
            </ListGroup>
            <Form.Text className="text-muted">
                Custom registration requirements
            </Form.Text>
        </Form.Group>
    );
};

export default CustomRegReqList;
