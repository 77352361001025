import Honkio from '../../Honkio';

const setAdminConsumer = (options = {}, errorHandler = () => {}) => {
  return Honkio().mainShop.userFetch(
    'adminconsumerset',
    { ...options, version: 3 },
    errorHandler
  );
};

export const createConsumer = (options = {}, errorHandler) => {
  return setAdminConsumer({ ...options, action: 'create' }, errorHandler);
};

export const updateConsumer = (options = {}, errorHandler) => {
  return setAdminConsumer({ ...options, action: 'update' }, errorHandler);
};

export const deleteConsumer = (options = {}, errorHandler) => {
  return setAdminConsumer({ ...options, action: 'delete' }, errorHandler);
};
