import Honkio from '../../../Honkio';

const getScript = (options = {}, errorHandler = {}) => {
  const { id, debug } = options;
  return Honkio().mainShop.userFetch(
    'adminappscriptsget',
    {
      debug,
      id,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getScript;
