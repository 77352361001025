import Honkio from '../../Honkio';

// Removes the merchant and all his records from the database
const cleanOutMerchant = (id, errorHandler = () => {}) => {
    return Honkio().mainShop.userFetch(
        `merchantclean`,
        { id, action: 'delete', version: 3 },
        errorHandler
    );
};
export default cleanOutMerchant;
