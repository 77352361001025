import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import TypeSelector from '../Transactions/TypeSelector';
import Wait from '../UI/Wait';

const ConsumerTransactionsList = ({
  typeFilter,
  setTypeFilter,
  optionsType,
  shopFilter,
  setShopFilter,
  optionsShop,
  statusFilter,
  setStatusFilter,
  optionsStatus,
  transactionsList,
  setSelectedTransaction,
  loading,  
  emptyText
}) => {
  const columns = useMemo(
    () => [
      {
        name: '#',
        grow: 0,
        compact: true,
        autoWidth: true,
        width: '20px',
        cell: (_, index) => index + 1,
      },
      {
        name: 'Status',
        sortable: true,
        selector: row => row.status,
        width: '80px',
        cell: (row) => row.status,
      },
      {
        name: 'Timestamp',
        sortable: true,
        selector: row => row.timestamp,
        cell: (row) => row.timestamp,
      },
      {
        name: 'Amount',
        sortable: true,
        selector: row => row.amount,
        compact: true,
        width: '80px',
        cell: (row) => row.amount,
      },
      {
        name: 'Type',
        sortable: true,
        selector: row => row.type,
        compact: true,
        width: '80px',
        cell: (row) => row.type,
      },
      {
        name: 'Shop',
        sortable: true,
        selector: row => row.shop.name,
        cell: (row) => row.shop.name,
      }
    ],
    []
  );
  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-4">
            <TypeSelector
              type={typeFilter}
              setType={setTypeFilter}
              options={optionsType}
              title = 'Type'
            />           
          </div>
          <div className="col-4">
            <TypeSelector
              type={shopFilter}
              setType={setShopFilter}
              options={optionsShop}
              title = 'Shop'
            />           
          </div>
          <div className="col-4">
            <TypeSelector
              type={statusFilter}
              setType={setStatusFilter}
              options={optionsStatus}
              title = 'Status'
            />           
          </div>
        </div>
      </div>
      <DataTable
        dense
        striped
        responsive
        pagination={true}
        columns={columns}
        data={transactionsList}
        highlightOnHover
        onRowClicked={setSelectedTransaction}
        progressPending={loading}
        progressComponent={
          <div className="p-5">
              <Wait />
          </div>
      }
      noDataComponent={emptyText}
      />
    </div>
  );
};

export default ConsumerTransactionsList;
