import React from 'react';
import { useEffect, useMemo, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { NotificationManager } from 'react-notifications';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { Modal, Button, ButtonGroup, Form } from 'react-bootstrap';
import _ from 'lodash';

//API
import getConsumer from '../../api/utils/consumer/getConsumer';
import getConsumerApps from '../../api/utils/consumer/getConsumerApps';
import getConsumerDevice from '../../api/utils/consumer/getConsumerDevice';
import changePassword from '../../api/utils/user/changePassword';

//Custom
import useLoader from '../UI/helpers/useLoader';
import Icon from '../UI/Icon';
import useSearchString from '../../utils/useSearchString';
import languages from '../../__fixtures__/languages';
import SmartForm from '../Form/SmartForm';
import UploadFileWithPreview from '../Form/elements/UploadFileWithPreview';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import ButtonActionForm from '../Form/elements/ButtonActionForm';
import LoadingBar from '../UI/LoadingBar';
import getMerchantData from '../../api/utils/merchant/getMerchantData';
import UserPublicSettingsForm from './UserPublicSettingsForm';
import UserHomeInfoForm from './UserHomeInfoForm';
import resendUserVerification from '../../api/utils/merchant/registration/resendUserVerification';
import UserInfoContext from '../../contexts/UserInfoContext';
import UserAppsInput from './UserAppsInput';
import { updateConsumer } from '../../api/utils/consumer/setAdminConsumer';
import getAdminRolesList from '../../api/utils/role/getAdminRolesList';
import SelectRole from './SelectRole';
import { getMerchantsPreviewsList } from '../../api/utils/merchant/getMerchantList';
import ReactTooltip from 'react-tooltip';
import ConsumerInfoContext from '../../contexts/ConsumerInfoContext';
import ApplicationSelector from './components/ApplicationSelector';
import RolesSelector from './RolesSelector';
import BackButton from '../UI/BackButton';
import CheckboxSwitch from '../Form/elements/CheckboxSwitch';

const UserEdit = () => {
    const { app, merchant, setInfo } = useContext(UserInfoContext);
    const { is_superadmin, role } = useContext(ConsumerInfoContext);

    const history = useHistory();
    const [consumer, setConsumer] = useState();
    const [consumerDevices, setConsumerDevices] = useState();
    const [consumerApps, setConsumerApps] = useState();
    const [formData, setFormData] = useState();
    const [progress, done] = useLoader([formData, consumer]);
    const { id } = useSearchString();

    const [showPswrdChange, setShowPswrdChange] = useState(false);
    const handleShowPswrdChange = () => setShowPswrdChange(true);
    const handleClosePswrdChange = () => setShowPswrdChange(false);
    const [loading, setLoading] = useState();

    const [roles, setRoles] = useState([]);
    const [loadingRoles, setLoadingRoles] = useState(false);

    const [merchantsList, setMerchantsList] = useState([]);
    const [loadingMerchants, setLoadingMerchants] = useState(false);

    const [selectedApps, setSelectedApps] = useState([]); // Uses `appList` as options
    const [selectedMerchants, setSelectedMerchants] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    // Home location coordinates
    const [homeLat, setHomeLat] = useState('0.0');
    const [homeLon, setHomeLon] = useState('0.0');

    const [forceVerify, setForceVerify] = useState(false);

    let isMounted = true;
    let timezones = moment.tz.names(); //To get list of timezone

    const devicesColumns = useMemo(
        () => [
            { name: '#', cell: (_, index) => index + 1 },
            { name: 'Name', cell: ({ name }) => name },
            { name: 'Type', cell: ({ type }) => type },
        ],
        []
    );

    const appsColumns = useMemo(
        () => [
            { name: '#', cell: (_, index) => index + 1 },
            { name: 'Name', cell: ({ name }) => name },
            { name: 'Class', cell: ({ class: cls }) => cls },
            { name: 'Active', cell: ({ active }) => active?.toString() },
        ],
        []
    );

    useEffect(() => {
        const h_location = consumer?.settings?.home_location;
        if (h_location) {
            try {
                setHomeLat(String(h_location[0]));
                setHomeLon(String(h_location[1]));
            } catch (error) {
                setHomeLat('0.00');
                setHomeLon('0.00');
            }
        }
    }, [consumer]);

    useEffect(() => {
        if (app?.id && merchant?.id && consumer?._id) {
            fetchRoles();
        }
    }, [app, merchant, consumer]);

    useEffect(() => {
        const resultHandler = (data) => {
            setConsumer(data.consumer);
        };

        const errorHandler = (error) => {
            setConsumer({});
            NotificationManager.error(error.description, 'Error', 4000);
        };

        getConsumer({ id }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    }, [id]);

    useEffect(() => {
        getConsumerDevice({ id })
            .then(({ devices }) => devices)
            .then(setConsumerDevices);
    }, [id]);

    useEffect(() => {
        getConsumerApps({ id })
            .then(({ apps }) => apps)
            .then(setConsumerApps);
    }, [id]);

    useEffect(() => {
        if (consumer && Object.keys(consumer).length !== 0) {
            setFormData(consumer);
        } else {
            setFormData(null);
        }
    }, [consumer]);

    useEffect(() => {
        if (consumer?.merchants) {
            fetchMerchantDetails(consumer?.merchants);
        }
    }, [consumer]);

    useEffect(() => {
        if (app?.id) {
            fetchMerchants();
        }
    }, [app]);

    const prepareAppsData = () => {
        if (is_superadmin) {
            const newApps = {
                apps: selectedApps,
                merchants: selectedMerchants,
                roles: selectedRoles.map((item) => item._id),
            };
            return newApps;
        }

        const appRolesIdsList = roles.map((item) => item._id);
        const appMerchantIdsList = merchantsList.map((item) => item._id);
        if (!selectedRoles.length) {
            // admin removed current app roles from consumer's roles
            const resultingApps = {
                apps: consumer.apps.apps.filter((item) => item !== app.id),
                merchants: consumer.apps.merchants.filter(
                    (item) => !appMerchantIdsList.includes(item)
                ),
                roles: consumer.apps.roles.filter(
                    (item) => !appRolesIdsList.includes(item)
                ),
            };
            return resultingApps;
        }

        try {
            const resultingData = {
                apps: [],
                merchants: [],
                roles: [],
            };
            resultingData.apps = [...new Set([...consumer.apps.apps, app.id])];
            resultingData.merchants = [
                ...new Set([...consumer.apps.merchants, merchant.id]),
            ];
            resultingData.roles = [
                ...new Set([
                    ...consumer.apps.roles.filter(
                        (item) => !appRolesIdsList.includes(item)
                    ),
                    ...selectedRoles.map((item) => item._id),
                ]),
            ];
            return resultingData;
        } catch (error) {
            console.log(error);
            return consumer.apps;
        }
    };

    const fetchRoles = async () => {
        const resultHandler = (res) => {
            setLoadingRoles(false);
            if (res?.status === 'accept' && res?.roles?.length) {
                setRoles(res.roles);
                if (consumer?.apps?.roles && consumer?.apps?.roles?.length) {
                    const newSelectedRoles = res.roles.filter((item) =>
                        consumer.apps.roles.includes(item._id)
                    );
                    setSelectedRoles(newSelectedRoles);
                }
                if (consumer?.apps?.apps && consumer?.apps?.apps?.length) {
                    setSelectedApps(consumer.apps.apps);
                }
                if (
                    consumer?.apps?.merchants &&
                    consumer?.apps?.merchants?.length
                ) {
                    setSelectedMerchants(consumer.apps.merchants);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoadingRoles(false);
            setRoles([]);
        };

        try {
            setLoadingRoles(true);
            const res = await getAdminRolesList(
                {
                    query_merchants: is_superadmin
                        ? consumer.apps.merchants
                        : [merchant.id],
                    appid: is_superadmin ? null : app.id,
                    query_skip: 0,
                    query_count: 10000,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoadingRoles(false);
            setRoles([]);
            console.log('error fetching roles: ', error);
        }
    };

    const fetchMerchants = async () => {
        const resultHandler = (res) => {
            setLoadingMerchants(false);
            if (res && res?.status === 'accept') {
                setMerchantsList(res.merchants);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoadingMerchants(false);
            setMerchantsList([]);
        };

        try {
            setLoadingMerchants(true);
            const res = await getMerchantsPreviewsList(
                {
                    query_application: app.id,
                    query_skip: 0,
                    query_count: 10000,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoadingMerchants(false);
            setMerchantsList([]);
            console.log('error fetching merchants: ', error);
        }
    };

    const onSubmit = (data) => {
        const appsData = prepareAppsData();

        if (_.isEmpty(appsData?.apps)) {
            NotificationManager.error('Please, select user apps');
            return;
        }
        if (_.isEmpty(appsData?.merchants)) {
            NotificationManager.error('Please, select user merchants');
            return;
        }
        if (_.isEmpty(appsData?.roles)) {
            NotificationManager.error('Please, select user roles');
            return;
        }

        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Saved');
                setConsumer(data.user);
            } else {
                NotificationManager.error('Error');
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        let submitData = { ...data };

        if (appsData) {
            submitData['apps'] = appsData;
        } else {
            submitData['apps'] = {};
        }

        if (homeLat && homeLon) {
            submitData['settings']['home_location'] = [
                parseFloat(homeLat),
                parseFloat(homeLon),
            ];
        }

        updateConsumer({ id, ...submitData }, errorHandler).then((res) => {
            return resultHandler(res);
        });
    };

    let onChangePswrdClick = () => {
        handleShowPswrdChange();
    };

    const onSubmitPassword = (data) => {
        if (data?.str_new_password1 !== data?.str_new_password2) {
            NotificationManager.error('Passwords dont match!', 'Error', 4000);
            return;
        }

        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'New password saved',
                    'Success',
                    4000
                );
            }
            handleClosePswrdChange();
        };
        const errorHandler = (error) => {
            if (error) {
                NotificationManager.error(error.description, 'Error', 4000);
            }
        };
        changePassword(
            {
                user_id: consumer._id,
                password: data.str_new_password1,
            },
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };

    const fetchMerchantDetails = async (mid) => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data?.status === 'accept' && data?.merchant) {
                } else {
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        getMerchantData({ id: mid }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const handleResendVerification = (verification_method) => {
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
        };
        const user = consumer;

        if (verification_method === 'email') {
            resendUserVerification(
                {
                    verification_method,
                    email: user.email,
                    application_id: app.id,
                    force: forceVerify,
                },
                errorHandler
            ).then((res) => {
                if (res.status === 'accept' && res.result) {
                    NotificationManager.success(
                        res.message || 'Resent successfully!',
                        'Success',
                        4000
                    );
                } else {
                    NotificationManager.error(
                        res.message || 'Error resending verification',
                        'Error',
                        4000
                    );
                }
            });
        }
    };

    const handleSaveUserApps = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('User apps saved', 'Success', 4000);
                setConsumer(data.user);
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            console.log('error updating user apps', error);
        };

        let submitData = { ...consumer };

        submitData['apps'] = {
            apps: selectedApps,
            merchants: selectedMerchants,
            roles: selectedRoles.map((item) => item._id),
        };

        try {
            const res = await updateConsumer(
                { id, ...submitData },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log(error);
        }
    };

    return done ? (
        formData ? (
            <div>
                <NotificationContainer />
                <SmartForm
                    onValid={() => {}}
                    onSubmit={onSubmit}
                    defaultValues={formData}
                >
                    <h3 className="d-flex justify-content-between">
                        <span>
                            <BackButton to="/users" text="Back to users list" />
                            Edit User Info (ID#{id})
                        </span>
                        <span>
                            Serial number:{' '}
                            {consumer?.serial_number || 'No number'}
                        </span>
                    </h3>
                    <Link
                        to={`/user/transactions?consumerId=${consumer?._id}`}
                        className="btn btn-primary"
                    >
                        Show transactions
                    </Link>
                    <button
                        className="btn btn-warning m-2"
                        type="button"
                        onClick={onChangePswrdClick}
                    >
                        Change password
                    </button>
                    <hr />
                    <h5 className="mt-3">Resend verifications</h5>
                    <div className="row">
                        <div className="col-10">
                            <ButtonGroup
                                aria-label="verifications_resend"
                                style={{ gap: '0.2rem' }}
                            >
                                <Button
                                    variant="primary"
                                    onClick={(e) =>
                                        handleResendVerification('email')
                                    }
                                >
                                    Verify email
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={(e) =>
                                        handleResendVerification('sms')
                                    }
                                >
                                    Verify phone
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={(e) =>
                                        handleResendVerification('telia')
                                    }
                                >
                                    Verify with Telia
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div className="col-2">
                            <Form.Check
                                className="d-block"
                                type="switch"
                                id="force_resend_verification"
                                label="Force"
                                value={forceVerify}
                                onChange={(e) => {
                                    setForceVerify(e.target.checked);
                                }}
                            />
                        </div>
                    </div>

                    <hr />
                    <div className="container">
                        <div className="row row-cols-2">
                            <div className="col">
                                <h5>Devices</h5>
                                <DataTable
                                    dense
                                    striped
                                    highlightOnHover
                                    data={consumerDevices}
                                    columns={devicesColumns}
                                />
                            </div>
                            <div className="col">
                                <h5>Applications</h5>
                                <DataTable
                                    dense
                                    striped
                                    highlightOnHover
                                    data={consumerApps}
                                    columns={appsColumns}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-12">
                                <InputFieldWithValidation
                                    type="number"
                                    min={0}
                                    step={1}
                                    className="mb-3"
                                    label="Serial number (demo only)"
                                    name="serial_number"
                                />
                            </div>
                            <div className="col-2">
                                <UploadFileWithPreview
                                    name="settings.str_photo_data"
                                    style={{ maxHeight: '100px' }}
                                    type="text"
                                />
                            </div>
                            <div className="col col-10">
                                {is_superadmin ? (
                                    <RolesSelector
                                        selectedApps={selectedApps}
                                        setSelectedApps={setSelectedApps}
                                        selectedMerchants={selectedMerchants}
                                        setSelectedMerchants={
                                            setSelectedMerchants
                                        }
                                        selectedRoles={selectedRoles}
                                        setSelectedRoles={setSelectedRoles}
                                        saveUserApps={handleSaveUserApps}
                                    />
                                ) : (
                                    <SelectRole
                                        roles={roles}
                                        selected={selectedRoles}
                                        onChange={(val) =>
                                            setSelectedRoles(val)
                                        }
                                        required={true}
                                        loading={loadingRoles}
                                    />
                                )}

                                <Button
                                    variant="link"
                                    data-tip
                                    data-for={`user-apps-tip`}
                                >
                                    <Icon
                                        color="var(--primary)"
                                        iconName="bi-info-circle"
                                    />
                                </Button>

                                <ReactTooltip
                                    id={`user-apps-tip`}
                                    place="top"
                                    effect="solid"
                                >
                                    <pre className="text-muted">
                                        {/* TODO: Render Apps/Merchants/Roles names instead of ids */}
                                        {JSON.stringify(consumer.apps, null, 4)}
                                    </pre>
                                </ReactTooltip>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-2" />

                            <div className="col-5">
                                <CheckboxSwitch
                                    name="active"
                                    label="Active"
                                    className="mb-3"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2" />
                            <div className="col-5">
                                <InputFieldWithValidation
                                    className="mb-3"
                                    label="Email"
                                    name="email"
                                />
                                <InputFieldWithValidation
                                    className="mb-3"
                                    label="First name"
                                    name="settings.str_firstname"
                                />
                                <InputFieldWithValidation
                                    className="mb-3"
                                    label="Last name"
                                    name="settings.str_lastname"
                                />
                                <InputFieldWithValidation
                                    className="mb-3"
                                    label="Telephone"
                                    name="settings.str_telephone"
                                />
                                <SelectFieldWithValidation
                                    className="mb-3"
                                    label="Language"
                                    name="settings.str_language"
                                    options={languages}
                                />
                                <SelectFieldWithValidation
                                    className="mb-3"
                                    label="Timezone"
                                    options={timezones}
                                    name="settings.str_timezone"
                                />
                                {/* <InputFieldWithValidation
                                    label="Ssn"
                                    name="settings.str_ssn"
                                    className="mb-3"
                                /> */}
                                <InputFieldWithValidation
                                    label="IBAN"
                                    name="IBAN_Number"
                                    className="mb-3"
                                />
                            </div>
                            <div className="col-5">
                                <InputFieldWithValidation
                                    className="mb-3"
                                    label="Street Address"
                                    name="settings.str_address1"
                                />
                                <InputFieldWithValidation
                                    className="mb-3"
                                    label="Address additional"
                                    name="settings.str_address2"
                                />
                                <InputFieldWithValidation
                                    className="mb-3"
                                    label="City"
                                    name="settings.str_city"
                                />
                                <InputFieldWithValidation
                                    className="mb-3"
                                    label="Zip"
                                    name="settings.str_zip"
                                />
                                <SelectFieldWithValidation
                                    className="mb-3"
                                    label="Country"
                                    name="settings.str_country"
                                    options={['ENG', 'RUS', 'FIN']}
                                />
                                <InputFieldWithValidation
                                    label="Bank name"
                                    name="bank_name"
                                    className="mb-3"
                                />
                                <InputFieldWithValidation
                                    className="mb-3"
                                    label="Balance"
                                    value={
                                        consumer?.balance
                                            ? `€${consumer.balance}`
                                            : 0
                                    }
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <hr />
                        <h5>Home location info</h5>
                        <div className="row">
                            <div className="col-12">
                                <UserHomeInfoForm
                                    settings={consumer?.settings}
                                    lat={homeLat}
                                    lon={homeLon}
                                    setLat={setHomeLat}
                                    setLon={setHomeLon}
                                />
                            </div>
                        </div>

                        <hr />
                        <h5>Privacy settings</h5>
                        <div className="row">
                            <div className="col-12">
                                <UserPublicSettingsForm
                                    label="User settings public access"
                                    className="my-3"
                                    settings={consumer?.settings}
                                />
                            </div>
                        </div>
                    </div>
                    <ButtonActionForm
                        label="Save changes"
                        name="action"
                        value={true}
                        type="submit"
                        className="btn btn-primary mt-2"
                    />
                    <br />
                </SmartForm>
                <Modal show={showPswrdChange} onHide={handleClosePswrdChange}>
                    <SmartForm onSubmit={onSubmitPassword}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <InputFieldWithValidation
                                            className="mb-3"
                                            name="str_new_password1"
                                            label="New password"
                                            type="password"
                                            required
                                        />
                                        <InputFieldWithValidation
                                            className="mb-3"
                                            name="str_new_password2"
                                            label="Repeat new password"
                                            type="password"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonActionForm
                                label="Update password"
                                name="active"
                                value={true}
                                type="submit"
                            />
                        </Modal.Footer>
                    </SmartForm>
                </Modal>
            </div>
        ) : (
            'not found'
        )
    ) : (
        <LoadingBar progress={progress} />
    );
};

export default UserEdit;
