import { useState, useEffect, useCallback, useContext } from 'react';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import { NotificationManager } from 'react-notifications';
import {
  getAssetTypeScheme,
  setAssetTypeScheme,
} from '../../api/utils/asset/type/assetTypeHierarchy';
import UserInfoContext from '../../contexts/UserInfoContext';
import AssetTypesSchemeEditForm from './AssetTypesSchemeEditForm';

const AssetTypesScheme = () => {
  const { app, merchant } = useContext(UserInfoContext);
  const merchantId = merchant?.id;
  const appId = app?.id;

  const pageTitle = `Asset types scheme (${appId ? app.name : '-'} / ${
    merchantId ? merchant.name : '-'
  })`;

  const [assetTypesScheme, setAssetTypesScheme] = useState(null);

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    fetchAssetTypesScheme();
  }, [merchantId, appId]);

  const fetchAssetTypesScheme = async () => {
    const resultHandler = (data) => {
      if (isMounted) {
        if (data && data.status === 'accept' && data.asset_types_hierarchy) {
          setAssetTypesScheme(data.asset_types_hierarchy);
        }
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    if (appId) {
      getAssetTypeScheme(appId, merchantId, {}, errorHandler).then((data) => {
        return resultHandler(data);
      });
    } else {
      setAssetTypesScheme([]);
    }
  };

  const onSave = useCallback((scheme) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        setAssetTypesScheme(data.asset_types_hierarchy);
        NotificationManager.success('Asset type scheme saved', 'Success', 4000);
      } else {
        NotificationManager.error('Unknown error', 'Error', 4000);
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    setAssetTypeScheme(appId, merchantId, scheme, errorHandler).then((data) => {
      return resultHandler(data);
    });
  });

  return (
    <div className="w-100 h-50 flex-column">
      <NotificationContainer />
      <h2>{pageTitle}</h2>
      {assetTypesScheme && (
        <AssetTypesSchemeEditForm
          onSave={onSave}
          assetTypesScheme={assetTypesScheme}
        />
      )}
    </div>
  );
};

export default AssetTypesScheme;
