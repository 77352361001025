import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import FeatureItem from './FeatureItem';
import Icon from '../../../UI/Icon';
import { v4 as uuidv4 } from 'uuid';

const PlanFeaturesList = ({ item, idx }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `pricing_plans[${idx}].features`,
  });

  useWatch({ name: `pricing_plans[${idx}].features` });

  const handleDeleteFeatureItem = (index) => {
    remove(index);
  };

  const handleAddFeatureItem = () => {
    append({ feature_id: uuidv4(), value: 'Feature 101' });
  };

  return (
    <>
      <div className="row">
        <div className="col d-flex align-items-center">
          <h5>Features</h5>
        </div>
        <div>
          <Button
            onClick={handleAddFeatureItem}
            variant="outline"
            className="shadow-none"
          >
            <Icon iconName="bi-plus-circle" size="1" />
          </Button>
        </div>
      </div>
      <ListGroup>
        {fields.length ? (
          fields.map((feat, index) => (
            <FeatureItem
              key={feat.id}
              idx={idx}
              index={index}
              feature={feat.value}
              onDelete={handleDeleteFeatureItem}
            />
          ))
        ) : (
          <ListGroup.Item>No features added yet...</ListGroup.Item>
        )}
      </ListGroup>
    </>
  );
};

export default PlanFeaturesList;
