import Honkio from '../../Honkio';

const setEvent = (options = {}, errorHandler = () => {}) => {
    const {
        id,
        name,
        assets,
        start_date,
        end_date,
        visible,
        coordinates,
        address,
        owner,
        action,
        merchant,
        application,
    } = options;
    return Honkio().mainShop.userFetch(
        'eventassetset',
        {
            id,
            name,
            assets,
            start_date,
            end_date,
            owner,
            visible,
            address,
            coordinates,
            merchant,
            application,
            action,
            version: 3,
        },
        errorHandler
    );
};

export const createEvent = (options = {}, errorHandler = () => {}) => {
    return setEvent({ ...options, action: 'create' }, errorHandler);
};

export const editEvent = (options = {}, errorHandler = () => {}) => {
    return setEvent({ ...options, action: 'update' }, errorHandler);
};
export const deleteEvent = (options = {}, errorHandler = () => {}) => {
    return setEvent({ ...options, action: 'delete' }, errorHandler);
};
