import Honkio from '../Honkio';

const getAdminAccesses = (options = {}, errorHandler = {}) => {
  const { appid, debug } = options;
  return Honkio().mainShop.userFetch(
    'adminaccessesget',
    {
      debug,
      appid,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};
export default getAdminAccesses;
