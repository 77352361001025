import Honkio from '../../../Honkio';

const listUserDevices = async function (options = {}, errorHandler = {}) {
    const { user_id, query_merchant, query_application } = options;

    return Honkio().mainShop.userFetch(
        'userdevicelist',
        {
            query_owner: user_id,
            query_merchant,
            query_application,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};

export default listUserDevices;
