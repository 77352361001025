const defaultStatus = {
  allow: { edit_amount: false, edit_products: false },
  auth: [],
  can_change_to: [],
  capture: [],
  chargeback: [],
  position: [
    {
      x: 0,
      y: 0,
    },
  ],
  reserve: [],
  sales_point: [],
  public: [],
  bundled_products: [],
  send_push: { owner: null, third_person: null },
  send_message: { owner: null, third_person: null },
  service_models: [],
};

const makeStatus = ({
  data: {
    options: {
      edit_amount,
      edit_products,
      reserve,
      sales_point,
      public: pb,
      bundled_products,
      send_message,
      send_push,
      service_models,
      velocity_rules,
    },
    settings: { auth, capture, chargeback },
  },
  position,
  can_change_to,
}) => {
  const result = {
    allow: { edit_amount, edit_products },
    auth,
    can_change_to,
    capture,
    chargeback,
    position: { left: Math.round(position.x), top: Math.round(position.y) },
    reserve,
    sales_point,
    public: pb,
    send_push,
    bundled_products,
    send_message,
    service_models,
    velocity_rules,
  };
  return result;
};

const getStatus = (data) => {
  const result = Object.assign({}, defaultStatus, makeStatus(data));
  return result;
};

export default getStatus;
