import Honkio from '../../Honkio';

const setGlobalSettings = async function (options = {}, errorHandler = {}) {
  return Honkio().mainShop.userFetch(
    'adminsettingsset',
    {
      ...options,
      action: 'update',
      version: 3,
    },
    errorHandler
  );
};

export default setGlobalSettings;
