import React, { useEffect, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import cn from 'classnames';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';

const CustomRegReqFields = ({
    fields,
    onFieldChange,
    onFieldAdd,
    onFieldDelete,
}) => {
    const types = useMemo(
        () => ['str', 'float', 'int', 'bool', 'document', 'picture'],
        []
    );
    const mandatory = useMemo(() => ['yes', 'no'], []);

    const renderFields = (fieldsArray) => {
        return fieldsArray.map((item, i) => (
            <Row
                key={`custom-reg-req-field-${i}`}
                className={cn('align-items-center', 'mb-2')}
            >
                <Col xs={4}>
                    <Form.Control
                        id="id"
                        name="id"
                        placeholder="custom_requirement"
                        value={item.id}
                        onChange={(e) => onFieldChange(e, i)}
                    />
                </Col>

                <Col xs={2}>
                    <Form.Control
                        id="type"
                        as={'select'}
                        aria-label="Regitstration requirement type"
                        placeholder="type"
                        defaultValue={types[0]}
                        value={item.type}
                        name="type"
                        className="custom-select"
                        required
                        onChange={(e) => onFieldChange(e, i)}
                    >
                        <option value="">&mdash; Type &mdash;</option>
                        {types.map((item, index) => (
                            <option key={`${index}-${item}-type`} value={item}>
                                {item}
                            </option>
                        ))}
                    </Form.Control>
                </Col>

                <Col xs={2}>
                    <Form.Control
                        id="name"
                        name="name"
                        placeholder="name"
                        value={item.name}
                        onChange={(e) => onFieldChange(e, i)}
                    />
                </Col>

                <Col xs={2}>
                    <Form.Control
                        id="mandatory"
                        as={'select'}
                        aria-label="Regitstration requirement is mandatory"
                        placeholder="mandatory"
                        defaultValue={mandatory[0]}
                        value={item.mandatory ? mandatory[0] : mandatory[1]}
                        onChange={(e) => onFieldChange(e, i)}
                        name="mandatory"
                        className="custom-select"
                        required
                    >
                        <option value="">&mdash; mandatory &mdash;</option>
                        {mandatory.map((item, index) => (
                            <option
                                key={`${index}-${item}-mandatory`}
                                value={item}
                            >
                                {item}
                            </option>
                        ))}
                    </Form.Control>
                </Col>

                <Col xs={2}>
                    <div
                        onClick={() => {
                            onFieldDelete(i);
                        }}
                        data-tip
                        data-for="deleteTip"
                        className={cn('text-danger')}
                    >
                        <Icon
                            iconName="btn bi-trash"
                            className={cn('text-danger')}
                        />
                        <ReactTooltip id="deleteTip" place="top" effect="solid">
                            Delete
                        </ReactTooltip>
                    </div>
                </Col>
            </Row>
        ));
    };

    return (
        <Container className={cn('my-3')}>
            <Row
                key={`custom-reg-req-head`}
                className={cn('align-items-center', 'mb-2')}
            >
                <Col xs={4}>ID</Col>

                <Col xs={2}>Type</Col>

                <Col xs={2}>Name</Col>

                <Col xs={2}>Mandatory</Col>

                <Col xs={2}>Action</Col>
            </Row>
            {fields.length ? (
                renderFields(fields)
            ) : (
                <div
                    className={cn(
                        'my-3',
                        'd-flex',
                        'justify-contente-center',
                        'align-items-center',
                        'text-center',
                        'text-muted'
                    )}
                >
                    No custom requirements yet...
                </div>
            )}

            <Button onClick={onFieldAdd} type="button" variant="primary">
                Add requirement
            </Button>
        </Container>
    );
};

export default CustomRegReqFields;
