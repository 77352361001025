const prepareToPublish = (merchantInfo) => {
    const {
        active,
        billing_start_date,
        Bank_name,
        IBAN_Number,
        Minimum_balance,
    } = merchantInfo;
    const {
        str_name,
        str_businessid,
        str_email,
        str_telephone,
        str_logo,
        str_address1,
        str_address2,
        str_zip,
        str_city,
        str_timezone,
        str_vatnumber,
        telia_error_url,
        telia_success_url,
        is_telia_enabled,
        photos_upload_url,
        enable_roles_mapper,
        roles_mapper,
        private_key,
        public_key,
    } = merchantInfo.settings;
    const merchant = {
        ...merchantInfo,
        active,
        billing_start_date,
        Bank_name,
        IBAN_Number,
        Minimum_balance,
        settings: {
            str_name,
            str_businessid,
            str_vatnumber,
            str_email,
            str_telephone,
            str_logo,
            str_address1,
            str_address2,
            str_zip,
            str_city,
            str_timezone,
            str_country: merchantInfo.settings.str_country,
            telia_error_url,
            telia_success_url,
            is_telia_enabled,
            photos_upload_url,
            enable_roles_mapper,
            roles_mapper,
            private_key,
            public_key,
        },
    };

    return { ...merchant };
};

export default prepareToPublish;
