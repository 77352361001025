import React, { useEffect, useState } from 'react';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import modelNames from '../../../__fixtures__/modelNames';
import Select from 'react-select';
import RoleSelect from '../../common/RoleSelect';
import _ from 'lodash';
import getAccessRule from '../../../api/utils/accessRules/getAccessRule';
import { Form, Button } from 'react-bootstrap';
import ConditionsList from './ConditionsList';
import LoadingBar from '../../UI/LoadingBar';
import useLoader from '../../UI/helpers/useLoader';

const ACTION_TYPE_OPTS = [
  {
    value: 'create',
    label: 'Create',
  },
  {
    value: 'read',
    label: 'Read',
  },
  {
    value: 'update',
    label: 'Update',
  },
  {
    value: 'delete',
    label: 'Delete',
  },
];

const AccessRuleForm = ({ rule_id, onSubmit, submitLoading }) => {
  const [selectedActionTypes, setSelectedActionTypes] = useState(null);

  const [accessRule, setAccessRule] = useState({});

  const [loading, setLoading] = useState(false);

  const [progress, done] = useLoader([accessRule]);

  const handleChangeAccessRule = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    setAccessRule((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    await onSubmit(accessRule);

    if (rule_id) fetchAccessRule(rule_id);
  };

  useEffect(() => {
    if (rule_id) {
      fetchAccessRule(rule_id);
    }
  }, [rule_id]);

  const fetchAccessRule = async (id) => {
    setLoading(true);
    const errorHandler = (error) => {
      NotificationManager.error(error.description);
    };

    const resultHandler = (data) => {
      if (data && data.status === 'accept' && data.access_rule) {
        if (data?.access_rule?.actions)
          setSelectedActionTypes(
            data.access_rule.actions.map((item) => ({
              label: item,
              value: item,
            }))
          );
        setAccessRule(data.access_rule);
        setLoading(false);
      } else {
        setLoading(false);
        setAccessRule(null);
      }
    };

    const res = await getAccessRule({ id }, errorHandler);

    resultHandler(res);
  };

  const handleAddCondition = (condition) => {
    setAccessRule((prev) => {
      if (prev?.conditions?.length) {
        return {
          ...prev,
          conditions: [...prev?.conditions, condition],
        };
      } else {
        return {
          ...prev,
          conditions: [condition],
        };
      }
    });
  };

  const handleRemoveCondition = (id) => {
    setAccessRule((prev) => {
      let newConditions = _.cloneDeep(prev?.conditions || []);
      newConditions = newConditions.filter((item) => item?.id !== id);
      return {
        ...prev,
        conditions: newConditions,
      };
    });
  };

  const handleEditConditionField = (e, item_id) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setAccessRule((prev) => {
      let newConditions = _.cloneDeep(prev?.conditions || []);
      const conditionIdx = newConditions.findIndex(
        (item) => item?.id === item_id
      );
      newConditions[conditionIdx][fieldName] = fieldValue;

      return {
        ...prev,
        conditions: newConditions,
      };
    });
  };

  return done ? (
    <Form onSubmit={handleSubmitForm}>
      <Form.Group className="mb-3" controlId="data.name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="test_model_access"
          name="name"
          value={accessRule?.name || ''}
          onChange={handleChangeAccessRule}
        />
        <Form.Text className="text-muted">Name of the access rule</Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="data.description">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="This access rule is for..."
          name="description"
          value={accessRule?.description || ''}
          onChange={handleChangeAccessRule}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="data.description">
        <Form.Label>Model name</Form.Label>
        <Form.Control
          as={'select'}
          aria-label="Model name"
          name="model_name"
          value={accessRule?.model_name || ''}
          onChange={handleChangeAccessRule}
          className="custom-select"
        >
          <option value="">&mdash; Select model name &mdash;</option>
          {modelNames.map((item, index) => (
            <option
              key={`${index}-${item?.value}-model-name-option`}
              value={item?.value}
            >
              {item?.name}
            </option>
          ))}
        </Form.Control>
        <Form.Text className="text-muted">
          Database model to which this rule will be applied
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="data.description">
        <Form.Label>Actions</Form.Label>
        <Select
          id="action-type-select"
          value={selectedActionTypes}
          onChange={(val) => {
            setSelectedActionTypes(val);
            return handleChangeAccessRule({
              target: {
                name: 'actions',
                value: val.map((item) => item.value),
              },
            });
          }}
          isMulti
          options={ACTION_TYPE_OPTS}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder={'— Select action types —'}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
        />
        <Form.Text className="text-muted">
          CRUD actions to use this rule
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="data.description">
        <Form.Label>Global</Form.Label>
        <Form.Check // prettier-ignore
          type="switch"
          name="is_global"
          id="is_global"
          checked={accessRule?.is_global || false}
          onChange={(e) =>
            handleChangeAccessRule({
              target: { name: 'is_global', value: !accessRule?.is_global },
            })
          }
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="data.description">
        <Form.Label>Active</Form.Label>
        <Form.Check // prettier-ignore
          type="switch"
          name="is_active"
          id="is_active"
          checked={accessRule?.is_active || false}
          onChange={(e) =>
            handleChangeAccessRule({
              target: { name: 'is_active', value: !accessRule?.is_active },
            })
          }
        />
      </Form.Group>

      <RoleSelect
        isPlain={true}
        selectedRoles={accessRule?.roles}
        setSelectedRoles={(val) =>
          handleChangeAccessRule({ target: { name: 'roles', value: val } })
        }
      />

      <ConditionsList
        className="mb-3"
        accessRule={accessRule}
        addCondition={handleAddCondition}
        removeCondition={handleRemoveCondition}
        editConditionField={handleEditConditionField}
      />

      <Button type="submit" variant="primary" disabled={submitLoading}>
        Submit
      </Button>
    </Form>
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default AccessRuleForm;
