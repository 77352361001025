import Honkio from '../../Honkio';

const deleteMerchant = (id, errorHandler = () => {}) => {
    return Honkio().mainShop.userFetch(
        `merchantdelete`,
        { id, action: 'delete', version: 3 },
        errorHandler
    );
};
export default deleteMerchant;
