import Honkio from '../../../Honkio';

const getNotifymessageTemplate = (options = {}, errorHandler = () => {}) => {
    const { id, merchant, application } = options;
    return Honkio().mainShop.userFetch(
        'admintemplatemodelsnotifymessageget',
        {
            id,
            merchant,
            application,
            action: 'read',
            version: 3,
        },
        errorHandler,
        true
    );
};

export default getNotifymessageTemplate;
