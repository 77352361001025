import { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import UserWidget from './UserWidget';

const ButtonSignInOut = () => {
  const { isAuth, authURL } = useContext(AuthContext);
  const component = isAuth ? (
    <UserWidget />
  ) : (
    <a href={authURL} className="btn btn-primary">
      Sign in
    </a>
  );
  return component;
};

export default ButtonSignInOut;
