import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import Icon from '../UI/Icon';

const ProductsItemInput = ({
    product = { id: '', name: '', quantity: 0 },
    onDelete,
    onEdit = () => console.log('not implemented'),
    onClick = () => {
        console.log('on click');
    },
}) => {
    const handleChangeProduct = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        onEdit({ ...product, [fieldName]: value });
    };

    return (
        <InputGroup className="mb-3">
            <InputGroup.Text
                className={cn('flex-grow-1', 'crsr')}
                onClick={onClick}
            >
                {product.name}
            </InputGroup.Text>

            <Form.Control
                className={cn('w-25')}
                type="number"
                placeholder="Product quantity"
                name="quantity"
                value={product?.quantity}
                onChange={handleChangeProduct}
                style={{ maxWidth: '5rem' }}
            />

            <div
                onClick={() => {
                    onDelete(product.id);
                }}
                data-tip
                data-for="deleteTip"
                className={cn(
                    'd-flex',
                    'justify-content-center',
                    'align-items-center'
                )}
            >
                <Icon
                    iconName="btn bi-trash"
                    color="var(--danger)"
                    colorOnHover="var(--danger)"
                />
                <ReactTooltip id="deleteTip" place="top" effect="solid">
                    Delete
                </ReactTooltip>
            </div>
        </InputGroup>
    );
};

export default ProductsItemInput;
