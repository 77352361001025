import { useEffect, useState, useContext } from 'react';
import UserInfoContext from '../../../../contexts/UserInfoContext';
import InlineSearch from '../../../UI/InlineSearch';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import { Col, Row, Container } from 'react-bootstrap';
import getMerchantPSPsPayments from '../../../../api/utils/payments/getMerchantPSPsPayments';
import PSPsORdersList from './PSPsOrdersList';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const PSPsPayments = () => {
    const [keyword, setKeyword] = useState('');
    const [pspsPayments, setPspsPayments] = useState([]);
    const [totalRows, setTotalRows] = useState();

    const [loading, setLoading] = useState(false);

    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);

    useEffect(() => {
        fetchPSPSOrders(1);
    }, [keyword, merchantId, perPage]);

    const fetchPSPSOrders = async (page = 1) => {
        const resultHandler = (data) => {
            if (data && data?.orders) {
                setPspsPayments(data.orders);
                setTotalRows(data.total);
            }
            setLoading(false);
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error');
            setLoading(false);
            setPspsPayments([]);
            setTotalRows(0);
        };

        try {
            setLoading(true);
            const res = await getMerchantPSPsPayments(
                {
                    query_merchant: merchantId,
                    query_application: appId,
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                    query_search: keyword,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            setPspsPayments([]);
            setTotalRows(0);
            console.log(
                'Unexpected error fetching psps transaction orders: ',
                error
            );
        }
    };

    const handlePageChange = (page) => {
        fetchPSPSOrders(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    return (
        <>
            <NotificationContainer />

            <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <InlineSearch
                            keyword={keyword}
                            setKeyword={setKeyword}
                        />
                    </Col>
                </Row>
            </Container>

            <Row>
                <Col sm={12}>
                    <PSPsORdersList
                        data={pspsPayments}
                        loading={loading}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        rowsCountPerPage={perPage}
                        handleRowsPerPage={handleRowsPerPage}
                        merchant={merchantId}
                        emptyText={'No PSPS transactions yet...'}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PSPsPayments;
