import { useEffect, useState } from 'react';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import getAdminVelocityRulesList from '../../api/utils/velocityRule/getAdminVelocityRulesList';
import VelocityRulesList from './VelocityRulesList';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, Button } from 'react-bootstrap';
import VelocityRuleModal from './VelocityRuleModal';
import activateVelocityRule from '../../api/utils/velocityRule/activateVelocityRule';
import { deleteVelocityRule } from '../../api/utils/velocityRule/setAdminVelocityRule';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const VelocityRules = () => {
  const pageTitle = 'Velocity Rules';

  const [velocityRules, setVelocityRules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmRemoveVelocityRule, setShowConfirmRemoveVelocityRule] =
    useState(false);
  const handleShowConfirmRemoveVelocityRule = () =>
    setShowConfirmRemoveVelocityRule(true);
  const handleCloseConfirmRemoveVelocityRule = () =>
    setShowConfirmRemoveVelocityRule(false);
  const [velocityRuleToRemove, setVelocityRuleToRemove] = useState({});

  const [showVelocityRulePreview, setShowVelocityRulePreview] = useState(false);
  const handleShowVelocityRulePreview = () => setShowVelocityRulePreview(true);
  const handleCloseVelocityRulePreview = () =>
    setShowVelocityRulePreview(false);
  const [currentVelocityRule, setCurrentVelocityRule] = useState();

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  let isMounted = true;
  useEffect(() => {
    setLoading(true);
    fetchVelocityRules();
    return () => {
      isMounted = false;
    };
  }, [perPage]);

  let onRowClick = (row) => {
    setCurrentVelocityRule(row);
    handleShowVelocityRulePreview();
  };

  let onActivateClick = (id, active) => {
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    activateVelocityRule({ id, active }, errorHandler).then((res) => {
      if (res.status === 'accept') {
        NotificationManager.success(res.message, 'Success', 4000);
        fetchVelocityRules();
      } else {
        NotificationManager.error(res.message, 'Error', 4000);
      }
    });
  };

  const fetchVelocityRules = async (page = 1) => {
    const resultHandler = (data) => {
      if (isMounted) {
        if (data && data.status === 'accept') {
          setVelocityRules(data.velocity_rules);
          setTotalRows(data?.total);
          setLoading(false);
        } else {
          setLoading(false);
          setVelocityRules([]);
          setTotalRows(0);
        }
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setVelocityRules([]);
      setLoading(false);
    };

    getAdminVelocityRulesList(
      {
        query_skip: (+page - 1) * perPage,
        query_count: perPage,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const handlePageChange = (page) => {
    fetchVelocityRules(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  const onDeleteClick = (id, name) => {
    setVelocityRuleToRemove({ id, name });
    handleShowConfirmRemoveVelocityRule();
  };

  const handleDeleteVelocityRule = () => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Velocity Rule removed', 'Success', 4000);
        handleCloseConfirmRemoveVelocityRule();
        fetchVelocityRules();
      }
    };

    const errorHandler = (error) => {
      console.log('Error removing velocity rule: ', error);
      NotificationManager.error(error.description, 'Error', 4000);
    };

    deleteVelocityRule(
      {
        id: velocityRuleToRemove.id,
      },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
    //   .then(handleCloseConfirmRemoveVelocityRule);
  };

  return (
    <div className="container-fluid">
      <NotificationContainer />
      <Link
        to={`/velocityRule/create`}
        className="btn btn-outline-primary mt-1 mb-3"
      >
        <i className="bi-plus-square ml-1 mr-2 " /> Create Velocity Rule
      </Link>
      <h2>{pageTitle}</h2>
      <VelocityRulesList
        loading={loading}
        velocityRules={velocityRules}
        emptyText={'The Velocity Rule list is empty'}
        onDeleteClick={onDeleteClick}
        onRowClicked={onRowClick}
        onActivateClick={onActivateClick}
        rowsCountPerPage={perPage}
        handleRowsPerPage={handleRowsPerPage}
        perPageChoices={PER_PAGE_CHOICES}
        totalRows={totalRows}
        handlePageChange={handlePageChange}
      />
      {currentVelocityRule && (
        <VelocityRuleModal
          velocityRuleId={currentVelocityRule._id}
          show={showVelocityRulePreview}
          onHide={handleCloseVelocityRulePreview}
        ></VelocityRuleModal>
      )}
      <Modal
        show={showConfirmRemoveVelocityRule}
        onHide={handleCloseConfirmRemoveVelocityRule}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to remove Velocity Rule{' '}
            <b>{velocityRuleToRemove ? velocityRuleToRemove.name : ''}</b>?{' '}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseConfirmRemoveVelocityRule}
          >
            Close
          </Button>
          <Button variant="danger" onClick={() => handleDeleteVelocityRule()}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VelocityRules;
