import Honkio from '../../../Honkio';

const getStatusesHistory = (options = {}, errorHandler = () => {}) => {
    const { id, query_count = 25, query_skip = 0 } = options;
    return Honkio().mainShop.userFetch(
        'orderstatushistory',
        {
            id,
            query_skip,
            query_count,
            action: 'read',
            version: 3,
        },
        errorHandler
    );
};

export default getStatusesHistory;
