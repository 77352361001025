import { useContext } from 'react';
import UserInfoContext from '../../contexts/UserInfoContext';
import AnySelector from '../UI/AnySelector';
import languages from '../../__fixtures__/languages';

const LanguageSelector = () => {
    const { language, setInfo } = useContext(UserInfoContext);
    const handleSetLanguage = (val) => {
        setInfo({ language: val });
    };

    return (
        <div className="ml-auto">
            <AnySelector
                setItem={handleSetLanguage}
                items={languages}
                valueSelector="value"
                nameSelector="name"
                defaultValue={language ?? 'en'}
                showLabel={false}
            />
        </div>
    );
};

export default LanguageSelector;
