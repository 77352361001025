import PaymentsOverviewEntry from './PaymentsOverviewEntry';

const PaymentsOverview = ({ payments, total, currency, sign }) => {
  if (!payments) return null
  return (
    <div className="col-md-4 order-md-2 mb-4">
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Payments</span>
        <span className="badge badge-secondary badge-pill">this month</span>
      </h4>
      <ul className="list-group mb-3">
        {payments.map((payment, index) => (
          <PaymentsOverviewEntry key={index} payment={payment} sign={sign} />
        ))}
        <li className="list-group-item d-flex justify-content-between">
          <span>Total ({currency})</span>
          <strong>
            {sign}
            {total}
          </strong>
        </li>
      </ul>
    </div>
  );
};

export default PaymentsOverview;
