import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import { createNotifyMessage } from '../../../api/utils/merchant/notifymessage/notifyMessages';
import languages from '../../../__fixtures__/languages';
import variables from '../../../__fixtures__/variables';
import NotifyMessageEditForm from './NotifyMessageEditForm';
import UserInfoContext from './../../../contexts/UserInfoContext';
import useSearchString from './../../../utils/useSearchString';
import BackButton from '../../UI/BackButton';

const LANGUAGES = [{ value: '', name: 'Default' }, ...languages];

const NotifyMessageCreate = () => {
    const history = useHistory();

    const userInfo = useContext(UserInfoContext);

    const { merchant = null, merchant_name = '' } = useSearchString();

    const merchantId = merchant
        ? merchant
        : userInfo.merchant?.id
        ? userInfo.merchant.id
        : null;
    const appid = userInfo.app?.id;
    const merchantName = merchant
        ? merchant_name
        : userInfo.merchant
        ? userInfo.merchant.name
        : '';

    const pageTitle = `Create message ${
        merchantName ? ` for ${merchantName}` : '(template)'
    }`;

    const [language, setLanguage] = useState('');

    const [notifyMessage, setNotifyMessage] = useState({
        merchant: merchantId,
        application: appid,
        name: '',
        notify: [],
        related_object: null,
        subject: '',
        subject__da: '',
        subject__en: '',
        subject__fi: '',
        subject__no: '',
        subject__sv: '',
        message: '',
    });

    const onSubmit = (data) => {
        console.log('NotifyMessageCreate: onSumit called; data == ', data);

        const resultHandler = (data) => {
            if (data && data.status === 'accept' && data.notify_message) {
                NotificationManager.success('Saved');
                history.push(`/messages/${data.notify_message._id}`);
            } else {
                NotificationManager.error('Error');
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };
        createNotifyMessage(
            Object.assign(notifyMessage, data),
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton
                    to="/messages"
                    text="Back to list of message templates"
                />{' '}
                {pageTitle}
            </h2>
            <NotifyMessageEditForm
                languages={LANGUAGES}
                onSubmitForm={onSubmit}
                language={language}
                setLanguage={setLanguage}
                notifyMessage={notifyMessage}
                setNotifyMessage={setNotifyMessage}
                variables={variables}
            />
        </div>
    );
};

export default NotifyMessageCreate;
