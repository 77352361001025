import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Icon from '../UI/Icon';
import DataTable from 'react-data-table-component';
import { useMemo } from 'react';
import Wait from '../UI/Wait';
import ReactTooltip from 'react-tooltip';

const VoucherUsersTable = ({
  data,
  loading,
  totalRows,
  rowsCountPerPage,
  handleRowsPerPage = () => {},
  perPageChoices = [25],
  handlePageChange = () => {},
  onRowsSelected = () => {},
  onRowClick = () => {},
}) => {
  const columns = useMemo(
    () => [
      {
        name: 'Serial',
        grow: 1,
        autoWidth: true,
        cell: ({ serial_number }, index) => serial_number,
      },
      {
        name: 'First name',
        grow: 1,
        cell: ({ str_firstname: firstname }) => firstname,
      },
      {
        name: 'Last name',
        grow: 1,
        cell: ({ str_lastname: lastname }) => lastname,
      },
      { name: 'Email', grow: 1, cell: ({ email }) => email ?? 'n/a' },
      {
        name: 'Active',
        grow: 0,
        cell: ({ active }) => (active === 'true' ? 'yes' : 'no'),
      },
      {
        name: 'Action',
        grow: 0,
        autoWidth: true,
        cell: ({ id, serial_number }) => (
          <div>
            <Link to={`/user/edit?id=${id}`} data-tip data-for="editTip">
              <Icon iconName="bi-pencil" />
              <ReactTooltip id="editTip" place="top" effect="solid">
                Edit
              </ReactTooltip>
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <DataTable
      selectableRows
      selectableRowsVisibleOnly
      selectableRowsHighlight
      onSelectedRowsChange={onRowsSelected}
      dense
      striped
      highlightOnHover
      data={data}
      columns={columns}
      pagination={true}
      paginationPerPage={rowsCountPerPage}
      paginationRowsPerPageOptions={perPageChoices}
      onChangeRowsPerPage={handleRowsPerPage}
      paginationServer
      progressPending={loading}
      onChangePage={handlePageChange}
      paginationTotalRows={totalRows}
      onRowClicked={onRowClick}
      progressComponent={
        <div className="p-5">
          <Wait />
        </div>
      }
    />
  );
};

export default VoucherUsersTable;
