import React, { useState } from 'react';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import _ from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateRoleForm from './TemplateRoleForm';
import {
    createRoleTemplate,
    updateRoleTemplate,
} from '../../../api/utils/templateModels/templateRoles/setRoleTemplate';
import BackButton from '../../UI/BackButton';
import useSearchString from '../../../utils/useSearchString';

const TemplateRoleEdit = () => {
    const { id } = useSearchString();

    const [submitLoading, setSubmitLoading] = useState(false);
    const history = useHistory();

    const handleSubmitForm = async (submitData) => {
        const resultHandler = (data) => {
            setSubmitLoading(false);
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Updated successfully',
                    'Success',
                    3000
                );
            }
        };

        const errorHandler = (error) => {
            setSubmitLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setSubmitLoading(true);
            const res = await updateRoleTemplate(
                { ...submitData, id },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton to="/template-models/roles" text="Back to list" />
                Edit role template
            </h2>

            <TemplateRoleForm
                id={id}
                onSubmit={handleSubmitForm}
                submitLoading={submitLoading}
            />
        </div>
    );
};

export default TemplateRoleEdit;
