import useSearchString from '../../utils/useSearchString';
import { useEffect, useState } from 'react';
import getAppInfo from '../../api/utils/app/getAppInfo';
import { updateApp } from '../../api/utils/app/setAppInfo';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import LoadingBar from '../UI/LoadingBar';
import useLoader from '../UI/helpers/useLoader';
import fillAppInfoRegistrationRequirements from './helpers/fillAppInfoRegistrationRequirements';
import BackButton from '../UI/BackButton';
import AppEditForm from './components/AppEditForm';
import _ from 'lodash';

const ApplicationInfo = () => {
    const [appInfo, setAppInfo] = useState();
    const [language, setLanguage] = useState('en');
    const [editorData, setEditorData] = useState('');
    const [multiLanguageEditorData, setMultiLanguageEditorData] = useState({});
    const { appid } = useSearchString();

    const [progress, done] = useLoader([appInfo]);

    const [existingCustomRegReq, setExistingCustomRegReq] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAppInfo();
    }, [appid]);

    const setAppInfoData = (appInfoData) => {
        const { appInfo, fields_merchant } =
            fillAppInfoRegistrationRequirements(appInfoData);
        setExistingCustomRegReq(fields_merchant);
        setAppInfo(appInfo);
    };

    const fetchAppInfo = async () => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setAppInfoData(data.application);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setAppInfo({});
        };

        if (appid) {
            try {
                setLoading(true);
                const res = await getAppInfo({ appid }, errorHandler);
                resultHandler(res);
            } catch (error) {
                setAppInfo({});
                setLoading(false);
            }
        } else {
            setAppInfo({});
        }
    };

    useEffect(() => {
        if (appInfo && language) {
            setEditorData(
                multiLanguageEditorData[language] ??
                    appInfo?.settings?.dict_tou_templates?.[
                        'str_tou_template__' + language
                    ] ??
                    ''
            );
        }
    }, [appInfo, language]);

    useEffect(() => {
        if (language && editorData) {
            setMultiLanguageEditorData((data) => ({
                ...data,
                [language]: editorData,
            }));
        }
    }, [editorData]);

    const onSubmit = async (data) => {
        const tou_arr = Object.entries(multiLanguageEditorData).map(
            ([key, value]) => ({
                key: 'str_tou_template__' + key,
                value: value,
            })
        );
        const tou = tou_arr.reduce(
            (obj, item) => ({
                ...obj,
                [item.key]: item.value,
            }),
            {}
        );

        const appSubmitData = _.cloneDeep(data);

        appSubmitData.settings.dict_tou_templates = tou;

        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setAppInfoData(data.application);
                NotificationManager.success(
                    'Application saved',
                    'Success',
                    4000
                );
            } else {
                NotificationManager.error('Unknown error', 'Error', 4000);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setLoading(true);
            const res = await updateApp(
                { id: appid, ...appSubmitData },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton to="/apps" text="Back to Applications list" />
                Edit Application (ID#{appid})
            </h2>
            {done ? (
                appInfo?._id ? (
                    <AppEditForm
                        appInfo={appInfo}
                        language={language}
                        setLanguage={setLanguage}
                        onSubmit={onSubmit}
                        editorData={editorData}
                        setEditorData={setEditorData}
                        existingCustomRegReq={existingCustomRegReq}
                    />
                ) : (
                    'not found'
                )
            ) : (
                <LoadingBar progress={progress} />
            )}
        </div>
    );
};

export default ApplicationInfo;
