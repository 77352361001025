import React from 'react';
import {
    Form,
    Button,
    Row,
    Col,
    Card,
    Table,
    Accordion,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Icon from '../../../UI/Icon';
import CronFieldsInfo from '../../components/CronFieldsInfo';

const INTERVAL_NAMES = [
    {
        label: 'Seconds',
        value: 'seconds',
    },
    {
        label: 'Minutes',
        value: 'minutes',
    },
    {
        label: 'Hours',
        value: 'hours',
    },
    {
        label: 'Days',
        value: 'days',
    },
    {
        label: 'Weeks',
        value: 'weeks',
    },
];

const CronFields = ({ job, handleChangeJob = () => {} }) => {
    return (
        <>
            <CronFieldsInfo />

            <Form.Group className="mb-3" controlId="data.cron_args.year">
                <Form.Label>Year</Form.Label>
                <Form.Control
                    type="text"
                    aria-label="Year"
                    name="data.cron_args.year"
                    value={job?.cron_args?.year || ''}
                    onChange={(e) => {
                        const currentCronArgs = job?.cron_args || {};
                        handleChangeJob({
                            target: {
                                value: {
                                    ...currentCronArgs,
                                    year: e.target.value,
                                },
                                name: 'cron_args',
                            },
                        });
                    }}
                    required
                    placeholder="4-digit year"
                ></Form.Control>
                <Form.Text className="text-muted">*, 2023 etc</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.cron_args.month">
                <Form.Label>Month</Form.Label>
                <Form.Control
                    type="text"
                    aria-label="Month"
                    name="data.cron_args.month"
                    value={job?.cron_args?.month || ''}
                    onChange={(e) => {
                        const currentCronArgs = job?.cron_args || {};
                        handleChangeJob({
                            target: {
                                value: {
                                    ...currentCronArgs,
                                    month: e.target.value,
                                },
                                name: 'cron_args',
                            },
                        });
                    }}
                    required
                    placeholder="month (1-12)"
                ></Form.Control>
                <Form.Text className="text-muted">*; 6-8,11-12; etc</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.cron_args.day">
                <Form.Label>Day</Form.Label>
                <Form.Control
                    type="text"
                    aria-label="Day"
                    name="data.cron_args.day"
                    value={job?.cron_args?.month || ''}
                    onChange={(e) => {
                        const currentCronArgs = job?.cron_args || {};
                        handleChangeJob({
                            target: {
                                value: {
                                    ...currentCronArgs,
                                    day: e.target.value,
                                },
                                name: 'cron_args',
                            },
                        });
                    }}
                    required
                    placeholder="day of month (1-31)"
                ></Form.Control>
                <Form.Text className="text-muted">
                    *; 1; last sun; 3rd fri; etc
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.cron_args.week">
                <Form.Label>Week</Form.Label>
                <Form.Control
                    type="text"
                    aria-label="Week"
                    name="data.cron_args.week"
                    value={job?.cron_args?.week || ''}
                    onChange={(e) => {
                        const currentCronArgs = job?.cron_args || {};
                        handleChangeJob({
                            target: {
                                value: {
                                    ...currentCronArgs,
                                    week: e.target.value,
                                },
                                name: 'cron_args',
                            },
                        });
                    }}
                    required
                    placeholder="ISO week (1-53)"
                ></Form.Control>
                <Form.Text className="text-muted">*; 23; etc</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.cron_args.day_of_week">
                <Form.Label>Day of week</Form.Label>
                <Form.Control
                    type="text"
                    aria-label="Day of week"
                    name="data.cron_args.day_of_week"
                    value={job?.cron_args?.day_of_week || ''}
                    onChange={(e) => {
                        const currentCronArgs = job?.cron_args || {};
                        handleChangeJob({
                            target: {
                                value: {
                                    ...currentCronArgs,
                                    day_of_week: e.target.value,
                                },
                                name: 'cron_args',
                            },
                        });
                    }}
                    required
                    placeholder="number or name of weekday (0-6 or mon,tue,wed,thu,fri,sat,sun)"
                ></Form.Control>
                <Form.Text className="text-muted">*; mon-fri; etc</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.cron_args.hour">
                <Form.Label>Hour</Form.Label>
                <Form.Control
                    type="text"
                    aria-label="Hour"
                    name="data.cron_args.hour"
                    value={job?.cron_args?.hour || ''}
                    onChange={(e) => {
                        const currentCronArgs = job?.cron_args || {};
                        handleChangeJob({
                            target: {
                                value: {
                                    ...currentCronArgs,
                                    hour: e.target.value,
                                },
                                name: 'cron_args',
                            },
                        });
                    }}
                    required
                    placeholder="hour (0-23)"
                ></Form.Control>
                <Form.Text className="text-muted">*; 12; 0-3; etc</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.cron_args.minute">
                <Form.Label>Minute</Form.Label>
                <Form.Control
                    type="text"
                    aria-label="Minute"
                    name="data.cron_args.minute"
                    value={job?.cron_args?.minute || ''}
                    onChange={(e) => {
                        const currentCronArgs = job?.cron_args || {};
                        handleChangeJob({
                            target: {
                                value: {
                                    ...currentCronArgs,
                                    minute: e.target.value,
                                },
                                name: 'cron_args',
                            },
                        });
                    }}
                    required
                    placeholder="minute (0-59)"
                ></Form.Control>
                <Form.Text className="text-muted">*; 30; etc</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.cron_args.second">
                <Form.Label>Second</Form.Label>
                <Form.Control
                    type="text"
                    aria-label="Second"
                    name="data.cron_args.second"
                    value={job?.cron_args?.second || ''}
                    onChange={(e) => {
                        const currentCronArgs = job?.cron_args || {};
                        handleChangeJob({
                            target: {
                                value: {
                                    ...currentCronArgs,
                                    second: e.target.value,
                                },
                                name: 'cron_args',
                            },
                        });
                    }}
                    required
                    placeholder="second (0-59)"
                ></Form.Control>
                <Form.Text className="text-muted">*; 5; etc</Form.Text>
            </Form.Group>

            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="data.start_date">
                        <Form.Label>Start date</Form.Label>
                        <Form.Control
                            as={DatePicker}
                            aria-label="Start date"
                            name="start_date"
                            selected={job?.start_date || ''}
                            onChange={(date) =>
                                handleChangeJob({
                                    target: { value: date, name: 'start_date' },
                                })
                            }
                            required
                            showTimeSelect
                            dateFormat="dd-MM-yyyy HH:mm"
                            timeFormat="HH:mm"
                            placeholderText="Select start date"
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            Job start date
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="data.start_date">
                        <Form.Label>End date</Form.Label>
                        <Form.Control
                            as={DatePicker}
                            aria-label="End date"
                            name="end_date"
                            selected={job?.end_date || ''}
                            onChange={(date) =>
                                handleChangeJob({
                                    target: { value: date, name: 'end_date' },
                                })
                            }
                            required
                            showTimeSelect
                            dateFormat="dd-MM-yyyy HH:mm"
                            timeFormat="HH:mm"
                            placeholderText="Select end date"
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            Job end date
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default CronFields;
