import React, { useContext, useEffect, useState } from 'react';
import useSearchString from '../../../utils/useSearchString';
import UserInfoContext from '../../../contexts/UserInfoContext';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { Link } from 'react-router-dom';
import InlineSearch from '../../UI/InlineSearch';
import JobsTable from './JobsTable';
import getScheduledJobsList from '../../../api/utils/scheduler/getScheduledJobsList';
import { deleteSchedulerJob } from '../../../api/utils/scheduler/setJob';
import DeleteConfirmModel from '../../Model/DeleteConfirmModel';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const JobsList = () => {
  const {
    app: { id: appId, name: appName },
    merchant: { id: merchantId, name: merchantName },
  } = useContext(UserInfoContext);

  const [jobsList, setJobsList] = useState([]);
  const [keyword, setKeyword] = useState('');

  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState('Scheduler jobs');

  useEffect(() => {
    if (merchantName) {
      setPageTitle(`Scheduler jobs (${merchantName})`);
    } else if (appName) {
      setPageTitle(`Scheduler jobs (${appName})`);
    } else {
      setPageTitle('Scheduler jobs');
    }
  }, [appName, merchantName]);

  useEffect(() => {
    fetchJobs();
  }, [appId, merchantId, keyword, perPage]);

  const fetchJobs = async (page = 1) => {
    setLoading(true);

    const resultHandler = (res) => {
      setJobsList(res?.jobs || []);
      setTotalRows(res?.total || 0);
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setJobsList([]);
      setTotalRows(0);
      setLoading(false);
    };

    try {
      const res = await getScheduledJobsList(
        {
          query_skip: (+page - 1) * perPage,
          query_count: perPage,
          query_search: keyword,
          query_merchant: appName && appName === 'server' ? null : merchantId,
          query_application: appName && appName === 'server' ? null : appId,
        },
        errorHandler
      );
      if (res && res?.status === 'accept') resultHandler(res);
      setLoading(false);
    } catch (error) {
      console.log('error loading jobs list: ', error);
      errorHandler(error);
      setJobsList([]);
      setTotalRows(0);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchJobs(page);
  };

  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  const handleRowClick = (row) => {
    // TODO
  };

  const handleDelete = (id) => {
    setShowConfirmModal(true);
    setDeleteId(id);
  };

  const handleCloseConfirmDelete = async () => {
    const resultHandler = (data) => {
      setDeleteId(undefined);
      if (data && data?.status === 'accept') {
        setShowConfirmModal(false);
        NotificationManager.success('Job removed');
        fetchJobs();
      } else {
        NotificationManager.error('Error removing job');
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    try {
      const res = await deleteSchedulerJob({ id: deleteId }, errorHandler);
      resultHandler(res);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="container-fluid">
      <NotificationContainer />
      <h2>{pageTitle}</h2>
      <Link
        to={`/scheduler/job/create`}
        className="btn btn-outline-primary mt-1 mb-3"
      >
        <i className="bi-plus-square ml-1 mr-2 " /> Create Job
      </Link>
      <div className="row">
        <div className="col">
          <InlineSearch setKeyword={setKeyword} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <JobsTable
            data={jobsList}
            loading={loading}
            onRowClicked={handleRowClick}
            onDeleteClick={handleDelete}
            rowsCountPerPage={perPage}
            handleRowsPerPage={handleRowsPerPage}
            perPageChoices={PER_PAGE_CHOICES}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
      <DeleteConfirmModel
        modal={showConfirmModal}
        onModalClose={() => {
          setShowConfirmModal(false);
        }}
        handleCloseConfirmDelete={handleCloseConfirmDelete}
      />
    </div>
  );
};

export default JobsList;
