import { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Form, FormGroup } from 'shards-react';
import { CONSUMER_URL } from '../../../config';
import CheckIcon from '@material-ui/icons/Check';
import Select from 'react-select';
import FormInputValidation from '../components/FormInputValidationComponent';
const Circular = () => (
  // we need to add some padding to circular progress to keep it from activating our scrollbar
  <div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>
);

class WidgetTypeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: '',
      type: 'generic',
    };
  }

  onSelect = () => {
    if (this.state.type === 'sensor') {
      this.props.goToStep(2);
    } else {
      this.props.goToStep(4);
    }
  };

  render() {
    return (
      <div>
        <FormGroup>
          <label htmlFor="type">Type</label>
          <Select
            id="type"
            options={[{ value: 'generic', label: 'Generic' }]}
            defaultValue={{ value: 'generic', label: 'Generic' }}
            onChange={(e) => {
              this.setState({
                type: e.value,
              });
            }}
          />
        </FormGroup>
        <Button theme="primary" className="float-right" onClick={this.onSelect}>
          Next
        </Button>
      </div>
    );
  }
}

export default WidgetTypeComponent;
