import Honkio from '../../../Honkio';

const topUpMerchantBalance = (options = {}, errorHandler = {}) => {
  const { merchant_id, amount } = options;
  return Honkio().mainShop.userFetch(
    'adminmerchanttopupbalance',
    { merchant_id, amount, version: 3, action: 'update' },
    errorHandler
  );
};
export default topUpMerchantBalance;
