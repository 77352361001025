const statusesToEdgeList = (data) => {
    const edges = Object.keys(data).filter(e => {
     return   data[e]['parent_of']?.length > 0
    })
    return edges.flatMap((key) => {
        return data[key]['parent_of']?.map((id) => ({
            id: `${key}-${id}`,
            source: key,
            target: id,
            arrowHeadType: 'arrow',
        }))
    }
    );
};

export default statusesToEdgeList;
