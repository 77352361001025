import { useEffect, useState } from 'react';
import useSearchString from '../../utils/useSearchString';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import getAssetStructure from '../../api/utils/asset/structures/getAssetStructure';

const AssetStructureViewRaw = () => {
  const [info, setInfo] = useState({});
  const { assetstructure, merchant } = useSearchString();

  const fetchAssetStructure = async (id) => {
    const resultHandler = (data) => {
      setInfo(data?.asset_structure);
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    const res = await getAssetStructure({ id }, errorHandler);
    if (res && res.status === 'accept') {
      return resultHandler(res);
    }
  };

  useEffect(() => {
    if (assetstructure) fetchAssetStructure(assetstructure);
  }, [assetstructure]);

  return (
    <div className="d-flex justify-content-center">
      <NotificationContainer />
      <pre className="text-left">{JSON.stringify(info, null, 2)}</pre>
    </div>
  );
};

export default AssetStructureViewRaw;
