import { useEffect, useState, useContext } from 'react';
import UserInfoContext from '../../../../contexts/UserInfoContext';
import InlineSearch from '../../../UI/InlineSearch';
import useSearchString from '../../../../utils/useSearchString';
import fixEmail from '../../../../utils/fixEmail';
import { getOrders } from '../../../../api/utils/order/getOrders';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import { Col, Row, Container } from 'react-bootstrap';
import TokenizationsList from './TokenizationsList';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const TOKENIZATION_TRANSACTION_ORDER_TYPE =
    'TOKENIZATION_TRANSACTION_ORDER_TYPE';

const TransactionTokenizationsList = () => {
    const [keyword, setKeyword] = useState('');
    const [tokenizationOrderList, setTokenizationOrdersList] = useState([]);
    const [totalRows, setTotalRows] = useState();

    const [loading, setLoading] = useState(false);

    const { merchant } = useSearchString();

    const {
        app: { id: appId },
        merchant: { id: contextMerchantId },
    } = useContext(UserInfoContext);

    const { email } = useSearchString(fixEmail);

    let merchantId = merchant
        ? merchant
        : contextMerchantId
        ? contextMerchantId
        : null;

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);

    useEffect(() => {
        fetchTokenizationOrders(1);
    }, [email, keyword, merchantId, perPage]);

    const fetchTokenizationOrders = async (page = 1) => {
        const resultHandler = (data) => {
            if (data && data?.orders) {
                setTokenizationOrdersList(data.orders);
                setTotalRows(data.total);
            }
            setLoading(false);
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error');
            setLoading(false);
            setTokenizationOrdersList([]);
            setTotalRows(0);
        };

        try {
            setLoading(true);
            const res = await getOrders(
                {
                    query_merchant: merchantId,
                    query_application: appId,
                    page: page - 1,
                    query_count: perPage,
                    query_type: TOKENIZATION_TRANSACTION_ORDER_TYPE,
                    query_search: keyword,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            setTokenizationOrdersList([]);
            setTotalRows(0);
            console.log(
                'Unexpected error fetching tokenization transaction orders: ',
                error
            );
        }
    };

    const handlePageChange = (page) => {
        fetchTokenizationOrders(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    return (
        <>
            <NotificationContainer />

            <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <InlineSearch
                            keyword={keyword}
                            setKeyword={setKeyword}
                        />
                    </Col>
                </Row>
            </Container>

            <Row>
                <Col sm={12}>
                    <TokenizationsList
                        data={tokenizationOrderList}
                        loading={loading}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        rowsCountPerPage={perPage}
                        handleRowsPerPage={handleRowsPerPage}
                        merchant={merchantId}
                        emptyText={'No tokenization transactions yet...'}
                    />
                </Col>
            </Row>
        </>
    );
};

export default TransactionTokenizationsList;
