import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Icon from '../../UI/Icon';
import ReactTooltip from 'react-tooltip';
import Wait from '../../UI/Wait';
import { Switch } from '@material-ui/core';
import { Form } from 'react-bootstrap';
import updateAccessRule from '../../../api/utils/accessRules/updateAccessRule';
import { NotificationManager } from 'react-notifications';

const Selector = ({
  items,
  selected,
  appid,
  language,
  loading,
  totalRows,
  rowsCountPerPage,
  handleRowsPerPage = () => {},
  perPageChoices = [25],
  handlePageChange = () => {},
  onRowClicked = () => {},
  onDeleteClick = () => {},
  toggleActive = () => {},
}) => {
  const columns = useMemo(
    () => [
      {
        name: 'ID',
        grow: 1,
        autoWidth: true,
        cell: ({ _id }) => (
          <span className="text-truncate" data-tip data-for={`id_tip_${_id}`}>
            {_id}
            <ReactTooltip id={`id_tip_${_id}`} place="top" effect="solid">
              {_id}
            </ReactTooltip>
          </span>
        ),
      },
      {
        name: 'Name',
        grow: 2,
        cell: (c) => {
          const text = <span className="text-muted">-</span>;
          const langText = c['name'];
          if (langText) {
            return langText;
          } else {
            return c.display_name ?? text;
          }
        },
      },
      {
        name: 'Description',
        grow: 4,
        cell: (c) => {
          const text = <span className="text-muted">No description yet</span>;
          const desc = c['description'];
          if (desc) {
            return desc;
          } else {
            return c.description ?? desc;
          }
        },
      },
      {
        name: 'Global',
        grow: 0,
        cell: (c, _) => (c?.is_global ? 'Yes' : 'No'),
      },
      {
        name: 'Active',
        grow: 0,
        cell: (c, i) => (
          //   <Switch
          <Form.Switch
            id={`is-active-switch-${c?._id}-${i}`}
            defaultChecked={c?.is_active}
            onChange={() => toggleActive(c)}
            className="m-0"
            name="is_active"
            label=""
          />
        ),
      },
      {
        name: 'Actions',
        grow: 1,
        cell: (row) => {
          return (
            <div className="d-flex">
              <Link
                to={`/accessrules/edit?rule_id=${row._id}${
                  appid ? `&appid=${appid}` : ''
                }`}
                data-tip
                data-for="editTip"
              >
                <Icon iconName="bi-pen" />
                <ReactTooltip id="editTip" place="top" effect="solid">
                  Edit
                </ReactTooltip>
              </Link>
              <div
                onClick={() => {
                  onDeleteClick(row._id, row.name);
                }}
                data-tip
                data-for="deleteTip"
              >
                <Icon iconName="btn bi-trash" />
                <ReactTooltip id="deleteTip" place="top" effect="solid">
                  Delete
                </ReactTooltip>
              </div>
            </div>
          );
        },
      },
    ],
    [appid, items]
  );

  const conditionalRowStyles = useMemo(
    () => [
      {
        when: (row) => row._id === selected?._id,
        style: {
          backgroundColor: 'AntiqueWhite',
        },
      },
    ],
    [selected]
  );

  return (
    <DataTable
      striped
      dense
      highlightOnHover
      progressPending={loading}
      progressComponent={
        <div className="p-5">
          <Wait />
        </div>
      }
      data={items ?? []}
      columns={columns}
      conditionalRowStyles={conditionalRowStyles}
      onRowClicked={onRowClicked}
      pagination={true}
      paginationPerPage={rowsCountPerPage}
      paginationRowsPerPageOptions={perPageChoices}
      onChangeRowsPerPage={handleRowsPerPage}
      paginationServer
      onChangePage={handlePageChange}
      paginationTotalRows={totalRows}
    />
  );
};

export default Selector;
