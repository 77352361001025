import Honkio from '../../Honkio';

const getSchedulerScriptsList = (options = {}, errorHandler = () => {}) => {
    const { query_search, query_skip, query_count } = options;
    return Honkio().mainShop.userFetch(
        'adminappschedulerscriptslist',
        {
            query_search,
            query_skip,
            query_count,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
export default getSchedulerScriptsList;
