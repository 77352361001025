import HonkioAPI from '../../Honkio';

/**
 *
 * @param {*} options - {object: string, type: string }
 * @param {*} options.object should be APP_ID for videos and images in gallery
 * @param {*} options.type - specify type to save on asset, used to load list of assets of same type; `image` for Images, `video` for videos, `document` for documents. It is possible to create custom `type` on the fly
 * @param {*} options.query_owner email of user who owns this file
 * @param {*} options.query_ids the list of file ids
 * @param {*} options.query_skip pagination skip documents count
 * @param {*} options.query_count pagination returned number of documents
 * @param {*} errorHandler - callback function, triggered on error in the response
 * @returns Promise
 */
export const fileList = (options = {}, errorHandler = () => {}) => {
    const {
        object: query_object,
        type: query_type,
        query_owner,
        query_merchant,
        query_application,
        query_ids,
        query_skip,
        query_count,
    } = options;
    return HonkioAPI().mainShop.userFetch(
        'adminfilelist',
        {
            query_object,
            query_type,
            query_owner,
            query_merchant,
            query_application,
            query_ids,
            query_skip,
            query_count,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
