import { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Form, FormGroup } from 'shards-react';
import { CONSUMER_URL } from '../../../config';
import CheckIcon from '@material-ui/icons/Check';
import Select from 'react-select';
import FormInputValidation from '../components/FormInputValidationComponent';

const Circular = () => (
  // we need to add some padding to circular progress to keep it from activating our scrollbar
  <div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>
);

class GenericWidgetBasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      type: '',
      name: '',
    };
  }

  render() {
    return (
      <div>
        <FormGroup>
          <label htmlFor="name">Name</label>
          <FormInputValidation
            id="name"
            validationType="notNull"
            value={this.state.name}
            placeholder="Name"
            onChange={(e) => this.setState({ name: e.target.value })}
            handleValidation={this.props.handleValidation}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="type">Type</label>
          <Select
            id="type"
            options={[
              { value: 'num_users', label: 'Number of users' },
              { value: 'num_reg', label: 'Number of registrations' },
              //   { value: 'maturity', label: 'Maturity' },
              //   { value: 'tenants', label: 'Tenants' },
              //   { value: 'square', label: 'Square' },
              //   { value: 'rental_cost', label: 'Rental cost' },
              //   {
              //     value: 'building_invoices',
              //     label: 'Building invoices',
              //   },
              //   {
              //     value: 'building_income_pie',
              //     label: 'Building income pie',
              //   },
            ]}
            defaultValue={{
              value: '',
              label: '— Select type —',
            }}
            onChange={(e) => {
              this.setState({
                type: e.value,
              });
            }}
          />
        </FormGroup>
        <Button
          theme="primary"
          className="float-right"
          onClick={() => this.props.addWidget(this.state.name, this.state.type)}
        >
          Next
        </Button>
      </div>
    );
  }
}

export default GenericWidgetBasicInfo;
