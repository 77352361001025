import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { Form } from 'react-bootstrap';
import getServerAppList from '../../../api/utils/app/getServerAppList';
import ActionButtons from './ActionButtons';
import Select from 'react-select';
import _ from 'lodash';
import cn from 'classnames';

/**
 * @param selectedApps string[]
 */
const ApplicationSelector = ({
    appList = [],
    setAppList = () => {},
    nextStep = () => {},
    appCallback = () => {},
    selectedApps = [],
    ...rest
}) => {
    const [appsSelected, setAppsSelected] = useState([]);

    const [error, setError] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (
            _.isArray(selectedApps) &&
            !_.isEmpty(selectedApps) &&
            _.isArray(appList) &&
            !_.isEmpty(appList)
        ) {
            const newSelected = appList.filter((item) =>
                selectedApps.includes(item._id)
            );
            setAppsSelected(newSelected);
        } else {
            setAppsSelected([]);
        }
    }, [selectedApps, appList]);

    useEffect(() => {
        fetchAppList();
    }, []);

    const fetchAppList = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setAppList(data.applications);
                setLoading(false);
            } else {
                setAppList([]);
                setLoading(false);
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setAppList([]);
            setLoading(false);
        };

        try {
            const res = await getServerAppList(
                { query_all: true, merchant: null, debug: true },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('error fetching app list: ', error);
        }
    };

    const validate = () => {
        if (_.isEmpty(appsSelected)) setError('Please, select applications');
        else {
            setError('');
            nextStep();
            appCallback(appsSelected.map((item) => item._id));
        }
    };

    return (
        <div>
            <h1>Select Applications</h1>
            <Form.Group className={cn('mb-3')} controlId="apps.apps">
                <Form.Label>Applications</Form.Label>
                <Select
                    id="action-type-select"
                    value={appsSelected}
                    onChange={(val) => {
                        setAppsSelected(val);
                        setError('');
                    }}
                    isMulti
                    options={appList}
                    className={cn('basic-multi-select')}
                    classNamePrefix="select"
                    placeholder={'— Select applications —'}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option._id}
                    isLoading={loading}
                    required
                />

                {error ? (
                    <Form.Text className="text-danger">{error}</Form.Text>
                ) : null}
            </Form.Group>

            <ActionButtons {...rest} nextStep={validate} loading={loading} />
        </div>
    );
};

export default ApplicationSelector;
