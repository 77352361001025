import React, { useState, useEffect } from 'react';
import { Form, Button, ListGroup } from 'react-bootstrap';
import ActionButtons from './ActionButtons';
import getMerchantList from '../../../api/utils/merchant/getMerchantList';
import Select, { components } from 'react-select';
import _ from 'lodash';
import cn from 'classnames';
import Icon from '../../UI/Icon';

const groupStyles = {
    border: `2px dotted red !important`,
    borderRadius: '5px',
    background: '#f2fcff',
};

const GroupHeading = (props) => {
    return (
        <div style={groupStyles}>
            <components.GroupHeading
                {...props}
                onClick={() => console.log('heading clicked')}
            >
                {props?.data?.name || ' '}
            </components.GroupHeading>
        </div>
    );
};

const CustomOption = (props) => {
    return (
        <>
            <components.Option {...props}>{props.children}</components.Option>
        </>
    );
};

const MerchantSelector = ({
    nextStep = () => {},
    merchantCallback = () => {},
    selectedApps = [],
    selectedMerchants = [],
    appList = [],
    rawMerchants,
    setRawMerchants,
    ...rest
}) => {
    const [error, setError] = useState('');

    const [merchantsList, setMerchantsList] = useState([]);
    const [merchantsSelected, setMerchantsSelected] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (
            _.isArray(selectedMerchants) &&
            !_.isEmpty(selectedMerchants) &&
            _.isArray(rawMerchants) &&
            !_.isEmpty(rawMerchants)
        ) {
            const newSelected = generateSelectedMerchants(selectedMerchants);
            setMerchantsSelected(newSelected);
        } else {
            setMerchantsSelected([]);
        }
    }, [selectedMerchants, rawMerchants]);

    const validate = () => {
        if (_.isEmpty(merchantsSelected)) setError('Please, select merchants');
        else {
            const selectedMerchantsAppIds = [
                ...new Set(
                    merchantsSelected.map((item) => item.application_id)
                ),
            ];

            if (
                !_.isEqual(selectedApps.sort(), selectedMerchantsAppIds.sort())
            ) {
                setError('Please, select merchant for each application');
                return;
            }

            const resultingMerchants = merchantsSelected.map(
                (item) => item._id
            );

            setError('');
            nextStep();
            merchantCallback(resultingMerchants);
        }
    };

    useEffect(() => {
        fetchMerchants();
    }, [selectedApps]);

    const generateMerchantOptions = (merchants = []) => {
        const grouped = _.groupBy(merchants, 'application_name');
        const res = Object.keys(grouped).map((item) => ({
            name: item,
            options: grouped[item],
        }));

        return res;
    };

    const generateSelectedMerchants = (merchantsIds = []) => {
        return rawMerchants.filter((item) => merchantsIds.includes(item._id));
    };

    const fetchMerchants = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                const opts = generateMerchantOptions(data.merchants);

                setRawMerchants(data.merchants);
                setMerchantsList(opts);
                setLoading(false);
            } else {
                setRawMerchants([]);
                setMerchantsList([]);
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            console.log('error fetching merchants: ', error);
            setRawMerchants([]);
            setMerchantsList([]);
            setLoading(false);
        };

        try {
            const res = await getMerchantList(
                {
                    query_applications: selectedApps,
                    query_non_shareholders: true,
                    query_skip: 0,
                    query_count: 10000,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('error fetching merchants list: ', error);
        }
    };

    const renderAppsProgress = () => {
        const currentApps = appList.filter((item) =>
            selectedApps.includes(item._id)
        );
        const currentSelected = merchantsSelected.map(
            (item) => item.application_id
        );

        return (
            <ListGroup defaultActiveKey="#link1">
                {currentApps.map((item) => {
                    const currentIncluded = currentSelected.includes(item._id);
                    return (
                        <ListGroup.Item
                            key={item._id}
                            variant={currentIncluded ? 'primary' : 'secondary'}
                            onClick={(e) => e.preventDefault()}
                            className={cn(
                                'd-flex',
                                'justify-content-between',
                                'align-items-center'
                            )}
                        >
                            <div>{item.name || 'No name'}</div>
                            <div>
                                <Icon
                                    iconName={
                                        currentIncluded
                                            ? 'bi-check-circle-fill'
                                            : 'bi-circle'
                                    }
                                    color={
                                        currentIncluded
                                            ? 'var(--success)'
                                            : 'var(--secondary)'
                                    }
                                />
                            </div>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        );
    };

    return (
        <div>
            <h1>Select Merchants</h1>
            <Form.Group className="mb-3" controlId="apps.merchants">
                <Form.Label>Applications progress</Form.Label>
                {renderAppsProgress()}
            </Form.Group>
            <Form.Group className="mb-3" controlId="apps.merchants">
                <Form.Label>Merchants</Form.Label>
                <Select
                    id="action-type-select"
                    value={merchantsSelected}
                    onChange={(val) => {
                        setMerchantsSelected(val);
                        setError('');
                    }}
                    isMulti
                    options={merchantsList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={'— Select merchants —'}
                    menuPortalTarget={document.body}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option._id}
                    isLoading={loading}
                    components={{
                        GroupHeading: GroupHeading,
                        Option: CustomOption,
                    }}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 2 }),
                        groupHeading: (base) => ({
                            ...base,
                            flex: '1 1',
                            color: 'var(--white)',
                            backgroundColor: 'var(--primary)',
                            margin: 0,
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            padding: '0.5rem',
                            zIndex: 1000,
                        }),
                    }}
                    required
                />
                {error ? (
                    <Form.Text className="text-danger">{error}</Form.Text>
                ) : null}
            </Form.Group>

            <ActionButtons {...rest} nextStep={validate} loading={loading} />
        </div>
    );
};

export default MerchantSelector;
