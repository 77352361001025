import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';

const NaturalInfo = ({ data }) => {
  const { entityType, values } = data;

  return (
    <Table responsive striped bordered hover className="overflow-hidden">
      <tbody
        style={{
          wordWrap: 'break-word',
          overflow: 'hidden',
        }}
      >
        <tr>
          <td>First name</td>
          <td>{values?.firstName} </td>
        </tr>
        <tr>
          <td>Last name</td>
          <td>{values?.lastName}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{values?.email}</td>
        </tr>
        <tr>
          <td>Country</td>
          <td>{values?.country}</td>
        </tr>
        <tr>
          <td>City</td>
          <td>{values?.city}</td>
        </tr>
        <tr>
          <td>Address</td>
          <td>{values?.address}</td>
        </tr>
        <tr>
          <td>Zip code</td>
          <td>{values?.zipCode}</td>
        </tr>
        <tr>
          <td>Share</td>
          <td>{values?.share || 0}%</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default NaturalInfo;
