import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Col, Image } from 'react-bootstrap';
import useLoader from '../../UI/helpers/useLoader';
import { NotificationManager } from 'react-notifications';

const CreateRoomModal = ({ asset, show, onHide, onSubmit }) => {
  const [name, setName] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);

  const handleCreateRoom = () => {
    if (!name) {
      NotificationManager.error('Add room name first');
      return;
    }
    onSubmit({ name, is_private: isPrivate });
    setName('');
    setIsPrivate(false);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create chat room for {asset?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            <label for="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label for="is_private">Room type</label>
            <select
              className="form-control"
              id="is_private"
              value={isPrivate}
              onChange={(e) => setIsPrivate(JSON.parse(e.target.value))}
            >
              <option value={true}>Private</option>
              <option value={false}>Public</option>
            </select>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleCreateRoom}>
          Submit
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateRoomModal;
