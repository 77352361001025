import { GOOGLE_API_KEY } from '../../config';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import useSupercluster from 'use-supercluster';
import { reducer, init } from './reducer';
import { useReducer, useRef, useEffect } from 'react';

const DynamicMap = ({
  defaultCenter = {
    lat: 60.1699,
    lng: 24.9384,
  },
  defaultZoom = 10,
  data = [],
}) => {
  const mapRef = useRef();
  const [state, dispatch] = useReducer(reducer, {}, init);
  const { clusters, supercluster } = useSupercluster({
    points: state.items,
    bounds: state.bounds,
    zoom: state.zoom,
    options: { radius: 100, maxZoom: 20 },
  });
  useEffect(() => {
    dispatch({ type: 'reset', payload: { items: data } });
  }, [data]);

  const onChange = ({ zoom, bounds }) => {
    dispatch({
      type: 'change',
      payload: {
        zoom,
        bounds: [bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat],
      },
    });
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map;
      }}
      onChange={onChange}
    >
      {clusters.map((cluster) => {
        const [lng, lat] = cluster.geometry.coordinates;

        const { id } = cluster;
        return (
          <Marker
            key={id}
            supercluster={supercluster}
            mapRef={mapRef}
            cluster={cluster}
            lng={lng}
            lat={lat}
          />
        );
      })}
    </GoogleMapReact>
  );
};

export default DynamicMap;
