import React from 'react';
import { ListGroup, Form, Button } from 'react-bootstrap';

const ALLOWED_OPERATORS = [
  'gt',
  'gte',
  'lte',
  'lt',
  'eq',
  'ne',
  'exists',
  'in',
];
const ALLOWED_TYPES = [
  'str',
  'bool',
  'int',
  'float',
  'date',
  'object_id',
  'list[object_id]',
  'list',
];

const ConditionItem = ({
  item,
  idx,
  onUpdate = () => {},
  onDelete = () => {},
}) => {
  return (
    <ListGroup.Item>
      <Form.Group className="mb-3" controlId="subject_field">
        <Form.Label>Subject field</Form.Label>
        <Form.Control
          type="text"
          placeholder="user._id"
          name="subject_field"
          value={item?.subject_field || ''}
          onChange={(e) => onUpdate(e, item.id)}
        />
        <Form.Text className="text-muted">user field for comparison</Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="object_field">
        <Form.Label>Object field</Form.Label>
        <Form.Control
          type="text"
          placeholder="asset.owner"
          name="object_field"
          value={item?.object_field || ''}
          onChange={(e) => onUpdate(e, item.id)}
        />
        <Form.Text className="text-muted">
          object field for comparison
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="subject_constant">
        <Form.Label>Subject constant</Form.Label>
        <Form.Control
          type="text"
          placeholder="hardcoded"
          name="subject_constant"
          value={item?.subject_constant || ''}
          onChange={(e) => onUpdate(e, item.id)}
        />
        <Form.Text className="text-muted">
          constant for comparison with user field
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="object_constant">
        <Form.Label>Object constant</Form.Label>
        <Form.Control
          type="text"
          placeholder="hardcoded"
          name="object_constant"
          value={item?.object_constant || ''}
          onChange={(e) => onUpdate(e, item.id)}
        />
        <Form.Text className="text-muted">
          constant for comparison with object field
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="operator">
        <Form.Label>Operator</Form.Label>
        <Form.Control
          as={'select'}
          aria-label="Operator"
          name="operator"
          value={item?.operator || ''}
          onChange={(e) => onUpdate(e, item.id)}
          className="custom-select"
        >
          <option value="">&mdash; Select operator &mdash;</option>
          {ALLOWED_OPERATORS.map((item, index) => (
            <option key={`${item}-${index}-operator-option`} value={item}>
              {item}
            </option>
          ))}
        </Form.Control>
        <Form.Text className="text-muted">
          operator to compare specified fields
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="field_type">
        <Form.Label>Field type</Form.Label>
        <Form.Control
          as={'select'}
          aria-label="Field type"
          name="field_type"
          value={item?.field_type || ''}
          onChange={(e) => onUpdate(e, item.id)}
          className="custom-select"
        >
          <option value="">&mdash; Select field type &mdash;</option>
          {ALLOWED_TYPES.map((item, index) => (
            <option key={`${item}-${index}-field-type-option`} value={item}>
              {item}
            </option>
          ))}
        </Form.Control>
        <Form.Text className="text-muted">
          type of the specified field
        </Form.Text>
      </Form.Group>

      <div className="row mt-3">
        <div className="col"></div>
        <div className="col d-flex justify-content-end align-items-center">
          <Button onClick={() => onDelete(idx)} variant="danger">
            Remove
          </Button>
        </div>
      </div>
    </ListGroup.Item>
  );
};

export default ConditionItem;
