import { useEffect, useState } from 'react';

const messages = {
  loading: <span className="h2 m-2">Loading...</span>,
  error: (
    <span>
      <span className="h2 m-2">We are waiting too long</span>
      <hr />
      <span className="text-muted">
        it might be caused by some server-side error
      </span>
    </span>
  ),
};

const Wait = () => {
  const [message, setMessage] = useState(messages.loading);
  useEffect(() => {
    const id = setTimeout(() => setMessage(messages.error), 5000);
    return () => clearTimeout(id);
  }, []);
  return (
    <div className="container-fluid h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="p-5 rounded shadow text-center">
        <div
          className="spinner-border"
          style={{ width: '3rem', height: '3rem' }}
          role="status"
        ></div>
        {message}
      </div>
    </div>
  );
};

export default Wait;
