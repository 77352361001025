import { useContext, useEffect, useState } from 'react';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { createAssetStructure } from '../../api/utils/asset/structures/setAssetStructure';
import SmartForm from '../Form/SmartForm';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import ButtonActionForm from '../Form/elements/ButtonActionForm';
import UserInfoContext from '../../contexts/UserInfoContext';
import { useHistory } from 'react-router-dom';

const AssetStructureCreate = () => {
  const history = useHistory();
  const { app, merchant, setInfo } = useContext(UserInfoContext);
  const [formData, setFormData] = useState();

  const onSubmit = async (data) => {
    if (!merchant?.id) {
      NotificationManager.error('Select merchant first');
      return;
    }

    const resultHandler = (data) => {
      NotificationManager.success(
        'Structure created. Redirecting...',
        'Success',
        1500
      );

      setTimeout(() => {
        history.push('/assetstructures');
      }, 1510);
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    const res = await createAssetStructure(
      {
        ...data,
        merchant: merchant.id,
        properties: {
          category_a: {
            is_required: true,
            type: 'enum',
            values: [
              {
                name: 'Tennis ball',
                value: 'tennis_ball',
              },
              {
                name: 'Catana',
                value: 'catana',
              },
            ],
          },
        },
      },
      errorHandler
    );
    if (res && res.status === 'accept') {
      return resultHandler(res);
    }
  };

  // TODO: Add proper Asset structure builder form if necessary
  return (
    <div>
      <NotificationContainer />
      <SmartForm
        onValid={() => {}}
        onSubmit={onSubmit}
        defaultValues={formData}
      >
        <h3 className="d-flex justify-content-between">
          <span>Create asset structure</span>
        </h3>

        <hr />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <InputFieldWithValidation
                className="mb-3"
                label="name"
                name="name"
              />
            </div>
          </div>
        </div>
        <ButtonActionForm
          label="Save changes"
          name="active"
          value={true}
          type="submit"
          className="btn btn-primary mt-2"
        />
        <br />
      </SmartForm>
    </div>
  );
};

export default AssetStructureCreate;
