import { useContext, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import useSearchString from '../../utils/useSearchString';
import AnySelector from '../UI/AnySelector';
import Icon from '../UI/Icon';
import getAdminRolesList from '../../api/utils/role/getAdminRolesList';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import Wait from './../UI/Wait';
import { getConsumerList } from '../../api/utils/consumer/getConsumerList';

const ConsumerList = () => {
  const [consumerList, setConsumerList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState('');

  const userInfo = useContext(UserInfoContext);
  const { merchant = null, merchant_name = '' } = useSearchString();
  const merchantId = merchant
    ? merchant
    : userInfo.merchant?.id
    ? userInfo.merchant.id
    : -1;
  const merchantName = merchant
    ? merchant_name
    : userInfo.merchant
    ? userInfo.merchant.name
    : '';
  const pageTitle = `Consumers ${merchantName ? `(${merchantName})` : ''}`;

  const [loading, setLoading] = useState(false);

  const { app } = useContext(UserInfoContext);
  let isMounted = true;

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (merchantId) {
      setLoading(true);
      fetchConsumers();
    }
  }, [merchantId]);

  const fetchConsumers = async () => {
    const resultHandler = (data) => {
      if (isMounted) {
        if (data && data.status === 'accept' && data.consumers) {
          setConsumerList(data.consumers);
          setLoading(false);
        } else {
          setLoading(false);
          setConsumerList([]);
        }
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setConsumerList([]);
      setLoading(false);
    };

    getConsumerList(
      { id: merchantId === -1 ? null : merchantId },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const columns = useMemo(
    () => [
      {
        name: '#',
        width: '60px',
        cell: (_, index) => index + 1,
      },
      {
        name: 'Email',
        cell: ({ email }) => email,
      },
      {
        name: 'First name',
        cell: ({ first_name: name }) => name,
      },
      {
        name: 'Last name',
        cell: ({ last_name: lastname }) => lastname,
      },
      {
        name: 'Telephone',
        cell: ({ telephone: phone }) => phone,
      },
      {
        name: 'Actions',
        cell: (row) => (
          <Link to={`/consumer/edit?id=${row._id}`}>
            <Icon iconName="bi-pencil" />
          </Link>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getAdminRolesList({ merchant: merchantId, appid: app.id })
      .then(({ roles }) => roles)
      .then(setRoles);
  }, [merchantId]);

  useEffect(() => {
    setFilteredList(
      consumerList.filter((consumer) => {
        if (!role) return true;
        return consumer?.apps?.roles?.includes(role);
      })
    );
  }, [consumerList, role]);

  return (
    <div>
      {/* <Link to={`/consumer/create`} className="btn btn-outline-primary mt-1 mb-3">
        <i className="bi-plus-square ml-1 mr-2 " /> Create Consumer
      </Link> */}
      <NotificationContainer />
      <h2>{pageTitle}</h2>
      <div className="m-1">
        <AnySelector
          label="— Select role —"
          items={roles}
          nameSelector="name"
          valueSelector="_id"
          setItem={setRole}
          resetItem={{ value: '', name: 'all' }}
        />
      </div>
      <DataTable
        dense
        striped
        progressPending={loading}
        progressComponent={
          <div className="p-5">
            <Wait />
          </div>
        }
        highlightOnHover
        data={filteredList}
        columns={columns}
      />
    </div>
  );
};

export default ConsumerList;
