import { useEffect, useState } from 'react';
import useSearchString from '../../utils/useSearchString';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import prepareToPublish from './helpers/prepareToPublish';
import { Modal, Button } from 'react-bootstrap';
import VelocityRuleEditForm from './VelocityRuleEditForm';
import getAdminVelocityRule from './../../api/utils/velocityRule/getAdminVelocityRule';
import {
  deleteVelocityRule,
  updateVelocityRule,
} from '../../api/utils/velocityRule/setAdminVelocityRule';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';

const VelocityRuleEdit = () => {
  const history = useHistory();
  const [velocityRule, setVelocityRule] = useState();

  const { id } = useSearchString();

  const [showConfirmRemoveVelocityRule, setShowConfirmRemoveVelocityRule] =
    useState(false);
  const handleShowConfirmRemoveVelocityRule = () =>
    setShowConfirmRemoveVelocityRule(true);
  const handleCloseConfirmRemoveVelocityRule = () =>
    setShowConfirmRemoveVelocityRule(false);
  const showConfirmDialog = () => {
    handleShowConfirmRemoveVelocityRule();
  };
  const [progress, done] = useLoader([velocityRule]);

  useEffect(() => {
    getAdminVelocityRule({ id })
      .then((data) => {
        console.log('velocity rule response: data ==', data);
        return data.velocity_rule;
      })
      //.then(({ product, types }) => ({ info: product, types }))
      .then(setVelocityRule)
      .catch(console.error);
  }, [id]);

  const onSubmit = (velocityRule) => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Saved');
        history.goBack();
      } else {
        NotificationManager.error('Error');
      }
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    updateVelocityRule(
      { ...prepareToPublish(id, velocityRule) },
      errorHandler
    ).then((data) => {
      return resultHandler(data);
    });
  };

  const onDelete = () => {
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        NotificationManager.success('Velocity Rule deleted');
        history.goBack();
      } else {
        NotificationManager.error('Error');
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };

    deleteVelocityRule({ id }, errorHandler)
      .then((data) => {
        return resultHandler(data);
      })
      .catch(console.error);
  };

  return done ? (
    velocityRule?._id ? (
      <div>
        <NotificationContainer />
        <VelocityRuleEditForm
          velocityRule={velocityRule}
          onSubmit={onSubmit}
          onDelete={() => {
            showConfirmDialog();
          }}
        />
        <Modal
          show={showConfirmRemoveVelocityRule}
          onHide={handleCloseConfirmRemoveVelocityRule}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>`Do you want to remove Velocity Rule {velocityRule.name} ?`</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseConfirmRemoveVelocityRule}
            >
              Close
            </Button>
            <Button variant="danger" onClick={() => onDelete()}>
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    ) : (
      'not found'
    )
  ) : (
    <LoadingBar progress={progress} />
  );
};

export default VelocityRuleEdit;
