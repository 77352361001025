import Honkio from '../../../Honkio';

export const getNotifyMessageList = (options = {}, errorHandler = () => {}) => {
  const { merchant, application, query_skip, query_count, debug } = options;
  return Honkio().mainShop.userFetch(
    'merchantnotifymessagelist',
    {
      debug,
      query_merchant: merchant,
      query_application: application,
      query_skip,
      query_count,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export const getNotifyMessage = (options = {}, errorHandler = () => {}) => {
  const { notifyMessageID, debug } = options;
  return Honkio().mainShop.userFetch(
    'merchantnotifymessageget',
    {
      debug,
      query_id: notifyMessageID,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

const notifyMessageSet = (options = {}, errorHandler = () => {}) => {
  return Honkio().mainShop.userFetch(
    'merchantnotifymessageset',
    { ...options, version: 3 },
    errorHandler
  );
};

export const createNotifyMessage = (options = {}, errorHandler = () => {}) => {
  return notifyMessageSet({ ...options, action: 'create' }, errorHandler);
};

export const updateNotifyMessage = (options = {}, errorHandler = () => {}) => {
  return notifyMessageSet({ ...options, action: 'update' }, errorHandler);
};

export const deleteNotifyMessage = (options = {}, errorHandler = () => {}) => {
  return notifyMessageSet({ ...options, action: 'delete' }, errorHandler);
};
