import Honkio from '../../Honkio';

const getConsumerDevice = (options = {}, errorHandler = {}) => {
  const { debug, id } = options;
  return Honkio().mainShop.userFetch(
    'adminconsumergetdevices',
    {
      debug,
      id,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getConsumerDevice;
