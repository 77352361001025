import Honkio from '../../../Honkio';

const addUserDevice = async function (options = {}, errorHandler = {}) {
    const {
        token,
        provided_user_agent,
        user_id,
        user_ip,
        application,
        merchant,
        name,
    } = options;

    return Honkio().mainShop.userFetch(
        'userdeviceadd',
        {
            token,
            user_id,
            user_ip,
            provided_user_agent,
            application,
            merchant,
            name,
            version: 3,
            action: 'update',
        },
        errorHandler
    );
};

export default addUserDevice;
