const assetTypeToState = (assetType) => {
  const {
    _id,
    name: name1,
    description: description1,
    merchant,
    application,
  } = assetType;
  const fields = assetType.properties.map((e) => {
    return {
      name: e.property_name,
      description: e.property_description,
      required: e.property_required ? 'yes' : 'no',
      type: e.property_type,
    };
  });
  const id_lists = assetType.id_lists || [];

  return {
    _id,
    name: name1,
    description: description1,
    merchant,
    application,
    fields: fields,
    id_lists,
  };
};

export default assetTypeToState;
