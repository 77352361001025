import Honkio from '../../../Honkio';

const getUserSettings = async (options = {}, errorHandler = () => {}) => {
  const { application, merchant } = options;

  return Honkio().mainShop.userFetch(
    'settingsuserget',
    {
      application,
      merchant,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getUserSettings;
