import { useEffect, useState, useMemo } from 'react';
import LoadingBar from '../../UI/LoadingBar';
import {
  Modal,
  Image,
  Table,
  Card,
  Container,
  Row,
  Col,
  ListGroup,
  Badge,
  Button,
  ButtonGroup,
} from 'react-bootstrap';
import useLoader from '../../UI/helpers/useLoader';
import getAccessRule from '../../../api/utils/accessRules/getAccessRule';

const AccessRulePreviewModal = ({ ruleId, show, onHide }) => {
  const [accessRule, setAccessRule] = useState(null);
  const [progress, done] = useLoader([accessRule, show, ruleId, onHide]);
  const [accesses, setAcesses] = useState([]);

  useEffect(() => {
    if (ruleId) {
      getAccessRule({ id: ruleId })
        .then((res) => {
          return res?.access_rule;
        })
        .then(setAccessRule)
        .catch((err) => {
          console.log('error getting access rule', err);
        });
    }
  }, [ruleId]);

  const container = (
    <Table striped hover size="sm">
      <tbody>
        <tr>
          <td>Name</td>
          <td>{accessRule?.name}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{accessRule?.description}</td>
        </tr>
        <tr>
          <td>Model</td>
          <td>{accessRule?.model_name}</td>
        </tr>
        <tr>
          <td>Actions</td>
          <td>
            {accessRule?.actions?.length
              ? accessRule.actions.join(', ')
              : 'None'}
          </td>
        </tr>
        <tr>
          <td>Roles</td>
          <td>
            {accessRule?.roles?.length ? accessRule.roles.join(', ') : 'None'}
          </td>
        </tr>
        <tr>
          <td>Is global</td>
          <td>{accessRule?.is_global ? 'true' : 'false'}</td>
        </tr>
        <tr>
          <td colSpan={2}>
            <pre>{JSON.stringify(accessRule?.conditions, null, 2)}</pre>
          </td>
        </tr>
      </tbody>
    </Table>
  );

  const onClickHide = () => {
    setAccessRule(null);
    onHide();
  };

  return (
    <Modal show={show} onHide={onClickHide}>
      <Modal.Header closeButton>
        <Modal.Title>Rule #{accessRule?._id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {done ? (
          accessRule ? (
            container
          ) : (
            'not found'
          )
        ) : (
          <LoadingBar progress={progress} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClickHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccessRulePreviewModal;
