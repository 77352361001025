import { useEffect, useState, useContext } from 'react';
import getEvents from '../../api/utils/event/getEvents';
import useSearchString from '../../utils/useSearchString';
import EventList from './EventList';
import UserInfoContext from '../../contexts/UserInfoContext';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { deleteEvent } from '../../api/utils/event/setEvent';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const Events = () => {
  const [eventList, setEventList] = useState([]);
  const [selected, setSelected] = useState({});
  const userInfo = useContext(UserInfoContext);
  const {
    merchant = null,
    parent = 'None',
    asset = null,
    asset_name = null,
  } = useSearchString();
  const merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;

  const [loading, setLoading] = useState(false);
  let isMounted = true;
  const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    setLoading(true);
    fetchEvents(1);
    return () => {
      isMounted = false;
    };
  }, [merchant, parent]);

  useEffect(() => {
    fetchEvents(1);
  }, [perPage]);

  const fetchEvents = async (page) => {
    if (isMounted) {
      getEvents(
        {
          asset: asset,
          merchantId: merchantId,
          page: page - 1,
          query_count: perPage,
          query_child_merchants: true,
        },
        errorHandler
      )
        .then((data) => {
          return resultHandler(data);
        })
        .catch(console.log);
    }
  };

  const resultHandler = (data) => {
    if (data && data.status === 'accept') {
      if (isMounted) {
        setEventList(data.events);
        setTotalRows(data.total);
        setLoading(false);
      }
    }
  };

  const errorHandler = (error) => {
    NotificationManager.error(error.description, 'Error', 4000);
    setEventList([]);
    setTotalRows(0);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    fetchEvents(page);
  };
  const handleRowsPerPage = (count) => {
    setPerPage(count);
  };

  const handleDeleteEvent = async (id) => {
    const resultHandler = (data) => {
      NotificationManager.success('Removed successfully!');
      fetchEvents(1);
    };
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
    };
    const res = await deleteEvent({ id }, errorHandler);
    if (res && res.status === 'accept') resultHandler(res);
  };

  return (
    <div className="w-100 h-100">
      <NotificationContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h2>{`Events ${asset_name ? `(${asset_name})` : ''}`}</h2>
          </div>
          <div className="col-3">
            <Button
              variant="primary"
              as={Link}
              to="/events/create"
              className="float-right"
            >
              Create event
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <EventList
              data={eventList}
              selectItem={setSelected}
              id={selected.id}
              loading={loading}
              totalRows={totalRows}
              handlePageChange={handlePageChange}
              rowsCountPerPage={perPage}
              merchant={merchantId}
              emptyText={
                merchantId
                  ? 'The event list is empty'
                  : 'Please, select merchant first'
              }
              perPageChoices={PER_PAGE_CHOICES}
              handleRowsPerPage={handleRowsPerPage}
              handleDeleteEvent={handleDeleteEvent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
