import Honkio from '../../Honkio';
import _ from 'lodash';

const getAllowedRegStatuses = (options = {}, errorHandler = {}) => {
  const opts = _.cloneDeep(options);
  opts.action = 'read';
  opts.version = 3;
  return Honkio().mainShop.userFetch(
    'adminordermodelregstatuses',
    opts,
    errorHandler
  );
};

export default getAllowedRegStatuses;
