import Honkio from '../../Honkio';

const getGlobalSettings = async function (options = {}, errorHandler = {}) {
  return Honkio().mainShop.userFetch(
    'adminsettingsget',
    {
      ...options,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getGlobalSettings;
