import Honkio from '../../Honkio';

const setAssetData = async function (
  asset,
  merchantId = null,
  appid = null,
  errorHandler = {},
  action = ''
) {
  return Honkio().mainShop.userFetch(
    'adminassetset',
    {
      ...asset,
      merchant: merchantId,
      application: appid,
      version: 3,
      action: action ? action : asset?.id ? 'update' : 'create',
    },
    errorHandler
  );
};

export default setAssetData;
