import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import listOrderModelTemplates from '../../../api/utils/templateModels/templateOrderModels/listOrderModelTemplates';
import { deleteOrderModelTemplate } from '../../../api/utils/templateModels/templateOrderModels/setOrderModelTemplate';
import OrderModelTemplatesTable from './OrderModelTemplatesTable';
import UserInfoContext from '../../../contexts/UserInfoContext';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const OrderModelTemplatesList = () => {
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [orderModelTemplates, setOrderModelTemplates] = useState([]);

    const [loading, setLoading] = useState(false);

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState();

    const pageTitle = `Order model templates`;

    useEffect(() => {
        if (merchantId && appId) fetchOrderModelTemplateslist(1);
    }, [perPage]);

    const fetchOrderModelTemplateslist = async (page = 1) => {
        setLoading(true);

        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setOrderModelTemplates(data.ordermodel_templates);
                setTotalRows(data.total);
            } else {
                setOrderModelTemplates([]);
                setTotalRows(0);
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
            setOrderModelTemplates([]);
            setTotalRows(0);
        };

        try {
            const result = await listOrderModelTemplates(
                {
                    query_merchant: merchantId,
                    query_application: appId,
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                },
                errorHandler
            );
            resultHandler(result);
        } catch (error) {
            errorHandler(error);
        }
    };

    const onDeleteClick = async (id) => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data?.status === 'accept') {
                fetchOrderModelTemplateslist(1);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setLoading(true);
            const res = await deleteOrderModelTemplate(
                { id, application: appId, merchant: merchantId },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
            console.error(error);
        }
    };

    const handlePageChange = (page) => {
        fetchOrderModelTemplateslist(page);
    };

    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    return (
        <div>
            <NotificationContainer />
            <div className="row">
                <div className="col-12 col-md-8">
                    <h2>{pageTitle}</h2>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-end align-items-center">
                    <Button
                        variant="outline-primary"
                        as={Link}
                        to={`/template-models/ordermodels/create`}
                        className="btn btn-outline-primary mt-1 mb-3"
                    >
                        <i className="bi-plus-square ml-1 mr-2" />
                        Create new
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <OrderModelTemplatesTable
                        data={orderModelTemplates}
                        loading={loading}
                        onDeleteClick={onDeleteClick}
                        rowsCountPerPage={perPage}
                        handleRowsPerPage={handleRowsPerPage}
                        perPageChoices={PER_PAGE_CHOICES}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default OrderModelTemplatesList;
