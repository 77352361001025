import React, { Component } from 'react';
import WidgetComponent from './WidgetComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Index, TimeRange, TimeSeries } from 'pondjs';
import {
  BarChart,
  ChartContainer,
  ChartRow,
  Charts,
  EventMarker,
  Legend,
  LineChart,
  ScatterChart,
  styler,
  YAxis,
} from 'react-timeseries-charts';
import { format } from 'd3-format';
import moment from 'moment';
import 'moment/locale/fi';
import HonkioAPI from '../../../api/Honkio';

class BuildingInvoicesComponent extends Component {
  constructor(props) {
    super(props);
    this.refreshData = this.refreshData.bind(this);
    let monthBack =
      this.props.settings && this.props.settings.monthBack
        ? this.props.settings.monthBack
        : 10;
    let monthForward =
      this.props.settings &&
      this.props.settings.monthForward &&
      this.props.settings.monthForward
        ? this.props.settings.monthForward
        : 2;
    let start = moment()
      .subtract(parseInt(monthBack), 'month')
      .startOf('month')
      .toDate();
    let end = moment()
      .add(parseInt(monthForward), 'month')
      .startOf('month')
      .toDate();
    this.state = {
      loading: true,
      timerange: new TimeRange([start.getTime(), end.getTime()]),
      start: start,
      end: end,
    };
  }

  componentDidMount() {
    this.loadBuildings();
    // this.refreshData();
    let that = this;
    this.setContainerRef = (element) => {
      that.container = element;
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let newState = {};
    if (nextProps.monthBack) {
      let monthBack =
        this.props.settings && this.props.settings.monthBack
          ? this.props.settings.monthBack
          : 10;
      let monthForward =
        this.props.settings &&
        this.props.settings.monthForward &&
        this.props.settings.monthForward
          ? this.props.settings.monthForward
          : 2;
      let start = moment()
        .subtract(parseInt(monthBack), 'month')
        .startOf('month')
        .toDate();
      let end = moment()
        .add(parseInt(monthForward), 'month')
        .startOf('month')
        .toDate();

      this.setState({
        timerange: new TimeRange([start.getTime(), end.getTime()]),
        start: start,
        end: end,
      });
    }
  }

  loadBuildings() {
    let that = this;
    let merchantgetbuildings = async function () {
      let response = await HonkioAPI().userShop.userFetch(
        'merchantbuildinglist',
        { merchant_id: localStorage.getItem('merchant_id') }
      );
      let buildings = response.buildings;
      let buildingCategories = [];
      response.buildings.forEach((building) => {
        buildingCategories.push({
          key: building.name,
          label: building.name,
        });
      });
      that.setState({
        buildings: buildings,
        buildingCategories: buildingCategories,
        loading: false,
      });
      that.refreshData(buildings);
    };
    merchantgetbuildings();
  }

  refreshData(buildingsList) {
    let that = this;
    that.setState({ loading: true });
    let merchantarealist = async function () {
      let buildings = buildingsList ? buildingsList : that.state.buildings;
      let buildingNames = {};
      let data = {};
      let points = [];
      let min = 0,
        max = 0,
        minDate = null;
      let indexes = [];

      // if (!timerange) {
      let timerange = that.state.timerange;
      // let queryDate = {
      //     'query_date': {
      //         'due_date': {
      //             '$gte': moment(timerange.begin()).format('YYYY-MM-DD hh:mm:ss'),
      //             '$lte': moment(timerange.end()).add(12, 'hours').format('YYYY-MM-DD hh:mm:ss')
      //         }
      //     }
      // }
      // }
      // query['query_start_date'] = ;
      // // }
      // // if (that.props.end_date) {
      // query['query_end_date'] = moment(timerange.end()).add(12, 'hours').format('YYYY-MM-DD hh:mm:ss');
      for (var i = 0; i < buildings.length; i++) {
        let building = buildings[i];
        buildingNames[building.id] = building.name;
        let query = {
          query_properties: { building: { eq: building.id } },
          // ...queryDate
        };
        let response = await HonkioAPI().userShop.userFetch(
          'merchantpaymentinvoicereport',
          query
        );
        console.error('RESPONSE');
        console.error(response);
        // let a = val
        let values = {};
        //new TimeRange([start.getTime(), end.getTime()])
        response.payment_invoices.forEach((val) => {
          let timestamp = moment(val.timestamp).startOf('month').toDate();
          const index = Index.getIndexString('1m', timestamp);
          if (indexes.indexOf(index) < 0) {
            indexes.push(index);
          }
          // alert(val.data ? 'data': 'i')
          const payment = parseInt(val.payment || 0);
          const invoice = parseInt(val.invoice || 0);
          const value = invoice - payment;
          // alert(value)
          values[index] = value;
          if (value > max) max = value;
          if (value < min) min = value;
          if (minDate === null) minDate = timestamp;
          if (timestamp < minDate) minDate = timestamp;
          // values.push([index, value])
        });

        data[building.id] = values;
        // points.push([building.name, ...values])
      }
      // alert(min + ' ' + max)
      points = [];
      indexes.sort((a, b) => {
        a = parseInt(a.replace(/\D/g, ''));
        b = parseInt(b.replace(/\D/g, ''));
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
      indexes.forEach((index) => {
        let vals = [];
        for (var i = 0; i < buildings.length; i++) {
          let building = buildings[i];
          if (data[building.id][index]) {
            vals.push(data[building.id][index]);
          } else {
            vals.push(0);
          }
        }
        points.push([index, ...vals]);
      });
      let names = Object.values(buildingNames);
      let columns = ['index', ...names];
      const name = 'index';
      // points = []
      // let incomeSeries = new TimeSeries({ name, columns, points });
      const incomeSeries = new TimeSeries({
        name: 'index',
        columns: columns,
        points: points,
      });
      //
      // const incomeSeries = {
      //      "name": "HI_ASOS",
      //    "utc": true,
      //    "columns": ["index", "income"],
      // }
      // let  = response.payment_invoices;
      // alert(min)
      // alert(names)
      that.setState({
        incomeSeries: incomeSeries,
        min: min, // min,
        max: max, // max,
        avg: 0, // avg,
        names: names,
        columns: columns, // avg,
        // timerange: new TimeRange([minDate.getTime(), that.state.end.getTime()]),
        loading: false,
      });
      //  let min = 0;
      // let max = 0;
      // let avg = 0;
      // let points = [];
      //
      // response.payment_invoices.forEach(val => {
      //   // let a = val
      //
      //   const index = Index.getIndexString("1m", moment(val.timestamp).toDate());
      //   const payment = parseInt(val.payment || 0 );
      //   const invoice = parseInt(val.invoice || 0 );
      //   const value = invoice-payment
      //   // alert(moment(val.timestamp).toDate() + ' ' + value)
      //   points.push([index, value]);
      //   if (value > max) max = value;
      //   if (value < min) min = value;
      //   avg += value;
      // });
      // avg = 0//parseFloat(avg / response.events.length) 0;
      //
      //
      //
    };
    merchantarealist();
  }

  renderMarker = () => {
    if (this.state.markerMode === 'flag') {
      return (
        <EventMarker
          type="flag"
          axis="axis"
          event={this.state.trackerEvent}
          column="income"
          info={[{ label: 'Anomaly', value: this.state.trackerValue }]}
          infoTimeFormat="%Y"
          infoWidth={120}
          markerRadius={2}
          markerStyle={{ fill: 'black' }}
        />
      );
    } else {
      return (
        <EventMarker
          type="point"
          axis="axis"
          event={this.state.trackerEvent}
          column="income"
          markerLabel={this.state.trackerValue}
          markerLabelAlign="left"
          markerLabelStyle={{ fill: 'black', stroke: 'white' }}
          markerRadius={3}
          markerStyle={{ fill: 'black' }}
        />
      );
    }
  };

  handleTrackerChanged = (t) => {
    if (t) {
      const e = this.state.incomeSeries.atTime(t);
      const eventTime = new Date(
        e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
      );
      const eventValue = e.get('temperature');
      const v = `${eventValue > 0 ? '+' : ''}${eventValue}°C`;
      this.setState({ tracker: eventTime, trackerValue: v, trackerEvent: e });
    } else {
      this.setState({ tracker: null, trackerValue: null, trackerEvent: null });
    }
  };

  handleTimeRangeChange = (timerange) => {
    this.setState({ timerange });
  };

  render() {
    const style = styler(this.state.columns, 'Paired');
    const points = [];
    let line;
    let summary;
    const axisStyle = {
      values: {
        labelColor: 'grey',
        labelWeight: 100,
        labelSize: 11,
      },
      axis: {
        axisColor: 'grey',
        axisWidth: 1,
      },
    };
    const min = this.state.min;
    const max = this.state.max;
    // const style = styler([{ key: "income", color: "#A5C8E1", selected: "#2CB1CF" }])
    let drawChart = (height, width) => {
      // const selectedValue = this.state.selection
      //     ? `${formatter(+this.state.selection.event.value(this.state.selection.column))}b`
      //     : "--";
      const formatter = format('.2s');
      const highlight = this.state.highlight;
      let infoValues = [];
      let infoNetValues = [];
      if (highlight) {
        const trafficText = `${formatter(
          highlight.event.get(highlight.column)
        )}`;
        infoValues = [
          { label: 'Building', value: highlight.column },
          { label: 'Value', value: trafficText },
        ];
        infoNetValues = [
          { label: 'Value' + highlight.column, value: trafficText },
        ];
      }
      let chartType =
        this.props.settings && this.props.settings.chartType
          ? this.props.settings.chartType
          : 'line';
      return (
        <ChartContainer
          timeRange={this.state.timerange}
          enablePanZoom={true}
          onTimeRangeChanged={this.handleTimeRangeChange}
          // timeAxisStyle={axisStyle}
          // onTrackerChanged={this.handleTrackerChanged}
          // enablePanZoom={true}
          // height={this.props.height * ROW_HEIGHT - 75}
          // width={this.props.width * 100 }
          // height={height}
          // width={width}
          width={width}
          height={height}
          // onTrackerChanged={this.handleTrackerChanged}
          onBackgroundClick={() => {
            // alert(442)
            this.setState({ selection: null });
          }}
          // width={this.container.offsetWidth}
        >
          <ChartRow
            // height={this.props.height * ROW_HEIGHT - 75}
            // width={this.props.width * 100 }
            height={height}
            width={width}
            // width={this.container.offsetWidth}
          >
            <YAxis
              id="axis"
              label="Income"
              min={this.state.min}
              max={this.state.max}
              // format=",.0f"
              width="100"
              // type="power"
              type="linear"
            />
            <Charts>
              {this.state.names.map((name, index) => {
                switch (chartType) {
                  case 'bar':
                    return (
                      <BarChart
                        axis="axis"
                        spacing={1}
                        size={5}
                        width={width}
                        offset={index * 5.0}
                        series={this.state.incomeSeries}
                        columns={[name]}
                        minBarHeight={5}
                        style={style}
                        highlighted={this.state.highlight}
                        onHighlightChange={(highlight) =>
                          this.setState({ highlight })
                        }
                        selected={this.state.selection}
                        onSelectionChange={(selection) =>
                          this.setState({ selection })
                        }
                        info={infoValues}
                        infoTimeFormat={(index) =>
                          moment(index.begin()).format("Do MMM 'YY")
                        }
                      />
                    );
                  case 'line':
                  default:
                    return [
                      <ScatterChart
                        axis="axis"
                        width={width}
                        series={this.state.incomeSeries}
                        columns={[name]}
                        style={style}
                      />,
                      <LineChart
                        axis="axis"
                        width={width}
                        series={this.state.incomeSeries}
                        columns={[name]}
                        style={style}
                      />,
                    ].map((o) => o);
                }
              })}
              {/*                    <BarChart*/}
              {/*axis="axis"*/}
              {/*spacing={3}*/}
              {/*width={width}*/}
              {/*offset={10}*/}
              {/*series={this.state.incomeSeries}*/}
              {/*columns={this.state.columns}*/}
              {/*minBarHeight={1}*/}
              {/*style={style} />*/}
              {/*                  {chartType === 'line' ? this.renderMarker() : ''}*/}
            </Charts>
          </ChartRow>
        </ChartContainer>
      );
    };

    let drawLegend = () => {
      return (
        <div>
          Buildings
          <Legend
            categories={this.state.buildingCategories}
            style={style}
            type="dot"
          />
        </div>
      );
    };

    return (
      <WidgetComponent
        position={this.props.position}
        height={this.props.height}
        width={this.props.width}
        icon="fas fa-money-bill-wave fa-2x"
        name={this.props.name}
        onRename={this.props.onRename}
        type="Building cashflow"
        onRemoveClick={this.props.onRemoveClick}
        onSettingsClick={this.props.onSettingsClick}
        summary1={
          this.state.loading || !this.state.incomeSeries
            ? () => <CircularProgress size={35} />
            : drawLegend()
        }
        chart={
          this.state.loading || !this.state.incomeSeries
            ? () => <CircularProgress size={35} />
            : drawChart
        }
        configFields={[]}
      />
    );
  }
}

export default BuildingInvoicesComponent;
