import HonkioAPI from '../../../Honkio';

/**
 *
 * @param {*} options - {object: string, type: string }
 * @param {*} options.type - file type
 * @param {*} options.query_owner email of user who owns this file
 * @param {*} options.query_ids the list of file ids
 * @param {*} options.query_merchant - merchant._id value
 * @param {*} options.query_skip pagination skip documents count
 * @param {*} options.query_count pagination returned number of documents
 * @param {*} options.query_sort_field - field to sort by
 * @param {*} options.query_sort_direct - -1 for ascending, 1 - for descending order
 * @param {*} options.query_search - search substring in file name attribute
 * @param {*} errorHandler - callback function, triggered on error in the response
 * @returns Promise
 */
export const fileList = (options = {}, errorHandler = () => {}) => {
    const {
        query_type,
        query_category,
        query_owner,
        query_merchant,
        query_skip,
        query_count,
        query_sort_field,
        query_sort_direction,
        query_search,
        query_ids,
    } = options;
    return HonkioAPI().mainShop.userFetch(
        'adminfileminiolist',
        {
            query_type,
            query_category,
            query_owner,
            query_merchant,
            query_skip,
            query_count,
            query_sort_field,
            query_sort_direction,
            query_search,
            query_ids,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
