import { useEffect, useMemo, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import getAppInfo from '../../api/utils/app/getAppInfo';
import { getMerchantOrderProcessList } from '../../api/utils/orderModel/getMerchantOrderProcessList';
import { deleteAdminOrderModel } from '../../api/utils/orderModel/setAdminOrderModel';
import useSearchString from '../../utils/useSearchString';
import Icon from '../UI/Icon';
import Wait from '../UI/Wait';
import UserInfoContext from '../../contexts/UserInfoContext';
import ReactTooltip from 'react-tooltip';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import { NotificationManager } from 'react-notifications';
import { Modal, Button, Form } from 'react-bootstrap';
import _ from 'lodash';
import setDefaultRecord from '../../api/utils/app/default/setDefaultRecord';

const OrderModelsList = () => {
    const [appInfo, setAppInfo] = useState();
    const [orderModelsList, setOrderModelsList] = useState([]);
    const userInfo = useContext(UserInfoContext);
    const { app, merchant } = useSearchString();
    const appid = app ? app : userInfo.app ? userInfo.app.id : null;

    const merchantId = merchant
        ? merchant
        : userInfo.merchant
        ? userInfo.merchant.id
        : null;

    const pageTitle = merchant
        ? 'Order models'
        : userInfo.merchant?.name
        ? `Order models (${userInfo.merchant.name})`
        : 'Order models (global)';

    const [showConfirmRemoveOrderModel, setShowConfirmRemoveOrderModel] =
        useState(false);
    const handleShowConfirmRemoveOrderModel = () =>
        setShowConfirmRemoveOrderModel(true);
    const handleCloseConfirmRemoveOrderModel = () =>
        setShowConfirmRemoveOrderModel(false);
    const [orderModelToRemove, setOrderModelToRemove] = useState({});

    const [loading, setLoading] = useState(false);

    const onSetDefault = async (id, is_default) => {
        const resultHandler = (res) => {
            if (res && res.status === 'accept') {
                let newOrderModelsList = _.cloneDeep(orderModelsList);
                const idx = newOrderModelsList.findIndex(
                    (item) => item._id === res.record._id
                );
                newOrderModelsList[idx].is_default = res.record.is_default;

                setOrderModelsList(newOrderModelsList);

                setLoading(false); // this helps re-render table

                // Alternative in case table starts glitching again
                // fetchOrderModelList(1);
            } else {
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
        };

        try {
            setLoading(true);
            const res = await setDefaultRecord(
                {
                    id,
                    is_default,
                    model_name: 'ordermodel',
                    merchant: merchantId,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoading(false);
            console.log('Error setting record as default: ', error);
        }
    };

    const columns = useMemo(
        () => [
            { name: '#', grow: 0, cell: (_, index) => index + 1 },
            { name: 'Name', grow: 1, cell: ({ name }) => name },
            {
                name: 'Default',
                grow: 0,
                omit: !userInfo?.merchant?.is_admin,
                cell: ({ _id, is_default }) => (
                    <Form.Switch
                        id={`is-default-switch-${_id}`}
                        defaultChecked={is_default}
                        onChange={() => {
                            onSetDefault(_id, !is_default);
                        }}
                        className="m-0"
                        name="is_default"
                        label=""
                    />
                ),
            },
            {
                name: 'Actions',
                grow: 1,
                right: true,
                cell: ({ _id, name, merchant }) => (
                    <div className="d-flex">
                        <Link
                            to={`/ordermodel/edit?omid=${_id}&MId=${merchant}`}
                        >
                            <Icon iconName="bi-pen" />
                        </Link>
                        <div
                            onClick={() => {
                                onDeleteClick(_id, name);
                            }}
                            data-tip
                            data-for="deleteTip"
                        >
                            <Icon iconName="btn bi-trash" />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </div>
                    </div>
                ),
            },
        ],
        [userInfo, orderModelsList]
    );

    const onDeleteClick = (id, name) => {
        setOrderModelToRemove({ id, name });
        handleShowConfirmRemoveOrderModel();
    };

    useEffect(() => {
        fetchAppInfo();
    }, [appid]);

    const fetchAppInfo = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setAppInfo(data.application);
            } else {
                setAppInfo({});
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setAppInfo({});
        };

        if (appid) {
            getAppInfo({ appid }, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setAppInfo({});
        }
    };

    useEffect(() => {
        fetchOrderModelList();
    }, [appInfo]);

    const fetchOrderModelList = async (page = 1) => {
        setLoading(true);
        if (appInfo && appInfo._id) {
            getMerchantOrderProcessList({
                merchantId: merchantId,
                appid: appInfo?._id,
            })
                .then(({ orderprocesses }) => orderprocesses)
                .then((result) => {
                    setLoading(false);
                    setOrderModelsList(result);
                });
        } else {
            if (!appid) {
                getMerchantOrderProcessList({ global: true })
                    .then(({ orderprocesses }) => orderprocesses)
                    .then((result) => {
                        setLoading(false);
                        setOrderModelsList(result);
                    });
            }
        }
    };

    const deleteOrderModel = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Deleted');
                fetchAppInfo();
            } else {
                NotificationManager.error('Error');
            }
            handleCloseConfirmRemoveOrderModel();
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            fetchOrderModelList();
        };
        deleteAdminOrderModel(orderModelToRemove.id, errorHandler).then(
            (data) => {
                return resultHandler(data);
            }
        );
    };

    return (
        <div>
            <NotificationContainer />
            <div className="row">
                <div className="col-12 col-md-8">
                    <h2>{pageTitle}</h2>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-end align-items-center">
                    <Button
                        variant="outline-primary"
                        as={Link}
                        to={`/ordermodel/create?${
                            appid ? `appid=${appid}` : ''
                        }${merchantId ? `&merchant=${merchantId}` : ''}`}
                        className="btn btn-outline-primary mt-1 mb-3"
                    >
                        <i className="bi-plus-square ml-1 mr-2 " /> Create new
                        model
                    </Button>
                </div>
            </div>
            <DataTable
                dense
                striped
                highlightOnHover
                data={orderModelsList}
                columns={columns}
                emptyText={'The list is empty'}
                progressPending={loading}
                progressComponent={
                    <div className="p-5">
                        <Wait />
                    </div>
                }
            />
            <Modal
                show={showConfirmRemoveOrderModel}
                onHide={handleCloseConfirmRemoveOrderModel}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Do you want to remove order model{' '}
                        <b>
                            {orderModelToRemove ? orderModelToRemove.name : ''}
                        </b>
                        ?{' '}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseConfirmRemoveOrderModel}
                    >
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => deleteOrderModel()}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default OrderModelsList;
