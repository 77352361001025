import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import getInfoByBusinessId from '../../../../api/utils/getInfoByBusinessId';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import _ from 'lodash';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const LegalInfo = ({ data }) => {
  const { entityType, values } = data;

  const [companyInfo, setCompanyInfo] = useState(null);

  useEffect(() => {
    if (values?.businessId) {
      fetchCompanyInfo(values?.businessId);
    }
  }, [values]);

  useEffect(() => {
    console.log('companyInfo ==', companyInfo);
  }, [companyInfo]);

  const fetchCompanyInfo = async (businessId) => {
    const info = await getInfoByBusinessId(values.businessId);
    if (info) {
      NotificationManager.success('Company info found by businessID');
      setCompanyInfo(info);
    } else {
      NotificationManager.error('Company info not found');
    }
  };

  return (
    <>
      <Table striped bordered hover>
        <tbody
          style={{
            wordWrap: 'break-word',
            overflow: 'hidden',
          }}
        >
          <tr>
            <td>Business ID</td>
            <td>{values?.businessId}</td>
          </tr>
          <tr>
            <td>Business activity</td>
            <td>{values?.businessActivity}</td>
          </tr>
          <tr>
            <td>Country</td>
            <td>{values?.country}</td>
          </tr>
          <tr>
            <td>City</td>
            <td>{values?.city}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>{values?.address}</td>
          </tr>
          <tr>
            <td>Zip code</td>
            <td>{values?.zipCode}</td>
          </tr>
          <tr>
            <td>Company name</td>
            <td>{values?.companyName}</td>
          </tr>
          <tr>
            <td>Share</td>
            <td>{values?.share || 0}%</td>
          </tr>
        </tbody>
      </Table>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Company info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ whiteSpace: 'pre-line' }}>
            {!_.isEmpty(companyInfo) ? (
              <pre className="overflow-hidden" style={{ maxWidth: '100%' }}>
                {JSON.stringify(companyInfo, null, 2)}
              </pre>
            ) : (
              <div>No company info...</div>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default LegalInfo;
