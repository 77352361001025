import SettingsLink from '../Settings/SettingsLink';

const GreyListMenu = () => {
  return (
    <div className="container">
      <div className="row row-cols-2">
        <SettingsLink to="/greylists/greylist?type=address">
          Address greylists
        </SettingsLink>
        <SettingsLink to="/greylists/greylist?type=network">
          Network greylists
        </SettingsLink>
        <SettingsLink to="/greylists/greylist?type=coordinates">
          Coordinates greylists
        </SettingsLink>
        <SettingsLink to="/greylists/greylist?type=email">
          Email greylists
        </SettingsLink>
        <SettingsLink to="/greylists/greylist?type=name">
          Name greylists
        </SettingsLink>
      </div>
    </div>
  );
};

export default GreyListMenu;
