import HonkioAPI from '../../../Honkio';

/**
 *
 * @param {*} options - {object: string, type: string }
 * @param {*} options.id file _id
 * @param {*} errorHandler - callback function, triggered on error in the response
 * @returns Promise
 */
export const getFile = (options = {}, errorHandler = () => {}) => {
    const { id } = options;
    return HonkioAPI().mainShop.userFetch(
        'adminfileminioget',
        {
            id,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
