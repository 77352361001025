const defaultAppInfoIds = [
    'address',
    'zip',
    'country',
    'city',
    'phone',
    'first_name',
    'last_name',
    'password',
    'business_id',
    'merchant_name',
];

export const defaultAppInfoRegistrationRequirements = [
    {
        id: 'address',
        required: false,
        name: 'Address',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'zip',
        required: false,
        name: 'Zip',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'country',
        required: false,
        name: 'Country',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'city',
        required: false,
        name: 'City',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'phone',
        required: false,
        name: 'Phone',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'first_name',
        required: true,
        name: 'First name',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'last_name',
        required: true,
        name: 'Last name',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'password',
        required: false,
        name: 'Password',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'business_id',
        required: false,
        name: 'Business ID',
        mandatory: false,
        type: 'str',
    },
    {
        id: 'merchant_name',
        required: true,
        name: 'Merchant name',
        mandatory: true,
        type: 'str',
    },
];

const convertToKeyValueDict = (arrayObj) => {
    const val = {};
    arrayObj.forEach((ob) => {
        val[ob.id] = ob;
    });
    return val;
};

const updateOrMerge = (a1, a2) => {
    const ob1 = convertToKeyValueDict(a1);
    const ob2 = convertToKeyValueDict(a2);
    const merged_obj = { ...ob1, ...ob2 };
    const val = Object.entries(merged_obj);
    return val.map((obj) => ({ ...obj[1] }));
};

const fillAppInfoRegistrationRequirements = (app) => {
    const defaultRR = Array.isArray(
        app.settings?.merchant_registration_requirements
    )
        ? app.settings?.merchant_registration_requirements.filter((e) => {
              return defaultAppInfoIds.includes(e.id);
          })
        : [];

    const customRR = Array.isArray(
        app.settings?.merchant_registration_requirements
    )
        ? app.settings?.merchant_registration_requirements.filter((e) => {
              return !defaultAppInfoIds.includes(e.id);
          })
        : [];
    const merchant_registration_requirements = updateOrMerge(
        defaultAppInfoRegistrationRequirements,
        defaultRR
    );

    const fields_merchant = customRR.map((e) => {
        return {
            name: e.name,
            mandatory: e.mandatory,
            id: e.id,
            type: e.type,
        };
    });

    const appInfo = Object.assign({}, app, {
        settings: {
            ...app.settings,
            merchant_registration_requirements,
        },
    });
    return { appInfo, fields_merchant };
};

export default fillAppInfoRegistrationRequirements;
