import { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-confirm-alert/src/react-confirm-alert.css';
import StepWizard from 'react-step-wizard';
import {
  Button,
  FormGroup,
  FormInput,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
} from 'shards-react';
import AssetSensorList from './AssetSensorList';
import WidgetBasicInfo from './WidgetBasicInfo';
import WidgetTypeComponent from './WidgetTypeComponent';
import GenericWidgetBasicInfo from './GenericWidgetBasicInfo';

const Circular = () => (
  // we need to add some padding to circular progress to keep it from activating our scrollbar
  <div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>
);

class WidgetSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      loading: true,
      settings: {},
    };
  }

  handleChange(e) {
    this.setState({
      settings: { ...this.state.settings, [e.target.id]: e.target.value },
    });
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.index && nextProps.items) {
      this.setState({
        items: nextProps.items,
        index: nextProps.index,
        settings: nextProps.items[nextProps.index],
      });
    }
  }

  onSubmit() {
    let items = this.state.items;
    let item = { ...items[this.state.index] };
    // alert(this.props.index)
    Object.keys(this.state.settings).forEach((k) => {
      // alert(k)

      let value = this.state.settings[k];
      // alert(value)
      item[k] = value;
    });
    items[this.props.index] = item;
    this.props.onSaveSettingsClick(items);
  }
  render() {
    return (
      <Modal
        open={this.props.visible}
        toggle={this.props.toggle}
        onRequestClose={this.props.onRequestClose}
        className="w-100"
      >
        <ModalHeader>Configure</ModalHeader>
        <ModalBody style={{ padding: 12 }}>
          {/*{this.props.settingsForm}*/}

          <FormGroup>
            <label htmlFor="type">Type</label>
            <FormSelect id="chartType" onChange={(e) => this.handleChange(e)}>
              <option
                value="line"
                selected={
                  this.state?.settings &&
                  this.state?.settings?.chartType === 'line'
                }
              >
                Lines
              </option>
              <option
                value="bar"
                selected={
                  this.state?.settings &&
                  this.state?.settings?.chartType === 'bar'
                }
              >
                Bars
              </option>
            </FormSelect>
          </FormGroup>

          <FormGroup>
            <label htmlFor="monthBack">Month back</label>
            <FormInput
              id="monthBack"
              placeholder="Month back"
              className="mb-2"
              onChange={(e) => this.handleChange(e)}
              value={this?.state?.settings?.monthBack}
              // disabled="disabled"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="monthForward">Month forward</label>
            <FormInput
              id="monthForward"
              placeholder="Month forward"
              className="mb-2"
              onChange={(e) => this.handleChange(e)}
              value={this?.state?.settings?.monthForward}
              // disabled="disabled"
            />
          </FormGroup>
          <Button id="submitEvent" onClick={this.onSubmit}>
            Save
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}

export default WidgetSettingsModal;
