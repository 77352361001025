import QueryString from 'qs';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Icon from '../UI/Icon';
import Wait from '../UI/Wait';
import ReactTooltip from 'react-tooltip';

const AssetStructuresList = ({
  data,
  ready,
  selectItem,
  id,
  className,
  emptyText,
  totalRows,
  rowsCountPerPage,
  handleRowsPerPage = () => {},
  onDeleteClick = () => {},
  perPageChoices = [25],
  handlePageChange = () => {},
}) => {
  const history = useHistory();
  const location = useLocation();
  const columns = useMemo(
    () => [
      { name: 'ID', grow: 1, cell: ({ _id }) => _id },
      { name: 'Name', grow: 2, cell: ({ name }) => name },
      {
        name: 'Actions',
        grow: 1,
        right: true,
        cell: ({ _id, merchant }) => (
          <div>
            <Link
              to={`/assetstructure/viewraw?assetstructure=${_id}&merchant=${merchant}`}
              data-tip
              data-for="viewTip"
            >
              <Icon iconName="bi-eye" />
              <ReactTooltip id="viewTip" place="top" effect="solid">
                View raw data
              </ReactTooltip>
            </Link>
            <Link
              to={`/assetstructure/edit?assetstructure=${_id}&merchant=${merchant}`}
              data-tip
              data-for="editTip"
            >
              <Icon iconName="bi-pencil" />
              <ReactTooltip id="editTip" place="top" effect="solid">
                Edit Asset
              </ReactTooltip>
            </Link>
            <span
              data-tip
              data-for="deleteTip"
              onClick={() => {
                onDeleteClick(_id);
              }}
            >
              <Icon iconName="bi bi-trash" />
              <ReactTooltip id="deleteTip" place="top" effect="solid">
                Delete
              </ReactTooltip>
            </span>
          </div>
        ),
      },
    ],
    [selectItem]
  );
  return (
    <DataTable
      dense
      striped
      highlightOnHover
      data={data}
      columns={columns}
      pagination={true}
      progressPending={!ready}
      noDataComponent={emptyText}
      onChangePage={handlePageChange}
      paginationTotalRows={totalRows}
      paginationPerPage={rowsCountPerPage}
      paginationRowsPerPageOptions={perPageChoices}
      onChangeRowsPerPage={handleRowsPerPage}
      paginationServer
      progressComponent={
        <div className="p-5">
          <Wait />
        </div>
      }
      onRowClicked={({ id }) => {
        const { pathname, search } = location;
        const qsObject = {
          ...QueryString.parse(search, { ignoreQueryPrefix: true }),
          parent: id,
        };
        history.push(`${pathname}?${QueryString.stringify(qsObject)}`);
      }}
    />
  );
};

export default AssetStructuresList;
