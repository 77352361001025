import { useEffect, useState } from 'react';
import getAppInfo from '../../api/utils/app/getAppInfo';
import { updateApp } from '../../api/utils/app/setAppInfo';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import Wait from '../UI/Wait';
import RulesEditForm from './RulesEditForm';

const AppLoginRules = () => {
    const [appId, setAppId] = useState(null);
    const [appInfo, setAppInfo] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (appId) fetchAppInfo();
    }, [appId]);

    useEffect(() => {
        // Get current app
        const notParsedInfo = localStorage.getItem('app_merchant_info');
        const parsedMerchantInfo = JSON.parse(notParsedInfo);
        setAppId(parsedMerchantInfo?.app?.id);
    }, []);

    const setAppInfoData = (appInfoData) => {
        setAppInfo(appInfoData);
    };

    const fetchAppInfo = async () => {
        setLoading(true);

        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setAppInfoData(data.application);
            } else {
                setAppInfo({});
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoading(false);
            setAppInfo({});
        };

        if (appId) {
            getAppInfo({ appid: appId }, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setAppInfo({});
            setLoading(false);
        }
    };

    const onSubmit = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setAppInfoData(data.application);
                NotificationManager.success(
                    'Application saved',
                    'Success',
                    4000
                );
            } else {
                NotificationManager.error('Unknown error', 'Error', 4000);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        const submitData = {
            settings: { login_rules: data.settings?.login_rules || {} },
        };

        updateApp({ id: appId, ...submitData }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    return (
        <div>
            <NotificationContainer />
            <h2>Application "{appInfo?.name}" login rules</h2>
            {!loading ? (
                appInfo?._id ? (
                    <RulesEditForm
                        appInfo={appInfo}
                        setAppInfo={setAppInfo}
                        onSubmit={onSubmit}
                    />
                ) : (
                    'not found'
                )
            ) : (
                <Wait />
            )}
        </div>
    );
};

export default AppLoginRules;
