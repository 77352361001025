import Honkio from '../../Honkio';

const getBellNotifications = (options, errorHandler = {}) => {
  const { query_application } = options;
  return Honkio().mainShop.userFetch(
    'admininfobellnotifications',
    {
      query_application,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getBellNotifications;
