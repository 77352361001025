import { useEffect, useState, useContext } from 'react';
import useSearchString from '../../utils/useSearchString';
import prepareToPublish from './helpers/prepareToPublish';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';

import UserInfoContext from '../../contexts/UserInfoContext';
import setAssetData from '../../api/utils/asset/setAssetData';
import AssetEditForm from './AssetEditForm';
import getAssetType from '../../api/utils/asset/type/getAssetType';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';
import BackButton from '../../components/UI/BackButton';
import { getAsset } from '../../api/utils/asset/getAssets';
import _ from 'lodash';
import { GOOGLE_API_KEY } from '../../config';
const BASE_GEO_CODE_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}`;

const AssetEdit = () => {
    const [selectedAssetType, setSelectedAssetType] = useState([]);

    const [assetInfo, setAssetInfo] = useState({});
    const { asset: assetId } = useSearchString();
    const [progress, done] = useLoader([assetInfo, selectedAssetType]);

    const [coordinatesInput, setCoordinatesInput] = useState([0, 0]);
    const [coordUpdated, setCoordUpdated] = useState(false);
    const [addressInput, setAddressInput] = useState('');
    const [addressUpdated, setAddressUpdated] = useState(false);

    // Address fields
    const [showAddressListModal, setShowAddressListModal] = useState(false);
    const handleShowAddressListModal = () => setShowAddressListModal(true);
    const handleCloseAddressListModal = () => setShowAddressListModal(false);
    const [addressList, setAddressList] = useState();

    const userInfo = useContext(UserInfoContext);
    const appid = userInfo.app ? userInfo.app.id : null;

    useEffect(() => {
        if (assetInfo?.type_reference) fetchAssetType();
    }, [assetInfo]);

    const fetchAssetType = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept' && data.asset_type) {
                setSelectedAssetType(data.asset_type);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        getAssetType({ id: assetInfo.type_reference }, errorHandler).then(
            (data) => {
                return resultHandler(data);
            }
        );
    };

    const onSave = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Asset saved', 'Success', 4000);
                setAssetInfo(data.asset);
                if (data?.asset?.coordinates) {
                    setCoordinatesInput(data.asset.coordinates);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        const assetData = prepareToPublish(assetInfo, data);

        setAssetData(
            assetData.asset,
            assetData.merchant,
            appid,
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };

    useEffect(() => {
        fetchAsset();
    }, [assetId]);

    const fetchAsset = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setAssetInfo(data.asset);
                if (data?.asset?.coordinates) {
                    setCoordinatesInput(data.asset.coordinates);
                }
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setAssetInfo();
        };
        if (assetId) {
            getAsset({ id: assetId }, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setAssetInfo();
        }
    };

    const handleGoogleRequest = async (adr, flag = 'address') => {
        const requestOptions = {
            method: 'POST',
        };
        const response = await fetch(adr, requestOptions);
        const result = await response.json();

        if (result.status === 'OK' && result.results.length > 0) {
            if (result.results.length > 1) {
                setAddressList(result.results);
                handleShowAddressListModal();
            } else {
                const newAsset = _.cloneDeep(assetInfo);

                const coordinates = [
                    result.results[0].geometry.location.lat,
                    result.results[0].geometry.location.lng,
                ];

                const address = result.results[0].formatted_address;

                newAsset.coordinates = coordinates;
                newAsset.str_address = address;

                setCoordinatesInput(coordinates);
                setAddressInput(address);

                setAssetInfo(newAsset);
            }
        } else {
            const newAsset = _.cloneDeep(assetInfo);
            newAsset.coordinates = [0, 0];

            setAssetInfo(newAsset);
            NotificationManager.warning(
                result.error_message || 'Geocode was not successful',
                'Geocode error',
                5000
            );
        }
    };

    const handleCoordinatesChanged = async (coordinates) => {
        setCoordUpdated(false);

        const adr = `${BASE_GEO_CODE_URL}&latlng=${coordinates[0]},${coordinates[1]}`;

        await handleGoogleRequest(adr, 'coordinates');
    };

    function onAddressSelected(address) {
        handleCloseAddressListModal();
        const coordinates = [
            address.geometry.location.lat,
            address.geometry.location.lng,
        ];
        const submitAddress = address.formatted_address;

        const submitData = _.cloneDeep(assetInfo);
        submitData.coordinates = coordinates;

        setCoordinatesInput(coordinates);
        setAddressInput(submitAddress);

        setAssetInfo(submitData);
    }

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton to="/assets" text="Back to list" /> Asset edit (ID#
                {assetId})
            </h2>
            {done ? (
                assetInfo ? (
                    <AssetEditForm
                        onSubmit={onSave}
                        defaultValues={assetInfo}
                        assetType={selectedAssetType}
                        coordinatesInput={coordinatesInput}
                        setCoordinatesInput={setCoordinatesInput}
                        setCoordUpdated={setCoordUpdated}
                        handleCoordinatesChanged={handleCoordinatesChanged}
                        addressList={addressList}
                        showAddressListModal={showAddressListModal}
                        onAddressSelected={onAddressSelected}
                        handleCloseAddressListModal={
                            handleCloseAddressListModal
                        }
                    />
                ) : (
                    'not found'
                )
            ) : (
                <LoadingBar progress={progress} />
            )}
        </div>
    );
};

export default AssetEdit;
