import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Form } from 'react-bootstrap';
import getMerchantData from '../../api/utils/merchant/getMerchantData';
import Wait from '../UI/Wait';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';

const TopUpModal = ({ show, merchant_id, onModalClose, handleSubmit }) => {
  const [merchantInfo, setMerchantInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [amount, setAmount] = useState('');

  useEffect(() => {
    if (merchant_id) {
      fetchMerchant();
    }
  }, [merchant_id]);

  const fetchMerchant = async () => {
    setLoading(true);
    const resultHandler = (data) => {
      setLoading(false);
      if (data && data?.status === 'accept' && data?.merchant) {
        setMerchantInfo(data?.merchant);
      } else {
        setMerchantInfo({});
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setLoading(false);
      setMerchantInfo({});
    };

    getMerchantData({ id: merchant_id }, errorHandler).then((data) => {
      return resultHandler(data);
    });
  };

  const handleSetAmount = (e) => {
    const re = /^[0-9]+$/gi;
    const newVal = e.target.value;
    if (re.test(newVal) || !newVal) {
      setAmount(newVal);
    }
  };

  const handleCloseModal = () => {
    setMerchantInfo(null);
    setAmount('');
    setLoading(false);
    onModalClose();
  };

  return (
    <div>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Top up balance{' '}
            {`"${merchantInfo?.settings?.str_name}"` || merchant_id}
          </Modal.Title>
        </Modal.Header>
        {loading ? (
          <Modal.Body>
            <Wait />
          </Modal.Body>
        ) : (
          <Modal.Body>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td>Bank name</td>
                  <td>{merchantInfo?.Bank_name}</td>
                </tr>
                <tr>
                  <td>Balance</td>
                  <td>
                    {parseFloat(
                      merchantInfo?.Merchant_balance || '0.00'
                    ).toFixed(2)}{' '}
                    {merchantInfo?.currency}
                  </td>
                </tr>
                <tr>
                  <td>Min balance</td>
                  <td>
                    {parseFloat(
                      merchantInfo?.Minimum_balance || '0.00'
                    ).toFixed(2)}{' '}
                    {merchantInfo?.currency}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Form.Group className="mb-3" controlId="topUp">
              <Form.Label>Add funds</Form.Label>
              <Form.Control
                type="email"
                placeholder={`Enter amount in ${merchantInfo?.currency}`}
                value={amount}
                onChange={handleSetAmount}
              />
              <Form.Text className="text-muted">
                Funds will be added to Merchant's balance
              </Form.Text>
            </Form.Group>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleSubmit({ merchant_id, amount });
              handleCloseModal();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TopUpModal;
