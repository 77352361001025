import Honkio from '../../../../Honkio';

const selectUserRegSettings = (options = {}, errorHandler = {}) => {
    const { merchant_id, user_reg_settings_id } = options;
    return Honkio().mainShop.userFetch(
        'adminmerchantuserregistrationsettingsselect',
        {
            merchant_id,
            user_reg_settings_id,
            version: 3,
            action: 'update',
        },
        errorHandler,
        true
    );
};

export default selectUserRegSettings;
