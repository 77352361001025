import { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-confirm-alert/src/react-confirm-alert.css';
import StepWizard from 'react-step-wizard';
import { Modal, ModalBody, ModalHeader } from 'shards-react';
import AssetSensorList from './AssetSensorList';
import WidgetBasicInfo from './WidgetBasicInfo';
import WidgetTypeComponent from './WidgetTypeComponent';
import GenericWidgetBasicInfo from './GenericWidgetBasicInfo';

const Circular = () => (
  // we need to add some padding to circular progress to keep it from activating our scrollbar
  <div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>
);

class WidgetAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Modal
        open={this.props.visible}
        toggle={this.props.toggle}
        onRequestClose={this.props.onRequestClose}
        className="w-100"
        closeAriaLabel="Close"
      >
        <ModalHeader>Add widget</ModalHeader>
        <ModalBody style={{ padding: 12 }}>
          <div>
            <StepWizard className="wizard">
              <WidgetTypeComponent
                onTypeSelected={(t) => {
                  this.setState({ asset: { group: t } });
                }}
              />

              <AssetSensorList
                onAssetSelected={(asset) => {
                  console.error(asset);
                  this.setState({ asset: asset });
                }}
              />

              <WidgetBasicInfo
                asset={this.state.asset}
                addWidget={(name) => {
                  this.props.addWidget(name, this.state.asset);
                }}
              />

              <GenericWidgetBasicInfo
                addWidget={(name, t) => {
                  this.props.addWidget(name, { group: t, id: 'none' });
                }}
              />
            </StepWizard>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default WidgetAddModal;
