import React, { useState } from 'react';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import _ from 'lodash';
import updateAccessRule from '../../api/utils/accessRules/updateAccessRule';
import useSearchString from '../../utils/useSearchString';
import AccessRuleDocModal from './components/AccessRuleDocModal';
import AccessRuleForm from './components/AccessRuleForm';

const EditAccessRule = () => {
  const { rule_id } = useSearchString();

  const [loading, setLoading] = useState(false);

  const [showDocModal, setShowDocModal] = useState(false);

  const handleSubmitForm = async (data) => {
    let dt = _.cloneDeep(data);

    const errorHandler = (error) => {
      setLoading(false);
      NotificationManager.error(error.description);
    };

    dt.id = rule_id;

    setLoading(true);
    const res = await updateAccessRule(dt, errorHandler);

    setLoading(false);
    if (res && res?.status === 'accept') {
      NotificationManager.success('Access rule updated');
    }
  };

  return (
    <div>
      <NotificationContainer />
      <AccessRuleForm
        rule_id={rule_id}
        onSubmit={handleSubmitForm}
        submitLoading={loading}
      />

      <AccessRuleDocModal
        show={showDocModal}
        onHide={() => setShowDocModal(false)}
      />
    </div>
  );
};

export default EditAccessRule;
