import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import {
    getPaymentById,
    updatePaymentProvider,
} from '../../api/utils/payments/getPayment';
import useSearchString from '../../utils/useSearchString';
import AppEditForm from '../Application/components/AppEditForm';
import BackButton from '../UI/BackButton';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';
import PaymentForm from './PaymentForm';

const PaymentEdit = () => {
    const [paymentInfo, setPaymentInfo] = useState();
    const { payment_providerId } = useSearchString();
    const [loading, setLoading] = useState(false);
    const [progress, done] = useLoader([paymentInfo]);
    useEffect(() => {
        fetchPaymentInfo();
    }, [payment_providerId]);

    const fetchPaymentInfo = async () => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setPaymentInfo(data.payment_provider);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setPaymentInfo({});
        };

        if (payment_providerId) {
            try {
                setLoading(true);
                const res = await getPaymentById(
                    { id: payment_providerId },
                    errorHandler
                );
                resultHandler(res);
            } catch (error) {
                setPaymentInfo({});
                setLoading(false);
            }
        } else {
            setPaymentInfo({});
        }
    };

    const onSubmit = async (data) => {
        const appSubmitData = _.cloneDeep(data);

        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setPaymentInfo(data.payment_provider);
                NotificationManager.success(
                    'Payment provider saved',
                    'Success',
                    4000
                );
            } else {
                NotificationManager.error('Unknown error', 'Error', 4000);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            setLoading(true);
            const res = await updatePaymentProvider(
                { id: payment_providerId, ...appSubmitData },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton
                    to="/payment"
                    text="Back to payment provider list"
                />
                Edit Payment provider (ID#{payment_providerId})
            </h2>

            {done ? (
                paymentInfo?._id ? (
                    <PaymentForm
                        paymentInfo={paymentInfo}
                        onSubmit={onSubmit}
                    />
                ) : (
                    'Not found '
                )
            ) : (
                <LoadingBar progress={progress} />
            )}
        </div>
    );
};

export default PaymentEdit;
