import Honkio from '../../Honkio';

const approvePaymentAml = (options = {}, errorHandler = {}) => {
  const { order_id, approved } = options;
  return Honkio().mainShop.userFetch(
    'adminpaymentsamlverify',
    {
      order_id,
      approved,
      action: 'update',
      version: 3,
    },
    errorHandler
  );
};

export default approvePaymentAml;
