import { useCallback, useEffect, useReducer, useState } from 'react';
import useSearchString from '../../utils/useSearchString';
import Editor from './Editor';
import SmartForm from '../Form/SmartForm';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import CheckboxSwitch from '../Form/elements/CheckboxSwitch';
import getScript from '../../api/utils/app/scripts/getScript';
import { updateScript } from '../../api/utils/app/scripts/setScript';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';

const init = (initialState) => initialState;

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'update':
      return { ...state, ...payload };
    default:
      return state;
  }
};

const ScriptEdit = () => {
  const { script } = useSearchString();
  const [state, dispatch] = useReducer(
    reducer,
    {
      text: '// code',
      trigger: '',
      type: '',
    },
    init
  );
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    const resultHandler = (data) => {
      NotificationManager.success('Script updated');
      const { text, name, type, active, debug, settings } = data.script;
      dispatch({
        type: 'update',
        payload: { text, name, type, active, debug, settings },
      });
      setLoading(false);
    };

    const errorHandler = (error) => {
      console.log('error updating script ==', error);
      NotificationManager.error(error.description, 'Error', 4000);
      setLoading(false);
    };

    const res = await updateScript({ ...data, id: script }, errorHandler);
    if (res && res.status === 'accept') {
      resultHandler(res);
    }
  };
  useEffect(() => {
    getScript({ id: script })
      .then(({ script }) => script)
      .then((report) => {
        const { text, name, type, active, debug, applications, trigger } =
          report;
        dispatch({
          type: 'update',
          payload: { text, name, type, active, debug, applications, trigger },
        });
      })
      .catch(console.error);
  }, [script]);

  return (
    <SmartForm
      defaultValues={state}
      className="container h-100"
      onSubmit={onSubmit}
    >
      <NotificationContainer />
      <Editor name="text" />
      <fieldset className="container p-3">
        <div className="row">
          <div className="col-5">
            <InputFieldWithValidation name="name" type="text" label="Name" />
          </div>
          <div className="col-5">
            <SelectFieldWithValidation
              name="type"
              label="Type"
              options={[
                { name: 'Scheduler script', value: 'scheduler' },
                { name: 'Report', value: 'report' },
                { name: 'API event', value: 'event' },
                { name: 'Select type...', value: '' },
              ]}
            />
          </div>
          <div className="col-2">
            <div className="row row-cols-1">
              <CheckboxSwitch name="active" label="Active" />
              <CheckboxSwitch name="debug" label="Debug" />
            </div>
          </div>
          <div className="col-5">
            <SelectFieldWithValidation
              name="trigger"
              label="Script trigger"
              options={[
                { name: 'User registration', value: 'user_register' },
                { name: 'Manual', value: 'manual' },
                { name: 'Other', value: 'other' },
                { name: 'Select trigger...', value: '' },
              ]}
            />
          </div>
          <div className="col-5"></div>
        </div>
      </fieldset>
      <button
        type="submit"
        className="btn btn-block btn-primary mt-3"
        disabled={loading}
      >
        Save
      </button>
    </SmartForm>
  );
};

export default ScriptEdit;
