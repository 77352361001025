import Honkio from '../../../Honkio';

const getProductTypeList = (options = {}, errorHandler = () => {}) => {
    const { query_merchant, query_skip, query_count, debug } = options;
    return Honkio().mainShop.userFetch(
        'adminproducttypelist',
        {
            debug,
            query_merchant,
            query_skip,
            query_count,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
export default getProductTypeList;
