import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import InputFieldWithValidation from '../../../Form/elements/InputFieldWithValidation';
import CheckboxSwitch from '../../../Form/elements/CheckboxSwitch';
import PlanFeaturesList from './PlanFeaturesList';

const PricingPlanItem = ({ item, idx, onDelete }) => {
  return (
    <ListGroup.Item>
      <InputFieldWithValidation
        className="p-2"
        label="Name"
        name={`pricing_plans[${idx}].name`}
        defaultValue={item?.name || ''}
      />
      <InputFieldWithValidation
        className="p-2"
        label="Amount"
        name={`pricing_plans[${idx}].amount`}
        defaultValue={item?.amount}
        type="number"
        step={1}
        min={0}
      />

      <PlanFeaturesList idx={idx} item={item} />

      <CheckboxSwitch
        name={`pricing_plans[${idx}].is_most_popular`}
        label="Is most popular"
        className="my-3"
        defaultChecked={item.is_most_popular}
      />

      <div className="row mt-3">
        <div className="col"></div>
        <div className="col d-flex justify-content-end align-items-center">
          <Button onClick={() => onDelete(idx)} variant="danger">
            {/* <Icon iconName="bi-trash-fill" size="2" color="var(--danger)" /> */}
            Remove
          </Button>
        </div>
      </div>
    </ListGroup.Item>
  );
};

export default PricingPlanItem;
