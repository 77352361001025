import Honkio from '../../Honkio';

const deleteAccessRule = (options = {}, errorHandler = {}) => {
  const { id } = options;
  return Honkio().mainShop.userFetch(
    'accessrulesdelete',
    {
      id,
      action: 'delete',
      version: 3,
    },
    errorHandler
  );
};
export default deleteAccessRule;
