import { useEffect, useState, useContext } from 'react';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { getOrders } from '../../../api/utils/order/getOrders';
import useSearchString from '../../../utils/useSearchString';
import UserInfoContext from '../../../contexts/UserInfoContext';
import RegOrderApprovalList from './RegOrdersApprovalList';
import approveOrRejectMerchant from '../../../api/utils/merchant/registration/approveOrRejectMerchant';

const MerchantApproval = () => {
  const [orderList = [], setOrderList] = useState([]);
  const [selected, setSelected] = useState({});
  const userInfo = useContext(UserInfoContext);
  const { merchant = null, application = null } = useSearchString();
  const merchantId = merchant
    ? merchant
    : userInfo.merchant
    ? userInfo.merchant.id
    : null;
  const applicationId = application
    ? application
    : userInfo.app
    ? userInfo.app.id
    : null;
  const pageTitle = merchant
    ? 'Merchant registration approval'
    : userInfo.merchant?.name
    ? `Merchant registration approval(${userInfo.merchant.name})`
    : 'Merchant registration approval';

  const [loading, setLoading] = useState(false);
  let isMounted = true;
  const [perPage, setPerPage] = useState(25);
  const [totalRows, setTotalRows] = useState();

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (merchantId) {
      fetchOrders(1);
    }
  }, [merchantId]);

  const fetchOrders = async (page) => {
    setLoading(true);
    const resultHandler = (data) => {
      if (data && data.status === 'accept') {
        if (isMounted) {
          setOrderList(data.orders);
          setTotalRows(data.orders_count);
          setLoading(false);
        }
      } else {
        NotificationManager.error('Error');
      }
    };

    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setOrderList([]);
      setTotalRows(0);
      setLoading(false);
    };

    if (isMounted) {
      getOrders(
        {
          query_application: applicationId,
          page: page - 1,
          query_count: perPage,
          query_child_merchants: true,
          query_type: 'merchant_registration',
        },
        errorHandler
      )
        .then((data) => {
          return resultHandler(data);
        })
        .catch(console.log);
    }
  };

  const handlePageChange = (page) => {
    fetchOrders(page);
  };

  const handleApproveMerchant = (order) => {
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setOrderList([]);
      setTotalRows(0);
      setLoading(false);
    };

    approveOrRejectMerchant(
      {
        order_id: order._id,
        approved: true,
      },
      errorHandler
    ).then((res) => {
      if (res.status === 'accept') {
        NotificationManager.success(
          res.message ? res.message : 'Merchant approved',
          'Success',
          4000
        );
        fetchOrders(1);
      } else {
        NotificationManager.error(
          res.message ? res.message : 'Merchant approval failed',
          'Error',
          4000
        );
        fetchOrders(1);
      }
    });
  };

  const handleRejectMerchant = (order) => {
    const errorHandler = (error) => {
      NotificationManager.error(error.description, 'Error', 4000);
      setOrderList([]);
      setTotalRows(0);
      setLoading(false);
    };

    approveOrRejectMerchant(
      {
        order_id: order._id,
        rejected: true,
      },
      errorHandler
    ).then((res) => {
      if (res.status === 'accept') {
        NotificationManager.success(
          res.message ? res.message : 'Merchant rejected',
          'Success',
          4000
        );
        fetchOrders(1);
      } else {
        NotificationManager.error(
          res.message ? res.message : 'Merchant reject failure',
          'Error',
          4000
        );
        fetchOrders(1);
      }
    });
  };

  return (
    <div className="w-100 h-100">
      <NotificationContainer />
      <div className="container-fluid">
        <div className="cus-flex">
          <h2>{pageTitle}</h2>{' '}
        </div>

        {merchantId ? (
          <div className="row">
            <div className="col">
              <RegOrderApprovalList
                data={orderList}
                selectItem={setSelected}
                id={selected.id}
                loading={loading}
                totalRows={totalRows}
                handlePageChange={handlePageChange}
                rowsCountPerPage={perPage}
                merchant={merchantId}
                emptyText={'The order list is empty'}
                onApprove={handleApproveMerchant}
                onReject={handleRejectMerchant}
              />
            </div>
          </div>
        ) : (
          'Please, select merchant first '
        )}
      </div>
    </div>
  );
};

export default MerchantApproval;
