import { useEffect, useState, useContext } from 'react';
import useSearchString from '../../utils/useSearchString';
import prepareToPublish from './helpers/prepareToPublish';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import UserInfoContext from '../../contexts/UserInfoContext';
import useLoader from '../UI/helpers/useLoader';
import LoadingBar from '../UI/LoadingBar';
import BackButton from '../UI/BackButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import _ from 'lodash';
import { GOOGLE_API_KEY } from '../../config';
import { createCustomer } from '../../api/utils/customer/setCustomer';
import { getCustomerType } from '../../api/utils/customer/type/getCustomerType';
import CustomerEditForm from './CustomerEditForm';

const BASE_GEO_CODE_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}`;

const CustomerCreate = () => {
    const history = useHistory();
    const { customer_type } = useSearchString();

    const [selectedCustomerType, setSelectedCustomerType] = useState([]);
    const [customerInfo, setCustomerInfo] = useState({});
    const [progress, done] = useLoader([customerInfo, selectedCustomerType]);

    const [coordinatesInput, setCoordinatesInput] = useState([0, 0]);
    const [coordUpdated, setCoordUpdated] = useState(false);
    const [addressInput, setAddressInput] = useState('');
    const [addressUpdated, setAddressUpdated] = useState(false);

    // Address fields
    const [showAddressListModal, setShowAddressListModal] = useState(false);
    const handleShowAddressListModal = () => setShowAddressListModal(true);
    const handleCloseAddressListModal = () => setShowAddressListModal(false);
    const [addressList, setAddressList] = useState();

    const {
        merchant: { id: merchantId },
        app: { id: appId },
    } = useContext(UserInfoContext);

    const onSave = async (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Customer saved', 'Success', 4000);
                history.push(`/customer/edit?id=${data.customer._id}`);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        const customerData = prepareToPublish(customerInfo, data);
        customerData.merchant = merchantId;
        customerData.application = appId;

        try {
            const res = await createCustomer(customerData, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.error('Unexpected error while creating customer: ', error);
        }
    };
    useEffect(() => {
        if (customer_type) {
            fetchCustomerType();
        }
    }, [customer_type]);

    const fetchCustomerType = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept' && data.customer_type) {
                setSelectedCustomerType(data.customer_type);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            const res = await getCustomerType(
                { id: customer_type },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.error(
                'Unexpected error while getting customer type: ',
                error
            );
        }
    };

    useEffect(() => {
        setCustomerInfo({
            type: selectedCustomerType.name,
        });
    }, [selectedCustomerType]);

    const handleGoogleRequest = async (adr, flag = 'address') => {
        const requestOptions = {
            method: 'POST',
        };
        const response = await fetch(adr, requestOptions);
        const result = await response.json();

        if (result.status === 'OK' && result.results.length > 0) {
            if (result.results.length > 1) {
                setAddressList(result.results);
                handleShowAddressListModal();
            } else {
                const newcustomer = _.cloneDeep(customerInfo);

                const coordinates = [
                    result.results[0].geometry.location.lat,
                    result.results[0].geometry.location.lng,
                ];

                const address = result.results[0].formatted_address;

                newcustomer.coordinates = coordinates;
                newcustomer.str_address = address;

                setCoordinatesInput(coordinates);
                setAddressInput(address);

                setCustomerInfo(newcustomer);
            }
        } else {
            const newcustomer = _.cloneDeep(customerInfo);
            newcustomer.coordinates = [0, 0];

            setCustomerInfo(newcustomer);
            NotificationManager.warning(
                result.error_message || 'Geocode was not successful',
                'Geocode error',
                5000
            );
        }
    };

    const handleCoordinatesChanged = async (coordinates) => {
        setCoordUpdated(false);

        const adr = `${BASE_GEO_CODE_URL}&latlng=${coordinates[0]},${coordinates[1]}`;

        await handleGoogleRequest(adr, 'coordinates');
    };

    function onAddressSelected(address) {
        handleCloseAddressListModal();
        const coordinates = [
            address.geometry.location.lat,
            address.geometry.location.lng,
        ];
        const submitAddress = address.formatted_address;

        const submitData = _.cloneDeep(customerInfo);
        submitData.coordinates = coordinates;

        setCoordinatesInput(coordinates);
        setAddressInput(submitAddress);

        setCustomerInfo(submitData);
    }

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton to="/customers" text="Back to list" /> Create
                customer
            </h2>
            {done ? (
                customerInfo ? (
                    <CustomerEditForm
                        onSubmit={onSave}
                        defaultValues={customerInfo}
                        customerType={selectedCustomerType}
                        coordinatesInput={coordinatesInput}
                        setCoordinatesInput={setCoordinatesInput}
                        setCoordUpdated={setCoordUpdated}
                        handleCoordinatesChanged={handleCoordinatesChanged}
                        addressList={addressList}
                        showAddressListModal={showAddressListModal}
                        onAddressSelected={onAddressSelected}
                        handleCloseAddressListModal={
                            handleCloseAddressListModal
                        }
                    />
                ) : (
                    'not found'
                )
            ) : (
                <LoadingBar progress={progress} />
            )}
        </div>
    );
};

export default CustomerCreate;
