import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';
import getRoleTemplate from '../../../api/utils/templateModels/templateRoles/getRoleTemplate';
import getAdminAccesses from '../../../api/utils/getAdminAccesses';
import languages from '../../../__fixtures__/languages';
import UserInfoContext from '../../../contexts/UserInfoContext';

const INITIAL_ROLE_TEMPLATE = {
    active: true,
    name: '',
    display_name__fi: '',
    display_name__sv: '',
    display_name__en: '',
    display_name__no: '',
    display_name__da: '',
    description: '',
    description__fi: '',
    description__sv: '',
    description__en: '',
    description__no: '',
    description__da: '',
    object_access: {},
};

const LABELS_OVERRIDE = {
    everything: 'grant all',
};

const TemplateRoleForm = ({
    id = null,
    onSubmit = () => {},
    submitLoading = false,
}) => {
    const {
        app: { id: appId },
        merchant: { id: merchantId },
    } = useContext(UserInfoContext);

    const [templateRole, setTemplateRole] = useState(INITIAL_ROLE_TEMPLATE);
    const [accesses, setAccesses] = useState({});

    const [selectedLanguage, setSelectedLanguage] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAccesses();
    }, []);

    useEffect(() => {
        if (id) fetchCurrentRoleTemplate(id);
    }, [id]);

    const fetchCurrentRoleTemplate = async (id) => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data?.status === 'accept') {
                const roleData = data.role_template;
                setTemplateRole(roleData);
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setTemplateRole({});
        };

        try {
            setLoading(true);
            const res = await getRoleTemplate(
                { id, merchant: merchantId, application: appId },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
            setTemplateRole({});
        }
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        if (!merchantId || !appId) {
            NotificationManager.error(
                'Please, select app and merchant',
                'Error'
            );
            return;
        }

        const submitSettings = _.cloneDeep(templateRole);
        submitSettings.merchant = merchantId;
        submitSettings.application = appId;

        await onSubmit(submitSettings);
    };

    const handleChangeTemplateRole = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        setTemplateRole((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    const fetchAccesses = async () => {
        const resultHandler = (data) => {
            setLoading(false);
            if (data && data?.status === 'accept') {
                setAccesses(data.accesses);
                if (_.isEmpty(templateRole?.object_access)) {
                    try {
                        // Set all accesses to [] by default
                        let newAccesses = {};
                        Object.keys(data.accesses).forEach((item) => {
                            newAccesses[item] = [];
                        });
                        setTemplateRole((prev) => ({
                            ...prev,
                            object_access: newAccesses,
                        }));
                    } catch (error) {
                        console.log('error setting default accesses', error);
                    }
                }
            }
        };

        const errorHandler = (error) => {
            setLoading(false);
            NotificationManager.error(error.description, 'Error', 4000);
            setAccesses({});
        };

        try {
            const res = await getAdminAccesses({}, errorHandler);
            resultHandler(res);
        } catch (error) {
            errorHandler(error);
        }
    };

    const columns = useMemo(
        () => [
            {
                name: 'Access',
                width: '200px',
                cell: (row) => row?.name,
            },
            {
                name: 'Description',
                cell: (row) =>
                    row?.description_key ?? (
                        <span className="text-muted">'No description yet'</span>
                    ),
            },
        ],
        []
    );

    const handleOverrideLabels = (label) => {
        const override = _.get(LABELS_OVERRIDE, label);
        if (override) return override;
        return label;
    };

    const isArrayEqual = (x, y) => _.isEmpty(_.xorWith(x, y, _.isEqual));

    const updateSelected = (accessName, selectedAccesses = []) => {
        if (!templateRole) return;

        let newAccess = _.cloneDeep(templateRole.object_access);
        const newSelected = selectedAccesses.map((a) => a.name);

        if (!isArrayEqual(newAccess[accessName], newSelected)) {
            newAccess[accessName] = newSelected;
            setTemplateRole((prev) => ({
                ...prev,
                object_access: newAccess,
            }));
        }
    };

    const rowSelectCritera = (row, currentName) =>
        (templateRole?.object_access?.[currentName] || []).includes(row.name);

    const permissionsMap = Object.keys(accesses).map((name, index) => (
        <div key={index}>
            <h4>{handleOverrideLabels(name)}</h4>
            <DataTable
                dense
                striped
                highlightOnHover
                columns={columns}
                data={accesses[name]}
                onSelectedRowsChange={(selectionEvent) => {
                    updateSelected(name, selectionEvent.selectedRows);
                }}
                selectableRows={true}
                selectableRowSelected={(row) => rowSelectCritera(row, name)}
            />
        </div>
    ));

    const handleSelectLanguage = (e) => {
        setSelectedLanguage(e.target.value);
    };

    return (
        <Form onSubmit={handleSubmitForm}>
            <Form.Group className="mb-3" controlId="data.name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Role name"
                    name="name"
                    value={templateRole?.name || ''}
                    onChange={handleChangeTemplateRole}
                />
                <Form.Text className="text-muted">
                    Name of the role created from this template
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.active">
                <Form.Label>Active</Form.Label>
                <Form.Check // prettier-ignore
                    type="switch"
                    name="active"
                    id="active"
                    checked={templateRole?.active || false}
                    onChange={(e) =>
                        handleChangeTemplateRole({
                            target: {
                                name: 'active',
                                value: !templateRole?.active,
                            },
                        })
                    }
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.language">
                <Form.Label>Language</Form.Label>
                <Form.Control
                    as={'select'}
                    aria-label="Language"
                    name="language"
                    value={selectedLanguage}
                    onChange={handleSelectLanguage}
                    className="custom-select"
                >
                    <option value="">Default</option>
                    {languages.map((item, index) => (
                        <option
                            key={`${index}-${item?.value}-${item?.name}-lang-option`}
                            value={item?.value}
                        >
                            {item?.name}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text className="text-muted">
                    Select language for subject and message
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.display_name">
                <Form.Label>
                    Display name{' '}
                    {selectedLanguage ? `(${selectedLanguage})` : null}
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Role displayed name"
                    name={
                        selectedLanguage
                            ? `display_name__${selectedLanguage}`
                            : 'display_name'
                    }
                    value={
                        selectedLanguage
                            ? templateRole?.[
                                  `display_name__${selectedLanguage}`
                              ]
                            : templateRole?.display_name || ''
                    }
                    onChange={handleChangeTemplateRole}
                />
                <Form.Text className="text-muted">
                    Displayed name of the role created from this template
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="data.description">
                <Form.Label>
                    Description{' '}
                    {selectedLanguage ? `(${selectedLanguage})` : null}
                </Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    name={
                        selectedLanguage
                            ? `description__${selectedLanguage}`
                            : 'description'
                    }
                    value={
                        selectedLanguage
                            ? templateRole?.[`description__${selectedLanguage}`]
                            : templateRole?.description || ''
                    }
                    onChange={handleChangeTemplateRole}
                />
            </Form.Group>

            {permissionsMap}

            <Button
                type="submit"
                variant="primary"
                disabled={loading || submitLoading}
            >
                Submit{' '}
                {loading || submitLoading ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : null}
            </Button>
        </Form>
    );
};

export default TemplateRoleForm;
