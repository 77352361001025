import Honkio from '../../../Honkio';

const getMerchantTransaction = (options = {}, errorHandler = {}) => {
  const { id } = options;
  return Honkio().mainShop.userFetch(
    'merchanttransactionget',
    {
      id,
      version: 3,
      action: 'read',
    },
    errorHandler
  );
};

export default getMerchantTransaction;
